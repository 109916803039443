import { MAIN_MAP_TELEMATICS_PREFIX } from "../../../../../core/map/actions/mainMapTelematics/mainMapTelematics.constants";

export const GET_MACHINES_POSITION_DASHBOARD = `${MAIN_MAP_TELEMATICS_PREFIX}/GET_MACHINES_POSITION_DASHBOARD`;
export const GET_MACHINES_POSITION_DASHBOARD_SUCCESS = `
${MAIN_MAP_TELEMATICS_PREFIX}/GET_MACHINES_POSITION_DASHBOARD_SUCCESS`;
export const GET_MACHINES_POSITION_DASHBOARD_ERROR = `
${MAIN_MAP_TELEMATICS_PREFIX}/GET_MACHINES_POSITION_DASHBOARD_ERROR`;
export const GET_MACHINES_POSITION_DASHBOARD_RESET = `
${MAIN_MAP_TELEMATICS_PREFIX}/GET_MACHINES_POSITION_DASHBOARD_RESET`;

export const SET_DASHBOARD_SELECTED_MACHINE = `${MAIN_MAP_TELEMATICS_PREFIX}/SET_DASHBOARD_SELECTED_MACHINE`;
export const SET_DASHBOARD_MACHINE_IS_OPEN = `${MAIN_MAP_TELEMATICS_PREFIX}/SET_DASHBOARD_MACHINE_IS_OPEN`;

export const GET_DASHBOARD_DRIVES_HISTORY_REQUEST = `
${MAIN_MAP_TELEMATICS_PREFIX}/GET_DASHBOARD_DRIVES_HISTORY_REQUEST`;
export const GET_DASHBOARD_DRIVES_HISTORY_SUCCESS = `
${MAIN_MAP_TELEMATICS_PREFIX}/GET_DASHBOARD_DRIVES_HISTORY_SUCCESS`;
export const GET_DASHBOARD_DRIVES_HISTORY_ERROR = `
${MAIN_MAP_TELEMATICS_PREFIX}/GET_DASHBOARD_DRIVES_HISTORY_ERROR`;

export const RESET_DASHBOARD_DRIVES_HISTORY = `${MAIN_MAP_TELEMATICS_PREFIX}/RESET_DASHBOARD_DRIVES_HISTORY`;
