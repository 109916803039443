import React, { FC } from "react";

import { Breakpoint } from "@mui/material";
import { FormattedMessage } from "react-intl";

import CfPrimaryTab from "../../../../../../shared/components/common/CfPrimaryTab/CfPrimaryTab";
import CfPrimaryTabs from "../../../../../../shared/components/common/CfPrimaryTabs/CfPrimaryTabs";

export interface ParcelDetailTab {
  id: string;
  section: string;
}

export interface ParcelDetailTabsProps {
  activateTab: (value: string) => void;
  activeTab: string;
  tabs: ParcelDetailTab[];
  width?: Breakpoint;
  onChange: (value: string) => void;
}

const ParcelDetailTabs: FC<ParcelDetailTabsProps> = ({
  activateTab,
  activeTab,
  onChange,
  tabs,
  width,
}) => {
  const handleChange = (event: never, value: string) => {
    onChange(value);
    activateTab(value);
  };

  const variant = width === "xs" ? "scrollable" : "standard";

  return (
    <CfPrimaryTabs
      allowScrollButtonsMobile={true}
      centered={width !== "xs"}
      onChange={handleChange}
      scrollButtons={true}
      tabValue={activeTab}
      variant={variant}
    >
      {tabs?.map((tab) => (
        <CfPrimaryTab
          data-test={tab.id}
          key={tab.id}
          label={<FormattedMessage id={`${tab.section}.${tab.id}`} />}
          style={{ flexGrow: 1 }}
          value={tab.id}
        />
      ))}
    </CfPrimaryTabs>
  );
};

export default ParcelDetailTabs;
