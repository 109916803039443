import React from "react";

import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export type BarChartStripe = {
  label: number;
  color: string;
};

type Props = {
  data: BarChartStripe[];
};

const HorizontalBarChart = ({ data = [] }: Props) => {
  const classes = useStyles();

  const colors = data.map((c) => c.color);
  const labels = data.map((c) => c.label);

  const maxValue = labels.reduce((a, b) => a + b, 0);

  return (
    <div className={classes.wrapper}>
      <div className={classes.bar}>
        {labels.map((value, index) => {
          const barWidth = (value / maxValue) * 100;
          const isFirst = index === 0;
          const isLast = index === labels.length - 1;
          return (
            <div
              className={classes.barStripe}
              key={index}
              style={{
                backgroundColor: colors[index],
                width: `${barWidth}%`,
                borderTopLeftRadius: isFirst ? 10 : 0,
                borderBottomLeftRadius: isFirst ? 10 : 0,
                borderTopRightRadius: isLast ? 10 : 0,
                borderBottomRightRadius: isLast ? 10 : 0,
              }}
            />
          );
        })}
      </div>
      <div className={classes.xAxisLabels}>
        {labels.map((value, index) => (
          <span
            className={classes.xLabel}
            key={index}
            style={{
              width: `${(value / maxValue) * 100}%`,
            }}
          >
            {value}
          </span>
        ))}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  bar: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  barStripe: {
    height: 7,
  },
  xAxisLabels: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  xLabel: {
    fontSize: 13,
    color: theme.palette.grey[500],
    textAlign: "center",
  },
}));

export default HorizontalBarChart;
