import { createSelector } from "reselect";

export const getTool = (state) => state.ui.editor.tool;
export const getHoveredId = (state) => state.ui.editor.hoveredId;
export const getSelected = (state) => state.ui.editor.selected;
export const getMapDragging = (state) => state.ui.editor.isMapDragging;
export const getIsEditing = (state) => Boolean(getTool(state));

export const getSelectedParcel = (state) => state.ui.editor.selected[0] || null;
export const getSelectedParcels = (state) => state.ui.editor.selected;
export const getSelectedParcelIds = createSelector(
  getSelectedParcels,
  (selected) => selected.map((item) => item.id),
);

// measurement selectors
export const getMeasurements = (state) => state.ui.editor.measurement.items;
