import React, { useEffect, useRef } from "react";

import _ from "lodash";
import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { match as matchType } from "react-router-dom";
import { bindActionCreators } from "redux";

import {
  getIsFetching,
  getError,
  getTotalCount,
} from "../../../../../shared/api/agroevidence/actions/actions.selectors";
import {
  getPage,
  getRowsPerPage,
  getOrder,
  getOrderBy,
  getSelected,
  getSelectedOnPage,
  getProcessedActions,
  getTextFilter,
  getActionsAdvancedFilter,
  getActionsDateTo,
  getActionsDateFrom,
} from "../../selectors/actionList.selectors";

import { fetchActions } from "../../actions/actionList.actions";

import { NAMESPACE as namespace } from "../../reducer/actionList.reducer";

import CfTableFooter from "../../../../../common/components/CfTableFooter/CfTableFooter";
import { useCfTableFooter } from "../../../../../common/components/CfTableFooter/useCfTableFooter";
import CfTableHead from "../../../../../common/components/CfTableHead/CfTableHead";
import { useCfTableHead } from "../../../../../common/components/CfTableHead/useCfTableHead";
import CfTableBody from "../../../../../shared/components/tables/CfTableBody/CfTableBody";
import CfTableBodyEmpty from "../../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableBodyLoader from "../../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader";
import CfTableWrapper from "../../../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import { getColDesc } from "../../../../../shared/misc/helper";
import { Thunk } from "../../../../../types";

import { ActionsState } from "../../../../../reducers/actions.reducer.types";

const columns = {
  "dateStart,id": getColDesc(true, <FormattedMessage id="common.date" />),
  "actionType.localizedNames.value": getColDesc(
    true,
    <FormattedMessage id="common.action-name" />,
  ),
  source: getColDesc(false, <FormattedMessage id="common.source" />),
  landUse: getColDesc(false, <FormattedMessage id="common.crop" />),
  localName: getColDesc(false, <FormattedMessage id="common.parcel" />),
  material: getColDesc(false, <FormattedMessage id="common.material" />),
  dose: getColDesc(
    false,
    <FormattedMessage id="common.totalDose" />,
    undefined,
    "center",
  ),
  dosePerHectare: getColDesc(
    false,
    <FormattedMessage id="common.dosePerHectare" />,
    undefined,
    "center",
  ),
};

type ReduxProps = ConnectedProps<typeof connector>;
type OwnProps = {
  ngGoToAction: (actionId: string, route: string) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  match: matchType<{ farmId: string }>;
};

type Props = OwnProps & ReduxProps;

const ActionsTable = (props: Props) => {
  const {
    actions,
    advancedFilter,
    count,
    dateFrom,
    dateTo,
    fetchActions,
    isFetching,
    ngGoToAction,
    order,
    orderBy,
    page,
    rowsPerPage,
    selected,
    selectedOnPage,
    textFilter,
  } = props;

  const { onPageChange, onRowsPerPageChange } = useCfTableFooter(namespace);

  const prevProps = useRef<Partial<Props>>({});

  const { onSelect, onSort } = useCfTableHead(namespace);

  useEffect(() => {
    fetchActions();
  }, [fetchActions]);

  useEffect(() => {
    const oldProps = _.pick(prevProps.current, [
      "order",
      "orderBy",
      "page",
      "rowsPerPage",
      "textFilter",
      "dateFrom",
      "dateTo",
    ]);
    const newProps = _.pick(props, [
      "order",
      "orderBy",
      "page",
      "rowsPerPage",
      "textFilter",
      "dateFrom",
      "dateTo",
    ]);

    if (
      !_.isEqual(newProps, oldProps) ||
      !_.isEqual(prevProps.current.advancedFilter, props.advancedFilter)
    ) {
      fetchActions();
    }

    prevProps.current = props;
  }, [
    order,
    orderBy,
    page,
    rowsPerPage,
    textFilter,
    dateTo,
    dateFrom,
    advancedFilter,
    props,
    fetchActions,
  ]);

  return (
    <CfTableWrapper>
      <CfTableHead
        columns={columns}
        items={actions}
        onSelect={onSelect}
        onSort={onSort}
        order={order}
        orderBy={orderBy}
        selected={selected}
        selectedOnPage={selectedOnPage}
      />
      {isFetching && <CfTableBodyLoader columns={columns} />}

      {actions.length && !isFetching ? (
        actions.map((row) => (
          // TODO temporary, fix this (row.isDraft)
          <CfTableBody
            columns={columns}
            hover={!row.isDraft}
            key={row.id}
            namespace={namespace}
            onClick={() => ngGoToAction(row.id, row.route)}
            row={row}
            selected={selected}
          />
        ))
      ) : (
        <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
      )}
      <CfTableFooter
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </CfTableWrapper>
  );
};

const mapStateToProps = (state: ActionsState, props: OwnProps) => ({
  actions: getProcessedActions(state, {
    match: props.match,
  }),
  isFetching: getIsFetching(state),
  count: getTotalCount(state),
  error: getError(state),
  page: getPage(state),
  rowsPerPage: getRowsPerPage(state),
  order: getOrder(state),
  orderBy: getOrderBy(state),
  selected: getSelected(state),
  selectedOnPage: getSelectedOnPage(state),
  textFilter: getTextFilter(state),
  dateFrom: getActionsDateFrom(state),
  dateTo: getActionsDateTo(state),
  advancedFilter: getActionsAdvancedFilter(state),
});

const mapDispatchToProps = (dispatch: Thunk<ActionsState>) =>
  bindActionCreators(
    {
      fetchActions,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ActionsTable);
