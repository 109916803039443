import React from "react";

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import { getLocalizedDateString } from "../../../../../shared/misc/timeHelpers";
import { COLOR_GREY } from "../../../../../theme";

import { PlantProtectionDetailFormValues } from "../PlantProtectionDetail.types";

interface Props {
  isMobile: boolean;
  values: PlantProtectionDetailFormValues;
}

export const GeneralSectionNonEditMode = ({ isMobile, values }: Props) => {
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        direction={isMobile ? "column-reverse" : "row"}
        justifyContent="space-between"
      >
        <Grid
          className={classes.container}
          container
          direction="row"
          item
          xs={6}
        >
          <Typography className={classes.label} variant="body2">
            <FormattedMessage id="Catalogues.plantProtection.detail.bioFunction" />
          </Typography>
          <Typography variant="subtitle2">{values.bioFunction}</Typography>
        </Grid>
        <Grid
          alignItems="center"
          className={classes.container}
          container
          item
          justifyContent={isMobile ? "flex-start" : "flex-end"}
          xs={6}
        >
          <Typography className={classes.label} variant="body2">
            <FormattedMessage id="Catalogues.plantProtection.detail.isEco" />
          </Typography>
          <Grid item>
            {values.isEco ? (
              <CheckIcon color="primary" />
            ) : (
              <ClearIcon color="error" />
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid className={classes.container} container item>
        <Typography className={classes.label} variant="body2">
          <FormattedMessage id="Catalogues.table.plantProtection.column.validTo" />
        </Typography>
        <Typography variant="subtitle2">
          {values.usableUntil
            ? getLocalizedDateString(values.usableUntil)
            : "-"}
        </Typography>
      </Grid>

      <Grid container item xs={8}>
        <Typography className={classes.label} variant="body2">
          <FormattedMessage id="common.note" />
        </Typography>
        <Typography variant="subtitle2">{values.notes ?? "-"}</Typography>
      </Grid>
    </>
  );
};

const useStyles = makeStyles(() => ({
  container: { marginBottom: 10 },
  label: {
    color: COLOR_GREY[500],
    marginRight: 10,
    fontWeight: 500,
  },
}));
