import React from "react";

import FormControl, { FormControlProps } from "@mui/material/FormControl";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

const useStyles = makeStyles({
  formControl: {
    margin: "5px 0px 25px 0px",
  },
});

type OwnProps = {
  children?: React.ReactNode | React.ReactNode[];
  classes?: Record<string, string>;
};

type Props = OwnProps & Omit<FormControlProps, "classes">;

const CfFormControl = ({ children, classes = {}, ...props }: Props) => {
  const defClasses = useStyles();

  return (
    <FormControl
      {...props}
      className={classNames(defClasses.formControl, classes.formControl)}
      fullWidth={true}
    >
      {children}
    </FormControl>
  );
};

export default CfFormControl;
