import React from "react";

import Popover from "@mui/material/Popover";
import { makeStyles } from "@mui/styles";
import isEmpty from "lodash/isEmpty";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { getCountriesSuggestions } from "../../selectors/manager.selectors";

import { getCountries, resetCountries } from "../../actions/manager.actions";

import CfSwitcherButton from "../../../../../shared/components/common/CfSwitcherButton/CfSwitcherButton";
import usePopover from "../../../../../shared/hooks/usePopover";
import AdminCountrySelector from "../../../../shared/components/AdminCountrySelector/AdminCountrySelector";

type Country = {
  code: string;
  name: string;
};

const CountrySwitcher = ({
  country,
  onCountrySelect,
}: {
  onCountrySelect: (country: Country) => void;
  country?: Country;
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const suggestions = useSelector(getCountriesSuggestions);
  const classes = useStyles();
  const popover = usePopover();
  const label = intl
    .formatMessage({
      id: "CountrySwitcher.allCountries",
    })
    .toUpperCase();
  const noCountryOption = [{ code: null, name: label }];
  const placeholder = intl.formatMessage({
    id: "CountrySwitcher.chooseCountry",
  });

  const onSuggestionSelect = (country: Country) => {
    onCountrySelect(country);
    popover.handlePopoverClose();
  };

  const onSuggestionClear = () => {
    dispatch(resetCountries());
  };

  const handlePopoverClose = () => {
    popover.handlePopoverClose();
    dispatch(resetCountries());
  };

  return (
    <div>
      <CfSwitcherButton
        data-test="country-switcher"
        onClick={popover.handlePopoverOpen}
      >
        <span className={classes.switcherButtonLabel}>
          {!isEmpty(country) && country.code !== null ? country.name : null}
          {country?.code ? null : (
            <FormattedMessage id="CountrySwitcher.allCountries" />
          )}
        </span>
      </CfSwitcherButton>
      <Popover
        anchorEl={popover.anchorEl}
        classes={{ paper: classes.popoverPaper }}
        onClose={handlePopoverClose}
        open={popover.isOpen}
        anchorOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
      >
        <AdminCountrySelector
          getCountries={() => dispatch(getCountries())}
          onSuggestionClear={onSuggestionClear}
          onSuggestionSelect={onSuggestionSelect}
          placeholder={placeholder}
          suggestions={
            suggestions ? noCountryOption.concat(suggestions) : suggestions
          }
        />
      </Popover>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  popoverPaper: {
    width: 350,
    overflow: "hidden",
  },
  switcherButtonLabel: {
    fontSize: 14,
  },
}));

export { CountrySwitcher };
