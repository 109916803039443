import React from "react";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import { useField } from "formik";

import { COLOR_FONT } from "../../../../theme";
import FormErrorMessage from "../FormErrorMessage/FormErrorMessage";

const useStyles = makeStyles(() => ({
  formControlLabel: {
    color: COLOR_FONT.main,
    margin: 0,
    gap: 8,
    alignItems: "center",
    "& .MuiFormControlLabel-label": {
      fontSize: 16,
    },
  },
  checkbox: {
    padding: 9,
  },
}));

type Props = {
  label: React.ReactElement | string;
  name: string;
  externalLabelClasses?: Record<string, string>;
  disabled?: boolean;
};

const FormCheckbox = (props: Props) => {
  const {
    disabled = false,
    externalLabelClasses = {},
    label,
    ...settings
  } = props;
  const fieldProps = useField(props);
  const field = fieldProps[0];
  const form = fieldProps[2];

  const classes = useStyles();

  return (
    <>
      <FormControlLabel
        label={label}
        labelPlacement="end"
        classes={{
          root: classnames(
            classes.formControlLabel,
            externalLabelClasses?.formControlLabel,
          ),
        }}
        control={
          <Checkbox
            {...field}
            checked={field.value ?? false}
            classes={{ root: classes.checkbox }}
            disabled={disabled}
            onChange={(e) => {
              form.setValue(e.target.checked);
            }}
            {...settings}
          />
        }
      />
      <FormErrorMessage name={field.name} />
    </>
  );
};

export default FormCheckbox;
