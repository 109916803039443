import { TableProcessedActionColumn } from "../../../../evidence/actions/shared/components/VariableActionExportsList/types";

import {
  FarmStatisticsTo,
  ParcelEagriInfoTo,
  ParcelDetailTo,
  ParcelSeedApplicationTo,
  CenterTo,
  ParcelTo,
  ParcelPredecessorTo,
  ParcelSuggestionTo,
  CropTo,
  LandUseResponse,
  ParcelGeometryResponse,
  ZoneDetailTo,
  ParcelAreaTo,
} from "../agroevidence.types";

interface Error {
  message: string;
  name: string;
  stack: string;
}

export interface ParcelListAdvancedFilter {
  centers?: CenterTo[];
  crops?: CropTo[];
  landUse?: LandUseResponse[];
  nsa?: NSAState;
  parcel?: ParcelSuggestionTo[];
  zones?: ZoneDetailTo[];
}

export interface ParcelActivitiesListAdvancedFilter {
  activities: ActivitiesState[];
}

export interface ActivitiesState {
  label: string;
  name: string;
}
export interface NSAState {
  label: string;
  state: boolean;
}

export interface ParcelsTypes {
  centers: CenterTo[];
  focusedRow?: string;
  isExportingData: boolean;
  isFetchingCenters: boolean;
  isFetchingItem: boolean;
  isFetchingItemEagriRestrictions: boolean;
  isFetchingItemPredecessors: boolean;
  isFetchingItemSeedApplication: boolean;
  isFetchingItemSubtractionsGeometry: boolean;
  isFetchingItemSubtractionsArea: boolean;
  isFetchingItems: boolean;
  isFetchingItemsSuggestion: boolean;
  isFetchingLandUse: boolean;
  isFetchingStatistics: boolean;
  item: ParcelDetailTo;
  itemEagriRestrictions: ParcelEagriInfoTo;
  itemError?: Error;
  itemPredecessors: ParcelPredecessorTo[];
  itemSeedApplication: ParcelSeedApplicationTo[];
  itemSubtractionsGeometry: ParcelGeometryResponse;
  itemSubtractionsArea: ParcelAreaTo;
  items: ParcelTo[];
  itemsError?: Error;
  itemsSuggestion: ParcelSuggestionTo[];
  landUse: LandUseResponse[];
  statistics: FarmStatisticsTo;
  totalCount: number;
}

export enum SEASON_STATE {
  CURRENT,
  FUTURE,
}

export interface TableProcessedParcelAction {
  id: string;
  isDraft?: boolean;
  route: string;
  single: {
    "dateStart,id": TableProcessedActionColumn;
    "actionType.localizedNames.value": TableProcessedActionColumn;
    source: TableProcessedActionColumn;
    activityCrop: TableProcessedActionColumn;
    area: TableProcessedActionColumn;
    accountableN?: TableProcessedActionColumn;
    material: TableProcessedActionColumn;
    dose: TableProcessedActionColumn;
    dosePerHectare: TableProcessedActionColumn;
    n: TableProcessedActionColumn;
    p2o5: TableProcessedActionColumn;
    k2o: TableProcessedActionColumn;
    mgo: TableProcessedActionColumn;
    cao: TableProcessedActionColumn;
    s: TableProcessedActionColumn;
  };
  multi: {
    material: TableProcessedActionColumn;
    dose: TableProcessedActionColumn;
    dosePerHectare: TableProcessedActionColumn;
    n: TableProcessedActionColumn;
    p2o5: TableProcessedActionColumn;
    k2o: TableProcessedActionColumn;
    mgo: TableProcessedActionColumn;
    cao: TableProcessedActionColumn;
    s: TableProcessedActionColumn;
  }[];
}
