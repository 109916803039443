import React from "react";

import { Box, styled } from "@mui/material";

export const CfChartTooltipWrapper = ({
  children,
}: React.PropsWithChildren<unknown>) => (
  <Wrapper>
    <div>{children}</div>
  </Wrapper>
);

const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: 3,
  WebkitBorderRadius: 3,
  MozBorderRadius: 3,
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
  WebkitBoxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
  MozBoxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
  padding: 0,
}));
