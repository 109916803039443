import React, { FC } from "react";

import { LoadingButton } from "@mui/lab";
import { Theme } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import HideButton from "./HideButton";

interface Props {
  isNew: boolean;
  onHide?: () => void;
  onHideTitleId?: string;
  onHideContextId?: string;
}

const Buttons: FC<Props> = ({
  isNew,
  onHide,
  onHideContextId,
  onHideTitleId,
}) => {
  const classes = useStyles();
  return (
    <Grid
      alignItems="center"
      className={classes.buttons}
      container
      justifyContent="center"
      spacing={1}
    >
      {!isNew && onHide && (
        <HideButton
          contextId={onHideContextId}
          onHide={onHide}
          titleId={onHideTitleId}
        />
      )}
      <Grid item>
        <Button id="cancel" type="reset" variant="contained">
          <FormattedMessage id="common.cancel" />
        </Button>
      </Grid>
      <Grid item>
        <LoadingButton
          color="primary"
          id="create"
          type="submit"
          variant="contained"
        >
          <FormattedMessage id={isNew ? "common.create" : "common.save"} />
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default Buttons;

const useStyles = makeStyles((theme: Theme) => ({
  buttons: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));
