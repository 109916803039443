import { defaults as defaultControls } from "ol/control";
import Map from "ol/Map";
import { fromLonLat, transform } from "ol/proj";
import View from "ol/View";

import {
  getZoomedExtent,
  transformWithValidation,
} from "../../../shared/misc/map.helpers";

import Geometry from "./geometry/Geometry.service";

export default class MapService {
  constructor(mapId, farmId, farmBbox, transformOptions, restOptions = {}) {
    this.farmId = farmId;
    this.transformOptions = transformOptions;
    this.farmExtent = this.calculateGeometryExtent(farmBbox);
    this.restOptions = restOptions;
    this.map = this.setMap(mapId);
  }

  setMap(mapId) {
    const map = new Map({
      target: mapId,
      view: new View({
        center: [0, 0],
        zoom: 2,
        minZoom: 5,
        maxZoom: 26,
        enableRotation: false,
      }),
      controls: defaultControls({
        attributionOptions: {
          collapsible: false,
        },
      }),
      ...this.restOptions,
    });
    return map;
  }

  getMap() {
    return this.map;
  }

  getResolution() {
    return this.map.getView().getResolution();
  }

  getTransformOptions() {
    return this.transformOptions;
  }

  getFarmExtent() {
    return this.farmExtent;
  }

  calculateGeometryExtent(geometry) {
    return Geometry.readGeometry(geometry, this.transformOptions).getExtent();
  }

  getFarmId() {
    return this.farmId;
  }

  zoomToFarm() {
    this.zoomToExtent(this.farmExtent);
  }

  zoomToGeometry(geometry, scaleFactor) {
    this.zoomToExtent(this.calculateGeometryExtent(geometry), scaleFactor);
  }

  getExtentFromLayer(layer) {
    if (layer.getSource().getFeatures().length > 0) {
      return layer.getSource().getExtent();
    }
    return null;
  }

  zoomToExtent(extent, scaleFactor = 0.9) {
    let newExtent = extent;
    if (scaleFactor) {
      newExtent = getZoomedExtent(extent, scaleFactor);
    }

    this.map.getView().fit(newExtent, {
      duration: 250,
    });
  }

  zoomToCoords(coords, zoom = 14, duration = 250) {
    this.map
      .getView()
      .animate({ center: fromLonLat([coords[0], coords[1]]), zoom, duration });
  }

  setCursor(value) {
    this.map.getViewport().style.cursor = value;
  }

  getCenterCoordinates() {
    return this.map.getView().getCenter();
  }

  transformCoords(coordinates, fromEpsg, toEpsg) {
    const [t] = transformWithValidation(coordinates, fromEpsg, toEpsg, true);
    return t;
  }

  updateSize() {
    this.map.updateSize();
  }

  static transformToWgs = (coords) =>
    transform(coords, "EPSG:3857", "EPSG:4326");
  static transformFromWgs = (coords) =>
    transform(coords, "EPSG:4326", "EPSG:3857");

  static transformCoordinates(coord) {
    return this.transformFromWgs(coord);
  }

  static traverseAndTransformCoordinates(arr) {
    return arr.map((item) => {
      if (Array.isArray(item)) {
        if (typeof item[0] === "number" && item.length === 2) {
          // This is a coordinate.
          return this.transformCoordinates(item);
        } else {
          // This is a nested array.
          return this.traverseAndTransformCoordinates(item);
        }
      }
      return item;
    });
  }
}
