import React, { Fragment } from "react";

import { IconButton, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import ToolbarSection from "../../../../shared/components/specific/ToolbarSection/ToolbarSection";
import BufferIcon from "../../../../shared/icons/mapTools/BufferIcon";
import DrawIcon from "../../../../shared/icons/mapTools/DrawIcon";
import MergeIcon from "../../../../shared/icons/mapTools/MergeIcon";
import SplitIcon from "../../../../shared/icons/mapTools/SplitIcon";

const EditorToolbar = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <ToolbarSection>
        <Tooltip
          title={<FormattedMessage id="ToolbarIconBtn.Geometry-tooltip" />}
        >
          <span>
            <IconButton classes={{ root: classes.root }} disabled>
              <DrawIcon />
              <BufferIcon />
              <MergeIcon />
              <SplitIcon />
            </IconButton>
          </span>
        </Tooltip>
      </ToolbarSection>
    </Fragment>
  );
};

export { EditorToolbar };

const useStyles = makeStyles(() => ({
  root: {
    margin: "0 5px",
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));
