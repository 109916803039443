import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import farmsAdminUI from "../admin/manager/farms/reducer/farms.reducer";
import irrigationAdminUI from "../admin/manager/irrigation/reducer/admin.devices.reducer";
import notificationsAdminUI from "../admin/manager/notifications/reducer/notifications.reducer";
import sensorsAdminUI from "../admin/manager/sensors/reducer/sensors.reducer";
import sentinelAdminUI from "../admin/manager/sentinel/reducer/sentinelAdmin.reducer";
import sentinelAdminStatsUI from "../admin/manager/sentinel/reducer/sentinelAdminStats.reducer";
import adminManagerUI from "../admin/manager/shared/reducer/manager.reducer";
import telematicsAdminUI from "../admin/manager/telematics/reducer/telematicsAdmin.reducer";
import farmsApi from "../shared/api/agroevidence/farms/farms.reducer";
import dataApi from "../shared/api/api.reducer";
import notificationsAdminApi from "../shared/api/automation/notificationsAdmin/notificationsAdmin.reducer";
import authReducer from "../shared/api/gateway/auth/auth.reducer";
import nodesApi from "../shared/api/iot/nodes/nodes.reducer";
import sensorsApi from "../shared/api/iot/sensors/sensors.reducer";
import devicesApi from "../shared/api/irrigation/devices/devices.reducer";
import sentinelAdminApi from "../shared/api/sentinel/admin/admin.reducer";
import telematicsAdminApi from "../shared/api/telematics/admin/telematicsAdmin.reducer";

export default (history) =>
  combineReducers({
    ui: combineReducers({
      manager: adminManagerUI(),
      iot_admin: sensorsAdminUI(),
      sentinel_admin: sentinelAdminUI(),
      sentinel_admin_stats: sentinelAdminStatsUI(),
      notifications_admin: notificationsAdminUI(),
      irrigation_admin: irrigationAdminUI(),
      telematics: telematicsAdminUI(),
      farmsAdmin: farmsAdminUI(),
      cache: () => [
        "iot_admin",
        "sentinel_admin",
        "notifications_admin",
        "irrigation_admin",
        "telematics",
      ],
    }),
    api: combineReducers({
      nodes: nodesApi(),
      sensors: sensorsApi(),
      devices: devicesApi(),
      farms: farmsApi(),
      notificationsAdmin: notificationsAdminApi(),
      sentinelParcels: sentinelAdminApi("table"),
      parcelsSuggestions: sentinelAdminApi("suggestions"),
      telematics: telematicsAdminApi,
      farmsAdmin: dataApi("AGROEVIDENCE_FARMS", "GET_FARMS", {
        onSuccess: (state, action) => ({
          ...state,
          totalCount: Number(action.meta.headers.get("X-Total-Count")),
        }),
      }),
    }),
    form: formReducer,
    router: connectRouter(history),
    auth: authReducer,
  });
