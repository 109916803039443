import React, { Fragment, useEffect, useState } from "react";

import { FormikProps, useField } from "formik";
import { useDispatch, useSelector } from "react-redux";

import {
  getEphAccountableNitrogen,
  getEphTargetSeedApplication,
} from "../../../../../shared/api/agroevidence/actions/actions.selectors";

import { getEphAccountableNitrogenApi } from "../../../../../shared/api/agroevidence/actions/actions.api";
import { useTypedIntl } from "../../../../../shared/hooks/useTypedIntl";
import {
  analyzLegislationCheckByParcels,
  parsePlantProtectionApplicationData,
  parsePlantProtectionsApplicationRestriction,
  analyzePlantProtectionApplication,
  mapRequestBodyEphAccountableNitrogenTo,
} from "../../../ActionEph/actionEph.services";

import { ParcelsListItem } from "./ParcelsListItem";

import { TargetSeedApplicationFertilizationRequestTo } from "../../../../../shared/api/agroevidence/agroevidence.types";
import {
  ActionEphFormValues,
  PlantProtectionRestrictionWarningsType,
  PlantProtectionApplicationDataByParcelsType,
  PlantProtectionApplicationRestrictionType,
} from "../../../ActionEph/actionEph.types";
import { InitialParcelToAdd } from "../../../ActionOthers/actionOther.types";

type Props = {
  actionDate: string;
  allMustBeSown: boolean;
  parcelsInForm: InitialParcelToAdd[];
  form: FormikProps<ActionEphFormValues>;
  formType: string;
  handleRemove: (index: number) => void;
  isDeleting: boolean;
  isDraft: boolean;
  isEditing: boolean;
  isExisting: boolean;
  isEphAction: boolean;
  updateParcelsActionArea: (parcelsInForm: InitialParcelToAdd[]) => void;
};

interface PlantProtectionRestrictionWarningsByParcelsType {
  [parcelId: string]: PlantProtectionRestrictionWarningsType[];
}

export const ParcelsList = ({
  actionDate,
  allMustBeSown,
  form,
  formType,
  handleRemove,
  isDeleting,
  isDraft,
  isEditing,
  isEphAction = false,
  isExisting,
  parcelsInForm,
  updateParcelsActionArea,
}: Props) => {
  const { locale } = useTypedIntl();
  const dispatch = useDispatch();

  const ephTargetSeedApplication = useSelector(getEphTargetSeedApplication);
  const ephAccountableNitrogen = useSelector(getEphAccountableNitrogen);

  const [plantProtections] = useField("plantProtections");
  const [fertilizers] = useField("fertilizers");

  const [
    historyPlantProtectionApplicationData,
    setHistoryPlantProtectionApplicationData,
  ] = useState<PlantProtectionApplicationDataByParcelsType | undefined>(
    undefined,
  );
  const [
    plantProtectionsApplicationRestriction,
    setPlantProtectionsApplicationRestriction,
  ] = useState<PlantProtectionApplicationRestrictionType | undefined>(
    undefined,
  );
  const [complianceResults, setComplianceResults] = useState<
    PlantProtectionRestrictionWarningsByParcelsType | undefined
  >(undefined);

  useEffect(() => {
    if (
      isEphAction &&
      parcelsInForm.length > 0 &&
      ephTargetSeedApplication &&
      ephTargetSeedApplication.length > 0 &&
      fertilizers.value.length > 0
    ) {
      const param = mapRequestBodyEphAccountableNitrogenTo(
        parcelsInForm,
        fertilizers.value,
        form.values.isStrawDecay,
        locale,
        ephTargetSeedApplication,
      );
      dispatch(
        getEphAccountableNitrogenApi(
          param as TargetSeedApplicationFertilizationRequestTo,
        ),
      );
    }
  }, [
    dispatch,
    ephTargetSeedApplication,
    fertilizers.value,
    form.values.isStrawDecay,
    isEphAction,
    locale,
    parcelsInForm,
  ]);

  useEffect(() => {
    if (
      isEphAction &&
      actionDate &&
      ephTargetSeedApplication &&
      ephTargetSeedApplication.length > 0 &&
      plantProtections.value.length > 0
    ) {
      const data = parsePlantProtectionApplicationData(
        ephTargetSeedApplication,
        actionDate,
      );
      setHistoryPlantProtectionApplicationData(data);
    }
  }, [
    actionDate,
    plantProtections.value,
    isEphAction,
    ephTargetSeedApplication,
  ]);

  useEffect(() => {
    if (isEphAction && plantProtections.value.length > 0) {
      const restriction = parsePlantProtectionsApplicationRestriction(
        plantProtections.value,
      );
      setPlantProtectionsApplicationRestriction(restriction);
    }
  }, [isEphAction, plantProtections.value]);

  useEffect(() => {
    if (isEphAction) {
      const results: PlantProtectionRestrictionWarningsByParcelsType = {};
      parcelsInForm.forEach((parcel) => {
        const parcelHistoryData = historyPlantProtectionApplicationData
          ? historyPlantProtectionApplicationData[parcel?.id]
          : undefined;
        if (parcelHistoryData && plantProtectionsApplicationRestriction) {
          results[parcel?.id] = analyzePlantProtectionApplication(
            parcelHistoryData,
            plantProtectionsApplicationRestriction,
          );
        }
      });
      setComplianceResults(results);
    }
  }, [
    historyPlantProtectionApplicationData,
    plantProtectionsApplicationRestriction,
    parcelsInForm,
    isEphAction,
  ]);

  const legislationCheckByParcels = analyzLegislationCheckByParcels(
    ephTargetSeedApplication || [],
    parcelsInForm,
  );

  return (
    <Fragment>
      {parcelsInForm.length > 0 &&
        parcelsInForm.map((parcel, index) => {
          // for EPH form: plant protection restriction warning by parcel
          const complianceResult = complianceResults?.[parcel.id];

          const isLegislationCheckWarning = ephTargetSeedApplication
            ? !legislationCheckByParcels[parcel.id]
            : false;

          const accountableNitrogen = ephTargetSeedApplication?.find(
            (item) => item.parcelId === parcel.id,
          )?.accountableNitrogen;

          const expectedAccountableNitrogen = ephAccountableNitrogen.find(
            (item) => item.parcelId === parcel.id,
          )?.accountableNitrogen;

          return (
            <ParcelsListItem
              accountableNitrogen={accountableNitrogen}
              allMustBeSown={allMustBeSown}
              complianceResult={complianceResult}
              expectedAccountableNitrogen={expectedAccountableNitrogen}
              form={form}
              formType={formType}
              handleRemoveItem={() => handleRemove(index)}
              index={index}
              isDeleting={isDeleting}
              isDraft={isDraft}
              isEditing={isEditing}
              isEphAction={isEphAction}
              isExisting={isExisting}
              key={parcel?.id}
              parcel={parcel}
              parcelsInForm={parcelsInForm}
              updateParcelsActionArea={updateParcelsActionArea}
              isLegislationCheckWarning={
                isEphAction ? isLegislationCheckWarning : false
              }
            />
          );
        })}
    </Fragment>
  );
};
