import React from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Theme } from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";

import usePopover from "../../../../../shared/hooks/usePopover";

const BulkActions = ({
  children,
  selected,
}: React.PropsWithChildren<{
  selected: number;
}>) => {
  const popover = usePopover();
  const classes = useStyles();

  return (
    <>
      <Button
        disabled={selected === 0}
        className={classNames(classes.button, {
          [classes.activeButton]: selected > 0,
        })}
        onClick={(evt) => {
          popover.handlePopoverOpen(evt);
        }}
      >
        <span className={classes.selectedLabel}>
          <FormattedMessage id="common.selected" />
          {selected > 0 && `\u00A0(${selected})`}
        </span>
        <ArrowDropDownIcon className={classes.rightIcon} />
      </Button>

      <Menu
        anchorEl={popover.anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        id="parcel list actions menu"
        onClick={popover.handlePopoverClose}
        open={popover.isOpen}
      >
        {children}
      </Menu>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    paddingLeft: 12,
    paddingRight: 12,
  },
  activeButton: {
    background: theme.palette.grey[100],
    color: theme.palette.primary.main,
    paddingRight: 6,
    marginRight: 8,
  },
  rightIcon: {
    position: "relative",
    top: -1,
  },
  selectedLabel: {
    paddingTop: 2,
  },
}));

export { BulkActions };
