import React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

type Props = {
  expanded: boolean;
  handleExpansion: () => void;
  testId: string;
};

function MapSwitcherHeader({
  children,
  expanded,
  handleExpansion,
  testId,
}: React.PropsWithChildren<Props>) {
  const classes = useStyles();

  return (
    <div
      data-test={`${testId}-header`}
      className={`${classes.layersHeader} ${
        expanded ? classes.expanded : classes.notExpanded
      }`}
    >
      {React.Children.only(children)}
      <IconButton
        onClick={handleExpansion}
        size="large"
        className={`${classes.expandButton} ${
          expanded ? classes.expandOpen : ""
        }`}
      >
        <ExpandMoreIcon />
      </IconButton>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  layersHeader: {
    padding: "4px 6px 4px 16px",
    display: "flex",
    justifyContent: "space-between",
    fontWeight: 500,
  },
  expanded: {
    backgroundColor: theme.palette.grey[100],
  },
  notExpanded: {
    backgroundColor: theme.palette.common.white,
  },
  expandButton: {
    width: 32,
    height: 32,
    padding: 0,
    alignSelf: "center",
    transform: "rotate(0deg)",
    color: theme.palette.grey[500],
    // transition: theme.transitions.create("transform", {
    //   duration: theme.transitions.duration.instant,
    // }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

export default MapSwitcherHeader;
