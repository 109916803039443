import React from "react";

import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import useToggles from "../../../../shared/toggles/toggles";
import { MenuItemType } from "../../services/menuItems";
import NavbarService from "../../services/NavbarService";

import { useMainMenuStyles } from "./MainMenu.styles";

interface Props {
  menuItem: MenuItemType;
  farmId: string;
  isActive: boolean;
  mobile?: boolean;
  onMenuItemClick?: () => void;
}

const MainMenuItem = ({
  farmId,
  isActive,
  menuItem,
  mobile = false,
  onMenuItemClick,
}: Props) => {
  const classes = useMainMenuStyles();
  const history = useHistory();
  const [, , isToggleActive] = useToggles();
  const Icon = NavbarService.getMenuItemIcon(menuItem.id);

  if (menuItem.toggleId && !isToggleActive(menuItem.toggleId)) {
    return null;
  }

  const handleClick = () => {
    if (onMenuItemClick) {
      onMenuItemClick();
    }
    history.push(`/farm/${farmId}/${menuItem.route}`);
    history.go(0);
  };

  // todo: "a" is temporary
  // change to "<NavLink to=" after remove angular and updating react-router-dom to version 6 (or higher)
  return (
    <a
      href={`/farm/${farmId}/${menuItem.route}`}
      id={`main-menu-${menuItem.id}`}
      onClick={handleClick}
      product-fruits={mobile ? undefined : `${menuItem.id}`}
      className={`${classes.menuItemLink} ${
        mobile ? classes.menuItemLinkMobile : ""
      }`}
      style={{
        color: isActive ? "#FFFFFF" : undefined,
        backgroundColor: isActive ? "rgba(255, 255, 255, 0.15)" : undefined,
      }}
    >
      <span
        className={
          mobile ? classes.navlinkContentMobile : classes.navlinkContentDesktop
        }
      >
        {mobile && <Icon className={classes.navlinkIcon} />}
        <FormattedMessage id={menuItem.translationId} />
      </span>
    </a>
  );
};

export default MainMenuItem;
