import React from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import CfLabel from "../../../../../../shared/components/common/CfLabel/CfLabel";
import EditableText from "../../../../../../shared/components/common/EditableText/EditableText";
import PageHeading from "../../../../../../shared/components/common/PageHeading/PageHeading";
import { ParcelsService } from "../../../../shared/services/Parcels.service";

import {
  ParcelDetailTo,
  ParcelPredecessorTo,
} from "../../../../../../shared/api/agroevidence/agroevidence.types";

interface Props {
  confirmEditing: (newName: string) => void;
  historicalParcel: ParcelPredecessorTo | null;
  isFetchingParcel: boolean;
  parcel: ParcelDetailTo;
}

const ParcelDetailHeader = ({
  confirmEditing,
  historicalParcel,
  isFetchingParcel = false,
  parcel,
}: Props) => {
  const classes = useStyles();

  const isHistorical = ParcelsService.isParcelHistorical(parcel);
  return (
    <PageHeading
      dataTest="parcelDetail-heading"
      value={
        <div className={classes.heading}>
          <div className={classes.parcelName}>
            <EditableText
              labelTestId="parcel-name"
              onConfirmEditing={(newValue) => confirmEditing(newValue)}
              initialValue={
                isFetchingParcel && historicalParcel
                  ? historicalParcel.localName
                  : parcel.localName
              }
            />
          </div>
          <div className={classes.blockNumber}>
            {isFetchingParcel && historicalParcel
              ? historicalParcel.blockNumber
              : parcel.blockNumber}
          </div>
          {isHistorical && (
            <CfLabel
              classes={{ label: classes.headingLabel }}
              message={<FormattedMessage id="ParcelDetail.historicalParcel" />}
            />
          )}
        </div>
      }
    />
  );
};

export { ParcelDetailHeader };

const useStyles = makeStyles((theme: Theme) => ({
  headingLabel: {
    padding: "3px 6px",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontSize: 14,
    marginTop: 6,
  },
  heading: {
    display: "flex",
    flexDirection: "column",
  },
  blockNumber: {
    color: theme.palette.grey[500],
    fontSize: 18,
  },
  parcelName: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 4,
  },
}));
