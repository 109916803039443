import React from "react";

import { Popper } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";

import { getMainMapTelematicsDashboardMachinesPositions } from "../../../../shared/api/telematics/mainMapTelematics/dashboard/machinesDashboard.selectors";

import {
  resetDashboardDrivesHistory,
  setDashboardMachineIsOpen,
  setDashboardMachinesReset,
} from "../../../../shared/api/telematics/mainMapTelematics/dashboard/machinesDashboard.actions";

import ToolbarIconBtn from "../../../../shared/components/specific/ToolbarIconBtn/ToolbarIconBtn";
import ToolbarSection from "../../../../shared/components/specific/ToolbarSection/ToolbarSection";
import usePopover from "../../../../shared/hooks/usePopover";

import MachinesTableWrapper from "./MachinesTableWrapper";
import { TractorWifiIcon } from "./TractorWifiIcon";

type Props = {
  onOpen: () => void;
};

const MachinesDashboard = ({ onOpen }: Props) => {
  const classes = useStyles();
  const popover = usePopover();
  const dispatch = useDispatch();
  const machines = useSelector(getMainMapTelematicsDashboardMachinesPositions);

  const handleClose = () => {
    dispatch(setDashboardMachineIsOpen(false));
    dispatch(setDashboardMachinesReset());
    dispatch(resetDashboardDrivesHistory());
    popover.handlePopoverClose();
  };

  return (
    <div className={classes.wrapper}>
      <ToolbarSection>
        <ToolbarIconBtn
          active={popover.isOpen}
          data-test="machine-dashboard"
          icon={(p) => <TractorWifiIcon {...p} />}
          product-fruits="machine-dashboard"
          tooltipTitle="MainMapTelematics.machinesDashboard.header.title"
          withBackground
          callback={(e) => {
            if (!popover.isOpen) {
              dispatch(setDashboardMachineIsOpen(true));
              onOpen();
              popover.handlePopoverOpen(e);
            } else {
              handleClose();
            }
          }}
        />
      </ToolbarSection>
      <Popper
        anchorEl={popover.anchorEl}
        className={classes.popper}
        open={popover.isOpen}
      >
        <MachinesTableWrapper
          handleClose={handleClose}
          machines={machines}
          testId="dashb-machines"
        />
      </Popper>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  popper: {
    background: theme.palette.common.white,
    borderRadius: 4,
    top: "20px !important",
    [theme.breakpoints.up("sm")]: {
      left: "13px !important",
    },
  },
  separator: {
    height: 1,
    backgroundColor: theme.palette.grey[100],
  },
  wrapper: {
    marginRight: 8,
  },
}));

export default MachinesDashboard;
