import React, { Component, Fragment } from "react";

import Grid from "@mui/material/Grid";
import differenceBy from "lodash/differenceBy";
import startCase from "lodash/startCase";
import PropTypes from "prop-types";
import { compose } from "react-recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { formValueSelector } from "redux-form";

import { selectSoilSamplesDataForVRF } from "../../../../core/precision/selectors/soilSamples.selectors";
import { getActionRestrictions } from "../../../../shared/api/agroevidence/actions/actions.selectors";
import {
  getIsFetchingParcel,
  getParcel,
} from "../../../../shared/api/agroevidence/parcels/parcels.selectors";
import { selectSoilSamplesIsFetching } from "../../../../shared/api/sentinel/soilSamples/soilSamples.selectors";
import {
  getIsExportingVA,
  getItems as getVariableFertilizationParcels,
  getVariableActionZones,
} from "../../../../shared/api/sentinel/variableApplication/variableApplication.selectors";

import {
  updateParcelGeometry,
  updateFertilizationType,
  updateVariableExpense,
  resetParcelSubtractionsGeometry,
} from "../actions/actions.actions";

import { indicesList } from "../../../../core/precision/selectors/indices";
import { MONITORING_STATUS } from "../../../../core/precision/selectors/monitoringStatus";
import {
  getParcelApi,
  resetParcelApi,
} from "../../../../shared/api/agroevidence/parcels/parcels.api";
import {
  getMonitoringDataApi,
  resetMonitoringData,
} from "../../../../shared/api/sentinel/monitoring/monitoring.api";
import { getPrecisionParcelApi } from "../../../../shared/api/sentinel/precision/precision.api";
import {
  getSoilSamplesDateApi,
  resetSoilSamplesDate,
} from "../../../../shared/api/sentinel/soilSamples/soilSamples.api";
import {
  getVariableParcelIds,
  resetVariableParcelIds,
  getVariableActionExpenses,
  getVariableActionZonesApi,
  resetVariableActionZones,
} from "../../../../shared/api/sentinel/variableApplication/variableApplication.api";
import CfErrorPage from "../../../../shared/components/common/CfErrorPage/CfErrorPage";
import CfLoader from "../../../../shared/components/common/CfLoader/CfLoader";
import SidebarMapWrapper from "../../../../shared/components/specific/SidebarMapWrapper/SidebarMapWrapper";
import { getDisplayName } from "../../../../shared/hocs/hocHelpers";
import VariableFertilizationExpired from "../../vrf/components/VariableFertilizationExpired/VariableFertilizationExpired";
import VariableFertilizationMap from "../../vrf/containers/VariableFertilizationMap/VariableFertilizationMap";
import ActionFormButtons from "../components/ActionFormButtons/ActionFormButtons";
import ActionFormHeader from "../components/ActionFormHeader/ActionFormHeader";
import { ActionRestrictionsInfo } from "../components/ActionRestrictionsInfo/ActionRestrictionsInfo";
import {
  parcelsCountChanged,
  resolveTargetCrop,
  expenseCountChanged,
  parseVarActionToSavi,
} from "../misc/action.helpers";

import { IndexType } from "../../../../shared/api/satellite/satellite.types";

const monitoringDataTypes = indicesList.map((i) => i.id);
const samplesDataTypes = [IndexType.SAMPLES];

const variableActionForm =
  ({ getApiError, getCreateVaIsLoading, getIsLoading, onExport }) =>
  (WrappedComponent) => {
    class VariableActionForm extends Component {
      constructor(props) {
        super(props);

        this.state = {
          // stands for VaRiableApplication
          isVraAllowed: false,
          // we have to make sure we display WrappedComponent
          // after all init data have been fetched (prop `isLoading`)
          isDataLoaded: false,
          currMapZones: null,
          satelliteDataTypes: [],
          initSatelliteData: {},
          satellites_isFetched: false,
        };
      }

      componentDidMount() {
        this.props.getVariableParcelIds().then((res) => {
          if (!res.error) {
            this.setState({
              isVraAllowed: Boolean(res.payload.length),
            });
          }
        });

        const { existingAction } = this.props;
        if (existingAction) {
          this.fetchVariableExpenses(existingAction.id);
        }
      }

      componentDidUpdate(prevProps) {
        const {
          expenses: prevExpenses,
          geometry: prevGeometry,
          isLoading: prevIsLoading,
          isSoilSamplesDataLoading: prevIsSoilSamplesDataLoading,
          parcels: prevParcels,
          satellite: prevSatellite,
          soilSamplesData: prevSoilSamplesData,
          targetCrop: prevTargetCrop,
        } = prevProps;

        const {
          existingAction: newExistingAction,
          expenses: newExpenses,
          fieldsManuallyChanged: newFieldsManuallyChanged,
          formName: newFormName,
          geometry: newGeometry,
          isLoading: newIsLoading,
          isSoilSamplesDataLoading: newIsSoilSamplesDataLoading,
          minMappingUnit: newMinMappingUnit,
          parcels: newParcels,
          satellite: newSatellite,
          soilSamplesData: newSoilSamplesData,
          targetCrop: newTargetCrop,
        } = this.props;

        const { satellites_isFetched: newSatellites_isFetched } = this.state;

        // Comparison booleans to detect the change
        const targetCropManualDiff = Boolean(
          newFieldsManuallyChanged.targetCrop,
        );
        const parcelsCountDiff = parcelsCountChanged(prevParcels, newParcels);
        const resolvedTargetCrop = resolveTargetCrop(newParcels);
        const expenseCountDiff = expenseCountChanged(prevExpenses, newExpenses);

        this.isSatelliteDataFetched();

        if (newIsLoading !== prevIsLoading) {
          this.setState({
            isDataLoaded: !newIsLoading,
          });
        }

        if (!newIsSoilSamplesDataLoading && prevIsSoilSamplesDataLoading) {
          this.setState({
            [`${samplesDataTypes[0]}_isFetched`]: true,
          });
        }

        if (prevSoilSamplesData !== newSoilSamplesData) {
          this.setState({
            [samplesDataTypes[0]]: newSoilSamplesData,
          });
        }

        /*
         * targetCrop RULES
         */

        /*
         * Touch target crop in order to get validated. Do when:
         * 1) prev target crop was not set
         * 2) new target crop is set
         * 3) action is already existing
         */
        if (
          newExistingAction &&
          !prevTargetCrop &&
          prevTargetCrop !== newTargetCrop
        ) {
          this.props.touch("targetCrop");
        }

        /*
         * Update target crop when:
         * 1) parcels count changed
         * 2) and resolved target crop is set
         * 3) and new target crop doesnt is not set or is not manually edited yet
         * 5) or resolved target crop id does not equal new target crop id
         * 6) wtf is this ... ?!
         */
        if (
          !targetCropManualDiff &&
          !newTargetCrop &&
          parcelsCountDiff &&
          newParcels.length
        ) {
          if (
            resolvedTargetCrop &&
            resolvedTargetCrop.id !== newTargetCrop?.id
          ) {
            this.props.change("targetCrop", resolvedTargetCrop);
          }
        }

        /*
         * Set target crop to null when:
         * 1) parcels count changed
         * 2) and there are no parcels
         * 3) and target crop was not changed manually yet
         */
        if (parcelsCountDiff && !newParcels.length && !targetCropManualDiff) {
          this.props.change("targetCrop", null);
          this.props.untouch("targetCrop");
        }

        /*
         * minMappingUnit & zonesCount RULES
         */

        /*
         * Set MMU and zonesCount when:
         * 1) expense was added
         * 2) MMU is not set yet
         */
        if (
          newExpenses.length &&
          newExpenses[0]?.variableExpense?.mmu &&
          !newMinMappingUnit
        ) {
          this.props.change(
            "minMappingUnit",
            newExpenses[0].variableExpense.mmu,
          );
          this.props.change(
            "zonesCount",
            newExpenses[0].variableExpense.applicationZones.length,
          );
        }

        /*
         * Reset MMU and zonesCount when:
         * 1) expenses were removed
         */
        if (expenseCountDiff && !newExpenses.length) {
          this.props.change("minMappingUnit", null);
          this.props.change("zonesCount", null);
        }

        /*
         * variabilities RULES
         */

        /*
         * Fetch variabilities when:
         * 1) new parcel is added; only one parcel can be added
         */
        if (parcelsCountDiff && newParcels.length) {
          const difference = differenceBy(newParcels, prevParcels, "id");
          const parcel = difference[0];
          this.props.getParcelApi(parcel.id);

          // loading of satellite data should be moved to `VaMapSourceDialog` and called when it is open
          this.props
            .getPrecisionParcelApi(parcel.id)
            .then((res) => {
              const resIndices = [...res.payload.activeBiomonitoringIndices];

              if (res.payload.soilSamples) {
                resIndices.push(IndexType.SAMPLES);
                samplesDataTypes.forEach(() => {
                  this.props.getSoilSamplesDateApi(parcel.id);
                });
              }

              if (
                res.payload.managementZones &&
                !res.payload.activeBiomonitoringIndices.includes(IndexType.SAVI)
              ) {
                return this.getSaviZones(parcel, IndexType.SAVI).then(() => {
                  resIndices.push(IndexType.SAVI);
                  return resIndices;
                });
              }

              return resIndices;
            })
            .then((resIndices) => {
              this.setSatelliteDataTypes(resIndices, newParcels);
            });
        }

        /*
         * Set zonesCount & satellite to nulls, expenses to [] when:
         * 1) parcels count changed
         * 2) and there are no parcels
         */
        if (parcelsCountDiff && !newParcels.length) {
          this.props.resetSoilSamplesDate();
          this.props.resetMonitoringData();
          this.props.resetParcelApi();
          this.props.resetParcelSubtractionsGeometry();
          this.props.resetVariableActionZones();
        }

        if (
          newExistingAction &&
          newSatellites_isFetched &&
          newExpenses.length &&
          newExpenses[0]?.variableExpense &&
          !newSatellite &&
          !prevSatellite
        ) {
          const variableExpense = newExpenses[0].variableExpense;

          const appZonesAvailable =
            Array.isArray(variableExpense.applicationZones) &&
            variableExpense.applicationZones.length;
          const satellite = appZonesAvailable
            ? {
                ...variableExpense.index,
                zones: variableExpense.applicationZones,
              }
            : variableExpense.index;
          this.props.change("satellite", satellite);
        }

        /*
         * expenses RULES
         */

        /*
         * Reset expenses when:
         * 1) satellite was removed
         */
        if (prevSatellite !== newSatellite && !newSatellite) {
          this.props.change("expenses", []);
        }

        /*
         * geometry RULES
         */
        if (prevGeometry !== newGeometry && newGeometry) {
          this.props.updateParcelGeometry(newGeometry, 0, newFormName);
        }
      }

      componentWillUnmount() {
        this.props.resetMonitoringData();
        this.props.resetVariableParcelIds();
        this.props.resetParcelApi();
        this.props.resetVariableActionZones();
        this.props.onReset();
      }

      setCurrMapZones = (currMapZones) => {
        this.setState({ currMapZones });
      };

      isSatelliteDataFetched() {
        let res = true;
        this.state.satelliteDataTypes.forEach((type) => {
          if (!this.state[[`${type}_isFetched`]]) {
            res = false;
          }
        });

        if (this.state.satellites_isFetched !== res) {
          this.setState({
            satellites_isFetched: res,
          });
        }
      }

      setSatelliteDataTypes(satelliteDataTypesProp, parcels) {
        const satelliteDataTypes = satelliteDataTypesProp.filter(
          (t) => t !== IndexType.CW,
        );
        const initSatelliteData = {};
        satelliteDataTypes.forEach((type) => {
          initSatelliteData[type] = null;
          initSatelliteData[`${type}_isFetched`] = false;
        });

        this.setState(
          {
            ...initSatelliteData,
            satelliteDataTypes,
          },
          () => {
            if (parcels && parcels.length > 0) {
              this.fetchMonitoringData(parcels[0]);
            }
          },
        );
      }

      fetchVariableExpenses(actionId) {
        this.props.getVariableActionExpenses(actionId).then((res) => {
          if (!res.error) {
            const { expenses, fertilizationType, formName } = this.props;
            let indexError = false;
            res.payload.forEach((va) => {
              const expense = expenses.find(
                (e) => e.material.id === va.materialId,
              );
              const index = expenses.indexOf(expense);
              if (index !== -1) {
                if (!fertilizationType.id && va.type) {
                  this.props.updateFertilizationType(formName, { id: va.type });
                }
                this.setCurrMapZones(va?.applicationZones || null);
                this.props.updateVariableExpense(va, index, formName);
              } else {
                indexError = true;
              }
            });
            if (indexError) {
              throw new Error("Unable to find expense index related to the VA");
            }
          }
        });
      }

      getSaviZones(parcel, type) {
        const { match } = this.props;
        const farmId = match.params.farmId;
        return this.props
          .getVariableActionZonesApi(farmId, parcel.id)
          .then((res) => {
            // the service is turned off
            if (res.error) {
              return { [type]: null };
            }
            // the service is turned on and has data
            if (res.payload) {
              const intervals = res.payload;
              return {
                [type]: intervals.map((interval) =>
                  parseVarActionToSavi(interval),
                ),
              };
            }
            // the service is turned on and has no data
            return { [type]: [] };
          });
      }

      getTypePromise(parcel, status, type) {
        if (type.endsWith(IndexType.SAVI)) {
          return this.getSaviZones(parcel, type);
        } else {
          return this.props
            .getMonitoringDataApi(parcel.id, type, "", "", status, 5)
            .then((res) => {
              // the service is turned off
              if (res.error) {
                return { [type]: null };
              }

              // the service is turned on and has data
              if (res.payload) {
                const intervals = res.payload;
                return {
                  [type]: intervals
                    .splice(0, 5)
                    .map((interval) => ({
                      dateFrom: interval.dateFrom,
                      dateTo: interval.dateTo,
                      crop: interval.crop,
                      averageQuality: interval.averageQuality,
                      zones: interval.snapshots[0]?.zones.map((z) => ({
                        geometry: z.geometry,
                        zoneNumber: Number(z.name) || z.name,
                        color: z.color,
                      })),
                      type,
                    }))
                    .filter((item) => item.zones && item.zones.length > 0),
                };
              }

              // the service is turned on and has no data
              return { [type]: [] };
            });
        }
      }

      fetchMonitoringData(parcel) {
        const promises = [];
        const types = this.state.satelliteDataTypes.filter(
          (satelliteDataType) =>
            monitoringDataTypes.includes(satelliteDataType),
        );
        const status = [MONITORING_STATUS.OK, MONITORING_STATUS.LAI_ONLY].join(
          ",",
        );
        types.forEach((type) => {
          const promise = this.getTypePromise(parcel, status, type);
          promises.push(promise);
        });

        Promise.all(promises)
          .then((data) => {
            data.forEach((result) => {
              const entries = Object.entries(result);
              const [key, val] = entries[0];
              this.setState({
                [key]: val,
              });
            });
          })
          .finally(() => {
            types.forEach((type) => {
              this.setState({
                [`${type}_isFetched`]: true,
              });
            });
          });
      }

      render() {
        const { currMapZones, isDataLoaded, isVraAllowed, satelliteDataTypes } =
          this.state;

        const satelliteData = {};
        satelliteDataTypes.forEach((type) => {
          satelliteData[type] = this.state[type];
        });

        const {
          actionRestriction,
          apiError,
          existingAction,
          fieldsManuallyChanged,
          formName,
          geometry,
          handleSubmit,
          history,
          isCreateVaLoading,
          isEditing,
          isExisting,
          isExportingVA,
          isFetchingGeometry,
          isLoading,
          isPristine,
          isSubmitting,
          minMappingUnit,
          ngGoToActions,
          onCancel,
          onEditingStart,
          onReset,
          parcels,
          ...rest
        } = this.props;
        return (
          <CfErrorPage error={apiError}>
            <SidebarMapWrapper
              map={
                <VariableFertilizationMap
                  currMapZones={currMapZones}
                  geometry={geometry}
                  isFetchingGeom={isFetchingGeometry}
                  parcel={parcels[0] || {}}
                />
              }
            >
              {!isDataLoaded ? (
                <CfLoader />
              ) : (
                <Fragment>
                  <ActionFormHeader
                    history={history}
                    isDisabled={isEditing}
                    isDraft={!!existingAction?.isDraft}
                    isExisting={isExisting}
                    isLoading={isExportingVA}
                    ngGoToActions={ngGoToActions}
                    onClick={onEditingStart}
                    onExport={(exportType) => onExport(this.props, exportType)}
                    title={`${startCase(formName).replace(/\s/g, "")}.title`}
                  />
                  <Grid
                    alignItems="center"
                    container
                    justifyContent="center"
                    spacing={0}
                  >
                    {!isVraAllowed && (
                      <Grid item xs={8}>
                        <VariableFertilizationExpired onSubscribe={() => {}} />
                      </Grid>
                    )}
                    <Grid item lg={10} xl={9} xs={11}>
                      <form onSubmit={handleSubmit}>
                        <Grid container justifyContent="center" spacing={5}>
                          <WrappedComponent
                            currMapZones={currMapZones}
                            existingAction={existingAction}
                            formName={formName}
                            geometry={geometry}
                            isCreateVaLoading={isCreateVaLoading}
                            isEditing={isEditing}
                            parcels={parcels}
                            satelliteData={satelliteData}
                            setCurrMapZones={this.setCurrMapZones}
                            {...rest}
                          />
                          <ActionRestrictionsInfo
                            validationDetails={actionRestriction}
                          />
                        </Grid>
                      </form>
                    </Grid>
                  </Grid>
                  {isEditing && (
                    <ActionFormButtons
                      formName={formName}
                      isExisting={isExisting}
                      isLoading={isCreateVaLoading}
                      isPristine={isPristine}
                      isSubmitting={isSubmitting}
                      onCancel={onCancel}
                      onReset={onReset}
                      withExport={false}
                    />
                  )}
                </Fragment>
              )}
            </SidebarMapWrapper>
          </CfErrorPage>
        );
      }
    }

    const mapStateToProps = (state, props) => {
      const selector = formValueSelector(props.formName);

      return {
        apiError: getApiError(state),
        geometry: getParcel(state)?.geometry,
        isLoading: getIsLoading(state),
        isFetchingGeometry: getIsFetchingParcel(state),
        isCreateVaLoading: getCreateVaIsLoading(state),
        isSoilSamplesDataLoading: selectSoilSamplesIsFetching(state),
        actionRestriction: getActionRestrictions(state),
        variableParcelIds: getVariableFertilizationParcels(state),
        variableActionZones: getVariableActionZones(state),
        soilSamplesData: selectSoilSamplesDataForVRF(state),
        isExportingVA: getIsExportingVA(state),
        actionDate: selector(state, "actionDate"),
        satellite: selector(state, "satellite"),
        fertilizationType: selector(state, "fertilizationType"),
        parcels: selector(state, "parcels"),
        targetCrop: selector(state, "targetCrop"),
        minMappingUnit: selector(state, "minMappingUnit"),
        expenses: selector(state, "expenses"),
      };
    };

    const mapDispatchToProps = (dispatch) =>
      bindActionCreators(
        {
          getVariableParcelIds,
          resetVariableParcelIds,
          getParcelApi,
          resetParcelApi,
          resetParcelSubtractionsGeometry,
          getSoilSamplesDateApi,
          resetSoilSamplesDate,
          getMonitoringDataApi,
          resetMonitoringData,
          updateParcelGeometry,
          getVariableActionExpenses,
          updateFertilizationType,
          updateVariableExpense,
          getVariableActionZonesApi,
          getVariableActionZones,
          resetVariableActionZones,
          getPrecisionParcelApi,
        },
        dispatch,
      );

    VariableActionForm.propTypes = {
      apiError: PropTypes.object.isRequired,
      isFetchingGeometry: PropTypes.bool.isRequired,
      isLoading: PropTypes.bool.isRequired,
      formName: PropTypes.string.isRequired,
      history: PropTypes.object.isRequired,
      isExisting: PropTypes.bool.isRequired,
      isEditing: PropTypes.bool.isRequired,
      isSubmitting: PropTypes.bool.isRequired,
      isPristine: PropTypes.bool.isRequired,
      isCreateVaLoading: PropTypes.bool.isRequired,
      fieldsManuallyChanged: PropTypes.object.isRequired,
      variableParcelIds: PropTypes.array.isRequired,
      isSoilSamplesDataLoading: PropTypes.bool.isRequired,
      updateVariableExpense: PropTypes.func.isRequired,
      // Functions
      getVariableParcelIds: PropTypes.func.isRequired,
      resetVariableParcelIds: PropTypes.func.isRequired,
      getParcelApi: PropTypes.func.isRequired,
      resetParcelApi: PropTypes.func.isRequired,
      resetParcelSubtractionsGeometry: PropTypes.func.isRequired,
      getSoilSamplesDateApi: PropTypes.func.isRequired,
      getMonitoringDataApi: PropTypes.func.isRequired,
      getVariableActionZones: PropTypes.func.isRequired,
      resetMonitoringData: PropTypes.func.isRequired,
      resetSoilSamplesDate: PropTypes.func.isRequired,
      resetVariableActionZones: PropTypes.func.isRequired,
      onEditingStart: PropTypes.func.isRequired,
      handleSubmit: PropTypes.func.isRequired,
      onReset: PropTypes.func.isRequired,
      onCancel: PropTypes.func.isRequired,
      touch: PropTypes.func.isRequired,
      untouch: PropTypes.func.isRequired,
      change: PropTypes.func.isRequired,
      updateParcelGeometry: PropTypes.func.isRequired,
      getVariableActionExpenses: PropTypes.func.isRequired,
      updateFertilizationType: PropTypes.func.isRequired,
      ngGoToActions: PropTypes.func.isRequired,
      // Non-required
      soilSamplesData: PropTypes.array,
      actionRestriction: PropTypes.object,
      existingAction: PropTypes.object,
      geometry: PropTypes.object,
      parcels: PropTypes.array,
      expenses: PropTypes.array,
      targetCrop: PropTypes.object,
      minMappingUnit: PropTypes.number,
      satellite: PropTypes.object,
      actionDate: PropTypes.object,
      fertilizationType: PropTypes.object,
    };

    VariableActionForm.defaultProps = {
      parcels: [],
      expenses: [],
      actionDate: {},
      soilSamplesData: null,
      existingAction: null,
      geometry: null,
      targetCrop: null,
      minMappingUnit: null,
      satellite: null,
      fertilizationType: {},
      actionRestriction: {},
    };

    VariableActionForm.displayName = `VariableActionForm(${getDisplayName(
      WrappedComponent,
    )})`;

    return compose(connect(mapStateToProps, mapDispatchToProps))(
      VariableActionForm,
    );
  };

export default variableActionForm;
