import React from "react";

import { makeStyles } from "@mui/styles";
import { FormikErrors } from "formik";

import { PlantProtectionListItem } from "./PlantProtectionListItem";

import {
  ActionEphFormValues,
  EphPlantProtectionsType,
} from "../../actionEph.types";

type Props = {
  actionTotalArea: number;
  isEditing: boolean;
  isExisting: boolean;
  plantProtections: EphPlantProtectionsType[];
  onItemRemove: (val: number, id: string) => void;
  errors: FormikErrors<Partial<ActionEphFormValues>>;
};

export const PlantProtectionList = ({
  actionTotalArea,
  errors,
  isEditing,
  isExisting,
  onItemRemove,
  plantProtections,
}: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.plantProtectionsList} id="plant-protections-list">
      {plantProtections.length > 0 &&
        plantProtections.map((plantProtection, index) => (
          <PlantProtectionListItem
            actionTotalArea={actionTotalArea}
            errors={errors}
            index={index}
            isEditing={isEditing}
            isExisting={isExisting}
            key={plantProtection?.id}
            onItemRemove={onItemRemove}
            plantProtection={plantProtection}
          />
        ))}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  plantProtectionsList: {
    marginTop: 25,
    marginBottom: 35,
  },
}));
