import React, { useEffect, useRef } from "react";

import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import { History } from "history";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  getRule,
  getIsFetchingRule,
  getErrorRule,
} from "../../../shared/api/automation/rules/rules.selectors";
import {
  getLocation,
  getIsFetchingLocation,
} from "../../../shared/api/iot/locations/locations.selectors";

import {
  fetchRule,
  resetRule,
  createRule,
  updateRule,
  fetchDeviceTypes,
  fetchLocation,
} from "../../actions/notificationList.actions";

import CfBackButton from "../../../shared/components/common/CfBackButton/CfBackButton";
import CfErrorPage from "../../../shared/components/common/CfErrorPage/CfErrorPage";
import CfLoader from "../../../shared/components/common/CfLoader/CfLoader";
import PageHeading from "../../../shared/components/common/PageHeading/PageHeading";
import useEditing from "../../../shared/hocs/useEditing";
import { AnyTodo } from "../../../types";
import NotificationDetailFormWrapper from "../../components/NotificationDetailFormWrapper/NotificationDetailFormWrapper";

type Props = {
  farmId: string;
  isExisting?: boolean;
  history: History;
};

const NotificationDetail = ({ farmId, history, isExisting = false }: Props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { isEditing, onEditingEnd, onEditingStart } = useEditing();
  const prevRuleRef = useRef(null);
  const { notificationId } = useParams<{ notificationId: string }>();

  const rule = useSelector(getRule) ?? null;
  const isFetchingRule = useSelector(getIsFetchingRule);
  const errorRule = useSelector(getErrorRule) ?? null;
  const nodeLocation = useSelector(getLocation);
  const isFetchingNodeLocation = useSelector(getIsFetchingLocation);

  useEffect(() => {
    if (isExisting) {
      dispatch(fetchRule(notificationId));
    } else {
      onEditingStart();
    }
    dispatch(fetchDeviceTypes());

    return () => {
      dispatch(resetRule());
    };
  }, [dispatch, isExisting, notificationId, onEditingStart]);

  useEffect(() => {
    if (rule && !prevRuleRef.current) {
      if (rule.sensors.length > 0) {
        dispatch(fetchLocation(rule.sensors[0].lastLocationId));
      }
    }
    prevRuleRef.current = rule;
  }, [rule, dispatch]);

  const goToNotifications = () => {
    history.push(`/farm/${farmId}/notifications`);
  };

  return (
    <CfErrorPage error={errorRule}>
      {isFetchingRule ||
      isFetchingNodeLocation ||
      (isExisting && (!rule || !nodeLocation.id)) ? (
        <CfLoader />
      ) : (
        <div className={classes.container}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid
                alignItems="center"
                className={classes.header}
                container
                spacing={2}
              >
                <Grid item xs={2}>
                  <CfBackButton
                    onClick={goToNotifications}
                    translId="NotificationDetail.backToNotifications"
                  />
                </Grid>
                <Grid item xs={8}>
                  <PageHeading
                    value={<FormattedMessage id="common.notification" />}
                  />
                </Grid>
                <Grid className={classes.editButtonItem} item xs={2}>
                  {isExisting && (
                    <>
                      <IconButton
                        data-test="notification-edit"
                        disabled={isEditing}
                        onClick={onEditingStart}
                        size="large"
                      >
                        <EditIcon />
                      </IconButton>
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid container justifyContent="center" spacing={2}>
                <Grid item lg={8} md={10} xs={12}>
                  <NotificationDetailFormWrapper
                    farmId={farmId}
                    isEditing={isEditing}
                    isExisting={isExisting}
                    nodeLocation={nodeLocation}
                    onEditingEnd={onEditingEnd}
                    rule={rule}
                    createRule={(values: AnyTodo) =>
                      (
                        dispatch(
                          createRule(values, farmId),
                        ) as unknown as Promise<unknown>
                      ).then((res: { error: AnyTodo }) => {
                        if (!res.error) {
                          goToNotifications();
                        }
                      })
                    }
                    updateRule={(values: AnyTodo) =>
                      (
                        dispatch(
                          updateRule(rule.id, values, farmId),
                        ) as unknown as Promise<unknown>
                      ).then((res: { error: AnyTodo }) => {
                        if (!res.error) {
                          goToNotifications();
                        }
                      })
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
    </CfErrorPage>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    margin: 15,
    paddingBottom: 30,
  },
  header: {
    margin: 0,
    overflowX: "hidden",
    width: "100%",
  },
  editButtonItem: {
    textAlign: "right",
  },
}));

export default NotificationDetail;
