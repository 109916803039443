import queryString from "query-string";
import { RSAA } from "redux-api-middleware";

import * as types from "./crops.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../../api.constants";

import { GetCropsApiParams } from "./crops.types";
import { RsaaMethods, RsaaTypes } from "../../../api.types";

export const getCropsApi = (params: GetCropsApiParams) => ({
  [RSAA]: {
    endpoint: `catalogues/crops?${queryString.stringify(params)}&`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_CROPS_REQUEST,
      types.GET_CROPS_SUCCESS,
      types.GET_CROPS_ERROR,
    ] as RsaaTypes,
  },
});

export const resetCropsApi = () => ({
  type: types.RESET_CROPS,
});
