import React, { Fragment } from "react";

import { createRoot } from "react-dom/client";

import { getLangId } from "../../../../shared/selectors/lang.selectors";
import { getOverlay } from "../../selectors/map.selectors";

import CfFormattedNumber from "../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import BaseContainer from "../../../../shared/containers/BaseContainer/BaseContainer";

export const removeOverlay = (key) => (_, getState) => {
  const overlay = getOverlay(getState());
  overlay.removeOverlay(key);
};

export const removeOverlays = () => (_, getState) => {
  const overlays = getOverlay(getState());
  overlays?.removeOverlays();
};

/** ************ */

export const addMeasurementOverlay =
  (data, elementId, key) => (_, getState) => {
    const state = getState();
    const overlay = getOverlay(state);
    const { area, geometry, length } = data;

    const langId = getLangId(state);
    let tooltipPosition;
    let tooltipOffset = [0, 0];
    if (area) {
      const pointCoordinates = geometry.getInteriorPoint().getCoordinates();
      tooltipPosition = [pointCoordinates[0], pointCoordinates[1]];
    } else {
      tooltipPosition = geometry.getLastCoordinate();
      tooltipOffset = [0, -20];
    }

    const element = overlay.getOverlayElement(elementId);
    const root = createRoot(element);
    root.render(
      <BaseContainer langId={langId} translations={{}}>
        <Fragment>
          <div className="measurement-info-result">
            {(length || length === 0) && (
              <p>
                <CfFormattedNumber
                  decimalDigits={length > 1000 ? 0 : 1}
                  value={length}
                />{" "}
                m
              </p>
            )}
            {(area || area === 0) && (
              <p>
                <CfFormattedNumber
                  decimalDigits={area > 1000 ? 1 : 3}
                  value={area}
                />{" "}
                ha
              </p>
            )}
          </div>
          <div className="measurement-info-tick" />
        </Fragment>
      </BaseContainer>,
    );

    overlay.removeOverlay(key);
    overlay.addOverlay(
      overlay.createOverlay(element, tooltipPosition, {
        offset: tooltipOffset,
        positioning: "bottom-center",
      }),
      key,
    );
  };
