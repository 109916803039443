import React from "react";

import { Theme } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import { FormattedMessage, FormattedDate } from "react-intl";
import { useHistory } from "react-router-dom";

import { NAMESPACE as namespace } from "../../reducer/parcelList.reducer";

import CfFormattedNumber from "../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import { CropName } from "../../../../../shared/components/specific/CropName/CropName";
import CfTableCell from "../../../../../shared/components/tables/CfTableCell/CfTableCell";
import CfTableCellNumber from "../../../../../shared/components/tables/CfTableCellNumber/CfTableCellNumber";
import CfTableCheckbox from "../../../../../shared/containers/CfTableCheckbox/CfTableCheckbox";
import { useFocusedTableRowTarget } from "../../../../../shared/hooks/useFocusedTableRowTarget";
import ParcelCenterAssign from "../../../shared/containers/ParcelCenterAssign/ParcelCenterAssign";
import { ParcelZoneName } from "../../components/ParcelZoneName";

import { ParcelTo } from "../../../../../shared/api/agroevidence/agroevidence.types";

type Props = {
  farmId: string;
  focusedRow?: string;
  isInit: boolean;
  onAssignCenter: (
    centerId: string,
    parcelIds: string[],
    bulk: boolean,
  ) => void;
  parcel: ParcelTo;
  selected: string[];
};

const ParcelTableRow = ({
  farmId,
  focusedRow,
  isInit,
  onAssignCenter,
  parcel,
  selected,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();

  const { targetElementRef } = useFocusedTableRowTarget({
    focusedRow,
    isInit,
    rowId: parcel.id.toString(),
  });

  const isSelected = (id: string) => selected && selected.indexOf(id) !== -1;

  return (
    <TableRow
      className={classes.pointer}
      hover
      ref={targetElementRef}
      selected={isSelected(parcel.id)}
      onClick={() =>
        history.push(`/farm/${farmId}/parcels/${parcel.id}/overview`)
      }
    >
      <CfTableCheckbox
        id={parcel.id}
        namespace={namespace}
        selected={selected}
      />
      <CfTableCell name="localName">
        <span className={classes.name}>{parcel.localName}</span>
      </CfTableCell>
      <CfTableCell name="blockNumber">
        <span>{parcel.blockNumber}</span>
      </CfTableCell>
      <CfTableCell name="area">
        <span>
          <CfTableCellNumber padding={true}>
            <p className={`${classes.cellRow}`}>
              <CfFormattedNumber value={parcel.area || 0} />
            </p>
            <p className={`${classes.cellRow} ${classes.greyText}`}>
              <CfFormattedNumber value={parcel.lpisBlockArea || 0} />
            </p>
          </CfTableCellNumber>
        </span>
      </CfTableCell>

      <CfTableCell name="culture">
        <span>
          <p className={`${classes.cellRow}`}>{parcel.landUseName}</p>
          <div
            className={`${classes.cellRow} ${classes.greyText} ${classes.cropCell}`}
          >
            <CropName
              cropType={parcel.seedApplication?.type}
              sx={{ height: 15 }}
            >
              {parcel.seedApplication?.seed.crop.name || (
                <FormattedMessage id="common.noCrop" />
              )}
            </CropName>
          </div>
        </span>
      </CfTableCell>

      <CfTableCell name="nitrateVulnerable">
        <FormattedMessage
          id={`common.${parcel.nitrateVulnerable ? "yes" : "no"}`}
        />
      </CfTableCell>

      <CfTableCell name="validFrom">
        <FormattedDate value={parcel.validFrom} />
      </CfTableCell>

      <TableCell
        key="zone"
        onClick={(evt) => evt.stopPropagation()}
        padding="none"
        style={{
          width: "1px",
          whiteSpace: "nowrap",
          paddingRight: "5px",
        }}
      >
        <ParcelZoneName parcel={parcel} />
      </TableCell>

      <TableCell
        key="center"
        onClick={(evt) => evt.stopPropagation()}
        padding="none"
        style={{
          width: "1px",
          whiteSpace: "nowrap",
          paddingRight: "5px",
        }}
      >
        <ParcelCenterAssign
          parcel={parcel}
          onCenterChange={(centerId: string, parcelId: string) =>
            onAssignCenter(centerId, [parcelId], false)
          }
        />
      </TableCell>
    </TableRow>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  greyText: {
    color: theme.palette.grey[500],
  },
  cellRow: {
    margin: 0,
  },
  name: {
    fontWeight: 500,
  },
  pointer: {
    cursor: "pointer",
  },
  cropCell: {
    display: "flex",
    alignItems: "center",
  },
}));

export default ParcelTableRow;
