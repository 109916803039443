import { useDispatch } from "react-redux";

import { setTextFilter } from "../../../shared/actions/filter.actions";

const useCfTextFilter = (namespace: string) => {
  const dispatch = useDispatch();

  const onChange = (inputValue: string) => {
    dispatch(setTextFilter(inputValue, namespace));
  };

  return {
    onChange,
  };
};

export { useCfTextFilter };
