import React from "react";

import { Table } from "@mui/material";
import { Theme } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { useDispatch } from "react-redux";

import { setTableSorting } from "../../../../../shared/actions/table.actions";

import * as sortOrder from "../../../../../shared/constants/sortOrder.constants";

import { NAMESPACE } from "../../reducer/sentinelAdminStats.reducer";

import { TableBodyColumn } from "./types";

import { SortOrderType } from "../../../../../shared/api/api.types";

type Props = {
  columns: TableBodyColumn[];
  order: SortOrderType;
  orderBy: string;
  classes: Record<string, string>;
};

const Header = ({ classes: propClasses, columns, order, orderBy }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleRequestSort = (newOrderBy: string) => {
    const isAsc = orderBy === newOrderBy && order === sortOrder.ASC;
    const newOrder = isAsc ? sortOrder.DESC : sortOrder.ASC;
    dispatch(setTableSorting(newOrder, newOrderBy, NAMESPACE));
  };

  const createSortHandler = (property: string) => () => {
    handleRequestSort(property);
  };

  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow classes={{ root: propClasses.wrapper }}>
          {columns.map((col) => (
            <TableCell
              align={col.align ?? "left"}
              key={col.key}
              style={col.style}
              classes={{
                root: classNames(classes.cell, propClasses.cell),
              }}
            >
              {col.sortable ? (
                <TableSortLabel
                  active={orderBy === col.key}
                  direction={orderBy === col.key ? order : sortOrder.ASC}
                  onClick={createSortHandler(col.key)}
                >
                  {col.label}
                </TableSortLabel>
              ) : (
                col.label
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </Table>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  cell: {
    color: theme.palette.grey[500],
    background: "transparent",
  },
}));

export { Header };
