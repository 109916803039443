import React from "react";

import { Box, styled } from "@mui/material";
import { FormattedMessage } from "react-intl";

import CfFormattedNumber from "../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import CfLoader from "../../../../../shared/components/common/CfLoader/CfLoader";

import { MAX_COLOR, MIDDLE_COLOR, MIN_COLOR } from "./settings";

const NoAttributeValue = () => (
  <AttributeWrapper>
    <FormattedMessage id="AsAppliedDetail.map.noAttributeValue" />
  </AttributeWrapper>
);

const LoadingAttribute = () => (
  <AttributeWrapper>
    <CfLoader size={20} />
  </AttributeWrapper>
);

const AttributeInterval = ({
  children,
  max,
  min,
  unit,
}: React.PropsWithChildren<{ max: number; min: number; unit: string }>) => (
  <AttributeWrapper>
    <span>
      <CfFormattedNumber value={min} />
    </span>
    {children}
    <span>
      <CfFormattedNumber value={max} /> {unit}
    </span>
  </AttributeWrapper>
);

const AttributeGradient = () => (
  <Box
    height="10px"
    width="200px"
    style={{
      background: `linear-gradient(to right, ${MIN_COLOR}, ${MIDDLE_COLOR}, ${MAX_COLOR})`,
    }}
  />
);

const AttributeWrapper = styled(Box)(({ theme }) => ({
  alignItems: "center",
  background: "white",
  borderRadius: "28px",
  display: "flex",
  fontSize: "14px",
  gap: theme.spacing(1),
  justifyContent: "space-between",
  padding: "4px 12px",
}));

export {
  AttributeWrapper,
  LoadingAttribute,
  NoAttributeValue,
  AttributeInterval,
  AttributeGradient,
};
