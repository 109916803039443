import React, { FC } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Collapse, IconButton, TableCell, Tooltip } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import moment from "moment";
import { FormattedMessage } from "react-intl";

import CfTableCell from "../../../../../shared/components/tables/CfTableCell/CfTableCell";
import { getLocalizedDateString } from "../../../../../shared/misc/timeHelpers";

import { COLUMNS_COUNT } from "./ExportsList";
import { getStatusText, getStatusIcon } from "./helpers";
import MachineFiles from "./MachineFiles";
import { EXPORT_TYPES, STATUSES, UploaderJob, WriterJob } from "./types";

const useStyles = makeStyles({
  format: {
    fontWeight: 500,
  },
  statusIcon: {
    width: 20,
    marginLeft: 10,
  },
  iconColumn: {
    width: 132,
    padding: 0,
  },
  iconsContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  iconButtons: {
    padding: 8,
    margin: "12px 0",
  },
  toggleIcon: {
    marginLeft: 12,
  },
  extraMargin: {
    marginLeft: 8,
    marginRight: 6,
  },
  machineFilesRow: {
    height: 0,
  },
  machineFilesCell: {
    padding: 0,
  },
});

export interface Props {
  childrenUploadJobs?: UploaderJob[];
  enableUpload: boolean;
  handleDeleteFactory: (jobId: number) => () => void;
  handleDownload: () => void;
  handleToggle: () => void;
  handleUpload: () => void;
  job: WriterJob;
  opened: boolean;
}

const ExportJob: FC<Props> = ({
  childrenUploadJobs,
  enableUpload,
  handleDeleteFactory,
  handleDownload,
  handleToggle,
  handleUpload,
  job,
  opened,
}) => {
  const { exportDate, jobId, name, status } = job;
  const classes = useStyles();

  return (
    <>
      <TableRow key={jobId}>
        <CfTableCell name="status">
          <Tooltip
            title={
              <FormattedMessage
                id={getStatusText(status, EXPORT_TYPES.WRITER)}
              />
            }
          >
            <img
              alt="success"
              className={classes.statusIcon}
              src={getStatusIcon(status)}
            />
          </Tooltip>
        </CfTableCell>
        <CfTableCell classes={{ root: classes.format }} name="format">
          <div>
            <span> {name}</span>
            {status === STATUSES.COMPLETE && !!childrenUploadJobs?.length && (
              <IconButton
                aria-label="Toggle view"
                className={classes.toggleIcon}
                onClick={handleToggle}
                size="large"
              >
                {opened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
          </div>
        </CfTableCell>
        <CfTableCell name="date">
          {getLocalizedDateString(moment(exportDate), ["L", "LTS"])}
        </CfTableCell>
        <CfTableCell classes={{ root: classes.iconColumn }} name="icons">
          <div className={classes.iconsContainer}>
            <IconButton
              aria-label="Download export"
              className={classes.iconButtons}
              disabled={status !== STATUSES.COMPLETE}
              onClick={handleDownload}
              size="large"
            >
              <FileDownloadIcon />
            </IconButton>
            {enableUpload && (
              <IconButton
                aria-label="Upload to machine"
                className={classes.iconButtons}
                disabled={status !== STATUSES.COMPLETE}
                onClick={handleUpload}
                size="large"
              >
                <CloudUploadIcon />
              </IconButton>
            )}
            <IconButton
              aria-label="Remove item"
              className={classnames(classes.iconButtons, classes.extraMargin)}
              onClick={handleDeleteFactory(jobId)}
              size="large"
            >
              <ClearIcon />
            </IconButton>
          </div>
        </CfTableCell>
      </TableRow>
      {!!childrenUploadJobs?.length && (
        <TableRow className={classes.machineFilesRow}>
          <TableCell
            className={classes.machineFilesCell}
            colSpan={COLUMNS_COUNT}
          >
            <Collapse in={opened}>
              <MachineFiles
                handleDeleteFactory={handleDeleteFactory}
                jobs={childrenUploadJobs}
              />
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default ExportJob;
