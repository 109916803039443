import React, { FC } from "react";

import { FormControlLabel, Theme, alpha } from "@mui/material";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import { useField } from "formik";

interface Props extends SwitchProps {
  label: React.ReactElement;
  labelPlacement?: "bottom" | "top" | "end" | "start";
  name: string;
  customClasses?: Record<string, string>;
}

const FormSwitch: FC<Props> = ({
  customClasses = {},
  label,
  labelPlacement,
  name,
  ...rest
}) => {
  const classes = useStyles();
  const fieldProps = useField(name);
  const field = fieldProps[0];
  const form = fieldProps[2];

  return (
    <FormControlLabel
      label={label}
      labelPlacement={labelPlacement ?? "top"}
      classes={{
        label: classes.label,
        root: classnames(classes.root, customClasses.switchLabelRoot),
      }}
      control={
        <Switch
          checked={!!field.value}
          onChange={() => {
            form.setValue(!field.value);
          }}
          {...rest}
        />
      }
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    fontSize: 16,
    fontWeight: 400,
    color: alpha(theme.palette.common.black, 0.6),
  },
  root: {
    alignItems: "center",
    marginLeft: 0,
  },
}));

export default FormSwitch;
