import React, { useEffect } from "react";

import Switch from "@mui/material/Switch";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  getItems,
  getIsFetchingRules,
  getTotalCount,
} from "../../../shared/api/automation/rules/rules.selectors";
import {
  getNotificationListOrder,
  getNotificationListOrderBy,
  getNotificationListPage,
  getNotificationListRowsPerPage,
  getNotificationListSelected,
  getNotificationListSelectedOnPage,
  getNotificationListTextFilter,
} from "../../selectors/notificationList.selectors";

import { fetchRules, patchRule } from "../../actions/notificationList.actions";

import { NAMESPACE as namespace } from "../../reducer/notificationList.reducer";

import CfTableFooter from "../../../common/components/CfTableFooter/CfTableFooter";
import { useCfTableFooter } from "../../../common/components/CfTableFooter/useCfTableFooter";
import CfTableHead from "../../../common/components/CfTableHead/CfTableHead";
import { useCfTableHead } from "../../../common/components/CfTableHead/useCfTableHead";
import CfTableBodyEmpty from "../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableBodyLoader from "../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader";
import CfTableCell from "../../../shared/components/tables/CfTableCell/CfTableCell";
import CfTableWrapper from "../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import CfTableCheckbox from "../../../shared/containers/CfTableCheckbox/CfTableCheckbox";
import { getColDesc } from "../../../shared/misc/helper";
import { COLOR_GREY } from "../../../theme";
import { AnyTodo } from "../../../types";
import NotificationCondition from "../../components/NotificationCondition/NotificationCondition";
import NotificationUsers from "../../components/NotificationUsers/NotificationUsers";

const colStyles = {
  head: {
    p: {
      margin: 0,
    },
    secondaryLabel: {
      color: COLOR_GREY[400],
    },
  },
};

const columns = {
  lastLocationName: getColDesc(
    true,
    <span>
      <p style={colStyles.head.p}>
        <FormattedMessage id="common.sensor" />
      </p>
      <p style={{ ...colStyles.head.p, ...colStyles.head.secondaryLabel }}>
        <FormattedMessage id="common.id" />
      </p>
    </span>,
  ),
  condition: getColDesc(false, <FormattedMessage id="common.condition" />),
  description: getColDesc(false, <FormattedMessage id="common.description" />),
  users: getColDesc(false, <FormattedMessage id="common.users" />),
  enabled: getColDesc(true, <FormattedMessage id="common.state" />),
};

type Props = {
  farmId: string;
  goToNotificationDetail: (val: string) => void;
};

const NotificationsTable = ({ farmId, goToNotificationDetail }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const page = useSelector(getNotificationListPage);
  const order = useSelector(getNotificationListOrder);
  const orderBy = useSelector(getNotificationListOrderBy);
  const rowsPerPage = useSelector(getNotificationListRowsPerPage);
  const selected = useSelector(getNotificationListSelected);
  const selectedOnPage = useSelector(getNotificationListSelectedOnPage);
  const textFilter = useSelector(getNotificationListTextFilter);
  const isFetching = useSelector(getIsFetchingRules);
  const rules: AnyTodo[] = useSelector(getItems);
  const count = useSelector(getTotalCount);

  const { onPageChange, onRowsPerPageChange } = useCfTableFooter(namespace);
  const { onSelect, onSort } = useCfTableHead(namespace);

  useEffect(() => {
    dispatch(fetchRules());
  }, [page, rowsPerPage, order, orderBy, textFilter, dispatch]);

  return (
    <CfTableWrapper>
      <CfTableHead
        columns={columns}
        items={rules}
        onSelect={onSelect}
        onSort={onSort}
        order={order}
        orderBy={orderBy}
        selected={selected}
        selectedOnPage={selectedOnPage}
      />
      {isFetching && <CfTableBodyLoader columns={columns} />}
      {rules.length ? (
        <TableBody>
          {rules.map((item) => (
            <TableRow
              className={classes.pointer}
              hover
              key={item.id}
              onClick={() => goToNotificationDetail(item.id)}
            >
              <CfTableCheckbox
                id={item.id}
                namespace={namespace}
                selected={selected}
              />
              <CfTableCell name="lastLocationName">
                <span>
                  <p className={`${classes.cellRow} ${classes.sensorName}`}>
                    <Link
                      to={`/farm/${farmId}/sensors/${item.sensors[0].lastLocationId}`}
                    >
                      {item.sensors[0].lastLocationName}
                    </Link>
                  </p>
                  <p className={classes.cellRow}>
                    {item.sensors[0].externalId}
                  </p>
                </span>
              </CfTableCell>
              <CfTableCell name="condition">
                <NotificationCondition condition={item.conditions[0]} />
              </CfTableCell>
              <CfTableCell name="description">{item.name}</CfTableCell>
              <CfTableCell name="users">
                <NotificationUsers users={item.users} />
              </CfTableCell>
              <CfTableCell name="enabled">
                <Tooltip
                  title={
                    <FormattedMessage
                      id={`NotificationsTable.${
                        item.enabled
                          ? "activeNotification"
                          : "inactiveNotification"
                      }`}
                    />
                  }
                >
                  <Switch
                    checked={item.enabled}
                    color="primary"
                    name="enabled"
                    onClick={(evt) => evt.stopPropagation()}
                    onChange={(evt) =>
                      dispatch(patchRule(item.id, evt.target.checked))
                    }
                  />
                </Tooltip>
              </CfTableCell>
            </TableRow>
          ))}
        </TableBody>
      ) : (
        <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
      )}
      <CfTableFooter
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </CfTableWrapper>
  );
};

const useStyles = makeStyles({
  cellRow: {
    margin: 0,
  },
  pointer: {
    cursor: "pointer",
  },
  sensorName: {
    fontWeight: 500,
  },
});

export default NotificationsTable;
