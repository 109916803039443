import React, { ChangeEvent, ReactNode } from "react";

import { Theme } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

interface Props {
  checked: boolean;
  indeterminate?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  label: ReactNode;
  header?: boolean;
  disabled?: boolean;
  grayed?: boolean;
}

const MapSwitcherCheckbox = ({
  checked,
  disabled = false,
  grayed = false,
  header = false,
  indeterminate = false,
  label,
  onChange,
}: Props) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      className={classes.layerRow}
      label={label}
      classes={{
        label: classNames({
          [classes.layerHeaderRowLabel]: header,
          [classes.layerRowLabel]: !header,
          [classes.grayed]: grayed,
        }),
      }}
      control={
        <Checkbox
          checked={checked}
          className={classes.layerRowCheckbox}
          color="primary"
          disabled={disabled}
          indeterminate={indeterminate}
          onChange={onChange}
        />
      }
    />
  );
};

export { MapSwitcherCheckbox };

const useStyles = makeStyles((theme: Theme) => ({
  layerRowCheckbox: {
    width: 36,
    height: 36,
    marginLeft: 6,
    marginRight: 2,
  },
  layerRow: {
    height: 36,
    marginBottom: 0,
  },
  layerHeaderRowLabel: {
    fontWeight: 500,
    fontSize: 14,
  },
  layerRowLabel: {
    fontSize: 14,
  },
  grayed: {
    color: theme.palette.grey[500],
  },
}));
