import { intersection } from "lodash";
import { createSelector } from "reselect";

import {
  getPrecisionAsAppliedOrder,
  getPrecisionAsAppliedOrderBy,
  getPrecisionAsAppliedPage,
  getPrecisionAsAppliedRowsPerPage,
  getPrecisionAsAppliedSelected,
  getPrecisionAsAppliedTextFilter,
} from "../../../../core/precision/selectors/precisionAsApplied.selectors";

import { paginationArray, sortArray } from "../../../misc/arraysAction.helpers";

import { PrecisionState } from "../../../../reducers/precision.reducer.types";

export const getPrecisionTaskdata = (state: PrecisionState) =>
  state.api.asApplied.list.data;
export const getPrecisionTaskdataCount = (state: PrecisionState) =>
  state.api.asApplied.list.count;
export const getPrecisionAsAppliedIsFetching = (state: PrecisionState) =>
  state.api.asApplied.list.isFetching;
export const getFileImportError = (state: PrecisionState) =>
  state.api.precisionAsApplied.fileImportError;
export const getShowVarioDocInactive = (state: PrecisionState) =>
  state.api.precisionAsApplied.showVarioDocInactive;
export const getFocusedRow = (state: PrecisionState) =>
  state.api.precisionAsApplied.focusedRow;

export const getPrecisionAsAppliedFiltered = createSelector(
  getPrecisionTaskdata,
  getPrecisionAsAppliedTextFilter,
  (data, textFilter) => {
    if (textFilter.length === 0) return data;
    const textFilterLowerCase = textFilter.toLowerCase();

    const dataFiltered = data?.filter((task) =>
      task.filename.toLowerCase().includes(textFilterLowerCase),
    );
    return dataFiltered;
  },
);

export const getPrecisionParcelsSortedPaginated = createSelector(
  getPrecisionAsAppliedFiltered,
  getPrecisionAsAppliedOrder,
  getPrecisionAsAppliedOrderBy,
  getPrecisionAsAppliedPage,
  getPrecisionAsAppliedRowsPerPage,
  (dataFiltered, order, orderBy, page, rowsPerPage) => {
    const dataSorted = sortArray(order, orderBy, dataFiltered ?? []);
    return paginationArray(page, rowsPerPage, dataSorted);
  },
);

export const getPrecisionAsAppliedSelectedOnPage = createSelector(
  getPrecisionTaskdata,
  getPrecisionAsAppliedSelected,
  (data, selected) =>
    intersection(
      data?.map((row) => row.id),
      selected,
    ),
);
