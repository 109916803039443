import React from "react";

import { styled } from "@mui/material";

type Props = {
  label: React.ReactNode;
  text: React.ReactNode;
};

const CfLabeledText = ({ label: _label, text: _text }: Props) => {
  const label = _text === undefined ? "" : _label;
  const text = _text ?? _label;

  return (
    <Container>
      <Label>{label}</Label>
      <Text isPlaceholder={text !== _text}>{text}</Text>
    </Container>
  );
};

const Text = styled("div")<{ isPlaceholder: boolean }>((props) => ({
  fontSize: 16,
  color: props.isPlaceholder ? props.theme.palette.grey[500] : "inherit",
  overflowWrap: "break-word",
}));

const Label = styled("div")(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.grey[500],
}));

const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  margin: 8,
});

export { CfLabeledText };
