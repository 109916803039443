import { AnyAction } from "redux";

import * as types from "./machinesDashboard.constants";
import * as commonFilters from "../../../../actions/filter.constants";
import * as commonTable from "../../../../actions/table.constants";
import * as sortOrder from "../../../../constants/sortOrder.constants";

import { MainMapTelematicsMachinesDashboardApiState } from "./machinesDashboard.types";
import { PositionSort } from "../../telematics.types";

export const NAMESPACE = "mainMapTelematicsMachinesDashboard";

export default () =>
  (
    state: MainMapTelematicsMachinesDashboardApiState = {
      machinesPositions: [],
      selected: undefined,
      isFetching: false,
      textFilter: "",
      order: sortOrder.ASC,
      orderBy: PositionSort.STATE,
      isOpen: false,

      drivesHistory: [],
      isFetchingDrivesHistory: false,
    },
    action: AnyAction,
  ) => {
    switch (action.type) {
      case types.GET_MACHINES_POSITION_DASHBOARD:
        return {
          ...state,
          isFetching: true,
        };
      case types.GET_MACHINES_POSITION_DASHBOARD_SUCCESS:
        return {
          ...state,
          machinesPositions: action.payload,
          isFetching: false,
        };
      case types.GET_MACHINES_POSITION_DASHBOARD_ERROR:
        return {
          ...state,
          isFetching: false,
        };
      case types.GET_MACHINES_POSITION_DASHBOARD_RESET:
        return {
          ...state,
          machinesPositions: [],
          selected: undefined,
          isFetching: false,
          textFilter: "",
          order: sortOrder.ASC,
          orderBy: PositionSort.STATE,
          isOpen: false,
        };

      case types.SET_DASHBOARD_SELECTED_MACHINE: {
        return {
          ...state,
          selected: action.payload,
        };
      }
      case types.SET_DASHBOARD_MACHINE_IS_OPEN: {
        return {
          ...state,
          isOpen: action.payload,
        };
      }

      case commonFilters.SET_TEXT_FILTER:
        return {
          ...state,
          textFilter: action.textFilter,
        };
      case commonTable.SET_TABLE_ORDER:
        return {
          ...state,
          order: action.order,
        };
      case commonTable.SET_TABLE_ORDER_BY:
        return {
          ...state,
          orderBy: action.orderBy,
        };

      case types.GET_DASHBOARD_DRIVES_HISTORY_REQUEST:
        return {
          ...state,
          isFetchingDrivesHistory: true,
        };
      case types.GET_DASHBOARD_DRIVES_HISTORY_SUCCESS:
        return {
          ...state,
          drivesHistory: action.payload,
          isFetchingDrivesHistory: false,
        };
      case types.GET_DASHBOARD_DRIVES_HISTORY_ERROR:
        return {
          ...state,
          isFetchingDrivesHistory: false,
        };

      case types.RESET_DASHBOARD_DRIVES_HISTORY:
        return {
          ...state,
          drivesHistory: [],
          isFetchingDrivesHistory: false,
        };

      default:
        return state;
    }
  };
