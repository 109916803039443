import React, { FC, ReactNode, useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment, { Moment } from "moment";
import { FormattedMessage } from "react-intl";

import CfDatePicker from "../../../../shared/components/common/CfDatePicker/CfDatePicker";

import { EagriFertilizerUseReportType } from "../../../../shared/api/agroevidence/agroevidence.types";

const useStyles = makeStyles(() => ({
  dialogContainer: {
    maxWidth: "400px",
  },
  itemContainer: {
    padding: "5px 0px",
  },
  button: {
    marginLeft: 8,
  },
  radioGroupWrapper: {
    fontSize: 14,
    fontWeight: 500,
    marginBottom: 30,
  },
  dateSelectorWrapper: {
    display: "flex",
    justifyContent: "flex-between",
    marginBottom: 30,
  },
  label: {
    fontSize: 12,
    marginBottom: 5,
  },
  statisticsDateRange: {
    fontSize: 16,
    fontWeight: 400,
    marginBottom: 5,
  },
  grid: {
    marginRight: 30,
  },
}));

interface FertilizerUsageReportDialogProps {
  onAccept: (dateFrom: string, dateTo: string, reportType: string) => void;
  onClose: () => void;
  showDialog?: boolean;
}

interface ErrorType {
  error?: boolean;
  helperText?: ReactNode;
}

const FertilizerUsageReportDialog: FC<FertilizerUsageReportDialogProps> = ({
  onAccept,
  onClose,
  showDialog = false,
}) => {
  const classes = useStyles();
  const [reportType, setReportType] = useState<string>(
    EagriFertilizerUseReportType.STATISTICS,
  );
  const [dateFrom, setDateFrom] = useState<Moment>(moment().startOf("year"));
  const [dateTo, setDateTo] = useState<Moment>(moment().endOf("day"));
  const [errorDateRange, setErrorDateRange] = useState<ErrorType>({});

  useEffect(() => {
    if (reportType === EagriFertilizerUseReportType.STATISTICS) {
      setDateFrom(moment().subtract(1, "years").startOf("year"));
      setDateTo(moment().subtract(1, "years").endOf("year"));
    }
    if (reportType === EagriFertilizerUseReportType.CHECK) {
      setDateFrom(moment().startOf("year"));
      setDateTo(moment().endOf("day"));
    }
  }, [reportType]);

  const handleSendReport = () => {
    onAccept(dateFrom.toISOString(), dateTo.toISOString(), reportType);
  };

  const onDateFromChange = (value: Moment) => {
    const newValue = moment(value).startOf("day");
    setDateFrom(newValue);
    if (newValue.isAfter(moment(dateTo))) {
      setErrorDateRange({
        error: true,
        helperText: <FormattedMessage id="DatePicker.wrongDateRange" />,
      });
    } else {
      setErrorDateRange({});
    }
  };

  const onDateToChange = (value: Moment) => {
    const newValue = moment(value).endOf("day");
    setDateTo(newValue);
    if (newValue.isBefore(moment(dateFrom))) {
      setErrorDateRange({
        error: true,
        helperText: <FormattedMessage id="DatePicker.wrongDateRange" />,
      });
    } else {
      setErrorDateRange({});
    }
  };

  const handleRadioGroupChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setReportType((event.target as HTMLInputElement).value);
  };

  const lastStatisticsReportDateRange = `1.1. - 31.12.${moment().year() - 1}`;

  return (
    <Dialog
      classes={{ paper: classes.dialogContainer }}
      data-test="report-fertilizers-dialog"
      onClose={onClose}
      open={showDialog}
    >
      <DialogTitle data-test="dialog-title">
        <FormattedMessage id="Reports.fertilizerUsageReport.dialogTitle" />
      </DialogTitle>
      <DialogContent>
        <Grid className={classes.itemContainer}>
          <Grid className={classes.radioGroupWrapper} item xs={5}>
            <DialogContentText className={classes.label}>
              <FormattedMessage id="Reports.fertilizerUsageReport.dialogTypeLabel" />
            </DialogContentText>
            <RadioGroup
              aria-labelledby="controlled-radio-buttons-group"
              name="radio-buttons-group"
              onChange={handleRadioGroupChange}
              value={reportType}
            >
              <FormControlLabel
                control={<Radio size="small" />}
                value={EagriFertilizerUseReportType.STATISTICS}
                label={
                  <FormattedMessage id="Reports.fertilizerUsageReport.dialogStatisticsType" />
                }
              />
              <FormControlLabel
                control={<Radio size="small" />}
                value={EagriFertilizerUseReportType.CHECK}
                label={
                  <FormattedMessage id="Reports.fertilizerUsageReport.dialogCheckType" />
                }
              />
            </RadioGroup>
          </Grid>
          {reportType === EagriFertilizerUseReportType.CHECK && (
            <div className={classes.dateSelectorWrapper}>
              <Grid className={classes.grid} item xs={5}>
                <CfDatePicker
                  label={<FormattedMessage id="common.date-from" />}
                  maxDate={undefined}
                  maxDateMessage=""
                  minDate={undefined}
                  minDateMessage=""
                  name="dateFrom"
                  input={{
                    value: dateFrom,
                    onChange: onDateFromChange,
                  }}
                  {...errorDateRange}
                />
              </Grid>
              <Grid item xs={5}>
                <CfDatePicker
                  label={<FormattedMessage id="common.date-to" />}
                  maxDate={undefined}
                  maxDateMessage=""
                  minDate={undefined}
                  minDateMessage=""
                  name="dateTo"
                  input={{
                    value: dateTo,
                    onChange: onDateToChange,
                  }}
                  {...errorDateRange}
                />
              </Grid>
            </div>
          )}
          {reportType === EagriFertilizerUseReportType.STATISTICS && (
            <>
              <div className={classes.label}>
                <FormattedMessage id="Reports.fertilizerUsageReport.dialogStatisticsReportInfo" />
              </div>
              <div className={classes.statisticsDateRange}>
                {lastStatisticsReportDateRange}
              </div>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          alignItems="center"
          container
          justifyContent="flex-end"
          spacing={1}
        >
          <Button
            className={classes.button}
            id="reset"
            onClick={onClose}
            type="reset"
            variant="text"
          >
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button
            className={classes.button}
            color="primary"
            disabled={errorDateRange.error}
            id="send"
            onClick={handleSendReport}
            type="submit"
            variant="text"
          >
            <FormattedMessage id="common.send" />
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default FertilizerUsageReportDialog;
