import React from "react";

import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import CfNoData from "../../../../../shared/components/charts/CfNoData/CfNoData";
import HorizontalPointsChart, {
  ChartPoint,
} from "../../../containers/biomonitoring/HorizontalPointsChart/HorizontalPointsChart";

interface Props {
  data: ChartPoint[];
  averageQuality?: number;
}

const QualityZonesChart = ({ averageQuality, data }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      {data && data.length > 0 ? (
        <>
          <div className={classes.header}>
            <span className={classes.headerAreaZone}>
              <FormattedMessage id="ManagementZones.AreaZonesChart.zoneQuality.title" />
              {" (%)"}
            </span>
            <span className={classes.headerTotalArea}>
              <span style={{ fontWeight: 400 }}>
                <FormattedMessage id="ManagementZones.AreaZonesChart.averageQuality.title" />
                :
              </span>

              {` ${averageQuality ?? 0} %`}
            </span>
          </div>
          <HorizontalPointsChart data={data} />
        </>
      ) : (
        <CfNoData />
      )}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    margin: "0 15px",
    position: "relative",
    minHeight: 103,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginBottom: 12,
  },
  headerAreaZone: {
    fontSize: 14,
    fontWeight: 500,
    whiteSpace: "nowrap",
  },
  headerTotalArea: {
    fontSize: 14,
    fontWeight: 600,
    color: "#848484",
    whiteSpace: "nowrap",
  },
}));

export default QualityZonesChart;
