import React, { ReactNode } from "react";

import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { Breakpoint, IconButton, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import { SimpleDialog } from "../../../../shared/components/common/SimpleDialog/SimpleDialog";
import { useToggle } from "../../../../shared/hooks/useToggle";

type Props = {
  title?: ReactNode;
  infoDialogContent?: ReactNode;
  hintTranslation?: ReactNode;
  maxDialogWidth?: Breakpoint;
};

const InfoDialog = ({
  hintTranslation,
  infoDialogContent,
  maxDialogWidth,
  title,
}: Props) => {
  const { on: open, setOff: onClose, setOn: onOpen } = useToggle();

  const classes = useStyles();

  return (
    <SimpleDialog.Root
      maxWidth={maxDialogWidth}
      onClose={onClose}
      open={open}
      openButton={
        <IconButton
          aria-label="Information"
          className={classes.infoButton}
          focusRipple={false}
          onClick={onOpen}
          size="medium"
        >
          <InfoOutlined />
        </IconButton>
      }
    >
      <SimpleDialog.Title>{title}</SimpleDialog.Title>
      <SimpleDialog.Content>
        <>
          {hintTranslation && (
            <Grid alignItems="flex-start" container>
              <Grid item xs={12}>
                <Typography>{hintTranslation}</Typography>
              </Grid>
            </Grid>
          )}
          {infoDialogContent}
        </>
      </SimpleDialog.Content>
      <SimpleDialog.Actions>
        <Button autoFocus color="primary" onClick={onClose}>
          <FormattedMessage id="common.close" />
        </Button>
      </SimpleDialog.Actions>
    </SimpleDialog.Root>
  );
};

const useStyles = makeStyles(() => ({
  infoButton: {
    padding: 3,
  },
}));

export { InfoDialog };
