import { createSelector } from "reselect";

import {
  getSectionListSearch,
  getSectionListOrder,
  getSectionListOrderBy,
  getSectionListPage,
  getSectionListRowsPerPage,
} from "./sectionList.selectors";
import { getPlantProtectionDetailApplications } from "../../shared/api/agroevidence/catalogues/plantProtection/plantProtection.selectors";

import {
  paginationArray,
  sortArray,
} from "../../shared/misc/arraysAction.helpers";

const getApplicationsFiltered = createSelector(
  getPlantProtectionDetailApplications,
  getSectionListSearch,
  (applications, search) => {
    if (search.length === 0) return applications;
    const searchLowerCase = search.toLowerCase();

    const applicationsFiltered = applications.filter(
      (application) =>
        application.crop?.name?.toLowerCase().includes(searchLowerCase) ||
        application.organism?.name?.toLowerCase().includes(searchLowerCase),
    );
    return applicationsFiltered;
  },
);

export const getApplicationsSortedPaginated = createSelector(
  getApplicationsFiltered,
  getSectionListOrder,
  getSectionListOrderBy,
  getSectionListPage,
  getSectionListRowsPerPage,
  (applicationsFiltered, order, orderBy = "crop", page, rowsPerPage) => {
    const parcelsSorted = sortArray(order, orderBy, applicationsFiltered);
    return paginationArray(page, rowsPerPage, parcelsSorted);
  },
);

export const getApplicationsTotalCount = createSelector(
  getPlantProtectionDetailApplications,
  getSectionListSearch,
  getApplicationsFiltered,
  (applications, search, applicationsFiltered) => {
    if (search.length === 0) return applications.length;
    return applicationsFiltered.length;
  },
);
