import React from "react";

import ClearIcon from "@mui/icons-material/Clear";
import { Theme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import { makeStyles } from "@mui/styles";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    background: theme.palette.secondary.light,
    color: theme.palette.text.primary,
    flexWrap: "nowrap",
  },
  snackbar: {
    bottom: 0,
  },
}));

type Props = {
  clearNoContentReports: () => void;
  noContentReports: (string | number)[];
};

const NoContentReportMessage = ({
  clearNoContentReports,
  noContentReports = [],
}: Props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const { formatMessage } = useIntl();

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    clearNoContentReports();
  };

  const noDataMessage = formatMessage({ id: "Report.noDataMessage" });
  const noDataReportsMessages = noContentReports
    .map((id) => formatMessage({ id: `Report.${id}` }))
    .join(", ");

  return (
    <Snackbar
      autoHideDuration={10000}
      className={classes.snackbar}
      message={`${noDataMessage} ${noDataReportsMessages}`}
      onClose={handleClose}
      open={open}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          onClick={handleClose}
          size="small"
        >
          <ClearIcon />
        </IconButton>
      }
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      ContentProps={{
        classes: {
          root: classes.root,
        },
      }}
    />
  );
};

export default NoContentReportMessage;
