import React, { PropsWithChildren, useEffect } from "react";

import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { alpha, Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";

import { setBottomPanelHeight } from "../../actions/mainMapTelematics/mainMapTelematics.actions";

import useHeightObserver from "../../../../shared/hooks/useHeightObserver";

type Props = {
  onPanelClose: () => void;
};

const Wrapper = ({ children, onPanelClose }: PropsWithChildren<Props>) => {
  const classes = useStyles();
  const { elementRef, height } = useHeightObserver();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBottomPanelHeight(height));
    return () => {
      dispatch(setBottomPanelHeight(0));
    };
  }, [dispatch, height]);

  return (
    <div
      className={classes.wrapper}
      data-test="map-bottom-panel"
      ref={elementRef}
    >
      <div className={classes.content}>{children}</div>
      <IconButton
        aria-label="Close detail"
        className={classes.closeButton}
        data-test="map-bottom-close"
        onClick={onPanelClose}
        size="large"
      >
        <CloseIcon />
      </IconButton>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: "absolute",
    bottom: 0,
    right: 0,
    left: 0,
    margin: "auto",
    boxShadow: `0 0 2px 0 ${alpha(theme.palette.common.black, 0.12)},
     0 2px 2px 0 ${alpha(theme.palette.common.black, 0.24)}`,
    backgroundColor: theme.palette.common.white,
    zIndex: 19,
    maxWidth: 1000,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "75%",
    },
  },
  closeButton: {
    position: "absolute",
    top: 0,
    right: 0,
    width: 36,
    height: 36,
    padding: 0,
  },
  content: {
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
    fontSize: 13,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
}));

export default Wrapper;
