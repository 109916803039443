import React, { useEffect } from "react";

import { TableBody } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  getAccountFarms,
  getAccountFarmsTotalCount,
  isFetchingAccountFarms,
} from "../../../../shared/api/agroevidence/farms/admin/farmsAdmin.selectors";

import { fetchAccountFarms } from "../actions/farms.actions";

import { NAMESPACE } from "../reducer/farms.reducer";

import CfTableFooter from "../../../../common/components/CfTableFooter/CfTableFooter";
import { useCfTableFooter } from "../../../../common/components/CfTableFooter/useCfTableFooter";
import CfTableHead from "../../../../common/components/CfTableHead/CfTableHead";
import { useCfTableHead } from "../../../../common/components/CfTableHead/useCfTableHead";
import CfTableBodyEmpty from "../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableBodyLoader from "../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader";
import CfTableWrapper from "../../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import { getColDesc } from "../../../../shared/misc/helper";
import DoubleLinedHeader from "../../../../telematics/components/DoubleLinedHeader/DoubleLinedHeader";
import {
  getFarmsAdminOrder,
  getFarmsAdminOrderBy,
  getFarmsAdminPage,
  getFarmsAdminRowsPerPage,
  getFarmsAdminTextFilter,
} from "../selectors/farms.selector";

import { Row } from "./Row";

const COLUMNS = {
  name: getColDesc(
    true,
    <DoubleLinedHeader
      leftOffset
      primaryId="TelematicsAdmin.columns.farm"
      secondaryId="TelematicsAdmin.columns.id"
    />,
    { width: "300px" },
  ),
  countryCode: getColDesc(
    true,
    <FormattedMessage id="TelematicsAdmin.columns.country" />,
  ),
  geoserver: getColDesc(false, "Geoserver"),
};

const FarmsAdminTable = () => {
  const accountFarms = useSelector(getAccountFarms);
  const isFetchingFarms = useSelector(isFetchingAccountFarms);
  const order = useSelector(getFarmsAdminOrder);
  const orderBy = useSelector(getFarmsAdminOrderBy);
  const page = useSelector(getFarmsAdminPage);
  const rowsPerPage = useSelector(getFarmsAdminRowsPerPage);
  const totalCount = useSelector(getAccountFarmsTotalCount);
  const textFilter = useSelector(getFarmsAdminTextFilter);
  const dispatch = useDispatch();
  const { onSelect, onSort } = useCfTableHead(NAMESPACE);

  const { onPageChange, onRowsPerPageChange } = useCfTableFooter(NAMESPACE);

  useEffect(() => {
    dispatch(fetchAccountFarms());
  }, [order, orderBy, page, rowsPerPage, textFilter, dispatch]);

  return (
    <CfTableWrapper>
      <CfTableHead
        columns={COLUMNS}
        items={accountFarms}
        onSelect={onSelect}
        onSort={onSort}
        order={order}
        orderBy={orderBy}
      />
      {isFetchingFarms && <CfTableBodyLoader columns={COLUMNS} />}

      {!isFetchingFarms && accountFarms?.length ? (
        <TableBody>
          {accountFarms.map((item) => (
            <Row item={item} key={item.id} />
          ))}
        </TableBody>
      ) : (
        <CfTableBodyEmpty colLength={Object.keys(COLUMNS).length + 1} />
      )}

      <CfTableFooter
        count={totalCount}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </CfTableWrapper>
  );
};

export { FarmsAdminTable };
