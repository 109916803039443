import { useRef, useEffect, useState } from "react";

const useObserveHeight = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    if (ref.current) {
      const observer = new ResizeObserver((entries) => {
        entries.forEach((entry: ResizeObserverEntry) => {
          setHeight(entry.contentRect.height);
        });
      });

      observer.observe(ref.current);

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return {
    elementRef: ref,
    height,
  };
};

export default useObserveHeight;
