import React from "react";

import PropTypes from "prop-types";
import { Router } from "react-router-dom";

import { LANG_KEYS } from "../../../../shared/lang/lang.constants";

import BaseContainer from "../../../../shared/containers/BaseContainer/BaseContainer";
import ProductFruitsProvider from "../../../../shared/containers/ProductFruits/ProductFruits";
import { Navbar } from "../../components/Navbar/Navbar";
import translationsCZ from "../../lang/locale-cs-CZ.json";
import translationsEL from "../../lang/locale-el-GR.json";
import translationsEN from "../../lang/locale-en-US.json";
import translationsES from "../../lang/locale-es-ES.json";
import translationsHU from "../../lang/locale-hu-HU.json";
import translationsPL from "../../lang/locale-pl-PL.json";
import translationsRO from "../../lang/locale-ro-RO.json";
import translationsRU from "../../lang/locale-ru-UA.json";
import translationsSR from "../../lang/locale-sr-LATN-RS.json";

const translations = {
  [LANG_KEYS.CZ]: translationsCZ,
  [LANG_KEYS.EN]: translationsEN,
  [LANG_KEYS.HU]: translationsHU,
  [LANG_KEYS.PL]: translationsPL,
  [LANG_KEYS.SR]: translationsSR,
  [LANG_KEYS.ES]: translationsES,
  [LANG_KEYS.RU]: translationsRU,
  [LANG_KEYS.EL]: translationsEL,
  [LANG_KEYS.RO]: translationsRO,
};

const NavbarWrapper = (props) => {
  const { history, ngProps } = props;
  return (
    <Router history={history}>
      <BaseContainer langId={ngProps.langId} translations={translations}>
        <ProductFruitsProvider
          account={ngProps.user}
          farm={ngProps.farm}
          langId={ngProps.langId}
          projectId={ngProps.productFruitsId}
        >
          <Navbar ngProps={ngProps} />
        </ProductFruitsProvider>
      </BaseContainer>
    </Router>
  );
};

NavbarWrapper.propTypes = {
  ngProps: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default NavbarWrapper;
