import React, { ReactNode, useEffect } from "react";

import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  getBioFunctions,
  getIsFetchingBioFunctions,
} from "../../../../../shared/api/agroevidence/catalogues/plantProtection/plantProtection.selectors";

import { fetchBioFunctions } from "../../../../actions/catalogues.actions";

import { resetBioFunctionApi } from "../../../../../shared/api/agroevidence/catalogues/plantProtection/plantProtection.api";
import CfAutocomplete from "../../../../../shared/components/common/CfAutocomplete/CfAutocomplete";

type Props = {
  defaultValues?: string;
  disabled?: boolean;
  error?: boolean;
  label: ReactNode;
  onChange: (items: string) => void;
};

export const BioFunctionSelector = ({
  defaultValues,
  disabled = false,
  error = false,
  label,
  onChange,
}: Props) => {
  const dispatch = useDispatch();
  const bioFunctions = useSelector(getBioFunctions);
  const isBioFunctionsFetching = useSelector(getIsFetchingBioFunctions);

  useEffect(() => {
    dispatch(fetchBioFunctions());

    return () => {
      dispatch(resetBioFunctionApi());
    };
  }, [dispatch]);

  const handleRenderOption = (option: string) => option;

  return (
    <CfAutocomplete
      defaultValues={defaultValues}
      disabled={disabled}
      error={error}
      helperText={error ? <FormattedMessage id="validation.required" /> : ""}
      id="bio-functions-selector"
      isFetching={isBioFunctionsFetching}
      isMultiple={false}
      label={label}
      loadOptions={() => dispatch(fetchBioFunctions())}
      onChange={onChange}
      renderOption={handleRenderOption}
      suggestions={bioFunctions}
      testId="bio-functions-selector"
    />
  );
};
