import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import editorUI from "../core/map/reducer/editor.reducer";
import layersUI from "../core/map/reducer/layersUI.reducer";
import mapUI from "../core/map/reducer/map.reducer";
import actionListUI from "../evidence/actions/list/reducer/actionList.reducer";
import agroevidenceActionsApi from "../shared/api/agroevidence/actions/actions.reducer";
import cropsApi from "../shared/api/agroevidence/catalogues/crops/crops.reducer";
import cataloguesEagriApi from "../shared/api/agroevidence/catalogues/eagri/eagri.reducer";
import fertilizersApi from "../shared/api/agroevidence/catalogues/fertilizers/fertilizers.reducer";
import plantProtectionApi from "../shared/api/agroevidence/catalogues/plantProtection/plantProtection.reducer";
import seedsApi from "../shared/api/agroevidence/catalogues/seeds/seeds.reducer";
import parcelsApi from "../shared/api/agroevidence/parcels/parcels.reducer";
import subtractableAreasApi from "../shared/api/agroevidence/subtractableAreas/subtractableAreas.reducer";
import zonesApi from "../shared/api/agroevidence/zones/zones.reducer";
import dataApi from "../shared/api/api.reducer";
import unitsApi from "../shared/api/core/units/units.reducer";
import authReducer from "../shared/api/gateway/auth/auth.reducer";
import layersApi from "../shared/api/other/layers/layers.reducer";
import AgIntegrationStatusApi from "../shared/api/sentinel/agiStatus/agiStatus.reducer";
import managementZonesApi from "../shared/api/sentinel/managementZones/managementZones.reducer";
import variableApplicationApi from "../shared/api/sentinel/variableApplication/variableApplication.reducer";
import aggregationsApi from "../shared/api/telematics/aggregations/aggregations.reducer";

export default (history) =>
  combineReducers({
    ui: combineReducers({
      eph: combineReducers({
        additions: parcelsApi("additions"),
        suggestions: parcelsApi("suggestions"),
      }),
      vrf: combineReducers({
        additions: parcelsApi("additions"),
        suggestions: parcelsApi("suggestions"),
      }),
      vrs: combineReducers({
        additions: parcelsApi("additions"),
        suggestions: parcelsApi("suggestions"),
      }),
      other: combineReducers({
        additions: parcelsApi("additions"),
        suggestions: parcelsApi("suggestions"),
      }),
      actionList: actionListUI(),
      editor: editorUI(),
      map: mapUI(),
      layers: layersUI(),
      cache: () => ["actionList"],
    }),
    api: combineReducers({
      aggregations: aggregationsApi(),
      agroevidence: combineReducers({
        actions: agroevidenceActionsApi(),
        crops: cropsApi(),
        eagri: cataloguesEagriApi(),
        fertilizers: fertilizersApi(),
        parcels: parcelsApi(),
        plantProtection: plantProtectionApi(),
        seeds: seedsApi(),
        subtractions: subtractableAreasApi(),
      }),
      zones: zonesApi(),
      management: managementZonesApi(),
      soilSamples: dataApi("PRECISION", "GET_SOIL_SAMPLES"),
      layers: layersApi(),
      variableApplication: variableApplicationApi(),
      units: unitsApi(),
    }),
    router: connectRouter(history),
    form: formReducer,
    AGIStatus: AgIntegrationStatusApi(),
    auth: authReducer,
  });
