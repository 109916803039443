import { toNumber } from "lodash";

import { AnyTodo } from "../../../../types";

import { SubtractionResponse } from "../../../../shared/api/agroevidence/agroevidence.types";
import { InitialParcelToAdd } from "../../ActionOthers/actionOther.types";

export const mapRequestBodyParcelsSubtractionArea = (
  parcels: InitialParcelToAdd[],
) =>
  // actions that use Formik
  ({
    parcelRestrictions: parcels.map((parcel: InitialParcelToAdd) => ({
      parcelId: parcel.id,
      restrictions: [
        ...parcel.subtractableAreas.absolute
          .filter((sa: SubtractionResponse) => sa.isUsed === true)
          .map(({ type, value }) => ({
            type,
            value,
          })),
        ...(toNumber(parcel.subtractableAreas.boundaryChecked) !== 0
          ? [
              {
                type: "Boundary",
                value: parcel.subtractableAreas.boundaryChecked,
              },
            ]
          : []),
        ...(toNumber(parcel.subtractableAreas.waterChecked) !== 0
          ? [
              {
                type: "Water",
                value: parcel.subtractableAreas.waterChecked,
              },
            ]
          : []),
      ],
    })),
  });

export const mapRequestBodyParcelsSubtraction = (
  parcels: AnyTodo,
  expenses?: AnyTodo,
  targetCrop?: AnyTodo,
) =>
  // actions that use reduxForm
  ({
    restrictions: parcels.map((parcel: AnyTodo) => ({
      parcelId: parcel.id,
      restrictions: [
        ...parcel.subtractableAreas.map(
          ({ isMaterial, isUsed, type, value }: AnyTodo) => ({
            type,
            value,
            isMaterial,
            isUsed,
          }),
        ),
      ],
    })),
    materials: expenses ? mapMaterials(expenses, targetCrop) : [],
  });

const mapMaterials = (expenses: AnyTodo, targetCrop: AnyTodo) =>
  expenses.map((expens: AnyTodo) => ({
    plantProtectionId: expens.material.id,
    targetOrganismIds: expens.pests.map((pest: AnyTodo) => pest.id),
    targetCropId: targetCrop.id,
    driftClass: expens.driftClass,
  }));

export const mapRequestBodyParcelSubtractionsGeometry = (
  parcelId: AnyTodo,
  subtractableAreas: AnyTodo,
) => ({
  parcelId,
  restrictions: [
    ...subtractableAreas
      .filter((sa: AnyTodo) => sa.isUsed === true)
      .map(({ type, value }: AnyTodo) => ({ type, value })),
  ],
});
