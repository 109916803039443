import React, { Fragment } from "react";

import CreateIcon from "@mui/icons-material/Create";
import { Grid, IconButton, Paper, Stack } from "@mui/material";

import { useToggle } from "../../../../../../shared/hooks/useToggle";
import { useParcelOverviewStyles } from "../../parcelOverview.styles";

import EditParcelZonesModal from "./EditParcelZonesModal";

import { ParcelDetailTo } from "../../../../../../shared/api/agroevidence/agroevidence.types";

interface Props {
  parcel: ParcelDetailTo;
}

const ParcelZones = ({ parcel }: Props) => {
  const classes = useParcelOverviewStyles();
  const {
    on: isModalOpened,
    setOff: handleModalClose,
    setOn: handleModalOpen,
  } = useToggle();

  return (
    <Fragment>
      <Grid className={classes.panelItem} data-test="parcel-zones" item xs={12}>
        <Paper className={classes.zonesPaper}>
          {parcel.zones?.length === 0 && (
            <b className={classes.zonesItems}>-</b>
          )}
          <Stack className={classes.zonesItems} direction="column">
            {parcel.zones?.map((zone) => (
              <span key={zone.id}>{zone.name}</span>
            ))}
          </Stack>
          <IconButton
            color="default"
            data-test="edit-icon"
            onClick={handleModalOpen}
            size="large"
          >
            <CreateIcon />
          </IconButton>
        </Paper>
      </Grid>

      <EditParcelZonesModal
        onClose={handleModalClose}
        open={isModalOpened}
        parcel={parcel}
      />
    </Fragment>
  );
};

export { ParcelZones };
