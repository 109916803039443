import React from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";

import { ManagementZoneIcon } from "../../../../../shared/icons/ManagementZoneIcon";

import { ZoneDto } from "../../../../../shared/api/satellite/satellite.types";

type Props = {
  zones: ZoneDto[];
  classes?: Record<string, string>;
};

const GeneralizedZoneManagementLegend = ({ classes, zones }: Props) => {
  const defClasses = useStyles();

  return (
    <div className={defClasses.wrapper}>
      {zones.map((zone) => (
        <span
          className={defClasses.item}
          data-test="management-zones-legend"
          key={zone.index}
        >
          <span className={defClasses.icon}>
            <ManagementZoneIcon color={zone.color} display="block" />
          </span>
          <span className={classNames(defClasses.zone, classes?.zone)}>
            <FormattedMessage id="common.zone2" />
            {`: ${zone.index}`}
          </span>
        </span>
      ))}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    marginTop: 6,
  },
  zone: {
    margin: "0 12px 0 5px",
    fontSize: 12,
    color: theme.palette.grey[500],
  },
  icon: {
    width: 10,
    height: 10,
  },
  item: {
    display: "flex",
    alignItems: "center",
  },
}));

export default GeneralizedZoneManagementLegend;
