import angular from "angular";

import "angular-http-auth";
import "angular-utf8-base64";
import "ui-select";
import "angular-file-upload";
import "angular-dynamic-locale";
import "moment";

import ngSanitize from "angular-sanitize";
import uiRouter from "@uirouter/angularjs";

import dropdown from "angular-ui-bootstrap/src/dropdown";
import modal from "angular-ui-bootstrap/src/modal";
import tabs from "angular-ui-bootstrap/src/tabs";
import collapse from "angular-ui-bootstrap/src/collapse";
import accordion from "angular-ui-bootstrap/src/accordion";
import datepicker from "angular-ui-bootstrap/src/datepicker";
import datepickerPopup from "angular-ui-bootstrap/src/datepickerPopup";
import tooltip from "angular-ui-bootstrap/src/tooltip";
import typeahead from "angular-ui-bootstrap/src/typeahead";

import ngFileSaver from "angular-file-saver";
import ngAnimate from "angular-animate";
import restangular from "restangular";
import ngCookies from "angular-cookies";

import { ngTableModule } from "ng-table";

import { Angular as AngularIntegration } from "@sentry/integrations";

import services from "./services/services.module";

export default angular.module("app.common", [
  "ui.select",
  "utf8-base64",
  "angularFileUpload",
  "http-auth-interceptor",
  "tmh.dynamicLocale",
  AngularIntegration.moduleName,
  ngCookies,
  ngAnimate,
  ngSanitize,
  uiRouter,
  dropdown,
  modal,
  tabs,
  collapse,
  accordion,
  datepicker,
  datepickerPopup,
  tooltip,
  typeahead,
  ngFileSaver,
  ngTableModule.name,
  restangular,
  services,
]).name;
