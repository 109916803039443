import React, { useEffect } from "react";

import moment from "moment";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  getMonitoringCropIntervalsIsFetching,
  getRecords,
  getRecordsIsFetching,
  getRecordsValidity,
} from "../../../../shared/api/sentinel/monitoring/monitoring.selectors";
import {
  getCropIntervals,
  getDateFrom,
  getDateTo,
  getIndex,
  getMonitoringStatus,
} from "../../selectors/monitoring.selectors";

import { setMonitoringInterval } from "../../actions/monitoring.actions";

import * as satelliteProductsTypes from "../../../../shared/constants/satelliteProductsTypes.constants";

import {
  getBiomonitoringIndexRecordsApi,
  getCropIntervalsApi,
} from "../../../../shared/api/sentinel/monitoring/monitoring.api";
import { getShortDateString } from "../../../../shared/misc/timeHelpers";
import { BioMonitoringHistoryInfoDialog } from "../../components/BioMonitoring/BioMonitoringHistoryInfoDialog";
import BioMonitoringImageErrorService from "../../services/BioMonitoringImageError.services";
import { ChartDataOrMonitoringData } from "../BioMonitoring";

import { IndexTypeDataType } from "./types";

import { PrecisionState } from "../../../../reducers/precision.reducer.types";
import {
  BiomonitoringIndexRecordDto,
  BiomonitoringIndexType,
  IndexType,
} from "../../../../shared/api/satellite/satellite.types";

const useSaviBioMonitoring = (parcelId: string) => {
  const dispatch = useDispatch();

  const index = useSelector((state: PrecisionState) => getIndex(state));
  const dateFrom = useSelector((state: PrecisionState) => getDateFrom(state));
  const dateTo = useSelector((state: PrecisionState) => getDateTo(state));
  const monitoringStatus = useSelector((state: PrecisionState) =>
    getMonitoringStatus(state),
  );
  const records = useSelector((state: PrecisionState) => getRecords(state));
  const isFetchingRecords = useSelector((state: PrecisionState) =>
    getRecordsIsFetching(state),
  );
  const validity = useSelector((state: PrecisionState) =>
    getRecordsValidity(state),
  );
  const cropIntervals = useSelector((state: PrecisionState) =>
    getCropIntervals(state),
  );
  const isFetchingCropIntervals = useSelector((state: PrecisionState) =>
    getMonitoringCropIntervalsIsFetching(state),
  );

  const isFetching = isFetchingRecords || isFetchingCropIntervals;

  useEffect(() => {
    if (
      monitoringStatus === satelliteProductsTypes.ACTIVE ||
      monitoringStatus === satelliteProductsTypes.HISTORICAL
    ) {
      dispatch(getCropIntervalsApi(parcelId, IndexType.SAVI));
    }
  }, [dispatch, monitoringStatus, parcelId]);

  useEffect(() => {
    if (dateFrom) {
      dispatch(
        getBiomonitoringIndexRecordsApi({
          "date-from": dateFrom,
          "date-to": dateTo.length > 0 ? dateTo : getShortDateString(),
          "index-type": index,
          parcelId,
        }),
      );
    }
  }, [dateFrom, dateTo, dispatch, index, parcelId]);

  const getChartValue = (r: BiomonitoringIndexRecordDto) => {
    const average = r.average;
    if (average === undefined || average === null || average >= 0) {
      return average;
    } else {
      return 0;
    }
  };

  const data: ChartDataOrMonitoringData[] = (records?.records ?? []).map(
    (r) =>
      ({
        value: getChartValue(r),
        tooltipValue: r.average,
        average: r.average,
        crop: undefined,
        dateFrom: r.dateFrom,
        dateTo: r.dateTo,
        imgExtent: r.imgExtent,
        indexType: r.type,
        imgPath: r.rasterUrl,
        zones: r.zones.map((z) => ({
          color: z.color,
          geometry: {
            type: z.geometry.type,
            coordinates: z.geometry.coordinates,
            geometries: z.geometry.geometries,
          },
          zoneNumber: Number(z.index) || z.index,
          areaHa: -1,
          zoneValue: -1,
        })),
      }) as ChartDataOrMonitoringData,
  );

  const overallImagesError =
    BioMonitoringImageErrorService.getOverallImagesError(data, index);
  const cropHarvestedError =
    BioMonitoringImageErrorService.checkCropHarvestedError(data);
  const lastImageDateTo = validity;

  const infoDialogContent =
    index === BiomonitoringIndexType.SAVI ? (
      <BioMonitoringHistoryInfoDialog.Root index={index}>
        <BioMonitoringHistoryInfoDialog.Footnote>
          <FormattedMessage
            id={`BioMonitoringInfoDialog.zoningExplanation.footnote.${index}`}
          />
        </BioMonitoringHistoryInfoDialog.Footnote>
      </BioMonitoringHistoryInfoDialog.Root>
    ) : undefined;

  useEffect(() => {
    if (!isFetching) {
      if (cropIntervals.length) {
        dispatch(
          setMonitoringInterval(cropIntervals[0].from, cropIntervals[0].to),
        );
      }
    }
  }, [cropIntervals, dispatch, isFetching]);

  return {
    isFetching,
    overallImagesError,
    cropHarvestedError,
    lastImageDateTo,
    data,
    infoDialogContent,
    cropIntervals,
    graphData: [...data].sort((a, b) =>
      moment(a.dateFrom).diff(moment(b.dateFrom)),
    ),
  } as IndexTypeDataType;
};

export { useSaviBioMonitoring };
