import { AnyAction } from "redux";
import { RSAAAction } from "redux-api-middleware";

import * as listSelectors from "../selectors/actionList.selectors";

import { setTableSelected } from "../../../../shared/actions/table.actions";

import * as types from "./actionList.constants";
import { DELETE_VARIABLE_BULK_IN_PROGRESS } from "../../../../shared/api/sentinel/variableApplication/variableApplication.constants";

import { NAMESPACE as namespace } from "../reducer/actionList.reducer";

import {
  getActionsApi,
  deleteActionsApi,
} from "../../../../shared/api/agroevidence/actions/actions.api";
import { deleteVariableExpenses as deleteVariableExpensesApi } from "../../../../shared/api/sentinel/variableApplication/variableApplication.api";
import { getShortDateString } from "../../../../shared/misc/timeHelpers";

import { ActionsState } from "../../../../reducers/actions.reducer.types";
import { getCommonParams } from "../../../../shared/api/agroevidence/actions/action.types";

export const fetchActions =
  () =>
  (dispatch: (action: RSAAAction) => void, getState: () => ActionsState) => {
    const state = getState();
    const advancedFilter = listSelectors.getActionsAdvancedFilter(state);
    const page = listSelectors.getPage(state);
    const rowsPerPage = listSelectors.getRowsPerPage(state);
    const order = listSelectors.getOrder(state);
    const orderBy = listSelectors.getOrderBy(state);
    const textFilter = listSelectors.getTextFilter(state);
    const dateFrom = listSelectors.getActionsDateFrom(state);
    const dateTo = listSelectors.getActionsDateTo(state);

    const params: getCommonParams = {
      page: page + 1,
      "per-page": rowsPerPage,
      "sort-dir": order,
      "sort-col": orderBy,
      search: textFilter || undefined,
      "target-crop-ids": advancedFilter.crops
        ?.map((crop) => crop.id)
        ?.join(","),
      parcelIds: advancedFilter.parcel?.map((parcel) => parcel.id)?.join(","),
      type: advancedFilter.activities
        ?.map((activity) => activity.name)
        ?.join(","),
      sources: advancedFilter.sources
        ?.map((source) => source.source)
        ?.join(","),
      from: dateFrom ? getShortDateString(dateFrom) : undefined,
      "date-to": dateTo ? getShortDateString(dateTo) : undefined,
      "is-draft": advancedFilter.draft?.state,
    };

    dispatch(getActionsApi(params) as unknown as RSAAAction);
  };

export const deleteActions =
  (actionIds: string[], variableActionIds: string[]) =>
  (dispatch: (action: AnyAction | RSAAAction) => Promise<unknown>) => {
    dispatch(setActionsBulkDeleteInProgress(true));
    dispatch(deleteActionsApi(actionIds) as unknown as RSAAAction)
      .then(() => {
        const promises: Promise<unknown>[] = [];
        if (variableActionIds?.length) {
          variableActionIds.forEach((variableActionId) => {
            if (actionIds.includes(variableActionId)) {
              promises.push(
                dispatch(
                  deleteVariableExpensesApi(
                    variableActionId,
                  ) as unknown as RSAAAction,
                ),
              );
            }
          });
        }

        return Promise.all(promises).then(() => {
          dispatch(fetchActions() as unknown as AnyAction);
        });
      })
      .finally(() => {
        dispatch(setTableSelected([], namespace));
        dispatch(setActionsBulkDeleteInProgress(false));
      });
  };

export const setActionsBulkDeleteInProgress = (payload: boolean) => ({
  type: DELETE_VARIABLE_BULK_IN_PROGRESS,
  payload,
});

export const setDate = (dateFrom: string, dateTo: string) => ({
  type: types.SET_DATE,
  dateFrom,
  dateTo,
});
