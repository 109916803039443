import React from "react";

import FormControlLabel from "@mui/material/FormControlLabel";
import { Field, FieldProps } from "formik";

import { ThreeStateControlTray } from "./ThreeStateControlTray/ThreeStateControlTray";

type Props = {
  name: string;
  label: React.ReactNode | string;
  classes: Record<string, string>;
};

const ThreeStateTrayFormControl = ({ classes, label, name }: Props) => (
  <FormControlLabel
    label={label}
    onClick={(e) => e.preventDefault()}
    classes={{
      root: classes.switchHistoricRoot,
      label: classes.switchHistoricLabel,
    }}
    control={
      <Field
        color="primary"
        id={name}
        label={null}
        name={name}
        render={({ field, form }: FieldProps<unknown>) => (
          <ThreeStateControlTray
            value={field.value as boolean}
            onChange={(clickedType) => {
              if (clickedType === "init") {
                form.setFieldValue(name, undefined);
              } else if (clickedType === "off") {
                form.setFieldValue(name, false);
              } else if (clickedType === "on") {
                form.setFieldValue(name, true);
              }
            }}
          />
        )}
      />
    }
  />
);

export { ThreeStateTrayFormControl };
