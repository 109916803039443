import React, { useEffect, useState, useContext } from "react";

import { Badge, IconButton, Tooltip, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedDate, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  getTosStatus,
  getTosStatusIsFetching,
} from "../../../shared/api/telematics/aggregations/aggregations.selectors";
import { getTelematicsAggregationsBulkEditMode } from "../../selectors/telematicsAggregations.selectors";

import { fetchAggregationsSaga } from "../../actions/telematicsAggregations.actions";

import TosIcon from "../../../assets/img/icons/telematics/tos.svg";
import {
  getTosStatusApi,
  sendToTosApi,
} from "../../../shared/api/telematics/aggregations/aggregations.api";
import CfDialog from "../../../shared/components/common/CfDialog/CfDialog";
import { SnackbarContext } from "../../../shared/containers/SnackbarProvider/SnackbarProvider";
import { useToggle } from "../../../shared/hooks/useToggle";
import { AsyncFn } from "../../../types";

const PayrollSystemSendButton = () => {
  const {
    on: dialogIsOpen,
    setOff: closeDialog,
    setOn: openDialog,
  } = useToggle();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const showSnackbar = useContext(SnackbarContext);

  const tosStatus = useSelector(getTosStatus);
  const tosStatusIsFetching = useSelector(getTosStatusIsFetching);
  const bulkEditMode = useSelector(getTelematicsAggregationsBulkEditMode);

  useEffect(() => {
    if (!isSubmitting) {
      dispatch(getTosStatusApi());
    }
  }, [dispatch, isSubmitting]);

  const handleAccept = () => {
    closeDialog();
    setIsSubmitting(true);

    dispatch((sendToTosApi as unknown as AsyncFn)())
      .then((res) => {
        if (!res.error) {
          return dispatch((sendToTosApi as unknown as AsyncFn)()).then(() => {
            dispatch(fetchAggregationsSaga());
          });
        } else {
          showSnackbar({
            message: <FormattedMessage id="TelematicsExport.error" />,
            isError: true,
          });
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  if (!tosStatus) return null;
  if (tosStatus.count === 0)
    return (
      <img
        alt="send to payroll system"
        className={classes.iconInactive}
        src={TosIcon}
      />
    );

  const isLoading = isSubmitting || tosStatusIsFetching;

  return (
    <>
      {isLoading && (
        <IconButton aria-label="Send to payroll system" disabled={bulkEditMode}>
          <CircularProgress size={18} />
        </IconButton>
      )}
      {!isLoading && (
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          placement="bottom"
          title={
            <FormattedMessage id="TelematicsAggregations.payrollSystem.tooltip" />
          }
        >
          <Badge
            badgeContent={tosStatus.count}
            classes={{ badge: classes.badge }}
            className={bulkEditMode ? classes.iconInactive : classes.iconActive}
            color="error"
            onClick={bulkEditMode ? undefined : () => openDialog()}
          >
            <IconButton
              aria-label="Send to payroll system"
              disabled={bulkEditMode}
            >
              <img alt="send to payroll system" src={TosIcon} />
            </IconButton>
          </Badge>
        </Tooltip>
      )}
      <CfDialog
        acceptText={<FormattedMessage id="common.confirm" />}
        cancelText={<FormattedMessage id="common.cancel" />}
        onAccept={handleAccept}
        onCancel={() => closeDialog()}
        opened={dialogIsOpen}
        title={
          <FormattedMessage id="TelematicsAggregations.payrollSystem.confirmationDialog.title" />
        }
      >
        <div>
          <p>
            <FormattedMessage
              id="TelematicsAggregations.payrollSystem.confirmationDialog.line1"
              values={{
                count: tosStatus.count,
                b: (chunks: React.ReactNode) => <b>{chunks}</b>,
              }}
            />
          </p>
          <p>
            <FormattedMessage id="TelematicsAggregations.payrollSystem.confirmationDialog.line2" />
            <span className={classes.bold}>
              <FormattedDate value={tosStatus.dateFrom} />
              {" - "}
              <FormattedDate value={tosStatus.dateTo} />
            </span>
          </p>
        </div>
      </CfDialog>
    </>
  );
};

const useStyles = makeStyles({
  bold: {
    fontWeight: 500,
  },
  badge: {
    top: 7,
    right: 11,
  },
  iconActive: {
    cursor: "pointer",
  },
  iconInactive: {
    opacity: 0.5,
  },
  tooltip: {
    maxWidth: 340,
  },
});

export default PayrollSystemSendButton;
