import React from "react";

import { IconButton, Tooltip, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  getMainMapTelematicsDashboardDrivesHistory,
  getMainMapTelematicsDashboardIsFetchingDrivesHistory,
  getMainMapTelematicsDashboardMachinesIsOpen,
} from "../../../../../shared/api/telematics/mainMapTelematics/dashboard/machinesDashboard.selectors";
import {
  getMainMapTelematicsisFetchingMachineDrivesHistory,
  getMainMapTelematicsMachineDrivesHistory,
} from "../../../../../shared/api/telematics/mainMapTelematics/mainMapTelematics.selectors";

import {
  fetchDashboardDrivesHistory,
  resetDashboardDrivesHistory,
} from "../../../../../shared/api/telematics/mainMapTelematics/dashboard/machinesDashboard.actions";
import {
  resetMachineDrivesHistory,
  setMachineFilter,
} from "../../../actions/mainMapTelematics/mainMapTelematics.actions";

import showOnMapIcon from "../../../../../assets/img/icons/telematics/showOnMap.svg";
import showOnMapActive from "../../../../../assets/img/icons/telematics/showOnMapActive.svg";
import showOnMapDisabled from "../../../../../assets/img/icons/telematics/showOnMapDisabled.svg";
import CfLoader from "../../../../../shared/components/common/CfLoader/CfLoader";

import {
  MachineTo,
  PositionDetailTo,
} from "../../../../../shared/api/telematics/telematics.types";

type Props = {
  machinePosition?: PositionDetailTo;
  disabled?: boolean;
};

const ShowOnMap = ({ disabled, machinePosition }: Props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const machineDashboardIsOpen = useSelector(
    getMainMapTelematicsDashboardMachinesIsOpen,
  );

  const dashboardDrivesHistory = useSelector(
    getMainMapTelematicsDashboardDrivesHistory,
  );
  const dashboardIsFetching = useSelector(
    getMainMapTelematicsDashboardIsFetchingDrivesHistory,
  );

  const machineDriveHistory = useSelector(
    getMainMapTelematicsMachineDrivesHistory,
  );
  const machineIsFetching = useSelector(
    getMainMapTelematicsisFetchingMachineDrivesHistory,
  );

  const isFetching = dashboardIsFetching || machineIsFetching;

  const historyIsActive =
    (machineDashboardIsOpen && !!dashboardDrivesHistory.length) ||
    (!machineDashboardIsOpen && !!machineDriveHistory?.length);

  const handleShowHistoryOnMap = () => {
    if (machineDashboardIsOpen) {
      if (historyIsActive) {
        dispatch(resetDashboardDrivesHistory());
      } else {
        dispatch(fetchDashboardDrivesHistory());
      }
    } else {
      const selectedMachine = machinePosition?.machine as MachineTo;
      if (historyIsActive) {
        dispatch(resetMachineDrivesHistory());
      } else {
        dispatch(setMachineFilter([selectedMachine]));
      }
    }
  };

  const getTitle = () => {
    if (machineDashboardIsOpen && disabled) {
      return (
        <FormattedMessage id="MainMapTelematicsDetail.showDrivingOnMapDashboard.disabled" />
      );
    } else if (machineDashboardIsOpen) {
      return (
        <FormattedMessage id="MainMapTelematicsDetail.showDrivingOnMapDashboard" />
      );
    } else {
      return <FormattedMessage id="MainMapTelematicsDetail.showDrivingOnMap" />;
    }
  };

  return (
    <Tooltip classes={{ tooltip: classes.tooltip }} title={getTitle()}>
      <span className={classes.showOnMap}>
        {isFetching && (
          <span style={{ padding: 12 }}>
            <CfLoader size={24} />
          </span>
        )}
        {!isFetching &&
          (historyIsActive ? (
            <IconButton
              aria-label="show on map active"
              disabled={disabled}
              onClick={handleShowHistoryOnMap}
              style={{ padding: 0 }}
            >
              <img alt="show on map active" src={showOnMapActive} width={48} />
            </IconButton>
          ) : (
            <IconButton
              aria-label="show on map"
              disabled={disabled}
              onClick={handleShowHistoryOnMap}
            >
              <img
                alt="show on map"
                src={disabled ? showOnMapDisabled : showOnMapIcon}
                width={24}
              />
            </IconButton>
          ))}
      </span>
    </Tooltip>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  showOnMap: {
    display: "grid",
    placeItems: "center",
  },
  tooltip: {
    [theme.breakpoints.up("md")]: {
      margin: "0 !important",
    },
  },
}));

export { ShowOnMap };
