import React from "react";

import PropTypes from "prop-types";

import { LANG_KEYS } from "../../../../shared/lang/lang.constants";

import BaseContainer from "../../../../shared/containers/BaseContainer/BaseContainer";
import translationsCZ from "../../../shared/lang/locale-cs-CZ.json";
import translationsEL from "../../../shared/lang/locale-el-GR.json";
import translationsEN from "../../../shared/lang/locale-en-US.json";
import translationsES from "../../../shared/lang/locale-es-ES.json";
import translationsHU from "../../../shared/lang/locale-hu-HU.json";
import translationsPL from "../../../shared/lang/locale-pl-PL.json";
import translationsRO from "../../../shared/lang/locale-ro-RO.json";
import translationsRU from "../../../shared/lang/locale-ru-UA.json";
import translationsSR from "../../../shared/lang/locale-sr-LATN-RS.json";
import ImpersonationPanel from "../ImpersonationPanel/ImpersonationPanel";

const translations = {
  [LANG_KEYS.CZ]: translationsCZ,
  [LANG_KEYS.EN]: translationsEN,
  [LANG_KEYS.SR]: translationsSR,
  [LANG_KEYS.ES]: translationsES,
  [LANG_KEYS.RU]: translationsRU,
  [LANG_KEYS.EL]: translationsEL,
  [LANG_KEYS.RO]: translationsRO,
  [LANG_KEYS.HU]: translationsHU,
  [LANG_KEYS.PL]: translationsPL,
};

const ImpersonationWrapper = (props) => (
  <BaseContainer langId={props.langId} translations={translations}>
    <ImpersonationPanel {...props} />
  </BaseContainer>
);

ImpersonationWrapper.propTypes = {
  langId: PropTypes.string.isRequired,
};

export default ImpersonationWrapper;
