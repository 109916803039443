import {
  IndexType,
  BiomonitoringIndexType,
} from "../../../shared/api/satellite/satellite.types";

export type IndiceType = IndexType | BiomonitoringIndexType;

export const indicesList = [
  { id: IndexType.LAI },
  { id: IndexType.CAB },
  { id: IndexType.CW },
  { id: IndexType.SAVI },
  // { id: BiomonitoringIndexType.RGB }, TODO: Temporarily hidden
];
