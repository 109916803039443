import { useDispatch } from "react-redux";

import {
  setTableSelected,
  setTableSorting,
} from "../../../shared/actions/table.actions";

const useCfTableHead = (namespace: string) => {
  const dispatch = useDispatch();

  const onSelect = (selected: unknown[]) => {
    dispatch(setTableSelected(selected, namespace));
  };

  const onSort = (order: string, colId: string) => {
    dispatch(setTableSorting(order, colId, namespace ?? null));
  };

  return {
    onSort,
    onSelect,
  };
};

export { useCfTableHead };
