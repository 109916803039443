/**
 * Check if this file still inuse
 */

/**
 * Created by brasko on 8.9.2015.
 */

import angular from "angular";

import Api from "./Api.service";
import ApiConfig from "./ApiConfig.service";
import ResourceUtils from "./ResourceUtils.service";

import RequestInterceptor from "./interceptors/RequestInterceptor.service";
import ResponseErrorInterceptor from "./interceptors/ResponseErrorInterceptor.service";
import ResponseSuccessInterceptor from "./interceptors/ResponseSuccessInterceptor.service";

import BatchesResource from "./resources/BatchesResource.service";
import FarmsResource from "./resources/FarmsResource.service";
import UsersResource from "./resources/UsersResource.service";

export default angular
  .module("app.api", [])
  .config(config)
  .factory("Api", Api)
  .factory("ApiConfig", ApiConfig)
  .factory("ResourceUtils", ResourceUtils)
  .factory("RequestInterceptor", RequestInterceptor)
  .factory("ResponseErrorInterceptor", ResponseErrorInterceptor)
  .factory("ResponseSuccessInterceptor", ResponseSuccessInterceptor)
  .factory("BatchesResource", BatchesResource)
  .factory("FarmsResource", FarmsResource)
  .factory("UsersResource", UsersResource).name;

/* @ngInject */
function config(RestangularProvider, $httpProvider) {
  $httpProvider.defaults.withCredentials = true;
  RestangularProvider.setDefaultHttpFields({
    withCredentials: true,
  });
}
