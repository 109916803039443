import { AnyAction } from "redux";

import * as commonFilterTypes from "../../../../shared/actions/filter.constants";
import * as commonTableTypes from "../../../../shared/actions/table.constants";
import { ROWS_PER_PAGE } from "../../../../shared/constants/rowsPerPage.constants";
import * as sortOrder from "../../../../shared/constants/sortOrder.constants";
import * as types from "../actions/actionList.constants";

import { ActionsListAdvancedFilter } from "../../../../shared/api/agroevidence/actions/action.types";
import { SortOrderType } from "../../../../shared/api/api.types";

export const NAMESPACE = "actionList";

export type ActionListUIState = {
  page: number;
  rowsPerPage: number;
  order: SortOrderType;
  orderBy: string;
  selected: string[];
  textFilter: string;
  advancedFilter: ActionsListAdvancedFilter;
  dateFrom?: string;
  dateTo?: string;
};

const initialState: ActionListUIState = {
  page: 0,
  rowsPerPage: ROWS_PER_PAGE,
  order: sortOrder.DESC,
  orderBy: "dateStart,id",
  selected: [],
  textFilter: "",
  dateFrom: undefined,
  dateTo: undefined,
  advancedFilter: {
    activities: [],
    sources: [],
  },
};

export default (reducerNamespace = NAMESPACE) =>
  (state = initialState, action: AnyAction) => {
    const { namespace } = action;
    if (namespace && namespace !== reducerNamespace) return state;

    switch (action.type) {
      case commonTableTypes.SET_TABLE_PAGE:
        return {
          ...state,
          page: action.page,
        };
      case commonTableTypes.SET_TABLE_ROWS_PER_PAGE:
        return {
          ...state,
          rowsPerPage: action.rowsPerPage,
          page: 0,
        };
      case commonTableTypes.SET_TABLE_ORDER:
        return {
          ...state,
          order: action.order,
          page: 0,
        };
      case commonTableTypes.SET_TABLE_ORDER_BY:
        return {
          ...state,
          orderBy: action.orderBy,
          page: 0,
        };
      case commonTableTypes.SET_TABLE_SELECTED:
        return {
          ...state,
          selected: action.selected,
        };
      case commonFilterTypes.SET_TEXT_FILTER:
        return {
          ...state,
          textFilter: action.textFilter,
          page: 0,
        };
      case types.SET_DATE:
        return {
          ...state,
          dateFrom: action.dateFrom,
          dateTo: action.dateTo,
          page: 0,
        };
      case commonFilterTypes.SET_ADVANCED_FILTER:
        return {
          ...state,
          advancedFilter: action.advancedFilter,
          page: 0,
        };

      default:
        return state;
    }
  };
