import React from "react";

import LinearProgress from "@mui/material/LinearProgress";
import MenuItem from "@mui/material/MenuItem";
import { Field } from "formik";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import {
  getHarvestProducts,
  getIsFetchingHarvestProducts,
} from "../../../../../shared/api/agroevidence/catalogues/eagri/eagri.selectors";

import CfFormControl from "../../../../../shared/components/form/CfFormControl/CfFormControl";
import CfFormikTextField from "../../../../../shared/components/form/CfFormikTextField/CfFormikTextField";
import * as validators from "../../../../../shared/misc/validators";

import { HarvestProductTo } from "../../../../../shared/api/agroevidence/agroevidence.types";

interface Props {
  isEditing: boolean;
  isSecondaryHarvestProduct?: boolean;
}

export const HarvestProductSelector = ({
  isEditing,
  isSecondaryHarvestProduct = false,
}: Props) => {
  const harvestProducts = useSelector(getHarvestProducts);
  const isFetchingHarvestProduct = useSelector(getIsFetchingHarvestProducts);

  if (isFetchingHarvestProduct) {
    return <LinearProgress color="secondary" />;
  }
  const isDisable = harvestProducts.length === 0 || !isEditing;
  const labelTranslationId = isSecondaryHarvestProduct
    ? "action.harvestProductSecondary"
    : "action.harvestProductMain";
  const name = isSecondaryHarvestProduct
    ? "secondaryHarvestProductId"
    : "mainHarvestProductId";

  return (
    <CfFormControl>
      <Field
        component={CfFormikTextField}
        disabled={isDisable}
        label={<FormattedMessage id={labelTranslationId} />}
        name={name}
        select
        validate={isSecondaryHarvestProduct ? undefined : validators.required}
        validateOnBlur
      >
        <MenuItem value={0}>-</MenuItem>
        {harvestProducts.map((item: HarvestProductTo) => (
          <MenuItem key={item.externalId} value={item.externalId}>
            {item.description}
          </MenuItem>
        ))}
      </Field>
    </CfFormControl>
  );
};
