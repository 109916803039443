import React from "react";

import { makeStyles } from "@mui/styles";

import { getLocalizedDateString } from "../../../../../shared/misc/timeHelpers";

import { ParcelProductDto } from "../../../../../shared/api/satellite/satellite.types";

type Props = {
  product?: ParcelProductDto;
};

const StatusWrapper = ({
  children: statusEl,
  product,
}: React.PropsWithChildren<Props>) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      {statusEl}
      <span className={classes.text}>
        {product ? getLocalizedDateString(product?.enabledDate) : undefined}
      </span>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    gap: 10,
    flexWrap: "wrap",
  },
  text: {
    lineHeight: "normal",
  },
}));

export { StatusWrapper };
