import React from "react";

import { Theme } from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { FormattedMessage, useIntl } from "react-intl";

import { AnyTodo } from "../../../../types";
import { FarmerPortalHeading } from "../shared";

const CSV = "csv";

const useStyles = makeStyles((theme: Theme) => ({
  info: {
    fontSize: 13,
    color: theme.palette.grey[500],
    marginBottom: 20,
    textAlign: "center",
  },
  button: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 30,
  },
  progress: {
    marginLeft: 10,
  },
}));

type Props = {
  isFetching: boolean;
  // onCsvExport: () => Promise<Record<string, any>>;
  // TODO: tahle služba není ve Swaggeru, tak nevím zda chodí vůbec params!
  onCsvExport: AnyTodo;
};

const SowingPlanCsvExport = ({ isFetching, onCsvExport }: Props) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const exportLabel = formatMessage({ id: "common.export" });

  return (
    <>
      <FarmerPortalHeading
        data-testid="sowing-plan-csv-export"
        titleTranslateString="Report.seedApplications"
      />
      <Grid className={classes.info} container justifyContent="center">
        <Grid item sm={2} xs={1} />
        <Grid item sm={8} xs={12}>
          <FormattedMessage id="Reports.sowingPlanInfo" />
        </Grid>
        <Grid item sm={2} xs={1} />
      </Grid>
      <div className={classes.button}>
        <Button
          color="primary"
          disabled={isFetching}
          id="sowing-plan"
          onClick={onCsvExport}
          variant="contained"
        >
          {`${exportLabel} ${CSV}`}
          {isFetching && (
            <CircularProgress className={classes.progress} size={21} />
          )}
        </Button>
      </div>
    </>
  );
};

export default SowingPlanCsvExport;
