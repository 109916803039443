import React from "react";

import { useSelector } from "react-redux";

import { getVarioDoc } from "../../../shared/api/sentinel/varioDoc/varioDoc.selectors";

import { useToggle } from "../../../shared/hooks/useToggle";

import AgActivationDialog from "./varioDocModals/AgActivationDialog";
import AgDeactivationDialog from "./varioDocModals/AgDeactivationDialog";
import VarioDocTableRow from "./VarioDocTableRow";

type Props = {
  shouldBeHidden: boolean;
};

const VarioDocRow = ({ shouldBeHidden }: Props) => {
  const varioDoc = useSelector(getVarioDoc);

  const {
    on: deactivationDialogOpen,
    setOff: closeDeactivationDialog,
    setOn: openDeactivationDialog,
  } = useToggle();
  const {
    on: activationDialogOpen,
    setOff: closeActivationDialog,
    setOn: openActivationDialog,
  } = useToggle();

  return (
    <>
      {!shouldBeHidden ? (
        <>
          <VarioDocTableRow
            buttonOnClick={openActivationDialog}
            data={varioDoc}
            editOnClick={openActivationDialog}
            switchOnClick={openDeactivationDialog}
          />
          {activationDialogOpen && (
            <AgActivationDialog
              data={varioDoc}
              onClose={closeActivationDialog}
              opened={activationDialogOpen}
            />
          )}
          {deactivationDialogOpen && (
            <AgDeactivationDialog
              onClose={closeDeactivationDialog}
              opened={deactivationDialogOpen}
            />
          )}
        </>
      ) : null}
    </>
  );
};

export default VarioDocRow;
