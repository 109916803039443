import React, { useEffect } from "react";

import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import { makeStyles } from "@mui/styles";
import { History } from "history";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  getAreas,
  getIsFetchingAreas,
  getAreasTotalCount,
} from "../../../../shared/api/irrigation/areas/areas.selectors";
import {
  getAreasOrder,
  getAreasPage,
  getAreasRowsPerPage,
  getAreasOrderBy,
} from "../../../selectors/areas.selectors";
import { getDevicesIdsForEachIrrigationArea } from "../../../selectors/map.selectors";

import {
  editorSetHoveredId,
  editorSetSelected,
} from "../../../../core/map/actions/editor/editor.actions";
import { fetchAreasList } from "../../../actions/areasList.actions";
import { setEnlargedVariant } from "../../../actions/map.actions";

import CfTableFooter from "../../../../common/components/CfTableFooter/CfTableFooter";
import { useCfTableFooter } from "../../../../common/components/CfTableFooter/useCfTableFooter";
import CfTableHead, {
  CfTableHeadColumnType,
} from "../../../../common/components/CfTableHead/CfTableHead";
import { useCfTableHead } from "../../../../common/components/CfTableHead/useCfTableHead";
import CfTableBodyLoader from "../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader";
import CfTableWrapper from "../../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import { setActivePage, setPrevPage } from "../../../actions/commonActions";
import EmptyAreas from "../../../components/EmptyAreas/EmptyAreas";

import AreaButtonsHeader from "./components/AreaButtonsHeader";
import AreaListRow from "./components/AreaListRow";

const NAMESPACE = "irrigationAreas";

const irrigationAreasTableColumns: CfTableHeadColumnType = {
  name: {
    sortable: true,
    label: <FormattedMessage id="IrrigationAreaList.area" />,
  },
  deviceCount: {
    sortable: true,
    label: <FormattedMessage id="IrrigationAreaList.deviceCount" />,
  },
  irrigationPlan: {
    sortable: false,
    label: <FormattedMessage id="IrrigationAreaList.irrigationPlan" />,
  },
  buttons: {
    sortable: false,
    label: <AreaButtonsHeader />,
    align: "right",
  },
};

type Props = {
  farmId: string;
  history: History;
};

const IrrigationAreasList = ({ farmId, history }: Props) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const areas = useSelector(getAreas);
  const areasDevicesIds = useSelector(getDevicesIdsForEachIrrigationArea);
  const isFetching = useSelector(getIsFetchingAreas);
  const totalCount = useSelector(getAreasTotalCount);
  const page = useSelector(getAreasPage);
  const order = useSelector(getAreasOrder);
  const orderBy = useSelector(getAreasOrderBy);
  const rowsPerPage = useSelector(getAreasRowsPerPage);

  const { onPageChange, onRowsPerPageChange } = useCfTableFooter(NAMESPACE);

  const isEmpty = !isFetching && areas.length === 0;

  const { onSelect, onSort } = useCfTableHead(NAMESPACE);

  useEffect(() => {
    dispatch(setEnlargedVariant(false));
    dispatch(fetchAreasList());
    dispatch(editorSetHoveredId(null));
    dispatch(editorSetSelected([]));
    dispatch(setActivePage(pathname));

    return () => {
      dispatch(setPrevPage(pathname));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(fetchAreasList());
  }, [dispatch, order, orderBy, page, rowsPerPage]);

  if (isEmpty) return <EmptyAreas />;
  return (
    <Box className={classes.container}>
      <CfTableWrapper minWidth={0}>
        <CfTableHead
          columns={irrigationAreasTableColumns}
          onSelect={onSelect}
          onSort={onSort}
          order={order}
          orderBy={orderBy}
        />
        {isFetching && (
          <CfTableBodyLoader columns={irrigationAreasTableColumns} />
        )}
        <TableBody>
          {areas.map((area) => {
            const areaDevicesIds = areasDevicesIds.find(
              (item) => item.id === area.id,
            );
            return (
              <AreaListRow
                area={area}
                areaDevicesIds={areaDevicesIds || {}}
                editorSetHoveredId={editorSetHoveredId}
                farmId={farmId}
                history={history}
                key={area.id}
              />
            );
          })}
        </TableBody>
        <CfTableFooter
          count={totalCount}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
        />
      </CfTableWrapper>
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    margin: "15px 25px 15px 15px",
  },
});

export default IrrigationAreasList;
