import React, { ChangeEvent } from "react";

import { alpha } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import countBy from "lodash/countBy";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { getCrops, getCropsOrder } from "../../selectors/crops.selectors";
import { getTool } from "../../selectors/editor.selectors";
import { getParcelLayerVisibility } from "../../selectors/layers.selectors";

import {
  setCropVisibility,
  setAllCropsVisibility,
} from "../../actions/crops/crops.actions";

import { AnyTodo } from "../../../../types";
import MapSwitcherBody from "../../components/MapSwitcherBody/MapSwitcherBody";
import { MapSwitcherCheckbox } from "../../components/MapSwitcherCheckbox/MapSwitcherCheckbox";
import MapSwitcherHeader from "../../components/MapSwitcherHeader/MapSwitcherHeader";

type Props = {
  expanded: boolean;
  handleExpansion: () => void;
  testId: string;
};

const CropSwitcher = ({ expanded, handleExpansion, testId }: Props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const tool = useSelector(getTool);
  const crops = useSelector(getCrops);
  const cropsOrder = useSelector(getCropsOrder);
  const parcelLayerVisibility = useSelector(getParcelLayerVisibility);

  const handleCropSelect = (crop: AnyTodo, visible: AnyTodo) => {
    dispatch(setCropVisibility(crop, visible));
  };

  const handleAllCropsSelect = (visible: AnyTodo) => {
    dispatch(setAllCropsVisibility(visible));
  };

  const getVisibleCropsCount = () =>
    countBy(crops, (crop) => crop.visible).true;

  const visibleCount = getVisibleCropsCount();

  const isDisabled = !!tool || !parcelLayerVisibility;

  return (
    <div data-test={testId}>
      <MapSwitcherHeader
        expanded={expanded}
        handleExpansion={handleExpansion}
        testId={testId}
      >
        <MapSwitcherCheckbox
          checked={visibleCount > 0 && cropsOrder.length === visibleCount}
          disabled={isDisabled}
          header
          indeterminate={visibleCount > 0 && visibleCount < cropsOrder.length}
          label={<FormattedMessage id="CropSwitcher.crops" />}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleAllCropsSelect(event.target.checked)
          }
        />
      </MapSwitcherHeader>
      <MapSwitcherBody expanded={expanded} testId={testId}>
        <FormGroup>
          {cropsOrder.map((cropId: AnyTodo) => {
            const crop = crops[cropId];
            return (
              <div className={classes.switcherRow} key={crop.id}>
                <MapSwitcherCheckbox
                  checked={crop.visible}
                  disabled={isDisabled}
                  label={crop.name}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleCropSelect(crop, event.target.checked)
                  }
                />
                <div
                  className={classes.cropColor}
                  style={{
                    backgroundColor: `rgba(${crop.color.red}, ${crop.color.green}, ${crop.color.blue}, 1)`,
                  }}
                />
              </div>
            );
          })}
        </FormGroup>
      </MapSwitcherBody>
    </div>
  );
};

export { CropSwitcher };

const useStyles = makeStyles((theme: Theme) => ({
  switcherRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cropColor: {
    width: 14,
    height: 14,
    borderRadius: "50%",
    border: `1px solid ${alpha(theme.palette.common.black, 0.2)}`,
    marginRight: 5,
  },
}));
