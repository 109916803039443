import React, { useContext } from "react";

import { ListItemButton, ListItemText } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { NAMESPACE as namespace } from "../../../reducer/sentinelAdmin.reducer";

import { CataloguesContext } from "../../../../../../catalogues/containers/CataloguesWrapper/CataloguesWrapper";
import CfTextFilter from "../../../../../../common/components/CfTextFilter/CfTextFilter";
import { useCfTextFilter } from "../../../../../../common/components/CfTextFilter/useCfTextFilter";
import { BulkActions } from "../BulkActions";
import { SentinelAdminTypeNoToggle } from "../useSentinelAdmin";

import AdvancedFilter from "./AdvancedFilter";

type Props = {
  selected: string[];
  handleBulkActionClick: (mode: SentinelAdminTypeNoToggle) => void;
};

const LeftSide = ({ handleBulkActionClick, selected }: Props) => {
  const { langId } = useContext(CataloguesContext);
  const { onChange } = useCfTextFilter(namespace);

  return (
    <>
      <BulkActions selected={selected.length}>
        <ListItemButton onClick={() => handleBulkActionClick("bulkEdit")}>
          <ListItemText>
            <FormattedMessage id="SentinelAdmin.parcels.services.edit" />
          </ListItemText>
        </ListItemButton>
      </BulkActions>
      <CfTextFilter
        name="sentinel-admin-text-filter"
        onChange={onChange}
        translId="SentinelAdmin.blockNrLocalName"
      />
      <AdvancedFilter langId={langId} namespace={namespace} />
    </>
  );
};

export { LeftSide };
