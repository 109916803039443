import React, { FC } from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSuspenseQuery } from "@tanstack/react-query";
import { FormattedMessage } from "react-intl";
import { Switch, Route, Redirect } from "react-router-dom";

import * as services from "../../../shared/constants/services.constants";
import { LANGUAGE_ID } from "../../../shared/lang/lang.constants";
import { TELEMATICS_URLS } from "../../telematics.constants";

import {
  Catalogue,
  EconomicSystem,
  MachineTo,
} from "../../../generated/api/telematics";
import { useFarmIds } from "../../../shared/api/client.utils";
import CfStatusPanel from "../../../shared/components/common/CfStatusPanel/CfStatusPanel";
import PageHeader from "../../../shared/components/common/PageHeader/PageHeader";
import PageHeading from "../../../shared/components/common/PageHeading/PageHeading";
import ServiceNotPurchased from "../../../shared/components/common/ServiceNotPurchased/ServiceNotPurchased";
import { links } from "../../../shared/constants/links";
import { TelematicsIcon } from "../../../shared/icons/navbar/TelematicsIcon";
import TelematicsAggregationDetail from "../TelematicsAggregationDetail/TelematicsAggregationDetail";
import TelematicsAggregationNew from "../TelematicsAggregationDetail/TelematicsAggregationNew";
import TelematicsAggregationsList from "../TelematicsAggregationsList/TelematicsAggregationsList";
import TelematicsDetail from "../TelematicsDetail/TelematicsDetail";
import TelematicsHandworkNew from "../TelematicsHandwork/TelematicsHandworkNew";
import { TelematicsLogbook } from "../TelematicsLogbook/TelematicsLogbook";
import TelematicsMachines from "../TelematicsMachines/TelematicsMachines";
import TelematicsTabs from "../TelematicsTabs/TelematicsTabs";

import { TelematicsProvider } from "./Context";
import { statusQuery } from "./telematics.api";

import { DriverIdentityTo } from "../../../shared/api/telematics/telematics.types";

const useStyles = makeStyles((theme: Theme) => ({
  noTelematics: {
    padding: theme.spacing(2),
  },
  header: {
    paddingBottom: theme.spacing(2),
  },
}));

export interface TelematicsNgProps {
  ngRedirectToMainMapWithFilters: (
    dateFrom?: string,
    dateTo?: string,
    machine?: MachineTo[],
    driver?: Pick<DriverIdentityTo, "name" | "code">[],
    openInNewPage?: boolean,
  ) => void;
}

interface Props extends TelematicsNgProps {
  catalogueType: Catalogue;
  economicSystem: EconomicSystem;
  economicSystemDate: string;
  farmId: string;
  initDateFrom: string;
  initDateTo: string;
  initMachineFilter?: MachineTo;
  langId: LANGUAGE_ID;
  ngGoToAction: (actionId?: string) => void;
  ngResetFilters: () => void;
  setInitMachineFilter: (initMachineFilter?: MachineTo) => void;
}

const Telematics: FC<Props> = ({
  farmId,
  initDateFrom,
  initDateTo,
  initMachineFilter,
  langId,
  ngGoToAction,
  ngRedirectToMainMapWithFilters,
  ngResetFilters,
  setInitMachineFilter,
}) => {
  const classes = useStyles();
  const status = useSuspenseQuery(statusQuery(useFarmIds()));

  if (status.data.data.telematics) {
    return (
      <TelematicsProvider
        catalogueType={status.data.data.catalogue}
        economicSystem={status.data.data.economicSystem}
        economicSystemDate={status.data.data.economicSystemDate}
        farmId={farmId}
        langId={langId}
      >
        <Switch>
          <Route
            exact
            path={`/farm/:farmId/${TELEMATICS_URLS.machines}`}
            render={(routerProps) => (
              <TelematicsTabs langId={langId} {...routerProps}>
                <TelematicsMachines
                  ngRedirectToMainMapWithFilters={
                    ngRedirectToMainMapWithFilters
                  }
                />
              </TelematicsTabs>
            )}
          />
          <Route
            exact
            path={`/farm/:farmId/${TELEMATICS_URLS.drivers}`}
            render={(routerProps) => (
              <TelematicsTabs langId={langId} {...routerProps}>
                <TelematicsAggregationsList
                  ngRedirectToMainMapWithFilters={
                    ngRedirectToMainMapWithFilters
                  }
                />
              </TelematicsTabs>
            )}
          />
          <Route
            exact
            path={`/farm/:farmId/${TELEMATICS_URLS.logbook}`}
            render={(routerProps) => (
              <TelematicsTabs langId={langId} {...routerProps}>
                <TelematicsLogbook
                  initDateFrom={initDateFrom}
                  initDateTo={initDateTo}
                  initMachineFilter={initMachineFilter}
                  langId={langId}
                  ngResetFilters={ngResetFilters}
                  setInitMachineFilter={setInitMachineFilter}
                  ngRedirectToMainMapWithFilters={
                    ngRedirectToMainMapWithFilters
                  }
                />
              </TelematicsTabs>
            )}
          />
          {/* TODO: CFD-1265 re-enable after aggregation is done */}
          <Redirect
            from={`/farm/:farmId/${TELEMATICS_URLS.logbook}/new`}
            to={`/farm/:farmId/${TELEMATICS_URLS.logbook}`}
          />
          <Route
            exact
            path={`/farm/:farmId/${TELEMATICS_URLS.logbook}/:telematicsId`}
            render={() => <TelematicsDetail farmId={farmId} langId={langId} />}
          />
          <Route
            exact
            path={`/farm/:farmId/${TELEMATICS_URLS.drivers}/new`}
            render={() => <TelematicsAggregationNew />}
          />
          <Route
            exact
            path={`/farm/:farmId/${TELEMATICS_URLS.handwork}/new`}
            render={() => <TelematicsHandworkNew />}
          />
          <Route
            exact
            path={`/farm/:farmId/${TELEMATICS_URLS.drivers}/:driveId`}
            render={() => (
              <TelematicsAggregationDetail
                catalogueType={status.data.data.catalogue}
                ngGoToAction={ngGoToAction}
              />
            )}
          />
          <Route
            exact
            path={`/farm/:farmId/${TELEMATICS_URLS.machines}/:driveId`}
            render={() => (
              <TelematicsAggregationDetail
                catalogueType={status.data.data.catalogue}
                ngGoToAction={ngGoToAction}
              />
            )}
          />
        </Switch>
      </TelematicsProvider>
    );
  }
  return (
    <div className={classes.noTelematics}>
      <PageHeader
        classes={{ header: classes.header }}
        heading={<PageHeading translationId="common.telematics" />}
      />
      <CfStatusPanel
        customContent={<ServiceNotPurchased serviceId={services.TELEMATICS} />}
        icon={TelematicsIcon}
        linkText={<FormattedMessage id="common.findOutMore" />}
        testId="no-telematics"
        title={<FormattedMessage id="Telematics.notPurchased.title" />}
        titleWithIcon={true}
        linkHref={
          langId === "cs-CZ" ? links.cs.telematics : links.en.telematics
        }
      />
    </div>
  );
};

export default Telematics;
