import React from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveIcon from "@mui/icons-material/Remove";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";

import { COLOR_GREY } from "../../../../../../theme";

import { ControlButton } from "./ControlButton";

export type ClickType = "on" | "off" | "init";

type Props = {
  value?: boolean;
  onChange: (clickedType: ClickType) => void;
};

const ThreeStateControlTray = ({ onChange, value }: Props) => {
  const classes = useStyles();

  const isInit = value === undefined || value === null;
  const isOff = value === false;
  const isOn = value === true;

  const onButtonClick = (clickedType: ClickType) => {
    onChange(clickedType);
  };

  return (
    <span>
      <span className={classes.wrapper}>
        <ControlButton
          onClick={() => onButtonClick("off")}
          tooltipTitle={<FormattedMessage id="common.disabled" />}
          className={classnames(classes.button, {
            [classes.off]: isOff,
          })}
        >
          <CancelIcon color={isOff ? "error" : "disabled"} />
        </ControlButton>

        <ControlButton
          onClick={() => onButtonClick("init")}
          tooltipTitle={<FormattedMessage id="common.intact" />}
          className={classnames(classes.button, {
            [classes.init]: isInit,
          })}
        >
          <RemoveIcon htmlColor={COLOR_GREY[400]} />
        </ControlButton>
        <ControlButton
          onClick={() => onButtonClick("on")}
          tooltipTitle={<FormattedMessage id="common.enabled" />}
          className={classnames(classes.button, {
            [classes.on]: isOn,
          })}
        >
          <CheckCircleIcon color={isOn ? "primary" : "disabled"} />
        </ControlButton>
      </span>
    </span>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    display: "grid",
    placeItems: "center",
    margin: 0,
    padding: 4,
    border: "none",
    background: "none",
    font: "inherit",
    color: "inherit",
    cursor: "pointer",
    outline: "none",
  },
  on: {
    backgroundColor: "#EBF8F4",
  },
  init: {
    backgroundColor: "#EBEBEB",
  },
  off: {
    backgroundColor: "#FFB8B8",
  },
  wrapper: {
    display: "flex",
    borderRadius: 3,
    border: `1px solid ${theme.palette.grey[200]}`,
    "& > *:not(:first-child)": {
      borderLeft: `1px solid ${theme.palette.grey[200]}`,
    },
  },
}));

export { ThreeStateControlTray };
