import React, { PropsWithChildren } from "react";

import { TextField, TextFieldProps } from "@mui/material";
import { useField } from "formik";

import { getError } from "../../../misc/formHelpers";
import FormErrorMessage from "../FormErrorMessage/FormErrorMessage";

type Props = PropsWithChildren<
  Omit<TextFieldProps, "onChange" | "onBlur" | "value">
>;

const FormTextField = (props: Props) => {
  const { ...settings } = props;
  const fieldProps = useField(settings.name || "");
  const field = fieldProps[0];
  const meta = fieldProps[1];

  return (
    <>
      <TextField
        {...field}
        error={!!getError(meta)}
        onBlur={(e) => {
          field.onBlur(e);
        }}
        onChange={(e) => {
          field.onChange(e);
        }}
        {...settings}
      />
      <FormErrorMessage name={field.name} />
    </>
  );
};

export default FormTextField;
