import { AnyAction, Action } from "redux";
import { RSAAAction } from "redux-api-middleware";
import {
  call,
  put,
  takeLatest,
  Effect,
  select,
  take,
} from "redux-saga/effects";

import {
  getTelematicsMachinesTextFilter,
  getTelematicsMachinesPage,
  getTelematicsMachinesRowsPerPage,
  getTelematicsMachinesOrderBy,
  getTelematicsMachinesOrder,
} from "../telematics/selectors/telematicsMachines.selectors";
import { selectDateRange } from "../telematics/selectors/telematicsTabs.selectors";

import * as machineAggregationsApiTypes from "../shared/api/telematics/machines/machines.constants";
import * as telematicsMachinesTypes from "../telematics/actions/telematicsMachines.constants";

import { getMachineAggregationsApi } from "../shared/api/telematics/machines/machines.api";
import { AnyTodo } from "../types";

import { GRRT } from "./sagas.types";
import { AggregatedMachineApiParams } from "../shared/api/telematics/machines/machines.types";

function* getApiParams(): Generator<
  Effect,
  AggregatedMachineApiParams,
  AnyTodo
> {
  const { dateFrom, dateTo } = yield select(selectDateRange);
  const textFilter = yield select(getTelematicsMachinesTextFilter);
  const page = yield select(getTelematicsMachinesPage);
  const perPage = yield select(getTelematicsMachinesRowsPerPage);
  const sortCol = yield select(getTelematicsMachinesOrderBy);
  const sortDir = yield select(getTelematicsMachinesOrder);
  const farmIds: (number | string)[] = [];
  const isValid = true;

  return {
    dateFrom,
    dateTo,
    machine: textFilter !== "" ? textFilter : undefined,
    page: page + 1,
    "per-page": perPage,
    "sort-col": sortCol,
    "sort-dir": sortDir,
    farmIds,
    isValid,
  };
}

function* fetchMachinesSaga(
  action: AnyAction,
): Generator<Effect, void, AnyTodo> {
  let refetch = false;
  if (action?.refetch) {
    refetch = action.refetch;
  }
  const params: GRRT<typeof getApiParams> = yield call(getApiParams);
  yield put(
    // @ts-expect-error no clue how to type this
    getMachineAggregationsApi(params, refetch) as unknown as Action<RSAAAction>,
  );
  yield take(machineAggregationsApiTypes.GET_MACHINE_AGGREGATIONS_SUCCESS);
}

export default function* TelematicsSaga(): Generator<Effect, void, AnyTodo> {
  yield takeLatest(
    telematicsMachinesTypes.FETCH_AGGREGATED_MACHINES,
    fetchMachinesSaga,
  );
}
