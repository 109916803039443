import { MainMapState } from "../../../../../reducers/map.reducer.types";

export const getMainMapTelematicsDashboardMachinesPositions = (
  state: MainMapState,
) => state.api.mainMapTelematicsMachinesDashboard.machinesPositions;
export const getMainMapTelematicsDashboardIsFetchingMachines = (
  state: MainMapState,
) => state.api.mainMapTelematicsMachinesDashboard.isFetching;
export const getMainMapTelematicsDashboardMachinesOrder = (
  state: MainMapState,
) => state.api.mainMapTelematicsMachinesDashboard.order;
export const getMainMapTelematicsDashboardMachinesOrderBy = (
  state: MainMapState,
) => state.api.mainMapTelematicsMachinesDashboard.orderBy;
export const getMainMapTelematicsDashboardMachinesIsOpen = (
  state: MainMapState,
) => state.api.mainMapTelematicsMachinesDashboard.isOpen;
export const getMainMapTelematicsDashboardSelectedMachine = (
  state: MainMapState,
) => state.api.mainMapTelematicsMachinesDashboard.selected;

export const getMainMapTelematicsDashboardTextFilter = (state: MainMapState) =>
  state.api.mainMapTelematicsMachinesDashboard.textFilter;

export const getMainMapTelematicsDashboardDrivesHistory = (
  state: MainMapState,
) => state.api.mainMapTelematicsMachinesDashboard.drivesHistory;
export const getMainMapTelematicsDashboardIsFetchingDrivesHistory = (
  state: MainMapState,
) => state.api.mainMapTelematicsMachinesDashboard.isFetchingDrivesHistory;
