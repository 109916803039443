import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useMainMenuStyles = makeStyles((theme: Theme) => ({
  mainMenuDesktop: {
    fontSize: 14,
    fontWeight: 500,
    display: "flex",
    height: "100%",
  },
  mainMenuMobile: {
    fontSize: 14,
    fontWeight: 500,
  },
  menuItemLink: {
    display: "flex",
    alignItems: "center",
    color: "rgba(255, 255, 255, 0.7)",
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.common.white,
    },
    "&:focus": {
      textDecoration: "none",
      color: theme.palette.common.white,
      outline: "none",
    },
  },
  menuItemLinkMobile: {
    margin: "8px 32px",
    borderRadius: 25,
    width: "fit-content",
  },
  navlinkContentDesktop: {
    whiteSpace: "nowrap",
    padding: "0px 15px",
    [theme.breakpoints.down("lg")]: {
      padding: "0px 10px",
    },
  },
  navlinkContentMobile: {
    padding: "10px 15px",
    display: "flex",
    alignItems: "center",
  },
  navlinkIcon: {
    width: 28,
    height: 28,
    marginRight: 10,
  },
  separator: {
    borderColor: theme.palette.primary.dark,
    margin: "16px 0px",
  },
}));
