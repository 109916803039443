import { formValueSelector } from "redux-form";
import { createSelector } from "reselect";

import { getFertilizers } from "../../../../shared/api/agroevidence/catalogues/fertilizers/fertilizers.selectors";
import { getPlantProtection } from "../../../../shared/api/agroevidence/catalogues/plantProtection/plantProtection.selectors";
import { getSeeds } from "../../../../shared/api/agroevidence/catalogues/seeds/seeds.selectors";
import { getParcels } from "../../../../shared/api/agroevidence/parcels/parcels.selectors";
import { getZones } from "../../../../shared/api/agroevidence/zones/zones.selectors";
import {
  getVariableExpenseIsError,
  getVariableExpenseSaveIsFetching,
  getError as getVariableParcelsError,
  getVariableExpensesIsError,
  getIsFetching,
  getVariableExpensesIsFetching,
  getCreateVariableExpensesIsFetching,
} from "../../../../shared/api/sentinel/variableApplication/variableApplication.selectors";

import { ActionsState } from "../../../../reducers/actions.reducer.types";
import {
  ParcelTo,
  ZoneDetailTo,
} from "../../../../shared/api/agroevidence/agroevidence.types";
import {
  FertilizerAndPPPsType,
  InitialParcelToAdd,
} from "../../ActionOthers/actionOther.types";

// for redux forms
export const getParcelsAndZonesSuggestions = (
  filter: undefined | ((i: unknown) => boolean),
  formName: string,
) =>
  createSelector(
    getParcels(`ui.${formName}.suggestions`),
    getZones,
    (parcels, zones) =>
      [
        {
          title: "common.parcels",
          items: filter ? parcels.filter?.(filter) : parcels,
        },
        {
          title: "common.zones",
          items: filter ? zones.filter?.(filter) : zones,
        },
      ].filter((section) => section.items?.length > 0),
  );

// for formik forms
export const getParcelsAndZonesSuggestionsAutocomplete = (formName: string) =>
  createSelector(
    getParcels(`ui.${formName}.suggestions`),
    getZones,
    (parcels: ParcelTo[], zones: ZoneDetailTo[]) => [
      ...parcels.map((parcel) => ({ ...parcel, title: "common.parcels" })),
      ...zones.map((zone) => ({ ...zone, title: "common.zones" })),
    ],
  );

export const getParcelsToAdd = (formName: string, state: ActionsState) =>
  getParcels(`ui.${formName}.additions`)(state);

// use in VRF ChemFertSelector.js
export const getChemistryAndFertilizersSuggestions = createSelector(
  getFertilizers,
  getPlantProtection,
  (fertilizers, plantProtection) =>
    [
      {
        title: "common.fertilizers",
        items: fertilizers.map((item) => ({ materialTypeId: "FR", ...item })),
      },
      {
        title: "common.chemistries",
        items: plantProtection.map((item) => ({
          materialTypeId: "CH",
          ...item,
        })),
      },
    ].filter((section) => section.items.length > 0),
);

// use in VRS SeedSelector.js
export const getSeedsSuggestions = createSelector(getSeeds, (seeds) =>
  [
    {
      title: "common.seeds",
      items: seeds,
    },
  ].filter((section) => section.items.length > 0),
);

export const getParcelsArea = (formName: string, state: ActionsState) => {
  const parcels: InitialParcelToAdd[] =
    formValueSelector(formName)(state, "parcels") || [];
  let parcelsArea = 0;
  if (parcels && parcels.length) {
    parcels.forEach((parcel) => {
      let areaToAdd = parcel.actionParcelTotalArea;
      if (parcel.subtractableAreas !== undefined) {
        areaToAdd = parcel.area - parcel.restrictedArea;
      }
      parcelsArea += areaToAdd;
    });
  }
  return parcelsArea;
};

export const getParcelsIds = (formName: string, state: ActionsState) => {
  const parcels: InitialParcelToAdd[] =
    formValueSelector(formName)(state, "parcels") || [];
  return parcels.map((parcel) => parcel.id);
};

export const getMaterialIds = (formName: string, state: ActionsState) => {
  const materials: FertilizerAndPPPsType[] =
    formValueSelector(formName)(state, "expenses") || [];
  return materials.map((material) => material.material.id);
};

// gets the highest drift class from all used chemistries in eph form
// possible drift classes: NONE, 50, 75, 90
// driftClass.substring(6) gets from DRIFT_50 string only the number
export const getDriftClass = (formName: string, state: ActionsState) => {
  let driftClass = "DRIFT_90";
  const materials: FertilizerAndPPPsType[] =
    formValueSelector(formName)(state, "expenses") || [];
  materials.forEach((material) => {
    if (material.material?.materialTypeId === "CH") {
      if (material.driftClass === "DRIFT_NONE") {
        driftClass = "DRIFT_NONE";
      } else if (
        driftClass !== "DRIFT_NONE" &&
        material.driftClass.substring(6) < driftClass.substring(6)
      ) {
        driftClass = material.driftClass;
      }
    }
  });
  return driftClass;
};

export const getSubtractableAreas = (formName: string, state: ActionsState) =>
  formValueSelector(formName)(state, "subtractableAreas");

export const getSubtractableAreasTotalArea = (
  formName: string,
  state: ActionsState,
) => formValueSelector(formName)(state, "restrictedArea");

export const getVAIsError = createSelector(
  getVariableExpenseIsError,
  getVariableParcelsError,
  getVariableExpensesIsError,
  (...errors) => errors.find((err) => err.isError === true) || {},
);

export const getVAIsLoading = createSelector(
  getVariableExpenseSaveIsFetching,
  getIsFetching,
  getVariableExpensesIsFetching,
  (isFetchingSave, isFetchingParcels, variableExpensesIsFetching) =>
    isFetchingSave || isFetchingParcels || variableExpensesIsFetching,
);

export const getCreateVAIsLoading = createSelector(
  getCreateVariableExpensesIsFetching,
  (isFetchingCreate) => isFetchingCreate,
);
