import moment from "moment/moment";

import { LANGUAGE_ID } from "../../../../shared/lang/lang.constants";

import Localization from "../../../../shared/services/Localization.service";
import { AnyTodo } from "../../../../types";

import { ActionParcelDetailTo } from "../../../../shared/api/agroevidence/agroevidence.types";

export default class ActionStateMapper {
  static mapVrsActionState(action: AnyTodo, locale: LANGUAGE_ID) {
    const { date, isDraft, note, parcels, seedApplicationType, seedExpense } =
      action;
    const actionDateMoment = moment(date).startOf("day");

    const standardFields: AnyTodo = {
      parcels: this.mapParcelsFrom(parcels),
      actionDate: actionDateMoment,
      actionNote: note,
    };

    // if VRS action has cropUseType
    if (action.cropUseType) {
      standardFields.cropUseType = action.cropUseType;
    }

    if (isDraft) {
      return {
        ...standardFields,
      };
    } else {
      return {
        ...standardFields,
        isCatchCrop: seedApplicationType !== "MAIN_CROP",
        seedId: seedExpense?.material.id || "",
        totalAmount:
          Localization.num2strNonFixed(
            seedExpense?.actionExpense?.amount,
            locale,
            3,
            3,
          ) || "0",
        hectarAmount:
          Localization.num2strNonFixed(
            seedExpense?.actionExpense?.dosePerHa,
            locale,
            3,
            3,
          ) || "0",
        unitId: seedExpense?.actionExpense.unit || "",
        expenses: [
          {
            doseUnit: {
              id: seedExpense?.actionExpense.unit,
              name: seedExpense?.actionExpense.unit,
            },
            material: { ...seedExpense?.material },
          },
        ],
      };
    }
  }

  static mapVrfActionState(action: AnyTodo) {
    const {
      date,
      fertilizers,
      isDraft,
      note,
      parcels,
      plantProtections,
      targetCrop,
    } = action;
    const actionDateMoment = moment(date).startOf("day");

    const standardFields = {
      parcels: this.mapParcelsFrom(parcels),
      actionDate: actionDateMoment,
      actionNote: note,
    };

    if (isDraft) {
      return {
        ...standardFields,
      };
    } else {
      return {
        ...standardFields,
        expenses: this.mapExpensesFrom(
          fertilizers,
          plantProtections,
          actionDateMoment,
          parcels,
        ),
        targetCrop,
      };
    }
  }

  static mapParcelsFrom(parcels: ActionParcelDetailTo[]) {
    return parcels
      ?.map((p: ActionParcelDetailTo) => ({
        ...p.parcel,
        subtractableAreas: p.restrictions,
        actionParcelTotalArea: p.actionArea,
        restrictedArea: p.restrictedArea,
      }))
      .sort((a: AnyTodo, b: AnyTodo) => a.id - b.id);
  }

  static mapExpensesFrom(
    fertilizers: AnyTodo,
    plantProtections: AnyTodo,
    actionDateMoment: AnyTodo,
    parcels: AnyTodo,
  ) {
    const mapExpense = (item: AnyTodo, materialTypeId: AnyTodo) => ({
      dosage: item.actionExpense.amount,
      dose: item.actionExpense.dosePerHa,
      doseUnit: {
        id: item.actionExpense.unit,
        name: item.actionExpense.unit,
      },
      dosageUnit: {
        id: item.actionExpense.unit,
        name: item.actionExpense.unit,
      },
      note: item.actionExpense.notes,
      material: {
        ...item.material,
        materialTypeId,
        authorizationHolder:
          materialTypeId === "CH"
            ? item.material.authorizationHolder
            : undefined,
        registrationNumber:
          materialTypeId === "CH"
            ? item.material.registrationNumber
            : undefined,
      },
      driftClass: item.actionMaterial.driftClass,
      incorporationDay:
        materialTypeId === "FR"
          ? this.mapDateSoilIncorporation(
              item.actionMaterial.dateSoilIncorporation,
              actionDateMoment,
            )
          : undefined,
      n:
        materialTypeId === "FR"
          ? item.fertilization.accountableNitrogen
          : undefined,
      p2o5: materialTypeId === "FR" ? item.fertilization.p2o5 : undefined,
      k2o: materialTypeId === "FR" ? item.fertilization.k2o : undefined,
      pests: materialTypeId === "CH" ? item.targetOrganisms : undefined,
      effectiveness:
        materialTypeId === "CH"
          ? item.parcelPlantProtectionEffectiveness.map(
              (effectivenessItem: AnyTodo) => ({
                parcelName: parcels.find(
                  (item: AnyTodo) =>
                    item.parcel.id === effectivenessItem.parcelId,
                )?.parcel.localName,
                parcelId: effectivenessItem.parcelId,
                value: effectivenessItem.effectiveness,
              }),
            )
          : undefined,
    });

    const fertilizerExpenses = fertilizers.map((fertilizer: AnyTodo) =>
      mapExpense(fertilizer, "FR"),
    );
    const plantProtectionExpenses = plantProtections.map(
      (plantProtection: AnyTodo) => mapExpense(plantProtection, "CH"),
    );

    return [...fertilizerExpenses, ...plantProtectionExpenses];
  }

  static mapDateSoilIncorporation(
    dateSoilIncorporation: AnyTodo,
    actionDateMoment: AnyTodo,
  ) {
    const incorporationDateMoment = moment(dateSoilIncorporation);
    if (incorporationDateMoment.isValid()) {
      if (incorporationDateMoment.isSame(actionDateMoment, "days")) {
        return "SAME_DAY";
      } else if (
        incorporationDateMoment
          .clone()
          .subtract(1, "d")
          .isSame(actionDateMoment, "days")
      ) {
        return "NEXT_DAY";
      }
    }
    return "NO";
  }
}
