import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useReportTypesStyles = makeStyles((theme: Theme) => ({
  subheading: {
    fontSize: 12,
    color: theme.palette.grey[500],
    marginBottom: 5,
    marginTop: 10,
  },
  errorSubheading: {
    fontSize: 12,
    color: theme.palette.error.main,
    marginBottom: 5,
    marginTop: 10,
  },
  label: {
    display: "flex",
    justifyContent: "space-between",
  },
  checkbox: {
    left: 0,
  },
  reports: {
    marginBottom: 5,
  },
}));
