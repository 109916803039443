import React, { useEffect, useState } from "react";

import { Breakpoint, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import CfStaticMap from "../../../../shared/components/specific/CfStaticMap/CfStaticMap";

import {
  combinedSoilSamplesData,
  Sample,
  Zone,
} from "../../../../shared/api/sentinel/soilSamples/soilSamples.types";

interface Props {
  item: combinedSoilSamplesData;
  onMapClick: (geometries: Zone[], points: Sample[]) => void;
  order: number;
  width?: Breakpoint;
}

const SoilSamplesZonesMap = ({ item, onMapClick, order = 0, width }: Props) => {
  const isMobileView = width === "xs";
  const classes = useStyles(isMobileView);
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    const delay = order * 150;
    const timer = setTimeout(() => {
      setHidden(false);
    }, delay);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.wrapper} data-test="history-item">
      <Paper>
        <div className={classes.images}>
          <div
            className={classes.zonesMap}
            onClick={() => onMapClick(item.zones, item.points)}
          >
            {!hidden && (
              <CfStaticMap
                classes={{ map: classes.map }}
                geometries={item.zones}
                mapId={`soil-sample-map_${order}`}
                points={item.points}
              />
            )}
          </div>
        </div>
        <div className={classes.nameHeading} data-test="name">
          <FormattedMessage id={`SoilSamples.${item.name}`} />
        </div>
      </Paper>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    margin: "10px 5px 5px 5px",
  },
  nameHeading: {
    textAlign: "left",
    padding: "0px 0px 10px 10px",
    fontSize: 16,
    fontWeight: 500,
  },
  images: {
    display: "flex",
    flexDirection: (isMobileView) => (isMobileView ? "row" : "column"),
  },
  zonesMap: {
    width: 300,
    height: 300,
    margin: 1,
    padding: 10,
    cursor: "pointer",
  },
  map: {
    borderRadius: 0,
    "& .ol-viewport": {
      borderRadius: 0,
    },
  },
}));

export default SoilSamplesZonesMap;
