import { getCrops } from "../../selectors/crops.selectors";
import {
  getHoveredId,
  getSelectedParcelIds,
} from "../../selectors/editor.selectors";
import { getStyle } from "../../selectors/map.selectors";
import { getSowingPlan } from "../../selectors/sowingPlan.selectors";

export const refreshDefaultStyles = () => (_, getState) => {
  const state = getState();
  const service = getStyle(state);
  const hoveredId = getHoveredId(state);
  const selectedIds = getSelectedParcelIds(state);
  const crops = getCrops(state);
  service.refreshDefaultStyles(hoveredId, selectedIds[0], crops);
};

export const refreshSowingPlanStyles = () => (_, getState) => {
  const state = getState();
  const service = getStyle(state);
  const hoveredId = getHoveredId(state);
  const selectedIds = getSelectedParcelIds(state);
  const sowingPlan = getSowingPlan(state);
  if (service !== null) {
    service.refreshSowingPlanStyles(hoveredId, selectedIds[0], sowingPlan);
  }
};

export const refreshParcelDetailStyles = (cropColor) => (_, getState) => {
  const state = getState();
  const service = getStyle(state);
  const hoveredId = getHoveredId(state);
  const selectedIds = getSelectedParcelIds(state);
  service.refreshParcelDetailStyles(hoveredId, selectedIds[0], cropColor);
};

export const refreshTelematicsStyles = () => (_, getState) => {
  const state = getState();
  const service = getStyle(state);
  const hoveredId = getHoveredId(state);
  const selectedIds = getSelectedParcelIds(state);
  service.refreshTelematicsStyles(hoveredId, selectedIds[0]);
};
