export const DAY_CODES = {
  MONDAY: "MONDAY",
  TUESDAY: "TUESDAY",
  WEDNESDAY: "WEDNESDAY",
  THURSDAY: "THURSDAY",
  FRIDAY: "FRIDAY",
  SATURDAY: "SATURDAY",
  SUNDAY: "SUNDAY",
};

export const IRRIGATION_URLS = {
  AREAS: "irrigation/areas",
  AREA: "irrigation/area",
  CREATE_AREA: "irrigation/area/create",
  ADD_TO_AREA: "irrigation/area/:areaId/add",
  DEVICES: "irrigation/devices",
  DEVICE: "irrigation/device",
  ACTIVATE: "activate",
  DEVICES_ROOT_WILDCARD: "irrigation/device*",
  AREAS_ROOT_WILDCARD: "irrigation/area*",
};

export const AREA_FORM_FIELDS = {
  AREA_NAME: "areaName",
  NAME: "name",
  LATITUDE: "latitude",
  LONGITUDE: "longitude",
};

export const GEOMETRY_WHOLE_COORDINATES = "coordinates";

export const GPS_COORDINATES_PRECISION = 6;
