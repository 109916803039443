import angular from "angular";

import parcelsController from "./parcels.controller";

const parcelsComponent = {
  bindings: {
    farm: "<",
  },
  controller: parcelsController,
};

export default angular
  .module("app.parcels", [])
  .config(config)
  .component("parcelsComponent", parcelsComponent).name;

config.$inject = ["$stateProvider"];

function config($stateProvider) {
  $stateProvider
    .state("farm.active.parcels", {
      url: "/parcels",
      views: {
        "farm@farm.active": {
          component: "parcelsComponent",
        },
      },
    })
    .state("farm.active.parcels.detail", {
      url: "/:parcelId",
      abstract: true,
      views: {
        "farm@farm.active": {
          component: "parcelsComponent",
        },
      },
    })
    .state("farm.active.parcels.detail.overview", {
      url: "/overview",
      views: {
        "farm@farm.active": {
          component: "parcelsComponent",
        },
      },
    })
    .state("farm.active.parcels.detail.activities", {
      url: "/activities",
      views: {
        "farm@farm.active": {
          component: "parcelsComponent",
        },
      },
    })
    .state("farm.active.parcels.detail.bio-monitoring", {
      url: "/bio-monitoring",
      views: {
        "farm@farm.active": {
          component: "parcelsComponent",
        },
      },
    });
}
