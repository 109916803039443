import React, { FC, useContext } from "react";

import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "redux";

import { deleteVarioDocIntegrationApi } from "../../../../shared/api/sentinel/varioDoc/varioDoc.api";
import CfDialog from "../../../../shared/components/common/CfDialog/CfDialog";
import { SnackbarContext } from "../../../../shared/containers/SnackbarProvider/SnackbarProvider";
import { AsyncFn, Thunk } from "../../../../types";

import { UserState } from "../../../../reducers/user.reducer.types";

interface Props {
  onClose: () => void;
  opened?: boolean;
}

type ReduxProps = ConnectedProps<typeof connector>;

const AgDeactivationDialog: FC<Props & ReduxProps> = ({
  deleteVarioDocIntegrationApi,
  onClose,
  opened = false,
}) => {
  const showSnackbar = useContext(SnackbarContext);

  const handleAgDeactivationDialogAccept = () => {
    (deleteVarioDocIntegrationApi as AsyncFn)().then((res) => {
      if (res.error) {
        showSnackbar({
          message: <FormattedMessage id="common.error" />,
          isError: true,
        });
        return;
      }
      showSnackbar({
        message: (
          <FormattedMessage id="VarioDoc.deactivationDialog.success.message" />
        ),
        isSuccess: true,
      });

      onClose();
    });
  };

  return (
    <CfDialog
      cancelText={<FormattedMessage id="common.cancel" />}
      onAccept={handleAgDeactivationDialogAccept}
      onCancel={onClose}
      onClose={onClose}
      opened={opened}
      title={<FormattedMessage id="VarioDoc.deactivationDialog.title" />}
      acceptText={
        <FormattedMessage id="VarioDoc.deactivationDialog.acceptBtn" />
      }
    >
      <FormattedMessage id="VarioDoc.deactivationDialog.text" />
    </CfDialog>
  );
};

const mapDispatchToProps = (dispatch: Thunk<UserState>) =>
  bindActionCreators(
    {
      deleteVarioDocIntegrationApi: deleteVarioDocIntegrationApi as () => void,
    },
    dispatch,
  );

const connector = connect(null, mapDispatchToProps);
export default connector(AgDeactivationDialog);
