import React, { ReactNode } from "react";

import { useSuspenseQuery } from "@tanstack/react-query";

import {
  OperationTo,
  TelematicsOperation,
} from "../../../../generated/api/telematics";
import CfAutocomplete from "../../../../shared/components/common/CfAutocomplete/CfAutocomplete";

import { operationsQuery } from "./OperationSelector.api";

type Props = {
  defaultValues?: TelematicsOperation[];
  label: ReactNode;
  onChange(items: OperationTo[]): void;
};

const OperationSelector = ({ defaultValues = [], label, onChange }: Props) => {
  const operations = useSuspenseQuery(operationsQuery());
  const defaultValuesTranslated = operations.data.data.filter((operation) =>
    defaultValues.includes(operation.code),
  );

  return (
    <CfAutocomplete
      defaultValues={defaultValuesTranslated}
      id="operation-selector"
      isMultiple={true}
      label={label}
      onChange={onChange}
      suggestions={operations.data.data}
      testId="operation-filter"
    />
  );
};

export default OperationSelector;
