import { RSAA } from "redux-api-middleware";

import * as types from "./agiStatus.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../api.constants";

import { getCreateAgIntegrationStatusApiParams } from "./agiStatus.types";
import { RsaaMethods, RsaaTypes } from "../../api.types";
import { IntegrationType } from "../../satellite/satellite.types";

export const getAgIntegrationStatusApi = () => ({
  [RSAA]: {
    endpoint: `integrations?type=${IntegrationType.AGINTEGRATED}&`,
    method: methods.GET as RsaaMethods,
    module: modules.SENTINEL,
    types: [
      types.GET_AGINTEGRATION_REQUEST,
      types.GET_AGINTEGRATION_SUCCESS,
      types.GET_AGINTEGRATION_ERROR,
    ] as RsaaTypes,
  },
});

export const putAgIntegrationApi = (type: IntegrationType) => ({
  [RSAA]: {
    endpoint: `integrations?type=${type}&`,
    method: methods.PUT as RsaaMethods,
    module: modules.SENTINEL,
    types: [
      types.PUT_AGINTEGRATION_REQUEST,
      types.PUT_AGINTEGRATION_SUCCESS,
      types.PUT_AGINTEGRATION_ERROR,
    ] as RsaaTypes,
  },
});

export const deleteAgIntegrationApi = () => ({
  [RSAA]: {
    endpoint: `integrations?type=${IntegrationType.AGINTEGRATED}&`,
    method: methods.DELETE as RsaaMethods,
    module: modules.SENTINEL,
    types: [
      types.DELETE_AGINTEGRATION_REQUEST,
      types.DELETE_AGINTEGRATION_SUCCESS,
      types.DELETE_AGINTEGRATION_ERROR,
    ] as RsaaTypes,
  },
});

export const getAgIntegrationSettingsApi = () => ({
  [RSAA]: {
    endpoint: "variable-applications/agintegrated/settings?",
    method: methods.GET as RsaaMethods,
    module: modules.SENTINEL,
    types: [
      types.GET_AGINTEGRATION_SETTINGS_REQUEST,
      types.GET_AGINTEGRATION_SETTINGS_SUCCESS,
      types.GET_AGINTEGRATION_SETTINGS_ERROR,
    ] as RsaaTypes,
  },
});

export const createAgIntegrationApi = (
  param: getCreateAgIntegrationStatusApiParams,
) => ({
  [RSAA]: {
    endpoint: "variable-applications/agintegrated?",
    method: methods.POST as RsaaMethods,
    body: JSON.stringify(param),
    module: modules.SENTINEL,
    types: [
      types.POST_AGINTEGRATION_REQUEST,
      types.POST_AGINTEGRATION_SUCCESS,
      types.POST_AGINTEGRATION_ERROR,
    ] as RsaaTypes,
  },
});
