import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { Scrollbars } from "react-custom-scrollbars-2";

import { StatsBody } from "../../components/ParcelZoneTable/StatsBody";
import { StatsFooter } from "../../components/ParcelZoneTable/StatsFooter";
import { StatsHeader } from "../../components/ParcelZoneTable/StatsHeader";

import { FarmStatisticsTo } from "../../../../../shared/api/agroevidence/agroevidence.types";
import { StatisticsItemType } from "../../../../../shared/api/agroevidence/zones/zones.types";

type Props = {
  parcelsStats: FarmStatisticsTo;
  onFilterCropInParcels: (crops: StatisticsItemType) => void;
};

const CropParcelStats = ({ onFilterCropInParcels, parcelsStats }: Props) => (
  <>
    <Table stickyHeader>
      <StatsHeader />
    </Table>
    <Scrollbars autoHeight={true} autoHeightMax={400}>
      <Table data-test="crop-list">
        <TableBody>
          {parcelsStats.crops
            .sort((a, b) => b.totalArea - a.totalArea)
            .map((crop, id) => (
              <StatsBody
                key={id}
                onFilterCropInParcels={onFilterCropInParcels}
                item={{
                  id: crop.cropId,
                  name: crop.name,
                  count: crop.count,
                  totalArea: crop.totalArea,
                  externalId: crop.externalId,
                }}
              />
            ))}
        </TableBody>
      </Table>
    </Scrollbars>
    <Table>
      <TableBody>
        {parcelsStats.parcels.totalArea !== undefined && (
          <StatsFooter stats={parcelsStats.parcels} />
        )}
      </TableBody>
    </Table>
  </>
);

export { CropParcelStats };
