import { AnyAction } from "redux";

import * as commonTableTypes from "../../../../shared/actions/table.constants";
import * as sortOrder from "../../../../shared/constants/sortOrder.constants";
import * as types from "../actions/sentinel.constants";

import { SortOrderType } from "../../../../shared/api/api.types";
import { AdminFarm } from "../../sensors/admin.sensors.types";

export const NAMESPACE = "sentinel_admin_stats";

export interface SentinelAdminStatsUIState {
  farm?: AdminFarm | null;
  order: SortOrderType;
  orderBy: string;
}

const initialState: SentinelAdminStatsUIState = {
  order: sortOrder.ASC,
  orderBy: "name",
  farm: null,
};

export default (reducerNamespace: string = NAMESPACE) =>
  (state = initialState, action: AnyAction) => {
    const { namespace } = action;
    if (namespace && namespace !== reducerNamespace) return state;

    switch (action.type) {
      case commonTableTypes.SET_TABLE_ORDER:
        return {
          ...state,
          order: action.order,
        };
      case commonTableTypes.SET_TABLE_ORDER_BY:
        return {
          ...state,
          orderBy: action.orderBy,
        };
      case types.SET_FARM_FILTER:
        return {
          ...state,
          farm: action.farm,
        };
      default:
        return state;
    }
  };
