import { createSelector } from "reselect";

import {
  getIsFetchingParcelPredecessors,
  getIsFetchinParcelEagriRestrictions,
} from "../../../../../shared/api/agroevidence/parcels/parcels.selectors";

export const getIsFetchingParcelDetails = createSelector(
  getIsFetchinParcelEagriRestrictions,
  getIsFetchingParcelPredecessors,
  (isFetchinEagriRestrictions, isFetchingPredecessors) =>
    isFetchinEagriRestrictions || isFetchingPredecessors,
);
