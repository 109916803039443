import React from "react";

import TelematicsPartialDriveWarning from "../../TelematicsPartialDriveWarning/TelematicsPartialDriveWarning";

import { DriveValidationType } from "../../../../shared/api/telematics/telematics.types";

type Props = {
  show: boolean;
  tooltipCode?: DriveValidationType | "default";
} & React.HTMLAttributes<HTMLButtonElement>;

const ApprovalWarning = ({
  show,
  style = {},
  tooltipCode = "default",
}: Props) => {
  if (!show) {
    return null;
  }

  return (
    <TelematicsPartialDriveWarning
      style={{ marginRight: 0, ...style }}
      tooltipId={`TelematicsAggregations.incompleteRide.long.${tooltipCode}`}
    />
  );
};

export default ApprovalWarning;
