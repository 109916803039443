import { AnyAction } from "redux";

import * as types from "../actions/reports.constants";

export const NAMESPACE = "reports";

export type ReportsUI = {
  noContentReports: (string | number)[];
};

const initialState: ReportsUI = {
  noContentReports: [],
};

export default (reducerNamespace = NAMESPACE) =>
  (state = initialState, action: AnyAction) => {
    const { namespace } = action;
    if (namespace && namespace !== reducerNamespace) return state;

    switch (action.type) {
      case types.SET_NO_CONTENT_REPORTS:
        return {
          ...state,
          noContentReports: action.noContentReports,
        };
      case types.CLEAR_NO_CONTENT_REPORTS:
        return {
          ...state,
          noContentReports: [],
        };
      default:
        return state;
    }
  };
