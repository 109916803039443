import { AnyTodo } from "../../types";

export interface ArrayProps {
  [key: string]: string | number | AnyTodo;
}

export const sortArray = <T extends ArrayProps[]>(
  sortOption: string,
  sortBy: string,
  array: T,
): T =>
  array.sort((a, b) => {
    const A = a[sortBy];
    const B = b[sortBy];

    if (A === undefined && B === undefined) {
      return 0;
    }

    if (typeof A === "number" && typeof B === "number") {
      return sortOption === "desc" ? B - A : A - B;
    }
    if (typeof A === "string" && typeof B === "string") {
      return sortOption === "desc" ? B.localeCompare(A) : A.localeCompare(B);
    }
    if (typeof A === "object" && typeof B === "object" && A && B) {
      const nameA = A.name;
      const nameB = B.name;
      if (typeof nameA === "string" && typeof nameB === "string") {
        return sortOption === "desc"
          ? nameB.localeCompare(nameA)
          : nameA.localeCompare(nameB);
      }
    }

    if (A === undefined) {
      return 1;
    }
    if (B === undefined) {
      return -1;
    }
    throw new Error("Elements in column are not of the same type");
  });

export const paginationArray = <T extends ArrayProps[]>(
  page: number,
  rowsPerPage: number,
  array: T,
): T => {
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  return array.slice(startIndex, endIndex) as T;
};
