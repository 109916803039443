import React from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import CheckCircleIconFilled from "../../../../../shared/icons/CheckCircleIconFilled";
import VaMapSourceItem from "../VaMapSourceItem/VaMapSourceItem";

import { IndexResponse } from "../../../../../shared/api/satellite/satellite.types";
import { ExtendedIndexResponse } from "../../../../../shared/api/sentinel/managementZones/managementZones.types";

const ITEMS_PER_LINE = 5;

const getValId = (val: ExtendedIndexResponse | undefined = {}) => {
  const { chemicalElement, crop, dateFrom: from, dateTo: to, type } = val;

  return `${type}_${crop?.legislativeCode || chemicalElement}_${
    from || ""
  }_${to || ""}`;
};

type Props = {
  onItemClick: (item: ExtendedIndexResponse) => void;
  satellite?: IndexResponse;
  source: ExtendedIndexResponse[];
};

const VaMapSourceList = ({ onItemClick, satellite, source }: Props) => {
  const classes = useStyles();

  return (
    <div className={`${classes.wrapper} `}>
      {source.map((val, index) => {
        const isLast = index + 1 === ITEMS_PER_LINE;
        const isSelected = satellite && getValId(satellite) === getValId(val);

        return (
          <div
            className={`${classes.container} ${isLast ? classes.isLast : ""}`}
            data-test="source-image"
            key={`${getValId(val)}`}
          >
            {isSelected && (
              <div className={classes.selection}>
                <CheckCircleIconFilled className={classes.icon} />
              </div>
            )}
            <VaMapSourceItem
              isSelected={isSelected}
              mapId={getValId(val)}
              onClick={() => onItemClick(val)}
              satellite={val}
            />
          </div>
        );
      })}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    margin: "10px 0 0 0",
  },
  container: {
    position: "relative",
    marginRight: 5,
    marginBottom: 10,
  },
  isLast: {
    marginRight: 0,
  },
  selection: {
    backgroundColor: "transparent",
    zIndex: 3,
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    color: theme.palette.secondary.main,
    width: 45,
    height: 45,
  },
}));

export default VaMapSourceList;
