import React, { ReactNode } from "react";

import { Breakpoint, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import CfSectionHeading from "../../../shared/components/common/CfSectionHeading/CfSectionHeading";
import useWidth from "../../../shared/hooks/useWidth";
import { getLocalizedDateString } from "../../../shared/misc/timeHelpers";

import { InfoDialog } from "./InfoDialog/InfoDialog";

export interface Props {
  children?: ReactNode;
  dialogHeadingTranslation?: ReactNode;
  headingTranslation?: ReactNode;
  hintTranslation?: ReactNode;
  infoDialogContent?: ReactNode;
  maxDialogWidth?: Breakpoint;
  testId?: string;
  lastUpdate?: string;
}

const SectionHeader = ({
  children,
  dialogHeadingTranslation,
  headingTranslation,
  hintTranslation,
  infoDialogContent,
  lastUpdate,
  maxDialogWidth = "sm",
  testId,
}: Props) => {
  const classes = useStyles();
  const width = useWidth();
  const modalEnabled = hintTranslation || infoDialogContent;

  const renderHeading = (
    <div className={classes.mainHeading}>
      <CfSectionHeading>{headingTranslation}</CfSectionHeading>
    </div>
  );

  return (
    <div className={classes.wrapper} data-test={testId}>
      <div className={classes.headingWrapper}>
        <div className={classes.mainHeadingWrapper}>
          <div className={classes.mainHeadingUpper}>
            {renderHeading}
            {modalEnabled && (
              <InfoDialog
                hintTranslation={hintTranslation}
                infoDialogContent={infoDialogContent}
                maxDialogWidth={maxDialogWidth}
                title={dialogHeadingTranslation || headingTranslation}
              />
            )}
          </div>
          {lastUpdate && (
            <div className={classes.lastUpdate}>
              <FormattedMessage id="BioMonitoring.lastUpdate" />
              <span>:{"\u00A0"}</span>
              {getLocalizedDateString(lastUpdate)}
            </div>
          )}
        </div>
        {width !== "xs" && (
          <div className={classes.childrenWrapper}>{children}</div>
        )}
      </div>
      {width === "xs" && (
        <div className={classes.childrenWrapper}>{children}</div>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: "100%",
  },
  headingWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  mainHeadingWrapper: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "center",
    minHeight: 59,
  },
  mainHeading: {
    marginLeft: 15,
    marginRight: 3,
    lineHeight: 1.25,
  },
  mainHeadingUpper: {
    display: "flex",
    alignItems: "center",
    minHeight: 30,
  },
  childrenWrapper: {
    flexGrow: 1,
  },
  lastUpdate: {
    fontSize: 13,
    color: theme.palette.grey[500],
    marginLeft: 15,
  },
}));

export default SectionHeader;
