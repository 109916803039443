import { RSAA } from "redux-api-middleware";

import * as types from "./managementZones.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../api.constants";

import { RsaaTypes } from "../../api.types";

export const getManagementZoneParcel = (farmId: string, parcelId: string) => ({
  [RSAA]: {
    endpoint: `management-zones/${farmId}/${parcelId}?`,
    method: methods.GET,
    module: modules.SENTINEL,
    types: [
      types.GET_MANAGEMENT_ZONE_PARCEL,
      types.GET_MANAGEMENT_ZONE_PARCEL_SUCCESS,
      types.GET_MANAGEMENT_ZONE_PARCEL_ERROR,
    ] as RsaaTypes,
  },
});

export const resetManagementZoneParcel = () => ({
  type: types.RESET_MANAGEMENT_ZONE_PARCEL,
});
