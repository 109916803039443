import React from "react";

import { Theme } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { makeStyles } from "@mui/styles";

import { UserType } from "../../services/menuItems";
import NavbarService from "../../services/NavbarService";

interface Props {
  grey?: boolean;
  mobile?: boolean;
  user: UserType;
}

const UserAvatar = ({ grey = false, mobile = false, user }: Props) => {
  const classes = useStyles();
  const username = NavbarService.getUsername(user);
  return (
    <Avatar
      className={
        grey
          ? classes.greyAvatar
          : `${classes.mainAvatar} ${
              mobile ? classes.mainAvatarMobile : classes.mainAvatarDesktop
            }`
      }
    >
      {username.charAt(0).toUpperCase()}
    </Avatar>
  );
};

export default UserAvatar;

const useStyles = makeStyles((theme: Theme) => ({
  mainAvatar: {
    fontWeight: 700,
    margin: 10,
    backgroundColor: theme.palette.avatar.primary,
    border: "2px solid",
    borderColor: theme.palette.avatar.secondary,
    color: "inherit",
  },
  mainAvatarDesktop: {
    height: 24,
    width: 24,
    fontSize: 14,
  },
  mainAvatarMobile: {
    height: 26,
    width: 26,
    fontSize: 16,
  },
  greyAvatar: {
    backgroundColor: theme.palette.grey[500],
    color: theme.palette.common.white,
    height: 20,
    width: 20,
    fontSize: 14,
    fontWeight: 700,
  },
}));
