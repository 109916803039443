import { unByKey } from "ol/Observable";

import Logger from "../../../shared/services/Logger.service";

export default class EventListener {
  constructor(map) {
    this.map = map;
  }

  setFeatureEL(
    iaType,
    callback,
    refresh = () => {},
    key,
    layerFilter = () => true,
  ) {
    this[key] = this.map.on(iaType, (evt) => {
      const feature = this.map.forEachFeatureAtPixel(
        evt.pixel,
        (feat) => feat,
        {
          layerFilter,
        },
      );

      callback(feature, evt);
      refresh();
    });
  }

  setFeatureHoverEL(onMouseMove, refresh, key, layerFilter) {
    this.setFeatureEL("pointermove", onMouseMove, refresh, key, layerFilter);
  }

  setFeatureClickEL(onMouseClick, refresh, key, layerFilter) {
    this.setFeatureEL("singleclick", onMouseClick, refresh, key, layerFilter);
  }

  removeEL(key) {
    if (this[key]) {
      unByKey(this[key]);
    } else {
      Logger.warn(`Trying to unbind non-existing Event Listener ${key}`);
    }
  }

  preventContextMenuELNew(e) {
    e.preventDefault();
  }

  preventContextMenuELOld() {
    window.event.returnValue = false;
  }

  deactivateContextMenuEL() {
    if (document.addEventListener) {
      document.addEventListener(
        "contextmenu",
        this.preventContextMenuELNew,
        false,
      );
    } else {
      document.attachEvent("contextmenu", this.preventContextMenuELOld);
    }
  }

  activateContextMenuEL() {
    if (document.removeEventListener) {
      document.removeEventListener(
        "contextmenu",
        this.removeContextMenuELNew,
        false,
      );
    } else {
      document.detachEvent("contextmenu", this.removeContextMenuELOld);
    }
  }
}
