import React, { useEffect } from "react";

import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";

import { setActionButtonHidden } from "../shared/actions/manager.actions";

import { NAMESPACE } from "./reducer/farms.reducer";

import CfTextFilter from "../../../common/components/CfTextFilter/CfTextFilter";
import { useCfTextFilter } from "../../../common/components/CfTextFilter/useCfTextFilter";

import { FarmsAdminTable } from "./components/FarmsAdminTable";

const Farms = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { onChange } = useCfTextFilter(NAMESPACE);

  useEffect(() => {
    dispatch(setActionButtonHidden(true));
  }, [dispatch]);

  return (
    <div>
      <Grid className={classes.filterBarContainer} container spacing={0}>
        <Grid item lg={4} md={3} sm={6} xs={12}>
          <CfTextFilter
            name="farms-admin-text-filter"
            onChange={onChange}
            translId="common.farmNameAndID"
          />
        </Grid>
      </Grid>
      <FarmsAdminTable />
    </div>
  );
};

const useStyles = makeStyles({
  filterBarContainer: {
    marginBottom: 6,
    marginLeft: 10,
  },
});

export { Farms };
