import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useParcelZoneStatisticsStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    "&:before": {
      content: '""',
      display: "block",
      paddingTop: "100%",
    },
  },
  label: {
    display: "flex",
    alignItems: "center",
  },
  createZone: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.grey[500],
  },
  button: {
    display: "flex",
    justifyContent: "center",
    padding: "5px 15px",
  },
  createZoneForm: {
    padding: "15px 15px",
  },
  container: {
    maxHeight: 400,
  },
}));

export { useParcelZoneStatisticsStyles };
