import React from "react";

import { FormControlLabel, Theme, alpha } from "@mui/material";
import Switch from "@mui/material/Switch";
import { makeStyles } from "@mui/styles";
import { FieldProps } from "formik";

interface Props extends FieldProps {
  label: React.ReactElement;
  labelPlacement?: "bottom" | "top" | "end" | "start";
}

const CfFormikSwitch = ({ field, form, label, labelPlacement }: Props) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      classes={{ label: classes.label, root: classes.root }}
      label={label}
      labelPlacement={labelPlacement ?? "top"}
      control={
        <Switch
          checked={!!field.value}
          className={classes.switch}
          onChange={() => form.setFieldValue(field.name, !field.value)}
        />
      }
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    fontSize: 12,
    fontWeight: 400,
    color: alpha(theme.palette.common.black, 0.6),
    marginBottom: -6,
  },
  switch: {
    marginLeft: -12,
  },
  root: {
    alignItems: "flex-start",
    marginLeft: 0,
  },
}));

export default CfFormikSwitch;
