import React, { ReactNode, useContext } from "react";

import AddIcon from "@mui/icons-material/Add";
import { Box, Fab, Theme, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps } from "react-router-dom";

import { CATALOGUES_URLS, SECTIONS } from "../../catalogues.constants";

import CfPrimaryTab from "../../../shared/components/common/CfPrimaryTab/CfPrimaryTab";
import CfPrimaryTabs from "../../../shared/components/common/CfPrimaryTabs/CfPrimaryTabs";
import PageHeader from "../../../shared/components/common/PageHeader/PageHeader";
import PageHeading from "../../../shared/components/common/PageHeading/PageHeading";
import { useToggle } from "../../../shared/hooks/useToggle";
import SeedsEditDialog from "../../components/seeds/SeedsEditDialog";
import { CataloguesContext } from "../CataloguesWrapper/CataloguesWrapper";

interface Props extends RouteComponentProps<{ farmId: string }> {
  activeTab: SECTIONS;
  children: ReactNode;
}

const CataloguesTabs = ({ activeTab, children, history }: Props) => {
  const { farmId, isTelematicSectionAllowed } = useContext(CataloguesContext);
  const classes = useStyles();

  const {
    on: showEditDialog,
    setOff: handleEditDialogClose,
    setOn: handleEditDialogOpen,
  } = useToggle();

  const handleClick = (target: SECTIONS) => () => {
    history.push(`/farm/${farmId}/${CATALOGUES_URLS[target]}`);
  };

  const isFertilizerTab = activeTab === SECTIONS.FERTILIZERS_REACT;
  const isSeedsTab = activeTab === SECTIONS.SEEDS_REACT;
  const isPlantProtectionTab = activeTab === SECTIONS.PLANT_PROTECTION;

  const showAddButton = isFertilizerTab || isSeedsTab || isPlantProtectionTab;

  const handleCreate = () => {
    if (isFertilizerTab) {
      history.push(`/farm/${farmId}/${CATALOGUES_URLS.fertilizersReact}/new`);
    }
    if (isSeedsTab) {
      handleEditDialogOpen();
    }
    if (isPlantProtectionTab) {
      history.push(`/farm/${farmId}/${CATALOGUES_URLS.plantProtection}/new`);
    }
  };

  const actionButtonId = () => {
    if (isSeedsTab) {
      return "Catalogues.seeds.dialog.title.create";
    } else if (isPlantProtectionTab) {
      return "Catalogues.table.plantProtection.create.title";
    } else if (isFertilizerTab) {
      return "Catalogues.table.fertilizers.create.title";
    }
  };

  return (
    <div>
      <div className={classes.container}>
        <PageHeader
          actionButtons={
            showAddButton &&
            (isSeedsTab || isPlantProtectionTab || isFertilizerTab ? (
              <Tooltip title={<FormattedMessage id={actionButtonId()} />}>
                <Box className={classes.fabContainer}>
                  <Fab
                    aria-label="add"
                    className={classes.fab}
                    color="secondary"
                    onClick={handleCreate}
                    size="medium"
                  >
                    <AddIcon />
                  </Fab>
                </Box>
              </Tooltip>
            ) : (
              <Box className={classes.fabContainer}>
                <Fab
                  aria-label="add"
                  className={classes.fab}
                  color="secondary"
                  onClick={handleCreate}
                  size="medium"
                >
                  <AddIcon />
                </Fab>
              </Box>
            ))
          }
          classes={{
            header: classes.header,
          }}
          heading={
            <PageHeading
              dataTest="catalogues-heading"
              value={<FormattedMessage id="Catalogues.main.title" />}
            />
          }
        />
        <div className={classes.tabsContainer}>
          <CfPrimaryTabs centered tabValue={activeTab}>
            <CfPrimaryTab
              data-test={SECTIONS.SEEDS_REACT}
              label={<FormattedMessage id="Catalogues.section.seeds" />}
              onClick={handleClick(SECTIONS.SEEDS_REACT)}
              value={SECTIONS.SEEDS_REACT}
            />
            <CfPrimaryTab
              data-test={SECTIONS.FERTILIZERS_REACT}
              label={<FormattedMessage id="Catalogues.section.fertilizers" />}
              onClick={handleClick(SECTIONS.FERTILIZERS_REACT)}
              value={SECTIONS.FERTILIZERS_REACT}
            />
            <CfPrimaryTab
              data-test={SECTIONS.PLANT_PROTECTION}
              label={<FormattedMessage id="Catalogues.section.pors" />}
              onClick={handleClick(SECTIONS.PLANT_PROTECTION)}
              value={SECTIONS.PLANT_PROTECTION}
            />
            {isTelematicSectionAllowed && (
              <CfPrimaryTab
                data-test={SECTIONS.DRIVERS}
                label={<FormattedMessage id="Catalogues.section.drivers" />}
                onClick={handleClick(SECTIONS.DRIVERS)}
                value={SECTIONS.DRIVERS}
              />
            )}
            {isTelematicSectionAllowed && (
              <CfPrimaryTab
                data-test={SECTIONS.MACHINES}
                label={<FormattedMessage id="Catalogues.section.machines" />}
                onClick={handleClick(SECTIONS.MACHINES)}
                value={SECTIONS.MACHINES}
              />
            )}
            {isTelematicSectionAllowed && (
              <CfPrimaryTab
                data-test={SECTIONS.EQUIPMENT}
                label={<FormattedMessage id="Catalogues.section.equipment" />}
                onClick={handleClick(SECTIONS.EQUIPMENT)}
                value={SECTIONS.EQUIPMENT}
              />
            )}
          </CfPrimaryTabs>
        </div>
      </div>
      {children}
      <SeedsEditDialog
        handleClose={handleEditDialogClose}
        opened={showEditDialog}
      />
    </div>
  );
};

export default CataloguesTabs;

const useStyles = makeStyles((theme: Theme) => ({
  actionButton: {
    marginRight: theme.spacing(2),
  },
  header: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#fff",
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  tabsContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  fabContainer: {
    position: "absolute",
    paddingRight: 15,
  },
  fab: {
    color: "#fff",
  },
}));
