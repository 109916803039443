import React from "react";

import { Theme } from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";

import CfTableCellNumber from "../../../../../shared/components/tables/CfTableCellNumber/CfTableCellNumber";

import { StatsTableCell } from "./StatsTableCell";

const StatsHeader = () => {
  const classes = useStyles();
  return (
    <TableHead>
      <TableRow>
        <StatsTableCell
          key="name"
          classes={{
            root: classNames(classes.statCellBorderBottom, classes.firstCell),
          }}
        >
          <FormattedMessage id="common.name" />
        </StatsTableCell>
        <StatsTableCell
          key="count"
          classes={{
            root: classNames(classes.statCellBorderBottom, classes.cell),
          }}
        >
          <CfTableCellNumber>
            <FormattedMessage id="common.count" />
          </CfTableCellNumber>
        </StatsTableCell>
        <StatsTableCell
          key="area"
          classes={{
            root: classNames(classes.statCellBorderBottom, classes.cell),
          }}
        >
          <CfTableCellNumber>
            <FormattedMessage id="common.area" />
          </CfTableCellNumber>
        </StatsTableCell>
      </TableRow>
    </TableHead>
  );
};

export { StatsHeader };

const useStyles = makeStyles((theme: Theme) => ({
  statCellBorderBottom: {
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    color: theme.palette.grey[500],
    backgroundColor: "white",
  },
  firstCell: {
    width: "40%",
  },
  cell: {
    width: "30%",
  },
}));
