import React from "react";

import { Button, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import { PRECISION_URLS } from "../precision.constants";

import { PRECISION_TABS } from "../containers/PrecisionDetail";

import { PrecisionParcel } from "../../../shared/api/sentinel/precision/precision.types";

const PRECISION_SERVICES_BUTTON = [
  {
    code: ["managementZones"],
    tab: "zoneManagement",
    label: "PrecisionTableService.managementZones",
  },
  {
    code: ["biomonitoring", "internalBiomonitoring"],
    tab: "monitoring",
    label: "PrecisionTableService.monitoring",
  },
  {
    code: ["soilSamples"],
    tab: "soilSamples",
    label: "PrecisionTableService.soilSamples",
  },
  {
    code: ["yieldMaps"],
    tab: "yieldMaps",
    label: "PrecisionTableService.yieldMaps",
  },
] as const;

interface Props {
  farmId: string;
  forceReloadOnClick?: boolean;
  parcel: PrecisionParcel;
}

const PrecisionTableServiceIndicators = ({
  farmId,
  forceReloadOnClick = false,
  parcel,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  const handleGoToServiceTab = (
    tab: (typeof PRECISION_SERVICES_BUTTON)[number]["tab"],
    evt: React.MouseEvent,
  ) => {
    evt.stopPropagation();
    history.push(
      `/farm/${farmId}/${PRECISION_URLS.services()}/${parcel.id}/${
        PRECISION_TABS[tab].path
      }`,
    );
    if (forceReloadOnClick) {
      history.go(0);
    }
  };

  return (
    <Box className={classes.container}>
      {PRECISION_SERVICES_BUTTON.map((button) => {
        // Check if at least one value in the code array is true:
        const isActive = button.code.some((code) => parcel[code]);

        return (
          <Button
            className={classes.button}
            disabled={!isActive}
            key={button.code.join(",")}
            name={button.code.join(",")}
            onClick={(evt: React.MouseEvent) =>
              handleGoToServiceTab(button.tab, evt)
            }
            sx={{
              backgroundColor: isActive
                ? theme.precision.button[button.code[0]].background
                : "#E0E0E0",
              color: isActive ? "#FFFFFF" : "#848484",
              "&:hover": {
                backgroundColor:
                  theme.precision.button[button.code[0]].backgroundHover,
                color: "#FFFFFF",
              },
            }}
          >
            <FormattedMessage id={button.label} />
          </Button>
        );
      })}
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    justifyContent: "start",
    marginLeft: 15,
  },
  button: {
    height: 21,
    marginRight: 6,
    fontSize: 14,
    fontWeight: 400,
    whiteSpace: "nowrap",
    textTransform: "none",
    padding: "6px 4px",
  },
}));

export { PrecisionTableServiceIndicators };
