import { createSelector } from "reselect";

import { ExportListOwnProps } from "../../../../evidence/actions/shared/components/VariableActionExportsList/ExportsList";
import ErrorService from "../../../services/Error.service";

import { ActionsState } from "../../../../reducers/actions.reducer.types";

export const getItems = (state: ActionsState) =>
  state.api.variableApplication.items;
export const getIsFetching = (state: ActionsState) =>
  state.api.variableApplication.isFetching;
export const getError = (state: ActionsState) =>
  ErrorService.getResErrorDto(state.api.variableApplication.error);

export const getVariableActionZones = (state: ActionsState) =>
  state.api.variableApplication.variableActionZones;
export const getVariableActionZonesIsFetching = (state: ActionsState) =>
  state.api.variableApplication.variableActionZonesIsFetching;

export const getVariableActionBulkIsDeleting = (state: ActionsState) =>
  state.api.variableApplication.bulkIsDeleting;

export const getVariableExpense = (state: ActionsState) =>
  state.api.variableApplication.variableExpense;
export const getVariableExpenseIsFetching = (state: ActionsState) =>
  state.api.variableApplication.isVariableExpenseFetching;
export const getVariableExpenseIsError = (state: ActionsState) =>
  ErrorService.getResErrorDto(
    state.api.variableApplication.variableExpenseError,
  );

export const getVariableExpenses = (state: ActionsState) =>
  state.api.variableApplication.variableExpenses;

export const getVariableActionIds = (state: ActionsState) =>
  state.api.variableApplication.actionIds;

export const getVariableExpensesIsError = (state: ActionsState) =>
  ErrorService.getResErrorDto(
    state.api.variableApplication.variableExpensesError,
  );

export const getVariableExpenseSaveIsFetching = (state: ActionsState) =>
  state.api.variableApplication.saveVariableExpenseIsFetching;

export const getVariableExpensesIsFetching = (state: ActionsState) =>
  state.api.variableApplication.variableExpensesIsFetching;
export const getCreateVariableExpensesIsFetching = (state: ActionsState) =>
  state.api.variableApplication.isVariableExpenseFetching;

export const getVariableActionIdsError = (state: ActionsState) =>
  ErrorService.getResErrorDto(state.api.variableApplication.errorActions);

export const getAgiJobs = (state: ActionsState) =>
  state.api.variableApplication.agiJobs;

export const getAgiMachines = (state: ActionsState) =>
  state.api.variableApplication.agiMachines;

export const getIsExportingVA = (state: ActionsState) =>
  state.api.variableApplication.isExportingVA;

export const getAgiJobsByApplicationId = createSelector(
  [
    getAgiJobs,
    (_: never, ownProps: ExportListOwnProps) => ownProps.applicationId,
  ],
  (jobs, applicationId) => jobs?.[applicationId]?.result || {},
);

export const getAgiJobsStatusByApplicationId = createSelector(
  [
    getAgiJobs,
    (_: never, ownProps: ExportListOwnProps) => ownProps.applicationId,
  ],
  (jobs, applicationId) => jobs?.[applicationId]?.isFetching || false,
);
