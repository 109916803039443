import React, { useEffect } from "react";

import { Theme } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { getTool } from "../../selectors/editor.selectors";

import { useToggle } from "../../../../shared/hooks/useToggle";

const SnackbarHint = () => {
  const classes = useStyles();

  const tool = useSelector(getTool);

  const {
    on: showSnackbar,
    setOff: setCloseSnackbar,
    setOn: setOpenSnackbar,
  } = useToggle();

  useEffect(() => {
    if (tool === "MEASURE") {
      setOpenSnackbar();
    } else {
      setCloseSnackbar();
    }
  }, [setCloseSnackbar, setOpenSnackbar, tool]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      className={classes.snackbar}
      data-test="snackbar-hint"
      open={showSnackbar}
      classes={{
        root: classes.snackbarRoot,
      }}
      ContentProps={{
        classes: {
          message: classes.message,
          root: classes.root,
        },
      }}
      message={
        <div className={classes.wrapper}>
          <span className={classes.textLight}>
            <FormattedMessage id="hint.MEASURE" />
          </span>
        </div>
      }
    />
  );
};

export { SnackbarHint };

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    textAlign: "center",
  },
  message: {
    width: "100%",
  },
  snackbarRoot: {
    bottom: 0,
  },
  root: {
    borderRadius: "4px 4px 0 0",
    [theme.breakpoints.up("md")]: {
      maxWidth: "750px",
    },
  },
  snackbar: {
    bottom: 0,
  },
  textLight: {
    color: "rgba(255,255,255,1)",
  },
}));
