import React from "react";

import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import { IconButton, IconButtonProps, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import CfLoader from "../../../shared/components/common/CfLoader/CfLoader";

type Props = {
  state: "pending" | "postponed" | "postponable";
} & Pick<IconButtonProps, "onClick">;

const PostponedIconButton = ({ onClick, state }: Props) => {
  const classes = useStyles();

  const handleEventPropagation = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.stopPropagation();
  };

  return (
    <div onClick={handleEventPropagation}>
      {state === "pending" ? (
        <CfLoader
          classes={{ wrapper: classes.wrapper }}
          color="inherit"
          size={20}
        />
      ) : null}
      {state === "postponed" || state === "postponable" ? (
        <Tooltip title={<FormattedMessage id={getLabelFromState(state)} />}>
          <span>
            <IconButton
              aria-label="Postponed ride"
              color={state === "postponed" ? "primary" : undefined}
              onClick={onClick}
              size="large"
            >
              {state === "postponed" ? (
                <BookmarkIcon />
              ) : (
                <BookmarkBorderOutlinedIcon />
              )}
            </IconButton>
          </span>
        </Tooltip>
      ) : null}
    </div>
  );
};

const getLabelFromState = (state: Exclude<Props["state"], "pending">) => {
  switch (state) {
    case "postponable":
      return "TelematicsAggregations.postponed.ride.acceptTooltip";
    case "postponed":
      return "TelematicsAggregations.postponed.ride.cancelTooltip";
    default:
      throw new Error(`Unexpected state: ${state}`);
  }
};

const getState = ({
  pending,
  postponed,
}: {
  pending?: boolean;
  postponed?: boolean;
}): Props["state"] => {
  if (pending) {
    return "pending";
  }

  if (postponed) {
    return "postponed";
  }

  return "postponable";
};

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: "14px",
  },
}));

export { getState, PostponedIconButton };
