import { createSelector } from "reselect";

import { getParcelsReportsState } from "../../../shared/api/agroevidence/parcels/parcels.selectors";
import { getZones } from "../../../shared/api/agroevidence/zones/zones.selectors";

import { AnyTodo } from "../../../types";
import { ReportsFormValues } from "../containers/Reports/types";

import { ReportsState } from "../../../reducers/reports.reducer.types";

export const getParcelsAndZonesSuggestions = (filter: AnyTodo) =>
  createSelector(
    getParcelsReportsState("ui.reportParcels.suggestions"),
    getZones,
    (parcels, zones) =>
      [
        {
          title: "common.parcels",
          items: filter ? parcels.filter?.(filter) : parcels,
        },
        {
          title: "common.zones",
          items: filter ? zones.filter?.(filter) : zones,
        },
      ].filter((section) => section.items?.length > 0),
  );

export const getParcelsToAdd = (state: ReportsState) =>
  getParcelsReportsState("ui.reportParcels.additions")(state);

export const getParcelIds = (values: ReportsFormValues) => {
  const parcels = values.parcels || [];
  return parcels.map((parcel) => parcel.id);
};

export const getNoContentReports = (state: ReportsState) =>
  state.ui.reports.noContentReports;
