import React from "react";

import { Theme } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

interface Props {
  isEditing: boolean;
  isExisting: boolean;
  isSubmitting: boolean;
  handleReset: () => void;
}

export const Buttons = ({
  handleReset,
  isEditing,
  isExisting,
  isSubmitting,
}: Props) => {
  const classes = useStyles();
  return (
    <Grid
      alignItems="center"
      className={classes.buttons}
      container
      justifyContent="center"
      spacing={4}
    >
      <Button
        className={classes.button}
        disabled={!isEditing}
        id="reset"
        onClick={handleReset}
        type="reset"
        variant="contained"
      >
        <FormattedMessage id={isExisting ? "common.cancel" : "common.reset"} />
      </Button>
      <Button
        className={classes.button}
        color="primary"
        disabled={!isEditing || isSubmitting}
        id="create"
        type="submit"
        variant="contained"
      >
        <FormattedMessage id={isExisting ? "common.save" : "common.create"} />
      </Button>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  buttons: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  button: {
    margin: 10,
  },
}));
