import React, { useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment, { Moment } from "moment";
import { FormattedMessage } from "react-intl";

import { getShortDateString } from "../../../../shared/misc/timeHelpers";

export enum EconomicYearType {
  PREVIOUS = "PREVIOUS",
  CURRENT = "CURRENT",
}

interface Props {
  onAccept: (dateFrom: string) => void;
  onClose: () => void;
  showDialog?: boolean;
}

const ReportOrganicNitrogenDialog = ({
  onAccept,
  onClose,
  showDialog = false,
}: Props) => {
  const classes = useStyles();
  const [economicYearType, setEconomicYearType] = useState(
    EconomicYearType.CURRENT,
  );

  const { dateFrom, dateRange } = updateDateRange(economicYearType);

  const handleExportReport = () => {
    onAccept(getShortDateString(dateFrom));
    onClose();
  };

  const handleRadioGroupChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setEconomicYearType(
      (event.target as HTMLInputElement).value as EconomicYearType,
    );
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogContainer }}
      data-test="export-organic-nitrogen-dialog"
      onClose={onClose}
      open={showDialog}
    >
      <DialogTitle data-test="dialog-title">
        <FormattedMessage id="Reports.organicNitrogen.Heading" />
      </DialogTitle>
      <DialogContent>
        <Grid className={classes.itemContainer}>
          <Grid className={classes.radioGroupWrapper} item xs={5}>
            <DialogContentText className={classes.label}>
              <FormattedMessage id="Reports.organicNitrogen.dialog.economicYearsLabel" />
            </DialogContentText>
            <RadioGroup
              aria-labelledby="controlled-radio-buttons-group"
              name="radio-buttons-group"
              onChange={handleRadioGroupChange}
              value={economicYearType}
            >
              <FormControlLabel
                control={<Radio size="small" />}
                value={EconomicYearType.PREVIOUS}
                label={
                  <FormattedMessage id="Reports.organicNitrogen.dialog.economicYear.previous" />
                }
              />
              <FormControlLabel
                control={<Radio size="small" />}
                value={EconomicYearType.CURRENT}
                label={
                  <FormattedMessage id="Reports.organicNitrogen.dialog.economicYear.current" />
                }
              />
            </RadioGroup>
          </Grid>
          <div className={classes.label}>
            <FormattedMessage id="Reports.organicNitrogen.dialog.statisticsPeriod" />
          </div>
          <div className={classes.statisticsDateRange}>{dateRange}</div>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          alignItems="center"
          container
          justifyContent="flex-end"
          spacing={1}
        >
          <Button
            className={classes.button}
            id="reset"
            onClick={onClose}
            type="reset"
            variant="text"
          >
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button
            className={classes.button}
            color="primary"
            id="send"
            onClick={handleExportReport}
            type="submit"
            variant="text"
          >
            <FormattedMessage id="common.export" />
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ReportOrganicNitrogenDialog;

const useStyles = makeStyles(() => ({
  dialogContainer: {
    maxWidth: "400px",
  },
  itemContainer: {
    padding: "5px 0px",
  },
  button: {
    marginLeft: 8,
  },
  radioGroupWrapper: {
    fontSize: 14,
    fontWeight: 500,
    marginBottom: 30,
  },
  label: {
    fontSize: 12,
    marginBottom: 5,
  },
  statisticsDateRange: {
    fontSize: 16,
    fontWeight: 400,
    marginBottom: 5,
  },
}));

const updateDateRange = (economicYearType: EconomicYearType) => {
  const today = moment();
  const currentYear = today.year();

  let dateFrom: Moment;
  let dateRange = "";

  if (today.isBefore(moment(`${currentYear}-07-01`))) {
    if (economicYearType === EconomicYearType.PREVIOUS) {
      dateFrom = today.clone().subtract(1, "years").startOf("year");
      dateRange = `1.7.${currentYear - 2} - 30.06.${currentYear - 1}`;
    } else {
      dateFrom = today.clone().startOf("year");
      dateRange = `1.7.${currentYear - 1} - 30.06.${currentYear}`;
    }
  } else if (economicYearType === EconomicYearType.PREVIOUS) {
    dateFrom = today.clone().startOf("year");
    dateRange = `1.7.${currentYear - 1} - 30.06.${currentYear}`;
  } else {
    dateFrom = today.clone().add(1, "years").startOf("year");
    dateRange = `1.7.${currentYear} - 30.06.${currentYear + 1}`;
  }

  return { dateFrom, dateRange };
};
