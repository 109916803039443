import React, { Component, Fragment } from "react";

import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { FormattedMessage, FormattedDate } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getStoresMaterialType } from "../../../list/selectors/stores.selectors";
import {
  getStoreItemById,
  getStoreFromFilter,
  getStoreToFilter,
  getStoreUnitId,
  getStoreAmount,
  getApiError,
} from "../../selectors/store.selectors";

import {
  fetchStore,
  saveTransaction,
  updateTransaction,
  deleteTransaction,
  setStoreFromFilter,
  setStoreToFilter,
  updateStoreViewData,
  exportStore,
} from "../../actions/store.actions";

import { resetStores } from "../../../../../shared/api/stores/stores/stores.api";
import CfBackButton from "../../../../../shared/components/common/CfBackButton/CfBackButton";
import CfDateFilter from "../../../../../shared/components/common/CfDateFilter/CfDateFilter";
import CfErrorPage from "../../../../../shared/components/common/CfErrorPage/CfErrorPage";
import PageHeader from "../../../../../shared/components/common/PageHeader/PageHeader";
import PageHeading from "../../../../../shared/components/common/PageHeading/PageHeading";
import { TABS } from "../../../list/components/StoresTabs/StoresTabs";
import DeleteDialog from "../../../shared/components/DeleteDialog/DeleteDialog";
import StoreExport from "../../../shared/components/StoreExport/StoreExport";
import StoreFabButton from "../../../shared/components/StoreFabButton/StoreFabButton";
import TransactionDialog from "../../../shared/containers/TransactionDialog/TransactionDialog";
import StoreUnitSwitch from "../../components/StoreUnitSwitch/StoreUnitSwitch";
import TransactionsTable from "../TransactionsTable/TransactionsTable";

const styles = (theme) => ({
  wrapper: {
    padding: theme.spacing(2),
  },
  header: {
    paddingBottom: theme.spacing(1),
  },
  filtersContainer: {
    marginBottom: "10px",
  },
});

const KEYS = {
  DELETE: "delete",
  CREATE: "create",
};

const ACTUAL_TAB = {
  [TABS.fertilizers.materialTypeId]: TABS.fertilizers.url,
  [TABS.chemistries.materialTypeId]: TABS.chemistries.url,
  [TABS.seeds.materialTypeId]: TABS.seeds.url,
};

export class Store extends Component {
  constructor(props) {
    super(props);

    this.state = {
      [KEYS.DELETE]: false,
      [KEYS.CREATE]: false,
      transaction: {},
    };
  }

  componentDidMount() {
    const { farmId, match } = this.props;
    const { storeId } = match.params;
    this.props.fetchStore(farmId, storeId);
  }

  componentDidUpdate(prevProps) {
    const { farmId, match } = this.props;
    if (match.params.storeId !== prevProps.match.params.storeId) {
      this.props.fetchStore(farmId, match.params.storeId);
    }
  }

  componentWillUnmount() {
    this.props.resetStores();
  }

  handleDialogOpen = (key, transaction = {}) => {
    this.setState({
      [key]: true,
      transaction,
    });
  };

  handleDialogClose = (key) => {
    this.setState({
      [key]: false,
      transaction: {},
    });
  };

  handleDialogAccept = (key, dto = {}) => {
    const { transaction } = this.state;
    const { farmId, item } = this.props;

    this.handleDialogClose(key);
    switch (key) {
      case KEYS.DELETE:
        return this.props.deleteTransaction(transaction.id, item.id, farmId);
      case KEYS.CREATE:
        if (!isEmpty(transaction)) {
          return this.props.updateTransaction(dto, item.id, farmId);
        }
        return this.props.saveTransaction(dto, item.id, farmId);

      default:
        throw new Error("Unrecognized store action");
    }
  };

  render() {
    const {
      amount,
      classes,
      dateFrom,
      dateTo,
      error,
      farmId,
      history,
      item,
      materialTypeId,
      ngGoToAction,
      unitId,
    } = this.props;
    const name = item && item.material ? item.material.name : "";
    const { transaction } = this.state;

    return (
      <CfErrorPage error={error}>
        <div className={classes.wrapper}>
          <PageHeader
            classes={{ header: classes.header }}
            heading={<PageHeading dataTest="store-heading" value={name} />}
            actionButtons={
              <Fragment>
                <StoreExport
                  handleExport={this.props.exportStore}
                  storeId={item.id}
                />
                <StoreFabButton
                  callback={() => this.handleDialogOpen(KEYS.CREATE)}
                />
              </Fragment>
            }
            backButton={
              <CfBackButton
                translId="Stores.backToStorages"
                onClick={() =>
                  history.push(
                    `/farm/${farmId}/stores/${ACTUAL_TAB[materialTypeId]}`,
                  )
                }
              />
            }
          />
          <div>
            <Grid
              className={classes.filtersContainer}
              container
              justifyContent="center"
              spacing={2}
            >
              <Grid item lg={2} sm={3} xs={12}>
                <StoreUnitSwitch
                  amount={amount}
                  farmId={farmId}
                  item={item}
                  onStoreUnitChange={this.props.updateStoreViewData}
                  unitId={unitId}
                />
              </Grid>
              <Grid item lg={2} sm={3} xs={12}>
                <CfDateFilter
                  label={<FormattedMessage id="common.date-from" />}
                  name="store-filter-from"
                  testId="store-filter-from"
                  value={dateFrom}
                  onFilterChange={(val) =>
                    this.props.setStoreFromFilter(val ? val.toISOString() : val)
                  }
                />
              </Grid>
              <Grid item lg={2} sm={3} xs={12}>
                <CfDateFilter
                  label={<FormattedMessage id="common.date-to" />}
                  name="store-filter-to"
                  testId="store-filter-to"
                  value={dateTo}
                  onFilterChange={(val) =>
                    this.props.setStoreToFilter(
                      val ? val.endOf("day").toISOString() : val,
                    )
                  }
                />
              </Grid>
            </Grid>
            <div>
              <TransactionsTable
                ngGoToAction={ngGoToAction}
                onDeleteOpen={(tr) => this.handleDialogOpen(KEYS.DELETE, tr)}
                onUpdateOpen={(tr) => this.handleDialogOpen(KEYS.CREATE, tr)}
                storeId={item.id}
              />
              <TransactionDialog
                onAccept={(vals) => this.handleDialogAccept(KEYS.CREATE, vals)}
                onClose={() => this.handleDialogClose(KEYS.CREATE)}
                opened={this.state.create}
                storeItem={item}
                transactionItem={this.state.transaction}
              />
              <DeleteDialog
                onAccept={() => this.handleDialogAccept(KEYS.DELETE)}
                onClose={() => this.handleDialogClose(KEYS.DELETE)}
                opened={this.state.delete}
                title={
                  <FormattedMessage id="Stores.store-delete-transaction" />
                }
              >
                <FormattedMessage
                  id="Stores.store-delete-transaction-confirm"
                  values={{
                    type: transaction.type ? (
                      <FormattedMessage id={`Stores.${transaction.type}`} />
                    ) : (
                      ""
                    ),
                    amount: transaction.amount,
                    date: transaction.date ? (
                      <FormattedDate value={transaction.date || ""} />
                    ) : (
                      ""
                    ),
                    unitId: <FormattedMessage id={`unit.${item.unitId}`} />,
                  }}
                />
              </DeleteDialog>
            </div>
          </div>
        </div>
      </CfErrorPage>
    );
  }
}

Store.propTypes = {
  history: PropTypes.object.isRequired,
  item: PropTypes.object,
  farmId: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  unitId: PropTypes.string,
  amount: PropTypes.number,
  fetchStore: PropTypes.func.isRequired,
  resetStores: PropTypes.func.isRequired,
  saveTransaction: PropTypes.func.isRequired,
  updateTransaction: PropTypes.func.isRequired,
  deleteTransaction: PropTypes.func.isRequired,
  setStoreFromFilter: PropTypes.func.isRequired,
  setStoreToFilter: PropTypes.func.isRequired,
  updateStoreViewData: PropTypes.func.isRequired,
  ngGoToAction: PropTypes.func.isRequired,
  error: PropTypes.object.isRequired,
  exportStore: PropTypes.func.isRequired,
  materialTypeId: PropTypes.string.isRequired,
};

Store.defaultProps = {
  item: {},
  dateFrom: "",
  dateTo: "",
  amount: 0,
  unitId: "",
  materialTypeId: "FR",
};

const mapStateToProps = (state, props) => ({
  item: getStoreItemById(state, props.match.params.storeId),
  dateFrom: getStoreFromFilter(state),
  dateTo: getStoreToFilter(state),
  amount: getStoreAmount(state),
  unitId: getStoreUnitId(state),
  error: getApiError(state),
  materialTypeId: getStoresMaterialType(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchStore,
      resetStores,
      saveTransaction,
      updateTransaction,
      deleteTransaction,
      setStoreFromFilter,
      setStoreToFilter,
      updateStoreViewData,
      exportStore,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Store));
