import React from "react";

import LinearProgress from "@mui/material/LinearProgress";
import MenuItem from "@mui/material/MenuItem";
import { Field } from "formik";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import {
  getHarvestProducts,
  getIsFetchingHarvestProducts,
} from "../../../../../shared/api/agroevidence/catalogues/eagri/eagri.selectors";

import CfFormControl from "../../../../../shared/components/form/CfFormControl/CfFormControl";
import CfFormikTextField from "../../../../../shared/components/form/CfFormikTextField/CfFormikTextField";
import * as validators from "../../../../../shared/misc/validators";

import { HarvestProductTo } from "../../../../../shared/api/agroevidence/agroevidence.types";

interface Props {
  isEditing: boolean;
}

export const MowingProductSelector = ({ isEditing }: Props) => {
  const products = useSelector(getHarvestProducts);
  const isFetching = useSelector(getIsFetchingHarvestProducts);

  if (isFetching) {
    return <LinearProgress color="secondary" />;
  }

  return products.length > 0 ? (
    <CfFormControl>
      <Field
        component={CfFormikTextField}
        disabled={!isEditing || isFetching}
        label={<FormattedMessage id="action.mowingProduct" />}
        name="productId"
        select
        validate={validators.required}
        validateOnBlur
      >
        {products.map((product: HarvestProductTo) => (
          <MenuItem key={product.externalId} value={product.externalId}>
            {product.description}
          </MenuItem>
        ))}
      </Field>
    </CfFormControl>
  ) : null;
};
