import React from "react";

import FilterList from "@mui/icons-material/FilterList";
import { Theme } from "@mui/material";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";

import CfSwitcherButton from "../../components/common/CfSwitcherButton/CfSwitcherButton";
import usePopover from "../../hooks/usePopover";

interface CfSingleFilterProps<T> {
  filter: string;
  filterItems: T[];
  filterItemsState: T[];
  formattedMessageId: string;
  mainPage?: boolean;
  setFilterItems: (filterItems: T[]) => void;
  setFilterItemsState: (filterItems: T[]) => void;
  withButton?: boolean;
  disabled?: boolean;
}

const CfSingleFilter = <T extends object>({
  children,
  disabled = false,
  filter = "",
  filterItems,
  filterItemsState,
  formattedMessageId,
  mainPage,
  setFilterItems,
  setFilterItemsState,
  withButton = true,
}: React.PropsWithChildren<CfSingleFilterProps<T>>) => {
  const { anchorEl, handlePopoverClose, handlePopoverOpen, isOpen } =
    usePopover();

  const handleFilterAccept = () => {
    setFilterItems(filterItemsState);
    handlePopoverClose();
  };

  const handleFilterCancel = () => {
    setFilterItems([]);
    setFilterItemsState([]);
    handlePopoverClose();
  };

  const styles = useStyles();
  const stylesMainPage = useStylesMainPage();
  const classes = mainPage ? stylesMainPage : styles;

  const filterCount = filterItems.length;
  const filterValue = filterCount > 0 ? `(${filterCount})` : "";
  return (
    <>
      <CfSwitcherButton
        data-test={`advanced-filter${filter}`}
        disabled={disabled}
        onClick={handlePopoverOpen}
        classes={{
          root: filterCount > 0 ? classes.selectedFilter : "",
        }}
        className={classnames(
          classes.filterButton,
          filterCount > 0 ? classes.selectedFilter : "",
        )}
      >
        <FilterList
          className={classes.filterIcon}
          style={filterCount > 0 ? { color: "#FFFFFF" } : {}}
        />
        <span className={classes.switcherButtonLabel}>
          <FormattedMessage
            id={formattedMessageId}
            values={{ count: filterValue }}
          />
        </span>
      </CfSwitcherButton>
      <Popover
        anchorEl={anchorEl}
        classes={{ paper: classes.popoverPaper }}
        onClose={handlePopoverClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {children}
        {withButton && (
          <div className={classes.buttons}>
            <Button id="cancel" onClick={handleFilterCancel}>
              <FormattedMessage id="CfFilter.cancelFilter" />
            </Button>
            <Button color="primary" id="accept" onClick={handleFilterAccept}>
              <FormattedMessage id="CfFilter.filter" />
            </Button>
          </div>
        )}
      </Popover>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  filterButton: {},
  popoverPaper: {
    overflow: "visible",
    marginTop: 7,
  },
  switcherButtonLabel: {
    fontSize: 14,
  },
  filterIcon: {
    color: theme.palette.grey[500],
    marginRight: 6,
  },
  selectedFilter: {
    width: "max-content",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  buttons: {
    float: "right",
    margin: "0px 8px 4px",
  },
}));

const useStylesMainPage = makeStyles((theme: Theme) => ({
  filterButton: {
    height: 28,
    background: theme.palette.common.white,
  },
  popoverPaper: {
    overflow: "visible",
    marginTop: 7,
  },
  switcherButtonLabel: {
    fontSize: 14,
  },
  filterIcon: {
    marginRight: 6,
  },
  selectedFilter: {
    width: "max-content",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  buttons: {
    float: "right",
    margin: "0px 8px 4px",
  },
}));

export default CfSingleFilter;
