import React from "react";

import { Grid } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import { useIntl } from "react-intl";

import { PlantProtectionEffectiveness } from "../../../../../shared/api/agroevidence/agroevidence.types";

type Props = {
  parcelName: string;
  value: string;
  index: number;
  hasNext: boolean;
};

export const PlantProtectionEffectivenessListItem = ({
  hasNext,
  index,
  parcelName,
  value,
}: Props) => {
  const classes = useStyles();
  const intl = useIntl();

  const messageId = `Por.Effectiveness.${value.toLowerCase()}`;
  const message =
    value === PlantProtectionEffectiveness.NOT_SPECIFIED
      ? "-"
      : intl.formatMessage({ id: messageId }).toUpperCase();
  return (
    <Tooltip
      placement="bottom"
      title={parcelName}
      classes={{
        tooltip: classes.tooltip,
      }}
    >
      <Grid item>{`${message}${index === 2 && hasNext ? " ..." : ""}${
        hasNext && index < 2 ? "\u00A0|\u00A0" : ""
      }`}</Grid>
    </Tooltip>
  );
};

const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: 12,
    marginBottom: 4,
  },
}));
