import React, { PropsWithChildren } from "react";

import { Input, InputProps } from "@mui/material";
import { useField } from "formik";

import { getError } from "../../../misc/formHelpers";
import FormErrorMessage from "../FormErrorMessage/FormErrorMessage";

export type FormInputProps = PropsWithChildren<
  Omit<InputProps, "onChange" | "onBlur" | "value">
>;

const FormInput = (props: FormInputProps) => {
  const { ...settings } = props;
  const fieldProps = useField(settings.name || "");
  const field = fieldProps[0];
  const meta = fieldProps[1];

  return (
    <>
      <Input
        {...field}
        error={!!getError(meta)}
        onBlur={(e) => {
          field.onBlur(e);
        }}
        onChange={(e) => {
          field.onChange(e);
        }}
        {...settings}
      />
      <FormErrorMessage name={field.name} />
    </>
  );
};

export default FormInput;
