import React, { useEffect } from "react";

import TableBody from "@mui/material/TableBody";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getIsFetchingParcelsCropDetails,
  getParcelsCropDetails,
  getTotalCountParcelsCropDetails,
} from "../../../../shared/api/agroevidence/parcelsCropDetails/parcelsCropDetails.selectors";

import { assignCrop } from "../../shared/actions/parcels.actions";
import { fetchParcelsWithCropDetails } from "../actions/parcelCropList.actions";

import CfTableFooter from "../../../../common/components/CfTableFooter/CfTableFooter";
import { useCfTableFooter } from "../../../../common/components/CfTableFooter/useCfTableFooter";
import CfTableHead from "../../../../common/components/CfTableHead/CfTableHead";
import { useCfTableHead } from "../../../../common/components/CfTableHead/useCfTableHead";
import {
  resetParcelsApi,
  resetParcelApi,
} from "../../../../shared/api/agroevidence/parcels/parcels.api";
import { getParcelSowingPlanApi } from "../../../../shared/api/agroevidence/sowingPlan/sowingPlan.api";
import CfTableBodyEmpty from "../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableBodyLoader from "../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader";
import CfTableWrapper from "../../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import { Thunk } from "../../../../types";
import {
  getParcelCropDetailsListPage,
  getParcelCropDetailsListOrder,
  getParcelCropDetailsListOrderBy,
  getParcelCropDetailsListRowsPerPage,
  getParcelCropDetailsListSelected,
  getParcelCropDetailsListTextFilter,
  getParcelCropDetailsListAdvancedFilter,
  getParcelListCropDetailsSelectedOnPage,
} from "../selectors/parcelCropDetailsList.selector";

import { parcelCropsTableColumns } from "./ParcelCropsTable.columns";
import ParcelCropsTableRow from "./ParcelCropsTableRow";

import { ParcelsState } from "../../../../reducers/parcels.reducer.types";
import {
  CropTo,
  SowingPlanSeasonTo,
} from "../../../../shared/api/agroevidence/agroevidence.types";

type ReduxProps = ConnectedProps<typeof connector>;
type OwnProps = {
  bulkAssignCrop: (crop: CropTo, seasonId: string, parcelIds: string[]) => void;
  farmId: string;
  namespace: string;
  parcelSowingPlan: SowingPlanSeasonTo[];

  shouldReloadData: boolean;
};
type ParcelCropsTableProps = OwnProps & ReduxProps;

const ParcelCropsTable = ({
  advancedFilter,
  bulkAssignCrop,
  count,
  farmId,
  fetchParcelsWithCropDetails,
  isFetchingParcels,
  namespace,
  order,
  orderBy,
  page,
  parcelSowingPlan,
  rowsPerPage,
  seasonParcels,
  selected,
  selectedOnPage,
  shouldReloadData,
  textFilter,
}: ParcelCropsTableProps) => {
  const { onPageChange, onRowsPerPageChange } = useCfTableFooter(namespace);

  const tableHeadColumns = parcelCropsTableColumns(parcelSowingPlan);
  const { onSelect, onSort } = useCfTableHead(namespace);

  useEffect(() => {
    fetchParcelsWithCropDetails();
  }, [
    page,
    order,
    orderBy,
    rowsPerPage,
    shouldReloadData,
    textFilter,
    advancedFilter,
    fetchParcelsWithCropDetails,
  ]);

  const handleAssignCrop = (
    crop: CropTo,
    seasonId: string,
    parcelId: string,
  ) => {
    bulkAssignCrop(crop, seasonId, [parcelId]);
  };

  return (
    <CfTableWrapper>
      <CfTableHead
        columns={tableHeadColumns}
        items={seasonParcels}
        onSelect={onSelect}
        onSort={onSort}
        order={order}
        orderBy={orderBy}
        selected={selected}
        selectedOnPage={selectedOnPage}
      />
      {isFetchingParcels && <CfTableBodyLoader columns={tableHeadColumns} />}
      {seasonParcels.length > 0 && !isFetchingParcels ? (
        <TableBody>
          {seasonParcels.map((parcel) => (
            <ParcelCropsTableRow
              farmId={farmId}
              isSelected={selected.includes(parcel.id)}
              key={parcel.id}
              namespace={namespace}
              onAssignCrop={handleAssignCrop}
              parcel={parcel}
              selected={selected}
            />
          ))}
        </TableBody>
      ) : (
        <CfTableBodyEmpty
          colLength={Object.keys(tableHeadColumns).length + 1}
        />
      )}
      <CfTableFooter
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </CfTableWrapper>
  );
};

const mapStateToProps = (state: ParcelsState) => ({
  seasonParcels: getParcelsCropDetails()(state),
  isFetchingParcels: getIsFetchingParcelsCropDetails()(state),
  count: getTotalCountParcelsCropDetails(state),
  page: getParcelCropDetailsListPage(state),
  order: getParcelCropDetailsListOrder(state),
  orderBy: getParcelCropDetailsListOrderBy(state),
  rowsPerPage: getParcelCropDetailsListRowsPerPage(state),
  selected: getParcelCropDetailsListSelected(state),
  selectedOnPage: getParcelListCropDetailsSelectedOnPage(state),
  textFilter: getParcelCropDetailsListTextFilter(state),
  advancedFilter: getParcelCropDetailsListAdvancedFilter(state),
});

const mapDispatchToProps = (dispatch: Thunk<ParcelsState>) =>
  bindActionCreators(
    {
      fetchParcelsWithCropDetails,
      getParcelSowingPlanApi,
      resetParcelsApi,
      resetParcelApi,
      assignCrop,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ParcelCropsTable);
