import { queryOptions } from "@tanstack/react-query";

import { GetAll5Params, getAll5 } from "../../../../generated/api/telematics";
import { staleTime } from "../../../../shared/query-client";

const equipmentsQuery = ({ dateFrom, dateTo, farmIds }: GetAll5Params) =>
  queryOptions({
    queryKey: ["telematics", "equipments", dateFrom, dateTo, farmIds],
    queryFn: () =>
      getAll5({
        dateFrom,
        dateTo,
        "sort-col": "NAME",
        "sort-dir": "ASC",
        farmIds,
      }),
    staleTime: staleTime.Infinity,
  });

export { equipmentsQuery };
