import debounce from "lodash/debounce";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import Polygon from "ol/geom/Polygon";

import {
  activateContextMenuEL,
  deactivateContextMenuEL,
} from "../eventListener/eventListener.actions";
import {
  removeDrawIA,
  removeLayer,
  removeModifyIA,
  setDrawIA,
  setLayer,
  setSnapIA,
  setModifyIA,
  addFeature,
  removeFeature,
  clearLayer,
  removeSnapIA,
} from "../interaction/interaction.actions";
import { mapSetCursor } from "../map/map.actions";
import {
  addMeasurementOverlay,
  removeOverlay,
  removeOverlays,
} from "../overlay/overlay.actions";

import * as types from "./measurement.constants";

import Geometry, { GEOM_TYPES } from "../../services/geometry/Geometry.service";
import { STYLE_TYPES } from "../../services/styles/CommonStyles.service";

const MEASURE_LINE_IA = "measure_line";
const MEASURE_POLYGON_IA = "measure_polygon";
const MEASUREMENT_OVERLAY_ELEMENT_ID = "measurement-result";
let newMeasurementId = 0;

export const measureStart = () => (dispatch) => {
  dispatch(deactivateContextMenuEL());
  dispatch(
    setLayer(
      MEASURE_POLYGON_IA,
      GEOM_TYPES.POLYGON,
      STYLE_TYPES.MEASUREMENT,
      STYLE_TYPES.MEASUREMENT,
    ),
  );
  dispatch(
    setLayer(
      MEASURE_LINE_IA,
      GEOM_TYPES.LINESTRING,
      STYLE_TYPES.MEASUREMENT,
      STYLE_TYPES.MEASUREMENT,
    ),
  );

  dispatch(setMeasureDrawIA());

  dispatch(mapSetCursor(""));

  dispatch(
    setModifyIA(
      (geom, evt) => {
        dispatch(onMeasurementModifyChange(geom, evt));
      },
      () => {
        dispatch(removeDrawIA(MEASURE_LINE_IA));
      },
      () => {
        dispatch(setMeasureDrawIA());
      },
      MEASURE_LINE_IA,
      GEOM_TYPES.LINESTRING,
      STYLE_TYPES.MEASUREMENT,
    ),
  );

  dispatch(
    setModifyIA(
      (geom, evt) => {
        dispatch(onMeasurementModifyChange(geom, evt, true));
      },
      () => {
        dispatch(removeDrawIA(MEASURE_LINE_IA));
      },
      () => {
        dispatch(setMeasureDrawIA());
      },
      MEASURE_POLYGON_IA,
      GEOM_TYPES.POLYGON,
      STYLE_TYPES.MEASUREMENT,
    ),
  );
};

export const measureEnd = () => (dispatch) => {
  dispatch(activateContextMenuEL());

  dispatch(removeDrawIA(MEASURE_LINE_IA));
  dispatch(removeSnapIA(MEASURE_LINE_IA));

  [MEASURE_LINE_IA, MEASURE_POLYGON_IA].forEach((iaId) => {
    dispatch(removeModifyIA(iaId));
    dispatch(removeLayer(iaId));
  });

  dispatch(removeOverlays());
  dispatch(clearMeasurementsItems());
};

/** measurement draw/modify interactions ************************************************************ */

const setMeasureDrawIA = () => (dispatch) => {
  dispatch(
    setDrawIA(
      (evt) => dispatch(onFeatureDrawStart(evt)),
      (geometry, evt) => dispatch(onFeatureDrawEnd(geometry, evt)),
      MEASURE_LINE_IA,
      GEOM_TYPES.LINESTRING,
      STYLE_TYPES.MEASUREMENT,
      STYLE_TYPES.MEASUREMENT,
    ),
  );
};

const onFeatureDrawStart = (evt) => (dispatch) => {
  const { feature } = evt;
  newMeasurementId += 1;

  const snapFeature = new Feature({
    geometry: new Point(feature.getGeometry().getFirstCoordinate()),
  });
  dispatch(removeSnapIA(MEASURE_LINE_IA));
  dispatch(setSnapIA(MEASURE_LINE_IA, [snapFeature]));

  feature.getGeometry().on("change", (changeEvt) => {
    if (!feature.getId()) {
      debouncedOnFeatureDrawChange(changeEvt, dispatch);
    }
  });
};

const debouncedOnFeatureDrawChange = debounce((evt, dispatch) => {
  dispatch(onFeatureDrawChange(evt));
}, 10);

const onFeatureDrawChange = (evt) => (dispatch) => {
  const geometry = evt.target;
  const coordinates = geometry.getCoordinates();
  const firstCoordinate = geometry.getFirstCoordinate();
  const lastAddedCoordinate = coordinates[coordinates.length - 2];

  // check if user clicked to initial point
  // if yes, consider it to be polygon, finish measurement and save feature
  // otherwise continue with drawing a line
  if (
    coordinates.length > 3 &&
    firstCoordinate[0] === lastAddedCoordinate[0] &&
    firstCoordinate[1] === lastAddedCoordinate[1]
  ) {
    const polygonCoordinates = coordinates.slice();
    polygonCoordinates.pop();
    const newFeature = new Feature({
      geometry: new Polygon([polygonCoordinates]),
    });
    dispatch(addFeature(MEASURE_POLYGON_IA, newFeature));
    dispatch(finishMeasurement(newFeature, true));
  } else {
    const geometryLength = Geometry.getLength(geometry);
    if (geometryLength) {
      dispatch(
        addMeasurementOverlay(
          { geometry, length: geometryLength },
          MEASUREMENT_OVERLAY_ELEMENT_ID,
          `${MEASUREMENT_OVERLAY_ELEMENT_ID}-${newMeasurementId}`,
        ),
      );
    }
  }
};

const onFeatureDrawEnd = (_, evt) => (dispatch) => {
  dispatch(finishMeasurement(evt.feature));
};

const onMeasurementModifyChange = (_, evt, isPolygon) => (dispatch) => {
  dispatch(finishMeasurement(evt.feature, isPolygon));
};

const finishMeasurement =
  (feature, isPolygon = false) =>
  (dispatch) => {
    const featureGeometry = feature.getGeometry();
    const measuredLength = Geometry.getLength(featureGeometry);
    let measuredArea = null;
    if (isPolygon) {
      measuredArea = Geometry.getArea(featureGeometry) / 10000;
    }
    const featureId = feature.getId();
    let measurementId;

    if (featureId) {
      measurementId = featureId;
      dispatch(
        updateMeasurement({
          id: measurementId,
          length: measuredLength,
          area: measuredArea,
        }),
      );
    } else {
      measurementId = newMeasurementId;
      feature.setId(measurementId);
      dispatch(
        addNewMeasurement({
          id: measurementId,
          length: measuredLength,
          area: measuredArea,
        }),
      );
    }

    dispatch(
      addMeasurementOverlay(
        {
          geometry: featureGeometry,
          length: measuredLength,
          area: measuredArea,
        },
        MEASUREMENT_OVERLAY_ELEMENT_ID,
        `${MEASUREMENT_OVERLAY_ELEMENT_ID}-${measurementId}`,
      ),
    );
  };

export const removeMeasurement = (measurement) => (dispatch) => {
  dispatch(
    removeFeature(
      measurement.area ? MEASURE_POLYGON_IA : MEASURE_LINE_IA,
      measurement.id,
    ),
  );
  dispatch(
    removeOverlay(`${MEASUREMENT_OVERLAY_ELEMENT_ID}-${measurement.id}`),
  );
  dispatch(removeMeasurementItem(measurement.id));
};

export const clearMeasurements = () => (dispatch) => {
  dispatch(removeOverlays());
  dispatch(clearLayer(MEASURE_LINE_IA));
  dispatch(clearLayer(MEASURE_POLYGON_IA));
  dispatch(clearMeasurementsItems());
};

/** ************************************************************ */

export const addNewMeasurement = (item) => ({
  type: types.MEASUREMENT_ADD_NEW,
  measurement: item,
});

export const updateMeasurement = (item) => ({
  type: types.MEASUREMENT_UPDATE,
  measurement: item,
});

export const clearMeasurementsItems = () => ({
  type: types.MEASUREMENT_CLEAR_ALL,
});

export const removeMeasurementItem = (itemId) => ({
  type: types.MEASUREMENT_REMOVE,
  measurementId: itemId,
});
