import React from "react";

import { makeStyles } from "@mui/styles";
import { Link, useParams } from "react-router-dom";

import CfFormattedNumber from "../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import SelectionItem from "../../../../shared/components/common/SelectionItem/SelectionItem";
import SelectionItemHeading from "../../../../shared/components/common/SelectionItemHeading/SelectionItemHeading";

import { ParcelTo } from "../../../../shared/api/agroevidence/agroevidence.types";

const useStyles = makeStyles({
  parcels: {
    marginTop: 25,
    marginBottom: 35,
  },
  name: {
    display: "flex",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
  },
  heading: {
    margin: "0 15px 0 15px",
  },
});

type Props = {
  items: ParcelTo[];
  onItemRemove: (val: number) => void;
};

const ReportsParcelsList = ({ items = [], onItemRemove }: Props) => {
  const classes = useStyles();
  const { farmId } = useParams<{ farmId: string }>();

  const renderParcelItem = (field: ParcelTo, index: number) => (
    <SelectionItem
      editing={true}
      key={field.id}
      onRemoveItem={() => {
        onItemRemove(index);
      }}
    >
      <span className={classes.row}>
        <span className={classes.name}>
          {field.blockNumber}
          <Link to={`/farm/${farmId}/parcels/${field.id}/overview`}>
            <SelectionItemHeading customClasses={{ item: classes.heading }}>
              <div>{field.localName}</div>
            </SelectionItemHeading>
          </Link>
        </span>
        <span>
          <CfFormattedNumber value={field.area} /> ha
        </span>
      </span>
    </SelectionItem>
  );

  return (
    <div className={classes.parcels} id="reports-parcel-list">
      {items.map((field, index) => renderParcelItem(field, index))}
    </div>
  );
};

export default ReportsParcelsList;
