import React from "react";

import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { getMainMapTelematicsDashboardSelectedMachine } from "../../../../shared/api/telematics/mainMapTelematics/dashboard/machinesDashboard.selectors";
import * as mainMapTelSelectors from "../../selectors/mainMapTelematics.selectors";

import { setDashboardMachineSelected } from "../../../../shared/api/telematics/mainMapTelematics/dashboard/machinesDashboard.actions";

import { PositionDashboard } from "../../../../shared/api/telematics/telematics.types";

const useMapSelection = (machines: PositionDashboard[]) => {
  const dispatch = useDispatch();

  const mapSelectedMachineGpsUnit = useSelector(
    mainMapTelSelectors.getMainMapTelematicsSelectedMachineGpsUnit,
  );
  const dashboardSelectedMachine = useSelector(
    getMainMapTelematicsDashboardSelectedMachine,
  );

  React.useEffect(() => {
    if (
      !isEmpty(mapSelectedMachineGpsUnit) &&
      mapSelectedMachineGpsUnit !== dashboardSelectedMachine?.machineGpsUnit
    ) {
      const rowToSelect = machines.find(
        (m) => m.machineGpsUnit === mapSelectedMachineGpsUnit,
      );
      dispatch(setDashboardMachineSelected(rowToSelect));
    }
  }, [
    dashboardSelectedMachine?.machineGpsUnit,
    dispatch,
    machines,
    mapSelectedMachineGpsUnit,
  ]);
};

export { useMapSelection };
