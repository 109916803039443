import React, { Fragment } from "react";

import { Theme } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import {
  getDevice,
  getIsFetchingDevice,
} from "../../../shared/api/irrigation/devices/devices.selectors";

import { NAMESPACE } from "../../reducer/devicesList.reducer";

import CfTableHead from "../../../common/components/CfTableHead/CfTableHead";
import { useCfTableHead } from "../../../common/components/CfTableHead/useCfTableHead";
import CfFormattedNumber from "../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import CfTableBodyEmpty from "../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableBodyLoader from "../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader";
import CfTableCell from "../../../shared/components/tables/CfTableCell/CfTableCell";
import CfTableWrapper from "../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import { AnyTodo } from "../../../types";

import {
  Device,
  ProgramsType,
  Valve,
} from "../../../shared/api/irrigation/devices/devices.types";

const ValvesPlan = () => {
  const { formatDate } = useIntl();
  const classes = useStyles();

  const device: Device = useSelector(getDevice);
  const isFetchingDevice: boolean = useSelector(getIsFetchingDevice);

  const { onSelect, onSort } = useCfTableHead(NAMESPACE);

  const getTableColumns = (valveStatus: Valve[]) => {
    const columns = {
      dayOfWeek: {
        label: <FormattedMessage id="common.day" />,
        style: {
          paddingLeft: "18px",
        },
      },
    };

    if (!valveStatus) {
      return columns;
    }

    valveStatus.forEach((valve) => {
      // @ts-ignore
      columns[`valve${valve.valveNumber}`] = {
        label: (
          <span>
            <FormattedMessage id="IrrigationList.valve" />{" "}
            {valve.valveNumber + 1}
          </span>
        ),
      };
    });

    return columns;
  };

  const renderProgram = (valveIndex: number, program: ProgramsType) => {
    const valveTime: AnyTodo = program.times.find(
      (time) => time.valve === valveIndex,
    );

    /* eslint-disable-next-line react/style-prop-object */
    return (
      <div className={classes.programTime} key={program.programTime}>
        {valveTime?.duration ? (
          <Fragment>
            {valveTime.time}{" "}
            <span className={classes.programDuration}>
              <span>
                <CfFormattedNumber
                  decimalDigits={0}
                  // eslint-disable-next-line react/style-prop-object
                  style="unit"
                  unit="minute"
                  unitDisplay="short"
                  value={valveTime.duration / 60}
                />{" "}
              </span>
              {valveTime.duration % 60 !== 0 && (
                <span>
                  {" "}
                  <CfFormattedNumber
                    decimalDigits={0}
                    // eslint-disable-next-line react/style-prop-object
                    style="unit"
                    unit="second"
                    unitDisplay="narrow"
                    value={valveTime.duration % 60}
                  />
                </span>
              )}
            </span>
          </Fragment>
        ) : (
          "-"
        )}
      </div>
    );
  };

  const columns = getTableColumns(device.valves);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <FormattedMessage id="IrrigationDetail.irrigationPlan" />
      </div>
      <CfTableWrapper>
        <CfTableHead columns={columns} onSelect={onSelect} onSort={onSort} />
        {isFetchingDevice && (
          <CfTableBodyLoader columns={columns} heightDiff="44px" top="44px" />
        )}
        {device.valves?.length ? (
          <TableBody>
            {device.schedules?.map((schedule) => {
              const modifiedDate = moment()
                .day(schedule.index + 1)
                .toISOString();
              const dayOfWeek = formatDate(modifiedDate, {
                weekday: "short",
              }).toUpperCase();
              return (
                <TableRow key={schedule.index}>
                  <CfTableCell name="dayOfWeek">
                    <span className={classes.dayOfWeek}>{dayOfWeek}</span>
                  </CfTableCell>
                  {device.valves.map((valve) => (
                    <CfTableCell key={valve.valveNumber} name="valvePrograms">
                      {schedule.programs.length > 0 ? (
                        <Fragment>
                          {schedule.programs.map((program) =>
                            renderProgram(valve.valveNumber, program),
                          )}
                        </Fragment>
                      ) : (
                        "-"
                      )}
                    </CfTableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        ) : (
          <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
        )}
      </CfTableWrapper>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: 16,
  },
  header: {
    padding: "8px 0px",
  },
  dayOfWeek: {
    paddingLeft: 12,
  },
  programTime: {
    marginTop: 2,
    marginBottom: 2,
  },
  programDuration: {
    color: theme.palette.grey[500],
  },
}));

export default ValvesPlan;
