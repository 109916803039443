import { queryOptions } from "@tanstack/react-query";

import { GetAll6Params, getAll6 } from "../../../../generated/api/telematics";
import { staleTime } from "../../../../shared/query-client";

const driversQuery = ({
  dateFrom,
  dateTo,
  farmIds,
}: Pick<GetAll6Params, "dateFrom" | "dateTo" | "farmIds">) =>
  queryOptions({
    queryKey: ["telematics", "drivers", dateFrom, dateTo, farmIds],
    queryFn: () =>
      getAll6({
        farmIds,
        dateFrom,
        dateTo,
        "sort-col": "NAME",
        "sort-dir": "ASC",
      }),
    staleTime: staleTime.Infinity,
  });

export { driversQuery };
