import React from "react";

import { makeStyles } from "@mui/styles";

import CfTextFilter from "../../../../common/components/CfTextFilter/CfTextFilter";
import { useCfTextFilter } from "../../../../common/components/CfTextFilter/useCfTextFilter";

type Props = {
  namespace: string;
  textFilter?: string;
};

const MachinesSearchFilter = ({ namespace, textFilter }: Props) => {
  const classes = useStyles();
  const { onChange } = useCfTextFilter(namespace);

  return (
    <div className={classes.wrapper}>
      <CfTextFilter
        initialValue={textFilter}
        name="machines-text-filter"
        onChange={onChange}
        translId="MainMapTelematics.machinesDashboard.filter.placeholder"
      />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    margin: 0,
  },
}));

export { MachinesSearchFilter };
