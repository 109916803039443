import React, { useEffect } from "react";

import EditIcon from "@mui/icons-material/Edit";
import { Theme } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  getNotificationsAdminPage,
  getNotificationsAdminOrder,
  getNotificationsAdminOrderBy,
  getNotificationsAdminRowsPerPage,
  getNotificationsAdminTextFilter,
  getAdminFarms as getAdminFarmsSelector,
  getAdminFarmsTotalCount,
  isFetchingAdminFarms,
} from "../../selectors/notifications.selectors";

import { fetchAdminFarms } from "../../actions/notifications.actions";

import { NAMESPACE as namespace } from "../../reducer/notifications.reducer";

import CfTableFooter from "../../../../../common/components/CfTableFooter/CfTableFooter";
import { useCfTableFooter } from "../../../../../common/components/CfTableFooter/useCfTableFooter";
import CfTableHead from "../../../../../common/components/CfTableHead/CfTableHead";
import { useCfTableHead } from "../../../../../common/components/CfTableHead/useCfTableHead";
import CfTableBodyEmpty from "../../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableBodyLoader from "../../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader";
import CfTableCell from "../../../../../shared/components/tables/CfTableCell/CfTableCell";
import CfTableRowToolButton from "../../../../../shared/components/tables/CfTableRowToolButton/CfTableRowToolButton";
import CfTableRowTools from "../../../../../shared/components/tables/CfTableRowTools/CfTableRowTools";
import CfTableWrapper from "../../../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import { getColDesc } from "../../../../../shared/misc/helper";
import Countries from "../../../../../shared/services/Countries.service";
import { COLOR_GREY } from "../../../../../theme";
import { AnyTodo } from "../../../../../types";
import FarmNotificationsStatus from "../../components/FarmNotificationsStatus/FarmNotificationsStatus";

const columns = {
  farmName: getColDesc(
    true,
    <div style={{ paddingLeft: 15 }}>
      <div>
        <FormattedMessage id="common.farm" />
      </div>
      <div style={{ color: COLOR_GREY[400] }}>
        <FormattedMessage id="common.id" />
      </div>
    </div>,
  ),
  adminEmail: getColDesc(
    false,
    <FormattedMessage id="NotificationsAdminTable.adminEmail" />,
  ),
  status: getColDesc(true, <FormattedMessage id="common.state" />),
  "usage.used": getColDesc(
    true,
    <FormattedMessage id="NotificationsAdminTable.notificationsCount" />,
  ),
};
const columnsLength = Object.keys(columns).length + 1;

type Props = {
  openFarmDialog: (farm: unknown) => void;
};

const NotificationsAdminTable = ({ openFarmDialog }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const page = useSelector(getNotificationsAdminPage);
  const order = useSelector(getNotificationsAdminOrder);
  const orderBy = useSelector(getNotificationsAdminOrderBy);
  const rowsPerPage = useSelector(getNotificationsAdminRowsPerPage);
  const textFilter = useSelector(getNotificationsAdminTextFilter);
  const count = useSelector(getAdminFarmsTotalCount);
  const farmsSettings: AnyTodo[] = useSelector(getAdminFarmsSelector) ?? [];
  const isFetching = useSelector(isFetchingAdminFarms);

  const { onPageChange, onRowsPerPageChange } = useCfTableFooter(namespace);
  const { onSelect, onSort } = useCfTableHead(namespace);

  useEffect(() => {
    dispatch(fetchAdminFarms());
  }, [page, rowsPerPage, order, orderBy, textFilter, dispatch]);

  const renderFarmEmailUsage = (farmUsage: AnyTodo) => {
    const emailUsage = farmUsage?.find(
      (usage: AnyTodo) => usage.type === "EMAIL",
    );
    return emailUsage ? (
      <span>
        {emailUsage.used}/{emailUsage.limit}
      </span>
    ) : (
      "-"
    );
  };

  return (
    <CfTableWrapper>
      <CfTableHead
        columns={columns}
        onSelect={onSelect}
        onSort={onSort}
        order={order}
        orderBy={orderBy}
      />
      {isFetching && <CfTableBodyLoader columns={columns} />}
      {farmsSettings.length > 0 ? (
        <TableBody>
          {farmsSettings.map((farm) => (
            <TableRow className={classes.tableRow} key={farm.farmId}>
              <CfTableCell name="farmName">
                <div className={classes.farmCell}>
                  <span className={classes.countryFlag}>
                    {Countries.countryToFlag(farm.farmCountryCode)}
                  </span>
                  <span>
                    <div className={classes.farmName}>{farm.farmName}</div>
                    <div className={classes.farmId}>{farm.farmId}</div>
                  </span>
                </div>
              </CfTableCell>
              <CfTableCell name="adminEmail">
                <span>{farm.adminEmail}</span>
              </CfTableCell>
              <CfTableCell name="status">
                <FarmNotificationsStatus status={farm.status} />
              </CfTableCell>
              <CfTableCell name="usage.used">
                {renderFarmEmailUsage(farm.usage)}
              </CfTableCell>
              <CfTableRowTools toolsClass={classes.tableRowTools}>
                <CfTableRowToolButton
                  icon={<EditIcon />}
                  onClick={() => openFarmDialog(farm)}
                  tooltipMessage={<FormattedMessage id="common.edit2" />}
                />
              </CfTableRowTools>
            </TableRow>
          ))}
        </TableBody>
      ) : (
        <CfTableBodyEmpty colLength={columnsLength} />
      )}
      <CfTableFooter
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </CfTableWrapper>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  farmCell: {
    paddingLeft: 15,
    display: "flex",
    alignItems: "center",
  },
  countryFlag: {
    fontSize: 16,
    marginRight: 8,
  },
  tableRow: {
    transform: "scale(1)",
    "&:hover $tableRowTools": {
      display: "inline-block",
      padding: "0px 10px 0px 0px",
    },
  },
  tableRowTools: {
    display: "none",
  },
  farmName: {
    fontWeight: 500,
  },
  farmId: {
    color: theme.palette.grey[500],
  },
}));

export default NotificationsAdminTable;
