import React from "react";

import ClearIcon from "@mui/icons-material/Clear";
import { Breakpoint, IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";

interface Props {
  acceptButtonVariant?: "text" | "outlined" | "contained" | undefined;
  acceptText?: React.ReactNode | string;
  cancelText?: React.ReactNode | string;
  children: React.ReactNode;
  dataTest?: string;
  dialogHeight?: string;
  disabled?: boolean;
  externalStyle?: Record<string, string>;
  maxWidth?: Breakpoint;
  onAccept?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
  onCloseIcon?: () => void;
  onEnter?: () => void;
  opened: boolean;
  processing?: boolean;
  title?: React.ReactNode | string;
}

const CfDialog = ({
  acceptButtonVariant,
  acceptText = "OK",
  cancelText = "Cancel",
  children,
  dataTest = "dialog",
  dialogHeight = "auto",
  disabled = false,
  externalStyle = {},
  maxWidth = "sm",
  onAccept,
  onCancel,
  onClose,
  onCloseIcon,
  onEnter,
  opened = false,
  processing = false,
  title = "",
}: Props) => {
  const classes = useStyles();

  return (
    <Dialog
      data-test={dataTest}
      fullWidth={true}
      maxWidth={maxWidth}
      onClose={onClose}
      open={opened}
      classes={{
        paper: classnames(
          classes.dialogContainer,
          externalStyle.dialogContainer,
        ),
      }}
      TransitionProps={{
        onEnter,
      }}
    >
      {!isEmpty(title) && (
        <DialogTitle
          className={classnames(classes.title, externalStyle.title)}
          data-test="dialog-title"
        >
          {title}
        </DialogTitle>
      )}
      <DialogContent
        data-test="dialog-content"
        style={{ height: dialogHeight }}
        classes={{
          root: classnames(classes.dialogContent, externalStyle.dialogContent),
        }}
      >
        {React.Children.only(children)}
      </DialogContent>
      <DialogActions
        data-test="dialog-actions"
        className={classnames(
          classes.dialogActions,
          externalStyle.dialogActions,
        )}
      >
        {onCancel && (
          <Button
            className={classnames(externalStyle.cancelButton)}
            data-test="cancel-button"
            id="dismiss"
            onClick={onCancel}
          >
            {cancelText}
          </Button>
        )}
        {onAccept && (
          <Button
            color="primary"
            data-test="accept-button"
            disabled={disabled}
            id="accept"
            onClick={onAccept}
            variant={acceptButtonVariant}
          >
            {acceptText}
          </Button>
        )}
        {onCloseIcon && (
          <IconButton
            className={classnames(classes.closeIcon, externalStyle.closeIcon)}
            disabled={processing}
            onClick={onCloseIcon}
            size="small"
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CfDialog;

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 20,
    fontWeight: 500,
    padding: "34px 50px 20px",
  },
  dialogContent: {
    padding: "0px 50px 24px 50px",
  },
  dialogContainer: {
    position: "relative",
    paddingRight: "10px",
  },
  dialogActions: {},
  closeIcon: {
    position: "absolute",
    top: 10,
    right: 10,
  },
}));
