import React, { FC, ReactNode } from "react";

import { useSuspenseQuery } from "@tanstack/react-query";

import { useFarmIds } from "../../../../shared/api/client.utils";
import CfAutocomplete from "../../../../shared/components/common/CfAutocomplete/CfAutocomplete";

import { productionOperationsQuery } from "./ProductionOperationSelector.api";

import { ProductionOperationTo } from "../../../../shared/api/telematics/telematics.types";

interface Props {
  defaultValues?: ProductionOperationTo[];
  externalClasses?: Record<string, string>;
  label: ReactNode;
  onChange(items: ProductionOperationTo[]): void;
}

const ProductionOperationSelector: FC<Props> = ({
  defaultValues = [],
  externalClasses,
  label,
  onChange,
}) => {
  const operations = useSuspenseQuery(
    productionOperationsQuery({ ...useFarmIds() }),
  );

  return (
    <CfAutocomplete
      classes={externalClasses}
      defaultValues={defaultValues}
      id="production-operation-selector"
      isMultiple={true}
      label={label}
      onChange={onChange}
      suggestions={operations.data.data}
      testId="production-operation-filter"
    />
  );
};

export default ProductionOperationSelector;
