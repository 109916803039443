import React, { ReactNode, ElementType } from "react";

import Check from "@mui/icons-material/Check";
import Button, { ButtonProps } from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

type Props = {
  /** Title of panel. */
  title?: string | ReactNode;
  /** Primary text description or explanation. */
  customContent?: ReactNode;
  content?: string | ReactNode;
  /** Secondary additional information. */
  secondaryContent?: string | ReactNode;
  /** URL address to link with button. Can be used instead of `onLinkClick` */
  linkHref?: string;
  /** Action that should perform after link click. Can be used instead of `linkHref` */
  onLinkClick?: () => void;
  /** If `true`, button will contain information about successful request.
   * Use if button performs some kind of action. */
  activated?: boolean;
  /** Label displaying in the button. */
  linkText?: string | ReactNode;
  /** If `true`, button will contain spinner. */
  isFetching?: boolean;
  /** Error text information displayed at the end, if something unusual happens. */
  errorText?: string | ReactNode;
  /** Icon to display at the beginning. */
  icon?: ElementType | ReactNode;
  /** Test id. */
  testId?: string;
  /** If `true`, elements will be aligned in a row instead of a column. */
  horizontal?: boolean;
  /** If `true`, panel will take 100% width of its container. */
  fullWidth?: boolean;
  /** If `true`, elements in panel will have smaller margins. */
  dense?: boolean;
  /** If `true`, paper will have grey background. */
  grey?: boolean;
  /** Elevation of paper. If greater that 0, panel will cast shadow to its background. */
  elevation?: number;
  buttonColor?: ButtonProps["color"];
  titleWithIcon?: boolean;
};

/**
 * Panel for displaying information about state of services or other useful information.
 * It typically consists of icon, header, content and link button.
 * It is responsive and possible to customize.
 * @version 1.0.0
 */
const CfStatusPanel = ({
  activated = false,
  buttonColor = "primary",
  content = null,
  customContent = null,
  dense = false,
  elevation = 2,
  errorText = null,
  fullWidth = false,
  grey = false,
  horizontal = false,
  icon = null,
  isFetching = false,
  linkHref = undefined,
  linkText = "",
  onLinkClick = undefined,
  secondaryContent = null,
  testId = "status",
  title = null,
  titleWithIcon = false,
}: Props) => {
  const classes = useStyles();

  const renderTitle = () => (
    <>
      {title && (
        <Typography
          className={classes.title}
          variant="h5"
          style={{
            marginBottom:
              !content && !secondaryContent && !customContent ? 10 : 0,
            alignItems: titleWithIcon ? "left" : "center",
          }}
        >
          {title}
        </Typography>
      )}
    </>
  );

  const renderContent = () => (
    <>
      {customContent && (
        <div className={classes.customContent}>{customContent}</div>
      )}
      {content && (
        <Typography
          className={dense ? classes.denseContent : classes.content}
          variant="body2"
        >
          {content}
        </Typography>
      )}
      {secondaryContent && (
        <Typography
          variant="body2"
          className={`${classes.secondaryContent} ${
            dense ? classes.denseContent : classes.content
          }`}
        >
          {secondaryContent}
        </Typography>
      )}
    </>
  );

  const Icon = icon as ElementType;

  return (
    <div className={classes.wrapper} data-test={testId}>
      <Paper
        className={`${classes.paper} ${grey ? classes.greyPaper : ""}`}
        elevation={elevation}
        style={{
          maxWidth: fullWidth ? "100%" : "600px",
          padding: dense ? "10px" : "20px 30px",
        }}
      >
        <div
          className={classes.contentWrapper}
          style={{
            flexDirection: horizontal ? "row" : "column",
            margin: grey && !dense ? "20px 0px" : 0,
            alignItems: titleWithIcon ? "left" : "center",
          }}
        >
          {titleWithIcon ? (
            <>
              <div className={classes.titleWithIcon}>
                <Icon className={classes.icon} style={{ marginLeft: 0 }} />
                {renderTitle()}
              </div>
              {renderContent()}
            </>
          ) : (
            <>
              {icon && <Icon className={classes.icon} />}
              <span className={horizontal ? classes.textWrapperDense : ""}>
                {renderTitle()}
                {renderContent()}
              </span>
            </>
          )}
          {linkText && (
            <span
              className={classes.buttonWrapper}
              style={{
                justifyContent:
                  horizontal || titleWithIcon ? "flex-end" : "initial",
              }}
            >
              {linkHref && (
                <a
                  className={classes.buttonLink}
                  href={linkHref}
                  target="_blank"
                >
                  <Button color={buttonColor} variant="contained">
                    {linkText}
                  </Button>
                </a>
              )}
              {onLinkClick && (
                <Button
                  className={classes.button}
                  color={buttonColor}
                  disabled={isFetching || activated}
                  onClick={onLinkClick}
                  variant="contained"
                >
                  {activated ? <FormattedMessage id="button.sent" /> : linkText}
                  {isFetching && (
                    <CircularProgress className={classes.progress} size={21} />
                  )}
                  {activated && <Check className={classes.sent} />}
                </Button>
              )}
            </span>
          )}
        </div>
        {errorText && <div className={classes.error}>{errorText}</div>}
      </Paper>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: 10,
    marginBottom: 20,
  },
  paper: {
    width: "100%",
  },
  greyPaper: {
    backgroundColor: theme.palette.grey[100],
  },
  icon: {
    margin: 10,
    color: theme.palette.grey[400],
    width: 32,
    height: 32,
  },
  buttonLink: {
    width: "max-content",
    "&:hover": {
      textDecoration: "none",
    },
    "&:focus": {
      textDecoration: "none",
      outline: "none",
    },
  },
  button: {
    width: "max-content",
  },
  error: {
    color: theme.palette.error.main,
    fontSize: 14,
    marginTop: 5,
  },
  progress: {
    marginLeft: 10,
  },
  sent: {
    marginLeft: 5,
    color: theme.palette.primary.main,
    marginBottom: 3,
  },
  contentWrapper: {
    display: "flex",
  },
  textWrapperDense: {
    textAlign: "left",
    margin: "0px 12px",
  },
  buttonWrapper: {
    display: "flex",
    flexGrow: 1,
    padding: 10,
  },
  secondaryContent: {
    color: theme.palette.grey[500],
  },
  denseContent: {
    margin: "5px 0px",
  },
  content: {
    margin: "10px 0px",
  },
  customContent: {
    padding: "10px 0px",
    fontSize: 14,
  },
  title: {
    fontWeight: 500,
  },
  titleWithIcon: {
    display: "flex",
    alignItems: "center",
  },
}));

export default CfStatusPanel;
