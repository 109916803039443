import { getType } from "@turf/invariant";
import GeoJSON from "ol/format/GeoJSON";
import { getArea, getLength } from "ol/sphere";

export const GEOM_TYPES = {
  POINT: "POINT",
  LINESTRING: "LINESTRING",
  MULTILINESTRING: "MULTILINESTRING",
  POLYGON: "POLYGON",
  MULTIPOLYGON: "MULTIPOLYGON",
};

export default class Geometry {
  static getType(geometry) {
    return getType(geometry).toUpperCase();
  }

  static getOLType(geometry) {
    return geometry.getType().toUpperCase();
  }

  static readGeometry(geometry, transformOptions) {
    return new GeoJSON().readGeometry(geometry, transformOptions);
  }

  static featureToGeometry(feature, transformOptions) {
    return new GeoJSON().writeGeometryObject(
      feature.getGeometry(),
      transformOptions,
    );
  }

  static getLength(geometry) {
    return getLength(geometry);
  }

  static getArea(geometry) {
    return getArea(geometry);
  }
}
