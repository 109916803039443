import React, { createContext, Suspense, FC, useEffect } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getAnyTelematicsCataloguesError } from "../../selectors/telematicsCatalogues.selectors";

import { LANGUAGE_ID, LANG_KEYS } from "../../../shared/lang/lang.constants";

import { getTelematicsStatusApi } from "../../../shared/api/telematics/sectionStatus/sectionStatus.api";
import {
  getCatalogueType,
  isTelematicSectionAllowed,
} from "../../../shared/api/telematics/sectionStatus/sectionStatus.selector";
import CfErrorPage from "../../../shared/components/common/CfErrorPage/CfErrorPage";
import CfLoader from "../../../shared/components/common/CfLoader/CfLoader";
import BaseContainer from "../../../shared/containers/BaseContainer/BaseContainer";
import { Thunk, RsaaApiError } from "../../../types";
import translationsCZ from "../../lang/locale-cs-CZ.json";
import translationsEL from "../../lang/locale-el-GR.json";
import translationsEN from "../../lang/locale-en-US.json";
import translationsES from "../../lang/locale-es-ES.json";
import translationsHU from "../../lang/locale-hu-HU.json";
import translationsPL from "../../lang/locale-pl-PL.json";
import translationsRO from "../../lang/locale-ro-RO.json";
import translationsRU from "../../lang/locale-ru-UA.json";
import translationsSR from "../../lang/locale-sr-LATN-RS.json";
import Catalogues from "../Catalogues/Catalogues";

import { CataloguesState } from "../../../reducers/catalogues.reducer.types";

const translations = {
  [LANG_KEYS.CZ]: translationsCZ,
  [LANG_KEYS.EN]: translationsEN,
  [LANG_KEYS.SR]: translationsSR,
  [LANG_KEYS.ES]: translationsES,
  [LANG_KEYS.RU]: translationsRU,
  [LANG_KEYS.EL]: translationsEL,
  [LANG_KEYS.RO]: translationsRO,
  [LANG_KEYS.HU]: translationsHU,
  [LANG_KEYS.PL]: translationsPL,
};

interface Context {
  catalogueType?: string;
  farmId: string;
  isTelematicSectionAllowed?: boolean;
  langId: string;
}

export const CataloguesContext = createContext<Context>({
  farmId: "",
  langId: "",
});

interface Props {
  [restProps: string]: unknown;
  catalogueType?: string;
  error?: RsaaApiError;
  farmId: string;
  getTelematicsStatus: () => void;
  isTelematicSectionAllowed?: boolean;
  langId: LANGUAGE_ID;
}

const CataloguesWrapper: FC<Props> = ({
  catalogueType,
  error,
  farmId,
  getTelematicsStatus,
  isTelematicSectionAllowed,
  langId,
  ...restProps
}) => {
  useEffect(() => {
    getTelematicsStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BaseContainer langId={langId} translations={translations}>
      <Suspense fallback={<CfLoader />}>
        <CataloguesContext.Provider
          value={{
            langId,
            farmId,
            catalogueType,
            isTelematicSectionAllowed,
          }}
        >
          <CfErrorPage error={error}>
            <Catalogues {...restProps} />
          </CfErrorPage>
        </CataloguesContext.Provider>
      </Suspense>
    </BaseContainer>
  );
};

const mapStateToProps = (state: CataloguesState) => ({
  catalogueType: getCatalogueType(state),
  error: getAnyTelematicsCataloguesError(state),
  isTelematicSectionAllowed: isTelematicSectionAllowed(state),
});

const mapDispatchToProps = (dispatch: Thunk<CataloguesState>) =>
  bindActionCreators(
    {
      getTelematicsStatus: getTelematicsStatusApi,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CataloguesWrapper);
