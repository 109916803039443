import queryString from "query-string";
import { RSAA } from "redux-api-middleware";

import * as types from "./fertilizers.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../../api.constants";

import { getFertilizersParams } from "./fertilizers.types";
import { RsaaMethods, RsaaTypes } from "../../../api.types";
import {
  FertilizerCreateTo,
  FertilizerPatchTo,
} from "../../agroevidence.types";

export const getFertilizersApi = (params: getFertilizersParams) => ({
  [RSAA]: {
    endpoint: `catalogues/fertilizers?${queryString.stringify(params)}&`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_FERTILIZERS_REQUEST,
      types.GET_FERTILIZERS_SUCCESS,
      types.GET_FERTILIZERS_ERROR,
    ] as RsaaTypes,
  },
});

export const getFertilizersSuggestionsApi = (params: getFertilizersParams) => ({
  [RSAA]: {
    endpoint: `catalogues/fertilizers/suggestions?${queryString.stringify(
      params,
    )}&`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_FERTILIZERS_REQUEST,
      types.GET_FERTILIZERS_SUCCESS,
      types.GET_FERTILIZERS_ERROR,
    ] as RsaaTypes,
  },
});

export const resetFertilizersApi = () => ({
  type: types.RESET_FERTILIZERS,
});

export const getFertilizerApi = (fertilizerId: string) => ({
  [RSAA]: {
    endpoint: `catalogues/fertilizers/${fertilizerId}?`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_FERTILIZER_REQUEST,
      types.GET_FERTILIZER_SUCCESS,
      types.GET_FERTILIZER_ERROR,
    ] as RsaaTypes,
  },
});

export const resetFertilizerApi = () => ({
  type: types.RESET_FERTILIZER,
});

export const updateFertilizerApi = (
  fertilizerId: string,
  params: FertilizerCreateTo,
) => ({
  [RSAA]: {
    endpoint: `catalogues/fertilizers/${fertilizerId}?`,
    method: methods.PUT as RsaaMethods,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(params),
    types: [
      types.UPDATE_FERTILIZER_REQUEST,
      types.UPDATE_FERTILIZER_SUCCESS,
      types.UPDATE_FERTILIZER_ERROR,
    ] as RsaaTypes,
  },
});

export const patchFertilizerApi = (
  fertilizerId: string,
  params: FertilizerPatchTo,
) => ({
  [RSAA]: {
    endpoint: `catalogues/fertilizers/${fertilizerId}?`,
    method: methods.PATCH as RsaaMethods,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(params),
    types: [
      types.UPDATE_FERTILIZER_REQUEST,
      types.UPDATE_FERTILIZER_SUCCESS,
      types.UPDATE_FERTILIZER_ERROR,
    ] as RsaaTypes,
  },
});

export const createFertilizerApi = (params: FertilizerCreateTo) => ({
  [RSAA]: {
    endpoint: "catalogues/fertilizers?",
    method: methods.POST as RsaaMethods,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(params),
    types: [
      types.CREATE_FERTILIZER_REQUEST,
      types.CREATE_FERTILIZER_SUCCESS,
      types.CREATE_FERTILIZER_ERROR,
    ] as RsaaTypes,
  },
});

export const getFertilizerDoseApi = (
  fertId: string,
  unitId: string,
  dose: number,
) => ({
  [RSAA]: {
    endpoint: `catalogues/fertilizers/${fertId}/dose?unitId=${unitId}&dose=${dose}&`,
    method: methods.GET,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_FERTILIZERS_DOSE_REQUEST,
      types.GET_FERTILIZERS_DOSE_SUCCESS,
      types.GET_FERTILIZERS_DOSE_ERROR,
    ] as RsaaTypes,
  },
});

export const resetFertilizerDoseApi = () => ({
  type: types.RESET_FERTILIZERS_DOSE,
});
