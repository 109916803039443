import React from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Theme } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

interface Props {
  path?: string;
  translId?: string;
  onClick?: () => void;
}

const CfBackButton = ({
  onClick,
  path = "",
  translId = "common.back",
}: Props) => {
  const classes = useStyles();

  const linkProps = onClick ? { onClick } : { component: Link, to: path };
  return (
    <Grid
      alignContent="flex-start"
      container
      justifyContent="flex-start"
      spacing={0}
    >
      <Grid item xs={12}>
        <Button
          aria-label="go back button"
          color="primary"
          size="large"
          classes={{
            root: classes.root,
          }}
          {...linkProps}
        >
          <ArrowBackIcon className={classes.leftIcon} />
          <Hidden only={["xs", "lg", "xl"]}>
            <FormattedMessage id="common.back" />
          </Hidden>
          <Hidden only={["xs", "sm", "md"]}>
            <FormattedMessage id={translId} />
          </Hidden>
        </Button>
      </Grid>
    </Grid>
  );
};

export default CfBackButton;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textAlign: "left",
    lineHeight: 1.25,
    "&:focus": {
      textDecoration: "none",
      outline: "none",
    },
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  [theme.breakpoints.down("xs")]: {
    root: {
      margin: 0,
      minWidth: 48,
    },
    leftIcon: {
      marginRight: 0,
    },
  },
}));
