import React from "react";

import { Table, TableCell, TableRow } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import CfFormattedNumber from "../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import CfTableCellNumber from "../../../../../shared/components/tables/CfTableCellNumber/CfTableCellNumber";

import { TableBodyColumn } from "./types";

import { FarmStatistics } from "../../../../../shared/api/satellite/satellite.types";

type Props = {
  fetchedData?: FarmStatistics;
  columns: TableBodyColumn[];
  classes: Record<string, string>;
};

const Footer = ({
  classes: propClasses,
  columns,
  fetchedData: data,
}: Props) => {
  const classes = useStyles();

  return (
    <Table>
      <TableBody>
        <TableRow
          classes={{
            root: classes.wrapper,
          }}
        >
          <TableCell
            className={propClasses.cell}
            key={columns[0].key}
            style={columns[0].style}
          >
            <div className={classes.footerCell}>
              <div>
                <FormattedMessage id="SentinelAdminStatsPanel.table.footer.areaPF" />
              </div>
              <div>
                <FormattedMessage id="SentinelAdminStatsPanel.table.footer.farmArea" />
              </div>
            </div>
          </TableCell>
          <TableCell
            className={propClasses.cell}
            key={columns[1].key}
            style={columns[1].style}
          >
            <div className={classes.footerCell}>
              <div>
                <CfTableCellNumber>
                  {data?.pfParcelsCount ?? "-"}
                </CfTableCellNumber>
              </div>
              <div>
                <CfTableCellNumber>
                  {data?.allParcelsCount ?? "-"}
                </CfTableCellNumber>
              </div>
            </div>
          </TableCell>
          <TableCell
            className={propClasses.cell}
            key={columns[2].key}
            style={columns[2].style}
          >
            <div className={classes.footerCell}>
              <div>
                <CfTableCellNumber>
                  <CfFormattedNumber value={data?.pfParcelsArea || 0} />
                </CfTableCellNumber>
              </div>
              <div>
                <CfTableCellNumber>
                  <CfFormattedNumber value={data?.allParcelsArea || 0} />
                </CfTableCellNumber>
              </div>
            </div>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    borderTop: "1px solid #E0E0E0",
  },
  footerCell: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
    "& > div:nth-of-type(2)": {
      color: "#848484",
    },
  },
}));

export { Footer };
