import React, { useEffect, useState, useMemo } from "react";

import { Grid } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { FormattedMessage } from "react-intl";

import CfFormControl from "../../../../../shared/components/form/CfFormControl/CfFormControl";
import CfFormikTextField from "../../../../../shared/components/form/CfFormikTextField/CfFormikTextField";
import { useTypedIntl } from "../../../../../shared/hooks/useTypedIntl";
import * as validators from "../../../../../shared/misc/validators";
import Localization from "../../../../../shared/services/Localization.service";

import { ActionOtherFormValues } from "../../actionOther.types";

interface Props {
  actionTotalArea: number;
  isEditing: boolean;
  isSecondaryHarvestProduct?: boolean;
}

export const Revenues = ({
  actionTotalArea,
  isEditing,
  isSecondaryHarvestProduct = false,
}: Props) => {
  const [dirtyInput, setDirtyInput] = useState("");
  const {
    setFieldValue,
    values: { secondaryTotalGain, totalGain },
  } = useFormikContext<ActionOtherFormValues>();
  const { locale } = useTypedIntl();

  const updateFormValues = (value: string) => {
    // this is triggered on value change.
    // Computation is skipped for all values that cannot be properly converted to numbers.

    const isValidValue = Localization.checkValidStr(value, locale);

    if (!isValidValue) {
      setFieldValue(dirtyInput, value);
      return;
    }

    // convert localization-formatted value strings to numbers
    const normalizedVal = Localization.str2numNonFixed(value, locale);
    const normalizedTotalArea = Localization.str2numNonFixed(
      actionTotalArea,
      locale,
    );

    let primaryField = "";
    let secondaryField = "";
    let secondaryFieldValue;

    switch (dirtyInput) {
      case "totalGain":
        primaryField = "totalGain";
        secondaryField = "hectarGain";
        secondaryFieldValue =
          (normalizedVal as number) / (normalizedTotalArea as number);
        break;
      case "hectarGain":
        primaryField = "hectarGain";
        secondaryField = "totalGain";
        secondaryFieldValue =
          (normalizedVal as number) * (normalizedTotalArea as number);
        break;
      case "secondaryTotalGain":
        primaryField = "secondaryTotalGain";
        secondaryField = "secondaryHectarGain";
        secondaryFieldValue =
          (normalizedVal as number) / (normalizedTotalArea as number);
        break;
      case "secondaryHectarGain":
        primaryField = "secondaryHectarGain";
        secondaryField = "secondaryTotalGain";
        secondaryFieldValue =
          (normalizedVal as number) * (normalizedTotalArea as number);
        break;
      default:
        break;
    }

    setFieldValue(
      primaryField,
      Localization.num2strNonFixed(normalizedVal, locale),
    );
    setFieldValue(
      secondaryField,
      Localization.num2str(secondaryFieldValue, locale, 3),
    );
  };

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    updateFormValues(value);
  };

  const onFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDirtyInput(e.currentTarget.name);
  };

  useEffect(() => {
    if (actionTotalArea) {
      const normalizedTotalArea = Localization.str2numNonFixed(
        actionTotalArea,
        locale,
      );

      if (totalGain && !isSecondaryHarvestProduct) {
        const normalizedTotalGain = Localization.str2numNonFixed(
          totalGain,
          locale,
        );
        const hectarGain =
          (normalizedTotalGain as number) / (normalizedTotalArea as number);
        setFieldValue(
          "hectarGain",
          Localization.num2str(hectarGain, locale, 3),
        );
      }
      if (secondaryTotalGain && isSecondaryHarvestProduct) {
        const normalizedSecondaryTotalGain = Localization.str2numNonFixed(
          secondaryTotalGain,
          locale,
        );
        const secondaryHectarGain =
          (normalizedSecondaryTotalGain as number) /
          (normalizedTotalArea as number);
        setFieldValue(
          "secondaryHectarGain",
          Localization.num2str(secondaryHectarGain, locale, 3),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, actionTotalArea, locale]);

  const localizedNumberValidator = useMemo(
    () => validators.localizedNumber(locale),
    [locale],
  );

  return (
    <Grid
      alignItems="center"
      container
      justifyContent="space-between"
      spacing={1}
    >
      <Grid item xs={10}>
        <CfFormControl>
          <Field
            component={CfFormikTextField}
            disabled={!actionTotalArea || !isEditing}
            onChange={onChangeValue}
            onFocus={onFocus}
            shrinkLabel={!!actionTotalArea}
            validate={localizedNumberValidator}
            validateOnBlur
            label={
              isSecondaryHarvestProduct ? (
                <FormattedMessage id="action.secondaryTotalGain" />
              ) : (
                <FormattedMessage id="action.totalGain" />
              )
            }
            name={
              isSecondaryHarvestProduct ? "secondaryTotalGain" : "totalGain"
            }
          />
        </CfFormControl>
      </Grid>
      <Grid item xs={2}>
        <span>t</span>
      </Grid>
      <Grid item xs={10}>
        <CfFormControl>
          <Field
            component={CfFormikTextField}
            disabled={!actionTotalArea || !isEditing}
            onChange={onChangeValue}
            onFocus={onFocus}
            shrinkLabel={!!actionTotalArea}
            label={
              isSecondaryHarvestProduct ? (
                <FormattedMessage id="action.secondaryGainPerHectare" />
              ) : (
                <FormattedMessage id="action.gainPerHectare" />
              )
            }
            name={
              isSecondaryHarvestProduct ? "secondaryHectarGain" : "hectarGain"
            }
          />
        </CfFormControl>
      </Grid>
      <Grid item xs={2}>
        <span>t/ha</span>
      </Grid>
    </Grid>
  );
};
