import { AdminState } from "../../../../reducers/admin.reducer.types";

export const getAdminParcels = (state: AdminState) =>
  state.api.sentinelParcels.items;
export const isFetchingAdminParcels = (state: AdminState) =>
  state.api.sentinelParcels.isFetching;
export const getAdminParcelsTotalCount = (state: AdminState) =>
  state.api.sentinelParcels.totalCount;
export const itemIsUpdatingAdminParcels = (state: AdminState) =>
  state.api.sentinelParcels.itemIsUpdating;

export const getAdminStats = (state: AdminState) =>
  state.api.sentinelParcels.stats;
export const isFetchingAdminStatsIsFetching = (state: AdminState) =>
  state.api.sentinelParcels.statsIsFetching;
