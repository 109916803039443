import React, { Component } from "react";

import StarRoundedIcon from "@mui/icons-material/StarRounded";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { compose } from "react-recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getSeedsSuggestions } from "../../../shared/selectors/actions.selectors";

import {
  getSeedsSuggestionsApi,
  resetSeedsApi,
} from "../../../../../shared/api/agroevidence/catalogues/seeds/seeds.api";
import CfAutosuggest from "../../../../../shared/components/common/CfAutosuggest/CfAutosuggest";
import { COLOR_SECONDARY } from "../../../../../theme";

export const CHEM_DIALOD_TYPE = "CH";
export const FERT_DIALOD_TYPE = "FR";

class SeedSelector extends Component {
  onSuggestionSelected = (suggestion) => {
    const { seedAdditionalProps } = this.props;

    const expense = {
      material: suggestion,
      ...seedAdditionalProps,
    };

    this.props.onSuggestionSelected(expense);
  };

  getSuggestionValue = (sugg) => {
    const suggestionValue = [sugg.name, sugg.varietyName]
      .filter((item) => item)
      .join(" - ");

    if (sugg.isFavorite) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <StarRoundedIcon
            style={{ color: COLOR_SECONDARY.cleverfarm.main, marginRight: 5 }}
          />{" "}
          {suggestionValue}
        </div>
      );
    }

    return suggestionValue;
  };

  getSuggestions = (searchInput) => {
    this.props.getSeedsSuggestionsApi({ search: searchInput });
  };

  clearSuggestions = () => {
    this.props.resetSeedsApi();
  };

  render() {
    const {
      disabled,
      intl: { formatMessage },
      placeholder,
      suggestions,
    } = this.props;
    return (
      <CfAutosuggest
        clearSuggestions={this.clearSuggestions}
        disabled={disabled}
        getSuggestions={this.getSuggestions}
        getSuggestionValue={this.getSuggestionValue}
        isDebounced
        onSuggestionSelected={this.onSuggestionSelected}
        placeholder={formatMessage({ id: placeholder })}
        requiredLength={3}
        testData="seed-selector"
        suggestions={suggestions.map((sugg) => ({
          ...sugg,
          title: formatMessage({ id: sugg.title }),
        }))}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  suggestions: getSeedsSuggestions(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSeedsSuggestionsApi,
      resetSeedsApi,
    },
    dispatch,
  );

SeedSelector.propTypes = {
  intl: PropTypes.object.isRequired,
  suggestions: PropTypes.array.isRequired,
  placeholder: PropTypes.string.isRequired,
  onSuggestionSelected: PropTypes.func.isRequired,
  getSeedsSuggestionsApi: PropTypes.func.isRequired,
  resetSeedsApi: PropTypes.func.isRequired,
  seedAdditionalProps: PropTypes.object,
  disabled: PropTypes.bool,
};

SeedSelector.defaultProps = {
  seedAdditionalProps: {},
  disabled: false,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(SeedSelector);
