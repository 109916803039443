import { FeatureLike } from "ol/Feature";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Stroke, Style, Icon } from "ol/style";

import geometryEnd from "../../../assets/img/geometryEnd.svg";
import geometryStart from "../../../assets/img/geometryStart.svg";
import {
  TELEMATICS_GEOMETRY_COLOR,
  TELEMATICS_GEOMETRY_PER_DAY_COLOR,
  TELEMATICS_GEOMETRY_WIDTH,
} from "../../../shared/misc/map.helpers";

import { GeoJSON } from "../../telematics.types";

export enum GeometryType {
  CIRCLE = "Circle",
  GEOMETRY_COLLECTION = "GeometryCollection",
  LINEAR_RING = "LinearRing",
  LINE_STRING = "LineString",
  MULTI_LINE_STRING = "MultiLineString",
  MULTI_POINT = "MultiPoint",
  MULTI_POLYGON = "MultiPolygon",
  POINT = "Point",
  POLYGON = "Polygon",
}

export enum FeatureNames {
  END = "endPoint",
  GEOMETRY_PER_DAY = "geometryPerDay",
  START = "startPoint",
}
interface MultiLineGeometry extends GeoJSON {
  coordinates: number[][][];
  type: GeometryType.MULTI_LINE_STRING;
}

interface SingleLineGeometry extends GeoJSON {
  coordinates: number[][];
  type: GeometryType.LINE_STRING;
}

const styleIcon = (icon: string, anchor: number, rotation?: number) =>
  new Style({
    image: new Icon({
      src: icon,
      size: [16, 16],
      anchor: [anchor, anchor],
      anchorXUnits: "pixels",
      anchorYUnits: "pixels",
      rotation,
    }),
  });

export const styleGeometryLayers = (feature: FeatureLike) => {
  const type = feature.getGeometry()?.getType();
  const name = feature.get("name");
  if (type === GeometryType.POINT) {
    if (name === FeatureNames.START) {
      const startAzimuth = feature.get("startAzimuth");
      return styleIcon(geometryStart, 6, startAzimuth);
    } else if (name === FeatureNames.END) {
      return styleIcon(geometryEnd, 8);
    }
  }

  const isGeometryPerDay = name === FeatureNames.GEOMETRY_PER_DAY;
  return new Style({
    stroke: new Stroke({
      color: isGeometryPerDay
        ? TELEMATICS_GEOMETRY_PER_DAY_COLOR
        : TELEMATICS_GEOMETRY_COLOR,
      width: TELEMATICS_GEOMETRY_WIDTH,
    }),
  });
};

export const getStyledVectorLayer = () =>
  new VectorLayer({
    source: new VectorSource(),
    zIndex: 50,
    style: styleGeometryLayers,
  });

export const getBoundaryPoints = (
  geometry: SingleLineGeometry | MultiLineGeometry,
): number[][] => {
  let geomStartPoint;
  let geomEndPoint;
  if (geometry.type === GeometryType.MULTI_LINE_STRING) {
    geomStartPoint = geometry.coordinates[0][0];
    const lastSection = geometry.coordinates[geometry.coordinates.length - 1];
    geomEndPoint = lastSection[lastSection.length - 1];
  } else {
    geomStartPoint = geometry.coordinates[0];
    geomEndPoint = geometry.coordinates[geometry.coordinates.length - 1];
  }

  return [geomStartPoint, geomEndPoint];
};
