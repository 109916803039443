// TODO: old por selector

import React, { Component } from "react";

import StarRoundedIcon from "@mui/icons-material/StarRounded";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { compose } from "react-recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getChemistryAndFertilizersSuggestions } from "../../selectors/actions.selectors";

import {
  getChemistryAndFertilizersByName,
  clearChemistryAndFertilizers,
} from "../../actions/actions.actions";

import CfAutosuggest from "../../../../../shared/components/common/CfAutosuggest/CfAutosuggest";
import { getShortDateString } from "../../../../../shared/misc/timeHelpers";
import { COLOR_SECONDARY } from "../../../../../theme";

export const CHEM_DIALOD_TYPE = "CH";
export const FERT_DIALOD_TYPE = "FR";

class ChemFertSelector extends Component {
  onSuggestionSelected = (suggestion) => {
    const { chemAdditionalProps, fertAdditionalProps } = this.props;

    const expense = {
      material: suggestion,
      ...(suggestion.materialTypeId === CHEM_DIALOD_TYPE
        ? chemAdditionalProps
        : {}),
      ...(suggestion.materialTypeId === FERT_DIALOD_TYPE
        ? fertAdditionalProps
        : {}),
    };

    this.props.onSuggestionSelected(expense);
  };

  getSuggestionValue = (sugg) => {
    const suggestionValue = (
      sugg.materialTypeId === "CH"
        ? [
            sugg.name,
            sugg.authorizationHolder,
            sugg.registrationNumber,
            // TODO probrat s obchodem a Monikou jestli je to potreba - Aktulani stav rozhodnuti
            // sugg.allowed ? "P" : "Z",
          ]
        : [sugg.name, sugg.producerTradeName]
    )
      .filter((item) => item)
      .join(", ");

    if (sugg.isFavorite) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <StarRoundedIcon
            style={{ color: COLOR_SECONDARY.cleverfarm.main, marginRight: 5 }}
          />{" "}
          {suggestionValue}
        </div>
      );
    }

    return suggestionValue;
  };

  getSuggestions = (searchInput) => {
    const { actionDate, chemOnly, fertOnly } = this.props;
    const validOn = getShortDateString(actionDate);
    this.props.getChemistryAndFertilizersByName(
      searchInput,
      validOn,
      chemOnly,
      fertOnly,
    );
  };

  clearSuggestions = () => {
    const { chemOnly, fertOnly } = this.props;
    this.props.clearChemistryAndFertilizers(chemOnly, fertOnly);
  };

  render() {
    const {
      disabled,
      intl: { formatMessage },
      placeholder,
      suggestions,
    } = this.props;

    return (
      <CfAutosuggest
        clearSuggestions={this.clearSuggestions}
        disabled={disabled}
        getSuggestions={this.getSuggestions}
        getSuggestionValue={this.getSuggestionValue}
        isDebounced
        onSuggestionSelected={this.onSuggestionSelected}
        placeholder={formatMessage({ id: placeholder })}
        requiredLength={1}
        scrollable={true}
        scrollHeightMax={300}
        testData="chem-fert-selector"
        suggestions={suggestions.map((sugg) => ({
          ...sugg,
          title: formatMessage({ id: sugg.title }),
        }))}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  suggestions: getChemistryAndFertilizersSuggestions(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getChemistryAndFertilizersByName,
      clearChemistryAndFertilizers,
    },
    dispatch,
  );

ChemFertSelector.propTypes = {
  // intl: PropTypes.object.isRequired,
  // suggestions: PropTypes.array.isRequired,
  // placeholder: PropTypes.string.isRequired,
  // onSuggestionSelected: PropTypes.func.isRequired,
  // clearChemistryAndFertilizers: PropTypes.func.isRequired,
  // getChemistryAndFertilizersByName: PropTypes.func.isRequired,
  fertAdditionalProps: PropTypes.object,
  chemAdditionalProps: PropTypes.object,
  disabled: PropTypes.bool,
  chemOnly: PropTypes.bool,
  fertOnly: PropTypes.bool,
  // actionDate: PropTypes.object.isRequired,
};

ChemFertSelector.defaultProps = {
  disabled: false,
  chemOnly: false,
  fertOnly: false,
  fertAdditionalProps: {},
  chemAdditionalProps: {},
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(ChemFertSelector);
