import React from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import { FormattedMessage, FormattedDate, FormattedTime } from "react-intl";

import HorizontalScrolling from "../../../../shared/components/common/HorizontalScrolling/HorizontalScrolling";
import HorizontalScrollingItem from "../../../../shared/components/common/HorizontalScrollingItem/HorizontalScrollingItem";
import { stringLength } from "../../../../shared/misc/stringLength";
import { isToday } from "../../../../shared/misc/timeHelpers";

import { PositionDetailTo } from "../../../../shared/api/telematics/telematics.types";

const EQUIPMENT_LIMIT_TO_WRAP = 23;

type Props = {
  machinePosition?: PositionDetailTo;
};

const DetailBody = ({ machinePosition }: Props) => {
  const classes = useStyles();

  const renderDate = (date?: string): JSX.Element => {
    if (isToday(date)) {
      return (
        <>
          <FormattedMessage id="common.date.today" />{" "}
        </>
      );
    } else {
      return (
        <>
          <FormattedDate value={date} /> {", "}
        </>
      );
    }
  };

  if (!machinePosition) return null;

  const shouldBeWrapped =
    stringLength(
      machinePosition.equipment?.name,
      machinePosition.equipment?.code,
    ) > EQUIPMENT_LIMIT_TO_WRAP;

  return (
    <div className={classes.detailWrapper}>
      <HorizontalScrolling>
        <HorizontalScrollingItem>
          <div className={classes.detailColumn}>
            <span className={classes.detailLabel}>
              <FormattedMessage id="MainMapTelematicsDetail.lastPosition" />
            </span>
            <span className={classes.detailValue}>
              {renderDate(machinePosition.time)}
              <FormattedTime value={machinePosition.time} />
            </span>
            <span className={classes.detailLabel}>
              <FormattedMessage id="MainMapTelematicsDetail.lastMovement" />
            </span>
            {machinePosition?.movementTime ? (
              <span className={classes.detailValue}>
                {renderDate(machinePosition.movementTime)}
                <FormattedTime value={machinePosition.movementTime} />
              </span>
            ) : (
              "-"
            )}
            <span className={classes.detailLabel}>
              <FormattedMessage id="MainMapTelematicsDetail.speed" />
            </span>
            <span className={classes.detailValue}>
              <FormattedMessage
                id="MainMapTelematicsDetail.speed.unit"
                values={{ speed: machinePosition.speed ?? "-" }}
              />
            </span>
          </div>
        </HorizontalScrollingItem>

        <HorizontalScrollingItem>
          <div className={classes.detailColumn}>
            <>
              <span className={classes.detailLabel}>
                <FormattedMessage id="MainMapTelematicsDetail.driver" />
              </span>
              <div>
                <span className={classes.detailValue}>
                  {machinePosition.driver?.name ??
                    machinePosition.driver?.code ??
                    "-"}
                </span>{" "}
                <span
                  className={classnames(
                    classes.detailValue,
                    classes.detailValueSecondary,
                  )}
                >
                  {machinePosition.driver?.code}
                </span>
              </div>
            </>
            <>
              <span className={classes.detailLabel}>
                <FormattedMessage id="MainMapTelematicsDetail.equipment" />
              </span>
              <div
                className={
                  shouldBeWrapped ? classes.wrappedDetailValue : undefined
                }
              >
                <span className={classes.detailValue}>
                  {machinePosition.equipment?.name ?? "-"}
                </span>{" "}
                <span
                  className={classnames(
                    classes.detailValue,
                    classes.detailValueSecondary,
                  )}
                >
                  {machinePosition.equipment?.code}
                </span>
              </div>
            </>
          </div>
        </HorizontalScrollingItem>
      </HorizontalScrolling>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  detailWrapper: {
    height: 120,
    width: "100%",
    padding: "10px 0",
    lineHeight: 1.7,
  },
  detailColumn: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    columnGap: "1.5rem",
    marginRight: "3rem",
  },
  detailLabel: {
    color: theme.palette.grey[500],
  },
  detailValue: {
    fontWeight: 500,
  },
  detailValueSecondary: {
    color: theme.palette.grey[500],
  },
  wrappedDetailValue: {
    display: "flex",
    flexDirection: "column",
  },
}));

export { DetailBody };
