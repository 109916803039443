import { getInteraction } from "../../selectors/map.selectors";

export const setLayer =
  (iaId, geomType, styleType, displayPoints) => (_, getState) => {
    const ia = getInteraction(getState());
    ia.setLayer(iaId, geomType, styleType, displayPoints);
  };

export const removeLayer = (iaId) => (_, getState) => {
  const ia = getInteraction(getState());
  ia.removeLayer(iaId);
};

export const clearLayer = (iaId) => (_, getState) => {
  const ia = getInteraction(getState());
  ia.clearLayer(iaId);
};

export const setDrawIA =
  (onDrawStart, onDrawEnd, iaId, geomType, styleType, pointsStyleType) =>
  (_, getState) => {
    const ia = getInteraction(getState());
    ia.setDrawIA(
      onDrawStart,
      onDrawEnd,
      iaId,
      geomType,
      styleType,
      pointsStyleType,
    );
  };

export const removeDrawIA = (iaId) => (_, getState) => {
  const ia = getInteraction(getState());
  ia.removeDrawIA(iaId);
};

export const setModifyIA =
  (onModifyChange, onModifyStart, onModifyEnd, iaId, geomType, styleType) =>
  (_, getState) => {
    const ia = getInteraction(getState());
    ia.setModifyIA(
      onModifyChange,
      onModifyStart,
      onModifyEnd,
      iaId,
      geomType,
      styleType,
    );
  };

export const removeModifyIA = (iaId) => (_, getState) => {
  const ia = getInteraction(getState());
  ia.removeModifyIA(iaId);
};

export const setSnapIA =
  (iaId, features, geometry, snapOptions) => (_, getState) => {
    const ia = getInteraction(getState());
    ia.setSnapIA(iaId, features, geometry, snapOptions);
  };

export const removeSnapIA = (iaId) => (_, getState) => {
  const ia = getInteraction(getState());
  ia.removeSnapIA(iaId);
};

export const setDoubleClickZoomIA = () => (_, getState) => {
  const ia = getInteraction(getState());
  ia.setDoubleClickZoomIA();
};

export const removeDoubleClickZoomIA = () => (_, getState) => {
  const ia = getInteraction(getState());
  ia.removeDoubleClickZoomIA();
};

export const addFeature = (iaId, feature) => (_, getState) => {
  const ia = getInteraction(getState());
  ia.addFeature(iaId, feature);
};

export const removeFeature = (iaId, featureId) => (_, getState) => {
  const ia = getInteraction(getState());
  ia.removeFeature(iaId, featureId);
};
