import { useEffect, useRef } from "react";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

type Props = {
  setFocusedRow: (val?: string) => void;
  sourceId: string;
};

export default function useFocusedTableRowSource({
  setFocusedRow,
  sourceId,
}: Props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const callCountRef = useRef(0);
  const lastCallTimestampRef = useRef(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const doSetFocusedRow = () => dispatch(setFocusedRow(sourceId));

  const shouldUnmountComponent = () => {
    const now = Date.now();
    if (now - lastCallTimestampRef.current < 1000) {
      callCountRef.current += 1;
    } else {
      callCountRef.current = 1;
    }
    lastCallTimestampRef.current = now;

    if (callCountRef.current > 3) {
      // eslint-disable-next-line no-console
      console.warn(
        `POP event is looping. UseEffect called more than 3 times in 1 second, 
        unmounting component.`,
      );
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (history.action === "POP" && shouldUnmountComponent()) {
      return () => {}; // No-op cleanup function to prevent endless looping
    }

    return () => {
      if (history.action === "POP") {
        doSetFocusedRow();
      }
    };
  }, [sourceId, history, doSetFocusedRow]);

  return {
    doSetFocusedRow,
  };
}
