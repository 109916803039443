import React from "react";

import { makeStyles } from "@mui/styles";
import {
  FieldArrayRenderProps,
  FieldInputProps,
  FormikErrors,
  useField,
  useFormikContext,
} from "formik";
import { FormattedMessage } from "react-intl";

import { FertilizersListItem } from "./FertilizersListItem";
import { FertilizersSelector } from "./FertilizersSelector";

import { FertilizerSuggestionTo } from "../../../../../shared/api/agroevidence/agroevidence.types";
import { ActionEphFormValues } from "../../actionEph.types";

interface Props {
  actionTotalArea: number;
  arrayHelpers: FieldArrayRenderProps;
  isEditing: boolean;
  name: string;
  errors: FormikErrors<Partial<ActionEphFormValues>>;
}

export const FertilizersControl = ({
  actionTotalArea,
  arrayHelpers,
  errors,
  isEditing,
  name,
}: Props) => {
  const classes = useStyles();

  const { setFieldValue } = useFormikContext<ActionEphFormValues>();
  const [field] = useField(name);

  const handleItemRemove = (index: number) => {
    arrayHelpers.remove(index);
  };

  const handleChangeFertilizers = (fertilizer: FertilizerSuggestionTo) => {
    const fertilizersIndFields = field.value || [];
    if (fertilizer && !isItemInList(fertilizer, field))
      setFieldValue("fertilizers", [fertilizer, ...fertilizersIndFields]);
  };

  const isItemInList = (
    activeItemToCheck: FertilizerSuggestionTo,
    field: FieldInputProps<FertilizerSuggestionTo[]>,
  ) =>
    field.value?.some(
      (fertilizer: FertilizerSuggestionTo) =>
        fertilizer.id === activeItemToCheck.id,
    );

  return (
    <div>
      <div className={classes.container} style={{ width: "100%" }}>
        {isEditing && (
          <FertilizersSelector
            defaultValues={null}
            disabled={!isEditing}
            label={<FormattedMessage id="Fertilizers.selector.placeholder" />}
            onChange={handleChangeFertilizers}
          />
        )}
        <div className={classes.fertilizersList} id="fertilizer-list">
          {field.value?.length > 0 &&
            field?.value.map((field: FertilizerSuggestionTo, index: number) => (
              <FertilizersListItem
                actionTotalArea={actionTotalArea}
                errors={errors}
                fertilizer={field}
                index={index}
                isEditing={isEditing}
                key={field?.id}
                onItemRemove={handleItemRemove}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    marginLeft: 0,
    marginRight: "auto",
  },
  fertilizersList: {
    marginTop: 25,
    marginBottom: 35,
  },
}));
