import React from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import { FormattedMessage, useIntl } from "react-intl";

import { ParcelProductDto } from "../../../../../shared/api/satellite/satellite.types";

const useStyles = makeStyles({
  tooltip: {
    margin: 4,
  },
});

export type SentinelStatusProps = {
  active?: boolean;
  product?: ParcelProductDto;
};

const SentinelStatus = ({ active = false, product }: SentinelStatusProps) => {
  const intl = useIntl();
  const classes = useStyles();

  enum SENTINEL_STATES {
    ACTIVE = "active",
    ACTIVE_NO_DATA = "activeNoData",
    HISTORICAL_SERVICE = "historicalService",
    INACTIVE = "inactive",
    WAITING_FOR_USER = "waitingForUser",
  }

  const getStatus = (
    active?: boolean,
    product?: ParcelProductDto,
  ): SENTINEL_STATES => {
    if (active) {
      if (product) {
        if (product.receivedDate) {
          return SENTINEL_STATES.ACTIVE;
        }
        return SENTINEL_STATES.ACTIVE_NO_DATA;
      }
      return SENTINEL_STATES.WAITING_FOR_USER;
    }
    if (product) {
      return SENTINEL_STATES.HISTORICAL_SERVICE;
    }
    return SENTINEL_STATES.INACTIVE;
  };

  const status = getStatus(active, product);
  const dateFrom =
    product && product.createdDate
      ? intl.formatDate(product.createdDate)
      : undefined;
  const dateTo =
    product && product.stoppedDate
      ? intl.formatDate(product.stoppedDate)
      : undefined;

  const getIcon = (status: SENTINEL_STATES) => {
    switch (status) {
      case SENTINEL_STATES.ACTIVE:
        return (
          <CheckCircleIcon color="primary" data-testid="CheckCircleIcon" />
        );
      case SENTINEL_STATES.ACTIVE_NO_DATA:
        return (
          <CheckCircleOutlineIcon
            color="primary"
            data-testid="CheckCircleOutlineIcon"
          />
        );
      case SENTINEL_STATES.WAITING_FOR_USER:
        return (
          <CheckCircleIcon color="secondary" data-testid="CheckCircleIcon" />
        );
      case SENTINEL_STATES.HISTORICAL_SERVICE:
        return <CancelIcon data-testid="CancelIcon" htmlColor="#5E15A7" />;
      default:
        return <CancelIcon color="disabled" data-testid="CancelIcon" />;
    }
  };

  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip }}
      data-testid="sentinel-status"
      title={
        <FormattedMessage
          id={`SentinelStatus.${status}`}
          values={{ dateFrom, dateTo }}
        />
      }
    >
      {getIcon(status)}
    </Tooltip>
  );
};

export default SentinelStatus;
