import React, { useState } from "react";

import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import ActivitiesSelector from "../../../../../core/parcels/detail/activities/containers/ParcelActivitiesAdvancedFilter/ActivitiesSelector/ActivitiesSelector";
import ParcelSelector from "../../../../../core/parcels/list/containers/ParcelAdvancedFilter/ParcelSelector/ParcelSelector";
import CfFilter from "../../../../../shared/containers/CfFilter/CfFilter";
import CropsSelector from "../../../shared/components/CropsSelector/CropsSelector";

import { ActionSourceSelector } from "./ActionSourceSelector";
import DraftSelector from "./DraftSelector";

import {
  ActionsListAdvancedFilter,
  AdvFilterDraftState,
  AdvFilterSourceState,
} from "../../../../../shared/api/agroevidence/actions/action.types";
import {
  CropTo,
  ParcelSuggestionTo,
} from "../../../../../shared/api/agroevidence/agroevidence.types";
import { ActivitiesState } from "../../../../../shared/api/agroevidence/parcels/parcels.types";

export interface ActionsAdvancedFilterProps {
  advancedFilter: ActionsListAdvancedFilter;
  langId: string;
  namespace: string;
}

export const ActionsAdvancedFilter = ({
  advancedFilter,
  langId,
  namespace = "actionList",
}: ActionsAdvancedFilterProps) => {
  const classes = useStyles();

  const [advancedFilterState, setAdvancedFilterState] =
    useState<ActionsListAdvancedFilter>(advancedFilter);

  const handleChangeParcel = (items: ParcelSuggestionTo[]) => {
    setAdvancedFilterState({ ...advancedFilterState, parcel: items });
  };

  const handleChangeCrop = (items: CropTo[]) => {
    setAdvancedFilterState({ ...advancedFilterState, crops: items });
  };

  const handleChangeActivityName = (items: ActivitiesState[]) => {
    setAdvancedFilterState({ ...advancedFilterState, activities: items });
  };

  const handleChangeSource = (items: AdvFilterSourceState[]) => {
    setAdvancedFilterState({ ...advancedFilterState, sources: items });
  };

  const handleChangeDraft = (items: AdvFilterDraftState) => {
    setAdvancedFilterState({ ...advancedFilterState, draft: items });
  };

  return (
    <CfFilter
      filterState={advancedFilterState}
      langId={langId}
      namespace={namespace}
      setAdvancedFilterState={setAdvancedFilterState}
    >
      <div className={classes.container}>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <ActivitiesSelector
              defaultActivities={advancedFilter.activities ?? []}
              onChange={handleChangeActivityName}
              label={
                <FormattedMessage id="ActionList.table.activity.placeholder" />
              }
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <ParcelSelector
              defaultValues={advancedFilter.parcel}
              label={<FormattedMessage id="common.parcel" />}
              onChange={handleChangeParcel}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <CropsSelector
              defaultValues={advancedFilter?.crops}
              isMultiple
              label={<FormattedMessage id="ActionList.table.cropEph" />}
              onChange={handleChangeCrop}
            />
          </Grid>
        </Grid>
        {/* ADD MATERIAL SELECTOR WHEN BE IS READY */}
        <Grid className={classes.select} item xs={12}>
          <ActionSourceSelector
            defaultSource={advancedFilter?.sources}
            label={<FormattedMessage id="ActionList.table.source" />}
            onChange={handleChangeSource}
          />
        </Grid>
        <Grid className={classes.select} item xs={12}>
          <DraftSelector
            defaultDraft={advancedFilter.draft}
            label={<FormattedMessage id="ActionsList.isDraft" />}
            onChange={handleChangeDraft}
          />
        </Grid>
      </div>
    </CfFilter>
  );
};

const useStyles = makeStyles({
  container: {
    padding: 20,
    minWidth: 300,
    maxWidth: 600,
  },
  select: {
    marginBottom: 10,
  },
});
