import React from "react";

import StarRoundedIcon from "@mui/icons-material/StarRounded";
import { debounce } from "lodash";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { getFertilizers } from "../../../../../shared/api/agroevidence/catalogues/fertilizers/fertilizers.selectors";
import { getIsFetchingActiveSubstances } from "../../../../../shared/api/agroevidence/catalogues/plantProtection/plantProtection.selectors";

import { fetchFertilizersByName } from "../../../shared/actions/actions.actions";

import { getSourceId } from "../../../../../catalogues/helpers/selectorHelpers";
import CfAutocomplete from "../../../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import { getLocalizedDateString } from "../../../../../shared/misc/timeHelpers";
import { COLOR_SECONDARY } from "../../../../../theme";

import { FertilizerSuggestionTo } from "../../../../../shared/api/agroevidence/agroevidence.types";

interface Props {
  defaultValues: unknown;
  disabled: boolean;
  error?: boolean;
  label: React.ReactNode;
  onChange: (item: unknown) => void;
}

export const FertilizersSelector = ({
  defaultValues,
  disabled = false,
  error = false,
  label,
  onChange,
}: Props) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const fertilizers = useSelector(getFertilizers);
  const isFetching = useSelector(getIsFetchingActiveSubstances);

  const handleGetFertilizers = (search: string) => {
    dispatch(fetchFertilizersByName(search));
  };

  const debouncedOptions = debounce(handleGetFertilizers, 500);

  const handleRenderOption = (option: FertilizerSuggestionTo) => {
    const catalogueType = option.catalogue?.type
      ? intl.formatMessage({ id: getSourceId(option.catalogue.type) })
      : null;

    const validTo = option.validTo
      ? getLocalizedDateString(option.validTo)
      : "-";

    const optionValue = [option.name, catalogueType, validTo].join(", ");

    if (option.isFavorite) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <StarRoundedIcon
            style={{ color: COLOR_SECONDARY.cleverfarm.main, marginRight: 5 }}
          />{" "}
          {optionValue}
        </div>
      );
    }
    return optionValue;
  };

  return (
    <CfAutocomplete
      clearInputOnSelect
      defaultValues={defaultValues}
      disabled={disabled}
      error={error}
      helperText={error ? <FormattedMessage id="validation.required" /> : ""}
      id="fertilizers-selector"
      isFetching={isFetching}
      label={label}
      loadOptions={debouncedOptions}
      onChange={onChange}
      renderOption={handleRenderOption}
      suggestions={fertilizers}
      testId="fertilizers-selector"
    />
  );
};
