import React from "react";

import { Grid } from "@mui/material";

import { PlantProtectionEffectivenessListItem } from "./PlantProtectionEffectivenessListItem";

import { PlantProtectionEffectiveness } from "../../../../../shared/api/agroevidence/agroevidence.types";
import { InitialParcelToAdd } from "../../../ActionOthers/actionOther.types";
import {
  EphPlantProtectionsType,
  PlantProtectionEffectivenessType,
} from "../../actionEph.types";

type Props = {
  parcels: InitialParcelToAdd[];
  plantProtection: EphPlantProtectionsType;
  isExisting: boolean;
};

export const PlantProtectionEffectivenessList = ({
  isExisting,
  parcels,
  plantProtection,
}: Props) => {
  const isPlantProtectionEffectivenessEmpty =
    !plantProtection.effectiveness ||
    plantProtection.effectiveness.length === 0;

  let data;

  if (isPlantProtectionEffectivenessEmpty) {
    data = parcels.slice(0, 3).map((item: InitialParcelToAdd, index) => ({
      parcelName: item.localName,
      value: isExisting
        ? PlantProtectionEffectiveness.NOT_SPECIFIED
        : PlantProtectionEffectiveness.YES,
      index,
      hasNext: index < parcels.length - 1,
    }));
  } else {
    data = plantProtection.effectiveness
      .slice(0, 3)
      .map((item: PlantProtectionEffectivenessType, index) => ({
        parcelName: item.parcelName,
        value: item.value,
        index,
        hasNext: index < plantProtection.effectiveness.length - 1,
      }));
  }

  return (
    <Grid container>
      {data.map((item, index) => (
        <PlantProtectionEffectivenessListItem
          hasNext={item.hasNext}
          index={item.index}
          key={index}
          parcelName={item.parcelName}
          value={item.value}
        />
      ))}
    </Grid>
  );
};
