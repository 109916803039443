import React, { ChangeEvent, useEffect, useState } from "react";

import { FormControlLabel, Switch } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { RSAAResultAction } from "redux-api-middleware";

import {
  getAdminIntegration,
  updateAdminIntegration,
} from "../../../../../../shared/api/sentinel/admin/admin.api";
import CfDialog from "../../../../../../shared/components/common/CfDialog/CfDialog";
import CfLoader from "../../../../../../shared/components/common/CfLoader/CfLoader";

import {
  IntegrationAdminTo,
  IntegrationType,
} from "../../../../../../shared/api/satellite/satellite.types";
import { AdminFarm } from "../../../../sensors/admin.sensors.types";

type Props = {
  onClose: () => void;
  farm?: AdminFarm;
};

const IntegrationManagementDialog = ({ farm, onClose }: Props) => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  const [varioDoc, setVarioDoc] = useState<boolean | undefined>(undefined);
  const [varioDocIsProcessing, setVarioDocIsProcessing] = useState(false);

  const [agInt, setAgInt] = useState<boolean | undefined>(undefined);
  const [agIntIsProcessing, setAgIntIsProcessing] = useState(false);

  const [taskdata, setTaskdata] = useState<boolean | undefined>(undefined);
  const [taskdataIsProcessing, setTaskdataIsProcessing] = useState(false);

  const isInit =
    varioDoc !== undefined && agInt !== undefined && taskdata !== undefined;

  useEffect(() => {
    const fetchSwitchValue = (
      type: IntegrationType,
      setRes: (val: boolean) => void,
    ) => {
      (
        dispatch(
          getAdminIntegration({
            farmIds: farm?.id,
            type,
          }),
        ) as unknown as Promise<unknown>
      ).then((res: RSAAResultAction<IntegrationAdminTo>) => {
        if (!res.error && res.payload) {
          setRes(res.payload.enabled);
        }
      });
    };

    fetchSwitchValue(IntegrationType.AGINTEGRATED, setAgInt);
    fetchSwitchValue(IntegrationType.TASKDATA, setTaskdata);
    fetchSwitchValue(IntegrationType.VARIODOC, setVarioDoc);
  }, [dispatch, farm?.id]);

  const onChangeSwitch = (
    enabled: boolean,
    type: IntegrationType,
    setRes: (val: boolean) => void,
    setIsProcessing: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    setIsProcessing((val) => !val);
    (
      dispatch(
        updateAdminIntegration({
          farmIds: farm?.id,
          payload: {
            type,
            enabled,
          },
        }),
      ) as unknown as Promise<unknown>
    )
      .then((res: RSAAResultAction<IntegrationAdminTo>) => {
        if (!res.error) {
          setRes(enabled);
        }
      })
      .finally(() => setIsProcessing(false));
  };

  return (
    <CfDialog
      acceptText={<FormattedMessage id="common.close" />}
      onAccept={onClose}
      opened
      title={
        <span>
          <FormattedMessage id="SentinelAdmin.integrationManagement.title" />
          {` - ${farm?.name}`}
        </span>
      }
    >
      {!isInit ? (
        <div className={classes.loading}>
          <CfLoader />
        </div>
      ) : (
        <div>
          <div>
            <FormControlLabel
              label={intl.formatMessage({ id: "Agi.service" })}
              control={
                <Switch
                  checked={agInt}
                  color="primary"
                  disabled={agIntIsProcessing || agInt === undefined}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    onChangeSwitch(
                      e.target.checked,
                      IntegrationType.AGINTEGRATED,
                      setAgInt,
                      setAgIntIsProcessing,
                    )
                  }
                />
              }
            />
          </div>
          <div>
            <FormControlLabel
              label={intl.formatMessage({ id: "VarioDoc.service" })}
              control={
                <Switch
                  checked={varioDoc}
                  color="primary"
                  disabled={varioDocIsProcessing || varioDoc === undefined}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    onChangeSwitch(
                      e.target.checked,
                      IntegrationType.VARIODOC,
                      setVarioDoc,
                      setVarioDocIsProcessing,
                    )
                  }
                />
              }
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={taskdata}
                  color="primary"
                  disabled={taskdataIsProcessing || taskdata === undefined}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    onChangeSwitch(
                      e.target.checked,
                      IntegrationType.TASKDATA,
                      setTaskdata,
                      setTaskdataIsProcessing,
                    )
                  }
                />
              }
              label={intl.formatMessage({
                id: "SentinelAdmin.integrationManagement.machineDataTab",
              })}
            />
          </div>
        </div>
      )}
    </CfDialog>
  );
};

const useStyles = makeStyles(() => ({
  loading: {
    margin: "10px 0px",
  },
}));

export default IntegrationManagementDialog;
