import { SectionListState } from "../catalogues/reducers/sectionList.types";
import { CataloguesTypesState } from "../shared/api/agroevidence/catalogues/cataloguesTypes.types";
import { CropsState } from "../shared/api/agroevidence/catalogues/crops/crops.types";
import { CataloguesEagriState } from "../shared/api/agroevidence/catalogues/eagri/eagri.types";
import { FertilizersState } from "../shared/api/agroevidence/catalogues/fertilizers/fertilizers.types";
import { PlantProtectionState } from "../shared/api/agroevidence/catalogues/plantProtection/plantProtection.types";
import { SeedsState } from "../shared/api/agroevidence/catalogues/seeds/seeds.types";
import { TelematicsCataloguesState } from "../shared/api/telematics/catalogues/telematicsCatalogues.types";
import { TelematicsStatusState } from "../shared/api/telematics/sectionStatus/sectionStatus.types";

export interface CataloguesState {
  api: {
    agroevidence: {
      cataloguesTypes: CataloguesTypesState;
      crops: CropsState;
      eagri: CataloguesEagriState;
      fertilizers: FertilizersState;
      plantProtection: PlantProtectionState;
      seeds: SeedsState;
    };
    telematics: TelematicsCataloguesState;
  };
  telematicsStatus: TelematicsStatusState;
  ui: {
    drivers: SectionListState;
    equipment: SectionListState;
    fertilizers: SectionListState;
    machines: SectionListState;
    plantProtection: SectionListState;
    plantProtectionApplications: SectionListState;
    seeds: SectionListState;
    selectedNamespace: CATALOGUES_NAMESPACES;
  };
}

export enum CATALOGUES_NAMESPACES {
  DRIVERS = "drivers",
  EQUIPMENT = "equipment",
  FERTILIZERS = "fertilizers",
  MACHINES = "machines",
  PLANT_PROTECTION = "plantProtection",
  PLANT_PROTECTION_APPLICATIONS = "plantProtectionApplications",
  SEEDS = "seeds",
}
