import React, { ReactNode, useMemo } from "react";

import { useIntl } from "react-intl";

import CfAutocomplete from "../../../../../shared/components/common/CfAutocomplete/CfAutocomplete";

import { AdvFilterDraftState } from "../../../../../shared/api/agroevidence/actions/action.types";

interface Props {
  defaultDraft?: AdvFilterDraftState;
  label: ReactNode;
  onChange: (items: AdvFilterDraftState) => void;
}

export const DRAFT_STATES = ["yes", "no"];

const DraftSelector = ({ defaultDraft, label, onChange }: Props) => {
  const intl = useIntl();

  const suggestions = useMemo(
    () =>
      DRAFT_STATES.map((s) => ({
        state: s === "yes",
        label: intl.formatMessage({ id: `common.${s}` }),
      })),
    [intl],
  );

  return (
    <CfAutocomplete
      defaultValues={defaultDraft}
      id="draft-filter"
      label={label}
      onChange={onChange}
      suggestions={suggestions}
      testId="draft-filter"
    />
  );
};

export default DraftSelector;
