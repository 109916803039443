import React, { useMemo } from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

import { IndiceType } from "../../../../core/precision/selectors/indices";
import { zonesColors } from "../../../../core/precision/services/monitoringZonesColors";

const getEvenIndexGradient = (index: IndiceType) => {
  const colors = zonesColors[index];
  const numColors = colors.length;
  const weight = 100 / (numColors - 1);
  return `linear-gradient(90deg,${colors.map((item, i) => `${item.color} ${i * weight}%`)})`;
};

interface Props {
  index: IndiceType;
  classes?: Record<string, string>;
}

const GradientRange = ({ classes, index }: Props) => {
  const defClasses = useStyles();
  const gradient = useMemo(() => getEvenIndexGradient(index), [index]);

  return (
    <div className={defClasses.legendWrapper}>
      <span className={classNames(defClasses.label, classes?.label)}>Min</span>{" "}
      <span
        className={defClasses.legendImage}
        style={{ background: gradient }}
      />{" "}
      <span className={classNames(defClasses.label, classes?.label)}>Max</span>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  legendWrapper: {
    display: "flex",
    alignItems: "center",
  },
  legendImage: {
    margin: "0px 5px",
    width: 107,
    height: 8,
    borderRadius: 10,
    border: "1px solid #E4E4E4",
  },
  label: {
    color: theme.palette.grey[500],
  },
}));

export default GradientRange;
