import { getConvertedAmount } from "../../../../shared/api/core/units/units.selectors";
import {
  getStorePage,
  getStoreRowsPerPage,
  getStoreFromFilter,
  getStoreToFilter,
  getStoreUnitId,
} from "../selectors/store.selectors";

import * as types from "./store.constants";

import { getActionByTransactionId } from "../../../../shared/api/agroevidence/actions/actions.api";
import { convertAmount } from "../../../../shared/api/core/units/units.api";
import {
  getStore,
  exportStoreItem as exportStoreApi,
} from "../../../../shared/api/stores/stores/stores.api";
import {
  getTransactions,
  createTransaction,
  updateTransaction as updateTransactionApi,
  deleteTransaction as deleteTransactionApi,
} from "../../../../shared/api/stores/transactions/transactions.api";
import FileService from "../../../../shared/services/File.service";
import LocalStorage from "../../../../shared/services/LocalStorage.service";

export const fetchStore = (farmId, storeId) => (dispatch) => {
  dispatch(getStore(storeId)).then((res) => {
    const item = res.payload;
    const records = LocalStorage.loadFromLocalStorage(`${farmId}-stores`) || {};

    if (records[storeId]) {
      if (records[storeId] !== item.unitId) {
        dispatch(
          calcStoreViewData(item.currentBalance, item.unitId, records[storeId]),
        );
      } else {
        dispatch(setStoreViewData(item.currentBalance, records[storeId]));
      }
    } else {
      dispatch(setStoreViewData(item.currentBalance, item.unitId));
      saveStoreUnitIdToLS(farmId, storeId, item.unitId);
    }
  });
};

export const fetchTransactions = (storeId) => (dispatch, getState) => {
  const state = getState();

  const params = {
    page: getStorePage(state),
    rowsPerPage: getStoreRowsPerPage(state),
    from: getStoreFromFilter(state),
    to: getStoreToFilter(state),
    unitId: getStoreUnitId(state),
  };

  dispatch(getTransactions(storeId, params));
};

export const saveTransaction = (item, storeId, farmId) => (dispatch) =>
  dispatch(createTransaction(item)).then(() => {
    dispatch(fetchStore(farmId, storeId));
    dispatch(fetchTransactions(storeId));
  });

export const updateTransaction = (item, storeId, farmId) => (dispatch) =>
  dispatch(updateTransactionApi(item)).then(() => {
    dispatch(fetchStore(farmId, storeId));
    dispatch(fetchTransactions(storeId));
  });

export const deleteTransaction =
  (transactionId, storeId, farmId) => (dispatch) =>
    dispatch(deleteTransactionApi(transactionId)).then(() => {
      dispatch(fetchStore(farmId, storeId));
      dispatch(fetchTransactions(storeId));
    });

export const setStoreFromFilter = (from) => ({
  type: types.SET_STORE_FROM_FILTER,
  from,
});

export const setStoreToFilter = (to) => ({
  type: types.SET_STORE_TO_FILTER,
  to,
});

export const setStoreUnitId = (unitId) => ({
  type: types.SET_STORE_UNIT_ID,
  unitId,
});

export const setStoreAmount = (amount) => ({
  type: types.SET_STORE_AMOUNT,
  amount,
});

export const setStoreViewData = (amount, unitId) => (dispatch) => {
  dispatch(setStoreAmount(amount));
  dispatch(setStoreUnitId(unitId));
};

export const calcStoreViewData =
  (amount, fromUnitId, toUnitId) => (dispatch, getState) => {
    dispatch(convertAmount(amount, fromUnitId, toUnitId)).then(() => {
      const state = getState();
      dispatch(setStoreViewData(getConvertedAmount(state), toUnitId));
    });
  };

export const updateStoreViewData =
  (amount, fromUnitId, toUnitId, farmId, storeId) => (dispatch) => {
    dispatch(calcStoreViewData(amount, fromUnitId, toUnitId));
    saveStoreUnitIdToLS(farmId, storeId, toUnitId);
  };

const saveStoreUnitIdToLS = (farmId, storeId, toUnitId) => {
  const records = LocalStorage.loadFromLocalStorage(`${farmId}-stores`) || {};
  records[storeId.toString()] = toUnitId;
  LocalStorage.saveToLocalStorage(records, `${farmId}-stores`);
};

export const redirectToAction = (transactionId, ngGoToAction) => (dispatch) => {
  dispatch(getActionByTransactionId(transactionId)).then((res) => {
    if (!res.error && res.payload?.actionId) {
      ngGoToAction(res.payload?.actionId);
    }
  });
};

export const exportStore = (exportFormat, storeId) => (dispatch, getState) => {
  const state = getState();
  const params = {
    from: getStoreFromFilter(state),
    to: getStoreToFilter(state),
    unit: getStoreUnitId(state),
    format: exportFormat,
  };
  dispatch(exportStoreApi(storeId, params)).then((res) => {
    FileService.processFileResponse(res);
  });
};
