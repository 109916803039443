import React from "react";

import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

type Props = {
  callback: () => void;
};

const StoreFabButton = ({ callback }: Props) => {
  const classes = useStyles();

  return (
    <div>
      <Fab
        aria-label="add to store"
        className={classes.button}
        color="secondary"
        onClick={callback}
        size="medium"
      >
        <AddIcon />
      </Fab>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: theme.palette.common.white,
    zIndex: "unset",
  },
}));

export default StoreFabButton;
