import { RSAA } from "redux-api-middleware";

import * as types from "./reports.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../api.constants";

// TODO: TENHLE ENDPOINT NEVIDÍM VE SWAGGERU!
// ekvivalent v agroevidenci by mohl být "seed-applications"
export const getSowingPlan = () => ({
  [RSAA]: {
    endpoint: `reports/eagri/sowing-plan?`,
    method: methods.GET,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_SOWING_PLAN,
      {
        type: types.GET_SOWING_PLAN_SUCCESS,
        payload: (action: unknown, state: unknown, res: Response) => ({
          body: res.blob(),
          disposition: res.headers.get("Content-Disposition"),
          type: res.headers.get("Content-Type"),
          status: res.status,
          id: "sowingPlan",
        }),
      },
      types.GET_SOWING_PLAN_ERROR,
    ],
  },
});
