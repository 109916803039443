import React, { useEffect } from "react";

import { Theme } from "@mui/material";
import Button from "@mui/material/Button";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  getLocations,
  getLocationsOrder,
  getLocationsOrderBy,
  getLocationsPage,
  getLocationsRowsPerPage,
  getLocationsTotalCount,
  getLocationsTextFilter,
  getLocationsHistoryFilter,
  getIsFetchingLocations,
} from "../../selectors/locations.selectors";
import { getHoveredFeatureId } from "../../selectors/map.selectors";

import {
  assignGroups,
  fetchLocationsWithPoints,
} from "../../actions/locations.actions";
import { storeHoveredFeatureId } from "../../actions/map.actions";

import { NAMESPACE as namespace } from "../../reducer/locations.reducer";

import CfTableFooter from "../../../common/components/CfTableFooter/CfTableFooter";
import { useCfTableFooter } from "../../../common/components/CfTableFooter/useCfTableFooter";
import CfTableHead from "../../../common/components/CfTableHead/CfTableHead";
import { useCfTableHead } from "../../../common/components/CfTableHead/useCfTableHead";
import NodeDeviceType from "../../../shared/components/common/NodeDeviceType/NodeDeviceType";
import DeviceActivityInfo from "../../../shared/components/specific/DeviceActivityInfo/DeviceActivityInfo";
import SensorsLastUpdate from "../../../shared/components/specific/SensorsLastUpdate/SensorsLastUpdate";
import CfTableBodyEmpty from "../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableBodyLoader from "../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader";
import CfTableCell from "../../../shared/components/tables/CfTableCell/CfTableCell";
import CfTableRowTools from "../../../shared/components/tables/CfTableRowTools/CfTableRowTools";
import CfTableWrapper from "../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import { getColDesc } from "../../../shared/misc/helper";
import SensorsService from "../../../shared/services/Sensors.service";
import { COLOR_GREY } from "../../../theme";
import { AnyTodo } from "../../../types";
import NodeLastValues from "../../components/NodeLastValues/NodeLastValues";
import { NodeLocationEditGroup } from "../../components/NodeLocationEditGroup/NodeLocationEditGroup";
import NodeLocationGroupsInfo from "../../components/NodeLocationGroupsInfo/NodeLocationGroupsInfo";
import { NodeLocationStatus } from "../../components/NodeLocationStatus/NodeLocationStatus";

const colStyles = {
  head: {
    p: {
      margin: 0,
    },
    secondaryLabel: {
      color: COLOR_GREY[400],
    },
  },
};

const columns = {
  status: getColDesc(true, <FormattedMessage id="common.state" />, {
    width: "70px",
  }),
  name: getColDesc(
    true,
    <span>
      <p style={colStyles.head.p}>
        <FormattedMessage id="common.name" />
      </p>
      <p style={{ ...colStyles.head.p, ...colStyles.head.secondaryLabel }}>
        <FormattedMessage id="common.id" />
      </p>
    </span>,
  ),
  lastUpdate: getColDesc(
    true,
    <span>
      <p style={colStyles.head.p}>
        <FormattedMessage id="common.lastUpdate" />
      </p>
      <p style={{ ...colStyles.head.p, ...colStyles.head.secondaryLabel }}>
        <FormattedMessage id="common.lastValues" />
      </p>
    </span>,
    { width: "30%" },
  ),
  deviceType: getColDesc(true, <FormattedMessage id="common.type" />),
  groups: getColDesc(false, <FormattedMessage id="NodeGroups.group" />, {
    width: "20%",
  }),
};

type Props = {
  farmId: string;
  shouldReloadData: boolean;
  advancedFilter: AnyTodo;
};

export const NodeLocationsTable = ({
  advancedFilter,
  farmId,
  shouldReloadData,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const locations: AnyTodo[] = useSelector(getLocations);
  const page = useSelector(getLocationsPage);
  const order = useSelector(getLocationsOrder);
  const orderBy = useSelector(getLocationsOrderBy);
  const rowsPerPage = useSelector(getLocationsRowsPerPage);
  const count = useSelector(getLocationsTotalCount);
  const textFilter = useSelector(getLocationsTextFilter) ?? "";
  const historyFilter = useSelector(getLocationsHistoryFilter);
  const isFetching = useSelector(getIsFetchingLocations);
  const hoveredFeatureId = useSelector(getHoveredFeatureId);

  const { onPageChange, onRowsPerPageChange } = useCfTableFooter(namespace);
  const { onSelect, onSort } = useCfTableHead(namespace);

  useEffect(() => {
    dispatch(fetchLocationsWithPoints());
  }, [
    page,
    rowsPerPage,
    order,
    orderBy,
    textFilter,
    advancedFilter,
    historyFilter,
    shouldReloadData,
    dispatch,
  ]);

  const renderLocation = (nodeLocation: AnyTodo) => {
    const isActive = SensorsService.isActive(nodeLocation);
    const isHistoric = SensorsService.isHistoric(nodeLocation);

    return (
      <span className={classes.lastValues}>
        <NodeLastValues
          hideValues={!nodeLocation.lastUpdate}
          isActive={isActive}
          isDetail={false}
          isHistoric={isHistoric}
          node={nodeLocation.node}
          pestPredictions={nodeLocation.computedFeatures || []}
        />
      </span>
    );
  };

  return (
    <CfTableWrapper>
      <CfTableHead
        columns={columns}
        onSelect={onSelect}
        onSort={onSort}
        order={order}
        orderBy={orderBy}
      />
      {isFetching && <CfTableBodyLoader columns={columns} />}
      {locations.length ? (
        <TableBody>
          {locations.map((nodeLocation) => {
            const isHistoric = SensorsService.isHistoric(nodeLocation);
            const isInactive = SensorsService.isInactive(nodeLocation);
            const isActive = SensorsService.isActive(nodeLocation);
            const isHovered = hoveredFeatureId === nodeLocation.id;
            return (
              <TableRow
                className={classes.tableRow}
                hover
                key={nodeLocation.id}
                onMouseEnter={() => storeHoveredFeatureId(nodeLocation.id)}
                onMouseLeave={() => storeHoveredFeatureId(null)}
                style={isHovered ? { backgroundColor: COLOR_GREY[100] } : {}}
                onClick={() => {
                  history.push(`/farm/${farmId}/sensors/${nodeLocation.id}`);
                }}
              >
                <CfTableCell name="status">
                  <NodeLocationStatus nodeLocation={nodeLocation} />{" "}
                </CfTableCell>
                <CfTableCell name="name">
                  <span>
                    <p className={`${classes.cellRow}`}>
                      {isInactive ? (
                        <Button
                          className={classes.activateButton}
                          disableElevation
                          size="small"
                          variant="contained"
                          onClick={(evt) => {
                            history.push(
                              `/farm/${farmId}/sensors/${nodeLocation.id}/activate`,
                            );
                            evt.stopPropagation();
                          }}
                        >
                          <FormattedMessage id="NodeActivation.placeSensor" />
                        </Button>
                      ) : (
                        <span className={classes.name}>
                          {nodeLocation.name}
                        </span>
                      )}
                    </p>
                    <p className={`${classes.cellRow} ${classes.greyText}`}>
                      {nodeLocation.node.externalId}
                    </p>
                  </span>
                </CfTableCell>
                <CfTableCell name="lastUpdate">
                  <span>
                    <div className={`${classes.cellRow}`}>
                      <span className={classes.lastUpdate}>
                        {isHistoric && (
                          <DeviceActivityInfo
                            dateFrom={nodeLocation.from}
                            dateTo={nodeLocation.to}
                            displayIcon={false}
                            isActive={isActive}
                            isHistoric={isHistoric}
                          />
                        )}
                        {!isHistoric && !isInactive && (
                          <SensorsLastUpdate
                            displayIcon={false}
                            lastUpdate={nodeLocation.lastUpdate}
                          />
                        )}
                        {!isHistoric && isInactive && (
                          <FormattedMessage id="NodeLocationsTable.inactive" />
                        )}
                      </span>
                    </div>
                    <div className={`${classes.cellRow}`}>
                      {renderLocation(nodeLocation)}
                    </div>
                  </span>
                </CfTableCell>
                <CfTableCell name="deviceType">
                  <NodeDeviceType
                    displayIcon={false}
                    node={nodeLocation.node}
                  />
                </CfTableCell>
                <CfTableCell name="groups">
                  <NodeLocationGroupsInfo
                    displayIcon={false}
                    nodeLocation={nodeLocation}
                  />
                </CfTableCell>
                <CfTableRowTools
                  key="group-edits-btns"
                  toolsClass={classes.tableRowTools}
                  withoutGradient={true}
                >
                  {isActive && !isHistoric && (
                    <NodeLocationEditGroup
                      nodeLocation={nodeLocation}
                      setGroups={assignGroups}
                    />
                  )}
                </CfTableRowTools>
              </TableRow>
            );
          })}
        </TableBody>
      ) : (
        <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
      )}
      <CfTableFooter
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </CfTableWrapper>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  greyText: {
    color: theme.palette.grey[500],
  },
  cellRow: {
    margin: 0,
  },
  lastUpdate: {
    color: theme.palette.grey[500],
  },
  lastValues: {
    display: "flex",
    flexWrap: "wrap",
    position: "relative",
    left: -5,
  },
  activateButton: {
    padding: "3px 8px",
    marginBottom: 4,
    width: "max-content",
  },
  name: {
    fontWeight: 500,
  },
  tableRow: {
    transform: "scale(1)",
    "&:hover $tableRowTools": {
      display: "block",
    },
    cursor: "pointer",
  },
  tableRowTools: {
    display: "none",
    background: "#FFFFFF00",
  },
}));

export default NodeLocationsTable;
