import { useEffect, useRef, useState } from "react";

import { useSelector } from "react-redux";

import { getMainMapTelematicsBottomPanelHeight } from "../../../../shared/api/telematics/mainMapTelematics/mainMapTelematics.selectors";

import { useCurrentWidth } from "../../../../shared/hooks/useWidth";

const useMachineTable = (isInit: boolean) => {
  const bottomPanelHeight = useSelector(getMainMapTelematicsBottomPanelHeight);
  const [topOffset, setTopOffset] = useState(0);
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const width = useCurrentWidth();

  useEffect(() => {
    if (isInit && tableContainerRef.current) {
      const rect = tableContainerRef.current.getBoundingClientRect();
      setTopOffset(rect.top);
    }
  }, [width, isInit, topOffset]);

  return {
    bottomPanelHeight,
    topOffset,
    tableContainerRef,
  };
};

export { useMachineTable };
