import { AnyAction } from "redux";

import * as commonFilterTypes from "../../shared/actions/filter.constants";
import * as commonTableTypes from "../../shared/actions/table.constants";
import { ROWS_PER_PAGE } from "../../shared/constants/rowsPerPage.constants";
import * as sortOrder from "../../shared/constants/sortOrder.constants";
import { SET_AGGREGATED } from "../actions/telematicsList.constants";

import { LogbookSortColumn } from "../../generated/api/telematics";

import { TelematicsList } from "../telematics.types";

export const NAMESPACE = "telematicsList";

const initialState: TelematicsList = {
  aggregated: false,
  page: 0,
  rowsPerPage: ROWS_PER_PAGE,
  order: sortOrder.ASC,
  orderBy: LogbookSortColumn.FROM,
  selected: [],
  textFilter: "",
  advancedFilter: {},
};

export default (reducerNamespace = NAMESPACE) =>
  (state = initialState, action: AnyAction): TelematicsList => {
    const { namespace } = action;
    if (namespace && namespace !== reducerNamespace) return state;

    const setFirstPage = (newState: Partial<TelematicsList>) => ({
      ...state,
      ...newState,
      page: 0,
    });

    switch (action.type) {
      case commonTableTypes.SET_TABLE_PAGE:
        return {
          ...state,
          page: action.page,
        };
      case commonTableTypes.SET_TABLE_ROWS_PER_PAGE:
        return setFirstPage({
          rowsPerPage: action.rowsPerPage,
        });
      case commonTableTypes.SET_TABLE_ORDER:
        return setFirstPage({
          order: action.order,
        });
      case commonTableTypes.SET_TABLE_ORDER_BY:
        return setFirstPage({
          orderBy: action.orderBy,
        });
      case commonTableTypes.SET_TABLE_SELECTED:
        return {
          ...state,
          selected: action.selected,
        };
      case commonFilterTypes.SET_TEXT_FILTER:
        return setFirstPage({
          textFilter: action.textFilter,
        });
      case commonFilterTypes.SET_ADVANCED_FILTER:
        return setFirstPage({
          advancedFilter: action.advancedFilter,
        });
      case SET_AGGREGATED:
        return setFirstPage({
          aggregated: action.payload,
        });
      default:
        return state;
    }
  };
