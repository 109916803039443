export enum COLUMN_NAMES {
  AUTH_HOLDER = "authorizationHolder",
  CROP = "crop",
  IS_FAVORITE = "isFavorite",
  NAME = "name",
  ORGANISMUS = "organismus",
  REG_NUMBER = "registrationNumber",
  VALID_TO = "validTo",
  SOURCE = "source",
  ACTIVE_SUBSTANCES = "activeSubstances",
}

export enum APPLICATIONS_COLUMN_NAMES {
  CROP = "crop",
  ORGANISM = "organism",
  REG_EXTERNAL_ID = "registrationExternalId",
  REG_HOLDER = "registrationHolder",
  REG_NUMBER = "registrationNumber",
  VALID_UNTIL = "validUntil",
}
