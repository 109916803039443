export enum SECTIONS {
  DRIVERS = "drivers",
  EQUIPMENT = "equipment",
  FERTILIZERS_REACT = "fertilizersReact",
  MACHINES = "machines",
  PLANT_PROTECTION = "plantProtection",
  SEEDS_REACT = "seedsReact",
}

export const CATALOGUES_URLS = {
  [SECTIONS.SEEDS_REACT]: "catalogues/seedsReact",
  [SECTIONS.FERTILIZERS_REACT]: "catalogues/fertilizersReact",
  [SECTIONS.PLANT_PROTECTION]: "catalogues/plantProtection",
  [SECTIONS.DRIVERS]: "catalogues/drivers",
  [SECTIONS.MACHINES]: "catalogues/machines",
  [SECTIONS.EQUIPMENT]: "catalogues/equipment",
};
