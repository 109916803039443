import React, { useEffect } from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { getVariableActionIds as getVariableActionIdsSelector } from "../../../../../../shared/api/sentinel/variableApplication/variableApplication.selectors";
import {
  getSelectedOnPage,
  getParcelActivitiesDateFilter,
} from "../../selectors/parcelActivities.selectors";

import {
  setDateFilter,
  deleteActivities,
} from "../../actions/parcelActivities.actions";

import { NAMESPACE as namespace } from "../../reducer/parcelActivities.reducer";

import {
  getVariableActionIds,
  resetVariableActionIds,
} from "../../../../../../shared/api/sentinel/variableApplication/variableApplication.api";
import CfDateFilter from "../../../../../../shared/components/common/CfDateFilter/CfDateFilter";
import TableActionButtons from "../../../../../../shared/components/tables/TableActionButtons/TableActionButtons";
import { AnyTodo } from "../../../../../../types";
import ParcelActivitiesAdvancedFilter from "../ParcelActivitiesAdvancedFilter/ParcelActivitiesAdvancedFilter";
import ParcelActivitiesTable from "../ParcelActivitiesTable/ParcelActivitiesTable";

type Props = {
  parcelId: string;
  langId: string;
  farmId: string;
  isParcelInZOD: boolean;
};

const ParcelActivities = ({
  farmId,
  isParcelInZOD,
  langId,
  parcelId,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const selectedOnPage = useSelector(getSelectedOnPage);
  const dateFilter = useSelector(getParcelActivitiesDateFilter);
  const variableActionIds = useSelector(getVariableActionIdsSelector);

  useEffect(() => {
    dispatch(getVariableActionIds());

    return () => {
      dispatch(resetVariableActionIds());
    };
  }, [dispatch]);

  const handleDateChange = (filter: AnyTodo) => {
    let dateFilter = filter;
    if (filter) {
      // TODO: utc offset is temporarily added, until BE error is fixed
      dateFilter = filter.add(filter.utcOffset(), "m").toISOString();
    }
    dispatch(setDateFilter(dateFilter));
  };

  const handleDeleteActivities = () => {
    dispatch(deleteActivities(parcelId, selectedOnPage, variableActionIds));
  };

  return (
    <div>
      <div className={classes.filtersHeader}>
        <div className={classes.actionButtons}>
          <TableActionButtons
            onDeleteActions={handleDeleteActivities}
            selected={selectedOnPage}
            content={
              <FormattedMessage id="ActionButtons.deleteModalAction.context" />
            }
            title={
              <FormattedMessage id="ActionButtons.deleteModalAction.title" />
            }
          />
        </div>
        <div className={classes.dateFilter}>
          <CfDateFilter
            label={<FormattedMessage id="common.date-from" />}
            name="parcel-activities-date-filter"
            onFilterChange={handleDateChange}
            value={dateFilter}
          />
        </div>
        <div className={classes.advancedFilter}>
          <ParcelActivitiesAdvancedFilter
            langId={langId}
            namespace={namespace}
          />
        </div>
      </div>
      <ParcelActivitiesTable
        farmId={farmId}
        isParcelInZOD={isParcelInZOD}
        parcelId={parcelId}
      />
    </div>
  );
};

export default ParcelActivities;

const useStyles = makeStyles((theme: Theme) => ({
  dateFilter: {
    width: 180,
    minWidth: 180,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      order: 2,
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0px 16px 8px 8px",
    },
  },
  actionButtons: {
    paddingBottom: 6,
    [theme.breakpoints.down("sm")]: {
      order: 3,
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: 14,
    },
  },
  advancedFilter: {
    order: 3,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 8,
      order: 1,
    },
  },
  filtersHeader: {
    display: "flex",
    alignItems: "baseline",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
}));
