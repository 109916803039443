import React, { useContext, useEffect, useState } from "react";

import { Theme } from "@mui/material";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RSAAResultAction } from "redux-api-middleware";

import { getDeviceTypes } from "../../../../../shared/api/irrigation/devices/devices.selectors";

import { fetchDeviceTypes } from "../../../../../irrigation/actions/devices.actions";
import {
  setActionButtonEnabled,
  setIrrigationTabActive,
  setSensorsTabActive,
  setActionButtonHidden,
} from "../../../shared/actions/manager.actions";
import {
  resetIrrigationAdminDevicesApi,
  setIrrigationAdminFarmFilter,
  setIrrigationAdminCountryFilter,
  patchAdminDevice,
  fetchIrrigationAdminDevices,
} from "../../actions/admin.devices.actions";

import { NAMESPACE as namespace } from "../../reducer/admin.devices.reducer";

import { postAdminSyncWithPCApi } from "../../../../../shared/api/irrigation/devices/devices.api";
import { SnackbarContext } from "../../../../../shared/containers/SnackbarProvider/SnackbarProvider";
import { CountrySwitcher } from "../../../shared/containers/CountrySwitcher/CountrySwitcher";
import { FarmSwitcher } from "../../../shared/containers/FarmSwitcher/FarmSwitcher";
import IrrigationAdminAssignConfirm from "../../components/IrrigationAdminAssignConfirm/IrrigationAdminAssignConfirm";
import IrrigationAdminBulkActions from "../../components/IrrigationAdminBulkActions/IrrigationAdminBulkActions";
import IrrigationAdminTextFilter from "../../components/IrrigationAdminTextFilter/IrrigationAdminTextFilter";
import SyncWithPlantControl from "../../components/SyncWithPlantControl";
import {
  getIrrigationAdminFarmFilter,
  getIrrigationAdminCountryFilter,
  getIrrigationAdminSelectedOnPage,
} from "../../selectors/admin.devices.selector";
import IrrigationAdminDeviceEditDialog from "../IrrigationAdminDeviceEditDialog/IrrigationAdminDeviceEditDialog";
import IrrigationAdminFilter from "../IrrigationAdminFilter/IrrigationAdminFilter";
import { IrrigationAdminTable } from "../IrrigationAdminTable/IrrigationAdminTable";

import {
  AdminCountry,
  AdminFarm,
  IrrigationAdminDevice,
} from "../../admin.irrigation.types";

interface Props {
  langId: string;
}

export const IrrigationAdmin = ({ langId }: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const showSnackbar = useContext(SnackbarContext);

  const dispatch = useDispatch();
  const deviceTypes = useSelector(getDeviceTypes);
  const farm = useSelector(getIrrigationAdminFarmFilter);
  const country = useSelector(getIrrigationAdminCountryFilter);
  const selectedOnPage = useSelector(getIrrigationAdminSelectedOnPage);

  const [assignFarm, setAssignFarm] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [deviceIds, setDeviceIds] = useState<string[]>([]);
  const [farmState, setFarmState] = useState<AdminFarm>({} as AdminFarm);
  const [activeDevice, setActiveDevice] = useState<IrrigationAdminDevice>(
    {} as IrrigationAdminDevice,
  );

  useEffect(() => {
    dispatch(fetchDeviceTypes());
    dispatch(setActionButtonHidden(true));
    dispatch(setActionButtonEnabled(true));
    dispatch(setSensorsTabActive(false));
    dispatch(setIrrigationTabActive(true));

    return () => {
      dispatch(resetIrrigationAdminDevicesApi());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDialogOpen = (deviceIds: string[], farm: AdminFarm) => {
    setDeviceIds(deviceIds);
    setFarmState(farm);
    setAssignFarm(true);
  };

  const handleDialogClose = () => {
    setDeviceIds([]);
    setFarmState({} as AdminFarm);
    setAssignFarm(false);
  };

  const handleDialogAccept = () => {
    const payload = {
      farmId: farmState.id,
    };
    dispatch(patchAdminDevice(deviceIds, payload));
    handleDialogClose();
  };

  const handleSetFarmFilter = (farm: AdminFarm) => {
    if (farm.code === "all") {
      dispatch(setIrrigationAdminFarmFilter({} as AdminFarm));
    } else if (farm.code === "none") {
      dispatch(
        setIrrigationAdminFarmFilter({
          id: "0",
          name: intl.formatMessage({ id: "common.no-farm" }),
        }),
      );
    } else {
      dispatch(setIrrigationAdminFarmFilter(farm));
    }
  };

  const handleSetCountryFilter = (country: AdminCountry) => {
    if (!country.code) {
      dispatch(setIrrigationAdminCountryFilter({} as AdminCountry));
    } else {
      dispatch(setIrrigationAdminCountryFilter(country));
    }
  };

  const handleEditDialogOpen = (activeDevice: IrrigationAdminDevice) => {
    setActiveDevice(activeDevice);
    setShowEditDialog(true);
  };

  const handleEditDialogAccept = (newFarm: AdminFarm, newName: string) => {
    const payload = {
      farmId: newFarm.id,
      name: newName,
    };
    dispatch(patchAdminDevice([activeDevice.id], payload));
    handleEditDialogClose();
  };

  const handleEditDialogClose = () => {
    setActiveDevice({} as IrrigationAdminDevice);
    setShowEditDialog(false);
  };

  const handleSyncWithPC = () => {
    (dispatch(postAdminSyncWithPCApi()) as unknown as Promise<unknown>).then(
      (res: RSAAResultAction) => {
        if (res.error) {
          showSnackbar({
            message: <FormattedMessage id="IrrigationAdmin.syncWithPC.error" />,
            isError: true,
          });
          return;
        }
        dispatch(fetchIrrigationAdminDevices());
        showSnackbar({
          message: <FormattedMessage id="IrrigationAdmin.syncWithPC.success" />,
          isSuccess: true,
        });
      },
    );
  };

  return (
    <Grid alignItems="center" container spacing={2}>
      {showEditDialog && (
        <IrrigationAdminDeviceEditDialog
          device={activeDevice}
          onAccept={handleEditDialogAccept}
          onClose={handleEditDialogClose}
          opened={showEditDialog}
        />
      )}
      <Grid item xs={12}>
        <Grid container spacing={0}>
          <Grid className={classes.tableHeaderContainer} container spacing={2}>
            <Grid item lg={2} md={2} sm={3} xs={12}>
              <IrrigationAdminBulkActions
                onAssignDevicesToFarm={handleDialogOpen}
                selected={selectedOnPage}
              />
              <IrrigationAdminAssignConfirm
                deviceIds={deviceIds}
                farm={farmState}
                onAccept={handleDialogAccept}
                onClose={handleDialogClose}
                opened={assignFarm}
              />
            </Grid>
            <Grid item lg={4} md={3} sm={6} xs={12}>
              <IrrigationAdminTextFilter namespace={namespace} />
            </Grid>
            <Grid item lg={2} md={2} sm={3} xs={12}>
              <IrrigationAdminFilter
                deviceTypes={deviceTypes}
                langId={langId}
                namespace={namespace}
              />
            </Grid>
            <Grid
              className={classes.switchers}
              item
              lg={4}
              md={5}
              sm={12}
              xs={12}
            >
              <span className={classes.switch}>
                <FarmSwitcher
                  allFarmOption={true}
                  farm={farm}
                  onFarmSelect={handleSetFarmFilter}
                />
              </span>
              <span className={classes.switch}>
                <CountrySwitcher
                  country={country}
                  onCountrySelect={handleSetCountryFilter}
                />
              </span>
              <span className={classes.switch}>
                <SyncWithPlantControl syncWithPC={handleSyncWithPC} />
              </span>
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <IrrigationAdminTable
                onAssignDevicesToFarm={handleDialogOpen}
                openDeviceEditDialog={handleEditDialogOpen}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  tableHeaderContainer: {
    paddingBottom: 8,
    alignItems: "center",
  },
  switchers: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end",
    },
  },
  switch: {
    margin: "0px 3px",
  },
}));
