import React, { ReactNode, useMemo } from "react";

import { useIntl } from "react-intl";

import CfAutocomplete from "../../../../../../shared/components/common/CfAutocomplete/CfAutocomplete";

export enum BooleanSelectorEnum {
  YES = "yes",
  NO = "no",
}

export type BooleanSelectorType = {
  state?: boolean;
  label?: string;
};

interface Props {
  defaultValues?: BooleanSelectorType;
  label: ReactNode;
  onChange: (items: BooleanSelectorType) => void;
  testId?: string;
}

export const BOOLEAN_STATES = [BooleanSelectorEnum.YES, BooleanSelectorEnum.NO];

const BooleanSelector = ({
  defaultValues = {},
  label,
  onChange,
  testId = "boolean-filter",
}: Props) => {
  const intl = useIntl();

  const suggestions = useMemo(
    () =>
      BOOLEAN_STATES.map((s) => ({
        state: s === BooleanSelectorEnum.YES,
        label: intl.formatMessage({ id: `common.${s}` }),
      })),
    [intl],
  );

  const handleGetSelected = (
    option: BooleanSelectorType,
    value?: BooleanSelectorType | null,
  ) => option?.label === value?.label;

  return (
    <CfAutocomplete
      defaultValues={defaultValues}
      getSelected={handleGetSelected}
      id={testId}
      label={label}
      onChange={onChange}
      suggestions={suggestions}
      testId={testId}
    />
  );
};

export { BooleanSelector };
