import { AnyAction } from "redux";

import * as types from "./satelliteProducts.constants";

import { SatelliteProducts } from "./satelliteProducts.types";

const initialState: SatelliteProducts = {
  isFetching: false,
  error: undefined,
  items: {
    biomonitoringStatus: undefined,
    historyPotentialStatus: undefined,
    variableApplicationStatus: undefined,
    internalBiomonitoringStatus: undefined,
    managementZonesStatus: undefined,
  },
};

export default () =>
  (state = initialState, action: AnyAction) => {
    switch (action.type) {
      case types.GET_SATELLITE_PRODUCTS:
        return {
          ...state,
          isFetching: true,
          error: {},
        };
      case types.GET_SATELLITE_PRODUCTS_SUCCESS:
        return {
          ...state,
          isFetching: false,
          items: action.payload,
        };
      case types.GET_SATELLITE_PRODUCTS_ERROR:
        return {
          ...state,
          isFetching: false,
          error: action.payload,
        };
      case types.RESET_SATELLITE_PRODUCTS:
        return {
          isFetching: false,
          error: {},
          items: {},
        };
      default:
        return state;
    }
  };
