import React from "react";

import { Theme, TableBody } from "@mui/material";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import CfTableHead from "../../../common/components/CfTableHead/CfTableHead";
import CfErrorPage from "../../../shared/components/common/CfErrorPage/CfErrorPage";
import CfTableWrapper from "../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import { getColDesc } from "../../../shared/misc/helper";

import FleetConnectorRow from "./FleetConnectorRow";
import { useIntegrationListInit } from "./useIntegrationListInit";
import VarioDocRow from "./VarioDocRow";

const columns = {
  status: getColDesc(false, <FormattedMessage id="common.state" />, {
    width: 80,
    alignContent: "center",
  }),
  service: getColDesc(false, <FormattedMessage id="common.service" />),
  user_account: getColDesc(false, <FormattedMessage id="common.userAccount" />),
  description: getColDesc(false, <FormattedMessage id="common.description" />, {
    maxWidth: 200,
  }),
};

const IntegrationsList = () => {
  const classes = useStyles();
  const { apiError, hideFleetConnectorRow, hideVarioDocRow } =
    useIntegrationListInit();

  return (
    <CfErrorPage error={apiError}>
      <div className={classes.headerWrapper}>
        <Grid className={classes.header} container>
          <Grid className={classes.headerTitle} item>
            <FormattedMessage id="IntegrationSettings.title" />
          </Grid>
          <Grid className={classes.headerDescription} item>
            <FormattedMessage id="IntegrationSettings.description" />
          </Grid>
        </Grid>
      </div>
      <div className={classes.contentWrapper}>
        <Grid alignItems="center" container spacing={2}>
          <Grid item xs={12}>
            <CfTableWrapper>
              <CfTableHead columns={columns} />
              <TableBody>
                <FleetConnectorRow shouldBeHidden={hideFleetConnectorRow} />
                <VarioDocRow shouldBeHidden={hideVarioDocRow} />
              </TableBody>
            </CfTableWrapper>
          </Grid>
        </Grid>
      </div>
    </CfErrorPage>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  headerWrapper: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  header: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 500,
    margin: "10px 0px 15px 0px",
  },
  headerDescription: {
    fontSize: 13,
    fontWeight: 400,
    marginBottom: 20,
  },
  contentWrapper: {
    padding: theme.spacing(2),
  },
}));

export default IntegrationsList;
