import React, { useCallback, useEffect, useRef } from "react";

import { makeStyles } from "@mui/styles";
import { useFormikContext } from "formik";
import differenceBy from "lodash/differenceBy";

import { useToggle } from "../../../../../shared/hooks/useToggle";

import { PlantProtectionEffectivenessDialog } from "./PlantProtectionEffectivenessDialog";
import { PlantProtectionEffectivenessList } from "./PlantProtectionEffectivenessList";

import { PlantProtectionEffectiveness } from "../../../../../shared/api/agroevidence/agroevidence.types";
import { InitialParcelToAdd } from "../../../ActionOthers/actionOther.types";
import {
  ActionEphFormValues,
  EphPlantProtectionsType,
  PlantProtectionEffectivenessFormValues,
} from "../../actionEph.types";

type Props = {
  parcels: InitialParcelToAdd[];
  isExisting: boolean;
  plantProtection: EphPlantProtectionsType;
  index: number;
  isEditing: boolean;
};

export const PlantProtectionEffectivenessControl = ({
  index,
  isEditing = false,
  isExisting,
  parcels,
  plantProtection,
}: Props) => {
  const classes = useStyles();

  const { setFieldValue } = useFormikContext<ActionEphFormValues>();

  const {
    on: showEditDialog,
    setOff: handleDialogClose,
    setOn: handleDialogOpen,
  } = useToggle();

  const getEffectiveness = useCallback(
    (parcels: InitialParcelToAdd[]) =>
      parcels.map((parcel) => ({
        parcelName: parcel.localName,
        parcelId: parcel.id,
        value: isExisting
          ? PlantProtectionEffectiveness.NOT_SPECIFIED
          : PlantProtectionEffectiveness.YES,
      })),
    [isExisting],
  );

  const prevParcels = useRef(parcels);

  useEffect(() => {
    if (
      parcels.length > prevParcels.current.length &&
      plantProtection.effectiveness?.length > 0
    ) {
      const newParcels = differenceBy(parcels, prevParcels.current, "id");
      const newEffectiveness = getEffectiveness(newParcels);

      setFieldValue(`plantProtections[${index}].effectiveness`, [
        ...plantProtection.effectiveness,
        ...newEffectiveness,
      ]);
    }
    if (
      parcels.length < prevParcels.current.length &&
      plantProtection.effectiveness?.length > 0
    ) {
      const removedParcel = differenceBy(prevParcels.current, parcels, "id");
      const updatedEffectiveness = plantProtection.effectiveness.filter(
        (item) => item.parcelId !== removedParcel[0].id,
      );
      setFieldValue(
        `plantProtections[${index}].effectiveness`,
        updatedEffectiveness,
      );
    }
    prevParcels.current = parcels;
  }, [
    getEffectiveness,
    index,
    parcels,
    plantProtection.effectiveness,
    setFieldValue,
  ]);

  const handleDialogAccept = (
    values: PlantProtectionEffectivenessFormValues,
  ) => {
    setFieldValue(
      `plantProtections[${index}].effectiveness`,
      values.effectiveness,
    );
    handleDialogClose();
  };

  return (
    <div>
      <a
        className={classes.link}
        data-test="effectiveness"
        href="#"
        onClick={handleDialogOpen}
      >
        <PlantProtectionEffectivenessList
          isExisting={isExisting}
          parcels={parcels}
          plantProtection={plantProtection}
        />
      </a>
      <PlantProtectionEffectivenessDialog
        getEffectiveness={getEffectiveness}
        handleAccept={handleDialogAccept}
        handleClose={handleDialogClose}
        isEditing={isEditing}
        opened={showEditDialog}
        parcels={parcels}
        plantProtection={plantProtection}
      />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  link: {
    "&:hover": {
      textDecoration: "underline",
      "&:focus": {
        textDecoration: "underline",
      },
    },
    "&:focus": {
      textDecoration: "none",
    },
  },
}));
