import React from "react";

import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { FormattedMessage } from "react-intl";

type Props = {
  count?: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    evt: React.MouseEvent<HTMLButtonElement>,
    page: number,
  ) => void;
  onRowsPerPageChange: (
    evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
};

const CfTableFooter = ({
  count = 0,
  onPageChange,
  onRowsPerPageChange,
  page,
  rowsPerPage,
}: Props) => {
  const rowsPerPageOptions = [10, 25, 50, 100];

  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          count={count}
          data-test="table-pagination"
          labelRowsPerPage={<FormattedMessage id="common.rowsPerPage" />}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          labelDisplayedRows={(setup) => (
            <FormattedMessage
              id="common.currentPage"
              values={{
                from: setup.from,
                to: setup.to,
                count: setup.count,
              }}
            />
          )}
        />
      </TableRow>
    </TableFooter>
  );
};

export default CfTableFooter;
