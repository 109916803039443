import React, { useEffect } from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { Theme } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import { History } from "history";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  getIsFetching,
  getTotalCount,
} from "../../../../../shared/api/stores/stores/stores.selectors";
import {
  getStoreItems,
  getStoresMaterialType,
  getStoresShowNullValues,
  getStoresTextFilter,
  getStoresPage,
  getStoresOrder,
  getStoresRowsPerPage,
} from "../../selectors/stores.selectors";

import { fetchStores } from "../../actions/stores.actions";

import { NAMESPACE as namespace } from "../../reducer/stores.reducer";

import CfTableFooter from "../../../../../common/components/CfTableFooter/CfTableFooter";
import { useCfTableFooter } from "../../../../../common/components/CfTableFooter/useCfTableFooter";
import CfTableHead from "../../../../../common/components/CfTableHead/CfTableHead";
import { useCfTableHead } from "../../../../../common/components/CfTableHead/useCfTableHead";
import CfFormattedNumber from "../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import CfTableBodyEmpty from "../../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableBodyLoader from "../../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader";
import CfTableCell from "../../../../../shared/components/tables/CfTableCell/CfTableCell";
import CfTableCellNumber from "../../../../../shared/components/tables/CfTableCellNumber/CfTableCellNumber";
import CfTableIconButton from "../../../../../shared/components/tables/CfTableIconButton/CfTableIconButton";
import CfTableWrapper from "../../../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import { getColDesc } from "../../../../../shared/misc/helper";
import { AnyTodo } from "../../../../../types";

const columns = {
  material: getColDesc(
    true,
    <span style={{ paddingLeft: 15 }}>
      <FormattedMessage id="Stores.material-name" />
    </span>,
    { width: "44%" },
  ),
  availableCount: getColDesc(
    false,
    <FormattedMessage id="Stores.current-amount" />,
    { textAlign: "right", width: "16%" },
    undefined,
    true,
  ),
  unit: getColDesc(false, <FormattedMessage id="common.unit" />, {
    textAlign: "right",
    width: "8%",
  }),
  planned: getColDesc(
    false,
    <FormattedMessage id="Stores.planned-to-apply" />,
    { textAlign: "right", width: "16%" },
    undefined,
    true,
  ),
  change: getColDesc(
    false,
    <div style={{ padding: "0 5px", textAlign: "center" }}>
      <FormattedMessage id="Stores.add-remove-amount" />
    </div>,
    { width: "8%" },
  ),
  delete: getColDesc(
    false,
    <div style={{ padding: "0 5px", textAlign: "center" }}>
      <FormattedMessage id="common.delete" />
    </div>,
    { width: "8%" },
  ),
};

type Props = {
  history: History;
  farmId: string;
  onUpdateStoreClick: (storeItem: unknown) => void;
  onDeleteStoreClick: (storeItem: unknown) => void;
};

const StoresTable = ({
  farmId,
  history,
  onDeleteStoreClick,
  onUpdateStoreClick,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const items: AnyTodo[] = useSelector(getStoreItems);
  const materialTypeId = useSelector(getStoresMaterialType);
  const showNullValues = useSelector(getStoresShowNullValues);
  const textFilter = useSelector(getStoresTextFilter);
  const isFetching = useSelector(getIsFetching);
  const count = useSelector(getTotalCount);
  const page = useSelector(getStoresPage);
  const order = useSelector(getStoresOrder);
  const rowsPerPage = useSelector(getStoresRowsPerPage);

  const { onPageChange, onRowsPerPageChange } = useCfTableFooter(namespace);

  const { onSelect, onSort } = useCfTableHead(namespace);

  useEffect(() => {
    dispatch(fetchStores());
  }, [
    page,
    rowsPerPage,
    materialTypeId,
    showNullValues,
    order,
    textFilter,
    dispatch,
  ]);

  return (
    <CfTableWrapper>
      <CfTableHead
        columns={columns}
        onSelect={onSelect}
        onSort={onSort}
        order={order}
        orderBy="material"
      />
      {isFetching && <CfTableBodyLoader columns={columns} />}

      {items.length ? (
        <TableBody>
          {items.map((item) => (
            <TableRow
              className={classes.pointer}
              hover
              key={item.id}
              onClick={() => history.push(`/farm/${farmId}/store/${item.id}`)}
            >
              <CfTableCell name="material">
                <span className={classes.name}>{item.material.name}</span>
              </CfTableCell>
              <CfTableCell name="availableCount">
                <CfTableCellNumber
                  negative={item.currentBalance < 0}
                  padding={true}
                >
                  <CfFormattedNumber
                    decimalDigits={4}
                    value={item.currentBalance}
                  />
                </CfTableCellNumber>
              </CfTableCell>
              <CfTableCell name="unit">
                <CfTableCellNumber>
                  <FormattedMessage id={`unit.${item.unitId}`} />
                </CfTableCellNumber>
              </CfTableCell>
              <CfTableCell name="planned">
                <CfTableCellNumber
                  negative={item.plannedConsumption < 0}
                  padding={true}
                >
                  <CfFormattedNumber value={item.plannedConsumption} />
                </CfTableCellNumber>
              </CfTableCell>
              <CfTableIconButton
                name="change"
                onClick={(evt) => {
                  onUpdateStoreClick(item);
                  evt.stopPropagation();
                }}
              >
                <AddCircleIcon className={classes.updateIcon} />
              </CfTableIconButton>
              <CfTableIconButton
                name="delete"
                onClick={(evt) => {
                  onDeleteStoreClick(item);
                  evt.stopPropagation();
                }}
              >
                <DeleteIcon className={classes.deleteIcon} />
              </CfTableIconButton>
            </TableRow>
          ))}
        </TableBody>
      ) : (
        <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
      )}
      <CfTableFooter
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </CfTableWrapper>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  deleteIcon: {
    color: theme.palette.action.active,
  },
  updateIcon: {
    color: theme.palette.action.active,
  },
  name: {
    fontWeight: 500,
    paddingLeft: 15,
  },
  pointer: {
    cursor: "pointer",
  },
}));

export default StoresTable;
