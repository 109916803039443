import React from "react";

import TableCell from "@mui/material/TableCell";

interface Props {
  classes?: Record<string, string>;
  className?: string;
  children: React.ReactNode;
  name: string;
  colSpan?: number;
  rowSpan?: number;
  size?: "small" | "medium";
}

const CfTableCell = (props: Props) => {
  const {
    children,
    className,
    classes,
    colSpan = 1,
    name,
    rowSpan = 1,
    size = "small",
  } = props;

  return (
    <TableCell
      classes={classes}
      className={className}
      colSpan={colSpan}
      key={name}
      rowSpan={rowSpan}
      size={size}
    >
      {children}
    </TableCell>
  );
};

export default CfTableCell;
