import React from "react";

import moment from "moment";
import { createRoot } from "react-dom/client";

import Localization from "../../shared/services/Localization.service";

import LoginWrapper from "./containers/LoginWrapper/LoginWrapper";

LoginController.$inject = [
  "$element",
  "$state",
  "$scope",
  "$rootScope",
  "$stateParams",
  "Auth",
];

export default function LoginController(
  $element,
  $state,
  $scope,
  $rootScope,
  $stateParams,
  Auth,
) {
  let root;
  const props = {
    isApiError: $stateParams.isApiError,
    ngLogin,
    ngRedirectToSignup,
    ngRedirectToReset,
    langId: null,
  };

  const unlistenTranslateChange = $rootScope.$on(
    "$translateChangeSuccess",
    () => {
      renderWithLang();
    },
  );

  $scope.$on("$destroy", () => {
    unlistenTranslateChange();
    root.unmount();
  });

  this.$onInit = function () {
    Auth.clearAllUserData();
    renderWithLang();
  };

  function ngLogin(credentials) {
    return Auth.login(credentials).then(() => {
      $state.go("farm");
    });
  }

  function ngRedirectToSignup() {
    $state.go("signup");
  }

  function ngRedirectToReset() {
    $state.go("reset");
  }

  function renderWithLang() {
    const langId = Localization.getLang();
    moment.locale(langId);
    render();
  }

  function render() {
    // hack for root element focus causing inputs losing focus after initial render
    $element[0].click();
    root = createRoot($element[0]);
    root.render(<LoginWrapper {...props} />);
  }
}
