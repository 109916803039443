import {
  LogbookAggregatedItemTo,
  LogbookItemTo,
} from "../../../generated/api/telematics";

const isAggregatedDrive = (
  drive: LogbookAggregatedItemTo | LogbookItemTo,
): drive is LogbookAggregatedItemTo => "duration" in drive;

export { isAggregatedDrive };
