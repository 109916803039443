import React, { Component, Fragment } from "react";

import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import { compose } from "react-recompose";

import ExportsList from "../../../shared/components/VariableActionExportsList/ExportsList";
import actionExpenseControl from "../../../shared/hocs/actionExpenseControl";
import VrsExpenseCard from "../VrsExpenseCard/VrsExpenseCard";
import VrsExpenseDialog from "../VrsExpenseDialog/VrsExpenseDialog";

class VrsExpenseControl extends Component {
  componentDidUpdate() {}

  render() {
    const {
      currMapZones,
      expense,
      fields,
      formName,
      isAdding,
      isEditing,
      isOpen,
      langId,
      onAccept,
      onClose,
      onExport,
      onMapClick,
      onRemove,
      satellite,
    } = this.props;
    return (
      <Fragment>
        <VrsExpenseDialog
          expense={expense}
          isAdding={isAdding}
          isEditing={isEditing}
          isOpen={isOpen}
          onAccept={onAccept}
          onClose={onClose}
          rootState={formName}
        />
        {satellite &&
          fields.map((fieldName, index) => {
            const field = fields.get(index);
            const applicationId = field?.variableExpense?.id;
            return (
              <Fragment key={fieldName}>
                <VrsExpenseCard
                  currMapZones={currMapZones}
                  expense={field}
                  formName={formName}
                  index={index}
                  isEditing={isEditing}
                  langId={langId}
                  onMapClick={onMapClick}
                  onRemove={() => onRemove(index)}
                  isFetching={
                    !field.variableExpense ||
                    !!field.variableExpense.isFetching ||
                    !!field.variableExpense.isError
                  }
                />
                <Grid style={{ margin: "48px 0" }}>
                  <ExportsList
                    applicationId={applicationId}
                    onExport={onExport}
                  />
                </Grid>
              </Fragment>
            );
          })}
      </Fragment>
    );
  }
}

VrsExpenseControl.propTypes = {
  fields: PropTypes.object.isRequired,
  formName: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isAdding: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  satellite: PropTypes.object,
  expense: PropTypes.object,
  onMapClick: PropTypes.func.isRequired,
  langId: PropTypes.string.isRequired,
  currMapZones: PropTypes.array,
  onExport: PropTypes.func.isRequired,
};

VrsExpenseControl.defaultProps = {
  satellite: null,
  expense: null,
  currMapZones: null,
};

export default compose(actionExpenseControl())(VrsExpenseControl);
