import React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import IconButton from "@mui/material/IconButton";
import { FormattedMessage } from "react-intl";

import CfChip from "../../../../shared/components/common/CfChip/CfChip";

import { useReportStyles } from "./style";

type Props = {
  children: React.ReactNode;
  header: React.ReactNode;
  id: string;
  count?: number;
};

const Report = ({ children, count = 0, header, id }: Props) => {
  const classes = useReportStyles();

  const message = <FormattedMessage id="Report.selected" values={{ count }} />;
  return (
    <Accordion
      classes={{ root: classes.accordion, expanded: classes.expanded }}
      data-test={id}
      data-testid={id}
    >
      <AccordionSummary
        id={id}
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
        }}
        expandIcon={
          <IconButton className={classes.expandIcon}>
            <ExpandMoreIcon />
          </IconButton>
        }
      >
        <span className={classes.header}>
          <span className={classes.label}>{header}</span>
          {count > 0 && <CfChip message={message} />}
        </span>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default Report;
