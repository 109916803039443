/**
 * Created by ondrejzvara on 27.3.17.
 */

import angular from "angular";

import logger from "./logger/logger.module";
import auth from "./auth/auth.module";
import storage from "./storage/storage.module";
import api from "./rest/api.module";
import spinner from "./spinner/spinner.module";

export default angular.module("app.common.services", [
  logger,
  storage,
  auth,
  api,
  spinner,
]).name;
