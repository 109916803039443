import React, { Fragment } from "react";

import { FormattedMessage } from "react-intl";

import { WarningMessageBox } from "../../../../core/parcels/shared/containers/WarningMessageBox";

import {
  ActionSowingDetailTo,
  ActionOtherDetailTo,
  ActionRevenueDetailTo,
  ActionType,
} from "../../../../shared/api/agroevidence/agroevidence.types";

interface Props {
  action: ActionRevenueDetailTo | ActionSowingDetailTo | ActionOtherDetailTo;
}

export function CoreActionWarningMessage({ action }: Readonly<Props>) {
  return (
    <Fragment>
      {action?.isEagriFromCoreParcels && (
        <Fragment>
          {action?.actionType === ActionType.SOWING && (
            <WarningMessageBox
              text={
                <FormattedMessage id="CoreActionWarningMessage.sowing.message.text" />
              }
              title={
                <FormattedMessage id="CoreActionWarningMessage.sowing.message.title" />
              }
            />
          )}
          {action?.actionType === ActionType.HARVEST && (
            <WarningMessageBox
              text={
                <FormattedMessage id="CoreActionWarningMessage.harvest.message.text" />
              }
              title={
                <FormattedMessage id="CoreActionWarningMessage.harvest.message.title" />
              }
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
