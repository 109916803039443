const PREFIX = "PRECISION_MONITORING";

export const GET_MONITORING_DATA_REQUEST = `${PREFIX}/GET_MONITORING_DATA_REQUEST`;
export const GET_MONITORING_DATA_SUCCESS = `${PREFIX}/GET_MONITORING_DATA_SUCCESS`;
export const GET_MONITORING_DATA_ERROR = `${PREFIX}/GET_MONITORING_DATA_ERROR`;
export const RESET_MONITORING_DATA = `${PREFIX}/RESET_MONITORING_DATA`;

export const GET_MONITORING_CROP_INTERVALS_REQUEST = `${PREFIX}/GET_MONITORING_CROP_INTERVALS_REQUEST`;
export const GET_MONITORING_CROP_INTERVALS_SUCCESS = `${PREFIX}/GET_MONITORING_CROP_INTERVALS_SUCCESS`;
export const GET_MONITORING_CROP_INTERVALS_ERROR = `${PREFIX}/GET_MONITORING_CROP_INTERVALS_ERROR`;
export const RESET_MONITORING_CROP_INTERVALS = `${PREFIX}/RESET_MONITORING_CROP_INTERVALS`;

export const GET_BIOMONITORING_INDEX_RECORDS_REQUEST = `${PREFIX}/GET_BIOMONITORING_INDEX_RECORDS_REQUEST`;
export const GET_BIOMONITORING_INDEX_RECORDS_SUCCESS = `${PREFIX}/GET_BIOMONITORING_INDEX_RECORDS_SUCCESS`;
export const GET_BIOMONITORING_INDEX_RECORDS_ERROR = `${PREFIX}/GET_BIOMONITORING_INDEX_RECORDS_ERROR`;
export const RESET_BIOMONITORING_INDEX_RECORDS = `${PREFIX}/RESET_BIOMONITORING_INDEX_RECORDS`;
