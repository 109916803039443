import React, { FC, useState, useMemo } from "react";

import { Theme } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import { FormattedMessage } from "react-intl";

import CfTableHead from "../../../common/components/CfTableHead/CfTableHead";
import CfTableWrapper from "../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import { getColDesc } from "../../../shared/misc/helper";
import DoubleLinedHeader from "../DoubleLinedHeader/DoubleLinedHeader";

import RideRow from "./RideRow";

import { LogbookAggregatedItemTo } from "../../../shared/api/telematics/telematics.types";

interface Props {
  data: LogbookAggregatedItemTo[];
  isInit?: boolean;
  opened: boolean;
}

const columns = {
  duration: getColDesc(
    true,
    <span style={{ marginLeft: 16 }}>
      <FormattedMessage id="TelematicsAggregations.list.duration" />
    </span>,
  ),
  "driver.name": getColDesc(
    true,
    <DoubleLinedHeader
      primaryId="TelematicsAggregations.list.driverName"
      secondaryId="TelematicsAggregations.list.driverId"
    />,
  ),
  operation: getColDesc(
    true,
    <FormattedMessage id="TelematicsAggregations.list.operation" />,
  ),
  productionOperation: getColDesc(
    false,
    <FormattedMessage id="TelematicsAggregations.list.productionOperation" />,
  ),
  parcel: getColDesc(
    false,
    <DoubleLinedHeader
      primaryId="TelematicsMachines.list.parcel"
      secondaryId="TelematicsMachines.list.crop"
    />,
  ),
  area: getColDesc(
    false,
    <DoubleLinedHeader
      primaryId="TelematicsAggregations.list.operationArea"
      secondaryId="TelematicsAggregations.list.parcelArea"
    />,
  ),
  distance: getColDesc(
    false,
    <FormattedMessage id="TelematicsAggregations.list.distance" />,
  ),
  additionalEquipment: getColDesc(
    false,
    <FormattedMessage id="TelematicsAggregations.list.additionalEquipment" />,
  ),
  approvals: getColDesc(false, null),
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: (opened) => opened && theme.palette.grey[100],
  },
  paper: {
    boxShadow: "none",
    borderBotton: "none",
  },
}));

const MachineRidesList: FC<Props> = ({ data, isInit, opened }) => {
  const [orderBy, setOrderBy] = useState("");
  const [ascOrdered, setAscOrdered] = useState(true);

  const classes = useStyles(opened);

  const handleSortClick = (colId: string) => () => {
    if (colId === orderBy) {
      setAscOrdered((prevState) => !prevState);
    } else {
      setOrderBy(colId);
      setAscOrdered(true);
    }
  };

  const sortedData = useMemo(() => {
    if (!orderBy) return data;
    return _.orderBy(data, orderBy, ascOrdered ? "asc" : "desc");
  }, [data, ascOrdered, orderBy]);

  return (
    <CfTableWrapper
      customClasses={{ table: classes.container, paper: classes.paper }}
      testId="aggregated-machines-table"
    >
      <CfTableHead
        columns={columns}
        customSortHandler={handleSortClick}
        items={sortedData}
        order={ascOrdered ? "asc" : "desc"}
        orderBy={orderBy}
      />
      <TableBody className={classes.container}>
        {sortedData.map((drive) => (
          <RideRow data={drive} isInit={isInit} key={drive.id} />
        ))}
      </TableBody>
    </CfTableWrapper>
  );
};

export default MachineRidesList;
