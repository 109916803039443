import React, { useState } from "react";

import CloseIcon from "@mui/icons-material/Clear";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormLabel,
  Grid,
  IconButton,
  List,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import IrrigationCounter from "../../IrrigationPlanCalendarModal/components/IrrigationCounter";
import IrrigationValve from "../../IrrigationPlanCalendarModal/components/IrrigationValve";

import {
  payloadPrepareStartManualAction,
  payloadPrepareStopManualAction,
} from "./IrrigationManualAction.helper";

import {
  Valve,
  ValveManualAction,
} from "../../../../shared/api/irrigation/devices/devices.types";

interface Props {
  deviceName: string;
  deviceValves: Valve[];
  onClose: () => void;
  onSubmit: (startManualAction: ValveManualAction[]) => void;
  showDialog: boolean;
}

export const IrrigationManualActionModal = ({
  deviceName,
  deviceValves,
  onClose,
  onSubmit,
  showDialog,
}: Props) => {
  const classes = useStyles();

  const [currentValves, setCurrentValves] = useState(deviceValves);
  const [generalCount, setGeneralCount] = useState(0);
  const [isGeneralCountActive, setIsGeneralCountActive] = useState(false);
  const [showManualActionError, setShowManualActionError] = useState(false);

  const generalCountIncrement = () => {
    const newGeneralCount = generalCount + 60;
    setCurrentValves((prevState) =>
      prevState.map((valve) => ({
        ...valve,
        durationSeconds: newGeneralCount,
      })),
    );
    setGeneralCount(newGeneralCount);
    setShowManualActionError(false);
    setIsGeneralCountActive(true);
  };

  const generalCountDecrement = () => {
    const newGeneralCount = Math.max(generalCount - 60, 0);
    setCurrentValves((prevState) =>
      prevState.map((valve) => ({
        ...valve,
        durationSeconds: newGeneralCount,
      })),
    );
    setGeneralCount(newGeneralCount);
    setShowManualActionError(false);
    setIsGeneralCountActive(true);
  };

  const onChangeDurations = (idVal: number, durationSeconds: number) => {
    const tempValves = currentValves;
    tempValves[idVal].durationSeconds = durationSeconds;
    setCurrentValves(tempValves);
    setIsGeneralCountActive(false);
    setShowManualActionError(false);
  };

  const handleTurnOff = () => {
    const stopManualAction = payloadPrepareStopManualAction(deviceValves, true);
    onSubmit(stopManualAction);
  };

  const handleSubmit = () => {
    const startManualAction = payloadPrepareStartManualAction(currentValves);
    validationHandleSubmit(startManualAction, onSubmit);
  };

  const validationHandleSubmit = (
    event: ValveManualAction[],
    onSubmit: (event: ValveManualAction[]) => void,
  ) => {
    const doesNotHaveAnyDurationsSet = !event.length;
    if (doesNotHaveAnyDurationsSet) {
      setShowManualActionError(true);
    } else {
      onSubmit(event);
    }
  };

  return (
    <Dialog data-test="dialog" open={showDialog}>
      <DialogContent>
        <Grid
          alignItems="baseline"
          container
          justifyContent="space-between"
          spacing={1}
        >
          <Grid item>
            <FormLabel className={classes.title} component="legend">
              <FormattedMessage id="Irrigation.valveManualActionModal.title" />
            </FormLabel>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="close"
              className={classes.closeIcon}
              onClick={onClose}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid
          alignItems="baseline"
          container
          justifyContent="flex-start"
          spacing={1}
        >
          <Grid item>
            <FormLabel className={classes.label} component="legend">
              <FormattedMessage id="Irrigation.planCalendarModal.lengthEachValve" />
            </FormLabel>
          </Grid>
          <Grid item>
            <IrrigationCounter
              active={isGeneralCountActive}
              checked={true}
              count={generalCount}
              handleDecrement={generalCountDecrement}
              handleIncrement={generalCountIncrement}
            />
          </Grid>
        </Grid>
        <Grid
          alignItems="stretch"
          container
          direction="column"
          justifyContent="flex-start"
          spacing={1}
        >
          <Grid item>
            <div className={classes.deviceName}>{deviceName}</div>
          </Grid>
          <Grid item>
            <List className={classes.list}>
              {currentValves.map((valve, id) => (
                <IrrigationValve
                  handleChangeDurations={onChangeDurations}
                  idValve={id}
                  isManualAction
                  key={valve.valveId}
                  valve={valve}
                />
              ))}
            </List>
          </Grid>
        </Grid>
        {showManualActionError && (
          <div className={classes.dialogCheckError}>
            <FormattedMessage id="Irrigation.valveManualActionModal.errorMsg" />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Grid alignItems="center" container justifyContent="space-between">
          <Grid item xs={4}>
            <Button
              className={classes.button}
              color="error"
              id="turnOff"
              onClick={handleTurnOff}
              variant="contained"
            >
              <FormattedMessage id="Irrigation.valveManualActionModal.off" />
            </Button>
          </Grid>
          <Grid
            container
            direction="row"
            item
            justifyContent="flex-end"
            spacing={1}
            xs={8}
          >
            <Grid item>
              <Button
                className={classes.button}
                id="reset"
                onClick={onClose}
                type="reset"
                variant="contained"
              >
                <FormattedMessage id="common.cancel" />
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.button}
                color="primary"
                id="run"
                onClick={handleSubmit}
                type="submit"
                variant="contained"
              >
                <FormattedMessage id="Irrigation.valveManualActionModal.run" />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    display: "flex",
    justifyContent: "left",
    color: theme.palette.grey[500],
    fontWeight: "normal",
    fontSize: "1.125rem",
    lineHeight: "1.3125rem",
    marginBottom: "1.75rem",
  },
  closeIcon: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  label: {
    fontWeight: "400",
    fontSize: "0.75rem",
    lineHeight: "1.0625rem",
    color: theme.palette.grey[500],
    marginBottom: "1.75rem",
  },
  deviceName: {
    fontWeight: "500",
    fontSize: "0.875rem",
    lineHeight: "1.0625rem",
    marginBottom: "1.25rem",
  },
  list: {
    padding: 0,
  },
  button: {
    fontWeight: "500",
    fontSize: "0.75rem",
    lineHeight: "0.875rem",
    margin: "0.3125rem 0rem",
  },
  dialogCheckError: {
    color: theme.palette.error.main,
    fontSize: "0.75rem",
  },
}));
