import React, { useEffect, useRef } from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import CfTextFilter from "../../../../../common/components/CfTextFilter/CfTextFilter";
import { useCfTextFilter } from "../../../../../common/components/CfTextFilter/useCfTextFilter";

type Props = {
  materialTypeId?: string;
  textFilter?: string;
  namespace: string;
  handleTextFilterReset: () => void;
};

const StoreTextFilter = ({
  handleTextFilterReset,
  materialTypeId,
  namespace,
  textFilter,
}: Props) => {
  const classes = useStyles();
  const { onChange } = useCfTextFilter(namespace);

  const prevMaterialTypeIdRef = useRef<string | undefined>();
  useEffect(() => {
    if (textFilter && materialTypeId !== prevMaterialTypeIdRef.current) {
      handleTextFilterReset();
    }
    prevMaterialTypeIdRef.current = materialTypeId;
  }, [textFilter, materialTypeId, handleTextFilterReset]);

  const resolvePlaceholder = () => {
    switch (materialTypeId) {
      case "FR":
        return "Stores.fert-name";
      case "CH":
        return "Stores.por-name";
      case "SD":
        return "Stores.seed-name";
      default:
        return "Stores.material-name";
    }
  };

  return (
    <div className={classes.wrapper}>
      <CfTextFilter
        initialValue={textFilter}
        name="stores-text-filter"
        onChange={onChange}
        translId={resolvePlaceholder()}
      />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    margin: 0,
  },
  [theme.breakpoints.up("sm")]: {
    wrapper: {
      margin: theme.spacing(1),
    },
  },
}));

export default StoreTextFilter;
