import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { getMonitoringDataError } from "../../../../../shared/api/sentinel/monitoring/monitoring.selectors";
import {
  getIndex,
  getMonitoringStatus,
} from "../../../selectors/monitoring.selectors";

import {
  setMonitoringIndex,
  setMonitoringInterval,
} from "../../../actions/monitoring.actions";

import { LANGUAGE_ID } from "../../../../../shared/lang/lang.constants";

import CfErrorPage from "../../../../../shared/components/common/CfErrorPage/CfErrorPage";
import useWidth from "../../../../../shared/hooks/useWidth";
import BioMonitoringStatusWrapper from "../../../components/BioMonitoring/BioMonitoringStatusWrapper";
import { OverallImagesError } from "../../../services/BioMonitoringImageError.services";
import { MonitoringCropIntervals } from "../../BioMonitoring";
import { BiomonitoringIndexTypeSection } from "../BiomonitoringIndexTypeSection/BiomonitoringIndexTypeSection";
import { CropHarvestedError } from "../CropHarvestedError";
import { HeaderBar } from "../HeaderBar";
import { IndexTypeSection } from "../IndexTypeSection/IndexTypeSection";

import { BiomonitoringIndexType } from "../../../../../shared/api/satellite/satellite.types";

type Props = {
  langId: LANGUAGE_ID;
  ngRedirectToSowing: (parcelId: string[]) => void;
  parcelId: string;
};

const BiomonitoringWrapper = ({
  langId,
  ngRedirectToSowing,
  parcelId,
}: Props) => {
  const dispatch = useDispatch();
  const width = useWidth();
  const index = useSelector(getIndex);
  const error = useSelector(getMonitoringDataError);
  const monitoringStatus = useSelector(getMonitoringStatus);

  const [cropHarvestedError, setCropHarvestedError] = React.useState<
    OverallImagesError | null | undefined
  >(undefined);

  const [cropIntervals, setCropIntervals] = React.useState<
    MonitoringCropIntervals[]
  >([]);

  const isBiomonitoringIndexType =
    index === BiomonitoringIndexType.SAVI ||
    index === BiomonitoringIndexType.RGB;

  return (
    <CfErrorPage error={error}>
      <BioMonitoringStatusWrapper
        langId={langId}
        monitoringStatus={monitoringStatus}
        width={width}
      >
        <>
          {cropHarvestedError && (
            <CropHarvestedError
              cropHarvestedErrorIcon={cropHarvestedError.icon}
              cropHarvestedErrorId={cropHarvestedError.id}
              ngRedirectToSowing={ngRedirectToSowing}
              parcelId={parcelId}
            />
          )}
          <HeaderBar
            cropIntervals={cropIntervals}
            index={index}
            setMonitoringIndex={(i) => dispatch(setMonitoringIndex(i))}
            setMonitoringInterval={(from, to) =>
              dispatch(setMonitoringInterval(from, to))
            }
          />

          {isBiomonitoringIndexType ? (
            <BiomonitoringIndexTypeSection
              index={index}
              parcelId={parcelId}
              setCropHarvestedError={setCropHarvestedError}
              setCropIntervals={setCropIntervals}
            />
          ) : (
            <IndexTypeSection
              index={index}
              parcelId={parcelId}
              setCropHarvestedError={setCropHarvestedError}
              setCropIntervals={setCropIntervals}
            />
          )}
        </>
      </BioMonitoringStatusWrapper>
    </CfErrorPage>
  );
};

export { BiomonitoringWrapper };
