import React, { FC } from "react";

import { makeStyles } from "@mui/styles";

import CfTextFilter from "../../../../../common/components/CfTextFilter/CfTextFilter";
import { useCfTextFilter } from "../../../../../common/components/CfTextFilter/useCfTextFilter";

const useStyles = makeStyles(() => ({
  wrapper: {
    margin: 0,
  },
}));

interface Props {
  namespace: string;
}

const IrrigationAdminTextFilter: FC<Props> = ({ namespace }) => {
  const classes = useStyles();
  const { onChange } = useCfTextFilter(namespace);

  return (
    <div className={classes.wrapper}>
      <CfTextFilter
        name="irrigation-admin-text-filter"
        onChange={onChange}
        translId="IrrigationAdmin.textFilter"
      />
    </div>
  );
};

export default IrrigationAdminTextFilter;
