import { get } from "lodash";
import { AnyAction } from "redux";

import * as types from "./admin.constants";

import { FarmStatistics, ParcelDataTo } from "../../satellite/satellite.types";

export interface SentinelAdminApiState {
  error: unknown;
  isFetching: boolean;
  items: ParcelDataTo[];
  totalCount: number;
  itemIsUpdating: boolean;

  statsIsFetching: boolean;
  stats?: FarmStatistics;
}

const initialState: SentinelAdminApiState = {
  isFetching: false,
  error: {},
  items: [],
  totalCount: 0,
  itemIsUpdating: false,

  statsIsFetching: false,
  stats: undefined,
};

export default (reducerContext: string) =>
  (state = initialState, action: AnyAction) => {
    const actionContext = get(action, "meta.context");
    if (actionContext && actionContext !== reducerContext) return state;

    switch (action.type) {
      case types.GET_ADMIN_PARCELS:
        return {
          ...state,
          isFetching: true,
          error: {},
        };
      case types.GET_ADMIN_PARCELS_SUCCESS:
        return {
          ...state,
          isFetching: false,
          items: action.payload,
          totalCount: Number(action.meta.headers.get("X-Total-Count")),
        };
      case types.GET_ADMIN_PARCELS_ERROR:
        return {
          ...state,
          isFetching: false,
          error: action.payload,
        };
      case types.RESET_ADMIN_PARCELS:
        return initialState;

      case types.GET_ADMIN_STATS:
        return {
          ...state,
          statsIsFetching: true,
        };
      case types.GET_ADMIN_STATS_SUCCESS:
        return {
          ...state,
          statsIsFetching: false,
          stats: action.payload,
        };
      case types.GET_ADMIN_STATS_ERROR:
        return {
          ...state,
          statsIsFetching: false,
        };
      case types.UPDATE_PARCEL_SERVICES:
        return {
          ...state,
          itemIsUpdating: true,
        };
      case types.UPDATE_PARCEL_SERVICES_SUCCESS:
      case types.UPDATE_PARCEL_SERVICES_ERROR:
        return {
          ...state,
          itemIsUpdating: false,
        };
      default:
        return state;
    }
  };
