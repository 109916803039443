import React from "react";

import { Theme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

import { useCheckOverlaps } from "./useCheckOverlaps";
import { useInteractiveTooltip } from "./useInteractiveTooltip";

export type ChartPoint = {
  label: number;
  color: string;
};

type Props = {
  data: ChartPoint[];
};

const HorizontalPointsChart = ({ data }: Props) => {
  const classes = useStyles();
  const { circleRefs, overlapIndices } = useCheckOverlaps({ data });
  const {
    clickedTooltipIndex,
    handleTooltipClick,
    handleTooltipHoverOff,
    handleTooltipHoverOn,
    hoveredTooltipIndex,
  } = useInteractiveTooltip();

  return (
    <div className={classes.wrapper}>
      <div className={classes.line} />
      <div className={classes.circleWrapper} style={{ right: 6 }}>
        <span className={classes.zero}>0</span>
      </div>
      {data.map((zone, index) => {
        const isOverlap = overlapIndices.has(index);
        return (
          <Tooltip
            arrow
            key={index}
            title={<span>{zone.label}</span>}
            classes={{
              tooltip: classes.tooltip,
            }}
            open={
              isOverlap &&
              (clickedTooltipIndex === index || hoveredTooltipIndex === index)
            }
          >
            <div
              className={classNames(classes.circleWrapper, classes.circle)}
              onClick={() => handleTooltipClick(index)}
              onMouseEnter={() => handleTooltipHoverOn(index)}
              onMouseLeave={handleTooltipHoverOff}
              onTouchStart={handleTooltipHoverOff}
              ref={(el) => {
                circleRefs.current[index] = el;
              }}
              style={{
                left: `${100 - zone.label}%`,
                backgroundColor: zone.color,
              }}
            >
              {!isOverlap && (
                <span className={classes.circleLabel}>{zone.label}</span>
              )}
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  tooltip: {
    background: theme.palette.common.white,
    color: theme.palette.grey[500],
    fontWeight: 400,
  },
  wrapper: {
    position: "relative",
    height: 70,
    marginTop: 10,
    marginLeft: 12,
    marginRight: 12,
  },
  line: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 20,
    height: 2,
    backgroundColor: theme.palette.grey[200],
  },
  circleWrapper: {
    position: "absolute",
    top: 20,
    transform: "translate(-50%, -50%)",
  },
  circle: {
    width: 24,
    height: 24,
    borderRadius: "50%",
    border: `1px solid transparent`,
  },
  circleLabel: {
    position: "absolute",
    top: 30,
    left: "50%",
    transform: "translateX(-50%)",
    fontSize: 12,
    color: theme.palette.grey[500],
  },
  zero: {
    position: "absolute",
    top: 5,
    fontSize: 12,
    color: theme.palette.grey[500],
  },
}));

export default HorizontalPointsChart;
