import React from "react";

import moment from "moment";
import { ProductFruits } from "react-product-fruits";

import { getShortDateString } from "../../misc/timeHelpers";

type Props = {
  account?: {
    authorities: string[];
    createdDate: number;
    firstName: string;
    lastName: string;
    login: string;
    email: string;
  };
  farm: {
    name: string;
    customer: {
      countryCode: string;
    };
    id: string;
  };
  langId: keyof typeof LANGUAGES;
  projectId: string;
};

const ProductFruitsProvider = ({
  account,
  children,
  farm,
  langId,
  projectId,
}: React.PropsWithChildren<Props>) => {
  if (!account || !projectId) {
    return children;
  }
  const createdDate = account.createdDate
    ? getShortDateString(moment(account.createdDate * 1000))
    : undefined;
  const isAdmin = account.authorities.includes("ROLE_ADMIN");
  const userInfo = {
    username: account?.login,
    email: account.email,
    firstName: account.firstName,
    lastName: account.lastName,
    role: isAdmin ? "admin" : "user",
    signUpAt: createdDate,
    props: {
      farmId: farm.id,
      farmName: farm.name,
      farmCountryCode: farm.customer.countryCode,
      userLanguage: LANGUAGES[langId],
    },
  };

  return (
    <>
      <ProductFruits
        language={LANGUAGES[langId]}
        user={userInfo}
        workspaceCode={projectId}
      />
      {children}
    </>
  );
};

const LANGUAGES = {
  "cs-CZ": "cs",
  "en-US": "en",
  "sr-LATN-RS": "en",
  "es-ES": "es",
  "ru-UA": "en",
  "el-GR": "en",
  "ro-RO": "en",
  "hu-HU": "en",
} as const;

export default ProductFruitsProvider;
