import React, { ReactNode, useEffect } from "react";

import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getActiveSubstances,
  getIsFetchingActiveSubstances,
} from "../../../../../shared/api/agroevidence/catalogues/plantProtection/plantProtection.selectors";

import { fetchActiveSubstances } from "../../../../actions/catalogues.actions";

import { resetActiveSubstancesApi } from "../../../../../shared/api/agroevidence/catalogues/plantProtection/plantProtection.api";
import CfAutocomplete from "../../../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import { Thunk } from "../../../../../types";

import { CataloguesState } from "../../../../../reducers/catalogues.reducer.types";
import { ActiveSubstanceTo } from "../../../../../shared/api/agroevidence/agroevidence.types";

type ReduxProps = ConnectedProps<typeof connector>;
type OwnProps = {
  defaultValues: ActiveSubstanceTo | ActiveSubstanceTo[] | null;
  disabled?: boolean;
  error?: boolean;
  isMultiple?: boolean;
  label: ReactNode;
  onChange: (items: ActiveSubstanceTo | ActiveSubstanceTo[]) => void;
};
type Props = ReduxProps & OwnProps;

const ActiveSubstancesSelector = ({
  activeSubstances,
  defaultValues,
  disabled = false,
  error = false,
  fetchActiveSubstances,
  isFetching,
  isMultiple = false,
  label,
  onChange,
  resetActiveSubstancesApi,
}: Props) => {
  useEffect(() => {
    fetchActiveSubstances();

    return () => {
      resetActiveSubstancesApi();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CfAutocomplete
      defaultValues={defaultValues}
      disabled={disabled}
      error={error}
      helperText={error ? <FormattedMessage id="validation.required" /> : ""}
      id="active-substances-selector"
      isFetching={isFetching}
      isMultiple={isMultiple}
      label={label}
      loadOptions={fetchActiveSubstances}
      onChange={onChange}
      suggestions={activeSubstances}
      testId="active-substances-selector"
    />
  );
};

const mapStateToProps = (state: CataloguesState) => ({
  activeSubstances: getActiveSubstances(state),
  isFetching: getIsFetchingActiveSubstances(state),
});

const mapDispatchToProps = (dispatch: Thunk<CataloguesState>) =>
  bindActionCreators(
    {
      fetchActiveSubstances,
      resetActiveSubstancesApi,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ActiveSubstancesSelector);
