import React, { FC, ReactNode, useEffect, useMemo } from "react";

import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getCenters,
  getIsFetchingCenters,
} from "../../../../../../shared/api/agroevidence/parcels/parcels.selectors";

import {
  getCentersApi,
  resetCentersApi,
} from "../../../../../../shared/api/agroevidence/parcels/parcels.api";
import CfAutocomplete from "../../../../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import { Thunk } from "../../../../../../types";

import { ParcelsState } from "../../../../../../reducers/parcels.reducer.types";
import { CenterTo } from "../../../../../../shared/api/agroevidence/agroevidence.types";

export interface CenterSelectorProps {
  defaultCenters?: CenterTo[];
  getCentersApi: (search?: string) => void;
  isCenterFetching: boolean;
  label: ReactNode;
  onChange: (items: CenterTo[]) => void;
  resetCentersApi: () => void;
  suggestions: CenterTo[];
}

const CenterSelector: FC<CenterSelectorProps> = ({
  defaultCenters = [],
  getCentersApi,
  isCenterFetching,
  label,
  onChange,
  resetCentersApi,
  suggestions,
}) => {
  const intl = useIntl();

  useEffect(() => {
    getCentersApi();

    return () => {
      resetCentersApi();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const extendedSuggestions = useMemo(
    () => [
      {
        id: "NONE",
        name: intl.formatMessage({ id: "ParcelDetailOverview.noCenterOption" }),
      },
      ...suggestions,
    ],
    [suggestions, intl],
  );

  return (
    <CfAutocomplete
      defaultValues={defaultCenters}
      id="center-selector"
      isFetching={isCenterFetching}
      isMultiple={true}
      label={label}
      loadOptions={getCentersApi}
      onChange={onChange}
      suggestions={extendedSuggestions}
      testId="center-filter"
    />
  );
};

const mapStateToProps = (state: ParcelsState) => ({
  suggestions: getCenters(state),
  isCenterFetching: getIsFetchingCenters(state),
});

const mapDispatchToProps = (dispatch: Thunk<ParcelsState>) =>
  bindActionCreators(
    {
      getCentersApi,
      resetCentersApi,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CenterSelector);
