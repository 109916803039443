import { AnyTodo } from "../../../../types";

export const sortedSuggestions = (
  suggestions: AnyTodo[],
  inputValue: string,
  getSortingString?: (item: AnyTodo) => string,
) => {
  if (!getSortingString) return suggestions;

  const sortedMatches: { index: number; option: AnyTodo }[] = [];
  const unsortedMatches: { option: AnyTodo }[] = [];

  suggestions.forEach((option) => {
    const sortingString = getSortingString(option);
    const index = sortingString.indexOf(inputValue);
    if (index === -1) {
      unsortedMatches.push({ option });
    } else {
      sortedMatches.push({ option, index });
    }
  });

  sortedMatches.sort(
    (a, b) =>
      a.index - b.index ||
      getSortingString(a.option).localeCompare(getSortingString(b.option)),
  );

  const sortedOptions = sortedMatches.map((match) => match.option);
  const unsortedOptions = unsortedMatches.map((match) => match.option);

  // Append items with an index of -1 to the end of the original order.
  const finalSortedOptions = sortedOptions.concat(unsortedOptions);

  return finalSortedOptions;
};
