import React from "react";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { FormattedMessage } from "react-intl";

import { PorUsageErrorSection } from "./PorUsageErrorSection";

import { PorUseReportMissingDataBody } from "../../../../shared/api/agroevidence/agroevidence.types";

interface Props {
  date: string;
  errorDetailedMessage?: PorUseReportMissingDataBody;
  onClose: () => void;
  showErrorDialog: boolean;
}

export const PorUsageReportErrorDialog = ({
  date,
  errorDetailedMessage,
  onClose,
  showErrorDialog,
}: Props) => (
  <Dialog
    data-test="report-fertilizers-dialog"
    maxWidth="md"
    onClose={onClose}
    open={showErrorDialog}
  >
    <DialogTitle>
      <FormattedMessage id="Reports.porUsageReport.errorDialogTitle" />
      <Typography>
        <FormattedMessage
          id="Reports.porUsageReport.errorDialogSubtitle"
          values={{ date }}
        />
      </Typography>
    </DialogTitle>
    <DialogContent>
      {!errorDetailedMessage && (
        <div>
          {" "}
          <FormattedMessage id="Reports.porUsageReport.generalError" />
        </div>
      )}
      {errorDetailedMessage && (
        <Stack direction="column" spacing={2}>
          {Object.keys(errorDetailedMessage).map(
            (section: keyof PorUseReportMissingDataBody) => {
              if (errorDetailedMessage[section].length === 0) {
                return null;
              }

              return (
                <PorUsageErrorSection
                  errorDetailedMessage={errorDetailedMessage}
                  key={section}
                  section={section}
                />
              );
            },
          )}
        </Stack>
      )}
    </DialogContent>
  </Dialog>
);
