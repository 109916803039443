import React from "react";

import Collapse from "@mui/material/Collapse";
import { makeStyles } from "@mui/styles";

type Props = {
  expanded: boolean;
  testId: string;
};

function MapSwitcherBody({
  children,
  expanded,
  testId,
}: React.PropsWithChildren<Props>) {
  const classes = useStyles();

  return (
    <Collapse in={expanded}>
      <div className={classes.layerListContent} data-test={`${testId}-content`}>
        {React.Children.only(children)}
      </div>
    </Collapse>
  );
}

const useStyles = makeStyles(() => ({
  layerListContent: {
    padding: "8px 10px 8px 16px",
  },
}));

export default MapSwitcherBody;
