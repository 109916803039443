import React, { ReactNode, useEffect } from "react";

import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  getSentinelAdminCrops,
  getSentinelAdminCropsIsFetching,
  getSentinelAdminFarm,
} from "../../../selectors/sentinel.selectors";

import { fetchCrops } from "../../../actions/sentinel.actions";

import { resetCropsApi } from "../../../../../../shared/api/agroevidence/catalogues/crops/crops.api";
import CfAutocomplete from "../../../../../../shared/components/common/CfAutocomplete/CfAutocomplete";

import { CropTo } from "../../../../../../shared/api/agroevidence/agroevidence.types";

type Props = {
  defaultValues?: CropTo | CropTo[];
  disabled?: boolean;
  error?: boolean;
  isMultiple?: boolean;
  label: ReactNode;
  onChange: (items: CropTo | CropTo[]) => void;
};

const CropsSelector = ({
  defaultValues = [],
  disabled = false,
  error = false,
  isMultiple = false,
  label,
  onChange,
}: Props) => {
  const dispatch = useDispatch();

  const suggestions = useSelector(getSentinelAdminCrops);
  const isCropsFetching = useSelector(getSentinelAdminCropsIsFetching);
  const farm = useSelector(getSentinelAdminFarm);

  useEffect(() => {
    dispatch(fetchCrops());

    return () => {
      dispatch(resetCropsApi());
    };
  }, [dispatch, farm?.id]);

  return (
    <CfAutocomplete
      defaultValues={defaultValues}
      disabled={disabled}
      error={error}
      helperText={error ? <FormattedMessage id="validation.required" /> : ""}
      id="crops-selector"
      isFetching={isCropsFetching}
      isMultiple={isMultiple}
      label={label}
      loadOptions={fetchCrops}
      onChange={onChange}
      suggestions={suggestions}
      testId="crops-selector"
    />
  );
};

export default CropsSelector;
