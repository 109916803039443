import React, { Fragment, useCallback, useEffect, useState } from "react";

import { FormattedMessage } from "react-intl";

import useWidth from "../../../../shared/hooks/useWidth";
import { LogoutIcon } from "../../../../shared/icons/navbar/LogoutIcon";
import { MenuItemType } from "../../services/menuItems";
import NavbarService from "../../services/NavbarService";
import MainMenuDropdown from "../MainMenuDropdown/MainMenuDropdown";

import { useMainMenuStyles } from "./MainMenu.styles";
import MainMenuItem from "./MainMenuItem";

export interface MainMenuProps {
  farmId: string;
  currState: string;
  logout: () => void;
  mobile?: boolean;
  onMenuItemClick?: () => void;
}

const MainMenu = ({
  currState,
  farmId,
  logout,
  mobile = false,
  onMenuItemClick,
}: MainMenuProps) => {
  const classes = useMainMenuStyles();
  const width = useWidth();

  const menuItems = NavbarService.getMainMenuItems();
  const dropdownMenuItems = NavbarService.getDropdownMainMenuItems();

  const isActive = useCallback(
    (menuItem: MenuItemType) => {
      const baseState = "farm.active";
      if (currState.startsWith(baseState)) {
        const stateName = currState.split(".")[2];
        return menuItem.states.some((state: string) => state === stateName);
      }
      return false;
    },
    [currState],
  );

  const getSelectedDropdownItem = useCallback(
    () => dropdownMenuItems.find((menuItem) => isActive(menuItem)),
    [dropdownMenuItems, isActive],
  );

  const [dropdownSelected, setDropdownSelected] = useState(
    getSelectedDropdownItem()?.id || dropdownMenuItems[0].id,
  );

  useEffect(() => {
    const selectedDropdownItem = getSelectedDropdownItem();
    if (selectedDropdownItem && selectedDropdownItem.id !== dropdownSelected) {
      setDropdownSelected(selectedDropdownItem.id);
    }
  }, [currState, getSelectedDropdownItem, dropdownSelected]);

  return (
    <div className={mobile ? classes.mainMenuMobile : classes.mainMenuDesktop}>
      {menuItems
        .filter(
          (menuItem) =>
            !(
              (width === "md" || width === "lg") &&
              menuItem.dropdown &&
              menuItem.id !== dropdownSelected
            ),
        )
        .map((menuItem) => (
          <MainMenuItem
            farmId={farmId}
            isActive={isActive(menuItem)}
            key={menuItem.id}
            menuItem={menuItem}
            mobile={mobile}
            onMenuItemClick={onMenuItemClick}
          />
        ))}
      {(width === "md" || width === "lg") && (
        <MainMenuDropdown
          farmId={farmId}
          items={dropdownMenuItems}
          lastSelected={dropdownSelected}
        />
      )}
      {mobile && (
        <Fragment>
          <hr className={classes.separator} />
          <div
            className={`${classes.menuItemLink} ${classes.menuItemLinkMobile}`}
            id="main-menu-logout"
            onClick={logout}
            role="button"
            tabIndex={0}
          >
            <span className={classes.navlinkContentMobile}>
              <LogoutIcon className={classes.navlinkIcon} />
              <FormattedMessage id="UserMenu.logout" />
            </span>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default MainMenu;
