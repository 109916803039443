import React from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import { useToggle } from "../../../hooks/useToggle";
import CfDialog from "../../common/CfDialog/CfDialog";

type Props = {
  onDeleteActions: () => void;
  title?: React.ReactNode | string;
  selected: string[];
  content?: JSX.Element;
  isDeleting?: boolean;
};

const TableActionButtons = ({
  content,
  isDeleting,
  onDeleteActions,
  selected,
  title = "",
}: Props) => {
  const classes = useStyles();
  const { on: opened, setOff: closeDialog, setOn: openDialog } = useToggle();

  const onClose = () => {
    closeDialog();
  };

  const onSubmit = () => {
    onClose();
    onDeleteActions();
  };

  const onDeleteBtnClick = () => {
    openDialog();
  };

  return (
    <div className={selected.length ? classes.buttonsActive : undefined}>
      <Button
        disabled={selected.length < 1 || isDeleting}
        id="table-delete-items-btn"
        onClick={onDeleteBtnClick}
        className={`${classes.button} ${
          selected.length ? classes.activeButton : ""
        }`}
      >
        <DeleteIcon />
        {selected.length > 0 && `\u00A0(${selected.length})`}
      </Button>

      <CfDialog
        acceptText={<FormattedMessage id="common.yes" />}
        cancelText={<FormattedMessage id="common.no" />}
        onAccept={onSubmit}
        onCancel={onClose}
        onClose={onClose}
        opened={opened}
        title={title}
      >
        <div>{content}</div>
      </CfDialog>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  activeButton: {
    background: theme.palette.grey[100],
    color: theme.palette.primary.main,
  },
  button: {
    minWidth: 48,
    paddingLeft: 12,
    paddingRight: 12,
  },
  buttonsActive: {
    marginRight: 8,
  },
}));

export default TableActionButtons;
