import { intersection } from "lodash";

import {
  getAdminParcels,
  getAdminParcelsTotalCount,
  isFetchingAdminParcels,
} from "../../../../shared/api/sentinel/admin/admin.selectors";

import { AdminState } from "../../../../reducers/admin.reducer.types";

export const getSentinelAdminPage = (state: AdminState) =>
  state.ui.sentinel_admin.page;
export const getSentinelAdminOrder = (state: AdminState) =>
  state.ui.sentinel_admin.order;
export const getSentinelAdminOrderBy = (state: AdminState) =>
  state.ui.sentinel_admin.orderBy;
export const getSentinelAdminRowsPerPage = (state: AdminState) =>
  state.ui.sentinel_admin.rowsPerPage;
export const getSentinelAdminTextFilter = (state: AdminState) =>
  state.ui.sentinel_admin.textFilter;
export const getSentinelAdminFarm = (state: AdminState) =>
  state.ui.sentinel_admin.farm ?? undefined;

export const getSentinelAdminAdvancedFilter = (state: AdminState) =>
  state.ui.sentinel_admin.advancedFilter;
export const getSentinelAdminCrops = (state: AdminState) =>
  state.ui.sentinel_admin.crops;
export const getSentinelAdminCropsIsFetching = (state: AdminState) =>
  state.ui.sentinel_admin.cropsIsFetching;

export const getSentinelAdminParcelsSuggestions = (state: AdminState) =>
  state.api.parcelsSuggestions.items;

export const getActiveSentinelParcels = (state: AdminState) =>
  getAdminParcels(state);
export const isFetchingActiveSentinelParcels = (state: AdminState) =>
  isFetchingAdminParcels(state);
export const getSentinelAdminTotalCount = (state: AdminState) =>
  getAdminParcelsTotalCount(state);
export const getSelected = (state: AdminState) =>
  state.ui.sentinel_admin.selected;

export const getSelectedOnPage = (state: AdminState) =>
  intersection(
    state.api.sentinelParcels.items.map((item) => item.parcelId),
    state.ui.sentinel_admin.selected,
  );
