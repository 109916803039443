import React from "react";

import { Theme } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { makeStyles } from "@mui/styles";
import { Field } from "formik";

import CfFormikSwitch from "../../../../../shared/components/form/CfFormikSwitch/CfFormikSwitch";

import { FormType, FormInitValuesType } from "./SentinelAdminParcelsDialog";
import { ThreeStateTrayFormControl } from "./ThreeStateTrayFormControl";

type Props = {
  mode: FormType;
  label: React.ReactNode | string;
  name: keyof FormInitValuesType;
};

const FormControl = ({ label, mode, name }: Props) => {
  const classes = useStyles();

  return (
    <>
      {mode === "single" ? (
        <FormControlLabel
          label={label}
          classes={{
            root: classes.switchHistoricRoot,
            label: classes.switchHistoricLabel,
          }}
          control={
            <Field
              color="primary"
              component={CfFormikSwitch}
              id={name}
              label={null}
              name={name}
            />
          }
        />
      ) : (
        <ThreeStateTrayFormControl
          classes={classes}
          label={label}
          name={name}
        />
      )}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  switchHistoricRoot: {
    display: "flex",
    flexDirection: "row-reverse",
    width: "100%",
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  switchHistoricLabel: {
    fontSize: 16,
    display: "flex",
    alignItems: "center",
    height: 45,
    width: "calc(100% - 62px)",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
}));

export { FormControl };
