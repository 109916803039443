import React from "react";

import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

type Props = {
  customClass?: string;
  value?: string | React.ReactNode;
  dataTest?: string;
  translationId?: string;
  align?: "left" | "right" | "center";
};

const PageHeading = ({
  align = "center",
  customClass: className = "",
  dataTest = "",
  translationId = "",
  value = "",
}: Props) => {
  const classes = useStyles();

  return (
    <h1
      className={`${classes.heading} ${className}`}
      data-test={dataTest}
      style={{ justifyContent: align }}
    >
      {translationId ? <FormattedMessage id={translationId} /> : value}
    </h1>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    fontSize: 24,
    fontWeight: 500,
    margin: 0,
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    padding: theme.spacing(1),
  },
  [theme.breakpoints.down("xs")]: {
    heading: {
      fontSize: 22,
    },
  },
}));

export default PageHeading;
