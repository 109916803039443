import React from "react";

import { Theme } from "@mui/material/styles";
import { TableSortLabelProps } from "@mui/material/TableSortLabel";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import { NAMESPACE as namespace } from "../../reducer/groups.reducer";

import CfTableHead from "../../../common/components/CfTableHead/CfTableHead";
import { useCfTableHead } from "../../../common/components/CfTableHead/useCfTableHead";
import { getColDesc } from "../../../shared/misc/helper";

const columns = {
  name: getColDesc(true, <FormattedMessage id="common.name" />, {
    paddingLeft: "15px",
    width: "50%",
  }),
  count: getColDesc(
    true,
    <FormattedMessage id="NodeGroups.count" />,
    { width: "50%" },
    "right",
  ),
};

type Props = {
  order?: TableSortLabelProps["direction"];
  orderBy?: string;
};

const Header = ({ order, orderBy }: Props) => {
  const classes = useStyles();
  const { onSelect, onSort } = useCfTableHead(namespace);

  return (
    <CfTableHead
      className={classes.header}
      columns={columns}
      onSelect={onSelect}
      onSort={onSort}
      order={order}
      orderBy={orderBy}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    backgroundColor: theme.palette.common.white,
  },
}));

export { Header };
