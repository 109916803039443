import { RSAAAction } from "redux-api-middleware";

import * as sortOrder from "../../../../shared/constants/sortOrder.constants";

import { getFarmsApi } from "../../../../shared/api/agroevidence/farms/farms.api";
import {
  getFarmsAdminOrder,
  getFarmsAdminOrderBy,
  getFarmsAdminPage,
  getFarmsAdminRowsPerPage,
  getFarmsAdminTextFilter,
} from "../selectors/farms.selector";

import { AdminState } from "../../../../reducers/admin.reducer.types";

export const fetchAccountFarms =
  () =>
  (dispatch: (action: RSAAAction) => void, getState: () => AdminState) => {
    const state = getState();

    const params = {
      isAscending: getFarmsAdminOrder(state) === sortOrder.ASC,
      "sort-col": getFarmsAdminOrderBy(state),
      "sort-dir": getFarmsAdminOrder(state),
      page: getFarmsAdminPage(state) + 1,
      "per-page": getFarmsAdminRowsPerPage(state),
      search: getFarmsAdminTextFilter(state),
    };

    dispatch(getFarmsApi(params));
  };
