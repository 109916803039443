import React from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { FormattedMessage, FormattedDate, FormattedTime } from "react-intl";

import CfTableCell from "../../../../shared/components/tables/CfTableCell/CfTableCell";
import IrrigationService from "../../../services/Irrigation.service";

import { Device } from "../../../../shared/api/irrigation/devices/devices.types";

interface Props {
  device: Device;
}

export const NextActionCell = ({ device }: Props) => {
  const classes = useStyles();

  const { next } = device;
  const isActive = IrrigationService.isActive(device);

  if (!isActive || !next) {
    return <CfTableCell name="nextAction">-</CfTableCell>;
  }

  const nextActionStart = moment(next.time).toISOString();
  const nextActionEnd = moment(next.time).add(next.duration, "s").toISOString();

  return (
    <CfTableCell name="nextAction">
      <div>
        <FormattedMessage id="IrrigationList.valve" /> {next.valve + 1}
      </div>
      <div className={classes.secondaryText}>
        <FormattedDate value={nextActionStart} weekday="short" />{" "}
        <FormattedTime value={nextActionStart} />
        &ndash;
        <FormattedTime value={nextActionEnd} />
      </div>
    </CfTableCell>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  secondaryText: {
    color: theme.palette.grey[500],
  },
}));
