import React, { FC } from "react";

import CloseIcon from "@mui/icons-material/Clear";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import { FormattedMessage } from "react-intl";

import PorUsageEvidence from "./PorUsageEvidence";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const ReportsHistoryDialog: FC<Props> = ({ isOpen, onClose }: Props) => (
  <Dialog
    data-test="history-report-dialog"
    fullWidth={true}
    maxWidth="md"
    onClose={onClose}
    open={isOpen}
  >
    <DialogTitle data-test="dialog-title">
      <Stack direction="row" justifyContent="space-between">
        <FormattedMessage id="Reports.history.dialog.title" />
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
    </DialogTitle>
    <DialogContent>
      <PorUsageEvidence />
    </DialogContent>
  </Dialog>
);
