import React, { useState } from "react";

import { Theme } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import IconButton from "@mui/material/IconButton";
import { createTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import createCftheme from "../../../../theme";
import { getAppVariant } from "../../../variants/getAppVariant";

const muiTheme = createTheme(createCftheme(getAppVariant()));

const primaryColor = muiTheme.palette.primary.main;
const defaultColor = muiTheme.palette.text.primary;
const whiteColor = muiTheme.palette.common.white;

type Props = {
  active: boolean;
  callback: React.MouseEventHandler<HTMLButtonElement> | undefined;
  icon: (props: { htmlColor: string }) => React.JSX.Element;
  tooltipTitle: string;
  withBackground?: boolean;
};

const ToolbarIconBtn = ({
  active,
  callback,
  icon,
  tooltipTitle,
  withBackground = false,
  ...rest
}: Props) => {
  const classes = useStyles();

  const [hover, setHover] = useState(false);

  React.useEffect(() => {
    if (active && hover) {
      handleOnMouseLeave();
    }
  }, [active, hover]);

  const handleOnMouseEnter = () => {
    setHover(true);
  };

  const handleOnMouseLeave = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    setHover(false);
  };

  const handleClickAway = () => {
    setHover(false);
  };

  const Icon = icon;
  const inactiveClassName = withBackground ? classes.background : "";

  let htmlColor = defaultColor;
  if (hover && !active) {
    htmlColor = primaryColor;
  } else if (active || (hover && active)) {
    htmlColor = whiteColor;
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        open={hover}
        placement="bottom"
        title={<FormattedMessage id={tooltipTitle} />}
      >
        <IconButton
          classes={{ root: classes.root }}
          className={active ? classes.active : inactiveClassName}
          disableRipple={true}
          onClick={callback}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
          {...rest}
          size="large"
        >
          <Icon htmlColor={htmlColor} />
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "relative",
    top: -3,
    width: 34,
    height: 34,
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  background: {
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.common.white,
    },
  },
  tooltip: {
    fontSize: 12,
    margin: 4,
  },
}));

export default ToolbarIconBtn;
