import { z } from "zod";

import { ActionEphFormValues } from "./actionEph.types";

const parcelsValidationSchema = z.object({});

const pestsValidationSchema = z.object({});

const numberStringRegex = /^\d{1,3}(?:[\s.,]\d{3})*(?:[.,]\d+)?$/;

// check if the value is greater than 0
const greaterThanZero = (value: string) => {
  const numericValue = parseFloat(
    value.replace(/[\s,]/g, "").replace(/,/g, "."),
  );
  return numericValue > 0;
};

const plantProtectionsValidationSchema = z.object({
  note: z.string().optional(),
  dosage: z
    .string()
    .refine((value) => numberStringRegex.test(value), {
      message:
        "Must contain only numbers and at most one decimal point or comma",
    })
    .refine(greaterThanZero, {
      message: "Must be greater than 0",
    }),
  pests: z.array(pestsValidationSchema).min(1, "error"),
  dosePerHectare: z
    .string()
    .refine((value) => numberStringRegex.test(value), {
      message:
        "Must contain only numbers and at most one decimal point or comma",
    })
    .refine(greaterThanZero, {
      message: "Must be greater than 0",
    }),
  driftClass: z.object({}),
});

const fertilizersValidationSchema = z.object({
  dosage: z
    .string()
    .refine((value) => numberStringRegex.test(value), {
      message:
        "Must contain only numbers and at most one decimal point or comma",
    })
    .refine(greaterThanZero, {
      message: "Must be greater than 0",
    }),
  dosePerHectare: z
    .string()
    .refine((value) => numberStringRegex.test(value), {
      message:
        "Must contain only numbers and at most one decimal point or comma",
    })
    .refine(greaterThanZero, {
      message: "Must be greater than 0",
    }),
});

export const validationSchema = z
  .object({
    parcels: z.array(parcelsValidationSchema).min(1, "error"),
    targetCrop: z.object({}),
    plantProtections: z.array(plantProtectionsValidationSchema).optional(),
    fertilizers: z.array(fertilizersValidationSchema),
    noFertilizersOrPlantProtection: z.object({}).optional(), // helper field for non empty por or fertilizer validation
  })
  .refine(
    (data) =>
      data?.plantProtections?.length !== 0 || data?.fertilizers?.length !== 0,
    {
      message: "error",
      path: ["noFertilizersOrPlantProtection"],
    },
  );

export const validateEphFormValues = (values: ActionEphFormValues) => {
  try {
    validationSchema.parse(values);
    return true;
  } catch (error) {
    return false;
  }
};
