import ErrorService from "../../../services/Error.service";

import { PrecisionState } from "../../../../reducers/precision.reducer.types";

export const getManagementZoneParcelSelector = (state: PrecisionState) =>
  state.api.management.managementZoneParcel;
export const getManagementZoneParcelIsFetching = (state: PrecisionState) =>
  state.api.management.managementZoneParcelIsFetching;
export const getManagementZoneParcelError = (state: PrecisionState) =>
  ErrorService.getResErrorDto(state.api.management.managementZoneParcelError);
