import React, { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Clear";
import { IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  getMainMapTelematicsDashboardMachinesOrder,
  getMainMapTelematicsDashboardMachinesOrderBy,
  getMainMapTelematicsDashboardTextFilter,
} from "../../../../shared/api/telematics/mainMapTelematics/dashboard/machinesDashboard.selectors";

import {
  getDashboardMachinePositions,
  resetDashboardDrivesHistory,
} from "../../../../shared/api/telematics/mainMapTelematics/dashboard/machinesDashboard.actions";

import { NAMESPACE } from "../../../../shared/api/telematics/mainMapTelematics/dashboard/machinesDashboard.reducer";

import { MachinesSearchFilter } from "./MachinesSearchFilter";
import { MachinesTable } from "./MachinesTable";

import { PositionDashboard } from "../../../../shared/api/telematics/telematics.types";

type Props = {
  handleClose: () => void;
  testId: string;
  machines: PositionDashboard[];
};

const MachinesTableWrapper = ({ handleClose, machines, testId }: Props) => {
  const classes = useStyles();

  const [isInit, setIsInit] = useState(false);

  const textFilter = useSelector(getMainMapTelematicsDashboardTextFilter);
  const order = useSelector(getMainMapTelematicsDashboardMachinesOrder);
  const orderBy = useSelector(getMainMapTelematicsDashboardMachinesOrderBy);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isInit) {
      dispatch(resetDashboardDrivesHistory());
      dispatch(getDashboardMachinePositions());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, textFilter, order, orderBy]);

  useEffect(() => {
    if (!isInit) {
      setIsInit(true);
    }
  }, [isInit]);

  return (
    <div className={classes.wrapper} data-test={testId}>
      <div className={classes.header}>
        <FormattedMessage id="MainMapTelematics.machinesDashboard.header.title" />
        <IconButton
          aria-label="close"
          className={classes.closeIcon}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.filter}>
        <MachinesSearchFilter namespace={NAMESPACE} textFilter="" />
      </div>
      <MachinesTable machines={machines} />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    maxWidth: 308,
    width: "100%",
  },
  loaderWrapper: {
    overflow: "hidden",
    paddingTop: 10,
    paddingBottom: 20,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: 48,
    padding: "10px 16px",
    fontSize: 14,
    fontWeight: 500,
  },
  filter: {
    padding: "10px 16px",
  },
  closeIcon: {
    padding: 12,
    width: 20,
    height: 20,
  },
}));

export default MachinesTableWrapper;
