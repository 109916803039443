import React, { useContext } from "react";

import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import { Checkbox, TableCell, TableRow } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { FormattedDate, FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import CfFormattedNumber from "../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import CfTableCell from "../../../shared/components/tables/CfTableCell/CfTableCell";
import { CataloguesContext } from "../../containers/CataloguesWrapper/CataloguesWrapper";
import { getSourceId } from "../../helpers/selectorHelpers";

import { COLUMN_NAMES } from "./fertilizers.columns";

import { FertilizerTo } from "../../../shared/api/agroevidence/agroevidence.types";

interface Props {
  data: FertilizerTo;
  onUpdateIsFavorite: (fertilizerId: string, isFavorite: boolean) => void;
}

const FertilizersListRow = ({ data, onUpdateIsFavorite }: Props) => {
  const { farmId } = useContext(CataloguesContext);

  const classes = useStyles();
  const history = useHistory();

  const isValid = data.eagriProperties?.isValid;

  const handleFavoriteClick = () => {
    onUpdateIsFavorite(data.id, data.isFavorite);
  };

  return (
    <TableRow
      className={classes.pointer}
      hover
      key={data.id}
      onClick={() =>
        history.push(`/farm/${farmId}/catalogues/fertilizersReact/${data.id}`)
      }
    >
      <TableCell
        classes={{ root: classes.isFavorite }}
        key={COLUMN_NAMES.IS_FAVORITE}
        onClick={(evt) => evt.stopPropagation()}
        padding="checkbox"
      >
        <Checkbox
          checked={data.isFavorite}
          checkedIcon={<StarRoundedIcon />}
          color="secondary"
          icon={<StarBorderRoundedIcon />}
          onClick={handleFavoriteClick}
        />
      </TableCell>
      <CfTableCell classes={{ root: classes.name }} name={COLUMN_NAMES.NAME}>
        {data.name}
      </CfTableCell>
      <CfTableCell name={COLUMN_NAMES.PRODUCER_TRADE_NAME}>
        {data?.producerTradeName ?? "-"}
      </CfTableCell>
      <CfTableCell name={COLUMN_NAMES.EVIDENCE_NUMBER}>
        {data.eagriProperties?.evidenceNumber ?? "-"}
      </CfTableCell>
      <CfTableCell name={COLUMN_NAMES.IS_ORGANIC}>
        <FormattedMessage id={`common.${data.isOrganic ? "yes" : "no"}`} />
      </CfTableCell>
      <CfTableCell name={COLUMN_NAMES.NITROGEN_CATEGORY}>
        {data.eagriProperties?.nitrogenCategory.name ?? "-"}
      </CfTableCell>
      <CfTableCell name={COLUMN_NAMES.SOURCE}>
        <FormattedMessage id={getSourceId(data.catalogue?.type)} />
      </CfTableCell>
      <CfTableCell
        classes={{ root: classes.operation }}
        name={COLUMN_NAMES.NITROGEN}
      >
        <CfFormattedNumber value={data.n} /> %
      </CfTableCell>
      <CfTableCell
        classes={{ root: classes.operation }}
        name={COLUMN_NAMES.P2O5}
      >
        <CfFormattedNumber value={data.p2o5} /> %
      </CfTableCell>
      <CfTableCell
        classes={{ root: classes.operation }}
        name={COLUMN_NAMES.K2O}
      >
        <CfFormattedNumber value={data.k2o} /> %
      </CfTableCell>
      <CfTableCell name={COLUMN_NAMES.VALID_TO}>
        <div
          className={classNames({
            [classes.dataIsHistorical]: !isValid,
          })}
        >
          {data.eagriProperties?.validTo ? (
            <FormattedDate value={data.eagriProperties.validTo} />
          ) : (
            "-"
          )}
        </div>
      </CfTableCell>
    </TableRow>
  );
};

export default FertilizersListRow;

const useStyles = makeStyles((theme: Theme) => ({
  pointer: {
    cursor: "pointer",
  },
  isFavorite: {
    paddingLeft: 7,
    width: 30,
  },
  name: {
    fontWeight: 500,
  },
  operation: {
    paddingLeft: theme.spacing(2),
  },
  dataIsHistorical: {
    color: theme.palette.error.main,
  },
}));
