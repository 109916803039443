import React from "react";

import CloseIcon from "@mui/icons-material/Clear";
import { Dialog, Grid, IconButton, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

type Props = {
  header: React.ReactNode;
  legend: React.ReactNode;
  map: React.ReactNode;
  onClose: () => void;
};

const PrecisionMapModal = ({ header, legend, map, onClose }: Props) => {
  const classes = useStyles();

  return (
    <Dialog maxWidth={false} onClose={onClose} open={true}>
      <Grid className={classes.itemContainer}>
        {header}
        <Grid className={classes.legendWrapper}>{legend}</Grid>
        <Grid item>
          <IconButton
            aria-label="close"
            className={classes.closeIcon}
            onClick={onClose}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid alignItems="baseline" container justifyContent="flex-start">
          {map}
        </Grid>
      </Grid>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  itemContainer: {
    width: 1100,
    padding: "15px 5px 5px 5px",
  },
  [theme.breakpoints.down("lg")]: {
    itemContainer: {
      width: 800,
    },
  },
  [theme.breakpoints.down("md")]: {
    itemContainer: {
      width: 500,
    },
  },
  [theme.breakpoints.down("sm")]: {
    itemContainer: {
      width: 300,
    },
  },
  closeIcon: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  legendWrapper: {
    marginTop: 5,
    marginBottom: 10,
    paddingLeft: 10,
    fontSize: 13,
  },
}));

export default PrecisionMapModal;
