import React, { Component, Fragment } from "react";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

import CfButtonPanel from "../CfButtonPanel/CfButtonPanel";
import CfSwitcherButton from "../CfSwitcherButton/CfSwitcherButton";

export class CfSwitcher extends Component {
  constructor(props) {
    super(props);
    const index = this.findItemIndexById(
      props.items,
      props.selectedItemId,
      props.onMenuItemClick,
    );
    this.state = {
      open: false,
      anchorEl: null,
      selectedIndex: index,
    };
  }

  componentDidUpdate(prevProps) {
    const { items, onMenuItemClick, selectedItemId } = this.props;
    if (selectedItemId !== prevProps.selectedItemId) {
      const index = this.findItemIndexById(
        items,
        selectedItemId,
        onMenuItemClick,
      );
      this.setState({
        selectedIndex: index,
      });
    }

    if (items !== prevProps.items) {
      if (!(this.state.selectedIndex < items?.length)) {
        this.setState({
          selectedIndex: 0,
        });
      }
    }
  }

  findItemIndexById = (items, selectedItemId, onMenuItemClick) => {
    let index = 0;
    const itemIndex = items.findIndex(
      (item) => this.props.getItemId(item) === selectedItemId,
    );
    if (itemIndex !== -1) {
      index = itemIndex;
    } else if (items.length) {
      onMenuItemClick(items[index]);
    }
    return index;
  };

  handleClickListItem = (event) => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleItemSelect = (index) => {
    const { items } = this.props;
    if (index >= 0 || index < items.length) {
      this.setState({ selectedIndex: index, open: false });
      this.props.onMenuItemClick(items[index]);
    }
  };

  shiftSelectedItem = (goForward = false) => {
    const { selectedIndex } = this.state;
    const newIndex = goForward ? selectedIndex + 1 : selectedIndex - 1;
    this.handleItemSelect(newIndex);
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  getSelectedItem = () => {
    const { items } = this.props;
    const { selectedIndex } = this.state;
    if (selectedIndex < items?.length) {
      return items[selectedIndex];
    } else {
      return items[0];
    }
  };

  render() {
    const {
      arrowsShift,
      classes,
      disabled,
      getItemId,
      getItemValue,
      getItemViewValue,
      isEmptyPlaceholder,
      items,
      testId,
    } = this.props;
    const { selectedIndex } = this.state;

    return (
      <div className={classes.wrapper}>
        <CfButtonPanel
          classes={{ root: classes.buttonPanel }}
          data-test={testId}
          uppercase={false}
        >
          {arrowsShift && (
            <Fragment>
              <IconButton
                className={classes.arrowButton}
                disabled={!items.length || selectedIndex === items.length - 1}
                onClick={() => this.shiftSelectedItem(true)}
                size="large"
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
              <IconButton
                className={classes.arrowButton}
                disabled={selectedIndex === 0}
                onClick={() => this.shiftSelectedItem(false)}
                size="large"
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            </Fragment>
          )}
          <CfSwitcherButton
            data-test={testId}
            disabled={disabled}
            onClick={(event) => this.handleClickListItem(event)}
            classes={{
              root: classes.button,
            }}
          >
            {items.length ? (
              <>
                {getItemViewValue
                  ? getItemViewValue(this.getSelectedItem())
                  : getItemValue(this.getSelectedItem())}
              </>
            ) : (
              <>{isEmptyPlaceholder}</>
            )}
          </CfSwitcherButton>
        </CfButtonPanel>

        <Menu
          anchorEl={this.state.anchorEl}
          className={classes.menu}
          onClose={this.handleRequestClose}
          open={this.state.open}
        >
          {items.map((item, index) => (
            <MenuItem
              className={classes.menuItem}
              id={getItemId(item)}
              key={getItemId(item)}
              onClick={() => this.handleItemSelect(index)}
              selected={index === selectedIndex}
              value={getItemValue(item)}
            >
              {getItemViewValue ? getItemViewValue(item) : getItemValue(item)}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
}

CfSwitcher.propTypes = {
  classes: PropTypes.object.isRequired,
  onMenuItemClick: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  getItemId: PropTypes.func.isRequired,
  getItemValue: PropTypes.func.isRequired,
  getItemViewValue: PropTypes.func,
  testId: PropTypes.string,
  isEmptyPlaceholder: PropTypes.node,
  selectedItemId: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
  arrowsShift: PropTypes.bool,
  disabled: PropTypes.bool,
};

CfSwitcher.defaultProps = {
  getItemViewValue: null,
  testId: "crop-switcher",
  selectedItemId: null,
  arrowsShift: false,
  disabled: false,
  items: [],
  isEmptyPlaceholder: undefined,
};

const styles = (theme) => {
  const { breakpoints } = theme;
  return {
    wrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      width: "100%",
    },
    button: {},
    menuItem: {
      fontSize: 16,
    },
    arrowButton: {
      padding: 4,
    },
    buttonPanel: {
      padding: 0,
    },
    [breakpoints.down("xs")]: {
      wrapper: {
        justifyContent: "center",
      },
    },
  };
};

export default withStyles(styles)(CfSwitcher);
