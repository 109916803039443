import { RSAA } from "redux-api-middleware";

import * as types from "./taskdata.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../api.constants";

import { RsaaMethods, RsaaTypes } from "../../api.types";
import { IntegrationType } from "../../satellite/satellite.types";

export const getTaskdataIntegrationStatusApi = () => ({
  [RSAA]: {
    endpoint: `integrations?type=${IntegrationType.TASKDATA}&`,
    method: methods.GET as RsaaMethods,
    module: modules.SENTINEL,
    types: [
      types.GET_TASKDATA_INTEGRATION_REQUEST,
      types.GET_TASKDATA_INTEGRATION_SUCCESS,
      types.GET_TASKDATA_INTEGRATION_ERROR,
    ] as RsaaTypes,
  },
});
