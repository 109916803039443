import React from "react";

import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import CfNoData from "../../../../../shared/components/charts/CfNoData/CfNoData";
import HorizontalBarChart, {
  BarChartStripe,
} from "../../../containers/biomonitoring/HorizontalBarChart/HorizontalBarChart";

interface Props {
  data: BarChartStripe[];
  totalAreaHa?: number;
}

const AreaZonesChart = ({ data, totalAreaHa }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      {data && data.length > 0 ? (
        <>
          <div className={classes.header}>
            <span className={classes.headerAreaZone}>
              <FormattedMessage id="ManagementZones.AreaZonesChart.zonesArea.title" />
              {" (ha)"}
            </span>
            <span className={classes.headerTotalArea}>
              <span style={{ fontWeight: 400 }}>
                <FormattedMessage id="ManagementZones.AreaZonesChart.totalArea.title" />
                :
              </span>

              {` ${totalAreaHa ?? 0} ha`}
            </span>
          </div>
          <HorizontalBarChart data={data} />
        </>
      ) : (
        <CfNoData />
      )}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    margin: "0 15px",
    position: "relative",
    minHeight: 60,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginBottom: 12,
  },
  headerAreaZone: {
    fontSize: 14,
    fontWeight: 500,
    whiteSpace: "nowrap",
  },
  headerTotalArea: {
    fontSize: 12,
    fontWeight: 600,
    whiteSpace: "nowrap",
    color: "#848484",
  },
}));

export default AreaZonesChart;
