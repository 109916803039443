import React from "react";

import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import CfSectionHeading from "../../../../shared/components/common/CfSectionHeading/CfSectionHeading";

type Props = {
  headingTranslationId: string;
  testId?: string;
  isModal?: boolean;
};

const SectionHeader = ({ headingTranslationId, isModal, testId }: Props) => {
  const defClasses = useStyles();
  const customClasses = useCustomStyles();
  const modalClasses = useModalStyles();

  return (
    <div className={defClasses.wrapper} data-test={testId}>
      <CfSectionHeading classes={isModal ? modalClasses : customClasses}>
        <FormattedMessage id={headingTranslationId} />
      </CfSectionHeading>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    lineHeight: 1.25,
  },
}));

const useCustomStyles = makeStyles(() => ({
  heading: {
    fontSize: 14,
  },
}));
const useModalStyles = makeStyles(() => ({
  heading: {
    fontSize: 20,
    marginBottom: 4,
  },
}));

export default SectionHeader;
