import React, { useMemo } from "react";

import { makeStyles } from "@mui/styles";
import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getCrops,
  getIsFetchingCrops,
} from "../../../../../shared/api/agroevidence/catalogues/crops/crops.selectors";

import { fetchCrops } from "../../actions/parcels.actions";

import { resetCropsApi } from "../../../../../shared/api/agroevidence/catalogues/crops/crops.api";
import withPopover from "../../../../../shared/hocs/withPopover";
import { Thunk } from "../../../../../types";

import CropAutosuggest from "./CropAutosuggest";

import { ParcelsState } from "../../../../../reducers/parcels.reducer.types";
import {
  CropTo,
  SowingPlanSeasonTo,
} from "../../../../../shared/api/agroevidence/agroevidence.types";

const useStyles = makeStyles(() => ({
  wrapper: {
    height: 250,
    width: 250,
  },
}));

type ParcelCropAssignProps = OwnProps & ReduxProps;
const ParcelCropAssignBulk = ({
  fetchCrops,
  handlePopoverClose,
  isFetching,
  onCropChangeBulk,
  resetCropsApi,
  sowingSeason,
  suggestions,
}: ParcelCropAssignProps) => {
  const classes = useStyles();

  const extendedSuggestions = useMemo(
    () => [
      {
        id: null,
        name: "-",
      },
      ...suggestions,
    ],
    [suggestions],
  );

  const onSuggestionSelect = (cropToAssign: CropTo) => {
    onCropChangeBulk(cropToAssign, sowingSeason.id);
    handlePopoverClose();
  };

  const onSuggestionClear = () => {
    handlePopoverClose();
    resetCropsApi();
  };

  return (
    <div className={classes.wrapper}>
      <CropAutosuggest
        getSuggestions={fetchCrops}
        isFetching={isFetching}
        onSuggestionClear={onSuggestionClear}
        onSuggestionSelect={onSuggestionSelect}
        suggestions={extendedSuggestions}
      />
    </div>
  );
};

const mapStateToProps = (state: ParcelsState) => ({
  suggestions: getCrops(state),
  isFetching: getIsFetchingCrops(state),
});

const mapDispatchToProps = (dispatch: Thunk<ParcelsState>) =>
  bindActionCreators(
    {
      fetchCrops,
      resetCropsApi,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;
type OwnProps = {
  handlePopoverClose: () => void;
  onCropChangeBulk: (crop: CropTo, seasonId: string) => void;
  sowingSeason: SowingPlanSeasonTo;
};

export default withPopover(connector(ParcelCropAssignBulk));
