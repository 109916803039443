import React, { useState } from "react";

import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getSentinelAdminAdvancedFilter,
  getSentinelAdminFarm,
} from "../../../selectors/sentinel.selectors";

import { setAdvancedFilter } from "../../../../../../shared/actions/filter.actions";

import { SentinelAdminAdvancedFilter } from "../../../reducer/sentinelAdmin.reducer";

import CfFilter from "../../../../../../shared/containers/CfFilter/CfFilter";
import { Thunk } from "../../../../../../types";

import { BooleanSelector, BooleanSelectorType } from "./BooleanSelector";
import CropsSelector from "./CropsSelector";

import { AdminState } from "../../../../../../reducers/admin.reducer.types";
import { CropTo } from "../../../../../../shared/api/agroevidence/agroevidence.types";

interface Props {
  advancedFilter: SentinelAdminAdvancedFilter;
  langId: string;
  namespace: string;
}

const _SentinelAdminAdvancedFilter = ({
  advancedFilter,
  langId,
  namespace,
}: Props) => {
  const classes = useStyles();
  const farm = useSelector(getSentinelAdminFarm);

  const [advancedFilterState, setAdvancedFilterState] =
    useState<SentinelAdminAdvancedFilter>(advancedFilter);

  const handleChangeCrops = (items: CropTo[]) => {
    setAdvancedFilterState({ ...advancedFilterState, crops: items });
  };
  const handleHistoryPotential = (items: BooleanSelectorType) => {
    setAdvancedFilterState({
      ...advancedFilterState,
      "growth-potential": items,
    });
  };
  const handleMonitoringGisat = (items: BooleanSelectorType) => {
    setAdvancedFilterState({
      ...advancedFilterState,
      "monitoring-gisat": items,
    });
  };
  const handleMonitoringPlanet = (items: BooleanSelectorType) => {
    setAdvancedFilterState({
      ...advancedFilterState,
      "monitoring-planet": items,
    });
  };
  const handleManagementZones = (items: BooleanSelectorType) => {
    setAdvancedFilterState({
      ...advancedFilterState,
      "management-zones": items,
    });
  };

  const resetInitFilter = () => {
    setAdvancedFilterState({});
  };

  return (
    <CfFilter
      disabled={!farm}
      filterState={advancedFilterState}
      langId={langId}
      namespace={namespace}
      onAccept={resetInitFilter}
      onReset={resetInitFilter}
      setAdvancedFilterState={setAdvancedFilterState}
    >
      <div className={classes.container}>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <CropsSelector
              defaultValues={advancedFilter?.crops || []}
              isMultiple
              label={<FormattedMessage id="common.crop" />}
              onChange={handleChangeCrops}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <BooleanSelector
              defaultValues={advancedFilter["growth-potential"]}
              label={<FormattedMessage id="SentinelAdmin.historyPotential" />}
              onChange={handleHistoryPotential}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <BooleanSelector
              defaultValues={advancedFilter["monitoring-gisat"]}
              onChange={handleMonitoringGisat}
              label={
                <FormattedMessage id="SentinelAdmin.header.advancedFilter.gisat" />
              }
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <BooleanSelector
              defaultValues={advancedFilter["monitoring-planet"]}
              onChange={handleMonitoringPlanet}
              label={
                <FormattedMessage id="SentinelAdmin.header.advancedFilter.planet" />
              }
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <BooleanSelector
              defaultValues={advancedFilter["management-zones"]}
              onChange={handleManagementZones}
              label={
                <FormattedMessage id="SentinelAdmin.header.advancedFilter.zoneManagement" />
              }
            />
          </Grid>
        </Grid>
      </div>
    </CfFilter>
  );
};

const mapStateToProps = (state: AdminState) => ({
  advancedFilter: getSentinelAdminAdvancedFilter(state),
});

const mapDispatchToProps = (dispatch: Thunk<AdminState>) =>
  bindActionCreators(
    {
      setAdvancedFilter,
    },
    dispatch,
  );

const useStyles = makeStyles({
  container: {
    padding: 20,
    minWidth: 300,
    maxWidth: 600,
  },
  select: {
    marginBottom: 10,
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(_SentinelAdminAdvancedFilter);
