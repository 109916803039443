import React from "react";

import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import get from "lodash/get";
import { FormattedMessage } from "react-intl";

import CfFormattedNumber from "../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import CfTableCellNumber from "../../../../../shared/components/tables/CfTableCellNumber/CfTableCellNumber";

import { StatsTableCell } from "./StatsTableCell";

interface Props {
  stats: {
    count: number;
    totalArea: number;
  };
}

const StatsFooter = ({ stats }: Props) => {
  const classes = useStyles();
  return (
    <TableRow>
      <StatsTableCell
        key="name"
        classes={{
          root: classNames(classes.statCellBorderTop, classes.firstCell),
        }}
      >
        <FormattedMessage id="ParcelZoneStatistics.total" />
      </StatsTableCell>
      <StatsTableCell
        key="count"
        classes={{
          root: classNames(classes.statCellBorderTop, classes.cell),
        }}
      >
        <CfTableCellNumber>{stats.count}</CfTableCellNumber>
      </StatsTableCell>
      <StatsTableCell
        key="area"
        classes={{
          root: classNames(classes.statCellBorderTop, classes.cell),
        }}
      >
        <CfTableCellNumber>
          <CfFormattedNumber value={get(stats, "totalArea", null) || 0} />
        </CfTableCellNumber>
      </StatsTableCell>
    </TableRow>
  );
};

export { StatsFooter };

const useStyles = makeStyles({
  statCellBorderTop: {
    borderTop: "1px solid rgba(224, 224, 224, 1)",
  },
  firstCell: {
    width: "40%",
  },
  cell: {
    width: "30%",
  },
});
