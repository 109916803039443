import { RSAAAction } from "redux-api-middleware";

import * as types from "../../../../shared/api/agroevidence/parcels/parcels.constants";

import { getCropsApi } from "../../../../shared/api/agroevidence/catalogues/crops/crops.api";
import { updateParcelApi } from "../../../../shared/api/agroevidence/parcels/parcels.api";
import { putParcelsCropApi } from "../../../../shared/api/agroevidence/parcelsCropDetails/parcelsCropDetails.api";
import { updateParcelSowingPlanApi } from "../../../../shared/api/agroevidence/sowingPlan/sowingPlan.api";

import { ParcelCrops } from "../../../../shared/api/agroevidence/parcelsCropDetails/parcelsCropDetails.types";

export const assignCenter =
  (centerId: string, parcelId: string) =>
  (dispatch: (action: RSAAAction) => void) =>
    dispatch(
      updateParcelApi(parcelId, {
        center: { centerId },
      }) as unknown as RSAAAction,
    );

export const assignZones =
  (zoneIds: string[], parcelId: string) =>
  (dispatch: (action: RSAAAction) => void) =>
    dispatch(updateParcelApi(parcelId, { zoneIds }) as unknown as RSAAAction);

export const assignCrop =
  (cropId: string, seasonId: string, parcelId: string) =>
  (dispatch: (action: RSAAAction) => void) =>
    dispatch(
      updateParcelSowingPlanApi(parcelId, {
        cropId,
        seasonId,
      }) as unknown as RSAAAction,
    );

export const fetchCrops =
  (search?: string) => (dispatch: (action: RSAAAction) => void) => {
    const params = {
      search,
      "include-no-crop": true,
    };
    dispatch(getCropsApi(params));
  };

export const bulkAssignCrop =
  (parcelCrops: ParcelCrops[]) => (dispatch: (action: RSAAAction) => void) =>
    dispatch(putParcelsCropApi(parcelCrops));

export const setFocusedRow = (payload?: string) => ({
  type: types.SET_FOCUSED_ROW,
  payload,
});
