import React, { useState } from "react";

import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { getParcelActivitiesAdvancedFilter } from "../../selectors/parcelActivities.selectors";

import CfFilter from "../../../../../../shared/containers/CfFilter/CfFilter";

import ActivitiesSelector from "./ActivitiesSelector/ActivitiesSelector";

import {
  ActivitiesState,
  ParcelActivitiesListAdvancedFilter,
} from "../../../../../../shared/api/agroevidence/parcels/parcels.types";

const useStyles = makeStyles({
  container: {
    padding: 20,
    minWidth: 300,
    maxWidth: 600,
  },
  select: {
    marginBottom: 10,
  },
});

type Props = {
  langId: string;
  namespace: string;
};

export const ParcelActivitiesAdvancedFilter = ({
  langId,
  namespace,
}: Props) => {
  const classes = useStyles();
  const advancedFilter = useSelector(getParcelActivitiesAdvancedFilter);

  const [advancedFilterState, setAdvancedFilterState] =
    useState<ParcelActivitiesListAdvancedFilter>(advancedFilter);

  const handleChangeActivityName = (items: ActivitiesState[]) => {
    setAdvancedFilterState({ ...advancedFilterState, activities: items });
  };

  return (
    <CfFilter
      filterState={advancedFilterState}
      langId={langId}
      namespace={namespace}
      setAdvancedFilterState={setAdvancedFilterState}
    >
      <div className={classes.container}>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <ActivitiesSelector
              defaultActivities={advancedFilter.activities}
              onChange={handleChangeActivityName}
              label={
                <FormattedMessage id="ParcelActivities.text-filter-placeholder" />
              }
            />
          </Grid>
        </Grid>
      </div>
    </CfFilter>
  );
};

export default ParcelActivitiesAdvancedFilter;
