import React, { Fragment } from "react";

import DownloadIcon from "@mui/icons-material/Download";
import MapIcon from "@mui/icons-material/Map";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { withStyles } from "@mui/styles";
import classnames from "classnames";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { compose } from "react-recompose";

import { ExportButton } from "../../../../../shared/components/common/ExportButton/ExportButton";
import withPopover from "../../../../../shared/hocs/withPopover";
import { ActionsPalette } from "../../palette/ActionsPalette";

const legacyMenuOptions = [
  { type: "COMMON", name: "SHP" },
  { type: "TRIMBLE", name: "SHP - Trimble" },
];

const menuOptions = [
  // Falcon exports are temporarily disabled
  // { type: 'Falcon TIFF', name: 'Falcon TIFF' },
  // { type: 'Falcon VT', name: 'Falcon VT' },
  { type: "AGCO AccuTerminal", name: "AGCO AccuTerminal" },
  { type: "Fendt VarioTerminal", name: "Fendt VarioTerminal" },
  { type: "Ag Leader TGT Publisher", name: "Ag Leader TGT Publisher" },
  { type: "AgLeader IRX", name: "AgLeader IRX" },
  { type: "AgLeader AGSETUP", name: "AgLeader AGSETUP" },
  { type: "AgLeader InCommand", name: "AgLeader InCommand" },
  { type: "AgView", name: "AgView" },
  { type: "AIM", name: "AIM" },
  { type: "Case Voyager2", name: "Case Voyager2" },
  { type: "Case Voyager2 Raster", name: "Case Voyager2 Raster" },
  { type: "Generic ISOXML Type 1", name: "Generic ISOXML Type 1" },
  { type: "Generic ISOXML Type 2", name: "Generic ISOXML Type 2" },
  { type: "Pro700 TaskData", name: "Pro700 TaskData" },
  { type: "Pro700 Shapefile", name: "Pro700 Shapefile" },
  { type: "John Deere GS1", name: "John Deere GS1" },
  {
    type: "John Deere GS2/GS3 Rx Shapefile",
    name: "John Deere GS2/GS3 Rx Shapefile",
  },
  { type: "John Deere GS2", name: "John Deere GS2" },
  { type: "John Deere GS3", name: "John Deere GS3" },
  { type: "John Deere Global Rx", name: "John Deere Global Rx" },
  { type: "Kinze BlueVantage", name: "Kinze BlueVantage" },
  {
    type: "Kinze Multi-Hybrid Shapefile",
    name: "Kinze Multi-Hybrid Shapefile",
  },
  { type: "Shapefile", name: "Shapefile" },
  { type: "Mid-Tech", name: "Mid-Tech" },
  { type: "Muller ISOXML", name: "Muller ISOXML" },
  { type: "Precision 20/20 Shapefile", name: "Precision 20/20 Shapefile" },
  { type: "Raven Viper", name: "Raven Viper" },
  { type: "Satloc-G4", name: "Satloc-G4" },
  { type: "Satloc-Bantam", name: "Satloc-Bantam" },
  { type: "SiteMate", name: "SiteMate" },
  { type: "TeeJet ISOXML", name: "TeeJet ISOXML" },
  {
    type: "Trimble AgGPS 170 Prescriptions",
    name: "Trimble AgGPS 170 Prescriptions",
  },
  { type: "Trimble FMD", name: "Trimble FMD" },
  { type: "Trimble FMX", name: "Trimble FMX" },
];

const styles = (theme) => ({
  menuPaper: {
    marginLeft: 10,
  },
  button: {},
  menuSectionHeading: {
    color: theme.palette.grey[500],
    textTransform: "uppercase",
    fontWeight: 700,
    opacity: "1 !important",
    margin: "12px 0 2px",
  },
  legacyOption: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  legacyOptionIcon: {
    color: ActionsPalette.neutral.main,
  },
});

export function VariableActionExport({
  anchorEl,
  buttonText,
  classes,
  customStyle,
  handlePopoverClose,
  handlePopoverOpen,
  iconButton,
  isDisabled,
  isLoading,
  isOpen,
  onExport,
}) {
  return (
    <Fragment>
      {iconButton ? (
        <ExportButton
          customIcon={<MapIcon />}
          disabled={isDisabled}
          isLoading={isLoading}
          onClick={handlePopoverOpen}
          tooltipTitle={
            <FormattedMessage id="ActionFormHeader.exportVariableMap" />
          }
        />
      ) : (
        <Button
          className={classnames(classes.button, customStyle.exportButton)}
          color="secondary"
          disabled={isDisabled || isLoading}
          id="create_and_export"
          onClick={handlePopoverOpen}
          startIcon={<MapIcon />}
          variant="contained"
        >
          {buttonText}
        </Button>
      )}
      <Menu
        anchorEl={anchorEl}
        className={classes.menuPaper}
        disableScrollLock={true}
        onClose={handlePopoverClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: iconButton ? "right" : "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: iconButton ? "right" : "left",
        }}
      >
        <MenuItem classes={{ root: classes.menuSectionHeading }} disabled>
          <FormattedMessage id="Vra.exportList.legacyHeader" />
        </MenuItem>
        {legacyMenuOptions.map((option) => (
          <MenuItem
            disabled={false}
            key={option.type}
            onClick={() => {
              onExport(option.type);
              handlePopoverClose();
            }}
          >
            <div className={classes.legacyOption}>
              <span>{option.name}</span>
              <DownloadIcon className={classes.legacyOptionIcon} />
            </div>
          </MenuItem>
        ))}
        <MenuItem classes={{ root: classes.menuSectionHeading }} disabled>
          <FormattedMessage id="Vra.exportList.header" />
        </MenuItem>
        {menuOptions.map((option) => (
          <MenuItem
            disabled={false}
            key={option.type}
            onClick={() => {
              onExport(option.type);
              handlePopoverClose();
            }}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
}

VariableActionExport.propTypes = {
  handlePopoverOpen: PropTypes.func.isRequired,
  handlePopoverClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  anchorEl: PropTypes.any,
  isDisabled: PropTypes.bool,
  onExport: PropTypes.func.isRequired,
  iconButton: PropTypes.bool,
  classes: PropTypes.object,
  isLoading: PropTypes.bool,
  customStyle: PropTypes.object,
  buttonText: PropTypes.node,
};

VariableActionExport.defaultProps = {
  anchorEl: null,
  isDisabled: false,
  iconButton: false,
  classes: {},
  isLoading: false,
  customStyle: {},
  buttonText: null,
};

export default compose(withStyles(styles), withPopover)(VariableActionExport);
