import React, { ReactNode } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Icon,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  Tooltip,
  Theme,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import { FORM_TYPES } from "../../../../evidence/actions/ActionOthers/actionOther.constants";

type Props = {
  children: ReactNode;
  details: ReactNode;
  formType: string;
  hasWarning: boolean;
  isDeleting: boolean;
  isEditing: boolean;
  isExisting: boolean;
  isSown: boolean;
  handleRemoveItem: () => void;
};

export const SelectionItemAccordion = ({
  children,
  details,
  formType,
  handleRemoveItem,
  hasWarning,
  isDeleting,
  isEditing,
  isExisting,
  isSown,
}: Props) => {
  const classes = useStyles(hasWarning);
  const isHarvestForm = formType === FORM_TYPES.HARVEST;
  const isMowingForm = formType === FORM_TYPES.MOWING;
  const mustBeSown = isHarvestForm || isMowingForm;

  const isNewNotSownParcel = !isSown && !isExisting;

  let warningMessage = "";
  if (isNewNotSownParcel) {
    if (isHarvestForm) {
      warningMessage = "action.harvest.catchParcelNotSown";
    } else if (isMowingForm) {
      warningMessage = "action.mowing.catchParcelNotSown";
    }
  }

  return (
    <Accordion
      classes={{ root: classes.accordion, expanded: classes.expanded }}
      data-test="item-root"
    >
      <AccordionSummary
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          expandIconWrapper: classes.expandIcon,
        }}
        expandIcon={
          mustBeSown && isNewNotSownParcel ? (
            <div className={classes.iconWrapper} />
          ) : (
            <ExpandMoreIcon data-test="expand-item" />
          )
        }
      >
        <div className={classes.content}>{children}</div>
        {mustBeSown && (
          <div className={classes.iconWrapper}>
            {isNewNotSownParcel && (
              <Tooltip title={<FormattedMessage id={warningMessage} />}>
                <Icon className={classes.infoIcon}>warning</Icon>
              </Tooltip>
            )}
          </div>
        )}
        <IconButton
          aria-label="Remove item"
          className={classes.removeButton}
          disabled={!isEditing}
          onClick={!isDeleting ? handleRemoveItem : undefined}
          size="large"
        >
          {!isDeleting ? (
            <ClearIcon />
          ) : (
            <CircularProgress className={classes.progress} size={20} />
          )}
        </IconButton>
      </AccordionSummary>
      {!(mustBeSown && isNewNotSownParcel) && (
        <AccordionDetails className={classes.details}>
          {details}
        </AccordionDetails>
      )}
    </Accordion>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    flexGrow: 100,
    margin: 0,
    padding: 6,
    "&$expanded": {
      margin: 0,
    },
  },
  removeButton: {
    alignSelf: "center",
    height: 36,
    width: 36,
    padding: 0,
    marginLeft: 6,
  },
  iconWrapper: {
    alignSelf: "center",
    width: 24,
    height: 24,
  },
  progress: {
    color: theme.palette.grey[500],
  },
  infoIcon: {
    color: theme.palette.secondary.main,
  },
  accordion: {
    margin: "0px 0px 12px 0px",
    "&$expanded": {
      margin: "0px 0px 12px 0px",
    },
  },
  root: {
    backgroundColor: (hasWarning) =>
      hasWarning ? theme.palette.secondary.light : theme.palette.grey[100],
    minHeight: 48,
    fontSize: 16,
    "&$expanded": {
      minHeight: 48,
    },
  },
  expandIcon: {
    padding: 6,
    order: -1,
  },
  expanded: {},
  details: {
    display: "block",
    padding: "8px 25px 8px",
  },
}));
