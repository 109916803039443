import angular from "angular";
import * as Sentry from "@sentry/browser";
import { Angular as AngularIntegration } from "@sentry/integrations";

import Localization from "./shared/services/Localization.service";

import { createAppModule, fetchConfig } from "./app.config";
import { setup } from "./shared/api/client";
import "./assets/less/main.less";

setup();

// create and export the main app module
const appModule = createAppModule();
export default appModule.name;

// fetch configuration files asynchronously and inject the included constants, bootstrap the angular afterwards
fetchConfig()
  .then((jsonConfig) => {
    appModule
      .constant("BACKEND_OPTIONS", jsonConfig.BACKEND_OPTIONS)
      .constant("ENVIRONMENT", jsonConfig.ENVIRONMENT)
      .constant("PRODUCT_FRUITS_ID", jsonConfig.PRODUCT_FRUITS_ID);
    return jsonConfig;
  })
  .then(bootstrapApplication);

function bootstrapApplication(configuration) {
  Localization.initLangInLocalStorage();

  angular.element(document).ready(() => {
    configureSentry(configuration);
    angular.bootstrap(document, ["app"], {
      strictDi: true,
    });
    $.material.init();
  });
}

function configureSentry(configuration) {
  const nodeEnv = process.env.NODE_ENV;
  console.info("APP VERSION:", process.env.BUILD_INFO_APP_COMMIT);
  Sentry.init({
    dsn: configuration.BACKEND_OPTIONS.sentryDsn,
    integrations: [
      new AngularIntegration(),
      // new Sentry.Replay({
      //   maskAllText: true,
      //   blockAllMedia: true,
      // }),
    ],
    environment: configuration.ENVIRONMENT,
    release: process.env.BUILD_INFO_APP_VERSION,
    // Session Replay:
    // replaysSessionSampleRate: 0, // doesn't capture any session for no reason
    // replaysOnErrorSampleRate: 1.0, // captures all sessions with unhandled exceptions
    beforeSend(event) {
      // send only in production, by returning null, it discards the event
      if (nodeEnv !== "production") {
        return null;
      }
      return event;
    },
  });
}
