import React, { ReactNode, useCallback } from "react";

import { Chip } from "@mui/material";
import { AutocompleteRenderGetTagProps } from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material/useAutocomplete";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";

import { selectShortDateRange } from "../../../selectors/telematicsTabs.selectors";

import { EquipmentTo } from "../../../../generated/api/telematics";
import { useFarmIds } from "../../../../shared/api/client.utils";
import CfAutocomplete from "../../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import useAdvancedFilterStyles from "../TelematicsAdvancedFilter.styles";

import { equipmentsQuery } from "./EquipmentSelector.api";

type Props = {
  defaultValues?: EquipmentTo[];
  externalClasses?: Record<string, string>;
  label: ReactNode;
  onChange: (items: EquipmentTo[]) => void;
};

const handleGetSelected = (option: EquipmentTo, value?: EquipmentTo | null) =>
  option.code === value?.code;

const handleFilterOptions = createFilterOptions({
  stringify: ({ code, name }) => `${name} ${code}`,
});

const EquipmentSelector = ({
  defaultValues = [],
  externalClasses = {},
  label,
  onChange,
}: Props) => {
  const { dateFrom, dateTo } = useSelector(selectShortDateRange);
  const classes = useAdvancedFilterStyles();
  const equipments = useSuspenseQuery(
    equipmentsQuery({ dateFrom, dateTo, ...useFarmIds() }),
  );

  const handleRenderOption = useCallback(
    (option: EquipmentTo) => (
      <div>
        <div>{option.name ?? option.code}</div>
        <div className={classes.subtext}>{option.code}</div>
      </div>
    ),
    [classes],
  );

  const handleRenderTags = useCallback(
    (values: EquipmentTo[], getTagProps: AutocompleteRenderGetTagProps) => (
      <>
        {values.map((value, index) => (
          <Chip
            {...getTagProps({ index })}
            key={value.id ?? value.code}
            label={
              <>
                {value.name ?? value.code}
                <span className={classes.subtext}>{value.code}</span>
              </>
            }
          />
        ))}
      </>
    ),
    [classes],
  );

  return (
    <CfAutocomplete
      classes={externalClasses}
      defaultValues={defaultValues}
      filterOptions={handleFilterOptions}
      getSelected={handleGetSelected}
      id="equipment-selector"
      isMultiple={true}
      label={label}
      onChange={onChange}
      renderOption={handleRenderOption}
      renderTags={handleRenderTags}
      suggestions={equipments.data.data}
      testId="equipment-filter"
    />
  );
};

export default EquipmentSelector;
