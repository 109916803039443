import { AnyAction } from "redux";

import * as types from "./reports.constants";

export type coreReportsType = {
  isFetchingSowingPlan: boolean;
};

const initialState: coreReportsType = {
  isFetchingSowingPlan: false,
};

export default () =>
  (state = initialState, action: AnyAction) => {
    switch (action.type) {
      case types.GET_SOWING_PLAN:
        return {
          ...state,
          isFetchingSowingPlan: true,
        };
      case types.GET_SOWING_PLAN_SUCCESS:
        return {
          ...state,
          isFetchingSowingPlan: false,
        };
      case types.GET_SOWING_PLAN_ERROR:
        return {
          ...state,
          isFetchingSowingPlan: false,
        };
      default:
        return state;
    }
  };
