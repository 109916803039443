import React from "react";

import ExtensionIcon from "@mui/icons-material/Extension";
import { IconButton, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

import { setFarmFilter } from "../../../actions/sentinel.actions";

import { useToggle } from "../../../../../../shared/hooks/useToggle";
import { FarmSwitcher } from "../../../../shared/containers/FarmSwitcher/FarmSwitcher";

import IntegrationManagementDialog from "./IntegrationManagementDialog";

import { AdminFarm } from "../../../../sensors/admin.sensors.types";

type Props = {
  farm?: AdminFarm;
};

const RightSide = ({ farm }: Props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    on: extensionDialogIsOpen,
    setOff: extensionDialogOff,
    setOn: extensionDialogOn,
  } = useToggle();

  return (
    <>
      <Tooltip
        title={
          <FormattedMessage id="SentinelAdmin.integrationManagement.title" />
        }
      >
        <span>
          <IconButton
            className={classes.extensionBtn}
            disabled={!farm}
            onClick={() => extensionDialogOn()}
            size="small"
          >
            <ExtensionIcon />
          </IconButton>
        </span>
      </Tooltip>
      <FarmSwitcher
        farm={farm}
        onFarmSelect={(farm) => dispatch(setFarmFilter(farm))}
      />
      {extensionDialogIsOpen && (
        <IntegrationManagementDialog farm={farm} onClose={extensionDialogOff} />
      )}
    </>
  );
};

const useStyles = makeStyles(() => ({
  extensionBtn: {
    padding: 7,
    "& > svg": {
      width: 18.5,
      height: 18.5,
    },
  },
}));

export { RightSide };
