import React, { useCallback } from "react";

import { setFocusedRow } from "../../../../shared/actions/parcels.actions";

import CfBackButton from "../../../../../../shared/components/common/CfBackButton/CfBackButton";
import useFocusedTableRowSource from "../../../../../../shared/hooks/useFocusedTableRowSource";
import { AnyTodo } from "../../../../../../types";

type Props = {
  farmId: string;
  history: AnyTodo;
  parcelId: string;
};

const ParcelDetailButtonBack = ({ farmId, history, parcelId }: Props) => {
  const { doSetFocusedRow } = useFocusedTableRowSource({
    sourceId: parcelId,
    setFocusedRow,
  });

  const handleGoBack = useCallback(() => {
    doSetFocusedRow();
    history.push(`/farm/${farmId}/parcels`);
  }, [doSetFocusedRow, history, farmId]);

  return (
    <CfBackButton
      onClick={handleGoBack}
      translId="ParcelDetail.backToParcels"
    />
  );
};

export { ParcelDetailButtonBack };
