import React, { PropsWithChildren } from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Button, { ButtonProps } from "@mui/material/Button";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

type Props = {
  transparent?: boolean;
  id?: string;
  classes?: Record<string, string>;
  withArrowDropDownIcon?: boolean;
} & ButtonProps;

const CfSwitcherButton = ({
  children,
  classes: propClasses = {},
  id = "switcher-button",
  transparent = false,
  withArrowDropDownIcon = true,
  ...rest
}: PropsWithChildren<Props>) => {
  const classes = useStyles({ withArrowDropDownIcon });

  return (
    <Button
      id={id}
      size="small"
      variant={transparent ? "text" : "contained"}
      {...rest}
      classes={{
        root: `${transparent ? classes.transparent : classes.root} ${
          propClasses.custom || classes.custom
        }`,
      }}
    >
      {children}
      {withArrowDropDownIcon && (
        <ArrowDropDownIcon classes={{ root: classes.icon }} />
      )}
    </Button>
  );
};

const useStyles = makeStyles<Theme, { withArrowDropDownIcon: boolean }>(
  (theme) => ({
    root: ({ withArrowDropDownIcon }) => ({
      backgroundColor: theme.palette.grey[300],
      padding: withArrowDropDownIcon ? "4px 4px 4px 12px" : "4px 0px",
      borderRadius: 50,
      fontSize: 14,
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      },
      "&:active": {
        boxShadow: "none",
      },
      "&.Mui-disabled": {
        backgroundColor: "#C5C5C5",
      },
    }),
    transparent: {
      textTransform: "none",
      padding: "0px 0px 0px 9px",
      minWidth: 48,
    },
    custom: {},
    icon: {},
  }),
);

export default CfSwitcherButton;
