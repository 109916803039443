import React from "react";

import { FieldInputProps, FormikProps } from "formik";
import moment from "moment";

import { useTypedIntl } from "../../../hooks/useTypedIntl";
import CfDatePickerTranslator from "../CfDatePickerTranslator/CfDatePickerTranslator";

interface Props {
  children: React.ReactNode;
  field: FieldInputProps<string>;
  form: FormikProps<Record<string, unknown>>;
  format: string;
}

const CfFormikDatePicker = (props: Props) => {
  const { children, field, form, format, ...restProps } = props;

  const intl = useTypedIntl();

  const error = form.errors[field.name];
  return (
    <CfDatePickerTranslator
      cancelLabel={intl.formatMessage({ id: "common.cancel" })}
      clearLabel={intl.formatMessage({ id: "common.clear" })}
      error={Boolean(error)}
      format={format || "L"}
      helperText={error}
      invalidDateMessage=""
      momentLocale={moment().locale()}
      name={field.name}
      okLabel={intl.formatMessage({ id: "common.select" })}
      placeholder={moment().startOf("year").format("L")}
      value={field.value}
      onChange={(inputVal: moment.Moment) => {
        form.setFieldValue(field.name, inputVal);
      }}
      {...restProps}
    >
      {children}
    </CfDatePickerTranslator>
  );
};

export default CfFormikDatePicker;
