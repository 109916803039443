import React from "react";

import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";

import { COLOR_FONT } from "../../../../theme";

interface Props {
  mobile: boolean;
  icon?: React.JSX.Element;
  onClick: () => void;
  dense: boolean;
  label: React.ReactNode | string;
  extraContent?: React.JSX.Element;
  iconStyle?: Record<string, string>;
  secondaryLevel: boolean;
}

const ContextMenuItem = ({
  dense = true,
  extraContent,
  icon,
  iconStyle,
  label = "",
  mobile = false,
  onClick,
  secondaryLevel = false,
  ...otherProps
}: Props) => {
  const classes = useStyles();

  return (
    <MenuItem
      className={classes.menuItemLink}
      dense={dense}
      onClick={onClick}
      classes={{
        gutters: mobile ? classes.mobileGutters : "",
      }}
      {...otherProps}
    >
      {secondaryLevel && <div className={classes.indent} />}
      {icon && <ListItemIcon style={iconStyle}>{icon}</ListItemIcon>}
      <ListItemText
        classes={{ primary: classes.primaryText }}
        primary={label}
      />
      {extraContent}
    </MenuItem>
  );
};

export { ContextMenuItem };

const useStyles = makeStyles(() => ({
  menuItemLink: {
    "&:hover": {
      textDecoration: "none",
      color: COLOR_FONT.main,
    },
    "&:focus": {
      textDecoration: "none",
      color: COLOR_FONT.main,
      outline: "none",
    },
  },
  primaryText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  mobileGutters: {
    paddingLeft: 32,
    paddingRight: 32,
  },
  indent: {
    width: 16,
  },
}));
