import React, { ReactNode } from "react";

import WarningIcon from "@mui/icons-material/Warning";
import { Theme } from "@mui/material";
import Card from "@mui/material/Card";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

type Props = {
  children: ReactNode;
  customClasses: string;
};

const CfWarningCard = ({ children, customClasses }: Props) => {
  const classes = useStyles();
  return (
    <div test-id="warning">
      <Card className={classNames(classes.card, customClasses)}>
        <WarningIcon className={classes.icon} />
        <p className={classes.content}>{children}</p>
      </Card>
    </div>
  );
};

export default CfWarningCard;

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.secondary.light,
    justifyContent: "center",
    padding: "8px 24px",
    marginBottom: theme.spacing(2),
  },
  icon: {
    color: theme.palette.secondary.dark,
    marginRight: 8,
  },
  content: {
    margin: 0,
    fontSize: 13,
  },
}));
