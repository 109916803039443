import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

type Props = {
  htmlColor: string;
};

const TractorWifiIcon = ({ htmlColor }: Props) => (
  <SvgIcon>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6388_5506)">
        <path
          d="M12.2163 0.442401L12.1727 2.54306C17.3928 2.43467 21.5377 6.5796 21.4293 11.7997L23.53 11.7561C23.6624 5.38062 18.5969 0.315168 12.2163 0.442401ZM12.1291 4.64371L12.0854 6.74436C14.9843 6.68417 17.2882 8.98806 17.228 11.887L19.3287 11.8433C19.4129 7.78908 16.1885 4.56467 12.1291 4.64371Z"
          fill={`${htmlColor}`}
        />
        <path
          d="M15.3215 17.37V14.0391C15.3215 13.3625 14.8406 12.8421 14.2154 12.8421H13.2535V9.97965H11.6184V12.7901H10.368L8.25196 8.67853C8.05959 8.26218 7.62677 8.00195 7.19394 8.00195H6.47256H4.16414C3.53894 8.054 3.00993 8.62649 3.00993 9.30307V14.0391V15.028C2.38474 15.7566 2 16.7454 2 17.8384C2 20.1283 3.73131 22.002 5.84736 22.002C7.33821 22.002 8.68479 21.0652 9.30999 19.6599H10.9451C11.1375 20.9611 12.1955 22.002 13.4459 22.002C14.8406 22.002 15.9948 20.7529 15.9948 19.2436C16.0429 18.515 15.7543 17.8904 15.3215 17.37ZM5.89545 20.2324C4.69315 20.2324 3.68322 19.1395 3.68322 17.8384C3.68322 16.4852 4.69315 15.4443 5.89545 15.4443C7.09775 15.4443 8.10769 16.5373 8.10769 17.8384C8.10769 19.1395 7.09775 20.2324 5.89545 20.2324ZM13.494 20.2324C13.0131 20.2324 12.5802 19.8161 12.5802 19.2436C12.5802 18.7231 12.965 18.2547 13.494 18.2547C13.9749 18.2547 14.4077 18.6711 14.4077 19.2436C14.3596 19.8161 13.9749 20.2324 13.494 20.2324Z"
          fill={`${htmlColor}`}
        />
      </g>
      <defs>
        <clipPath id="clip0_6388_5506">
          <rect fill={`${htmlColor}`} height="24" width="24" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
export { TractorWifiIcon };
