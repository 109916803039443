import React from "react";

import MuiTableCell from "@mui/material/TableCell";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

interface Props {
  children: React.ReactNode;
  classes?: Record<string, string>;
}

const StatsTableCell = ({ children, classes: propClasses = {} }: Props) => {
  const classes = useStyles();
  return (
    <MuiTableCell
      className={classNames(classes.root, propClasses?.root)}
      padding="normal"
    >
      <span className={classes.statCellPadding}>{children}</span>
    </MuiTableCell>
  );
};

export { StatsTableCell };

const useStyles = makeStyles({
  root: {
    borderBottom: 0,
  },
  statCellPadding: {
    padding: "0 9px",
    display: "block",
  },
});
