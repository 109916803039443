import { LANGUAGE_ID } from "../../../../shared/lang/lang.constants";

import { getShortDateString } from "../../../../shared/misc/timeHelpers";
import Localization from "../../../../shared/services/Localization.service";
import { AnyTodo } from "../../../../types";

import {
  ParcelTo,
  PlantProtectionEffectiveness,
} from "../../../../shared/api/agroevidence/agroevidence.types";

export const mapRequestBodyVRSTo = (
  values: AnyTodo,
  locale: LANGUAGE_ID,
  existingAction: AnyTodo,
) => {
  const expense = values.expenses[0]; // VRS should have only one
  const payload: AnyTodo = {
    date: getShortDateString(values.actionDate),
    note: values.actionNote,
    parcels: mapParcels(values.parcels),
    seedExpense: {
      actionMaterial: {
        materialId: expense.material.id,
      },
      actionExpense: {
        amount: Localization.str2numNonFixed(
          expense.variableExpense.totalDose,
          locale,
        ),
        unit: expense.doseUnit.id,
        dosePerHa: Localization.str2numNonFixed(
          expense.variableExpense.doseHa,
          locale,
        ),
      },
    },
    seedApplicationType: values.isCatchCrop ? "CATCH_CROP" : "MAIN_CROP",
  };

  if (existingAction) {
    payload.id = existingAction.id;
    payload.seedExpense.actionMaterial.id =
      existingAction.seedExpense.actionMaterial.id;
  }

  if (values.cropUseType) {
    payload.cropUseType = values.cropUseType.cropUseType;
  }

  return payload;
};

export const mapRequestBodyEphActionTo = (
  values: AnyTodo,
  actionId: string,
) => {
  const payload: AnyTodo = {
    date: getShortDateString(values.actionDate),
    note: values.actionNote,
    parcels: mapParcels(values.parcels),
    targetCropId: values.targetCrop.id,
    isStrawDecay: values.isStrawDecay || false,
    plantProtections: mapPlantProtection(values.expenses, values.parcels),
    fertilizers: mapFertilizers(values.expenses, values.actionDate),
  };

  if (actionId !== undefined) {
    payload.id = actionId;
  }

  return payload;
};

const mapPlantProtection = (expenses: AnyTodo, parcels: AnyTodo) => {
  const mapPlantProtection = expenses
    .filter((exp: AnyTodo) => exp.material.materialTypeId === "CH")
    .map((exp: AnyTodo) => ({
      actionMaterial: {
        materialId: exp.material.id,
        driftClass: exp.driftClass,
      },
      actionExpense: {
        amount: exp.dosage,
        unit: exp.doseUnit.id,
        dosePerHa: exp.dose,
        notes: exp.note,
      },
      targetOrganismsIds: exp.pests.map((item: AnyTodo) => item.id),
      parcelPlantProtectionEffectiveness: exp.effectiveness
        ? exp.effectiveness.map((item: AnyTodo) => ({
            parcelId: item.parcelId,
            effectiveness: item.value,
          }))
        : parcels.map((item: AnyTodo) => ({
            parcelId: item.id,
            effectiveness: PlantProtectionEffectiveness.YES,
          })),
    }));
  return mapPlantProtection || [];
};

const mapFertilizers = (expenses: AnyTodo, actionDate: AnyTodo) => {
  const mapFertilizers = expenses
    .filter((exp: AnyTodo) => exp.material.materialTypeId === "FR")
    .map((exp: AnyTodo) => {
      const mapExp = {
        actionMaterial: {
          materialId: exp.material.id,
          dateSoilIncorporation: mapDateSoilIncorporation(
            exp.incorporationDay,
            actionDate,
          ),
        },
        actionExpense: {
          amount: exp.variableExpense
            ? exp.variableExpense.totalDose
            : exp.dosage,
          unit: exp.doseUnit.id,
          dosePerHa: exp.variableExpense
            ? exp.variableExpense.doseHa
            : exp.dose,
          notes: exp.note,
        },
      };
      return mapExp;
    });
  return mapFertilizers || [];
};

const mapDateSoilIncorporation = (
  incorporationDay: AnyTodo,
  actionDate: AnyTodo,
) => {
  if (incorporationDay === "SAME_DAY") {
    return getShortDateString(actionDate);
  } else if (incorporationDay === "NEXT_DAY") {
    const dayAfterActionDate = actionDate.clone().add(1, "day");
    return getShortDateString(dayAfterActionDate);
  } else {
    return null;
  }
};

export const createNewVrfTo = (values: AnyTodo) =>
  values.expenses.map((expObj: AnyTodo) => expObj.variableExpense);

const mapParcels = (parcels: ParcelTo[]) =>
  parcels.map((parcel) => ({
    parcelId: parcel.id,
    restrictions: mapEPHParcelSubtractableAreas(parcel),
  }));

const mapEPHParcelSubtractableAreas = (parcel: AnyTodo) => {
  const filteredAreas = parcel.subtractableAreas.filter(
    (sa: AnyTodo) => sa.isUsed === true,
  );

  if (filteredAreas.length === 0) {
    return [];
  }

  return filteredAreas.map((sa: AnyTodo) => ({
    type: sa.type,
    value: sa.value,
  }));
};
