import React, { FC, ReactNode, useEffect, useCallback, useMemo } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getIsFetchingParcels,
  getParcels,
} from "../../../shared/api/agroevidence/parcels/parcels.selectors";

import {
  getParcelsApi,
  ParcelsApiParams,
  resetParcelsApi,
} from "../../../shared/api/agroevidence/parcels/parcels.api";
import CfAutocomplete from "../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import { Thunk } from "../../../types";

import { TelematicsState } from "../../../reducers/telematics.reducer.types";
import {
  ParcelStatus,
  ParcelTo,
} from "../../../shared/api/agroevidence/agroevidence.types";

interface Props {
  error?: boolean;
  getParcelsApi(params: ParcelsApiParams): void;
  isEditing: boolean;
  isFetching: boolean;
  label: ReactNode;
  onChange(value: ParcelTo | null): void;
  parcelSuggestions: ParcelTo[];
  resetParcelsApi(): void;
  validToDate: string;
}

const handleRenderOption = (option: ParcelTo) =>
  `${option.blockNumber}, ${option.localName}`;

const handleGetSelected = (option: ParcelTo, value?: ParcelTo | null) =>
  option.localName === value?.localName;

const handleGetLabel = (option?: ParcelTo | null) => {
  if (!option) return "";
  if (option.blockNumber) return `${option.blockNumber} (${option.localName})`;
  return option.localName || "";
};

const TelematicsParcelSelector: FC<Props> = ({
  error,
  getParcelsApi,
  isEditing,
  isFetching,
  label,
  onChange,
  parcelSuggestions,
  resetParcelsApi,
  validToDate,
}) => {
  const fetchParcels = useCallback(
    (localNameBlockNr) => {
      getParcelsApi({
        search: localNameBlockNr,
        page: 1,
        "per-page": 1000,
        "valid-from": validToDate,
        "valid-to": validToDate,
        status: [ParcelStatus.OPEN, ParcelStatus.RETIRED],
      });
    },
    [validToDate, getParcelsApi],
  );

  const filteredParcelSuggestions = useMemo(
    () =>
      parcelSuggestions.filter(
        (parcel) =>
          parcel.status === ParcelStatus.OPEN ||
          (parcel.status === ParcelStatus.RETIRED &&
            parcel.validTo !== undefined &&
            parcel.validTo > validToDate),
      ),
    [parcelSuggestions, validToDate],
  );

  useEffect(() => {
    fetchParcels("");

    return () => {
      resetParcelsApi();
    };
  }, [fetchParcels, resetParcelsApi]);

  return (
    <CfAutocomplete
      disabled={!isEditing}
      error={!!error}
      getLabel={handleGetLabel}
      getSelected={handleGetSelected}
      id="parcel-selector"
      isFetching={isFetching}
      label={label}
      loadOptions={fetchParcels}
      onChange={onChange}
      renderOption={handleRenderOption}
      suggestions={filteredParcelSuggestions}
      testId="parcel-filter"
    />
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  parcelSuggestions: getParcels()(state),
  isFetching: getIsFetchingParcels()(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      getParcelsApi,
      resetParcelsApi,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TelematicsParcelSelector);
