import React from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import { COLOR_GREY } from "../../../../theme";
import { IndiceType } from "../../selectors/indices";
import { zonesColors } from "../../services/monitoringZonesColors";

import { IndexType } from "../../../../shared/api/satellite/satellite.types";

interface Props {
  index: IndiceType;
}

const BioMonitoringZonesLegend = ({ index }: Props) => {
  const classes = useStyles();

  const getIdValue = (id: number) => {
    if (index === IndexType.SAVI) {
      if (id === 1) {
        return <FormattedMessage id="BioMonitoring.history.bareClay" />;
      } else {
        return (
          <>
            <FormattedMessage id="BioMonitoring.history.zone" />
            {` ${id - 1}`}
          </>
        );
      }
    }
    return (
      <>
        <FormattedMessage id="BioMonitoring.history.zone" />
        {` ${id}`}
      </>
    );
  };

  return (
    <div className={classes.legendWrapper}>
      {zonesColors[index].map((zoneColor) => (
        <span
          className={classes.legendItem}
          key={`legend_zone_${zoneColor.id}`}
        >
          <span
            className={classes.colorRectangle}
            style={{
              backgroundColor: zoneColor.color,
              border: zoneColor?.borderColor
                ? `1px solid ${COLOR_GREY[200]}`
                : "",
            }}
          />
          <span className={classes.label}>{getIdValue(zoneColor.id)}</span>
        </span>
      ))}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  legendWrapper: {
    display: "flex",
    flexWrap: "wrap",
  },
  legendItem: {
    display: "flex",
    alignItems: "center",
    marginRight: 15,
  },
  colorRectangle: {
    display: "inline-block",
    width: 10,
    height: 10,
    marginRight: 6,
  },
  label: {
    color: theme.palette.grey[500],
  },
}));

export default BioMonitoringZonesLegend;
