import React, { useEffect, useState } from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { isEmpty } from "lodash";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { getPrevPage } from "../../selectors/common.selectors";

import { DAY_CODES, IRRIGATION_URLS } from "../../irrigation.constants";

import CfBackButton from "../../../shared/components/common/CfBackButton/CfBackButton";
import EditableText from "../../../shared/components/common/EditableText/EditableText";
import PageHeader from "../../../shared/components/common/PageHeader/PageHeader";
import PageHeading from "../../../shared/components/common/PageHeading/PageHeading";
import DeviceActivityInfo from "../../../shared/components/specific/DeviceActivityInfo/DeviceActivityInfo";
import { LocationCoordinatesInfo } from "../../../shared/components/specific/LocationCoordinatesInfo/LocationCoordinatesInfo";
import IrrigationPlanIndicators from "../../containers/IrrigationList/areas/components/IrrigationPlanIndicators";
import IrrigationService from "../../services/Irrigation.service";

import { AreaDetail } from "../../../shared/api/irrigation/areas/area.types";
import {
  Device,
  SchedulesType,
} from "../../../shared/api/irrigation/devices/devices.types";

const IRRIGATION_DAYS_SHORT_TO_LONG_FORMAT = {
  Mon: DAY_CODES.MONDAY,
  Tue: DAY_CODES.TUESDAY,
  Wed: DAY_CODES.WEDNESDAY,
  Thu: DAY_CODES.THURSDAY,
  Fri: DAY_CODES.FRIDAY,
  Sat: DAY_CODES.SATURDAY,
  Sun: DAY_CODES.SUNDAY,
} as { [key: string]: string };

interface Props {
  device: Device;
  area: AreaDetail;
  farmId: string;
  updateDeviceName: (deviceId: string, deviceName: string) => void;
}

export const IrrigationDetailHeader = ({
  area,
  device,
  farmId,
  updateDeviceName,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const prevPage = useSelector(getPrevPage);

  const [irrigationDays, setIrrigationDays] = useState<string[]>([]);

  const isActive = IrrigationService.isActive(device);

  useEffect(() => {
    if (!isEmpty(device)) {
      const irrigationDays = device.schedules.reduce(
        (acc: string[], schedule: SchedulesType) => {
          if (schedule.programs.length > 0) {
            acc.push(IRRIGATION_DAYS_SHORT_TO_LONG_FORMAT[schedule.dayOfWeek]);
          }
          return acc;
        },
        [],
      );
      setIrrigationDays(irrigationDays);
    }
  }, [device]);

  const backButtonHandle = () => {
    if (prevPage) {
      history.push(prevPage);
    } else {
      history.push(`/farm/${farmId}/${IRRIGATION_URLS.DEVICES}`);
    }
  };

  return (
    <div>
      <div className={classes.headerWithInfo}>
        <div className={classes.headerWrapper}>
          <PageHeader
            backButton={
              <CfBackButton
                onClick={backButtonHandle}
                translId="IrrigationDetail.goBack"
              />
            }
            heading={
              <PageHeading
                customClass={classes.heading}
                value={
                  <div>
                    <EditableText
                      initialValue={device.name}
                      labelTestId="irrigation-device-name"
                      onConfirmEditing={(newName) =>
                        updateDeviceName(device.id, newName)
                      }
                    />
                    <span className={classes.deviceId}>
                      {device.externalId}
                    </span>
                  </div>
                }
              />
            }
          />
        </div>

        <div className={classes.deviceInfoWrapper}>
          <div className={classes.deviceInfo}>
            {device.activationDate && (
              <span
                className={classes.rowItem}
                data-test="irrigation-device-activity-date"
              >
                <DeviceActivityInfo dateFrom={device.activationDate} />
              </span>
            )}
            {device.geometry && (
              <span
                className={classes.rowItem}
                data-test="irrigation-device-location"
              >
                <LocationCoordinatesInfo
                  geometry={device.geometry}
                  locationOn={isActive}
                />
              </span>
            )}
            <span className={classes.rowItem}>-</span>
            <span
              className={classes.rowItem}
              style={{ color: "#333333", fontWeight: "bold" }}
            >
              {area.name}
            </span>
            <span className={classes.rowItem} style={{ marginRight: 6 }}>
              <FormattedMessage id="Irrigation.deviceDetail.activeDays" />
            </span>
            <div
              className={classes.rowItemDays}
              data-test="irrigation-device-active-days"
            >
              <IrrigationPlanIndicators plan={irrigationDays} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  headerWithInfo: {
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  headerWrapper: {
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  heading: {
    display: "flex",
    flexDirection: "column",
  },
  deviceId: {
    color: theme.palette.grey[500],
    fontSize: 18,
  },
  rowItem: {
    display: "flex",
    marginRight: 12,
    alignItems: "center",
  },
  rowItemDays: {
    width: 200,
  },
  deviceInfo: {
    fontSize: 13,
    display: "flex",
    color: theme.palette.grey[500],
    justifyContent: "center",
    flexWrap: "wrap",
  },
  deviceInfoWrapper: {
    padding: "8px 16px",
  },
}));
