import queryString from "query-string";
import { RSAA } from "redux-api-middleware";

import * as types from "./farms.constants";
import * as sortOrder from "../../../constants/sortOrder.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../api.constants";

import { getStatesForApiCall } from "../../api.helpers";

import { Params } from "./farms.types";
import { RsaaMethods, RsaaTypes } from "../../api.types";
import { PagingFilter } from "../agroevidence.types";

interface FarmsApiParams
  extends Omit<PagingFilter, "pageOffset" | "pageNumber"> {
  pageNumber?: number;
  pageOffset?: number;
  search?: string;
  sortProperty?: string;
}

export const getFarmsApi = (params: FarmsApiParams) => ({
  [RSAA]: {
    endpoint: `admin/farms?${queryString.stringify(params)}&`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: getStatesForApiCall("AGROEVIDENCE_FARMS")("GET_FARMS"),
  },
});

export const resetFarmsApi = () => ({
  type: types.RESET_FARMS,
});

export const getFarmApi = (farmId: string) => ({
  [RSAA]: {
    endpoint: `farms/${farmId}?`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_FARM_REQUEST,
      types.GET_FARM_SUCCESS,
      types.GET_FARM_ERROR,
    ] as RsaaTypes,
  },
});

export const resetFarmApi = () => ({
  type: types.RESET_FARM,
});

// TODO migrate this endpoint to agroevidence module

export const getCountriesApi = (params: Params) => {
  const p = {
    page: params.page || 1,
    "per-page": params.rowsPerPage || 500,
  };

  return {
    [RSAA]: {
      endpoint: `farms/countries?${queryString.stringify(p)}&`,
      method: methods.GET,
      module: modules.CORE,
      types: [
        types.GET_COUNTRIES_REQUEST,
        types.GET_COUNTRIES_SUCCESS,
        types.GET_COUNTRIES_ERROR,
      ],
    },
  };
};

export const resetCountriesApi = () => ({
  type: types.RESET_COUNTRIES,
});

export const createEmptyFarmApi = (farmName: string, countryCode: string) => ({
  [RSAA]: {
    endpoint: `registration/empty?farmName=${encodeURIComponent(
      farmName,
    )}&countryCode=${countryCode}&`,
    method: methods.POST,
    module: modules.AGROEVIDENCE,
    types: [
      types.CREATE_EMPTY_FARM_REQUEST,
      types.CREATE_EMPTY_FARM_SUCCESS,
      types.CREATE_EMPTY_FARM_ERROR,
    ],
  },
});

export const createEagriFarmApi = (farmName: string) => ({
  [RSAA]: {
    endpoint: `registration/eagri?farmName=${encodeURIComponent(
      farmName,
    )}&countryCode=CZ&`,
    method: methods.POST,
    module: modules.AGROEVIDENCE,
    types: [
      types.CREATE_EAGRI_FARM_REQUEST,
      types.CREATE_EAGRI_FARM_SUCCESS,
      types.CREATE_EAGRI_FARM_ERROR,
    ],
  },
});

export const validateFarmFileApi = (
  countryCode: string,
  formData: unknown,
) => ({
  [RSAA]: {
    endpoint: `registration/shapefile/validate?countryCode=${countryCode}&`,
    method: methods.POST,
    noContentType: true,
    body: formData,
    module: modules.AGROEVIDENCE,
    types: [
      types.VALIDATE_FARM_FILE_REQUEST,
      types.VALIDATE_FARM_FILE_SUCCESS,
      types.VALIDATE_FARM_FILE_ERROR,
    ],
  },
});

export const createFarmByFileApi = (
  countryCode: string,
  farmName: string,
  formData: unknown,
) => ({
  [RSAA]: {
    endpoint: `registration/shapefile?countryCode=${countryCode}&farmName=${encodeURIComponent(
      farmName,
    )}&`,
    method: methods.POST,
    noContentType: true,
    body: formData,
    module: modules.AGROEVIDENCE,
    types: [
      types.CREATE_FARM_BY_FILE_REQUEST,
      types.CREATE_FARM_BY_FILE_SUCCESS,
      types.CREATE_FARM_BY_FILE_ERROR,
    ],
  },
});

export const putFarmUpdateLpisApi = (
  farmId: string,
  date: string,
  updateType: string,
) => ({
  [RSAA]: {
    endpoint: `farms/eagri/${farmId}?update-mode=${updateType}&validity-date=${date}&`,
    method: methods.PUT as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.PUT_LPIS_UPDATE_REQUEST,
      types.PUT_LPIS_UPDATE_SUCCESS,
      types.PUT_LPIS_UPDATE_ERROR,
    ] as RsaaTypes,
  },
});

export const getFarmUpdateLpisResultsApi = () => {
  const p = {
    page: 1,
    "per-page": 20,
    "sort-dir": sortOrder.DESC,
  } as Record<string, number | string>;

  return {
    [RSAA]: {
      endpoint: `farms/updated?${queryString.stringify(p)}&`,
      method: methods.GET as RsaaMethods,
      module: modules.AGROEVIDENCE,
      types: [
        types.GET_LPIS_UPDATE_RESULTS_REQUEST,
        types.GET_LPIS_UPDATE_RESULTS_SUCCESS,
        types.GET_LPIS_UPDATE_RESULTS_ERROR,
      ] as RsaaTypes,
    },
  };
};

export const resetFarmUpdateLpisResultsApi = () => ({
  type: types.RESET_LPIS_UPDATE_RESULTS,
});

export const createFarmAvailableUpdatesApi = () => ({
  [RSAA]: {
    endpoint: "farms/eagri/available-updates?",
    method: methods.POST as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.CREATE_LPIS_AVAILABLE_UPDATES_REQUEST,
      types.CREATE_LPIS_AVAILABLE_UPDATES_SUCCESS,
      types.CREATE_LPIS_AVAILABLE_UPDATES_ERROR,
    ] as RsaaTypes,
  },
});

export const getFarmAvailableUpdatesApi = () => ({
  [RSAA]: {
    endpoint: "farms/eagri/available-updates?",
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_LPIS_AVAILABLE_UPDATES_REQUEST,
      types.GET_LPIS_AVAILABLE_UPDATES_SUCCESS,
      types.GET_LPIS_AVAILABLE_UPDATES_ERROR,
    ] as RsaaTypes,
  },
});

export const resetFarmAvailableUpdatesApi = () => ({
  type: types.RESET_LPIS_AVAILABLE_UPDATES_RESULTS,
});
