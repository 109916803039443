// TODO uncomment all the lines on task CFD-4710
import React, { Fragment } from "react";

// import { Button } from "@mui/material";
import { FormattedMessage } from "react-intl";
// import { useParams } from "react-router-dom";

// import UpdateLpisIcon from "../../../../../../shared/icons/navbar/UpdateLpisIcon";
import { WarningMessageBox } from "../../../../shared/containers/WarningMessageBox";

import {
  ParcelDetailTo,
  ParcelSource,
  ParcelStatus,
} from "../../../../../../shared/api/agroevidence/agroevidence.types";

interface Props {
  parcel: ParcelDetailTo;
}

export function CoreParcelWarningMessage({ parcel }: Readonly<Props>) {
  // const { farmId } = useParams<{ farmId: string }>();

  // const navigateToUpdateLpisHandler = () => {
  //   if (farmId) {
  //     window.open(
  //       `/farm/${farmId}/user/updatelpis`,
  //       "_blank",
  //       "noopener noreferrer",
  //     );
  //   }
  // };

  return (
    <Fragment>
      {parcel?.parcelSource === ParcelSource.CORE &&
        parcel?.status !== ParcelStatus.RETIRED && (
          <WarningMessageBox
            text={<FormattedMessage id="CoreParcel.validParcel.message.text" />}
            // action={
            //   <Button
            //     color="primary"
            //     data-test="update-lpis"
            //     onClick={navigateToUpdateLpisHandler}
            //     variant="contained"
            //   >
            //     <UpdateLpisIcon />
            //     <FormattedMessage id="UpdateLPIS.button" />
            //   </Button>
            // }
            title={
              <FormattedMessage id="CoreParcel.validParcel.message.title" />
            }
          />
        )}
      {parcel?.parcelSource === ParcelSource.CORE &&
        parcel?.status === ParcelStatus.RETIRED && (
          <WarningMessageBox
            text={
              <FormattedMessage id="CoreParcel.retiredParcel.message.text" />
            }
            title={
              <FormattedMessage id="CoreParcel.retiredParcel.message.title" />
            }
          />
        )}
    </Fragment>
  );
}
