import React, { useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";

import { getMainMapTelematicsMachinePositions } from "../../../../shared/api/telematics/mainMapTelematics/mainMapTelematics.selectors";
import {
  getMainMapTelematicsDriverFilter,
  getMainMapTelematicsMachineFilter,
  getMainMapTelematicsSelectedMachineGpsUnit,
} from "../../selectors/mainMapTelematics.selectors";
import { getMainMapTelematics } from "../../selectors/map.selectors";

import * as dashboardActions from "../../../../shared/api/telematics/mainMapTelematics/dashboard/machinesDashboard.actions";
import {
  resetMachineDrivesHistory,
  setMachineFilter,
  setSelectedMachineGpsUnit,
} from "../../actions/mainMapTelematics/mainMapTelematics.actions";

import { DetailBody } from "./DetailBody";
import DetailHeader from "./DetailHeader";
import Wrapper from "./Wrapper";

import {
  MachineTo,
  PositionMachineTo,
} from "../../../../shared/api/telematics/telematics.types";

type Props = {
  ngRedirectToTelematics: (
    dateFrom?: string,
    dateTo?: string,
    machine?: PositionMachineTo,
  ) => void;
};

const MainMapTelematicsDetail = ({ ngRedirectToTelematics }: Props) => {
  const dispatch = useDispatch();

  const selectedMachineGpsUnit = useSelector(
    getMainMapTelematicsSelectedMachineGpsUnit,
  );
  const machinePositions = useSelector(getMainMapTelematicsMachinePositions);
  const mapService = useSelector(getMainMapTelematics);
  const driverFilter = useSelector(getMainMapTelematicsDriverFilter);
  const machineFilter = useSelector(getMainMapTelematicsMachineFilter);

  React.useEffect(() => {
    dispatch(resetMachineDrivesHistory());
    dispatch(dashboardActions.resetDashboardDrivesHistory());
  }, [driverFilter, machineFilter, dispatch]);

  const handleClose = () => {
    dispatch(dashboardActions.setDashboardMachineSelected(undefined));
    dispatch(setSelectedMachineGpsUnit(undefined));
    dispatch(resetMachineDrivesHistory());
    dispatch(dashboardActions.resetDashboardDrivesHistory());
    mapService.setMachineDrivesHistory([]);

    const selectedMachine = machinePosition?.machine as MachineTo;
    const newMachineFilter = machineFilter.filter(
      (m) =>
        m.id !== selectedMachine.id &&
        m.machineCode !== selectedMachine.machineCode,
    );
    dispatch(setMachineFilter(newMachineFilter));
  };

  const machinePosition = useMemo(
    () =>
      machinePositions?.find(
        (x) => x?.machine?.gpsUnit === selectedMachineGpsUnit,
      ),
    [machinePositions, selectedMachineGpsUnit],
  );

  if (!machinePosition) return null;

  return (
    <div>
      <Wrapper onPanelClose={handleClose}>
        <DetailHeader
          machinePosition={machinePosition}
          ngRedirectToTelematics={ngRedirectToTelematics}
        />
        <DetailBody machinePosition={machinePosition} />
      </Wrapper>
    </div>
  );
};

export default MainMapTelematicsDetail;
