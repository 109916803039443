import React from "react";

import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from "@mui/icons-material/Refresh";
import { IconButton, TableRow, Tooltip } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

import { forceGeoserverReset } from "../../../../shared/api/agroevidence/farms/admin/farmsAdmin.api";
import CfTableCell from "../../../../shared/components/tables/CfTableCell/CfTableCell";
import CfTableRowToolButton from "../../../../shared/components/tables/CfTableRowToolButton/CfTableRowToolButton";
import CfTableRowTools from "../../../../shared/components/tables/CfTableRowTools/CfTableRowTools";
import { SnackbarContext } from "../../../../shared/containers/SnackbarProvider/SnackbarProvider";
import { useToggle } from "../../../../shared/hooks/useToggle";
import Countries from "../../../../shared/services/Countries.service";

import EditDialog from "./EditDialog";

import { AdminFarmTo } from "../../../../shared/api/agroevidence/agroevidence.types";

const Row = ({ item }: { item: AdminFarmTo }) => {
  const classes = useStyles();
  const {
    on: showEditDialog,
    setOff: closeEditDialog,
    setOn: openEditDialog,
  } = useToggle();

  return (
    <TableRow className={classes.row}>
      <CfTableCell name="companyName">
        <div className={classes.cellMultipleRows}>
          <span className={classes.farmName}>{item.name}</span>
          <span className={classes.greyText}>{item.id}</span>
        </div>
      </CfTableCell>
      <CfTableCell classes={{ root: classes.flag }} name="countryCode">
        {Countries.countryToFlag(item.countryCode)}
      </CfTableCell>
      <CfTableCell name="geoserver">
        <GeoserverResetButton farmId={item.id} />
      </CfTableCell>
      <CfTableRowTools toolsClass={classes.editButton}>
        <CfTableRowToolButton
          icon={<EditIcon />}
          onClick={openEditDialog}
          tooltipMessage={
            <FormattedMessage id="TelematicsFarmsAdmin.edit.tooltip" />
          }
        />
      </CfTableRowTools>
      <EditDialog
        farm={item}
        handleClose={closeEditDialog}
        opened={showEditDialog}
      />
    </TableRow>
  );
};

const GeoserverResetButton = ({ farmId }: { farmId: string }) => {
  const dispatch = useDispatch();
  const showSnackbar = React.useContext(SnackbarContext);
  const { on: pending, setOff: endPending, setOn: startPending } = useToggle();

  const handleForceGeoserverReset = async () => {
    try {
      startPending();
      await (dispatch(
        forceGeoserverReset({ farmId }),
      ) as unknown as Promise<unknown>);
      showSnackbar({
        message: <FormattedMessage id="FarmsAdmin.geoserver.refresh.success" />,
        isSuccess: true,
      });
    } catch {
      showSnackbar({
        message: <FormattedMessage id="FarmsAdmin.geoserver.refresh.error" />,
        isError: true,
      });
    } finally {
      endPending();
    }
  };

  return (
    <IconButton disabled={pending} onClick={handleForceGeoserverReset}>
      <Tooltip title={<FormattedMessage id="FarmsAdmin.geoserver.refresh" />}>
        <RefreshIcon />
      </Tooltip>
    </IconButton>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    transform: "scale(1)",
    "&:hover $editButton": {
      display: "inline-block",
      padding: "0px 10px 0px 0px",
    },
  },
  cellMultipleRows: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 16,
  },
  farmName: {
    fontWeight: 500,
  },
  greyText: {
    color: theme.palette.grey[400],
  },
  editButton: {
    display: "none",
  },
  flag: {
    fontSize: 20,
  },
}));

export { Row };
