import React, { useState, useEffect } from "react";

import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { match as matchType } from "react-router-dom";
import { bindActionCreators } from "redux";

import {
  getVariableActionBulkIsDeleting,
  getVariableActionIds as getVariableActionIdsSelector,
} from "../../../../../shared/api/sentinel/variableApplication/variableApplication.selectors";
import {
  getSelectedOnPage,
  getTextFilter,
  getActionsAdvancedFilter,
  getActionsDateFrom,
  getActionsDateTo,
} from "../../selectors/actionList.selectors";

import { deleteActions, setDate } from "../../actions/actionList.actions";

import { NAMESPACE as namespace } from "../../reducer/actionList.reducer";

import CfTextFilter from "../../../../../common/components/CfTextFilter/CfTextFilter";
import { useCfTextFilter } from "../../../../../common/components/CfTextFilter/useCfTextFilter";
import {
  getVariableParcelIds,
  getVariableActionIds,
  resetVariableActionIds,
} from "../../../../../shared/api/sentinel/variableApplication/variableApplication.api";
import CfErrorPage from "../../../../../shared/components/common/CfErrorPage/CfErrorPage";
import { DateRangeSelector } from "../../../../../shared/components/common/DateRangeSelector/DateRangeSelector";
import FabWithOptions, {
  FabOptionType,
} from "../../../../../shared/components/common/FabWithOptions/FabWithOptions";
import PageHeader from "../../../../../shared/components/common/PageHeader/PageHeader";
import PageHeading from "../../../../../shared/components/common/PageHeading/PageHeading";
import TableActionButtons from "../../../../../shared/components/tables/TableActionButtons/TableActionButtons";
import { AsyncFn, Thunk } from "../../../../../types";
import { ActionsAdvancedFilter } from "../ActionsAdvancedFilter/ActionsAdvancedFilter";
import ActionsTable from "../ActionsTable/ActionsTable";

import { ActionsState } from "../../../../../reducers/actions.reducer.types";

type ReduxProps = ConnectedProps<typeof connector>;
type OwnProps = {
  langId: string;
  ngGoToAction: (actionId: string, route: string) => void;
  ngGoToEph: () => void;
  ngGoToHarvest: () => void;
  ngGoToMowing: () => void;
  ngGoToOtherNew: () => void;
  ngGoToSowing: () => void;
  ngGoToVrf: () => void;
  ngGoToVrs: () => void;
  match: matchType<{ farmId: string }>;
};
type Props = OwnProps & ReduxProps;

function ActionsList({
  advancedFilter,
  bulkIsDeleting,
  dateFrom,
  dateTo,
  deleteActions,
  getVariableActionIds,
  getVariableParcelIds,
  langId = "",
  match,
  ngGoToAction,
  ngGoToEph,
  ngGoToHarvest,
  ngGoToMowing,
  ngGoToOtherNew,
  ngGoToSowing,
  ngGoToVrf,
  ngGoToVrs,
  resetVariableActionIds,
  selectedOnPage,
  setDate,
  textFilter,
  variableActionIds = [],
}: Props) {
  const classes = useStyles();
  const { onChange } = useCfTextFilter(namespace);
  const [isVraAllowed, setIsVraAllowed] = useState(false);

  const fabOptions: FabOptionType[] = [
    {
      id: "add-other-sowing-link",
      translId: "common.addOtherSowingActivity",
      action: () => ngGoToSowing(),
    },
    {
      id: "add-eph-link",
      translId: "common.addEph",
      action: () => ngGoToEph(),
    },
    {
      id: "add-other-link",
      translId: "common.addOtherActivity",
      action: () => ngGoToOtherNew(),
    },
    {
      id: "add-other-mowing-link",
      translId: "common.addOtherMowingActivity",
      action: () => ngGoToMowing(),
    },
    {
      id: "add-harvest-link",
      translId: "common.addHarvestActivity",
      action: () => ngGoToHarvest(),
      divider: true,
    },
  ];

  const fabOptsVrf = [
    {
      id: "add-var-vert-link",
      translId: "common.addVrfActivity",
      action: () => ngGoToVrf(),
    },
  ];

  const fabOptsVrs = [
    {
      id: "add-var-seed-link",
      translId: "common.addVrsActivity",
      action: () => ngGoToVrs(),
    },
  ];

  useEffect(() => {
    (getVariableParcelIds as AsyncFn<null>)().then((res) => {
      if (!res.error) {
        setIsVraAllowed(Boolean(res.payload.length));
      }
    });
    getVariableActionIds();
    return () => {
      resetVariableActionIds();
    };
  }, [getVariableParcelIds, getVariableActionIds, resetVariableActionIds]);

  const handleDeleteActions = () => {
    deleteActions(selectedOnPage, variableActionIds);
  };

  return (
    <CfErrorPage>
      <div className={classes.wrapper}>
        <PageHeader
          classes={{ header: classes.header }}
          actionButtons={
            <FabWithOptions
              options={[
                ...fabOptions,
                ...fabOptsVrf.map((opt) => ({
                  ...opt,
                  disabled: !isVraAllowed,
                })),
                ...fabOptsVrs.map((opt) => ({
                  ...opt,
                  disabled: !isVraAllowed,
                })),
              ]}
            />
          }
          heading={
            <PageHeading
              dataTest="activities-heading"
              translationId="common.activities"
            />
          }
        />
        <div>
          <div className={classes.filtersHeader}>
            <span className={classes.actionButtons}>
              <TableActionButtons
                isDeleting={bulkIsDeleting}
                onDeleteActions={handleDeleteActions}
                selected={selectedOnPage}
                content={
                  <FormattedMessage id="ActionButtons.deleteModalAction.context" />
                }
                title={
                  <FormattedMessage id="ActionButtons.deleteModalAction.title" />
                }
              />
            </span>

            <span className={classes.dateFilter}>
              <DateRangeSelector
                dateFrom={dateFrom ?? ""}
                dateTo={dateTo ?? ""}
                setDate={setDate}
              />
            </span>

            <div className={classes.textFilter}>
              <CfTextFilter
                initialValue={textFilter}
                name="action-list-text-filter"
                onChange={onChange}
                translId="ActionsList.text-filter-placeholder"
              />
            </div>
            <div className={classes.advancedFilter}>
              <ActionsAdvancedFilter
                advancedFilter={advancedFilter}
                langId={langId}
                namespace={namespace}
              />
            </div>
          </div>
          <ActionsTable match={match} ngGoToAction={ngGoToAction} />
        </div>
      </div>
    </CfErrorPage>
  );
}

const mapStateToProps = (state: ActionsState) => ({
  selectedOnPage: getSelectedOnPage(state),
  textFilter: getTextFilter(state),
  dateFrom: getActionsDateFrom(state),
  dateTo: getActionsDateTo(state),
  variableActionIds: getVariableActionIdsSelector(state),
  advancedFilter: getActionsAdvancedFilter(state),
  bulkIsDeleting: getVariableActionBulkIsDeleting(state),
});

const mapDispatchToProps = (dispatch: Thunk<ActionsState>) =>
  bindActionCreators(
    {
      deleteActions,
      setDate,
      getVariableActionIds,
      resetVariableActionIds,
      getVariableParcelIds: getVariableParcelIds as () => void,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ActionsList);

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: theme.spacing(2),
  },
  header: {
    paddingBottom: theme.spacing(1),
  },
  dateFilter: {
    display: "flex",
    gap: "10px",
    margin: "auto 16px 8px 0",
    minWidth: 180,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: "10px",
    },
    [theme.breakpoints.up("sm")]: {
      padding: 0,
    },
  },
  actionButtons: {
    paddingBottom: 6,
    [theme.breakpoints.down("md")]: {
      order: 3,
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: 14,
    },
  },
  textFilter: {
    paddingTop: "16px",
    paddingBottom: 8,
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: 450,
    },
  },
  filtersHeader: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  advancedFilter: {
    display: "flex",
    alignItems: "center",
    marginLeft: 10,
  },
}));
