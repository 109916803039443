import React, { Fragment } from "react";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import WarningIcon from "@mui/icons-material/Warning";
import { Theme, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import CfBackButton from "../../../../../shared/components/common/CfBackButton/CfBackButton";
import PageHeader from "../../../../../shared/components/common/PageHeader/PageHeader";
import PageHeading from "../../../../../shared/components/common/PageHeading/PageHeading";
import VariableActionExport from "../VariableActionExport/VariableActionExport";

type Props = {
  title: string;
  isExisting: boolean;
  isDisabled: boolean;
  onClick: () => void;
  onExport?: () => void;
  ngGoToActions: () => void;
  handleStartIsSplitting: () => void;
  isLoading?: boolean;
  isDraft: boolean;
};

const ActionDetailHeader = ({
  handleStartIsSplitting,
  isDisabled,
  isDraft = false,
  isExisting,
  isLoading = false,
  ngGoToActions,
  onClick,
  onExport = undefined,
  title,
}: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.headerWrapper}>
      <PageHeader
        actionButtons={
          isExisting ? (
            <Fragment>
              {onExport && (
                <VariableActionExport
                  buttonText={<FormattedMessage id="Vra.exportList.header" />}
                  iconButton={true}
                  isDisabled={isDisabled}
                  isLoading={isLoading}
                  onExport={onExport}
                />
              )}
              {!!handleStartIsSplitting && (
                <Tooltip
                  title={
                    <FormattedMessage id="ActionSplit.button.tooltip.label" />
                  }
                >
                  <span>
                    <IconButton
                      data-test="action-other-split"
                      disabled={isDisabled}
                      onClick={handleStartIsSplitting}
                      size="large"
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              <Tooltip
                title={
                  <FormattedMessage id="ActionEdit.button.tooltip.label" />
                }
              >
                <span>
                  <IconButton
                    data-test="action-edit"
                    disabled={isDisabled}
                    onClick={onClick}
                    size="large"
                  >
                    <EditIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Fragment>
          ) : null
        }
        backButton={
          <CfBackButton
            onClick={() => ngGoToActions()}
            translId="action.backToActivities"
          />
        }
        heading={
          <div className={classes.headingContainer}>
            {isDraft && (
              <Tooltip title={<FormattedMessage id="ActionDetail.isDraft" />}>
                <WarningIcon color="secondary" />
              </Tooltip>
            )}
            <PageHeading dataTest="action-name" translationId={title} />
          </div>
        }
      />
    </div>
  );
};

export { ActionDetailHeader };

const useStyles = makeStyles((theme: Theme) => ({
  headerWrapper: {
    padding: theme.spacing(2),
  },
  headingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
