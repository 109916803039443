import React from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FilterListIcon from "@mui/icons-material/FilterList";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import CfFormattedNumber from "../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import CfTableCellNumber from "../../../../../shared/components/tables/CfTableCellNumber/CfTableCellNumber";
import CfTableRowToolButton from "../../../../../shared/components/tables/CfTableRowToolButton/CfTableRowToolButton";
import CfTableRowTools from "../../../../../shared/components/tables/CfTableRowTools/CfTableRowTools";

import { StatsTableCell } from "./StatsTableCell";

import { StatisticsItemType } from "../../../../../shared/api/agroevidence/zones/zones.types";

interface Props {
  item: StatisticsItemType;
  onEditZone?: (zone: StatisticsItemType) => void;
  onDeleteZone?: (zone: StatisticsItemType) => void;
  onFilterZoneInParcels?: (zones: StatisticsItemType) => void;
  onFilterCropInParcels?: (crops: StatisticsItemType) => void;
}

const StatsBody = ({
  item,
  onDeleteZone,
  onEditZone,
  onFilterCropInParcels,
  onFilterZoneInParcels,
}: Props) => {
  const classes = useStyles();
  return (
    <TableRow
      className={onDeleteZone ? classes.tableRowZone : classes.tableRowCrop}
      key={item.id}
    >
      <StatsTableCell classes={{ root: classes.firstCell }} key="name">
        {item.name ?? "-"}
      </StatsTableCell>
      <StatsTableCell classes={{ root: classes.cell }} key="count">
        <CfTableCellNumber>{item.count ?? "-"}</CfTableCellNumber>
      </StatsTableCell>
      <StatsTableCell classes={{ root: classes.cell }} key="area">
        <CfTableCellNumber>
          <CfFormattedNumber value={item.totalArea ?? 0} />
        </CfTableCellNumber>
      </StatsTableCell>
      <CfTableRowTools
        key="crop-filter-btn"
        toolsClass={classes.tableRowToolsCrop}
      >
        <>
          {onFilterCropInParcels && (
            <CfTableRowToolButton
              icon={<FilterListIcon />}
              iconProps={classes.actionBtn}
              onClick={() => onFilterCropInParcels(item)}
              tooltipMessage={
                <FormattedMessage id="ParcelZoneStatistics.list.filter-parcels" />
              }
            />
          )}
        </>
      </CfTableRowTools>
      <CfTableRowTools
        key="area-delete-btn"
        toolsClass={classes.tableRowToolsZone}
      >
        {onFilterZoneInParcels && (
          <CfTableRowToolButton
            icon={<FilterListIcon />}
            iconProps={classes.actionBtn}
            onClick={() => onFilterZoneInParcels(item)}
            tooltipMessage={
              <FormattedMessage id="ParcelZoneStatistics.list.filter-parcels" />
            }
          />
        )}
        {onEditZone && (
          <CfTableRowToolButton
            icon={<EditIcon />}
            iconProps={classes.actionBtn}
            onClick={() => onEditZone(item)}
            tooltipMessage={<FormattedMessage id="common.edit" />}
          />
        )}
        {onDeleteZone && (
          <CfTableRowToolButton
            icon={<DeleteIcon />}
            iconProps={classes.actionBtn}
            onClick={() => onDeleteZone(item)}
            tooltipMessage={<FormattedMessage id="common.delete" />}
          />
        )}
      </CfTableRowTools>
    </TableRow>
  );
};

export { StatsBody };

const useStyles = makeStyles({
  firstCell: {
    width: "40%",
  },
  cell: {
    width: "30%",
  },
  actionBtn: {
    width: 32,
    height: 32,
    padding: 0,
  },
  root: {
    borderBottom: 0,
  },
  tableRowZone: {
    transform: "scale(1)",
    "&:hover $tableRowToolsZone": {
      display: "inline-block",
      padding: "5px 10px 0px 0px",
    },
  },
  tableRowCrop: {
    transform: "scale(1)",
    "&:hover $tableRowToolsCrop": {
      display: "inline-block",
      padding: "5px 10px 0px 0px",
    },
  },
  tableRowToolsCrop: {
    display: "none",
  },
  tableRowToolsZone: {
    display: "none",
  },
});
