import React from "react";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import { ExportIcon } from "../../../icons/ExportIcon";
import CfLoader from "../CfLoader/CfLoader";

type Props = {
  customIcon?: React.ReactNode;
  tooltipTitle?: React.ReactNode;
  isLoading?: boolean;
} & Pick<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  "onClick" | "disabled" | "id"
>;

const ExportButton = ({
  customIcon,
  disabled,
  isLoading,
  onClick,
  tooltipTitle,
  ...rest
}: Props) => {
  const classes = useStyles();

  return (
    <Tooltip title={tooltipTitle || <FormattedMessage id="common.export" />}>
      <span>
        {isLoading ? (
          <CfLoader color="inherit" size={20} />
        ) : (
          <IconButton
            aria-label="Export"
            className={classes.exportButton}
            disabled={disabled}
            onClick={onClick}
            size="large"
            {...rest}
          >
            {customIcon || <ExportIcon className={classes.exportIcon} />}
          </IconButton>
        )}
      </span>
    </Tooltip>
  );
};

const useStyles = makeStyles({
  exportButton: {
    minWidth: "unset",
  },
  exportIcon: {
    width: 20,
    height: 20,
  },
});

export { ExportButton };
