import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  getMonitoringCropIntervalsIsFetching,
  getMonitoringDataIsFetching,
  getRecordsValidity,
} from "../../../../shared/api/sentinel/monitoring/monitoring.selectors";
import {
  getChartData,
  getCropIntervals,
  getDateFrom,
  getDateTo,
  getIndex,
  getMonitoringData,
  getMonitoringStatus,
} from "../../selectors/monitoring.selectors";

import { setMonitoringInterval } from "../../actions/monitoring.actions";

import * as satelliteProductsTypes from "../../../../shared/constants/satelliteProductsTypes.constants";

import {
  getCropIntervalsApi,
  getMonitoringDataApi,
} from "../../../../shared/api/sentinel/monitoring/monitoring.api";
import {
  disableHTMLCanvasImageSmoothing,
  enableHTMLCanvasImageSmoothing,
} from "../../../../shared/misc/helpers";
import { BioMonitoringHistoryInfoDialog } from "../../components/BioMonitoring/BioMonitoringHistoryInfoDialog";
import { MONITORING_STATUS } from "../../selectors/monitoringStatus";
import BioMonitoringImageErrorService from "../../services/BioMonitoringImageError.services";

import { IndexTypeDataType } from "./types";

import { PrecisionState } from "../../../../reducers/precision.reducer.types";

const supportedImageStates = [
  MONITORING_STATUS.OK,
  MONITORING_STATUS.NO_ZONES,
  MONITORING_STATUS.NO_ZONES_2020,
  MONITORING_STATUS.LAI_ONLY,
];

const useBioMonitoring = (parcelId: string) => {
  const dispatch = useDispatch();

  const index = useSelector((state: PrecisionState) => getIndex(state));
  const isFetchingData = useSelector((state: PrecisionState) =>
    getMonitoringDataIsFetching(state),
  );
  const isFetchingCropIntervals = useSelector((state: PrecisionState) =>
    getMonitoringCropIntervalsIsFetching(state),
  );
  const dateFrom = useSelector((state: PrecisionState) => getDateFrom(state));
  const dateTo = useSelector((state: PrecisionState) => getDateTo(state));
  const monitoringStatus = useSelector((state: PrecisionState) =>
    getMonitoringStatus(state),
  );
  const cropIntervals = useSelector((state: PrecisionState) =>
    getCropIntervals(state),
  );
  const data = useSelector((state: PrecisionState) => getMonitoringData(state));
  const graphData = useSelector((state: PrecisionState) => getChartData(state));
  const validity = useSelector((state: PrecisionState) =>
    getRecordsValidity(state),
  );

  const isFetching = isFetchingData || isFetchingCropIntervals;

  useEffect(() => {
    // temporarily disable global HTMLCanvas smoothing
    disableHTMLCanvasImageSmoothing();
    if (
      monitoringStatus === satelliteProductsTypes.ACTIVE ||
      monitoringStatus === satelliteProductsTypes.HISTORICAL
    ) {
      dispatch(getCropIntervalsApi(parcelId));
    }

    return () => {
      // enable global HTMLCanvas smoothing
      enableHTMLCanvasImageSmoothing();
    };
  }, [dispatch, monitoringStatus, parcelId]);

  useEffect(() => {
    if (dateFrom) {
      const status = supportedImageStates.join(",");
      dispatch(getMonitoringDataApi(parcelId, index, dateFrom, dateTo, status));
    }
  }, [index, dateFrom, parcelId, dateTo, dispatch]);

  useEffect(() => {
    if (cropIntervals.length) {
      dispatch(
        setMonitoringInterval(cropIntervals[0].from, cropIntervals[0].to),
      );
    }
  }, [cropIntervals, dispatch]);

  const overallImagesError =
    BioMonitoringImageErrorService.getOverallImagesError(data, index);
  const cropHarvestedError =
    BioMonitoringImageErrorService.checkCropHarvestedError(data);
  const lastImageDateTo = validity;

  const infoDialogContent = (
    <BioMonitoringHistoryInfoDialog.Root index={index} />
  );

  return {
    isFetching,
    overallImagesError,
    cropHarvestedError,
    lastImageDateTo,
    data,
    infoDialogContent,
    graphData,
    cropIntervals,
  } as IndexTypeDataType;
};

export { useBioMonitoring };
