import React, { ReactNode } from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";

interface Props {
  children: ReactNode;
  customClasses?: Record<string, string>;
  subheading?: string;
  reversed?: boolean;
}

const SelectionItemHeading = ({
  children,
  customClasses,
  reversed = false,
  subheading = "",
}: Props) => {
  const classes = useStyles();

  return (
    <div
      className={classnames(classes.item, customClasses?.item)}
      data-test="item"
      style={{ flexDirection: reversed ? "column-reverse" : "column" }}
    >
      <div
        className={classnames(classes.heading, customClasses?.heading)}
        data-test="item-heading"
      >
        {children}
      </div>
      <div
        className={classnames(classes.subheading, customClasses?.subheading)}
        data-test="item-subheading"
      >
        {subheading}
      </div>
    </div>
  );
};

export default SelectionItemHeading;

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    fontSize: 16,
  },
  item: {
    fontWeight: 500,
    margin: 8,
    display: "flex",
  },
  subheading: {
    fontSize: 12,
    color: theme.palette.grey[500],
  },
}));
