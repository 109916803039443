import { makeStyles } from "@mui/styles";

export const useSharedTableStyles = makeStyles(() => ({
  nameBodyCell: {
    fontWeight: 500,
  },
  bodyCell: {
    borderBottomColor: "transparent",
  },
  cell: {
    padding: "9px 6px",
  },
}));
