import moment from "moment";

import {
  validateParcels,
  validateExpenses,
  validateTargetCrop,
  validateActionDate,
  warnTargetCrop,
  EPH_CODES,
} from "../../shared/misc/action.helpers";

export const STRONG_ZONES_FERTILIZATION = "STRONG_ZONES_FERTILIZATION";
export const WEAK_ZONES_FERTILIZATION = "WEAK_ZONES_FERTILIZATION";

export const getInitialValues = () => ({
  actionDate: moment().startOf("day"),
  parcels: [],
  expenses: [],
  fertilizationType: {
    id: WEAK_ZONES_FERTILIZATION,
  },
  zonesCount: null,
  satellite: null,
  minMappingUnit: null,
  targetCrop: null,
  actionNote: "",
  exportTypeOnSubmit: "",
});

export const validate = (values) => {
  const parcels = validateParcels(values);
  const expenses = validateExpenses(
    values,
    "VariableExpensesControl.chooseMaterial",
  );
  const targetCrop = validateTargetCrop(values);
  const actionDate = validateActionDate(values);

  return {
    ...(parcels ? { parcels } : {}),
    ...(expenses ? { expenses } : {}),
    ...(actionDate ? { actionDate } : {}),
    ...(targetCrop ? { targetCrop } : {}),
  };
};

export const warn = (values) => {
  const targetCropWarning = warnTargetCrop(values);
  return {
    ...(targetCropWarning ? { targetCrop: targetCropWarning } : {}),
  };
};

// Transforms what goes to LocalStorage
export const lsTransformFnc = (values) => ({
  ...values,
  expenses: values.expenses.map((expense) => {
    const { variableExpense, ...rest } = expense;
    return rest;
  }),
});

export const isVrf = (action, variableActionIds = []) => {
  if (!action || !action.id) return false;
  const isEphCode = EPH_CODES.includes(action.actionType);

  return isEphCode && variableActionIds?.includes(action.id);
};
