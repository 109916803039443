/* eslint-disable no-nested-ternary */
import React from "react";

import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { get } from "lodash";
import { useSelector } from "react-redux";

import { getSentinelAdminStatsFarm } from "../../selectors/sentinelStats.selectors";

import CfLoader from "../../../../../shared/components/common/CfLoader/CfLoader";
import CfTableBodyEmpty from "../../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import { AnyTodo } from "../../../../../types";

import { TableBodyColumn } from "./types";

type Props = {
  isFetching: boolean;
  data: AnyTodo[];
  columns: TableBodyColumn[];
  classes: Record<string, string>;
};

const Body = ({ classes: propClasses, columns, data, isFetching }: Props) => {
  const ROWS_COUNT = data.length;
  const classes = useStyles();
  const emptyTableBodyClasses = useTableBodyEmptyStyles();

  const farm = useSelector(getSentinelAdminStatsFarm);

  return (
    <Table>
      {farm ? (
        <>
          {isFetching ? (
            <CfLoader classes={{ wrapper: classes.loaderWrapper }} />
          ) : data ? (
            <TableBody>
              {data.map((row, i) => (
                <TableRow key={i}>
                  {columns.map((col) => (
                    <TableCell
                      align={col.align ?? "left"}
                      key={col.key}
                      style={col.style}
                      classes={{
                        root: classNames({
                          [propClasses.cell]: true,
                          [propClasses.bodyCell]: true,
                          [propClasses[`${col.key}BodyCell`]]: true,
                        }),
                      }}
                    >
                      {col.renderBodyCell
                        ? col.renderBodyCell(row)
                        : get(row, col.key, "-")}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <CfTableBodyEmpty
              classes={emptyTableBodyClasses}
              colLength={ROWS_COUNT}
              translId="common.empty-table"
            />
          )}
        </>
      ) : (
        <CfTableBodyEmpty
          classes={emptyTableBodyClasses}
          colLength={ROWS_COUNT}
          translId="SentinelAdminTable.noFarmSelected"
        />
      )}
    </Table>
  );
};

const useStyles = makeStyles(() => ({
  loaderWrapper: {
    "&:before": {
      content: '""',
      display: "block",
      paddingTop: "100%",
    },
  },
}));

const useTableBodyEmptyStyles = makeStyles(() => ({
  wrapper: {
    borderBottomColor: "transparent",
  },
}));

export { Body };
