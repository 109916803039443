import React, { Component, Fragment } from "react";

import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { compose } from "react-recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getError } from "../../../../../shared/api/stores/stores/stores.selectors";
import {
  getStoresMaterialType,
  getStoresTextFilter,
  getStoresShowNullValues,
} from "../../selectors/stores.selectors";

import { setTextFilter } from "../../../../../shared/actions/filter.actions";
import {
  setStoreShowNullValues,
  setStoreMaterialType,
  saveNewStore,
  deleteStore,
  exportStores,
} from "../../actions/stores.actions";

import { NAMESPACE as namespace } from "../../reducer/stores.reducer";

import { resetStores } from "../../../../../shared/api/stores/stores/stores.api";
import CfErrorPage from "../../../../../shared/components/common/CfErrorPage/CfErrorPage";
import PageHeader from "../../../../../shared/components/common/PageHeader/PageHeader";
import PageHeading from "../../../../../shared/components/common/PageHeading/PageHeading";
import DeleteDialog from "../../../shared/components/DeleteDialog/DeleteDialog";
import StoreExport from "../../../shared/components/StoreExport/StoreExport";
import StoreFabButton from "../../../shared/components/StoreFabButton/StoreFabButton";
import TransactionDialog from "../../../shared/containers/TransactionDialog/TransactionDialog";
import StoreDisplayNull from "../../components/StoreDisplayNull/StoreDisplayNull";
import StoresTabs from "../../components/StoresTabs/StoresTabs";
import StoreTextFilter from "../../components/StoreTextFilter/StoreTextFilter";
import StoreNewDialog from "../StoreNewDialog/StoreNewDialog";
import StoresTable from "../StoresTable/StoresTable";

const KEYS = {
  DELETE: "delete",
  CREATE: "create",
  UPDATE: "update",
};

export class Stores extends Component {
  constructor(props) {
    super(props);

    this.state = {
      [KEYS.CREATE]: false,
      [KEYS.DELETE]: false,
      [KEYS.UPDATE]: false,
      item: {},
    };
  }

  componentWillUnmount() {
    this.props.resetStores();
  }

  handleDialogOpen = (key, item = {}) => {
    this.setState({
      [key]: true,
      item,
    });
  };

  handleDialogClose = (key) => {
    this.setState({
      [key]: false,
      item: {},
    });
  };

  handleDialogAccept = (key, dto = {}) => {
    const { item } = this.state;
    const { farmId } = this.props;

    this.handleDialogClose(key);
    switch (key) {
      case KEYS.DELETE:
        return this.props.deleteStore(farmId, item.id);
      case KEYS.CREATE:
      case KEYS.UPDATE:
        return this.props.saveNewStore(dto);
      default:
        throw new Error("Unrecognized store action");
    }
  };

  render() {
    const { item } = this.state;
    const {
      classes,
      error,
      farmId,
      history,
      location,
      materialTypeId,
      showNullValues,
      textFilter,
    } = this.props;
    return (
      <CfErrorPage error={error}>
        <div>
          <div className={classes.headerWithTabs}>
            <div className={classes.headerWrapper}>
              <PageHeader
                actionButtons={
                  <Fragment>
                    <StoreExport handleExport={this.props.exportStores} />
                    <StoreFabButton
                      callback={() => this.handleDialogOpen(KEYS.CREATE)}
                    />
                    <StoreNewDialog
                      onClose={() => this.handleDialogClose(KEYS.CREATE)}
                      opened={this.state.create}
                      onAccept={(dto) =>
                        this.handleDialogAccept(KEYS.CREATE, dto)
                      }
                    />
                  </Fragment>
                }
                heading={
                  <PageHeading
                    dataTest="stores-heading"
                    translationId="common.stores"
                  />
                }
              />
            </div>
            <StoresTabs
              history={history}
              location={location}
              setStoreMaterialType={this.props.setStoreMaterialType}
            />
          </div>
          <div className={classes.bodyWrapper}>
            <Grid container spacing={0}>
              <Grid item lg={4} md={5} sm={7} xl={3} xs={12}>
                <StoreTextFilter
                  materialTypeId={materialTypeId}
                  namespace={namespace}
                  textFilter={textFilter}
                  handleTextFilterReset={() =>
                    this.props.setTextFilter("", namespace)
                  }
                />
              </Grid>
              <Grid item lg={8} md={7} sm={5} xl={9} xs={12}>
                <StoreDisplayNull
                  setStoreShowNullValues={this.props.setStoreShowNullValues}
                  showNullValues={showNullValues}
                />
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <StoresTable
                  farmId={farmId}
                  history={history}
                  onDeleteStoreClick={(storeItem) =>
                    this.handleDialogOpen(KEYS.DELETE, storeItem)
                  }
                  onUpdateStoreClick={(storeItem) =>
                    this.handleDialogOpen(KEYS.UPDATE, storeItem)
                  }
                />
                <TransactionDialog
                  onAccept={(dto) => this.handleDialogAccept(KEYS.UPDATE, dto)}
                  onClose={() => this.handleDialogClose(KEYS.UPDATE)}
                  opened={this.state.update}
                  storeItem={this.state.item}
                />
                <DeleteDialog
                  onAccept={() => this.handleDialogAccept(KEYS.DELETE)}
                  onClose={() => this.handleDialogClose(KEYS.DELETE)}
                  opened={this.state.delete}
                  title={<FormattedMessage id="Stores.store-delete-material" />}
                >
                  <FormattedMessage
                    id="Stores.store-delete-material-confirm"
                    values={{
                      material: !isEmpty(item) ? item.material.name : "",
                    }}
                  />
                </DeleteDialog>
              </Grid>
            </Grid>
          </div>
        </div>
      </CfErrorPage>
    );
  }
}

Stores.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  farmId: PropTypes.string.isRequired,
  showNullValues: PropTypes.bool.isRequired,
  setStoreShowNullValues: PropTypes.func.isRequired,
  setStoreMaterialType: PropTypes.func.isRequired,
  setTextFilter: PropTypes.func.isRequired,
  resetStores: PropTypes.func.isRequired,
  saveNewStore: PropTypes.func.isRequired,
  deleteStore: PropTypes.func.isRequired,
  exportStores: PropTypes.func.isRequired,
  materialTypeId: PropTypes.string,
  textFilter: PropTypes.string,
  error: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

Stores.defaultProps = {
  materialTypeId: null,
  textFilter: "",
};

const styles = (theme) => ({
  bodyWrapper: {
    padding: theme.spacing(2),
  },
  tableContainer: {
    padding: "8px",
  },
  headerWithTabs: {
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  headerWrapper: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
});

const mapStateToProps = (state) => ({
  showNullValues: getStoresShowNullValues(state),
  materialTypeId: getStoresMaterialType(state),
  textFilter: getStoresTextFilter(state),
  error: getError(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setStoreShowNullValues,
      setStoreMaterialType,
      setTextFilter,
      resetStores,
      saveNewStore,
      deleteStore,
      exportStores,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(compose(withStyles(styles))(Stores));
