import React from "react";

import { LinearProgress } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { Field } from "formik";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import {
  getOtherActionTypes,
  getIsFetchingOtherActionTypes,
} from "../../../../../shared/api/agroevidence/actions/actions.selectors";

import CfFormControl from "../../../../../shared/components/form/CfFormControl/CfFormControl";
import CfFormikTextField from "../../../../../shared/components/form/CfFormikTextField/CfFormikTextField";
import * as validators from "../../../../../shared/misc/validators";

import { ActionTypeTo } from "../../../../../shared/api/agroevidence/agroevidence.types";

interface Props {
  isEditing: boolean;
}
export const ActionSelector = ({ isEditing }: Props) => {
  const otherActionTypes = useSelector(getOtherActionTypes);
  const isFetchingOtherActionTypes = useSelector(getIsFetchingOtherActionTypes);

  if (isFetchingOtherActionTypes) {
    return <LinearProgress color="secondary" />;
  }

  return (
    <CfFormControl>
      <Field
        component={CfFormikTextField}
        disabled={!isEditing}
        label={<FormattedMessage id="common.action" />}
        name="actionType"
        select
        validate={validators.required}
        validateOnBlur={true}
      >
        {otherActionTypes.map((item: ActionTypeTo) => (
          <MenuItem key={item.code} value={item.code}>
            {item.name}
          </MenuItem>
        ))}
      </Field>
    </CfFormControl>
  );
};
