import { AnyAction } from "redux";
import { RSAAAction } from "redux-api-middleware";

import {
  getSentinelAdminPage,
  getSentinelAdminOrder,
  getSentinelAdminOrderBy,
  getSentinelAdminRowsPerPage,
  getSentinelAdminTextFilter,
  getSentinelAdminFarm,
  getSentinelAdminAdvancedFilter,
} from "../selectors/sentinel.selectors";

import * as types from "./sentinel.constants";

import {
  getAdminParcels,
  getCrops,
  getParcelsByName,
  resetAdminParcels,
} from "../../../../shared/api/sentinel/admin/admin.api";

import { AdminState } from "../../../../reducers/admin.reducer.types";
import { AdminFarm } from "../../sensors/admin.sensors.types";

export const fetchActiveParcels =
  () =>
  (dispatch: (action: RSAAAction) => void, getState: () => AdminState) => {
    const state = getState();
    const advancedFilter = getSentinelAdminAdvancedFilter(state);
    const params = {
      localNameBlockNr: getSentinelAdminTextFilter(state),
      farmIds: getSentinelAdminFarm(state)?.id,
      "monitoring-gisat": advancedFilter["monitoring-gisat"]?.state,
      "monitoring-planet": advancedFilter["monitoring-planet"]?.state,
      "management-zones": advancedFilter["management-zones"]?.state,
      "growth-potential": advancedFilter["growth-potential"]?.state,
      crops: advancedFilter.crops?.map((c) => c.name),
      page: getSentinelAdminPage(state) + 1,
      "per-page": getSentinelAdminRowsPerPage(state),
      "sort-col": getSentinelAdminOrderBy(state),
      "sort-dir": getSentinelAdminOrder(state),
    };
    dispatch(getAdminParcels("table", params) as unknown as RSAAAction);
  };

export const fetchCrops =
  () =>
  (dispatch: (action: RSAAAction) => void, getState: () => AdminState) => {
    const state = getState();
    const farmIds = getSentinelAdminFarm(state)?.id;
    dispatch(getCrops({ farmIds }) as unknown as RSAAAction);
  };

export const fetchParcelsSuggestions =
  (searchInput: string) =>
  (dispatch: (action: RSAAAction) => void, getState: () => AdminState) => {
    const state = getState();
    const farmId = getSentinelAdminFarm(state)?.id;
    dispatch(
      getParcelsByName(
        "suggestions",
        searchInput,
        farmId,
      ) as unknown as RSAAAction,
    );
  };

export const clearParcelsSuggestions =
  () => (dispatch: (action: AnyAction) => void) => {
    dispatch(resetAdminParcels("suggestions"));
  };

export const setFarmFilter = (farm: AdminFarm) => ({
  type: types.SET_FARM_FILTER,
  farm,
});

export interface SentinelAdminParcel {
  blockNr: string;
  name: string;
  parcelId: string;
}
