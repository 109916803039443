import React from "react";

import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { FieldInputProps, FormikProps } from "formik";

interface Props {
  children?: React.ReactNode;
  customClasses?: Record<string, string>;
  disableValue?: boolean;
  field: FieldInputProps<string>;
  form: FormikProps<Record<string, unknown>>;
  shrinkLabel?: boolean;
  useDefaultHelperText?: boolean;
  validateOnBlur?: boolean;
}

const CfFormikTextField = (props: Props) => {
  const {
    children,
    customClasses,
    disableValue = false,
    field,
    form,
    shrinkLabel = false,
    useDefaultHelperText = false,
    validateOnBlur = false,
    ...restProps
  } = props;

  const classes = useStyles();
  const shrinkLabelProps = {
    InputLabelProps: { shrink: true },
  };

  const formHelperTextProps = {
    classes: {
      root: classes.helperText,
    },
  };

  const error = form.errors[field.name];
  return (
    <TextField
      classes={customClasses}
      error={Boolean(error)}
      helperText={error}
      name={field.name}
      onChange={field.onChange}
      // Hack due to label and value overlapping
      // https://stackoverflow.com/questions/50955603/react-material-ui-label-overlaps-with-text
      value={disableValue ? "" : field.value || ""}
      FormHelperTextProps={
        useDefaultHelperText ? undefined : formHelperTextProps
      }
      onBlur={(e) => {
        field.onBlur(e);
        if (validateOnBlur) {
          form.validateField(field.name);
        }
      }}
      {...(shrinkLabel && shrinkLabelProps)}
      {...restProps}
    >
      {children}
    </TextField>
  );
};

export default CfFormikTextField;

const useStyles = makeStyles({
  helperText: {
    position: "absolute",
    bottom: -22,
  },
});
