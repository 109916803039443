import React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import TableBody from "@mui/material/TableBody";
import TableCell, { TableCellProps } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";

interface Props {
  columns: Record<
    string,
    {
      align?: TableCellProps["align"];
      hideOnMobile?: boolean;
      label?: (() => React.ReactNode) | React.ReactNode;
      rightPadding?: boolean;
      sortable?: boolean;
      style?: React.CSSProperties;
      superRightPadding?: boolean;
    }
  >;
  heightDiff?: string;
  top?: string;
}

const CfTableBodyLoader = ({ columns, heightDiff, top }: Props) => {
  const classes = useStyles();
  const style = {
    top: top || "57px",
    height: `calc(100% - ${heightDiff || "114px"}`,
  };
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={Object.keys(columns).length}>
          <div className={classes.spinner} style={style}>
            <CircularProgress />
          </div>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export default CfTableBodyLoader;

const useStyles = makeStyles(() => ({
  spinner: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "calc(100% - 114px)",
    zIndex: 999,
    borderRadius: "2px",
    backgroundColor: "white",
    left: 0,
    top: "57px",
  },
}));
