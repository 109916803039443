import React, { FC } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import moment from "moment";
import { FormattedMessage } from "react-intl";

import { getLocalizedDateString } from "../../../../../shared/misc/timeHelpers";

import { getStatusText, getStatusIcon } from "./helpers";
import { EXPORT_TYPES, UploaderJob } from "./types";

export interface Props {
  handleDeleteFactory: (jobId: number) => () => void;
  jobs: UploaderJob[];
}

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    backgroundColor: theme.palette.grey[100],
    boxShadow: "inset 0px 0px 4px rgba(0, 0, 0, 0.2)",
  },
  headerText: {
    color: theme.palette.grey[500],
  },
  headerWide: {
    width: "55%",
  },
  headerNarrow: {
    width: "25%",
  },
  iconColumns: {
    width: 30,
  },
  iconButtons: {
    padding: 8,
    margin: "12px 0",
  },
  machineName: {
    fontWeight: 500,
  },
  statusIcon: {
    width: 20,
    marginRight: 10,
  },
  withOffset: {
    paddingLeft: 15,
  },
}));

const MachineFiles: FC<Props> = ({ handleDeleteFactory, jobs }) => {
  const classes = useStyles();
  return (
    <div>
      <Table classes={{ root: classes.table }}>
        <TableHead>
          <TableRow>
            <TableCell
              className={classnames(
                classes.headerText,
                classes.headerWide,
                classes.withOffset,
              )}
            >
              <FormattedMessage id="VaExports.machineFiles.machine" />
            </TableCell>
            <TableCell
              className={classnames(classes.headerText, classes.headerNarrow)}
            >
              <FormattedMessage id="VaExports.machineFiles.date" />
            </TableCell>
            <TableCell
              className={classnames(classes.headerText, classes.headerNarrow)}
            >
              <FormattedMessage id="VaExports.machineFiles.state" />
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {jobs.map((job) => (
            <TableRow key={job.jobId}>
              <TableCell
                className={classnames(classes.machineName, classes.withOffset)}
                size="small"
              >
                {job.name}
              </TableCell>
              <TableCell size="small">
                {getLocalizedDateString(moment(job.exportDate), ["L", "LTS"])}
              </TableCell>
              <TableCell size="small">
                <img
                  alt="status"
                  className={classes.statusIcon}
                  src={getStatusIcon(job.status)}
                />
                <FormattedMessage
                  id={getStatusText(job.status, EXPORT_TYPES.UPLOADER)}
                />
              </TableCell>
              <TableCell classes={{ root: classes.iconColumns }} size="small">
                <IconButton
                  aria-label="Remove item"
                  className={classes.iconButtons}
                  onClick={handleDeleteFactory(job.jobId)}
                  size="large"
                >
                  <ClearIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default MachineFiles;
