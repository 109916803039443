import React from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ErrorMessage } from "formik";

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    fontSize: 12,
    color: theme.palette.error.main,
    textAlign: "left",
  },
}));

type Props = {
  name: string;
};

const FormErrorMessage = ({ name }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.error}>
      <ErrorMessage name={name} />
    </div>
  );
};

export default FormErrorMessage;
