import React, { useEffect } from "react";

import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import { useDispatch } from "react-redux";

import { setActionButtonHidden } from "../shared/actions/manager.actions";

import { NAMESPACE } from "./reducer/telematicsAdmin.reducer";

import CfTextFilter from "../../../common/components/CfTextFilter/CfTextFilter";
import { useCfTextFilter } from "../../../common/components/CfTextFilter/useCfTextFilter";
import { useToggle } from "../../../shared/hooks/useToggle";

import ActivationDialog from "./components/ActivationDialog";
import ActivationSelector from "./components/ActivationSelector";
import AdvancedFilter from "./components/AdvancedFilter";
import DeactivationDialog from "./components/DeactivationDialog";
import TelematicsAdminTable from "./components/TelematicsAdminTable";

interface Props {
  langId: string;
}

const TelematicsAdmin = ({ langId }: Props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    on: activationDialogIsOpen,
    setOff: closeActivationDialog,
    setOn: openActivationDialog,
  } = useToggle();
  const {
    on: deactivationDialogIsOpen,
    setOff: closeDeactivationDialog,
    setOn: openDeactivationDialog,
  } = useToggle();
  const { onChange } = useCfTextFilter(NAMESPACE);

  useEffect(() => {
    dispatch(setActionButtonHidden(true));
  }, [dispatch]);

  return (
    <div>
      <div className={classes.filterBarContainer}>
        <ActivationSelector
          className={classes.item}
          handleActivation={openActivationDialog}
          handleDeactivation={openDeactivationDialog}
        />
        <div className={classnames(classes.textFilter, classes.item)}>
          <CfTextFilter
            name="telematics-admin-text-filter"
            onChange={onChange}
            translId="TelematicsAdmin.textFilter"
          />
        </div>
        <AdvancedFilter langId={langId} />
      </div>
      <TelematicsAdminTable />
      <ActivationDialog
        handleClose={closeActivationDialog}
        opened={activationDialogIsOpen}
      />
      <DeactivationDialog
        handleClose={closeDeactivationDialog}
        opened={deactivationDialogIsOpen}
      />
    </div>
  );
};

const useStyles = makeStyles({
  filterBarContainer: {
    display: "flex",
    marginBottom: 6,
  },
  textFilter: {
    maxWidth: 400,
  },
  item: {
    marginRight: 12,
  },
});

export default TelematicsAdmin;
