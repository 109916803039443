import React from "react";

import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import CfNoData from "../../../../../shared/components/charts/CfNoData/CfNoData";
import CfStaticMap from "../../../../../shared/components/specific/CfStaticMap/CfStaticMap";
import { useToggle } from "../../../../../shared/hooks/useToggle";
import PrecisionMap from "../../PrecisionMapModal/PrecisionMap";
import PrecisionMapModal from "../../PrecisionMapModal/PrecisionMapModal";
import SectionHeader from "../SectionHeader";

import GeneralizedZoneManagementLegend from "./GeneralizedZoneManagementLegend";

import { ManagementZoneResponseDto } from "../../../../../shared/api/satellite/satellite.types";

type Props = {
  item?: ManagementZoneResponseDto;
};

const GeneralizedZoneManagementMap = ({ item }: Props) => {
  const classes = useStyles();
  const legendClasses = useLegendStyles();
  const { on: isOpen, setOff: closeDialog, setOn: openDialog } = useToggle();

  const getZones = () =>
    item?.savi?.sort((a, b) => Number(b.index) - Number(a.index));

  const zones = getZones() ?? [];
  const noData = !zones.length;

  const getLegend = (isModal?: boolean) => (
    <div className={classes.mapLegend}>
      <SectionHeader
        headingTranslationId="ManagementZones.overview.heading"
        isModal={isModal}
      />
      <GeneralizedZoneManagementLegend
        classes={legendClasses}
        zones={zones.sort((a, b) => Number(a.index) - Number(b.index))}
      />
    </div>
  );

  return (
    <>
      <div
        className={classes.wrapper}
        onClick={noData ? undefined : openDialog}
      >
        {noData ? (
          <CfNoData />
        ) : (
          <>
            {getLegend()}
            <CfStaticMap
              geometries={zones}
              mapId="management-zones-map"
              classes={{
                map: classes.map,
                mapSelected: classes.mapSelected,
              }}
            />
          </>
        )}
      </div>
      {isOpen && item && (
        <PrecisionMapModal
          header={null}
          legend={getLegend(true)}
          map={<PrecisionMap geometries={zones} parcelId={item.parcelId} />}
          onClose={closeDialog}
        />
      )}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    height: 764,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    alignItems: "center",
    margin: "0 15px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      height: 500,
    },
  },
  map: {
    borderRadius: 0,
    "& .ol-viewport": {
      borderRadius: 0,
    },
  },
  mapSelected: {
    borderRadius: 0,
  },
  mapLegend: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "100%",
    marginBottom: 4,
  },
}));

const useLegendStyles = makeStyles(() => ({
  zone: {
    fontSize: 13,
  },
}));

export default GeneralizedZoneManagementMap;
