import orderBy from "lodash/orderBy";
import { RSAA } from "redux-api-middleware";

import * as types from "./variableApplication.constants";
import * as sortOrder from "../../../constants/sortOrder.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../api.constants";

import { MachineTo } from "../../../../evidence/actions/shared/components/VariableActionExportsList/types";
import { AnyTodo } from "../../../../types";

import {
  IndexType,
  VariableApplicationType,
  VariableApplicationZoneExtendedResponse,
  VariableApplicationZoneType,
} from "../../satellite/satellite.types";

export const getVariableActionZonesApi = (
  farmId: string,
  parcelId: string,
) => ({
  [RSAA]: {
    endpoint: `variable-applications/zones/${farmId}/${parcelId}?`,
    method: methods.GET,
    module: modules.SENTINEL,
    types: [
      types.GET_VARIABLE_ACTION_ZONES,
      types.GET_VARIABLE_ACTION_ZONES_SUCCESS,
      types.GET_VARIABLE_ACTION_ZONES_FAILURE,
    ],
  },
});

export const resetVariableActionZones = (context: undefined) => ({
  type: types.RESET_VARIABLE_ACTION_ZONES,
  meta: {
    context,
  },
});

// ziskanie existujucich variabilnych davok
export const getVariableActionExpenses = (actionId: string) => ({
  [RSAA]: {
    endpoint: `variable-applications/actions/${actionId}?`,
    method: methods.GET,
    module: modules.SENTINEL,
    types: [
      types.GET_VARIABLE_FERTILIZATION_EXPENSES,
      types.GET_VARIABLE_FERTILIZATION_EXPENSES_SUCCESS,
      types.GET_VARIABLE_FERTILIZATION_EXPENSES_FAILURE,
    ],
  },
});

export const resetVariableActionExpenses = (context: undefined) => ({
  type: types.RESET_VARIABLE_FERTILIZATION_EXPENSES,
  meta: {
    context,
  },
});

// ziskani actionIds ktore su VA
export const getVariableActionIds = () => ({
  [RSAA]: {
    endpoint: "variable-applications/actions?",
    method: methods.GET,
    module: modules.SENTINEL,
    types: [
      types.GET_VARIABLE_FERTILIZATION_ACTIONS,
      types.GET_VARIABLE_FERTILIZATION_ACTIONS_SUCCESS,
      types.GET_VARIABLE_FERTILIZATION_ACTIONS_FAILURE,
    ],
  },
});

export const resetVariableActionIds = (context?: undefined) => ({
  type: types.RESET_VARIABLE_FERTILIZATION_ACTIONS,
  meta: {
    context,
  },
});

// ziskani parcelIds na kterych je povoleny variabilni aplikace
export const getVariableParcelIds = () => ({
  [RSAA]: {
    endpoint: "variable-applications/parcels?",
    method: methods.GET,
    module: modules.SENTINEL,
    types: [
      types.GET_VARIABLE_FERTILIZATION_PARCELS,
      types.GET_VARIABLE_FERTILIZATION_PARCELS_SUCCESS,
      types.GET_VARIABLE_FERTILIZATION_PARCELS_FAILURE,
    ],
  },
});

export const resetVariableParcelIds = (context: undefined) => ({
  type: types.RESET_VARIABLE_FERTILIZATION_PARCELS,
  meta: {
    context,
  },
});

type CreateVariableExpenseParams = {
  applicationZones: VariableApplicationZoneExtendedResponse[];
  averageDoseHa: number;
  chemicalElement: string;
  cropLegCode: number;
  dateFrom: string;
  dateTo: string;
  geometry: AnyTodo;
  indexType: string;
  materialId: number;
  maxDoseHa: number;
  minDoseHa: number;
  parcelId: number;
  subtractionGeometry: AnyTodo;
  type: VariableApplicationType;
  unit: string;
  zoneType?: VariableApplicationZoneType;
};

export const createVariableExpense = ({
  applicationZones,
  averageDoseHa,
  chemicalElement,
  cropLegCode,
  dateFrom,
  dateTo,
  geometry,
  indexType,
  materialId,
  maxDoseHa,
  minDoseHa,
  parcelId,
  subtractionGeometry,
  type,
  unit,
  zoneType,
}: CreateVariableExpenseParams) => {
  const body = {
    zoneType: indexType.endsWith(IndexType.SAVI) ? zoneType : undefined,
    parcelId,
    materialId,
    cropLegCode,
    geometry,
    subtractionGeometry,
    type: applicationZones ? "RECOMPUTE" : type,
    index: {
      type: indexType.endsWith(IndexType.SAVI) ? IndexType.SAVI : indexType,
      chemicalElement,
      dateFrom,
      dateTo,
    },
    unit,
    ...(averageDoseHa ? { averageDoseHa } : {}),
    ...(minDoseHa ? { minDoseHa } : {}),
    ...(maxDoseHa ? { maxDoseHa } : {}),
    ...(applicationZones ? { applicationZones } : {}),
  };

  return {
    [RSAA]: {
      endpoint: "variable-applications?",
      method: methods.POST,
      module: modules.SENTINEL,
      body: JSON.stringify(body),
      types: [
        {
          type: types.POST_VARIABLE_FERTILIZATION_EXPENSE,
        },
        {
          type: types.POST_VARIABLE_FERTILIZATION_EXPENSE_SUCCESS,
          payload: (action: never, state: never, res: Response) =>
            res.json().then((data) => ({
              ...data,
              applicationZones: orderBy(
                data.applicationZones,
                "quality",
                sortOrder.DESC,
              ),
              type,
            })),
        },
        {
          type: types.POST_VARIABLE_FERTILIZATION_EXPENSE_FAILURE,
        },
      ],
    },
  };
};

type SaveVariableExpenseParams = {
  actionId: string;
  variableExpenseDTO: unknown;
};

export const saveVariableExpense = ({
  actionId,
  variableExpenseDTO,
}: SaveVariableExpenseParams) => ({
  [RSAA]: {
    endpoint: `variable-applications/actions/${actionId}?`,
    method: methods.PUT,
    module: modules.SENTINEL,
    body: JSON.stringify(variableExpenseDTO),
    types: [
      types.PUT_VARIABLE_FERTILIZATION_EXPENSE,
      types.PUT_VARIABLE_FERTILIZATION_EXPENSE_SUCCESS,
      types.PUT_VARIABLE_FERTILIZATION_EXPENSE_FAILURE,
    ],
  },
});

export const resetVariableExpense = (context: undefined) => ({
  type: types.RESET_VARIABLE_FERTILIZATION_EXPENSE,
  meta: {
    context,
  },
});

export const deleteVariableExpenses = (actionId: string) => ({
  [RSAA]: {
    endpoint: `variable-applications/actions/${actionId}?`,
    method: methods.DELETE,
    module: modules.SENTINEL,
    types: [
      types.DELETE_VARIABLE_FERTILIZATION_EXPENSE,
      types.DELETE_VARIABLE_FERTILIZATION_EXPENSE_SUCCESS,
      types.DELETE_VARIABLE_FERTILIZATION_EXPENSE_FAILURE,
    ],
  },
});

export const exportVariableApplication = (
  applicationId: number,
  exportType: string,
  material: string,
  parcel: string,
  parcelNumber: string,
) => ({
  [RSAA]: {
    endpoint:
      // eslint-disable-next-line max-len
      `variable-applications/${applicationId}/export?export-type=${exportType}&parcel-name=${parcel}&material-name=${material}&parcel-number=${parcelNumber}&`,
    method: methods.GET,
    module: modules.SENTINEL,
    types: [
      types.EXPORT_VARIABLE_APPLICATION,
      types.EXPORT_VARIABLE_APPLICATION_SUCCESS,
      types.EXPORT_VARIABLE_APPLICATION_ERROR,
    ],
  },
});

export const exportVariableApplicationLegacy = (
  applicationId: number,
  exportType: string,
) => ({
  [RSAA]: {
    endpoint: `variable-applications/${applicationId}/export/legacy?export-type=${exportType}&`,
    method: methods.GET,
    module: modules.SENTINEL,
    types: [
      types.EXPORT_VARIABLE_APPLICATION_LEGACY,
      {
        type: types.EXPORT_VARIABLE_APPLICATION_LEGACY_SUCCESS,
        payload: (action: never, state: never, res: Response) => ({
          body: res.blob(),
          disposition: res.headers.get("Content-Disposition"),
          type: res.headers.get("Content-Type"),
        }),
      },
      types.EXPORT_VARIABLE_APPLICATION_LEGACY_ERROR,
    ],
  },
});

export const getAGIntegratedJobs = (applicationId: number) => ({
  [RSAA]: {
    endpoint: `variable-applications/${applicationId}/agintegrated-jobs?`,
    method: methods.GET,
    module: modules.SENTINEL,
    types: [
      {
        type: types.GET_AGI_JOBS,
        payload: () => ({
          applicationId,
        }),
      },
      {
        type: types.GET_AGI_JOBS_SUCCESS,
        payload: (action: never, state: never, res: Response) =>
          res.json().then((data) => ({
            applicationId,
            jobs: [...data],
          })),
      },
      types.GET_AGI_JOBS_ERROR,
    ],
  },
});

export const getAGIConnectedMachines = () => ({
  [RSAA]: {
    endpoint: "variable-applications/agintegrated/machine?",
    method: methods.GET,
    module: modules.SENTINEL,
    types: [
      types.GET_AGI_MACHINES,
      types.GET_AGI_MACHINES_SUCCESS,
      types.GET_AGI_JOBS_ERROR,
    ],
  },
});

export const deleteAGIntegratedJob = (jobId: string | number) => ({
  [RSAA]: {
    endpoint: `variable-applications/agintegrated-jobs/${jobId}?`,
    method: methods.DELETE,
    module: modules.SENTINEL,
    types: [
      types.DELETE_AGI_JOB,
      types.DELETE_AGI_JOB_SUCCESS,
      types.DELETE_AGI_JOB_ERROR,
    ],
  },
});

export const downloadAGIntegratedFile = (
  applicationId: number,
  jobId: string | number,
) => ({
  [RSAA]: {
    endpoint: `variable-applications/${applicationId}/download-file/${jobId}?`,
    method: methods.GET,
    module: modules.SENTINEL,
    types: [
      types.DOWNLOAD_AGI_FILE,
      {
        type: types.DOWNLOAD_AGI_FILE_SUCCESS,
        payload: (action: never, state: never, res: Response) => ({
          body: res.blob(),
          disposition: res.headers.get("Content-Disposition"),
          type: res.headers.get("Content-Type"),
        }),
      },
      types.DOWNLOAD_AGI_FILE_ERROR,
    ],
  },
});

export const sendExportToMachines = (
  applicationId: number,
  jobId: string | number,
  machines: MachineTo,
) => ({
  [RSAA]: {
    endpoint: `variable-applications/${applicationId}/agintegrated-jobs/${jobId}/machine?`,
    method: methods.POST,
    module: modules.SENTINEL,
    body: JSON.stringify(machines),
    types: [
      types.POST_FILE_TO_MACHINES,
      types.POST_FILE_TO_MACHINES_SUCCESS,
      types.POST_FILE_TO_MACHINES_ERROR,
    ],
  },
});
