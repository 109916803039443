import React from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import { NAMESPACE } from "../../reducer/telematicsAggregations.reducer";

import CfTextFilter from "../../../common/components/CfTextFilter/CfTextFilter";
import { useCfTextFilter } from "../../../common/components/CfTextFilter/useCfTextFilter";
import { CfSwitch } from "../../../shared/components/common/CfSwitch/CfSwitch";

interface Props {
  setUnapprovedRidesFilter: (payload: boolean) => void;
  textFilter: string;
  unapprovedRidesOnly: boolean;
}

const TableFilters = ({
  setUnapprovedRidesFilter,
  textFilter,
  unapprovedRidesOnly,
}: Props) => {
  const classes = useStyles();
  const { onChange } = useCfTextFilter(NAMESPACE);

  return (
    <div className={classes.filters}>
      <div className={classes.textFilter}>
        <CfTextFilter
          initialValue={textFilter}
          onChange={onChange}
          translId="TelematicsAggregations.textFilterPlaceholder"
        />
      </div>
      <div className={classes.toggle}>
        <CfSwitch
          initialChecked={unapprovedRidesOnly}
          onChange={(v) => setUnapprovedRidesFilter(v)}
          label={
            <FormattedMessage id="TelematicsAggregations.list.filterUnapproved" />
          }
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  filters: {
    display: "flex",
    height: 40,
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      display: "grid",
      gridTemplateColumns: "auto auto auto",
      gridTemplateRows: "40px",
      gridTemplateAreas: `
        'text-filter text-filter text-filter'
        'date . toggle'
      `,
      height: "auto",
    },
  },
  date: {
    gridArea: "date",
    justifySelf: "flex-start",
    alignSelf: "end",
  },
  textFilter: {
    gridArea: "text-filter",
    justifySelf: "center",
    maxWidth: 400,
    width: "100%",
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(1),
      maxWidth: "unset",
      marginLeft: 0,
    },
  },
  toggle: {
    [theme.breakpoints.up("md")]: {
      marginLeft: "auto",
    },
    gridArea: "toggle",
    justifySelf: "flex-end",
  },
}));

export default TableFilters;
