import React from "react";

import { groupBy, isNil, some } from "lodash";
import countBy from "lodash/countBy";
import every from "lodash/every";
import get from "lodash/get";
import size from "lodash/size";
import moment, { Moment } from "moment";
import { FormattedMessage } from "react-intl";

import { isOtherAction } from "../../ActionOthers/helpers/others.helpers";
import { isVrf } from "../../vrf/helpers/vrf.helpers";
import { isVrs } from "../../vrs/helpers/vrs.helpers";

import {
  ActionOtherDetailTo,
  ActionOverviewTo,
  ActionType,
  CatalogueType,
  CropTo,
} from "../../../../shared/api/agroevidence/agroevidence.types";
import {
  VariableActionZonesTo,
  VariableApplicationPutRequestBody,
  VariableApplicationZoneExtendedResponse,
} from "../../../../shared/api/satellite/satellite.types";
import {
  ExtInitialParcelToAdd,
  FertilizerAndPPPsType,
  InitialParcelToAdd,
  OnVarAppExportExpenses,
} from "../../ActionOthers/actionOther.types";

export const parcelsCountChanged = (
  oldParcels: ExtInitialParcelToAdd[],
  newParcels: ExtInitialParcelToAdd[],
) => newParcels.length !== oldParcels.length;

export const expenseCountChanged = (
  oldExpenses: VariableApplicationPutRequestBody[],
  newExpenses: VariableApplicationPutRequestBody[],
) => newExpenses.length !== oldExpenses.length;

export const parcelsAreaChanged = (
  oldParcelsArea: number,
  newParcelsArea: number,
) => oldParcelsArea !== newParcelsArea;

const NO_CROP: CropTo = {
  id: "56169128334983305",
  name: "Bez plodiny",
  externalId: 0,
  catalogueType: CatalogueType.EAGRI,
  group: {
    id: "56169127934427383",
    externalId: "0",
    name: "Nedefinováno",
    legislativeCode: "0",
  },
  validFrom: "2000-01-01",
  color: {
    red: 255,
    green: 255,
    blue: 255,
    asDecimal: 16777215,
  },
  textColor: {
    red: 0,
    green: 0,
    blue: 0,
    asDecimal: 0,
  },
};

export const resolveTargetCrop = (
  parcels: ExtInitialParcelToAdd[],
): CropTo | undefined => {
  if (!parcels || parcels.length === 0) {
    return undefined;
  }

  const firstParcel = parcels[0];
  if (firstParcel?.seedApplication) {
    return get(firstParcel, "seedApplication.seed.crop") as CropTo;
  }

  return NO_CROP;
};

export const isAllParcelsSameCrop = (
  parcelList: InitialParcelToAdd[],
  cropPredicate?: (parcel: InitialParcelToAdd) => boolean,
) => {
  const numParcelsByCropCodes = countBy(
    parcelList,
    "seedApplication.seed.crop.externalId",
  );
  return (
    size(numParcelsByCropCodes) === 1 &&
    (cropPredicate ? every(parcelList, cropPredicate) : true)
  );
};

export const warnTargetCrop = (values: {
  parcels: InitialParcelToAdd[];
  targetCrop: CropTo;
}) => {
  let result = null;
  const { parcels, targetCrop } = values;

  if (targetCrop) {
    //  TODO make for all action types
    const allParcelsSameCrop = isAllParcelsSameCrop(parcels);
    const defaultTargetCrop = resolveTargetCrop(parcels);
    if (
      defaultTargetCrop &&
      (defaultTargetCrop.externalId !== targetCrop.externalId ||
        !allParcelsSameCrop)
    ) {
      result = {
        // advanced error structure supported by some Cf-components (like CfSelector)
        icon: "info",
        message: (
          <FormattedMessage
            id={
              allParcelsSameCrop
                ? "action.targetCropWarning"
                : "action.targetCropWarningDiffCrops"
            }
            values={{
              crop: targetCrop.name,
            }}
          />
        ),
      };
    }
  }

  return result;
};

export const validateParcels = (values: { parcels: InitialParcelToAdd[] }) => {
  const { parcels } = values;
  return !parcels || !parcels.length
    ? { _error: <FormattedMessage id="ParcelControl.chooseParcel" /> }
    : null;
};

export const isValidDate = (date?: Moment) =>
  moment.isMoment(date) && date.isValid();

export const validateActionDate = (values: { actionDate?: Moment }) => {
  const { actionDate } = values;
  if (!actionDate) {
    return { _error: <FormattedMessage id="action.selectDate" /> };
  } else if (!isValidDate(actionDate)) {
    return { _error: <FormattedMessage id="action.selectValidDate" /> };
  } else {
    return null;
  }
};

export const validateExpenses = (
  values: { expenses: FertilizerAndPPPsType[] },
  msg = "ActionExpensesControl.chooseMaterial",
) => {
  const { expenses } = values;
  return !expenses || !expenses.length
    ? { _error: <FormattedMessage id={msg} /> }
    : null;
};

export const validateMinDose = (values: {
  minDose: number;
  maxDose: number;
}) => {
  const { maxDose, minDose } = values;
  return maxDose && minDose >= maxDose
    ? {
        _error: (
          <FormattedMessage id="VariableFertilization.minDoseValidation" />
        ),
      }
    : null;
};

export const validateMaxDose = (values: {
  minDose: number;
  maxDose: number;
}) => {
  const { maxDose, minDose } = values;
  return minDose && maxDose <= minDose
    ? {
        _error: (
          <FormattedMessage id="VariableFertilization.maxDoseValidation" />
        ),
      }
    : null;
};

const isCatchCrop = (parcel: InitialParcelToAdd) =>
  parcel.seedApplication?.type === "CATCH_CROP";

const isToTargetCatchCrop = (
  parcels: InitialParcelToAdd[],
  targetCrop: CropTo,
) => {
  const parcelsByCropCodes = groupBy(
    parcels,
    "seedApplication.seed.crop.externalId",
  );
  const targetCropParcels = targetCrop
    ? parcelsByCropCodes[targetCrop.externalId]
    : undefined;
  return !isNil(targetCropParcels) && some(targetCropParcels, isCatchCrop);
};

const isInvalidCatchCropApplication = (
  parcels: InitialParcelToAdd[],
  targetCrop: CropTo,
) =>
  isToTargetCatchCrop(parcels, targetCrop) &&
  !isAllParcelsSameCrop(parcels, isCatchCrop);

export const validateTargetCrop = (values: {
  parcels: InitialParcelToAdd[];
  targetCrop: CropTo;
}) => {
  const { parcels, targetCrop } = values;

  if (!targetCrop) {
    return { _error: <FormattedMessage id="validation.required" /> };
  }

  return isInvalidCatchCropApplication(parcels, targetCrop)
    ? {
        message: <FormattedMessage id="Eph.catchCropParcelsError" />,
      }
    : null;
};

export const zoneTotalDoseChanged = (
  oldZones: VariableApplicationZoneExtendedResponse[],
  newZones: VariableApplicationZoneExtendedResponse[],
) =>
  newZones.some((newZone) => {
    const currZone = oldZones.find((z) => z.zoneId === newZone.zoneId);
    if (!currZone) {
      return false;
    }

    return currZone.totalDose !== newZone.totalDose;
  });

export const EPH_CODES = [
  ActionType.FERT,
  ActionType.FERT_IND,
  ActionType.FERT_ORG,
  ActionType.SPRAY,
  ActionType.TANKMIX,
  ActionType.LIMING,
  ActionType.SPREADING_PEST,
];

export const isEph = (
  action: ActionOtherDetailTo | ActionOverviewTo,
  variableActionIds: string[],
) => {
  if (!action || !action.id) return false;
  const isEphCode = EPH_CODES.includes(action.actionType as ActionType);

  return isEphCode && !variableActionIds?.includes(action.id);
};

export const getRouting = (
  action: ActionOtherDetailTo | ActionOverviewTo,
  variableActionIds: string[],
) =>
  isEph(action, variableActionIds) ||
  isVrf(action, variableActionIds) ||
  isVrs(action, variableActionIds) ||
  isOtherAction(action as ActionOtherDetailTo)
    ? "actions.action"
    : "action";

export const onVarAppExport = (
  props: {
    expenses: OnVarAppExportExpenses[];
    parcels: ExtInitialParcelToAdd[];
    exportVariableApplications: (
      applications: unknown[],
      exportType: string,
      parcelName: string,
      parcelNumber: string,
    ) => void;
  },
  exportType: string,
) => {
  const { expenses, exportVariableApplications, parcels } = props;
  const applications = expenses
    ?.filter((e) => e.variableExpense)
    .map((e) => ({ id: e.variableExpense.id, material: e.material.name }));
  const parcelName = parcels?.[0]?.localName || "";
  const blockNumber = parcels?.[0]?.blockNumber || "0";
  const parcelNumber = blockNumber.replace("/", "_");
  exportVariableApplications(
    applications,
    exportType,
    parcelName,
    parcelNumber,
  );
};

export const parseVarActionToSavi = (val: VariableActionZonesTo) => ({
  dateFrom: val?.dateFrom,
  dateTo: val?.dateTo,
  zones: val?.zones ?? [],
  type: val.type,
});
