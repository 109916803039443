import { RsaaApiError } from "../../../types";
import { Tab } from "../../containers/TelematicsTabs/TelematicsTabs";

type Props = {
  driversAggregatedExportError: RsaaApiError;
  exportAggregatedDrives: () => void;
  exportAggregatedMachinesDrives: () => void;
  exportDrives: () => void;
  exportAggregatedRecords: () => void;
  isExportingDriversAggregated: boolean;
  isExportingMachinesAggregated: boolean;
  machinesAggregatedExportError: RsaaApiError;
  selectedTelematicsTab: Tab;
  telListIsAggregated: boolean;
};

export function useTelematicsExportState({
  driversAggregatedExportError,
  exportAggregatedDrives,
  exportAggregatedMachinesDrives,
  exportAggregatedRecords,
  exportDrives,
  isExportingDriversAggregated,
  isExportingMachinesAggregated,
  machinesAggregatedExportError,
  selectedTelematicsTab,
  telListIsAggregated,
}: Props) {
  const getExportTabDrives = () => {
    if (selectedTelematicsTab === "drivers") {
      return exportAggregatedDrives;
    } else if (selectedTelematicsTab === "machines") {
      return exportAggregatedMachinesDrives;
    } else if (selectedTelematicsTab === "logbook" && telListIsAggregated) {
      return exportAggregatedRecords;
    } else {
      return exportDrives;
    }
  };

  return {
    tabExportError:
      selectedTelematicsTab === "drivers"
        ? driversAggregatedExportError
        : machinesAggregatedExportError,
    tabIsExporting:
      selectedTelematicsTab === "drivers"
        ? isExportingDriversAggregated
        : isExportingMachinesAggregated,
    exportTabDrives: getExportTabDrives(),
  };
}
