import moment from "moment";
import { Action, AnyAction } from "redux";
import { RSAAAction, RSAAResultAction } from "redux-api-middleware";

import {
  getMainMapTelematicsDashboardMachinesOrder,
  getMainMapTelematicsDashboardMachinesOrderBy,
  getMainMapTelematicsDashboardTextFilter,
  getMainMapTelematicsDashboardSelectedMachine,
} from "./machinesDashboard.selectors";
import { getMainMapTelematics } from "../../../../../core/map/selectors/map.selectors";

import { setSelectedMachineGpsUnit } from "../../../../../core/map/actions/mainMapTelematics/mainMapTelematics.actions";

import * as types from "./machinesDashboard.constants";
import { GET_MACHINE_POSITIONS_SUCCESS } from "../mainMapTelematics.constants";

import MainMapTelematics from "../../../../../core/map/services/MainMapTelematics.service";
import { AnyTodo } from "../../../../../types";
import {
  getMachineDrivesHistoryApi,
  getMachinesPositionDashboardApi,
  GetMachinesPositionDashboardParams,
} from "../mainMapTelematics.api";

import { MainMapState } from "../../../../../reducers/map.reducer.types";
import {
  DailyPositionTo,
  Geometry,
  PositionDashboard,
  PositionDetailTo,
} from "../../telematics.types";
import { getMachineDrivesHistoryApiParams } from "../mainMapTelematics.types";

export const setDashboardMachineIsOpen = (payload: boolean) => ({
  type: types.SET_DASHBOARD_MACHINE_IS_OPEN,
  payload,
});

export const setDashboardMachinesReset = () => ({
  type: types.GET_MACHINES_POSITION_DASHBOARD_RESET,
});

export const resetDashboardDrivesHistory =
  () => (dispatch: (action: AnyAction) => void) => {
    dispatch({
      type: types.RESET_DASHBOARD_DRIVES_HISTORY,
    });
    dispatch(
      setDashboardMachineDrivesHistory([]) as unknown as Action<RSAAAction>,
    );
  };

export const setDashboardMachineSelected =
  (row?: PositionDashboard) => (dispatch: (action: AnyAction) => void) => {
    dispatch(resetDashboardDrivesHistory() as unknown as Action<RSAAAction>);
    dispatch({
      type: types.SET_DASHBOARD_SELECTED_MACHINE,
      payload: row,
    });
  };

export const setMapDashboardMachinePositions =
  (machinePositions: PositionDetailTo[]) =>
  (_: never, getState: () => MainMapState) => {
    const mainMapTelematics: MainMapTelematics =
      getMainMapTelematics(getState());
    mainMapTelematics.setMachinePositions(machinePositions);
  };

const setDashboardMachinePositions = (
  machinePositions?: PositionDetailTo[],
) => ({
  type: GET_MACHINE_POSITIONS_SUCCESS,
  payload: machinePositions,
});

export const setDashboardMachineSelection =
  () =>
  (dispatch: (action: AnyAction) => void, getState: () => MainMapState) => {
    const state = getState();
    const row = getMainMapTelematicsDashboardSelectedMachine(state);
    dispatch(setSelectedMachineGpsUnit(row?.machineGpsUnit) as AnyTodo);
    const mainMapTelematics: MainMapTelematics =
      getMainMapTelematics(getState());
    mainMapTelematics.setDashboardMachineSelected(row?.position, true);
  };

export const getDashboardMachinePositions =
  () =>
  (dispatch: (action: RSAAAction) => void, getState: () => MainMapState) => {
    const state = getState();

    const params: GetMachinesPositionDashboardParams = {
      "sort-col": getMainMapTelematicsDashboardMachinesOrderBy(state),
      "sort-dir": getMainMapTelematicsDashboardMachinesOrder(state),
      search: getMainMapTelematicsDashboardTextFilter(state),
    };

    (
      dispatch(
        getMachinesPositionDashboardApi(params),
      ) as unknown as Promise<unknown>
    ).then((res: RSAAResultAction<PositionDashboard[]>) => {
      if (!res.error) {
        const mappedDashboardMachines: PositionDetailTo[] = res.payload
          .filter((m) => !!m.position)
          .map((m) => ({
            azimuth: m.azimuth,
            time: m.time ?? "",
            speed: m.speed,
            movementTime: m.movementTime,
            machine: {
              gpsUnit: m.machineGpsUnit,
              group: m.machineGroup,
              name: m.machineName,
            },
            driver: {
              code: m.driverCode ?? "",
              name: m.driverName,
            },
            equipment: {
              code: m.equipmentCode ?? "",
              name: m.equipmentName,
            },
            location: m.position as Geometry,
            state: m.state,
          }));

        dispatch(
          setDashboardMachinePositions(mappedDashboardMachines) as AnyTodo,
        );
      }
    });
  };

export const fetchDashboardDrivesHistory =
  () =>
  (dispatch: (action: RSAAAction) => void, getState: () => MainMapState) => {
    const state = getState();
    const row = getMainMapTelematicsDashboardSelectedMachine(state);

    const params: getMachineDrivesHistoryApiParams = {
      "date-from": moment().startOf("day").toISOString(),
      "date-to": moment().toISOString(),
      machines: row?.machineGpsUnit,
    };

    return dispatch(getMachineDrivesHistoryApi(params, true));
  };

export const setDashboardMachineDrivesHistory =
  (machineDrivesHistory: DailyPositionTo[]) =>
  (_: never, getState: () => MainMapState) => {
    const mainMapTelematics: MainMapTelematics =
      getMainMapTelematics(getState());
    if (mainMapTelematics) {
      mainMapTelematics.setMachineDrivesHistory(machineDrivesHistory, false);
    }
  };
