import React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import loginImg from "../../../assets/img/login-compressed.jpg";
import logo from "../../../assets/img/variants/cleverfarm/login-logo.png";
import LangSwitch from "../../components/specific/LangSwitch/LangSwitch";
import Localization from "../../services/Localization.service";

type Props = React.PropsWithChildren<{
  isFetching?: boolean;
  large?: boolean;
  header?: React.ReactNode;
}>;

const EntryContainer = ({
  children,
  header,
  isFetching,
  large = false,
}: Props) => {
  const classes = useStyles();
  const HeaderJsx =
    header === undefined ? null : (
      <Grid item>
        <h3>{}</h3>
      </Grid>
    );

  return (
    <div className={classes.container}>
      <Paper className={classes.paper} style={{ maxWidth: large ? 700 : 450 }}>
        <Grid alignItems="center" container justifyContent="center" spacing={2}>
          <Grid
            alignItems="stretch"
            className={classes.headerGrid}
            container
            item
            justifyContent="space-between"
            spacing={2}
          >
            <Grid item>
              <img alt="company logo" className={classes.logo} src={logo} />
            </Grid>
            {HeaderJsx}
            <Grid className={classes.langSwitch} item>
              <LangSwitch onLangChange={Localization.setLang} />
            </Grid>
          </Grid>
          <Grid item sm={10} xs={12}>
            {children}
          </Grid>
          {isFetching && (
            <div className={classes.spinner} id="spinner">
              <CircularProgress />
            </div>
          )}
        </Grid>
      </Paper>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    minHeight: "100%",
    backgroundImage: `url(${loginImg})`,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    "-webkit-background-size": "cover",
    "-moz-background-size": "cover",
    "-o-background-size": "cover",
    backgroundSize: "cover",
    margin: 0,
    padding: 16,
  },
  paper: {
    textAlign: "center",
    backgroundColor: "rgba(255,255,255,0.95)",
    padding: "0px 24px 32px 24px",
    position: "relative",
  },
  spinner: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    zIndex: 999,
    top: 0,
    borderRadius: "2px",
    backgroundColor: "rgba(236,236,236,0.9)",
  },
  logo: {
    ...theme.assets.login.style,
    content: `url(${theme.assets.login.src})`,
  },
  headerGrid: {
    width: "100%",
  },
  langSwitch: {
    marginRight: -24,
  },
}));

export default EntryContainer;
