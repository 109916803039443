import React from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface Props {
  children: React.ReactNode;
  negative?: boolean;
  padding?: boolean;
}

const CfTableCellNumber = (props: Props) => {
  const classes = useStyle();
  const { children, negative = false, padding = false } = props;
  return (
    <div
      style={negative ? { color: "red" } : {}}
      className={
        padding ? `${classes.number} ${classes.paddingNumber}` : classes.number
      }
    >
      {children}
    </div>
  );
};

export default CfTableCellNumber;

const useStyle = makeStyles((theme: Theme) => ({
  number: {
    textAlign: "right",
  },
  paddingNumber: {
    [theme.breakpoints.up("md")]: {
      paddingRight: theme.spacing(6),
    },
    [theme.breakpoints.down("lg")]: {
      paddingRight: theme.spacing(2),
    },
  },
}));
