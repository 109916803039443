import React, { useEffect, useState } from "react";

import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { Fab, Theme, Tooltip } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  setDeviceValvesForManualAction,
  getIsFetchingDeviceValves,
} from "../../../../shared/api/irrigation/devices/devices.selectors";
import { Namespace } from "../../../selectors/devices.common.selectors";

import {
  valvesManualControl,
  fetchDeviceValves,
} from "../../../actions/devices.actions";

import { IRRIGATION_URLS } from "../../../irrigation.constants";

import CfTableCell from "../../../../shared/components/tables/CfTableCell/CfTableCell";
import CfTableCheckbox from "../../../../shared/containers/CfTableCheckbox/CfTableCheckbox";
import { useToggle } from "../../../../shared/hooks/useToggle";
import IrrigationService from "../../../services/Irrigation.service";
import IrrigationStatus from "../../IrrigationStatus/IrrigationStatus";
import ValvesStatus from "../../ValvesStatus/ValvesStatus";

import { payloadPrepareStopManualAction } from "./IrrigationManualAction.helper";
import { IrrigationManualActionModal } from "./IrrigationManualActionModal";
import { NextActionCell } from "./NextActionCell";

import {
  Device,
  ValveManualAction,
} from "../../../../shared/api/irrigation/devices/devices.types";

interface Props {
  device: Device;
  farmId: string;
  handleContentRefresh: () => void;
  handleHover: (id: string | null) => void;
  isIrrigating: boolean;
  namespace: Namespace;
  selected?: string[];
}

export const DeviceTableRow = ({
  device,
  farmId,
  handleContentRefresh,
  handleHover,
  isIrrigating,
  namespace,
  selected,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();

  const dispatch = useDispatch();
  const deviceValves = useSelector(setDeviceValvesForManualAction);
  const isFetchingDeviceValves = useSelector(getIsFetchingDeviceValves);

  const [hasClickedToStopValves, setHasClickedToStopValves] = useState(false);

  const {
    on: showDialog,
    setOff: closeDialog,
    setOn: openDialog,
  } = useToggle();

  const isActive = IrrigationService.isActive(device);

  const handleRowClick = () =>
    history.push(`/farm/${farmId}/${IRRIGATION_URLS.DEVICE}/${device.id}`);

  useEffect(() => {
    if (hasClickedToStopValves === true) {
      const stopManualAction = payloadPrepareStopManualAction(deviceValves);
      dispatch(
        valvesManualControl(device.id, stopManualAction, handleContentRefresh),
      );
      setHasClickedToStopValves(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceValves]);

  const handleStopManualAction = (evt: React.SyntheticEvent) => {
    evt.stopPropagation();
    dispatch(fetchDeviceValves(device.id));
    setHasClickedToStopValves(true);
  };

  const handleOpenModal = (evt: React.SyntheticEvent) => {
    evt.stopPropagation();
    dispatch(fetchDeviceValves(device.id));
    openDialog();
  };

  const handleCloseModal = () => {
    closeDialog();
  };

  const handleSubmitModal = (startManualAction: ValveManualAction[]) => {
    dispatch(
      valvesManualControl(device.id, startManualAction, handleContentRefresh),
    );
    closeDialog();
  };

  return (
    <>
      <TableRow
        hover
        key={device.id}
        onClick={handleRowClick}
        onMouseEnter={() => handleHover(device.id)}
        onMouseLeave={() => handleHover(null)}
        style={{ cursor: "pointer" }}
      >
        {selected && (
          <CfTableCheckbox
            id={device.id}
            namespace={namespace}
            selected={selected}
          />
        )}
        {!selected && (
          <CfTableCell name="status">
            <IrrigationStatus installed={isActive} />{" "}
          </CfTableCell>
        )}
        <CfTableCell name="name">
          <div className={classes.nameCell}>
            <div className={classes.deviceName}>{device.name}</div>
            <div className={classes.secondaryText}>{device.externalId}</div>
          </div>
        </CfTableCell>

        <CfTableCell name="valveStatus">
          <ValvesStatus installed={isActive} valvesStatus={device.valves} />
        </CfTableCell>
        <NextActionCell device={device} />
        <CfTableCell name="manualAction">
          {isActive && (
            <Tooltip
              title={
                <span>
                  <FormattedMessage
                    id={
                      isIrrigating
                        ? "Irrigation.valveManualAction.off"
                        : "Irrigation.valveManualAction.on"
                    }
                  />
                </span>
              }
            >
              <Fab
                aria-label="action"
                size="small"
                className={
                  isIrrigating ? classes.fabActive : classes.fabNotActive
                }
                onClick={
                  isIrrigating ? handleStopManualAction : handleOpenModal
                }
              >
                <PowerSettingsNewIcon />
              </Fab>
            </Tooltip>
          )}
        </CfTableCell>
      </TableRow>
      {showDialog && !isFetchingDeviceValves && (
        <IrrigationManualActionModal
          deviceName={device.name}
          deviceValves={deviceValves}
          onClose={handleCloseModal}
          onSubmit={handleSubmitModal}
          showDialog={showDialog}
        />
      )}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  nameCell: {
    paddingLeft: 12,
  },
  activateButton: {
    padding: "3px 8px",
    marginBottom: 4,
    width: "max-content",
  },
  deviceName: {
    fontWeight: 500,
  },
  secondaryText: {
    color: theme.palette.grey[500],
  },
  fabActive: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
  },
  fabNotActive: {
    color: theme.palette.grey[200],
    backgroundColor: theme.palette.grey[500],
    "&:hover": {
      color: theme.palette.grey[200],
      backgroundColor: theme.palette.grey[500],
    },
  },
}));
