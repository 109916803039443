import React, { FC } from "react";

import TableBody from "@mui/material/TableBody";
import { connect } from "react-redux";

import {
  getSectionListOrder,
  getSectionListOrderBy,
  getSectionListPage,
  getSectionListRowsPerPage,
} from "../../../selectors/sectionList.selectors";

import { getSelectedNamespace } from "../../../reducers/namespace.reducer";

import CfTableFooter from "../../../../common/components/CfTableFooter/CfTableFooter";
import { useCfTableFooter } from "../../../../common/components/CfTableFooter/useCfTableFooter";
import CfTableHead from "../../../../common/components/CfTableHead/CfTableHead";
import { useCfTableHead } from "../../../../common/components/CfTableHead/useCfTableHead";
import CfTableBodyEmpty from "../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableBodyLoader from "../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader";
import CfTableWrapper from "../../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import { useDidUpdate } from "../../../../shared/hooks/useDidUpdate";
import { ColumnData } from "../../../helpers/tableHelpers";

import {
  CataloguesState,
  CATALOGUES_NAMESPACES,
} from "../../../../reducers/catalogues.reducer.types";
import { SortOrderType } from "../../../../shared/api/api.types";

interface Props {
  children: React.ReactNode;
  columns: Record<string, ColumnData>;
  count: number;
  handleOnChangeTrigger: () => void;
  hasData: boolean;
  isFetching: boolean;
  namespace: CATALOGUES_NAMESPACES;
  order: SortOrderType;
  orderBy: string;
  page: number;
  rowsPerPage: number;
}

const TCTable: FC<Props> = ({
  children,
  columns,
  count,
  handleOnChangeTrigger,
  hasData,
  isFetching,
  namespace,
  order,
  orderBy,
  page,
  rowsPerPage,
}) => {
  const { onPageChange, onRowsPerPageChange } = useCfTableFooter(namespace);
  const { onSelect, onSort } = useCfTableHead(namespace);

  useDidUpdate(() => {
    handleOnChangeTrigger();
  }, [order, orderBy, page, rowsPerPage]);

  return (
    <CfTableWrapper>
      <CfTableHead
        columns={columns}
        onSelect={onSelect}
        onSort={onSort}
        order={order}
        orderBy={orderBy}
      />
      {isFetching && <CfTableBodyLoader columns={columns} />}
      {!isFetching && hasData && <TableBody>{children}</TableBody>}
      {!isFetching && !hasData && (
        <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
      )}
      <CfTableFooter
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </CfTableWrapper>
  );
};

const mapStateToProps = (state: CataloguesState) => ({
  namespace: getSelectedNamespace(state),
  order: getSectionListOrder(state),
  orderBy: getSectionListOrderBy(state),
  page: getSectionListPage(state),
  rowsPerPage: getSectionListRowsPerPage(state),
});

export default connect(mapStateToProps)(TCTable);
