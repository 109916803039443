import React from "react";

import { makeStyles } from "@mui/styles";
import { Formik } from "formik";
import { FormattedMessage } from "react-intl";

import { AnyTodo } from "../../../types";
import {
  notificationFormInitialValues as initialValues,
  mapNotificationFormValues,
} from "../../services/Notifications.service";
import { NotificationDetailForm } from "../NotificationDetailForm/NotificationDetailForm";

type Props = {
  farmId: string;
  isEditing?: boolean;
  isExisting?: boolean;
  nodeLocation: AnyTodo;
  onEditingEnd: () => void;
  rule: AnyTodo;
  updateRule: (values: AnyTodo) => void;
  createRule: (values: AnyTodo) => void;
};

export function NotificationDetailFormWrapper({
  createRule,
  farmId,
  isEditing = false,
  isExisting = true,
  nodeLocation,
  onEditingEnd,
  rule,
  updateRule,
}: Props) {
  const classes = useStyles();
  const initialRuleValues = isExisting
    ? mapNotificationFormValues(rule, nodeLocation)
    : initialValues;

  const validateForm = (values: AnyTodo) => {
    const errors: Record<string, React.ReactNode> = {};
    if (!values.users || values.users.length === 0) {
      errors.users = (
        <FormattedMessage id="NotificationUsersControl.validation" />
      );
    }
    return errors;
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={isExisting ? initialRuleValues : initialValues}
      validate={validateForm}
      validateOnBlur={false}
      validateOnChange={false}
      onReset={() => {
        if (isExisting) {
          onEditingEnd();
        }
      }}
      onSubmit={(values) => {
        if (isExisting) {
          onEditingEnd();
          updateRule(values);
        } else {
          createRule(values);
        }
      }}
    >
      <NotificationDetailForm
        classes={classes}
        farmId={farmId}
        isEditing={isEditing}
        isExisting={isExisting}
      />
    </Formik>
  );
}

const useStyles = makeStyles(() => ({
  formButtons: {},
  button: {
    margin: 10,
  },
  condition: {
    display: "flex",
    alignItems: "flex-end",
  },
  helperText: {
    position: "absolute",
    bottom: -22,
  },
  infoCardWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  infoCard: {
    display: "flex",
    backgroundColor: "rgba(120, 211, 240, 0.2)",
    width: "fit-content",
    padding: "16px 24px",
    alignItems: "center",
  },
  infoIconWrapper: {
    marginRight: 8,
    width: 24,
    height: 24,
  },
  infoIcon: {
    color: "#78D3F0",
  },
}));

export default NotificationDetailFormWrapper;
