import { AnyAction } from "redux";

import * as types from "./managementZones.constants";

import { ManagementZoneAPI } from "./managementZones.types";

const initialState: ManagementZoneAPI = {
  managementZoneParcel: undefined,
  managementZoneParcelIsFetching: false,
  managementZoneParcelError: undefined,
};

export default () =>
  (state = initialState, action: AnyAction) => {
    switch (action.type) {
      case types.GET_MANAGEMENT_ZONE_PARCEL:
        return {
          ...state,
          managementZoneParcelIsFetching: true,
        };
      case types.GET_MANAGEMENT_ZONE_PARCEL_SUCCESS:
        return {
          ...state,
          managementZoneParcelIsFetching: false,
          managementZoneParcel: action.payload,
        };
      case types.GET_MANAGEMENT_ZONE_PARCEL_ERROR:
        return {
          ...state,
          managementZoneParcelIsFetching: false,
          managementZoneParcelError: action.payload,
        };

      case types.RESET_MANAGEMENT_ZONE_PARCEL:
        return {
          ...state,
          managementZoneParcel: undefined,
          managementZoneParcelIsFetching: false,
          managementZoneParcelError: undefined,
        };

      default:
        return state;
    }
  };
