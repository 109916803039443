import * as services from "../constants/services.constants";

const config = [
  {
    id: services.BIOMONITORING,
    translationBaseId: "BioMonitoring",
    p1: true,
    p2: {
      intro: true,
      itemsLength: 4,
    },
    p3: true,
  },
  {
    id: services.MANAGEMENT_ZONES,
    translationBaseId: "HistoryPotential",
    p1: true,
    p2: {
      intro: true,
      itemsLength: 3,
    },
    p3: true,
  },
  {
    id: services.SENSORS,
    translationBaseId: "NodeLocations",
    p1: true,
    p2: {
      itemsLength: 3,
      additionalInfo: true,
    },
    p3: true,
  },
  {
    id: services.IRRIGATION,
    translationBaseId: "Irrigation",
    p1: true,
    p2: {
      itemsLength: 3,
    },
    p3: true,
  },
  {
    id: services.NOTIFICATIONS,
    translationBaseId: "Notifications",
    p1: true,
    p2: {
      itemsLength: 3,
    },
    p3: true,
  },
  {
    id: services.TELEMATICS,
    translationBaseId: "Telematics",
    p1: true,
    p2: {
      itemsLength: 3,
    },
    p3: true,
  },
  {
    id: services.PRECISION,
    translationBaseId: "PrecisionFarming",
    p1: true,
    p2: {
      intro: true,
      itemsLength: 3,
      additionalInfo: true,
    },
    p3: true,
  },
];

export default class ServiceNotPurchasedService {
  static getServiceConfig(serviceId) {
    return config.find((configItem) => configItem.id === serviceId);
  }
}
