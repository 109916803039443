import React, { Component } from "react";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

const styles = {
  tooltip: {
    margin: 4,
  },
  editButton: {
    padding: 10,
  },
};

class CfTableRowToolButton extends Component {
  renderToolButton() {
    const { classes, disabled, icon, iconProps, onClick } = this.props;
    return (
      <span>
        <IconButton
          classes={{ root: iconProps }}
          className={classes.editButton}
          data-test="tool-icon"
          disabled={disabled}
          onClick={onClick}
          size="large"
        >
          {icon}
        </IconButton>
      </span>
    );
  }

  render() {
    const { classes, tooltipMessage } = this.props;
    return tooltipMessage ? (
      <Tooltip
        disableInteractive
        title={tooltipMessage}
        classes={{
          tooltip: classes.tooltip,
        }}
      >
        {this.renderToolButton()}
      </Tooltip>
    ) : (
      this.renderToolButton()
    );
  }
}

CfTableRowToolButton.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  tooltipMessage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  icon: PropTypes.node.isRequired,
  iconProps: PropTypes.any,
};

CfTableRowToolButton.defaultProps = {
  onClick: () => {},
  disabled: false,
  tooltipMessage: "",
  iconProps: "",
};

export default withStyles(styles)(CfTableRowToolButton);
