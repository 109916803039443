import React, { Fragment, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import { Paper } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars-2";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getGroups,
  getIsFetchingGroups,
} from "../../../shared/api/iot/groups/groups.selectors";
import {
  getGroupsOrder,
  getGroupsOrderBy,
} from "../../selectors/groups.selectors";

import { fetchGroups } from "../../actions/groups.actions";

import CfLoader from "../../../shared/components/common/CfLoader/CfLoader";
import NameEditDialog from "../../../shared/components/common/NameEditDialog/NameEditDialog";
import { Header } from "../../components/NodeGroupsTable/Header";
import {
  GroupsBody,
  NewGroup,
} from "../../components/NodeGroupsTable/NodeGroupsTable";

export const NodeGroups = ({
  fetchGroups,
  groups,
  isFetching,
  onCreateGroup,
  onDeleteGroup,
  onFilterGroupInSensors,
  onUpdateGroup,
  order,
  orderBy,
  shouldReloadData,
}) => {
  const classes = useStyles();

  const [createNewGroup, setCreateNewGroup] = useState(false);
  const [editGroup, setEditGroup] = useState(false);
  const [groupToEdit, setGroupToEdit] = useState({});

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups, order, orderBy, shouldReloadData]);

  const onNewGroupCreate = () => setCreateNewGroup(true);
  const onCreateGroupCancel = () => setCreateNewGroup(false);

  const onCreateGroupConfirm = (group) => {
    onCreateGroup(group);
    setCreateNewGroup(false);
  };

  const onEditGroup = (group) => {
    setEditGroup(true);
    setGroupToEdit(group);
  };

  const onEditGroupClose = () => {
    setEditGroup(false);
    setGroupToEdit(undefined);
  };

  const onEditGroupAccept = (group) => {
    onUpdateGroup(group);
    onEditGroupClose();
  };

  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            {isFetching ? (
              <CfLoader classes={{ wrapper: classes.wrapper }} />
            ) : (
              <Fragment>
                <span className={classes.header}>
                  <FormattedMessage id="NodeGroups.header" />
                </span>
                <Table stickyHeader>
                  <Header order={order} orderBy={orderBy} />
                </Table>
                <Scrollbars autoHeight={true} autoHeightMax={400}>
                  <Table data-test="group-list">
                    <TableBody>
                      {groups.map((item) => (
                        <GroupsBody
                          item={item}
                          key={item.id}
                          onDeleteGroup={onDeleteGroup}
                          onEditGroup={onEditGroup}
                          onFilterGroup={onFilterGroupInSensors}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </Scrollbars>
                <div className={classes.createGroup}>
                  {!createNewGroup && (
                    <span className={classes.button}>
                      <Button
                        className={classes.label}
                        data-test="add-group"
                        disabled={createNewGroup}
                        endIcon={<AddIcon />}
                        onClick={onNewGroupCreate}
                      >
                        <FormattedMessage id="NodeGroups.newGroup" />
                      </Button>
                    </span>
                  )}
                  {createNewGroup && (
                    <div className={classes.createGroupForm}>
                      <NewGroup
                        onCreateGroupConfirm={onCreateGroupConfirm}
                        onCreateGroupDismiss={onCreateGroupCancel}
                      />
                    </div>
                  )}
                </div>
              </Fragment>
            )}
          </Paper>
        </Grid>
      </Grid>
      <NameEditDialog
        item={groupToEdit}
        onAccept={onEditGroupAccept}
        onClose={onEditGroupClose}
        opened={editGroup}
        title={<FormattedMessage id="NodeGroups.editGroup" />}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  isFetching: getIsFetchingGroups(state),
  groups: getGroups(state),
  order: getGroupsOrder(state),
  orderBy: getGroupsOrderBy(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchGroups,
    },
    dispatch,
  );

NodeGroups.propTypes = {
  groups: PropTypes.array.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  fetchGroups: PropTypes.func.isRequired,
  shouldReloadData: PropTypes.bool.isRequired,
  onUpdateGroup: PropTypes.func.isRequired,
  onDeleteGroup: PropTypes.func.isRequired,
  onCreateGroup: PropTypes.func.isRequired,
  onFilterGroupInSensors: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NodeGroups);

const useStyles = makeStyles((theme) => ({
  wrapper: {
    "&:before": {
      content: '""',
      display: "block",
      paddingTop: "100%",
    },
  },
  paper: {
    paddingTop: 10,
  },
  label: {
    display: "flex",
    alignItems: "center",
  },
  createGroup: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.grey[500],
  },
  button: {
    display: "flex",
    justifyContent: "center",
    padding: "5px 15px",
  },
  createGroupForm: {
    padding: "15px 15px",
  },
  header: {
    fontSize: 18,
    fontWeight: 500,
    marginLeft: 15,
    marginBottom: 15,
  },
}));
