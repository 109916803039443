import { storeInitialCrops } from "../crops/crops.actions";
import { refreshSowingPlanStyles } from "../style/style.actions";

import * as types from "./sowingPlan.constants";

import {
  exportSowingPlanApi,
  getSeasonDetailSowingPlanApi,
} from "../../../../shared/api/agroevidence/sowingPlan/sowingPlan.api";
import FileService from "../../../../shared/services/File.service";
import {
  SHOWN_NUMBER_SOWING_SEASONS,
  START_YEAR_FIRST_SOWING_SEASONS,
} from "../../../parcels/shared/services/Parcels.service";

export const storeInitialSeasonSowingPlan = (parcels) => ({
  type: types.STORE_INITIAL_SEASON,
  parcels,
});

export const setSelectedSeasonSowingPlan = (
  selectedSeasonId,
  selectedSeasonYears,
) => ({
  type: types.SET_SELECTED_SEASON,
  selectedSeasonId,
  selectedSeasonYears,
});

export const fetchSeasonSowingPlansMainMap = (seasonId) => (dispatch) =>
  dispatch(getSeasonDetailSowingPlanApi(seasonId)).then((res) => {
    if (res.error) return;
    const parcelsOrder = res.payload.parcels
      .map((parcel) => {
        if (!parcel?.crop) return;
        const item = {};
        item.id = parcel.parcelId;
        item.color = parcel.crop.color;
        return item;
      })
      .filter((item) => typeof item !== "undefined");

    const crops = {};
    const cropsOrder = res.payload.statistics
      .map((statistic) => {
        if (!statistic?.crop) return;
        const crop = {};
        crop.id = statistic.crop.id;
        crop.name = statistic.crop.name;
        crop.visible = true;
        crop.color = statistic.crop.color;
        crop.externalId = statistic.crop.externalId;
        crops[crop.externalId] = crop;
        return crop.externalId;
      })
      .filter((item) => typeof item !== "undefined");

    dispatch(storeInitialSeasonSowingPlan(parcelsOrder));
    dispatch(storeInitialCrops(crops, cropsOrder));
    dispatch(refreshSowingPlanStyles());
  });

export const storeResetSeasonSowingPlan = () => ({
  type: types.STORE_RESET_SEASON,
});

export const exportSowingPlan = () => (dispatch) => {
  dispatch(
    exportSowingPlanApi(
      START_YEAR_FIRST_SOWING_SEASONS,
      SHOWN_NUMBER_SOWING_SEASONS,
    ),
  ).then((res) => {
    FileService.processFileResponse(res);
  });
};
