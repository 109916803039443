import React, { ReactNode, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  getCrops,
  getIsFetchingCrops,
} from "../../../../../../shared/api/agroevidence/catalogues/crops/crops.selectors";

import { fetchCrops } from "../../../../shared/actions/parcels.actions";

import { resetCropsApi } from "../../../../../../shared/api/agroevidence/catalogues/crops/crops.api";
import CfAutocomplete from "../../../../../../shared/components/common/CfAutocomplete/CfAutocomplete";

import { CropTo } from "../../../../../../shared/api/agroevidence/agroevidence.types";

interface Props {
  defaultCrops?: CropTo[];
  label: ReactNode;
  onChange: (items: CropTo[]) => void;
}

const CropsSelector = ({ defaultCrops = [], label, onChange }: Props) => {
  const dispatch = useDispatch();
  const suggestions = useSelector(getCrops);
  const isCropsFetching = useSelector(getIsFetchingCrops);

  useEffect(() => {
    dispatch(fetchCrops());

    return () => {
      dispatch(resetCropsApi());
    };
  }, [dispatch]);

  return (
    <CfAutocomplete
      defaultValues={defaultCrops}
      id="crop-selector"
      isFetching={isCropsFetching}
      isMultiple={true}
      label={label}
      loadOptions={fetchCrops}
      onChange={onChange}
      suggestions={suggestions}
      testId="crop-filter"
    />
  );
};

export default CropsSelector;
