import { useDispatch } from "react-redux";

import {
  setTablePage,
  setTableRowsPerPage,
} from "../../../shared/actions/table.actions";

const useCfTableFooter = (namespace: string) => {
  const dispatch = useDispatch();

  const onRowsPerPageChange = (
    evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    dispatch(setTableRowsPerPage(evt.target.value, namespace));
  };

  const onPageChange = (
    evt: React.MouseEvent<HTMLButtonElement>,
    page: number,
  ) => {
    dispatch(setTablePage(page, namespace));
  };

  return {
    onRowsPerPageChange,
    onPageChange,
  };
};

export { useCfTableFooter };
