import React, {
  useEffect,
  useContext,
  useCallback,
  Fragment,
  useState,
} from "react";

import {
  Grid,
  Button,
  Card,
  Icon,
  Typography,
  Switch,
  FormControlLabel,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Form, Field, FieldArray, useFormikContext } from "formik";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  getSeeds,
  getIsFetchingSeeds,
} from "../../../shared/api/agroevidence/catalogues/seeds/seeds.selectors";

import {
  fetchCropUsage,
  fetchHarvestProduct,
  fetchOtherActionTypes,
} from "../shared/actions/actions.actions";

import {
  FORM_TYPES,
  OTHER_ACTION_HARVEST_CODE,
  OTHER_ACTION_MOWING_CODE,
  OTHER_ACTION_SOWING_CODE,
} from "./actionOther.constants";

import { resetOtherActionTypesApi } from "../../../shared/api/agroevidence/actions/actions.api";
import {
  resetCropUsageApi,
  resetHarvestProductsApi,
} from "../../../shared/api/agroevidence/catalogues/eagri/eagri.api";
import CfFormControl from "../../../shared/components/form/CfFormControl/CfFormControl";
import CfFormikDatePicker from "../../../shared/components/form/CfFormikDatePicker/CfFormikDatePicker";
import CfFormikTextField from "../../../shared/components/form/CfFormikTextField/CfFormikTextField";
import { useTypedIntl } from "../../../shared/hooks/useTypedIntl";
import * as validators from "../../../shared/misc/validators";
import { AnyTodo } from "../../../types";
import { ActionButtons } from "../shared/components/ActionButtons/ActionButtons";
import { ActionDetailContext } from "../shared/containers/ActionDetail/ActionDetail";
import { ParcelsControl } from "../shared/containers/ParcelsControl/ParcelsControl";
import { ActionsPalette } from "../shared/palette/ActionsPalette";

import { Revenues } from "./components/ActionHarvest/Revenues";
import { ActionSelector } from "./components/ActionOther/ActionSelector";
import { AmountSelector } from "./components/ActionOther/AmountSelector";
import { CropUsageSelector } from "./components/ActionOther/CropUsageSelector";
import { HarvestProductSelector } from "./components/ActionOther/HarvestProductSelector";
import { MowingProductSelector } from "./components/ActionOther/MowingProductSelector";
import { SeedSelector } from "./components/ActionOther/SeedSelector";
import { isAnyParcelNotSown, isAnyParcelSown } from "./helpers/others.helpers";

import { ActionOtherFormValues } from "./actionOther.types";
import { CropSeedSuggestionTo } from "../../../shared/api/agroevidence/agroevidence.types";

interface Props {
  countryCode: string;
  formType: string;
  handleLsReset: () => void;
  handleResetForm: () => void;
  handleSaveToLs: (values: ActionOtherFormValues) => void;
  isDraft: boolean;
  isEditing: boolean;
  isExisting: boolean;
  isSubmitting: boolean;
  onEditingEnd: () => void;
  values: ActionOtherFormValues;
}

export const ActionOtherForm = ({
  countryCode,
  formType,
  handleLsReset,
  handleResetForm,
  handleSaveToLs,
  isDraft,
  isEditing,
  isExisting,
  isSubmitting,
  onEditingEnd,
  values,
}: Props) => {
  const classes = useStyles();
  const { locale } = useTypedIntl();

  const [actionTotalArea, setActionTotalArea] = useState(0);
  const dispatch = useDispatch();
  const seeds = useSelector(getSeeds) as CropSeedSuggestionTo[];
  const isFetchingSeeds = useSelector(getIsFetchingSeeds);

  const {
    handleCancelIsSplitting,
    isFetchingActionSplit,
    isSplitting,
    isSplittingError,
    splitActionHandler,
  } = useContext(ActionDetailContext);

  const { resetForm, setFieldValue } = useFormikContext();

  useEffect(
    () => () => {
      dispatch(resetCropUsageApi());
      dispatch(resetHarvestProductsApi());
      dispatch(resetOtherActionTypesApi());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (isExisting) return;
    handleSaveToLs(values);

    if (formType === FORM_TYPES.HARVEST) {
      setFieldValue("actionType", OTHER_ACTION_HARVEST_CODE);
    } else if (formType === FORM_TYPES.MOWING) {
      setFieldValue("actionType", OTHER_ACTION_MOWING_CODE);
    } else if (formType === FORM_TYPES.SOWING) {
      setFieldValue("actionType", OTHER_ACTION_SOWING_CODE);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    if (values.seedId && seeds.length) {
      const selectedSeed = seeds.filter((seed) => seed.id === values.seedId);
      const cropExternalIdforFetch = selectedSeed?.[0]?.cropExternalId;

      (
        dispatch(
          fetchCropUsage(cropExternalIdforFetch),
        ) as unknown as Promise<unknown>
      ).then((res: AnyTodo) => {
        if (!res.payload.length) {
          setFieldValue("cropUseType", "");
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seeds, values.seedId]);

  useEffect(() => {
    if (formType === FORM_TYPES.HARVEST && values.parcels.length) {
      // TODO pouziti externalId pro fetch harvest product zatim neni mozny
      // const cropExternalIdforFetch =
      //   values.parcels[0].seedApplication?.seed.crop.externalId;
      // fetchHarvestProduct(cropExternalIdforFetch);
      dispatch(fetchHarvestProduct());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.parcels, formType]);

  useEffect(() => {
    if (formType === FORM_TYPES.OTHER) {
      dispatch(fetchOtherActionTypes());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formType, locale]);

  useEffect(() => {
    if (values.secondaryHarvestProductId === 0) {
      setFieldValue("secondaryTotalGain", 0);
      setFieldValue("secondaryHectarGain", 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.secondaryHarvestProductId]);

  const handleReset = useCallback(() => {
    if (!isExisting) {
      handleLsReset();
      handleResetForm();
    } else {
      onEditingEnd();
      resetForm();
    }
  }, [handleLsReset, isExisting, onEditingEnd, resetForm, handleResetForm]);

  const handleHarvestCropChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFieldValue("harvestCrop", event.target.checked);
  };

  const handleIsCatchCropChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFieldValue("isCatchCrop", event.target.checked);
  };

  const handleSeedChange = (
    e: React.ChangeEvent,
    value: CropSeedSuggestionTo,
  ) => {
    setFieldValue("seedId", value.id);
  };

  const isNewHarvestForm = formType === FORM_TYPES.HARVEST && !isExisting;
  const isNewMowingForm = formType === FORM_TYPES.MOWING && !isExisting;
  const allMustBeSown = isNewHarvestForm || isNewMowingForm;

  const hasUnsownParcels = isAnyParcelNotSown(values.parcels);
  const isParcelSelected = values.parcels.length > 0;
  const showWarningMessage =
    allMustBeSown && hasUnsownParcels && isParcelSelected;

  const hasAllUnsownParcels = !isAnyParcelSown(values.parcels);

  const hasDuplicateParcelIds =
    values.parcels
      .map((p) => p.id)
      .filter((value, index, self) => self.indexOf(value) !== index).length > 0;

  let warningMessage = "";
  if (showWarningMessage) {
    if (formType === FORM_TYPES.HARVEST) {
      warningMessage = hasAllUnsownParcels
        ? "action.harvest.catchAllParcelsNotSown"
        : "action.harvest.catchSomeParcelsNotSown";
    } else if (formType === FORM_TYPES.MOWING) {
      warningMessage = hasAllUnsownParcels
        ? "action.mowing.catchAllParcelsNotSown"
        : "action.mowing.catchSomeParcelsNotSown";
    }
  }

  const isMainHarvestProductSelected = values.mainHarvestProductId !== 0;
  const isSecondaryHarvestProductSelected =
    values.secondaryHarvestProductId !== 0;
  const czechFarm = countryCode === "CZ";

  return (
    <Fragment>
      <Form>
        <Grid alignItems="center" container justifyContent="center" spacing={1}>
          <Grid item lg={8} md={10} xs={12}>
            <Grid item xs={12}>
              <FieldArray name="parcels">
                {({ form, push, remove }) => (
                  <ParcelsControl
                    allMustBeSown={allMustBeSown}
                    form={form}
                    formName="other"
                    formType={formType}
                    handleInsert={push}
                    handleRemove={remove}
                    hasDuplicateParcelIds={hasDuplicateParcelIds}
                    isDraft={isDraft}
                    isEditing={isEditing}
                    isExisting={isExisting}
                    setActionTotalArea={setActionTotalArea}
                  />
                )}
              </FieldArray>
            </Grid>
            <Grid
              alignItems="center"
              className={classes.date}
              container
              justifyContent="center"
              spacing={1}
            >
              <Grid item md={5} sm={7} xl={4} xs={10}>
                <CfFormControl>
                  <Field
                    component={CfFormikDatePicker}
                    disabled={!isEditing}
                    label={<FormattedMessage id="common.date" />}
                    name="date"
                    validate={validators.formikDateValidRequired}
                  />
                </CfFormControl>
              </Grid>
            </Grid>

            {formType === FORM_TYPES.MOWING && (
              <Grid
                alignItems="center"
                container
                justifyContent="center"
                spacing={1}
              >
                <Grid item md={5} sm={7} xl={4} xs={10}>
                  <MowingProductSelector isEditing={isEditing} />
                </Grid>
              </Grid>
            )}

            {formType === FORM_TYPES.HARVEST && czechFarm && (
              <Grid
                alignItems="center"
                container
                justifyContent="center"
                spacing={1}
              >
                <Grid item md={5} sm={7} xl={4} xs={10}>
                  <HarvestProductSelector isEditing={isEditing} />
                </Grid>
              </Grid>
            )}

            {(formType === FORM_TYPES.HARVEST ||
              formType === FORM_TYPES.MOWING) && (
              <Grid
                alignItems="center"
                container
                justifyContent="center"
                spacing={1}
              >
                <Grid item md={5} sm={7} xl={4} xs={10}>
                  <Revenues
                    actionTotalArea={actionTotalArea}
                    isEditing={isEditing}
                  />
                </Grid>
              </Grid>
            )}

            {formType === FORM_TYPES.HARVEST &&
              isMainHarvestProductSelected &&
              czechFarm && (
                <>
                  <Grid
                    alignItems="center"
                    container
                    justifyContent="center"
                    spacing={1}
                  >
                    <Grid item md={5} sm={7} xl={4} xs={10}>
                      <HarvestProductSelector
                        isEditing={isEditing}
                        isSecondaryHarvestProduct
                      />
                    </Grid>
                  </Grid>
                  {isSecondaryHarvestProductSelected && (
                    <Grid
                      alignItems="center"
                      container
                      justifyContent="center"
                      spacing={1}
                    >
                      <Grid item md={5} sm={7} xl={4} xs={10}>
                        <Revenues
                          actionTotalArea={actionTotalArea}
                          isEditing={isEditing}
                          isSecondaryHarvestProduct={true}
                        />
                      </Grid>
                    </Grid>
                  )}
                </>
              )}

            {formType === FORM_TYPES.OTHER && (
              <Grid
                alignItems="center"
                container
                justifyContent="center"
                spacing={1}
              >
                <Grid item md={5} sm={7} xl={4} xs={10}>
                  <ActionSelector isEditing={isEditing} />
                </Grid>
              </Grid>
            )}

            {
              // todo: temporary hide switch, remove "false"
            }
            {false && formType === FORM_TYPES.MOWING && (
              <Grid
                alignItems="center"
                container
                justifyContent="center"
                spacing={1}
              >
                <Grid item md={5} sm={7} xl={4} xs={10}>
                  <FormControlLabel
                    className={classes.checkboxContainer}
                    disabled={!isEditing}
                    label={<FormattedMessage id="action.harvestCrop" />}
                    labelPlacement="start"
                    control={
                      <Switch
                        checked={values.harvestCrop}
                        color="primary"
                        id="harvestCrop"
                        onChange={handleHarvestCropChange}
                      />
                    }
                  />
                </Grid>
              </Grid>
            )}

            {formType === FORM_TYPES.SOWING && (
              <Grid
                alignItems="center"
                container
                justifyContent="center"
                spacing={1}
              >
                <Grid item md={5} sm={7} xl={4} xs={10}>
                  <SeedSelector
                    isEditing={isEditing}
                    isFetchingSeeds={isFetchingSeeds}
                    onChange={handleSeedChange}
                    seeds={seeds as CropSeedSuggestionTo[]}
                  />
                </Grid>
              </Grid>
            )}

            {formType === FORM_TYPES.SOWING && czechFarm && (
              <Grid
                alignItems="center"
                container
                justifyContent="center"
                spacing={1}
              >
                <Grid item md={5} sm={7} xl={4} xs={10}>
                  <CropUsageSelector isEditing={isEditing} />
                </Grid>
              </Grid>
            )}

            {formType === FORM_TYPES.SOWING && (
              <Grid
                alignItems="center"
                container
                justifyContent="center"
                spacing={1}
              >
                <Grid item md={5} sm={7} xl={4} xs={10}>
                  <AmountSelector
                    actionTotalArea={actionTotalArea}
                    isEditing={isEditing}
                  />
                </Grid>
              </Grid>
            )}

            {formType === FORM_TYPES.SOWING && (
              <Grid
                alignItems="center"
                container
                justifyContent="center"
                spacing={1}
              >
                <Grid item md={5} sm={7} xl={4} xs={10}>
                  <FormControlLabel
                    className={classes.checkboxContainer}
                    disabled={!isEditing}
                    label={<FormattedMessage id="action.isCatchCrop" />}
                    labelPlacement="start"
                    control={
                      <Switch
                        checked={values.isCatchCrop}
                        color="primary"
                        id="isCatchCrop"
                        onChange={handleIsCatchCropChange}
                      />
                    }
                  />
                </Grid>
              </Grid>
            )}

            <Grid
              alignItems="center"
              container
              justifyContent="center"
              spacing={1}
            >
              <Grid item md={5} sm={7} xl={4} xs={10}>
                <CfFormControl>
                  <Field
                    component={CfFormikTextField}
                    disabled={!isEditing}
                    label={<FormattedMessage id="common.note" />}
                    name="description"
                  />
                </CfFormControl>
              </Grid>
            </Grid>
            {showWarningMessage && (
              <Grid
                alignItems="center"
                className={classes.actionInfo}
                container
                justifyContent="center"
              >
                <Card className={classes.card}>
                  <div className={classes.header}>
                    <Icon>warning</Icon>
                  </div>
                  <Typography className={classes.content} component="p">
                    <FormattedMessage id={warningMessage} />
                  </Typography>
                </Card>
              </Grid>
            )}
            {isEditing && (
              <Grid item xs={12}>
                <Grid
                  alignItems="center"
                  className={classes.formButtons}
                  container
                  justifyContent="center"
                  spacing={0}
                >
                  <Button
                    className={classes.button}
                    disabled={!isEditing}
                    id="reset"
                    onClick={handleReset}
                    type="reset"
                    variant="contained"
                  >
                    <FormattedMessage
                      id={isExisting ? "common.cancel" : "common.reset"}
                    />
                  </Button>
                  <Button
                    className={classes.button}
                    color="primary"
                    id="create"
                    type="submit"
                    variant="contained"
                    disabled={
                      showWarningMessage ||
                      !isEditing ||
                      hasDuplicateParcelIds ||
                      isSubmitting
                    }
                  >
                    <FormattedMessage
                      id={isExisting ? "common.save" : "common.create"}
                    />
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Form>
      {isSplitting && (
        <ActionButtons
          isDisabled={isSplittingError}
          isLoading={isFetchingActionSplit}
          onCancel={handleCancelIsSplitting}
          onConfirm={splitActionHandler}
        />
      )}
    </Fragment>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  formButtons: {},
  button: {
    margin: 10,
  },
  helperText: {
    position: "absolute",
    bottom: -22,
  },
  date: {
    marginTop: 10,
  },
  actionInfo: {
    marginTop: 30,
    marginBottom: 30,
  },
  card: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    backgroundColor: theme.palette.secondary.light,
  },
  content: {
    padding: 16,
  },
  header: {
    padding: 16,
    display: "inline-flex",
    color: ActionsPalette.neutral.main,
  },
  checkboxContainer: {
    marginLeft: 0,
    marginRight: 0,
    width: "100%",
    "& .MuiFormControlLabel-label": {
      width: "100%",
    },
  },
}));
