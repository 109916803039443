import React from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import TableRow from "@mui/material/TableRow";
import { withStyles } from "@mui/styles";
import get from "lodash/get";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import CfTextFieldWithConfirm from "../../../shared/components/common/CfTextFieldWithConfirm/CfTextFieldWithConfirm";
import CfTableCell from "../../../shared/components/tables/CfTableCell/CfTableCell";
import CfTableCellNumber from "../../../shared/components/tables/CfTableCellNumber/CfTableCellNumber";
import CfTableRowToolButton from "../../../shared/components/tables/CfTableRowToolButton/CfTableRowToolButton";
import CfTableRowTools from "../../../shared/components/tables/CfTableRowTools/CfTableRowTools";

const styles = () => ({
  deleteGroupBtn: {
    width: 32,
    height: 32,
    padding: 5,
  },
  name: {
    paddingLeft: 15,
    borderBottom: 0,
    width: "50%",
  },
  count: {
    paddingRight: 15,
  },
  root: {
    borderBottom: 0,
    width: "50%",
  },
  tableRow: {
    transform: "scale(1)",
    "&:hover $tableRowTools": {
      display: "inline-block",
      padding: "0px 10px 0px 0px",
    },
  },
  tableRowTools: {
    display: "none",
  },
  actionBtn: {
    width: 32,
    height: 32,
    padding: 0,
  },
});

export const GroupsBodyPure = (props) => {
  const { classes, item, onDeleteGroup, onEditGroup, onFilterGroup } = props;
  return (
    <TableRow className={classes.tableRow} key={get(item, "id")}>
      <CfTableCell classes={{ root: classes.name }} name="name">
        {get(item, "name", "-")}
      </CfTableCell>
      <CfTableCell
        classes={{ root: `${classes.root} ${classes.count}` }}
        name="nodeCount"
      >
        <CfTableCellNumber>{get(item, "nodeCount", "-")}</CfTableCellNumber>
      </CfTableCell>
      <CfTableRowTools key="group-edits-btn" toolsClass={classes.tableRowTools}>
        <CfTableRowToolButton
          icon={<FilterList />}
          iconProps={classes.actionBtn}
          onClick={() => onFilterGroup([item])}
          tooltipMessage={<FormattedMessage id="NodeGroups.filterGroup" />}
        />
        <CfTableRowToolButton
          icon={<EditIcon />}
          iconProps={classes.actionBtn}
          onClick={() => onEditGroup(item)}
          tooltipMessage={<FormattedMessage id="common.edit" />}
        />
        <CfTableRowToolButton
          icon={<DeleteIcon />}
          iconProps={classes.actionBtn}
          onClick={() => onDeleteGroup(item)}
          tooltipMessage={<FormattedMessage id="common.delete" />}
        />
      </CfTableRowTools>
    </TableRow>
  );
};

GroupsBodyPure.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  onDeleteGroup: PropTypes.func,
  onFilterGroup: PropTypes.func,
  onEditGroup: PropTypes.func,
};

GroupsBodyPure.defaultProps = {
  onDeleteGroup: () => {},
  onFilterGroup: () => {},
  onEditGroup: () => {},
};

export const GroupsBody = withStyles(styles)(GroupsBodyPure);

/* ************************************************************************************ */

export const NewGroupPure = (props) => {
  const { onCreateGroupConfirm, onCreateGroupDismiss } = props;
  return (
    <CfTextFieldWithConfirm
      label={<FormattedMessage id="NodeGroups.addingGroup" />}
      labelTestId="new-group"
      onAccept={(name) => onCreateGroupConfirm({ name })}
      onCancel={onCreateGroupDismiss}
    />
  );
};

NewGroupPure.propTypes = {
  onCreateGroupConfirm: PropTypes.func.isRequired,
  onCreateGroupDismiss: PropTypes.func.isRequired,
};

export const NewGroup = withStyles(styles)(NewGroupPure);
