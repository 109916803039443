import { AnyAction } from "redux";

import { API_IDENTIFIER } from "./sentinel/precision/precision.constants";

import { getStates } from "./api.helpers";

import { ApiDataState } from "./api.types";

const initialState: ApiDataState = {
  isFetching: false,
  data: null,
  error: {},
};

const dataApi =
  <T>(
    prefix: string,
    identifier: API_IDENTIFIER,
    hooks?: {
      onSuccess: (
        nextState: ApiDataState<T>,
        action: AnyAction,
      ) => ApiDataState<T>;
    },
  ) =>
  (state = initialState, action: AnyAction): ApiDataState<T> => {
    const states = getStates(prefix)(identifier);

    switch (action.type) {
      case states.REQUEST:
        return {
          ...state,
          isFetching: true,
          error: {},
        } as ApiDataState<T>;
      case states.SUCCESS: {
        const nextState = {
          ...state,
          error: {},
          isFetching: false,
          data: action.payload,
        };
        return hooks?.onSuccess
          ? hooks.onSuccess(nextState, action)
          : nextState;
      }
      case states.ERROR:
        return {
          ...state,
          isFetching: false,
          data: null,
          error: action.payload,
        };
      case states.RESET:
        return {
          ...state,
          data: null,
          isFetching: false,
          error: {},
        };
      default:
        return state as ApiDataState<T>;
    }
  };

export default dataApi;
