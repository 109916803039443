import React from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useField, useFormikContext } from "formik";
import { FormattedMessage, useIntl } from "react-intl";

import CfAutocomplete from "../../../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import { AnyTodo } from "../../../../../types";
import { createSoilIncorporationDate } from "../../actionEph.services";

import { ActionEphFormValues } from "../../actionEph.types";

interface Props {
  isDisabled: boolean;
  error: boolean;
  index: number;
}

export const FertilizersIncorporationDaysSelector = ({
  error,
  index,
  isDisabled,
}: Props) => {
  const classes = useStyles();

  const { setFieldValue } = useFormikContext<ActionEphFormValues>();
  const intl = useIntl();

  const [actionDate] = useField("date");

  const mapIncorporationDays = incorporationDays.map((option) => ({
    name: intl.formatMessage({ id: option.name }),
    value: option.value,
  }));

  const setIncorporationDaysField = (option: AnyTodo) => {
    const soilIncorporationDate = createSoilIncorporationDate({
      option: option.value,
      actionDate: actionDate.value,
    });

    setFieldValue(
      `fertilizers[${index}].dateSoilIncorporation`,
      soilIncorporationDate,
    );
  };

  return (
    <div className={classes.container}>
      <CfAutocomplete
        defaultValues={mapIncorporationDays[0]}
        disabled={isDisabled}
        error={error}
        helperText={error ? <FormattedMessage id="validation.required" /> : ""}
        id="IncorporationDays-selector"
        label={<FormattedMessage id="SoilIncorporation.incorporation.date" />}
        onChange={setIncorporationDaysField}
        suggestions={mapIncorporationDays}
        testId="fertilizers-selector"
      />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    marginLeft: 0,
    marginRight: "auto",
    [theme.breakpoints.up("md")]: {
      width: "85%",
    },
  },
}));

const incorporationDays = [
  {
    name: "SoilIncorporation.incorporation.no",
    value: "NO",
  },
  {
    name: "SoilIncorporation.incorporation.sameDay",
    value: "SAME_DAY",
  },
  {
    name: "SoilIncorporation.incorporation.nextDay",
    value: "NEXT_DAY",
  },
];
