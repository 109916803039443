import { useState, useCallback } from "react";

const useEditing = () => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEditingStart = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleEditingEnd = useCallback(() => {
    setIsEditing(false);
  }, []);

  return {
    isEditing,
    onEditingEnd: handleEditingEnd,
    onEditingStart: handleEditingStart,
  };
};

export default useEditing;
