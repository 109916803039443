import React, { ReactNode } from "react";

import { useIntl } from "react-intl";

import { getCataloguesTypesApi } from "../../../../../shared/api/agroevidence/catalogues/cataloguesTypes.api";
import CfAutocomplete from "../../../../../shared/components/common/CfAutocomplete/CfAutocomplete";

import { AdvFilterSourceState } from "../../../../../shared/api/agroevidence/actions/action.types";
import { ActionSource } from "../../../../../shared/api/agroevidence/agroevidence.types";

interface Props {
  defaultSource?: AdvFilterSourceState[];
  label: ReactNode;
  onChange: (items: AdvFilterSourceState[]) => void;
}

export const ActionSourceSelector = ({
  defaultSource = [],
  label,
  onChange,
}: Props) => {
  const intl = useIntl();

  const ACTION_SOURCES = Object.values(ActionSource);

  const extendedSuggestions = ACTION_SOURCES.map((source) => ({
    source,
    name: intl.formatMessage({ id: `common.action.source.${source}` }),
  }));

  return (
    <CfAutocomplete
      defaultValues={defaultSource}
      id="action-source-filter"
      isMultiple={true}
      label={label}
      loadOptions={getCataloguesTypesApi}
      onChange={onChange}
      suggestions={extendedSuggestions}
      testId="action-source-filter"
    />
  );
};
