import React, { useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import { Checkbox, Grid, TableCell, TableRow } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import CfTableCell from "../../../shared/components/tables/CfTableCell/CfTableCell";
import CfTableRowToolButton from "../../../shared/components/tables/CfTableRowToolButton/CfTableRowToolButton";
import CfTableRowTools from "../../../shared/components/tables/CfTableRowTools/CfTableRowTools";
import { useToggle } from "../../../shared/hooks/useToggle";
import { getSourceId } from "../../helpers/selectorHelpers";

import { COLUMN_NAMES } from "./seeds.columns";
import SeedsEditDialog from "./SeedsEditDialog";

import { CropSeedTo } from "../../../shared/api/agroevidence/agroevidence.types";

interface Props {
  data: CropSeedTo;
  onUpdateIsFavorite: (seedId: string, isFavorite: boolean) => void;
}

const SeedsListRow = ({ data, onUpdateIsFavorite }: Props) => {
  const classes = useStyles();

  const {
    on: showEditDialog,
    setOff: handleEditDialogClose,
    setOn: handleEditDialogOpen,
  } = useToggle();
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDialogClose = () => {
    setIsDeleting(false);
    handleEditDialogClose();
  };

  const handleDeleteDialogOpen = () => {
    setIsDeleting(true);
    handleEditDialogOpen();
  };

  const allowEditing = data.catalogue?.canUpdate;

  const handleFavoriteClick = () => {
    onUpdateIsFavorite(data.id, data.isFavorite);
  };

  return (
    <TableRow className={classes.row}>
      <TableCell
        classes={{ root: classes.isFavorite }}
        key={COLUMN_NAMES.IS_FAVORITE}
        onClick={(evt) => evt.stopPropagation()}
        padding="checkbox"
      >
        <Checkbox
          checked={data.isFavorite}
          checkedIcon={<StarRoundedIcon />}
          color="secondary"
          icon={<StarBorderRoundedIcon />}
          onClick={handleFavoriteClick}
        />
      </TableCell>
      <CfTableCell name={COLUMN_NAMES.CROP}>
        <Grid alignItems="center" container>
          <div
            className={classes.cropColor}
            style={{
              backgroundColor: `rgba(${data.crop.color.red}, ${data.crop.color.green}, ${data.crop.color.blue}, 1)`,
            }}
          />
          <div className={classes.crop}>{data.crop.name}</div>
        </Grid>
      </CfTableCell>
      <CfTableCell name={COLUMN_NAMES.VARIETY}>
        {data.varietyName ?? "-"}
      </CfTableCell>
      <CfTableCell name={COLUMN_NAMES.SOURCE}>
        <FormattedMessage id={getSourceId(data.catalogue?.type)} />
      </CfTableCell>
      {allowEditing && (
        <CfTableRowTools toolsClass={classes.editButton}>
          <CfTableRowToolButton
            icon={<EditIcon />}
            onClick={handleEditDialogOpen}
            tooltipMessage={
              <FormattedMessage id="Catalogues.table.shared.disabledEditTooltip" />
            }
          />
          <CfTableRowToolButton
            icon={<DeleteIcon />}
            onClick={handleDeleteDialogOpen}
            tooltipMessage={<FormattedMessage id="common.delete" />}
          />
        </CfTableRowTools>
      )}
      <SeedsEditDialog
        data={data}
        handleClose={handleDialogClose}
        isDeleting={isDeleting}
        isExisting
        opened={showEditDialog}
      />
    </TableRow>
  );
};

export default SeedsListRow;

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    transform: "scale(1)",
    "&:hover $editButton": {
      display: "inline-block",
    },
    "& td:first-child": {
      paddingLeft: theme.spacing(1),
    },
  },
  editButton: {
    display: "none",
  },
  isFavorite: {
    width: 30,
  },
  cropColor: {
    width: 14,
    height: 14,
    borderRadius: "50%",
    border: "1px solid rgba(0, 0, 0, 0.2)",
    marginRight: 10,
  },
  crop: {
    fontWeight: 500,
  },
}));
