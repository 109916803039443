import React from "react";

import AddIcon from "@mui/icons-material/Add";
import { Theme } from "@mui/material";
import Fab from "@mui/material/Fab";
import { makeStyles } from "@mui/styles";

interface Props {
  enabled: boolean;
  handleDialogOpen: () => void;
}

export const AdminFabButton = ({ enabled, handleDialogOpen }: Props) => {
  const classes = useStyles();
  return (
    <div>
      <Fab
        aria-label="add sensor"
        className={classes.button}
        color="secondary"
        disabled={!enabled}
        onClick={handleDialogOpen}
        size="medium"
      >
        <AddIcon />
      </Fab>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: theme.palette.common.white,
  },
}));
