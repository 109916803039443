import React from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import CfTextFilter from "../../../../../common/components/CfTextFilter/CfTextFilter";
import { useCfTextFilter } from "../../../../../common/components/CfTextFilter/useCfTextFilter";

type Props = {
  namespace: string;
  textFilter?: string;
};

export const ParcelListTextFilter = ({ namespace, textFilter }: Props) => {
  const classes = useStyles();
  const { onChange } = useCfTextFilter(namespace);

  return (
    <div className={classes.wrapper}>
      <CfTextFilter
        initialValue={textFilter}
        name="parcel-list-text-filter"
        onChange={onChange}
        translId="ParcelListTextFilter.placeholder"
      />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    margin: 0,
  },
  [theme.breakpoints.up("sm")]: {
    wrapper: {
      margin: theme.spacing(1),
    },
  },
}));

export default ParcelListTextFilter;
