import axios from "axios";
import { useParams } from "react-router-dom";
import { z } from "zod";

const setLanguage = (language: string) => {
  axios.defaults.params = {
    ...(axios.defaults.params ?? {}),
    language: language.split("-")[0],
  };
};

/**
 * document.location parsing is an ugly hack until we restructure our react-router routes
 */
const useFarmIds = (): Record<"farmIds", string> => {
  const farmIdSchema = z.string().regex(/\d/);
  const { farmId } = useParams<{ farmId: string }>();
  const farmIdFallback = document.location.pathname.split("/")[2];
  const maybeFarmId = farmIdSchema.safeParse(farmId);

  if (maybeFarmId.success) {
    return { farmIds: maybeFarmId.data };
  }

  return { farmIds: farmIdSchema.parse(farmIdFallback) };
};

export { setLanguage, useFarmIds };
