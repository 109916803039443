import React, { Component } from "react";

import { withStyles } from "@mui/styles";
import debounce from "lodash/debounce";
import Feature from "ol/Feature";
import { Point } from "ol/geom";
import ImageLayer from "ol/layer/Image";
import VectorLayer from "ol/layer/Vector";
import Projection from "ol/proj/Projection";
import Static from "ol/source/ImageStatic";
import VectorSource from "ol/source/Vector";
import { Fill, Stroke, Style as OlStyle, Circle } from "ol/style";
import PropTypes from "prop-types";
import { compose } from "react-recompose";

import MapLoader from "../../../../core/map/components/MapLoader/MapLoader";
import Geometry from "../../../../core/map/services/geometry/Geometry.service";
import Layers from "../../../../core/map/services/Layers.service";
import MapService from "../../../../core/map/services/Map.service";
import basemapCZ from "../../../api/other/layers/basemapCZ.config.json";
import basemapGlobal from "../../../api/other/layers/basemapGlobal.config.json";
import withConfig from "../../../hocs/context/withConfig";
import { withFarm } from "../../../hocs/context/withFarm";
import { getZoomedExtent } from "../../../misc/map.helpers";

class CfStaticMap extends Component {
  constructor(props) {
    super(props);

    this.MAP_SRID_ID = "3857";
    this.DATA_SRID_ID = "4326";

    this.transformOptions = {
      dataProjection: `EPSG:${this.DATA_SRID_ID}`,
      featureProjection: `EPSG:${this.MAP_SRID_ID}`,
    };

    this.state = {
      isFetching: true,
    };

    this.boundUpdateMapSize = this.updateMapSize.bind(this);
    this.debouncedUpdateMapSize = debounce(this.boundUpdateMapSize, 300);
    window.addEventListener("resize", this.debouncedUpdateMapSize);
  }

  componentDidMount() {
    const {
      config,
      farm: {
        boundingBox,
        customer: { countryCode },
        id: farmId,
      },
      geometries,
      image,
      mapId,
      overlayGeometry,
      points,
    } = this.props;

    this.map = new MapService(
      mapId,
      farmId,
      boundingBox,
      this.transformOptions,
      {
        controls: [],
        interactions: [],
      },
    );

    const basemapConfig = countryCode === "CZ" ? basemapCZ : basemapGlobal;
    this.addLayers(basemapConfig, farmId, countryCode, config, mapId);

    if (points) {
      this.addPoints(points);
    }

    if (geometries) {
      this.addGeometries(geometries);
    }

    if (overlayGeometry) {
      this.addGeometries(
        overlayGeometry,
        `${this.props.mapId}_geometries-difference`,
      );
    }

    if (image) {
      this.addImage(image);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      displayedMap: newDisplayedMap,
      geometries,
      image,
      overlayGeometry,
      points,
    } = this.props;

    const {
      displayedMap: prevDisplayedMap,
      geometries: prevGeometries,
      image: prevImage,
      overlayGeometry: prevOverlayGeometry,
      points: prevPoints,
    } = prevProps;

    if ((!prevPoints?.length && points?.length) || prevPoints !== points) {
      this.addPoints(points);
    }

    if (
      (!prevGeometries?.length && geometries?.length) ||
      prevGeometries !== geometries
    ) {
      this.addGeometries(geometries);
    }

    if (
      (!prevOverlayGeometry?.length && overlayGeometry?.length) ||
      prevOverlayGeometry !== overlayGeometry
    ) {
      this.addGeometries(
        overlayGeometry,
        `${this.props.mapId}_geometries-difference`,
      );
    }

    if (prevImage?.url !== image?.url) {
      this.addImage(image);
    }

    if (prevDisplayedMap !== newDisplayedMap) {
      this.updateMapSize();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.debouncedUpdateMapSize);
  }

  addLayers(layersConfig, farmId, countryCode, config) {
    this.layers = new Layers(
      this.map.getMap(),
      config.api,
      farmId,
      this.map.getFarmExtent(),
    );
    this.layers.setInitialLayers(layersConfig);
  }

  addPoints(points) {
    const layerId = `${this.props.mapId}_points`;
    if (this[layerId]) {
      this.removeLayer(layerId);
    }

    this[layerId] = new VectorLayer({
      source: new VectorSource(),
      style: new OlStyle({
        image: new Circle({
          radius: 4,
          fill: new Fill({ color: "#333333" }),
          stroke: new Stroke({
            color: "#FFFFFF",
            width: 2,
          }),
        }),
      }),
      zIndex: 2,
    });

    points.forEach((point) => {
      this[layerId].getSource().addFeature(
        new Feature({
          geometry: new Point(point.geometry.coordinates),
        }),
      );
    });

    this.layers.addLayer(this[layerId]);
    this.setState({
      isFetching: false,
    });

    this.updateMapSize();
  }

  addGeometries(geometries, layerId = `${this.props.mapId}_geometries`) {
    if (this[layerId]) {
      this.removeLayer(layerId);
    }

    this[layerId] = new VectorLayer({
      source: new VectorSource(),
      style: (feature) =>
        new OlStyle({
          fill: new Fill({
            color: `#${feature.get("color")}`,
          }),
          stroke:
            this.props.stroke === false
              ? undefined
              : new Stroke({
                  color: "#333333",
                  width: 1,
                }),
        }),
    });

    geometries.forEach((geometry) => {
      this[layerId].getSource().addFeature(
        new Feature({
          geometry: Geometry.readGeometry(geometry.geometry, {}),
          color: geometry.color,
        }),
      );
    });

    if (this[layerId].getSource().getFeatures().length > 0) {
      const layerExtent = this[layerId].getSource().getExtent();
      const mapExtent = getZoomedExtent(layerExtent, 0.9);
      this.map.getMap().getView().fit(mapExtent, this.map.getMap().getSize());
    }

    this.layers.addLayer(this[layerId]);
    this.setState({
      isFetching: false,
    });

    this.updateMapSize();
  }

  addImage(image) {
    const layerId = `${this.props.mapId}_imagery`;
    if (this[layerId]) {
      this.removeLayer(layerId);
    }

    const { extent, url } = image;
    this[layerId] = new ImageLayer({
      source: new Static({
        interpolate: this.props.interpolate,
        url,
        projection: new Projection({
          code: "xkcd-image",
          units: "pixels",
          extent,
        }),
        imageExtent: extent,
      }),
    });

    this.map.getMap().getView().fit(extent, this.map.getMap().getSize());

    this.layers.addLayer(this[layerId]);
    this.setState({
      isFetching: false,
    });

    this.updateMapSize();
  }

  removeLayer(layerId) {
    this.setState({
      isFetching: true,
    });
    this.layers.removeLayer(this[layerId]);
    this[layerId] = null;
  }

  updateMapSize() {
    this.map.updateSize();
  }

  render() {
    const { isFetching } = this.state;
    const { classes, interactive, isSelected, mapId } = this.props;

    return (
      <div className={classes.map} id={mapId}>
        {interactive && <div className={classes.mapHover} />}
        <div
          className={`${classes.mapSelected} ${isSelected ? classes.selected : ""}`}
        />
        <MapLoader isFetching={isFetching} offset={100} />
      </div>
    );
  }
}

CfStaticMap.propTypes = {
  classes: PropTypes.object.isRequired,
  farm: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  geometries: PropTypes.array,
  interpolate: PropTypes.bool,
  overlayGeometry: PropTypes.array,
  points: PropTypes.array,
  image: PropTypes.object,
  mapId: PropTypes.string.isRequired,
  displayedMap: PropTypes.bool,
  isSelected: PropTypes.bool,
  interactive: PropTypes.bool,
  stroke: PropTypes.bool,
};

CfStaticMap.defaultProps = {
  variability: null,
  isSelected: false,
  displayedMap: true,
  geometries: null,
  points: null,
  image: null,
  interactive: true,
};

const styles = (theme) => ({
  map: {
    height: "100%",
    width: "100%",
    position: "relative",
    borderRadius: "5px",
    "&:hover $mapHover": {
      opacity: 0.2,
    },
    "& .ol-viewport": {
      borderRadius: "5px",
    },
  },
  mapHover: {
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    position: "absolute",
    backgroundColor: "#EBA607",
    opacity: 0,
    zIndex: 5,
  },
  selected: {
    boxShadow: `inset 0px 0px 0px 3px ${theme.palette.secondary.main}`,
  },
  mapSelected: {
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    position: "absolute",
    backgroundColor: "transparent",
    zIndex: 10,
    borderRadius: "5px",
  },
});

const CfStaticMapWithFarm = withFarm(CfStaticMap);
export default compose(withConfig(), withStyles(styles))(CfStaticMapWithFarm);
