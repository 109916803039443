import React from "react";

import { FormattedNumber } from "react-intl";

import { AnyTodo } from "../../../../types";

type Props = {
  value: number;
  decimalDigits?: number;
  style?: AnyTodo;
  unit?: Intl.NumberFormatOptions["unit"];
  unitDisplay?: Intl.NumberFormatOptions["unitDisplay"];
};

const CfFormattedNumber = ({
  decimalDigits = 3,
  style,
  value,
  ...rest
}: Props) => (
  <FormattedNumber
    {...rest}
    maximumFractionDigits={decimalDigits}
    minimumFractionDigits={decimalDigits}
    style={style}
    value={value}
  />
);

export default CfFormattedNumber;
