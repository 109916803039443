import React, { useMemo } from "react";

import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { IntlProvider } from "react-intl";

import "../../misc/polyfills";
import { LANG_KEYS, LANGUAGE_ID } from "../../lang/lang.constants";

import Metadata from "../../../core/metadata/Metadata";
import createCftheme from "../../../theme";
import commonTranslationsCZ from "../../lang/locale-cs-CZ.json";
import commonTranslationsEL from "../../lang/locale-el-GR.json";
import commonTranslationsEN from "../../lang/locale-en-US.json";
import commonTranslationsES from "../../lang/locale-es-ES.json";
import commonTranslationsHU from "../../lang/locale-hu-HU.json";
import commonTranslationsPL from "../../lang/locale-pl-PL.json";
import commonTranslationsRO from "../../lang/locale-ro-RO.json";
import commonTranslationsRU from "../../lang/locale-ru-UA.json";
import commonTranslationsSR from "../../lang/locale-sr-LATN-RS.json";
import Localization from "../../services/Localization.service";
import { getAppVariant } from "../../variants/getAppVariant";
import SnackbarProvider from "../SnackbarProvider/SnackbarProvider";

const commonTranslations = {
  [LANG_KEYS.CZ]: commonTranslationsCZ,
  [LANG_KEYS.EN]: commonTranslationsEN,
  [LANG_KEYS.SR]: commonTranslationsSR,
  [LANG_KEYS.ES]: commonTranslationsES,
  [LANG_KEYS.RU]: commonTranslationsRU,
  [LANG_KEYS.EL]: commonTranslationsEL,
  [LANG_KEYS.RO]: commonTranslationsRO,
  [LANG_KEYS.HU]: commonTranslationsHU,
  [LANG_KEYS.PL]: commonTranslationsPL,
} as const;

const BaseContainer = ({
  children,
  langId,
  translations,
}: React.PropsWithChildren<{
  langId: LANGUAGE_ID;
  translations: Record<LANGUAGE_ID, Record<string, string>>;
}>) => {
  const variant = getAppVariant();
  const muiTheme = createTheme(createCftheme(variant));

  const mergedTranslations = useMemo(
    () => Localization.mergeTranslations(commonTranslations, translations),
    [translations],
  );

  return (
    <IntlProvider locale={langId} messages={mergedTranslations[langId]}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={muiTheme}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <SnackbarProvider>
              <Metadata />
              {children}
            </SnackbarProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </IntlProvider>
  );
};

export default BaseContainer;
