import { useRef, useEffect, useState } from "react";

import { ChartPoint } from "./HorizontalPointsChart";

type Props = {
  data: ChartPoint[];
};

const useCheckOverlaps = ({ data }: Props) => {
  const [overlapIndices, setOverlapIndices] = useState<Set<number>>(new Set());
  const circleRefs = useRef<(HTMLDivElement | null)[]>([]);

  const checkOverlaps = () => {
    const overlaps = new Set<number>();

    circleRefs.current.forEach((circle, index) => {
      if (circle) {
        const rect1 = circle.getBoundingClientRect();
        for (let i = 0; i < index; i += 1) {
          const prevCircle = circleRefs.current[i];
          if (prevCircle) {
            const rect2 = prevCircle.getBoundingClientRect();
            // Check if the circles overlap horizontally
            if (rect1.left < rect2.right && rect1.right > rect2.left) {
              overlaps.add(index);
              overlaps.add(i);
            }
          }
        }
      }
    });

    setOverlapIndices(overlaps);
  };

  useEffect(() => {
    checkOverlaps();
    window.addEventListener("resize", checkOverlaps);
    return () => window.removeEventListener("resize", checkOverlaps);
  }, [data]);

  return {
    overlapIndices,
    circleRefs,
  };
};

export { useCheckOverlaps };
