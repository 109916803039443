import React, { ReactNode, useCallback } from "react";

import { Chip } from "@mui/material";
import { AutocompleteRenderGetTagProps } from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material/useAutocomplete";
import { useSuspenseQuery } from "@tanstack/react-query";
import { FormattedDate, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { selectShortDateRange } from "../../../selectors/telematicsTabs.selectors";

import { MachineTo } from "../../../../generated/api/telematics";
import { useFarmIds } from "../../../../shared/api/client.utils";
import CfAutocomplete from "../../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import useAdvancedFilterStyles from "../TelematicsAdvancedFilter.styles";

import { machinesQuery } from "./MachineSelector.api";

type Props = {
  defaultValues?: MachineTo[];
  label: ReactNode;
  onChange(items: MachineTo[]): void;
};

const handleGetSelected = (option: MachineTo, value?: MachineTo | null) =>
  option.id === value?.id;

const handleFilterOptions = createFilterOptions({
  stringify: ({ licencePlate, name }) => `${name} ${licencePlate}`,
});

const MachineSelector = ({ defaultValues = [], label, onChange }: Props) => {
  const { dateFrom, dateTo } = useSelector(selectShortDateRange);
  const classes = useAdvancedFilterStyles();
  const machines = useSuspenseQuery(
    machinesQuery({ dateFrom, dateTo, ...useFarmIds() }),
  );

  const handleRenderOption = useCallback(
    (option: MachineTo) => (
      <div>
        <div>{option.name ?? option.gpsUnit}</div>
        <div className={classes.subtext}>
          {option.licencePlate ?? option.gpsUnit}
          {option.validTo && (
            <span>
              {" "}
              (<FormattedMessage id="TelematicsList.filter.machine.validTo" />
              <FormattedDate value={option.validTo} />)
            </span>
          )}
        </div>
      </div>
    ),
    [classes],
  );

  const handleRenderTags = useCallback(
    (values: MachineTo[], getTagProps: AutocompleteRenderGetTagProps) => (
      <>
        {values.map((value, index) => (
          <Chip
            {...getTagProps({ index })}
            key={value.id ?? index}
            label={
              <>
                {value.name ?? value.gpsUnit}
                <span className={classes.subtext}>
                  {value.licencePlate ?? value.gpsUnit}
                </span>
              </>
            }
          />
        ))}
      </>
    ),
    [classes],
  );

  return (
    <CfAutocomplete
      defaultValues={defaultValues}
      filterOptions={handleFilterOptions}
      getSelected={handleGetSelected}
      id="machine-selector"
      isMultiple={true}
      label={label}
      onChange={onChange}
      renderOption={handleRenderOption}
      renderTags={handleRenderTags}
      suggestions={machines.data.data}
      testId="machine-filter"
    />
  );
};

export default MachineSelector;
