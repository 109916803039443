import React, { Component, Fragment } from "react";

import InfoOutlined from "@mui/icons-material/InfoOutlined";
import IconButton from "@mui/material/IconButton";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

import CfSimpleDialog from "../CfSimpleDialog/CfSimpleDialog";

const styles = () => ({
  infoButton: {
    padding: 3,
  },
  custom: {},
  infoIcon: {},
});

class CfInfoDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  onDialogClose = () => {
    this.setState({
      open: false,
    });
  };

  onDialogOpen = () => {
    this.setState({
      open: true,
    });
  };

  render() {
    const { children, classes, heading, maxWidth } = this.props;
    const { open } = this.state;

    return (
      <Fragment>
        <IconButton
          aria-label="Information"
          className={classes.infoButton}
          focusRipple={false}
          onClick={this.onDialogOpen}
          size="medium"
          classes={{
            root: classes.custom,
          }}
        >
          <InfoOutlined className={classes.infoIcon} />
        </IconButton>
        <CfSimpleDialog
          heading={heading}
          maxWidth={maxWidth}
          onDialogClose={this.onDialogClose}
          open={open}
        >
          {children}
        </CfSimpleDialog>
      </Fragment>
    );
  }
}

CfInfoDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  heading: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  maxWidth: PropTypes.string,
};

CfInfoDialog.defaultProps = {
  heading: null,
  maxWidth: "sm",
};

export default withStyles(styles)(CfInfoDialog);
