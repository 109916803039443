import ErrorService from "../../../services/Error.service";

import { PrecisionState } from "../../../../reducers/precision.reducer.types";

export const getMonitoringData = (state: PrecisionState) =>
  state.api.monitoring.items;
export const getMonitoringDataIsFetching = (state: PrecisionState) =>
  state.api.monitoring.isFetching;
export const getMonitoringDataError = (state: PrecisionState) =>
  ErrorService.getResErrorDto(state.api.monitoring.error);

export const getMonitoringCropIntervals = (state: PrecisionState) =>
  state.api.monitoring.cropIntervals;
export const getMonitoringCropIntervalsIsFetching = (state: PrecisionState) =>
  state.api.monitoring.cropIntervalsIsFetching;

export const getMonitoringCropIntervalsError = (state: PrecisionState) =>
  ErrorService.getResErrorDto(state.api.monitoring.cropIntervalsError);

export const getRecords = (state: PrecisionState) =>
  state.api.monitoring.records;
export const getRecordsIsFetching = (state: PrecisionState) =>
  state.api.monitoring.recordsIsFetching;
export const getRecordsValidity = (state: PrecisionState) =>
  state.api.monitoring.validity;
export const getRecordsError = (state: PrecisionState) =>
  ErrorService.getResErrorDto(state.api.monitoring.recordsError);
