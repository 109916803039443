import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import { getAgIntegrationStatusApi } from "../../../shared/api/sentinel/agiStatus/agiStatus.api";
import { getVarioDocIntegrationStatusApi } from "../../../shared/api/sentinel/varioDoc/varioDoc.api";
import { AsyncFn, RsaaApiError } from "../../../types";

const useIntegrationListInit = () => {
  const [fleetConnectorApiError, setFleetConnectorApiError] = useState<
    RsaaApiError | undefined
  >();
  const [varioDocApiError, setVarioDocApiError] = useState<
    RsaaApiError | undefined
  >();
  const [hideFleetConnectorRow, setHideFleetConnectorRow] =
    useState<boolean>(true);
  const [hideVarioDocRow, setHideVarioDocRow] = useState<boolean>(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch((getAgIntegrationStatusApi as unknown as AsyncFn)()).then(
      (res) => {
        const shouldBeHidden = res.error && res.payload.status === 403;
        setHideFleetConnectorRow(shouldBeHidden);

        const serverError = res.payload.status.toString().startsWith("50");
        setFleetConnectorApiError(
          serverError
            ? {
                isError: true,
                code: res.payload.status,
              }
            : undefined,
        );
      },
    );
  }, [dispatch, setFleetConnectorApiError]);

  useEffect(() => {
    dispatch((getVarioDocIntegrationStatusApi as unknown as AsyncFn)()).then(
      (res) => {
        const shouldBeHidden = res.error && res.payload.status === 403;
        setHideVarioDocRow(shouldBeHidden);

        const serverError = res.payload.status.toString().startsWith("50");
        setVarioDocApiError(
          serverError
            ? {
                isError: true,
                code: res.payload.status,
              }
            : undefined,
        );
      },
    );
  }, [dispatch, setVarioDocApiError]);

  return {
    apiError: fleetConnectorApiError ?? varioDocApiError,
    hideFleetConnectorRow,
    hideVarioDocRow,
  };
};

export { useIntegrationListInit };
