import React, { Fragment } from "react";

import GroupWorkIcon from "@mui/icons-material/GroupWork";
import { makeStyles } from "@mui/styles";

import SensorsService from "../../../shared/services/Sensors.service";
import { AnyTodo } from "../../../types";

type Props = {
  displayIcon: boolean;
  nodeLocation: AnyTodo;
};

const NodeLocationGroupsInfo = ({
  displayIcon = true,
  nodeLocation = {},
}: Props) => {
  const classes = useStyles();
  const isHistoric = SensorsService.isHistoric(nodeLocation);

  return (
    <>
      {!isHistoric && (
        <Fragment>
          {displayIcon && <GroupWorkIcon className={classes.icon} />}
          {nodeLocation.groups && nodeLocation.groups.length
            ? nodeLocation.groups.map((group: AnyTodo) => group.name).join(", ")
            : "-"}
        </Fragment>
      )}
    </>
  );
};

const useStyles = makeStyles({
  icon: {
    marginRight: 3,
  },
});

export default NodeLocationGroupsInfo;
