import { AnyAction } from "redux";
import { RSAAAction } from "redux-api-middleware";

import {
  getPrecisionAsAppliedTextFilter,
  getPrecisionAsAppliedOrder,
  getPrecisionAsAppliedPage,
  getPrecisionAsAppliedRowsPerPage,
  getPrecisionAsAppliedShowArchived,
} from "../selectors/precisionAsApplied.selectors";

import { SET_TABLE_SELECTED } from "../../../shared/actions/table.constants";
import * as types from "../../../shared/api/sentinel/precision/precision.constants";

import { getTaskdataApi } from "../../../shared/api/sentinel/asAppliedTaskdata/asAppliedTaskdata.api";
import { resetPrecisionParcelsApi } from "../../../shared/api/sentinel/precision/precision.api";

import { PrecisionState } from "../../../reducers/precision.reducer.types";

export const resetPrecision = () => (dispatch: (action: AnyAction) => void) => {
  dispatch(resetPrecisionParcelsApi());
};

export const fetchPrecisionAsApplied =
  () =>
  (dispatch: (action: RSAAAction) => void, getState: () => PrecisionState) => {
    const state = getState();

    const params = {
      "sort-dir": getPrecisionAsAppliedOrder(state),
      page: getPrecisionAsAppliedPage(state) + 1,
      "per-page": getPrecisionAsAppliedRowsPerPage(state),
      search: getPrecisionAsAppliedTextFilter(state),
      showArchived: getPrecisionAsAppliedShowArchived(state),
    };

    dispatch(getTaskdataApi(params));
  };

export const setOpenedPrecisionAsAppliedRows = (payload: number[]) => ({
  type: types.SET_OPENED_PRECISION_AS_APPLIED_ROWS,
  payload,
});

export const setPrecisionAsAppliedShowArchived = (payload: boolean) => ({
  type: types.GET_PRECISION_AS_APPLIED_SHOW_ARCHIVED,
  payload,
});

export const resetSelectedAsApplied = () => ({
  type: SET_TABLE_SELECTED,
  selected: [],
});

export const setPrecisionPageIsInit = (payload: boolean) => ({
  type: types.GET_PRECISION_PAGE_IS_INIT,
  payload,
});

export const setFocusedRow = (payload?: string) => ({
  type: types.SERVICES_SET_FOCUSED_ROW,
  payload,
});

export const setTaskdataIsVisible = (val: boolean) => ({
  type: types.SET_TASKDATA_IS_VISIBLE,
  payload: val,
});
