import React, { FC, ReactNode } from "react";

import { InputAdornment } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { Field } from "formik";
import { FormattedMessage } from "react-intl";

import CfFormikNumericTextField from "../../../shared/components/form/CfFormikNumericTextField/CfFormikNumericTextField";
import * as validators from "../../../shared/misc/validators";

interface Props {
  fullWidth?: boolean;
  isEditing: boolean;
  isWider?: boolean;
  label: string;
  name: string;
  unit?: ReactNode;
}

const NumericField: FC<Props> = ({
  fullWidth = false,
  isEditing,
  isWider = false,
  label,
  name,
  unit,
}) => {
  const classes = useStyles();

  return (
    <Field
      component={CfFormikNumericTextField}
      disabled={!isEditing}
      fullWidth={fullWidth}
      label={<FormattedMessage id={label} />}
      name={name}
      validate={validators.formikTextFieldPositiveNumberRequired}
      customClasses={{
        root: classNames({
          [classes.fieldFormik]: fullWidth,
          [classes.numericTextFieldWider]: isWider && !fullWidth,
          [classes.numericTextField]: !isWider && !fullWidth,
        }),
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <span
              style={{ color: isEditing ? "black" : "rgba(0, 0, 0, 0.26)" }}
            >
              {unit}
            </span>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default NumericField;

const useStyles = makeStyles({
  numericTextField: {
    width: 75,
    marginRight: 20,
    marginBottom: 20,
  },
  numericTextFieldWider: {
    width: 180,
    marginRight: 30,
    marginBottom: 20,
  },
  fieldFormik: {
    marginBottom: 20,
    marginRight: 20,
  },
});
