import React from "react";

import invariant from "tiny-invariant";

import { Catalogue, EconomicSystem } from "../../../generated/api/telematics";

type TContext = {
  catalogueType: Catalogue | undefined;
  economicSystem: EconomicSystem | undefined;
  economicSystemDate: string | undefined;
  farmId: string;
  langId: string;
};

const Context = React.createContext<TContext>({
  farmId: "",
  langId: "",
  economicSystemDate: "",
  economicSystem: undefined,
  catalogueType: undefined,
});

const TelematicsProvider = ({
  catalogueType,
  children,
  economicSystem,
  economicSystemDate,
  farmId,
  langId,
}: React.PropsWithChildren<TContext>) => (
  <Context.Provider
    value={{
      langId,
      farmId,
      catalogueType,
      economicSystem,
      economicSystemDate,
    }}
  >
    {children}
  </Context.Provider>
);

const useTelematicsContext = () => {
  const context = React.useContext(Context);

  invariant(
    context,
    "TelematicsContext only available within <TelematicsProvider />",
  );

  return context;
};

export { TelematicsProvider, useTelematicsContext };
