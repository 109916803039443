import React from "react";

import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import usePopover from "../../../hooks/usePopover";

export type FabOptionType = {
  id?: string;
  translId?: string;
  action: () => void;
  divider?: boolean;
  disabled?: boolean;
  hidden?: boolean;
};

type Props = {
  options: FabOptionType[];
  disabled?: boolean;
};

function FabWithOptions({ disabled, options }: Props) {
  const classes = useStyles();
  const popover = usePopover();

  return (
    <div>
      <Fab
        aria-label="parcel detail fab button"
        className={classes.button}
        color="secondary"
        disabled={disabled}
        onClick={popover.handlePopoverOpen}
        size="medium"
      >
        <AddIcon />
      </Fab>
      <Menu
        anchorEl={popover.anchorEl}
        onClose={popover.handlePopoverClose}
        open={popover.isOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {options.map((option) => (
          <MenuItem
            disabled={option.disabled}
            divider={Boolean(option.divider)}
            key={option.translId}
            onClick={() => {
              popover.handlePopoverClose();
              option.action();
            }}
          >
            <FormattedMessage id={option.translId} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: theme.palette.common.white,
    zIndex: "unset",
  },
}));

export default FabWithOptions;
