import React, { FC, useState } from "react";

import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { FormattedMessage } from "react-intl";

import CfDialog from "../../../shared/components/common/CfDialog/CfDialog";

interface Props {
  onHide: () => void;
  titleId?: string;
  contextId?: string;
}

const HideButton: FC<Props> = ({ contextId, onHide, titleId }) => {
  const [opened, setIsOpened] = useState(false);

  const onClose = () => setIsOpened(false);

  const onSubmit = () => {
    onClose();
    onHide();
  };

  const onHideBtnClick = () => setIsOpened(true);

  return (
    <Grid item>
      <Button
        color="error"
        id="delete"
        onClick={onHideBtnClick}
        variant="contained"
      >
        <FormattedMessage id="common.delete" />
      </Button>

      <CfDialog
        acceptText={<FormattedMessage id="common.yes" />}
        cancelText={<FormattedMessage id="common.no" />}
        onAccept={onSubmit}
        onCancel={onClose}
        onClose={onClose}
        opened={opened}
        title={<FormattedMessage id={titleId} />}
      >
        <div>
          <FormattedMessage id={contextId} />
        </div>
      </CfDialog>
    </Grid>
  );
};

export default HideButton;
