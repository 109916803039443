import React, { useEffect } from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getApiError } from "../../../shared/api/telematics/machines/machines.selectors";
import { getTelematicsMachinesTextFilter } from "../../selectors/telematicsMachines.selectors";

import { NAMESPACE } from "../../reducer/telematicsMachines.reducer";

import CfTextFilter from "../../../common/components/CfTextFilter/CfTextFilter";
import { useCfTextFilter } from "../../../common/components/CfTextFilter/useCfTextFilter";
import { resetApiError } from "../../../shared/api/telematics/machines/machines.api";
import CfErrorPage from "../../../shared/components/common/CfErrorPage/CfErrorPage";
import { Thunk } from "../../../types";
import TelematicsMachinesTable from "../../components/TelematicsMachineTable/TelematicsMachineTable";
import { TelematicsNgProps } from "../Telematics/Telematics";

import { TelematicsState } from "../../../reducers/telematics.reducer.types";

type ReduxProps = ConnectedProps<typeof connector>;

type Props = ReduxProps & TelematicsNgProps;

const TelematicsMachines = ({
  apiError,
  ngRedirectToMainMapWithFilters,
  resetApiError,
  textFilter,
}: Props) => {
  const { onChange } = useCfTextFilter(NAMESPACE);

  useEffect(
    () => () => {
      resetApiError();
    },
    [resetApiError],
  );
  const classes = useStyles();

  return (
    <CfErrorPage error={apiError}>
      <div className={classes.wrapper}>
        <div className={classes.filters}>
          <div className={classes.textFilter}>
            <CfTextFilter
              initialValue={textFilter}
              onChange={onChange}
              translId="TelematicsMachines.textFilterPlaceholder"
            />
          </div>
        </div>
        <TelematicsMachinesTable
          ngRedirectToMainMapWithFilters={ngRedirectToMainMapWithFilters}
        />
      </div>
    </CfErrorPage>
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  textFilter: getTelematicsMachinesTextFilter(state),
  apiError: getApiError(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      resetApiError,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: theme.spacing(2),
  },
  filters: {
    display: "flex",
    height: 40,
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      display: "grid",
      gridTemplateColumns: "auto auto auto",
      gridTemplateRows: "40px",
      gridTemplateAreas: `
        'text-filter text-filter text-filter'
        'date . toggle'
      `,
      height: "auto",
    },
  },
  date: {
    gridArea: "date",
    justifySelf: "flex-start",
    alignSelf: "end",
  },
  textFilter: {
    gridArea: "text-filter",
    justifySelf: "center",
    maxWidth: 400,
    width: "100%",
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(1),
      maxWidth: "unset",
      marginLeft: 0,
    },
  },
  toggle: {
    [theme.breakpoints.up("md")]: {
      marginLeft: "auto",
    },
    gridArea: "toggle",
    justifySelf: "flex-end",
  },
  switch: {
    marginLeft: "auto",
  },
  switchLabel: {
    fontSize: 14,
  },
}));

export default connector(TelematicsMachines);
