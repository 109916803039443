import React from "react";

import { makeStyles } from "@mui/styles";
import classNames from "classnames";

const useStyles = makeStyles(() => ({
  heading: {
    fontSize: 13,
    display: "inline",
    fontWeight: 500,
    margin: 0,
  },
}));

type Props = {
  classes?: Record<string, string>;
};

const CfSectionHeading = ({
  children,
  classes,
}: React.PropsWithChildren<Props>) => {
  const defClasses = useStyles();
  return (
    <h3 className={classNames(defClasses.heading, classes?.heading)}>
      {children}
    </h3>
  );
};

export default CfSectionHeading;
