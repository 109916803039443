import React from "react";

import LocationOffIcon from "@mui/icons-material/LocationOff";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import { Geometry } from "../../../api/irrigation/devices/devices.types";

interface Props {
  locationOn: boolean;
  geometry: Geometry;
}

export const LocationCoordinatesInfo = ({
  geometry,
  locationOn = true,
}: Props) => {
  const classes = useStyles();

  const coordinates = geometry?.coordinates ? (
    `${parseFloat(geometry.coordinates[1].toFixed(9))}, 
    ${parseFloat(geometry.coordinates[0].toFixed(9))}`
  ) : (
    <FormattedMessage id="LocationCoordinatesInfo.locationUnavailable" />
  );

  return (
    <Tooltip
      placement="bottom"
      title={<FormattedMessage id="common.location" />}
      classes={{
        tooltip: classes.tooltip,
      }}
    >
      <span className={classes.itemContent}>
        {locationOn ? <LocationOnIcon /> : <LocationOffIcon />}
        <span
          className={classes.spanMessageMargin}
          data-test="location-coordinates"
        >
          {locationOn ? (
            coordinates
          ) : (
            <FormattedMessage id="LocationCoordinatesInfo.noLocation" />
          )}
        </span>
      </span>
    </Tooltip>
  );
};

const useStyles = makeStyles(() => ({
  tooltip: {
    marginBottom: 4,
  },
  itemContent: {
    display: "flex",
    alignItems: "center",
  },
  spanMessageMargin: {
    marginLeft: "3px",
  },
}));
