import React, { FC } from "react";

import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from "@mui/icons-material/EditOff";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import { Checkbox, IconButton, TextField, Tooltip } from "@mui/material";
import classNames from "classnames";
import { Field } from "formik";
import { FormattedMessage, useIntl } from "react-intl";

import CfBackButton from "../../../../../shared/components/common/CfBackButton/CfBackButton";
import PageHeader from "../../../../../shared/components/common/PageHeader/PageHeader";
import PageHeading from "../../../../../shared/components/common/PageHeading/PageHeading";
import * as validators from "../../../../../shared/misc/validators";
import { useFertilizerDetailStyles } from "../FertilizerDetail.styles";

interface Props {
  backButtonLabel: string;
  canUpdate?: boolean;
  defaultIsFavorite: boolean;
  defaultName: string;
  error: boolean;
  handleFavoriteClick: () => void;
  handleGoBack: () => void;
  handleStartEdit: () => void;
  isEditing: boolean;
  itemNamePlaceholder: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FertilizerDetailHeader: FC<Props> = ({
  backButtonLabel,
  canUpdate = false,
  defaultIsFavorite,
  defaultName,
  error = false,
  handleFavoriteClick,
  handleGoBack,
  handleStartEdit,
  isEditing,
  itemNamePlaceholder,
  onChange,
}) => {
  const classes = useFertilizerDetailStyles();
  const intl = useIntl();

  return (
    <PageHeader
      actionButtons={
        <Tooltip
          followCursor
          title={
            canUpdate ? (
              ""
            ) : (
              <span>
                <FormattedMessage
                  id="Catalogues.fertilizers.detail.editOff.tooltip"
                  values={{
                    br: <br />,
                    b: (chunks: string) => <b>{chunks}</b>,
                  }}
                />
              </span>
            )
          }
        >
          {/* https://github.com/mui/material-ui/issues/8416 */}
          <div>
            <IconButton
              data-test="fertilizer-edit"
              disabled={!canUpdate}
              onClick={handleStartEdit}
              size="large"
            >
              {canUpdate ? <EditIcon /> : <EditOffIcon />}
            </IconButton>
          </div>
        </Tooltip>
      }
      backButton={
        <CfBackButton onClick={handleGoBack} translId={backButtonLabel} />
      }
      heading={
        <PageHeading
          customClass={classes.heading}
          value={
            <>
              <Tooltip
                placement="bottom"
                title={
                  <FormattedMessage
                    id={
                      defaultIsFavorite
                        ? "Catalogues.detail.shared.removeFromFavorites.tooltip"
                        : "Catalogues.detail.shared.addToFavorites.tooltip"
                    }
                  />
                }
              >
                <Checkbox
                  checked={defaultIsFavorite}
                  checkedIcon={<StarRoundedIcon />}
                  color="secondary"
                  data-test="favourite"
                  icon={<StarBorderRoundedIcon />}
                  onClick={handleFavoriteClick}
                />
              </Tooltip>
              {isEditing ? (
                <Field
                  component={TextField}
                  data-test="fertilizer-name"
                  disabled={!isEditing}
                  error={error}
                  name="name"
                  onChange={onChange}
                  placeholder={intl.formatMessage({ id: itemNamePlaceholder })}
                  validate={validators.required}
                  value={defaultName}
                  helperText={
                    error ? <FormattedMessage id="validation.required" /> : ""
                  }
                  InputProps={{
                    classes: {
                      root: classNames(classes.textField, {
                        [classes.headingContentConfirming]: !isEditing,
                        [classes.headingValidationError]: error,
                      }),
                      input: classes.headingFontSize,
                    },
                  }}
                />
              ) : (
                <span
                  data-test="fertilizer-name"
                  className={classNames(
                    classes.headingFontSize,
                    classes.headingContentConfirming,
                  )}
                >
                  {defaultName}
                </span>
              )}
            </>
          }
        />
      }
    />
  );
};

export default FertilizerDetailHeader;
