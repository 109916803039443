import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

type Props = {
  htmlColor: string;
};

const LayersIcon = ({ htmlColor }: Props) => (
  <SvgIcon>
    <svg
      viewBox="0 0 17.3 17.8"
      x="0px"
      xmlns="http://www.w3.org/2000/svg"
      y="0px"
    >
      <path
        d="M8.3,14L3.1,9.9l-1.2,1l6.4,5c0.3,0.2,0.6,0.2,0.9,0l6.4-5l-1.2-1L9,14C8.8,14.1,8.5,14.1,8.3,14z M9.1,11.9L15.5,7L9.1,2.1c-0.3-0.2-0.7-0.2-0.9,0L1.8,7l6.3,4.9C8.5,12.1,8.8,12.1,9.1,11.9z"
        fill={`${htmlColor}`}
      />
    </svg>
  </SvgIcon>
);

export default LayersIcon;
