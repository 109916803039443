import React from "react";

import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { getDeviceTypes } from "../../../../sensors/selectors/nodes.selectors";

import { AnyTodo } from "../../../../types";
import { SensorsIcon } from "../../../icons/navbar/SensorsIcon";
import DeviceTypesService from "../../../services/DeviceTypes.service";

type Props = {
  displayIcon?: boolean;
  node?: AnyTodo;
};

export const NodeDeviceType = ({ displayIcon = true, node = {} }: Props) => {
  const classes = useStyles();
  const deviceTypes = useSelector(getDeviceTypes);

  const deviceGroup = DeviceTypesService.getDeviceGroupByDeviceType(
    node.deviceType,
    deviceTypes,
  );

  return (
    <span className={classes.itemContent}>
      {displayIcon && <SensorsIcon className={classes.icon} />}
      <span>
        {deviceGroup ? (
          <FormattedMessage id={`DeviceGroup.${deviceGroup}`} />
        ) : (
          "-"
        )}
      </span>
    </span>
  );
};

const useStyles = makeStyles({
  itemContent: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    height: 20,
    marginRight: "3px",
  },
});

export default NodeDeviceType;
