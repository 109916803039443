import React from "react";

import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  setMonitoringIndex,
  setMonitoringInterval,
} from "../actions/monitoring.actions";

import CfLoader from "../../../shared/components/common/CfLoader/CfLoader";
import CfStatusPanel from "../../../shared/components/common/CfStatusPanel/CfStatusPanel";
import SatelliteIcon from "../../../shared/icons/SatelliteIcon";
import { Thunk } from "../../../types";
import BioMonitoringImagesOverallError from "../components/BioMonitoring/BioMonitoringImagesOverallError";
import { IndiceType } from "../selectors/indices";

import { ChartSection } from "./biomonitoring/ChartSection/ChartSection";
import { TilesContainer } from "./biomonitoring/TilesContainer";
import { IndexTypeDataType } from "./biomonitoring/types";
import { useChartDesignData } from "./biomonitoring/useChartDesignData";

import { PrecisionState } from "../../../reducers/precision.reducer.types";
import {
  Crop,
  Zone,
} from "../../../shared/api/sentinel/managementZones/managementZones.types";
import {
  CropIntervals,
  imgExtent,
} from "../../../shared/api/sentinel/monitoring/monitoring.types";

export type MapImage = {
  extent: number[];
  url: string;
};

export type Geometries = {
  color: string;
  geometry: {
    coordinates: number[][][][];
    type: string;
  };
  zoneNumber: number;
};

export interface MonitoringCropIntervals extends CropIntervals {
  id: string;
}

export interface ChartDataOrMonitoringData {
  crop?: Crop;
  dateFrom: string;
  dateTo: string;
  imgExtent: imgExtent;
  imgPath: string;
  indexType: IndiceType;
  status?: string;
  value: number;
  tooltipValue?: number;
  zones: Zone[];
  average: number;
}

interface OwnProps {
  parcelId: string;
  hookData: IndexTypeDataType;
  index: IndiceType;
  lastUpdate?: string;
}

type ReduxProps = ConnectedProps<typeof connector>;

type Props = ReduxProps & OwnProps;

const BioMonitoring = ({ hookData, index, lastUpdate, parcelId }: Props) => {
  const { chartDesignData } = useChartDesignData(index);

  const {
    data,
    graphData,
    infoDialogContent,
    isFetching,
    lastImageDateTo,
    overallImagesError,
  } = hookData;

  const classes = useStyles(isFetching);

  return (
    <>
      {isFetching ? (
        <CfLoader />
      ) : (
        <>
          {overallImagesError && (
            <BioMonitoringImagesOverallError
              lastImageDateTo={lastImageDateTo}
              overallImagesError={overallImagesError}
            />
          )}
          {!data.length && (
            <CfStatusPanel
              fullWidth={true}
              grey={true}
              icon={SatelliteIcon}
              title={<FormattedMessage id="BioMonitoring.imagesProcessed" />}
            />
          )}
          {!overallImagesError && !!data.length && (
            <Grid className={classes.wrapper} container spacing={1}>
              <ChartSection
                chartDesignData={chartDesignData}
                graphData={graphData}
                index={index}
                isFetchingData={isFetching}
              />
              <TilesContainer
                index={index}
                infoDialogContent={infoDialogContent}
                lastUpdate={lastUpdate}
                monitoringData={data}
                parcelId={parcelId}
              />
            </Grid>
          )}
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch: Thunk<PrecisionState>) =>
  bindActionCreators(
    {
      setMonitoringInterval,
      setMonitoringIndex,
    },
    dispatch,
  );

const useStyles = makeStyles(() => ({
  wrapper: {
    marginBottom: 10,
    height: (isFetching) => (isFetching ? "90%" : "auto"),
  },
}));

const connector = connect(null, mapDispatchToProps);

export default connector(BioMonitoring);
