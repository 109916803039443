import React, { FC, ReactNode, useCallback } from "react";

import { Chip } from "@mui/material";
import { AutocompleteRenderGetTagProps } from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material/useAutocomplete";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { selectShortDateRange } from "../../../selectors/telematicsTabs.selectors";

import { DriverTo } from "../../../../generated/api/telematics";
import { useFarmIds } from "../../../../shared/api/client.utils";
import CfAutocomplete from "../../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import useAdvancedFilterStyles from "../TelematicsAdvancedFilter.styles";

import { driversQuery } from "./DriverSelector.api";

interface DriverSelectorProps {
  defaultValues?: DriverTo[];
  externalClasses?: Record<string, string>;
  label: ReactNode;
  onChange(items: DriverTo[]): void;
}

const defaultDefaultValues: DriverTo[] = [];

const handleGetSelected = (option: DriverTo, value?: DriverTo | null) =>
  option.code === value?.code;

const handleFilterOptions = createFilterOptions({
  stringify: ({ code, name }) => `${name} ${code}`,
});

const DriverSelector: FC<DriverSelectorProps> = ({
  defaultValues = defaultDefaultValues,
  externalClasses,
  label,
  onChange,
}) => {
  const { dateFrom, dateTo } = useSelector(selectShortDateRange);
  const classes = useAdvancedFilterStyles();
  const intl = useIntl();
  const drivers = useSuspenseQuery(
    driversQuery({ dateFrom, dateTo, ...useFarmIds() }),
  );
  const suggestions = [
    {
      code: "nodriver",
      name: intl.formatMessage({ id: "TelematicsList.filter.withoutDriver" }),
    },
    ...drivers.data.data,
  ];

  const handleRenderOption = useCallback(
    (option: DriverTo) => (
      <div>
        <div>{option.name ?? option.code}</div>
        <div className={classes.subtext}>
          {option.code === "nodriver" ? "-" : option.code}
        </div>
      </div>
    ),
    [classes],
  );

  const handleRenderTags = useCallback(
    (values: DriverTo[], getTagProps: AutocompleteRenderGetTagProps) => (
      <>
        {values.map((value, index) => (
          <Chip
            {...getTagProps({ index })}
            key={value.id ?? value.code}
            label={
              <>
                {value.name ?? value.code}{" "}
                <span className={classes.subtext}>
                  {value.code === "nodriver" ? "-" : value.code}
                </span>
              </>
            }
          />
        ))}
      </>
    ),
    [classes],
  );

  return (
    <CfAutocomplete
      classes={externalClasses}
      defaultValues={defaultValues}
      filterOptions={handleFilterOptions}
      getSelected={handleGetSelected}
      id="driver-selector"
      isMultiple={true}
      label={label}
      onChange={onChange}
      renderOption={handleRenderOption}
      renderTags={handleRenderTags}
      suggestions={suggestions}
      testId="driver-filter"
    />
  );
};

export default DriverSelector;
