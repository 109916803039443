import { useEffect, useState } from "react";

import { Breakpoint, useMediaQuery, useTheme } from "@mui/material";

const DEFAULT_VALUE = undefined;

// https://mui.com/components/use-media-query/#migrating-from-withwidth
const useWidth = (): Breakpoint | undefined => {
  const theme = useTheme();
  if (!theme) {
    return DEFAULT_VALUE;
  }

  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || DEFAULT_VALUE
  );
};

const useIsMobile = () => {
  const width = useWidth();
  const mobileBreakPoints: Breakpoint[] = ["xs", "sm"];

  return width ? mobileBreakPoints.includes(width) : DEFAULT_VALUE;
};

const useCurrentWidth = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return width;
};

export default useWidth;
export { useIsMobile, useCurrentWidth };
