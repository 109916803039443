import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useParcelOverviewStyles = makeStyles((theme: Theme) => ({
  heading: {
    marginBottom: 10,
    fontSize: 18,
  },
  paper: {
    padding: "10px 15px",
    fontSize: "13px",
  },
  precisionPaper: {
    padding: 15,
    fontSize: "13px",
  },
  gridItem: {
    display: "flex",
    alignItems: "center",
    minHeight: 32,
  },
  zonesPaper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  zonesItems: {
    padding: 10,
    fontSize: "13px",
  },
  panelItem: {
    marginBottom: 20,
  },
  overviewPanelItem: {
    marginBottom: 10,
  },
  errorNotificationContainer: {
    display: "flex",
    alignItems: "center",
  },
  errorNotification: {
    color: theme.palette.error.main,
  },
}));

export { useParcelOverviewStyles };
