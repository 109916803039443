import React, { FC } from "react";

import { Checkbox } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Field } from "formik";
import { FormattedMessage } from "react-intl";

interface Props {
  defaultValues: boolean;
  isEditing: boolean;
  label: string;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, name: string) => void;
}

const CheckboxField: FC<Props> = ({
  defaultValues,
  isEditing,
  label,
  name,
  onChange,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Field
        checked={defaultValues}
        component={Checkbox}
        disabled={!isEditing}
        name={name}
        style={{ paddingLeft: 0 }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          onChange(event, name)
        }
      />
      <span style={isEditing ? {} : { color: "rgba(0, 0, 0, 0.26)" }}>
        <FormattedMessage id={label} />
      </span>
    </div>
  );
};

export default CheckboxField;

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
  },
});
