const PREFIX = "AGROEVIDENCE_PARCELS";

export const GET_PARCELS_REQUEST = `${PREFIX}/GET_PARCELS_REQUEST`;
export const GET_PARCELS_SUCCESS = `${PREFIX}/GET_PARCELS_SUCCESS`;
export const GET_PARCELS_ERROR = `${PREFIX}/GET_PARCELS_ERROR`;
export const RESET_PARCELS = `${PREFIX}/RESET_PARCELS`;

export const GET_PARCELS_SUGGESTIONS_REQUEST = `${PREFIX}/GET_PARCELS_SUGGESTIONS_REQUEST`;
export const GET_PARCELS_SUGGESTIONS_SUCCESS = `${PREFIX}/GET_PARCELS_SUGGESTIONS_SUCCESS`;
export const GET_PARCELS_SUGGESTIONS_ERROR = `${PREFIX}/GET_PARCELS_SUGGESTIONS_ERROR`;
export const RESET_PARCELS_SUGGESTIONS = `${PREFIX}/RESET_PARCELS_SUGGESTIONS`;

export const ADD_PARCELS = "ADD_PARCELS";

export const GET_PARCELS_STATISTICS_REQUEST = `${PREFIX}/GET_PARCELS_STATISTICS_REQUEST`;
export const GET_PARCELS_STATISTICS_SUCCESS = `${PREFIX}/GET_PARCELS_STATISTICS_SUCCESS`;
export const GET_PARCELS_STATISTICS_ERROR = `${PREFIX}/GET_PARCELS_STATISTICS_ERROR`;
export const RESET_PARCELS_STATISTICS = `${PREFIX}/RESET_PARCELS_STATISTICS`;

export const GET_PARCELS_LAND_USE_REQUEST = `${PREFIX}/GET_PARCELS_LAND_USE_REQUEST`;
export const GET_PARCELS_LAND_USE_SUCCESS = `${PREFIX}/GET_PARCELS_LAND_USE_SUCCESS`;
export const GET_PARCELS_LAND_USE_ERROR = `${PREFIX}/GET_PARCELS_LAND_USE_ERROR`;
export const RESET_PARCELS_LAND_USE = `${PREFIX}/RESET_PARCELS_LAND_USE`;

export const GET_CENTERS_REQUEST = `${PREFIX}/GET_CENTERS_REQUEST`;
export const GET_CENTERS_SUCCESS = `${PREFIX}/GET_CENTERS_SUCCESS`;
export const GET_CENTERS_ERROR = `${PREFIX}/GET_CENTERS_ERROR`;
export const RESET_CENTERS = `${PREFIX}/RESET_CENTERS`;

export const GET_PARCEL_REQUEST = `${PREFIX}/GET_PARCEL_REQUEST`;
export const GET_PARCEL_SUCCESS = `${PREFIX}/GET_PARCEL_SUCCESS`;
export const GET_PARCEL_ERROR = `${PREFIX}/GET_PARCEL_ERROR`;
export const RESET_PARCEL = `${PREFIX}/RESET_PARCEL`;

export const PATCH_PARCEL_REQUEST = `${PREFIX}/PATCH_PARCEL_REQUEST`;
export const PATCH_PARCEL_SUCCESS = `${PREFIX}/PATCH_PARCEL_SUCCESS`;
export const PATCH_PARCEL_ERROR = `${PREFIX}/PATCH_PARCEL_ERROR`;

export const GET_PARCEL_SEED_APPLICATION_REQUEST = `${PREFIX}/GET_PARCEL_SEED_APPLICATION_REQUEST`;
export const GET_PARCEL_SEED_APPLICATION_SUCCESS = `${PREFIX}/GET_PARCEL_SEED_APPLICATION_SUCCESS`;
export const GET_PARCEL_SEED_APPLICATION_ERROR = `${PREFIX}/GET_PARCEL_SEED_APPLICATION_ERROR`;

export const GET_PARCEL_PREDECESSORS_REQUEST = `${PREFIX}/GET_PARCEL_PREDECESSORS_REQUEST`;
export const GET_PARCEL_PREDECESSORS_SUCCESS = `${PREFIX}/GET_PARCEL_PREDECESSORS_SUCCESS`;
export const GET_PARCEL_PREDECESSORS_ERROR = `${PREFIX}/GET_PARCEL_PREDECESSORS_ERROR`;

export const GET_PARCEL_EAGRI_RESTRICTIONS_REQUEST = `${PREFIX}/GET_PARCEL_EAGRI_RESTRICTIONS_REQUEST`;
export const GET_PARCEL_EAGRI_RESTRICTIONS_SUCCESS = `${PREFIX}/GET_PARCEL_EAGRI_RESTRICTIONS_SUCCESS`;
export const GET_PARCEL_EAGRI_RESTRICTIONS_ERROR = `${PREFIX}/GET_PARCEL_EAGRI_RESTRICTIONS_ERROR`;

export const POST_PARCEL_SUBTRACTIONS_GEOMETRY_REQUEST = `${PREFIX}/POST_PARCEL_SUBTRACTIONS_GEOMETRY_REQUEST`;
export const POST_PARCEL_SUBTRACTIONS_GEOMETRY_SUCCESS = `${PREFIX}/POST_PARCEL_SUBTRACTIONS_GEOMETRY_SUCCESS`;
export const POST_PARCEL_SUBTRACTIONS_GEOMETRY_ERROR = `${PREFIX}/POST_PARCEL_SUBTRACTIONS_GEOMETRY_ERROR`;
export const RESET_PARCEL_SUBTRACTIONS_GEOMETRY = `${PREFIX}/RESET_PARCEL_SUBTRACTIONS_GEOMETRY`;

export const POST_PARCEL_SUBTRACTIONS_AREA_REQUEST = `${PREFIX}/POST_PARCEL_SUBTRACTIONS_AREA_REQUEST`;
export const POST_PARCEL_SUBTRACTIONS_AREA_SUCCESS = `${PREFIX}/POST_PARCEL_SUBTRACTIONS_AREA_SUCCESS`;
export const POST_PARCEL_SUBTRACTIONS_AREA_ERROR = `${PREFIX}/POST_PARCEL_SUBTRACTIONS_AREA_ERROR`;
export const RESET_PARCEL_SUBTRACTIONS_AREA = `${PREFIX}/RESET_PARCEL_SUBTRACTIONS_AREA`;

export const EXPORT_PARCELS_REQUEST = `${PREFIX}/EXPORT_PARCELS_REQUEST`;
export const EXPORT_PARCELS_SUCCESS = `${PREFIX}/EXPORT_PARCELS_SUCCESS`;
export const EXPORT_PARCELS_ERROR = `${PREFIX}/EXPORT_PARCELS_ERROR`;

export const SET_FOCUSED_ROW = `${PREFIX}/SET_FOCUSED_ROW`;
