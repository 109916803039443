/* eslint-disable no-nested-ternary */
import React, { useMemo, useEffect, FC, useState } from "react";

import { Grid } from "@mui/material";
import { useSuspenseQuery } from "@tanstack/react-query";
import classnames from "classnames";
import { Field, Form, Formik, FormikProps } from "formik";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RSAAResultAction } from "redux-api-middleware";

import { selectDateRange } from "../../selectors/telematicsTabs.selectors";

import { calculateArea } from "../../actions/telematicsAggregations.actions";

import InfoBlueFaded from "../../../assets/img/icons/info_blue-faded.svg";
import { useFarmIds } from "../../../shared/api/client.utils";
import { getAggregatedOverlapsApi } from "../../../shared/api/telematics/aggregations/aggregations.api";
import CfFormikTimePicker from "../../../shared/components/form/CfFormikTimePicker/CfFormikTimePicker";
import TimeIntervalsBar from "../../../shared/components/misc/TimeIntervalsBar/TimeIntervalsBar";
import {
  getLocalizedDateString,
  getShortDateString,
} from "../../../shared/misc/timeHelpers";
import { AsyncFn, Thunk } from "../../../types";
import { useTelematicsContext } from "../../containers/Telematics/Context";
import { operationsQuery } from "../../containers/TelematicsAdvancedFilter/OperationSelector/OperationSelector.api";
import { productionOperationsQuery } from "../../containers/TelematicsAdvancedFilter/ProductionOperationSelector/ProductionOperationSelector.api";
import {
  alignTimesWithDate,
  getDuration,
  getNotOverlapedTime,
  sumTimeIntervals,
} from "../../helpers/index";
import { DriveKeysFormValues } from "../TelematicsAggregationDetailContent/DriveKeysForm";
import {
  ApprovalWarning,
  BonusField,
  Buttons,
  DateField,
  DriveKey,
  DriverField,
  OperationField,
  ProductionOperationField,
} from "../TelematicsAggregationDetailContent/formComponents";
import WinfasClient from "../TelematicsAggregationDetailContent/formComponents/WinfasClient";
import { validateHandworkForm } from "../TelematicsAggregationDetailContent/validators";
import TelematicsForeignWarning, {
  TelematicsForeignWarningType,
} from "../TelematicsForeignWarning/TelematicsForeignWarning";

import { useNewHandworkFormStyles } from "./style";

import {
  ConnectedProps,
  TelematicsHandworkFormValues,
  FORM_TYPES,
} from "./TelematicsHandworkForm.types";
import { TelematicsState } from "../../../reducers/telematics.reducer.types";
import {
  Type,
  Affiliation,
  TelematicsOperation,
  KeyType,
  HandworkCreateTo,
  DriveOverlapRequestTo,
  DriveDetailOverlapTo,
  DriverTo,
  Catalogue,
} from "../../../shared/api/telematics/telematics.types";

export type CreateHandworkType = TelematicsHandworkFormValues &
  DriveKeysFormValues;

const TelematicsHandworkForm: FC<ConnectedProps> = ({
  approvalValidationErrors,
  dateFilter,
  driveDetail,
  driveKeys,
  formPath,
  getAggregatedOverlapsApi,
  handleReset,
  handleSave,
}) => {
  const operations = useSuspenseQuery(operationsQuery());
  const productionOperations = useSuspenseQuery(
    productionOperationsQuery({
      handwork: false,
      ...useFarmIds(),
    }),
  );
  const { catalogueType } = useTelematicsContext();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [overlaps, setOverlaps] = useState<DriveDetailOverlapTo[]>([]);
  const [selectedDriver, setSelectedDriver] = useState<
    DriverTo | undefined | null
  >(undefined);
  const [sumTimeFormatted, setSumTimeFormatted] = useState<string>("");

  // hooks
  const classes = useNewHandworkFormStyles();

  const initialEmptyValues: Partial<CreateHandworkType> = {
    date: dateFilter?.dateFrom ? moment(dateFilter?.dateFrom) : undefined,
    operation: TelematicsOperation.HANDWORK,
    productionOperation: productionOperations.data.data.find(
      (op) => op.code === "0030-VO",
    ),
    driverCode: "",
    bonus:
      productionOperations.data.data.find((op) => op.code === "0030-VO")
        ?.bonus ?? 0,
    type: Type.HANDWORK,
    timeFrom: dateFilter?.dateFrom
      ? moment(dateFilter?.dateFrom).startOf("day")
      : undefined,
    timeTo: dateFilter?.dateFrom
      ? moment(dateFilter?.dateFrom).startOf("day")
      : undefined,
    formType: FORM_TYPES.CREATE,
    duration: undefined,
    supplierKey: undefined,
    customerKey: undefined,
    client: undefined,
  };

  const isNew = !driveDetail;

  const initialValues: Partial<CreateHandworkType> = useMemo(() => {
    if (isNew) return initialEmptyValues;

    let dateFrom;
    let dateTo;
    let date;
    if (formPath === "drivers") {
      date = driveDetail.dateFrom ? moment(driveDetail.dateFrom) : undefined;
      dateFrom = dateFilter?.dateFrom
        ? moment(dateFilter?.dateFrom).startOf("day")
        : undefined;
      dateTo = dateFilter?.dateFrom
        ? moment(dateFilter?.dateFrom).startOf("day")
        : undefined;
    } else if (formPath === "logbook") {
      date = driveDetail.timeFrom ? moment(driveDetail.timeFrom) : undefined;
      dateFrom = driveDetail.timeFrom
        ? moment(driveDetail.timeFrom)
        : undefined;
      dateTo = driveDetail.timeTo ? moment(driveDetail.timeTo) : undefined;
    }

    return {
      date,
      operation: driveDetail?.operation,
      productionOperation: driveDetail?.productionOperation,
      driverCode: driveDetail.driver?.code ?? "",
      bonus: driveDetail.driver?.bonus ?? 0,
      type: driveDetail.type,
      timeFrom: dateFrom,
      timeTo: dateTo,
      formType: FORM_TYPES.EDIT,
      duration: driveDetail.duration,
      supplierKey: driveKeys?.keys?.supplierKey,
      customerKey: driveKeys?.keys?.customerKey,
      client: driveKeys?.client,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driveDetail, driveKeys, initialEmptyValues]);

  const showKeys = formPath !== "logbook" && catalogueType === Catalogue.WINFAS;

  const handleSubmit = (values: CreateHandworkType) => {
    let data: HandworkCreateTo = {
      driver: values?.driverCode || undefined,
      productionOperation: values?.productionOperation?.code || undefined,
      bonus: values.bonus,
      timeFrom: getShortDateString(values.timeFrom, "HH:mm"),
      timeTo: getShortDateString(values.timeTo, "HH:mm"),
      winfas: showKeys
        ? {
            keys: {
              supplierKeyId: values.supplierKey?.id,
              customerKeyId: values.customerKey?.id,
            },
            client: values.client ?? undefined,
          }
        : undefined,
    };

    if (values.type === Type.HANDWORK) {
      const [timeFromAligned, timeToAligned] = alignTimesWithDate(
        values.date,
        values.timeFrom,
        values.timeTo,
      );
      data = {
        ...data,
        timeFrom: timeFromAligned.toISOString(),
        timeTo: timeToAligned.toISOString(),
      };
    }
    if (handleSave) {
      return handleSave(data);
    }
  };

  const handworkInterval = (
    timeFrom?: moment.Moment | string,
    timeTo?: moment.Moment | string,
  ) => {
    const from = moment(timeFrom).toISOString();
    const to = moment(timeTo).toISOString();
    return `${from}/${to}`;
  };

  return (
    <>
      <Formik<Partial<CreateHandworkType>>
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={hasSubmitted}
        validateOnChange={hasSubmitted}
        validateOnMount={false}
        validate={(values) =>
          validateHandworkForm(values, sumTimeFormatted, showKeys)
        }
      >
        {(formikProps: FormikProps<CreateHandworkType>) => {
          const {
            errors,
            isSubmitting,
            setFieldValue,
            touched,
            validateForm,
            values,
          } = formikProps;

          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            const touchedFieldsCount = Object.keys(touched).length;
            const fieldsCount = Object.keys(values).length;
            const fieldErrorsCount = Object.keys(errors).length;

            // formik sets all fields as touched on submit
            const submitted = touchedFieldsCount === fieldsCount;
            const submittedWithErrors = submitted && fieldErrorsCount > 0;

            if (submittedWithErrors) {
              // revalidate form until user fixes all errors
              validateForm();
            }
          }, [values, touched, validateForm, errors]);

          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (isNew) {
              if (values.date) {
                setFieldValue("timeFrom", values.date.startOf("day"));
                setFieldValue("timeTo", values.date.startOf("day"));
              }
              if (values.date && values.driverCode) {
                (getAggregatedOverlapsApi as AsyncFn<DriveOverlapRequestTo>)({
                  date: getShortDateString(values.date),
                  driver: values.driverCode,
                }).then((res: RSAAResultAction<DriveDetailOverlapTo[]>) => {
                  if (!res.error) {
                    setFieldValue(
                      "timeIntervals",
                      res?.payload.map(
                        ({ dateFrom, dateTo }) => `${dateFrom}/${dateTo}`,
                      ),
                    );
                    setOverlaps(res?.payload);
                  }
                });
              }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [values.date, values.driverCode]);

          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (hasSubmitted) {
              validateForm();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [validateForm, sumTimeFormatted]);

          // classifiers need always some date (even if user haven't selected it yet)
          // also use as valid-to date for parcels selector
          const dateForClassifiers =
            values.date?.toISOString() ?? moment().startOf("day").toISOString();

          const hasExternalDriver =
            driveDetail?.driver?.affiliation === Affiliation.EXTERNAL;

          const notOverlapedTime = getNotOverlapedTime(overlaps, {
            dateFrom: moment(values.timeFrom).toISOString(),
            dateTo: moment(values.timeTo).toISOString(),
          });

          const { totalHours, totalMinutes } =
            sumTimeIntervals(notOverlapedTime);
          setSumTimeFormatted(
            `${totalHours
              .toString()
              .padStart(2, "0")}:${totalMinutes.toString().padStart(2, "0")}`,
          );

          const getTimeIntervals = () => {
            if (isNew) {
              return values.timeIntervals as string[];
            }
            return [];
          };

          const getHandworkIntervals = (): string[] => {
            if (isNew) {
              return notOverlapedTime.map(({ dateFrom, dateTo }) =>
                handworkInterval(dateFrom, dateTo),
              );
            } else if (formPath === "logbook") {
              return [handworkInterval(values.timeFrom, values.timeTo)];
            } else if (formPath === "drivers") {
              return (
                driveDetail?.drivePart?.map(({ dateFrom, dateTo }) =>
                  handworkInterval(dateFrom, dateTo),
                ) ?? []
              );
            }
            return values.timeIntervals as string[];
          };

          return (
            <Form className={classes.container}>
              <Grid container justifyContent="space-between">
                <Grid item xs={6}>
                  <OperationField
                    disabled={true}
                    operations={operations.data.data}
                  />
                </Grid>
                <Grid item xs={4}>
                  <DateField disabled={!isNew} {...formikProps} />
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.fieldWrapper}>
                    <ApprovalWarning
                      show={!!approvalValidationErrors.productionOperation}
                      tooltipCode={approvalValidationErrors.productionOperation}
                    />
                    <ProductionOperationField
                      date={dateForClassifiers}
                      disabled={!isNew}
                      handwork
                      handleProductionOperationChange={(value) => {
                        setFieldValue("productionOperation", value);

                        const operation = productionOperations.data.data.find(
                          (o) => o.id === value?.id,
                        );

                        setFieldValue(
                          "bonus",
                          value?.bonus ?? operation?.bonus ?? 0,
                        );
                      }}
                      {...formikProps}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.fieldWrapper}>
                    <ApprovalWarning
                      show={!!approvalValidationErrors.driverCode}
                      tooltipCode={approvalValidationErrors.driverCode}
                    />
                    <DriverField
                      date={dateForClassifiers}
                      disabled={!isNew}
                      placeholderId="Telematics.handwork.worker"
                      setSelectedDriver={setSelectedDriver}
                      {...formikProps}
                    />
                  </div>
                  {hasExternalDriver && (
                    <div className={classes.externalDriver}>
                      <TelematicsForeignWarning
                        text={driveDetail?.driver?.companyName}
                        type={TelematicsForeignWarningType.Driver}
                      />
                    </div>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <BonusField
                    disabled={!isNew}
                    label={<FormattedMessage id="Telematics.handwork.bonus" />}
                    {...formikProps}
                  />
                </Grid>
              </Grid>
              <Grid
                className={classnames(classes.intervals, classes.timeContainer)}
                container
                justifyContent="center"
              >
                {isNew && (
                  <Grid container gap={4}>
                    <Grid item>
                      <Field
                        component={CfFormikTimePicker}
                        name="timeFrom"
                        label={
                          <FormattedMessage id="TelematicsAggregations.detail.timeFrom" />
                        }
                      />
                    </Grid>
                    <Grid item>
                      <Field
                        component={CfFormikTimePicker}
                        name="timeTo"
                        label={
                          <FormattedMessage id="TelematicsAggregations.detail.timeTo" />
                        }
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid container>
                  <Grid
                    className={classes.intervalWarningIcon}
                    item
                    xs={approvalValidationErrors.duration ? 1 : 0}
                  >
                    <ApprovalWarning
                      show={!!approvalValidationErrors.duration}
                      tooltipCode={approvalValidationErrors.duration}
                    />
                  </Grid>
                  <Grid item xs={approvalValidationErrors.duration ? 11 : 12}>
                    <div className={classes.intervalsHeader}>
                      {isNew && (
                        <>
                          <span className={classes.intervalsHeaderLabelNew}>
                            {`${
                              values.date
                                ? getLocalizedDateString(values.date)
                                : ""
                            } ${selectedDriver?.name ?? ""}`}
                          </span>
                          <span
                            data-test="duration"
                            className={classnames(
                              sumTimeFormatted !== "00:00"
                                ? classes.activeIntervalsTime
                                : classes.intervalsTime,
                            )}
                          >
                            {sumTimeFormatted}{" "}
                            <FormattedMessage id="TelematicsAggregations.detail.intervals.hours" />
                          </span>
                        </>
                      )}
                      {!isNew && (
                        <>
                          <span className={classes.intervalsHeaderLabel}>
                            <FormattedMessage id="TelematicsAggregations.detail.intervals" />
                            :
                          </span>
                          <span
                            className={classes.intervalsTime}
                            data-test="duration"
                          >
                            {formPath === "logbook"
                              ? getDuration(
                                  values.timeTo.diff(
                                    values.timeFrom,
                                    "milliseconds",
                                  ) / 1000,
                                )
                              : getDuration(values.duration)}{" "}
                            <FormattedMessage id="TelematicsAggregations.detail.intervals.hours" />
                          </span>
                        </>
                      )}
                    </div>
                    <TimeIntervalsBar
                      handworkIntervals={getHandworkIntervals()}
                      intervals={getTimeIntervals()}
                      isEditing={isNew}
                      withTimeAxis
                      datetimeEnd={moment(values.date)
                        .endOf("day")
                        .toISOString()}
                      datetimeStart={moment(values.date)
                        .startOf("day")
                        .toISOString()}
                    />
                    {isNew && (
                      <div className={classes.timelineNotification}>
                        <img alt="started" src={InfoBlueFaded} />
                        <span className={classes.timelineNotificationText}>
                          <FormattedMessage id="Telematics.handwork.timeline.notification" />
                        </span>
                      </div>
                    )}
                  </Grid>
                </Grid>
                {showKeys && (
                  <Grid container justifyContent="space-between">
                    <Grid item xs={12}>
                      <h3 className={classes.sectionHeading}>
                        <FormattedMessage id="TelematicsAggregations.detail.section.keys" />
                      </h3>
                    </Grid>
                    <Grid item xs={12}>
                      <DriveKey
                        disabled={!isNew}
                        fieldName="supplierKey"
                        keyType={KeyType.SUPPLIER}
                        tooltipCode={approvalValidationErrors.supplierKey}
                        helperText={
                          !!errors.supplierKey && (
                            <FormattedMessage id={errors.supplierKey} />
                          )
                        }
                        showApprovalWarning={
                          !!approvalValidationErrors.supplierKey
                        }
                        // variant="disabled"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <DriveKey
                        disabled={!isNew}
                        fieldName="customerKey"
                        keyType={KeyType.CUSTOMER}
                        tooltipCode={approvalValidationErrors.customerKey}
                        helperText={
                          !!errors.customerKey && (
                            <FormattedMessage id={errors.customerKey} />
                          )
                        }
                        showApprovalWarning={
                          !!approvalValidationErrors.customerKey
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <WinfasClient
                        date={dateForClassifiers}
                        disabled={!isNew}
                        fieldName="client"
                        showApprovalWarning={!!approvalValidationErrors.client}
                        tooltipCode={approvalValidationErrors.client}
                        helperText={
                          !!errors.client && (
                            <FormattedMessage id={errors.client} />
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                )}
                {isNew && (
                  <Buttons
                    isSubmitting={isSubmitting}
                    onCancelClick={() => handleReset()}
                    onSubmitClick={() => setHasSubmitted(true)}
                  />
                )}
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  dateFilter: selectDateRange(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      calculateArea,
      getAggregatedOverlapsApi,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TelematicsHandworkForm);
