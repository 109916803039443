import React from "react";

import ClearIcon from "@mui/icons-material/Clear";
import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import { COLOR_SECONDARY } from "../../../../theme";

interface Props {
  children?: React.ReactNode;
  header?: React.ReactNode;
  onRemoveItem: () => void;
  isEditing: boolean;
  variant?: "warning" | "error";
}

export const ListItem = ({
  children,
  header,
  isEditing,
  onRemoveItem,
  variant,
}: Props) => {
  const classes = useStyles({ variant });

  return (
    <Paper>
      <div className={classes.paper} data-test="item-root">
        {header && <div className={classes.content}>{header}</div>}
        <Tooltip
          disableInteractive
          title={
            <span>
              <FormattedMessage id="common.delete" />
            </span>
          }
        >
          <span>
            <IconButton
              aria-label="Remove item"
              className={classes.removeButton}
              disabled={!isEditing}
              onClick={onRemoveItem}
              size="large"
            >
              <ClearIcon />
            </IconButton>
          </span>
        </Tooltip>
      </div>
      {!!children && <div className={classes.body}>{children}</div>}
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    display: "flex",
    alignItems: "center",
    margin: "10px 0px",
    backgroundColor: (variant: { variant?: string }) =>
      variant?.variant === "warning"
        ? COLOR_SECONDARY.cleverfarm.light
        : theme.palette.grey[100],
    padding: "4px 12px",
  },
  content: {
    flexGrow: 100,
    padding: 6,
  },
  body: {
    padding: "4px 12px",
  },
  removeButton: {
    height: 36,
    width: 36,
    padding: 0,
    marginLeft: 6,
  },
}));
