import React, { ReactNode } from "react";

import { Theme } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";

interface Props {
  children: ReactNode;
  customClasses?: Record<string, string>;
  minWidth?: number;
  testId?: string;
}

const CfTableWrapper = ({
  children,
  customClasses,
  minWidth = -1,
  testId = "table",
}: Props) => {
  const classes = useStyles({ minWidth });

  return (
    <Paper className={classnames(classes.paper, customClasses?.paper)}>
      <Table
        className={classnames(classes.table, customClasses?.table)}
        data-test={testId}
      >
        {children}
      </Table>
    </Paper>
  );
};

export default CfTableWrapper;

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    minWidth: ({ minWidth }: { minWidth: number }) =>
      minWidth >= 0 ? minWidth : `${theme.breakpoints.values.sm}px`,
    position: "relative",
    borderCollapse: "initial",
  },
  paper: {
    overflowX: "auto",
  },
}));
