import React from "react";

import { Autocomplete, TextField, Theme } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { makeStyles } from "@mui/styles";
import { FieldProps, Field } from "formik";
import { FormattedMessage } from "react-intl";

import CfFormControl from "../../../../../shared/components/form/CfFormControl/CfFormControl";
import * as validators from "../../../../../shared/misc/validators";

import { CropSeedSuggestionTo } from "../../../../../shared/api/agroevidence/agroevidence.types";

interface Props {
  isEditing: boolean;
  isFetchingSeeds: boolean;
  onChange: (event: React.ChangeEvent, newValue: CropSeedSuggestionTo) => void;
  seeds: CropSeedSuggestionTo[];
}

export const SeedSelector = ({
  isEditing,
  isFetchingSeeds,
  onChange,
  seeds,
}: Props) => {
  const classes = useStyles();

  if (isFetchingSeeds) {
    return <LinearProgress color="secondary" />;
  }

  const handleRenderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: CropSeedSuggestionTo,
  ) => {
    const optionValue = [option.name, option.varietyName]
      .filter((item) => item)
      .join(" - ");

    return (
      <li {...props} key={option.id}>
        {option.isFavorite ? `⭐ ${optionValue}` : optionValue}
      </li>
    );
  };

  return (
    <CfFormControl>
      <Field name="seedId" validate={validators.required} validateOnBlur>
        {({ field, meta }: FieldProps<string>) => (
          <>
            <Autocomplete
              disableClearable
              disabled={!isEditing || isFetchingSeeds}
              onChange={onChange}
              options={seeds.filter((seed) => !seed.isHidden)}
              value={seeds.find((seed) => seed.id === field.value)}
              getOptionLabel={(seed) =>
                [seed.name, seed.varietyName].filter((item) => item).join(" - ")
              }
              renderInput={(params) => (
                <TextField
                  name="seedId"
                  {...params}
                  label={<FormattedMessage id="action.sowingSeed" />}
                />
              )}
              renderOption={(props, option) =>
                handleRenderOption(props, option)
              }
            />
            {meta.error && (
              <div className={classes.error}>
                <FormattedMessage id="validation.required" />
              </div>
            )}
          </>
        )}
      </Field>
    </CfFormControl>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    color: theme.palette.error.main,
    fontSize: "12px",
    marginTop: "3px",
  },
}));
