import CloudsIcon from "../../../shared/icons/satelliteErrors/CloudsIcon";
import ImageNotSupportedIcon from "../../../shared/icons/satelliteErrors/ImageNotSupportedIcon";
import LAIOnlyIcon from "../../../shared/icons/satelliteErrors/LAIOnlyIcon";
import NoCropIcon from "../../../shared/icons/satelliteErrors/NoCropIcon";
import NoZonesIcon from "../../../shared/icons/satelliteErrors/NoZonesIcon";
import SatelliteIcon from "../../../shared/icons/SatelliteIcon";
import { ChartDataOrMonitoringData } from "../containers/BioMonitoring";
import { IndiceType } from "../selectors/indices";
import { MONITORING_STATUS } from "../selectors/monitoringStatus";

import { IndexType } from "../../../shared/api/satellite/satellite.types";

export interface OverallImagesError {
  excludedIndices?: IndiceType[];
  icon: (props: unknown) => React.JSX.Element;
  id: string;
  onlyZonesError?: boolean;
  overallTranslation?: boolean;
}

const errors: OverallImagesError[] = [
  {
    id: MONITORING_STATUS.NO_CLOUDFREE_IMAGES,
    icon: CloudsIcon,
  },
  {
    id: MONITORING_STATUS.NO_ZONES,
    icon: SatelliteIcon,
    onlyZonesError: true,
  },
  {
    id: MONITORING_STATUS.LAI_ONLY,
    icon: LAIOnlyIcon,
    excludedIndices: [IndexType.LAI],
    overallTranslation: true,
  },
  {
    id: MONITORING_STATUS.NO_ZONES_2020,
    icon: NoZonesIcon,
    onlyZonesError: true,
  },
  {
    id: MONITORING_STATUS.CROP_HARVESTED,
    icon: NoCropIcon,
  },
];

const defaultError = {
  id: MONITORING_STATUS.NOT_AVAILABLE,
  icon: ImageNotSupportedIcon,
  overallTranslation: true,
};

export default class BioMonitoringImageErrorService {
  static getPossibleErrors(indexType: IndiceType, isZonesImage: boolean) {
    const possibleErrors = errors.filter(
      (error) =>
        // zones error is not possible for source images
        !(error.onlyZonesError && !isZonesImage) &&
        // some errors may be allowed only for specific indices
        !(
          indexType &&
          error.excludedIndices &&
          error.excludedIndices.includes(indexType)
        ),
    );
    return possibleErrors;
  }

  static checkSnapshotDefaultError(
    snapshot: ChartDataOrMonitoringData,
    isZonesImage = false,
  ) {
    let isImageAvailable;
    if (isZonesImage) {
      isImageAvailable = snapshot.zones?.length;
    } else {
      isImageAvailable = snapshot.imgPath && snapshot.imgExtent;
    }
    return isImageAvailable ? null : defaultError;
  }

  static getSnapshotError(
    snapshot: ChartDataOrMonitoringData,
    isZonesImage: boolean,
  ) {
    const imageStatus = snapshot.status;
    const possibleErrors = this.getPossibleErrors(
      snapshot.indexType,
      isZonesImage,
    );
    const snapshotError = possibleErrors.find(
      (error) => imageStatus === error.id,
    );

    if (!snapshotError) {
      return this.checkSnapshotDefaultError(snapshot, isZonesImage);
    }
    return snapshotError;
  }

  static getOverallImagesError(
    items: ChartDataOrMonitoringData[],
    indexType: IndiceType,
  ) {
    if (!items?.length) {
      return null;
    }
    let firstItemError = null;

    // errors only for zones image can't be considered as overall errors
    const possibleErrors = this.getPossibleErrors(indexType, false);

    const isOverallError = items.every((item, index) => {
      const imageStatus = item.status;
      let itemError: OverallImagesError | null | undefined;

      itemError = possibleErrors.find((error) => imageStatus === error.id);

      if (!itemError) {
        itemError = this.checkSnapshotDefaultError(item);
      }
      if (index === 0) {
        firstItemError = itemError;
      }
      return Boolean(itemError);
    });

    // return error of first item if all items have some kind of error status
    if (isOverallError) {
      return firstItemError;
    }
    return null;
  }

  static checkCropHarvestedError(items: ChartDataOrMonitoringData[]) {
    if (!items?.length) {
      return null;
    }

    const imageStatus = items[0].status;
    const cropHarvestedError = errors.find(
      (error) => error.id === MONITORING_STATUS.CROP_HARVESTED,
    );
    return imageStatus === cropHarvestedError?.id ? cropHarvestedError : null;
  }
}
