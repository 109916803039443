import moment from "moment";
import { injectIntl } from "react-intl";

import CfDatePickerTranslator from "../../form/CfDatePickerTranslator/CfDatePickerTranslator";
import { createComponent } from "../../reduxFormUtils";

const CfDatePicker = createComponent(
  CfDatePickerTranslator,
  ({
    format,
    input: { onBlur, onChange, onFocus, value, ...inputProps } = {},
    intl: { formatMessage },
    maxDateMessage,
    minDateMessage,
    propagateInvalidDate = false,
    ...props
  }) => ({
    value,
    format: format || "L",
    momentLocale: moment().locale(),
    placeholder: moment().startOf("year").format("L"),
    okLabel: formatMessage({
      id: "common.select",
    }),
    cancelLabel: formatMessage({
      id: "common.cancel",
    }),
    clearLabel: formatMessage({
      id: "common.clear",
    }),
    invalidDateMessage: formatMessage({
      id: "CfDatePicker.invalidDateMessage",
    }),
    minDateMessage,
    maxDateMessage,
    helperText: props?.meta?.error,
    error: !!props?.meta?.error,
    ...inputProps,
    ...props,
    onChange: (inputVal) => {
      const isUserInputValid = moment.isMoment(inputVal) && inputVal.isValid();

      if (propagateInvalidDate || isUserInputValid) {
        onChange(inputVal);
      }
    },
  }),
);

export default injectIntl(CfDatePicker);
