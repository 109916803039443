import React, { FC, useEffect } from "react";

import { Theme } from "@mui/material";
import { createFilterOptions } from "@mui/material/useAutocomplete";
import { makeStyles } from "@mui/styles";
import { useFormikContext } from "formik";
import { ReactNodeLike } from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getWinfasClients } from "../../../../shared/api/telematics/aggregations/aggregations.selectors";

import { getWinfasClientCombinationsForDriveApi } from "../../../../shared/api/telematics/aggregations/aggregations.api";
import CfAutocomplete from "../../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import CfFormControl from "../../../../shared/components/form/CfFormControl/CfFormControl";
import { getShortDateString } from "../../../../shared/misc/timeHelpers";
import { Thunk } from "../../../../types";
import { DriveKeysFormValues } from "../DriveKeysForm";

import ApprovalWarning from "./ApprovalWarning";

import { TelematicsState } from "../../../../reducers/telematics.reducer.types";
import { WinfasClientParams } from "../../../../shared/api/telematics/aggregations/aggregations.types";
import {
  DriveValidationType,
  WinfasClientTo,
} from "../../../../shared/api/telematics/telematics.types";

export const EMPTY_NAME = "-";
interface Props {
  customClasses?: Record<string, string>;
  date: string;
  disabled?: boolean;
  fetchSuggestions: (params: WinfasClientParams) => void;
  fieldName: keyof DriveKeysFormValues;
  helperText?: NonNullable<ReactNodeLike> | null;
  placeholder?: string;
  showApprovalWarning?: boolean;
  suggestions: WinfasClientTo[];
  tooltipCode?: DriveValidationType;
  hasEmptyOption?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
  },
  secondaryText: {
    color: theme.palette.grey[500],
  },
}));

const WinfasClient: FC<Props> = ({
  customClasses,
  date,
  disabled = false,
  fetchSuggestions,
  fieldName,
  hasEmptyOption = false,
  helperText,
  placeholder,
  showApprovalWarning = false,
  suggestions,
  tooltipCode,
}) => {
  const { errors, setFieldValue, values } =
    useFormikContext<DriveKeysFormValues>();
  const classes = useStyles();

  useEffect(() => {
    if (disabled) return;
    const shortDate = getShortDateString(date);
    fetchSuggestions({ dateFrom: shortDate, dateTo: shortDate });
  }, [fetchSuggestions, disabled, date]);

  const handleFilterOptions = createFilterOptions({
    stringify: ({ idNumber, name }) => `${name} (${idNumber})`,
  });

  const emptyOption = { name: EMPTY_NAME, idNumber: "" };
  const extendedSuggestions = hasEmptyOption
    ? [emptyOption, ...(suggestions || [])]
    : suggestions || [];

  return (
    <div className={classes.container}>
      <ApprovalWarning show={showApprovalWarning} tooltipCode={tooltipCode} />
      <CfFormControl>
        <CfAutocomplete
          classes={customClasses}
          defaultValues={values[fieldName]}
          disabled={disabled}
          error={!!errors[fieldName]}
          filterOptions={handleFilterOptions}
          helperText={helperText}
          isMultiple={false}
          multilineInput
          onChange={(value) => setFieldValue(fieldName, value)}
          placeholder={placeholder}
          suggestions={extendedSuggestions}
          getLabel={(option: WinfasClientTo) =>
            option.name !== EMPTY_NAME && option.name
              ? `${option.name} ${option.idNumber ? `(${option.idNumber})` : EMPTY_NAME}`
              : EMPTY_NAME
          }
          label={
            placeholder ? undefined : (
              <FormattedMessage id="TelematicsAggregations.detail.keys.client" />
            )
          }
          renderOption={(option: WinfasClientTo) => (
            <div>
              <div>{option.name ?? EMPTY_NAME}</div>
              <div className={classes.secondaryText}>
                {option.idNumber ?? EMPTY_NAME}
              </div>
            </div>
          )}
        />
      </CfFormControl>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      fetchSuggestions: getWinfasClientCombinationsForDriveApi,
    },
    dispatch,
  );

const mapStateToProps = (state: TelematicsState) => ({
  suggestions: getWinfasClients(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(WinfasClient);
