import React, { ReactNode } from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";

interface Props {
  children: ReactNode;
  label?: ReactNode;
  customClasses?: Partial<Record<"column" | "label" | "content", string>>;
}

const SelectionItemColumn = ({ children, customClasses, label }: Props) => {
  const classes = useStyles();

  return (
    <div
      className={classnames(classes.column, customClasses?.column)}
      data-test="item-column"
    >
      {label && (
        <div
          className={classnames(classes.label, customClasses?.label)}
          data-test="item-column-label"
        >
          {label}
        </div>
      )}
      {children && (
        <div
          className={classnames(classes.content, customClasses?.content)}
          data-test="item-column-content"
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default SelectionItemColumn;

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    fontSize: 12,
    color: theme.palette.grey[500],
  },
  column: {
    margin: 8,
  },
  content: {
    fontSize: 16,
  },
}));
