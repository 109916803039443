import React from "react";

import { Theme } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import { useDispatch, useSelector } from "react-redux";

import {
  getMainMapTelematicsDashboardMachinesOrderBy,
  getMainMapTelematicsDashboardSelectedMachine,
} from "../../../../shared/api/telematics/mainMapTelematics/dashboard/machinesDashboard.selectors";

import { setDashboardMachineSelected } from "../../../../shared/api/telematics/mainMapTelematics/dashboard/machinesDashboard.actions";

import carIcon from "../../../../assets/img/icons/telematics/dashboard/car.svg";
import combineIcon from "../../../../assets/img/icons/telematics/dashboard/combine.svg";
import loaderIcon from "../../../../assets/img/icons/telematics/dashboard/loader.svg";
import tankIcon from "../../../../assets/img/icons/telematics/dashboard/other.svg";
import tractorIcon from "../../../../assets/img/icons/telematics/dashboard/tractor.svg";
import truckIcon from "../../../../assets/img/icons/telematics/dashboard/truck.svg";

import { MachineStatus } from "./MachineStatus";

import {
  MachineGroupCode,
  PositionDashboard,
  PositionSort,
  PositionState,
} from "../../../../shared/api/telematics/telematics.types";

type Props = {
  row: PositionDashboard;
  i: number;
  machines: PositionDashboard[];
};

const MachineTableRow = ({ i, machines, row }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selected = useSelector(getMainMapTelematicsDashboardSelectedMachine);
  const orderBy = useSelector(getMainMapTelematicsDashboardMachinesOrderBy);

  const getIcon = (row: PositionDashboard) => {
    switch (row.machineGroup) {
      case MachineGroupCode.COMBINE:
        return <img alt="combine" src={combineIcon} />;
      case MachineGroupCode.LOADER:
        return <img alt="loader" src={loaderIcon} />;
      case MachineGroupCode.TRACTOR:
        return <img alt="tractor" src={tractorIcon} />;
      case MachineGroupCode.TRUCK:
        return <img alt="truck" src={truckIcon} />;
      case MachineGroupCode.PASSENGER:
        return <img alt="car" src={carIcon} />;
      case MachineGroupCode.OTHER:
        return <img alt="tank" src={tankIcon} />;
      default:
        return null;
    }
  };

  const renderDivider = (row: PositionDashboard, i: number) => {
    if (orderBy === PositionSort.STATE) {
      return i < machines.length - 1 && row.state !== machines[i + 1].state;
    }
    return false;
  };

  const isSelected = (row: PositionDashboard) =>
    selected && row.machineName === selected.machineName;

  const isDisabled = row.state === PositionState.MISSING;

  return (
    <TableRow
      key={i}
      className={classNames(
        classes.row,
        renderDivider(row, i) ? classes.divider : undefined,
        isSelected(row) ? classes.selectedRow : undefined,
        isDisabled ? classes.disabled : undefined,
      )}
      onClick={
        isDisabled
          ? undefined
          : () => dispatch(setDashboardMachineSelected(row))
      }
    >
      <TableCell className={classes.smallCell}>
        <div className={classes.statusWrapper}>
          <MachineStatus state={row.state} />
        </div>
      </TableCell>
      <TableCell>
        <div className={classes.machineWrapper}>
          <span className={classes.machineIconWrapper}>{getIcon(row)}</span>
          <div>
            <div className={classes.topText}>
              {isEmpty(row.machineName) ? "-" : row.machineName}
            </div>
            <div className={classes.middleText}>
              {isEmpty(row.equipmentName) ? "-" : row.equipmentName}
            </div>
            <div className={classes.bottomText}>
              {isEmpty(row.driverName) ? "-" : row.driverName}
            </div>
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
      cursor: "pointer",
    },
  },
  disabled: {
    "&:hover": {
      backgroundColor: "transparent",
      cursor: "default",
    },
  },
  selectedRow: {
    backgroundColor: "#EBF8F4",
    "&:hover": {
      backgroundColor: "#EBF8F4",
    },
  },
  statusWrapper: {
    display: "grid",
    placeItems: "center",
    paddingLeft: 10,
  },
  topText: {
    fontWeight: 500,
    fontSize: 13,
  },
  middleText: {
    fontWeight: 400,
    fontSize: 13,
    color: theme.palette.grey[500],
  },
  bottomText: {
    fontWeight: 400,
    fontSize: 13,
  },
  smallCell: {
    padding: "4px 0",
    height: 67, // The height of the whole row
  },
  machineWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  machineIconWrapper: {
    minWidth: 30,
    maxWidth: 30,
    width: 30,
  },
  divider: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
}));

export { MachineTableRow };
