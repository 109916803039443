import ErrorService from "../../../services/Error.service";

import { ActionsState } from "../../../../reducers/actions.reducer.types";

export const getActions = (state: ActionsState) =>
  state.api.agroevidence.actions.items;
export const getIsFetching = (state: ActionsState) =>
  state.api.agroevidence.actions.isFetching;
export const getError = (state: ActionsState) =>
  ErrorService.getResErrorDto(state.api.agroevidence.actions.error);

export const isDeletingActions = (state: ActionsState) =>
  state.api.agroevidence.actions.isDeleting;

export const getTotalCount = (state: ActionsState) =>
  state.api.agroevidence.actions.totalCount;

export const getAction = (state: ActionsState) =>
  state.api.agroevidence.actions.item;

export const getOtherActionTypes = (state: ActionsState) =>
  state.api.agroevidence.actions.otherActionTypes;
export const getIsFetchingOtherActionTypes = (state: ActionsState) =>
  state.api.agroevidence.actions.isFetchingOtherActionTypes;

export const getActionRestrictions = (state: ActionsState) =>
  state.api.agroevidence.actions.itemRestrictions;
export const getIsFetchingActionRestrictions = (state: ActionsState) =>
  state.api.agroevidence.actions.isFetchingItemRestrictions;

export const getIsFetchingActionSplit = (state: ActionsState) =>
  state.api.agroevidence.actions.isFetchingActionSplit;

export const getEphTargetSeedApplication = (state: ActionsState) =>
  state.api.agroevidence.actions.ephTargetSeedApplication;
export const getIsFetchingEphTargetSeedApplication = (state: ActionsState) =>
  state.api.agroevidence.actions.isFetchingEphTargetSeedApplication;

export const getEphAccountableNitrogen = (state: ActionsState) =>
  state.api.agroevidence.actions.ephAccountableNitrogen;
export const getIsFetchingEphAccountableNitrogen = (state: ActionsState) =>
  state.api.agroevidence.actions.isFetchingEphAccountableNitrogen;
