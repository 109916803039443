import React, { ReactNode, useEffect } from "react";

import { uniqBy } from "lodash";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { getOrganisms } from "../../../../../shared/api/agroevidence/catalogues/plantProtection/plantProtection.selectors";

import { fetchOrganisms } from "../../../../../catalogues/actions/catalogues.actions";

import { resetOrganismsApi } from "../../../../../shared/api/agroevidence/catalogues/plantProtection/plantProtection.api";
import CfAutocomplete from "../../../../../shared/components/common/CfAutocomplete/CfAutocomplete";

import {
  OrganismTo,
  PlantProtectionApplicationTo,
} from "../../../../../shared/api/agroevidence/agroevidence.types";

type Props = {
  applications: PlantProtectionApplicationTo[];
  defaultValues?: OrganismTo | OrganismTo[];
  disabled?: boolean;
  error?: boolean;
  isMultiple?: boolean;
  label: ReactNode;
  onChange: (items: OrganismTo | OrganismTo[]) => void;
};

export const PestSelector = ({
  applications,
  defaultValues,
  disabled = false,
  error = false,
  isMultiple = false,
  label,
  onChange,
}: Props) => {
  const dispatch = useDispatch();
  const allUsedOrganisms = useSelector(getOrganisms);

  useEffect(() => {
    dispatch(fetchOrganisms("", true, false));

    return () => {
      dispatch(resetOrganismsApi());
    };
  }, [dispatch]);

  const applicationsOrganisms = applications.map((app) => app.organism);

  const suggestions = applicationsOrganisms.length
    ? uniqBy(applicationsOrganisms, "id")
    : allUsedOrganisms;

  suggestions.sort((a, b) => {
    if (!a || !b) return 0;
    return a.name.localeCompare(b.name);
  });

  return (
    <CfAutocomplete
      defaultValues={defaultValues}
      disabled={disabled}
      error={error}
      helperText={error ? <FormattedMessage id="validation.required" /> : ""}
      id="organisms-selector"
      isMultiple={isMultiple}
      label={label}
      onChange={onChange}
      suggestions={suggestions}
      testId="organisms-selector"
    />
  );
};
