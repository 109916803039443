import { useEffect, useRef } from "react";

import { AnyTodo } from "../../types";

export const useDidUpdate = (callback: () => void, deps: AnyTodo[]) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) callback();
    else didMountRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
