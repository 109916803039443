import { AnyAction } from "redux";

import * as types from "../asAppliedTaskdata/asAppliedTaskdata.constants";

import { PrecisionAsApplied } from "./precision.types";

export const NAMESPACE = "precisionAsAppliedList";

const initialState: PrecisionAsApplied = {
  fileImportError: undefined,
  showVarioDocInactive: false,
  focusedRow: undefined,
};

export default () =>
  (state = initialState, action: AnyAction) => {
    switch (action.type) {
      case types.AS_APPLIED_IMPORT_FILES_REQUEST:
        return {
          ...state,
          fileImportError: undefined,
        };
      case types.AS_APPLIED_IMPORT_FILES_ERROR:
        return {
          ...state,
          fileImportError: true,
        };
      case types.AS_APPLIED_SET_FILE_ERROR:
        return {
          ...state,
          fileImportError: action.payload,
        };
      case types.AS_APPLIED_SHOW_VARIODOC_INACTIVE:
        return {
          ...state,
          showVarioDocInactive: action.payload,
        };
      case types.AS_APPLIED_SET_FOCUSED_ROW:
        return {
          ...state,
          focusedRow: action.payload,
        };
      default:
        return state;
    }
  };
