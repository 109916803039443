import React, { Fragment, useEffect, useState } from "react";

import { Grid } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import {
  Field,
  FieldArray,
  FormikErrors,
  useField,
  useFormikContext,
} from "formik";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { getPlantProtectionApplication } from "../../../../../shared/api/agroevidence/catalogues/plantProtection/plantProtection.selectors";

import { getPlantProtectionApplicationApi } from "../../../../../shared/api/agroevidence/catalogues/plantProtection/plantProtection.api";
import CfAutocomplete from "../../../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import { ListItem } from "../../../../../shared/components/common/SelectionItem/ListItem";
import SelectionItemColumn from "../../../../../shared/components/common/SelectionItemColumn/SelectionItemColumn";
import CfFormControl from "../../../../../shared/components/form/CfFormControl/CfFormControl";
import CfFormikTextField from "../../../../../shared/components/form/CfFormikTextField/CfFormikTextField";
import { useTypedIntl } from "../../../../../shared/hooks/useTypedIntl";
import Localization from "../../../../../shared/services/Localization.service";
import UnitService from "../../../../../shared/services/Unit.service";
import { AnyTodo } from "../../../../../types";
import {
  checkIsAllowedForCrop,
  driftClassValue,
  isCropInApplications,
  mapActivePest,
} from "../../actionEph.services";
import { PestControl } from "../PestControl/PestControl";

import { PlantProtectionEffectivenessControl } from "./PlantProtectionEffectivenessControl";

import {
  ActiveSubstanceTo,
  PlantProtectionRegistrationApplicationBufferTo,
} from "../../../../../shared/api/agroevidence/agroevidence.types";
import {
  ActionEphFormValues,
  EphPlantProtectionsType,
  MappedActivePest,
  RestrictionsPestBuffer,
} from "../../actionEph.types";

const driftClasses = [
  {
    code: "DRIFT_NONE",
    name: "bez redukce",
  },
  {
    code: "DRIFT_50",
    name: "50%",
  },
  {
    code: "DRIFT_75",
    name: "75%",
  },
  {
    code: "DRIFT_90",
    name: "90%",
  },
];

interface Props {
  actionTotalArea: number;
  isEditing: boolean;
  plantProtection: EphPlantProtectionsType;
  index: number;
  isExisting: boolean;
  onItemRemove: (index: number, id: string) => void;
  errors: FormikErrors<Partial<ActionEphFormValues>>;
}

export const PlantProtectionListItem = ({
  actionTotalArea,
  errors,
  index,
  isEditing,
  isExisting,
  onItemRemove,
  plantProtection,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isOverMaxValue, setIsOverMaxValue] = useState(false);
  const [recommendedDoseUnit, setRecommendedDoseUnit] = useState<
    string | undefined
  >("");

  const { locale } = useTypedIntl();

  const { setFieldValue } = useFormikContext<ActionEphFormValues>();
  const [currentPlantProtection] = useField(`plantProtections[${index}]`);
  const [currentPests] = useField(`plantProtections[${index}].pests`);
  const [currentDriftClass] = useField(`plantProtections[${index}].driftClass`);
  const [parcels] = useField(`parcels`);
  const [targetCropField] = useField("targetCrop");
  const selectedTargetCropId = targetCropField?.value?.id;
  const [dosePerHa] = useField(`plantProtections[${index}].dosePerHectare`);
  const [doseUnit] = useField(`plantProtections[${index}].doseUnit`);

  const plantApplications = useSelector(getPlantProtectionApplication);
  const currentPlantProtectionApplication = plantApplications.find(
    (application) =>
      application.plantProtectionId === currentPlantProtection.value.id,
  );

  const isPlantProtectionForCurrentCrop = isCropInApplications({
    applications: currentPlantProtectionApplication,
    cropId: selectedTargetCropId,
  });

  const registeredApplications =
    currentPlantProtectionApplication?.applications.filter(
      (application) => application.crop?.id === selectedTargetCropId,
    );

  useEffect(() => {
    setFieldValue(
      `plantProtections[${index}].driftClass`,
      driftClasses.find(
        (driftClass) =>
          driftClass.code === currentPlantProtection.value?.driftClass,
      ) ?? driftClasses[0],
    );
    setFieldValue(
      `plantProtections[${index}].pests`,
      currentPlantProtection.value?.pests ?? [],
    );
    setFieldValue(
      `plantProtections[${index}].dosePerHectare`,
      currentPlantProtection.value?.dosePerHectare ?? "0",
    );
    setFieldValue(
      `plantProtections[${index}].doseUnit`,
      unitsPerHectare.find((unit) => unit.id === doseUnit?.value) ??
        unitsPerHectare[0],
    );
    setFieldValue(
      `plantProtections[${index}].dosage`,
      currentPlantProtection.value?.dosage ?? "0",
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getPlantProtectionApplicationApi(plantProtection?.id));
  }, [dispatch, plantProtection?.id]);

  useEffect(() => {
    const allowedPests = currentPests.value?.filter(
      (pest: MappedActivePest) => pest?.isAllowedForCrop,
    );

    if (allowedPests && allowedPests?.length > 0) {
      setRecommendedDoseUnit(allowedPests[0].recommendedDoseUnit);

      // pre-set doseUnit according to the recommendedDoseUnit of the first allowed pest
      if (allowedPests[0].recommendedDoseUnit) {
        setFieldValue(
          `plantProtections[${index}].doseUnit`,
          unitsPerHectare.find(
            (unit) => unit.name === allowedPests[0].recommendedDoseUnit,
          ),
        );
      }
    }

    if (allowedPests?.length === 0) {
      setRecommendedDoseUnit(undefined);
    }

    const maxValuePest = allowedPests?.reduce(
      (acc: MappedActivePest, curr: MappedActivePest) =>
        (curr?.maxDose ?? 0) > (acc?.maxDose ?? 0) ? curr : acc,
      allowedPests[0],
    );

    setIsOverMaxValue(
      Localization.parseNumberFromLocalizedString(String(dosePerHa?.value)) >
        Localization.parseNumberFromLocalizedString(
          String(maxValuePest?.maxDose),
        ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dosePerHa?.value, currentPests?.value?.length]);

  useEffect(() => {
    if (currentPests?.value) {
      const filteredBuffersByDriftClass = currentPests.value
        .flatMap((item: MappedActivePest) =>
          item.buffer?.map(
            (buffer: PlantProtectionRegistrationApplicationBufferTo) => buffer,
          ),
        )
        .filter(
          (buffer: PlantProtectionRegistrationApplicationBufferTo) =>
            buffer?.driftReduction ===
            driftClassValue[
              currentDriftClass?.value?.code as keyof typeof driftClassValue
            ],
        )
        .map((buffer: PlantProtectionRegistrationApplicationBufferTo) => ({
          type: buffer?.type,
          value: buffer?.buffer,
          isMaterial: true,
          name: currentPlantProtection?.value?.name,
        }));

      parcels.value.forEach((parcel: AnyTodo, index: number) => {
        const originalWaterRestrictions =
          parcel?.subtractableAreas?.water?.filter(
            (buffer: RestrictionsPestBuffer) =>
              !buffer?.isMaterial ||
              buffer?.name !== currentPlantProtection?.value?.name,
          ) ?? [];
        const originalBoundaryRestrictions =
          parcel?.subtractableAreas?.boundary?.filter(
            (buffer: RestrictionsPestBuffer) =>
              !buffer?.isMaterial ||
              buffer?.name !== currentPlantProtection?.value?.name,
          ) ?? [];

        const waterRestrictions =
          filteredBuffersByDriftClass?.filter(
            (buffer: RestrictionsPestBuffer) =>
              buffer?.type === "Water" && buffer?.value !== 0,
          ) ?? [];
        const boundaryRestrictions =
          filteredBuffersByDriftClass?.filter(
            (buffer: RestrictionsPestBuffer) =>
              buffer?.type === "Boundary" && buffer?.value !== 0,
          ) ?? [];

        const reducedWaterRestrictions = waterRestrictions.length
          ? waterRestrictions.reduce(
              (acc: RestrictionsPestBuffer, curr: RestrictionsPestBuffer) =>
                curr.value > acc.value ? curr : acc,
              waterRestrictions[0],
            )
          : undefined;
        const reducedBoundaryRestrictions = boundaryRestrictions.length
          ? boundaryRestrictions.reduce(
              (acc: RestrictionsPestBuffer, curr: RestrictionsPestBuffer) =>
                curr.value > acc.value ? curr : acc,
              boundaryRestrictions[0],
            )
          : undefined;

        setFieldValue(`parcels[${index}]`, {
          ...parcel,
          subtractableAreas: {
            ...parcel.subtractableAreas,
            water: [
              ...originalWaterRestrictions,
              ...(reducedWaterRestrictions ? [reducedWaterRestrictions] : []),
            ],
            boundary: [
              ...originalBoundaryRestrictions,
              ...(reducedBoundaryRestrictions
                ? [reducedBoundaryRestrictions]
                : []),
            ],
          },
        });
      });
    }

    return () => {
      parcels.value.forEach((parcel: AnyTodo, index: number) => {
        const originalWaterRestrictions =
          parcel?.subtractableAreas?.water?.filter(
            (buffer: RestrictionsPestBuffer) =>
              buffer?.isMaterial === undefined ||
              buffer.name !== currentPlantProtection?.value?.name,
          ) ?? [];
        const originalBoundaryRestrictions =
          parcel?.subtractableAreas?.boundary?.filter(
            (buffer: RestrictionsPestBuffer) =>
              buffer?.isMaterial === undefined ||
              buffer.name !== currentPlantProtection?.value?.name,
          ) ?? [];

        setFieldValue(`parcels[${index}]`, {
          ...parcel,
          subtractableAreas: {
            ...parcel.subtractableAreas,
            water: [...originalWaterRestrictions],
            boundary: [...originalBoundaryRestrictions],
          },
        });
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentDriftClass?.value?.code,
    currentPests?.value?.length,
    currentPests?.value,
    parcels?.value?.length,
  ]);

  useEffect(() => {
    const updatedPests: MappedActivePest[] = [];

    currentPests?.value?.forEach((pest: MappedActivePest) => {
      const isAllowed = checkIsAllowedForCrop({
        activePestId: isExisting ? (pest.id as string) : pest.organism.id,
        applications: currentPlantProtectionApplication?.applications ?? [],
        targetCropId: selectedTargetCropId,
      });

      updatedPests.push({ ...pest, isAllowedForCrop: !!isAllowed });
    });

    const checkedPests = updatedPests.map((pest) =>
      mapActivePest({
        activeSubstance: pest as unknown as ActiveSubstanceTo,
        applications: currentPlantProtectionApplication?.applications ?? [],
        targetCropId: selectedTargetCropId,
      }),
    );

    const allowedPests = checkedPests.filter(
      (pest: MappedActivePest) => pest?.isAllowedForCrop,
    );

    setRecommendedDoseUnit(
      allowedPests.length > 0 ? allowedPests[0].recommendedDoseUnit : undefined,
    );

    setFieldValue(`plantProtections[${index}].pests`, checkedPests);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTargetCropId, currentPlantProtectionApplication?.applications]);

  useEffect(() => {
    if (actionTotalArea && dosePerHa.value) {
      const normalizedDosePerHectare =
        Localization.parseNumberFromLocalizedString(dosePerHa.value);

      const totalDosage =
        (normalizedDosePerHectare as number) * actionTotalArea;

      setFieldValue(
        `plantProtections[${index}].dosePerHectare`,
        Localization.num2str(normalizedDosePerHectare, locale, 3),
      );
      setFieldValue(
        `plantProtections[${index}].dosage`,
        Localization.num2str(totalDosage, locale, 3),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, actionTotalArea, locale]);

  const updateFieldValues = (
    event: React.ChangeEvent<HTMLInputElement>,
    mainField: string,
    relatedField: string,
    calculationFn: (value: number) => number,
  ) => {
    const value = event.currentTarget.value;
    const isValidValue = Localization.checkValidStr(value, locale);
    if (!isValidValue) {
      setFieldValue(mainField, value);
      return;
    }

    const normalizedVal = Localization.str2num(value, locale, 5);

    const calculatedValue = Number(
      calculationFn(normalizedVal as number).toFixed(5),
    );

    setFieldValue(
      mainField,
      Localization.num2strNonFixed(normalizedVal, locale),
    );
    setFieldValue(
      relatedField,
      Localization.num2str(calculatedValue, locale, 5),
    );
  };

  const setDriftClass = (value: number) => {
    setFieldValue(`plantProtections[${index}].driftClass`, value);
  };
  const setDosePerHectarUnit = (value: { id: string; name: string }) => {
    setFieldValue(`plantProtections[${index}].doseUnit`, value);
  };

  const setDosePerHectare = (event: React.ChangeEvent<HTMLInputElement>) => {
    const calculateDosage = (value: number) =>
      actionTotalArea !== 0 ? value * actionTotalArea : value;

    updateFieldValues(
      event,
      `plantProtections[${index}].dosePerHectare`,
      `plantProtections[${index}].dosage`,
      calculateDosage,
    );
  };

  const setDosage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const calculateDosePerHectare = (value: number) =>
      actionTotalArea !== 0 ? value / actionTotalArea : value;

    updateFieldValues(
      event,
      `plantProtections[${index}].dosage`,
      `plantProtections[${index}].dosePerHectare`,
      calculateDosePerHectare,
    );
  };

  return (
    <Fragment>
      <ListItem
        isEditing={isEditing}
        key={plantProtection.id}
        variant={!isPlantProtectionForCurrentCrop ? "warning" : undefined}
        header={
          <div
            className={classes.headerRow}
            data-test="plant-protection-heading"
          >
            <div>
              <div className={classes.name}>{plantProtection.name}</div>
              {!isPlantProtectionForCurrentCrop && (
                <div
                  className={classes.warningNotRegisteredForCrop}
                  data-test="pest-no-register-for-crop"
                >
                  <FormattedMessage id="Por.notRegisteredForCrop.message" />
                </div>
              )}
            </div>
            <Grid item sm={2} xs={6}>
              {parcels.value.length > 0 && (
                <SelectionItemColumn
                  label={<FormattedMessage id="Por.Effectiveness.heading" />}
                >
                  <PlantProtectionEffectivenessControl
                    index={index}
                    isEditing={isEditing}
                    isExisting={isExisting}
                    parcels={parcels.value}
                    plantProtection={plantProtection}
                  />
                </SelectionItemColumn>
              )}
            </Grid>
          </div>
        }
        onRemoveItem={() => {
          onItemRemove(index, currentPlantProtection.value.id);
        }}
      >
        <Grid container>
          <Grid item md={5} xs={12}>
            <FieldArray name={`plantProtections[${index}].pests`}>
              {(arrayHelpers) => (
                <PestControl
                  arrayHelpers={arrayHelpers}
                  data-test="pest-item"
                  dosePerHa={dosePerHa.value}
                  isEditing={isEditing}
                  name={arrayHelpers.name}
                  plantProtectionApplication={registeredApplications ?? []}
                  hasError={
                    errors?.plantProtections
                      ? !!(errors?.plantProtections[index] as AnyTodo)?.pests
                      : false
                  }
                />
              )}
            </FieldArray>
          </Grid>
          <Grid container item md={7} xs={12}>
            <Grid item xs={10}>
              <CfAutocomplete
                blurOnSelect
                disabled={!isEditing}
                id="drift-selection"
                onChange={setDriftClass}
                suggestions={driftClasses}
                defaultValues={
                  driftClasses.find(
                    (driftClass) =>
                      driftClass.code === currentDriftClass?.value?.code,
                  ) ?? driftClasses[0]
                }
                error={
                  errors?.plantProtections
                    ? !!(errors?.plantProtections[index] as AnyTodo)?.driftClass
                    : false
                }
                label={
                  <FormattedMessage id="Por.driftClassSelector.placeholder" />
                }
              />
            </Grid>
            <Grid
              alignItems="baseline"
              container
              item
              spacing={1}
              wrap="nowrap"
            >
              <Grid alignItems="flex-end" container item spacing={1} xs={6}>
                <Grid item xs={7}>
                  <CfFormControl
                    classes={{ formControl: classes.customFormControl }}
                  >
                    <Field
                      component={CfFormikTextField}
                      disabled={!isEditing}
                      label={<FormattedMessage id="common.dosePerHectare" />}
                      name={`plantProtections[${index}].dosePerHectare`}
                      onChange={setDosePerHectare}
                      validateOnBlur
                      error={
                        errors?.plantProtections
                          ? !!(errors?.plantProtections[index] as AnyTodo)
                              ?.dosePerHectare
                          : undefined
                      }
                      helperText={
                        !!(errors?.plantProtections?.[index] as AnyTodo)
                          ?.dosePerHectare && (
                          <FormattedMessage id="validation.number" />
                        )
                      }
                    />
                  </CfFormControl>
                </Grid>
                <Grid item xs={5}>
                  <CfFormControl
                    classes={{ formControl: classes.customFormControl }}
                  >
                    <CfAutocomplete
                      disableClearable
                      disabled={!isEditing}
                      id="plant-selection-dose-unit"
                      onChange={setDosePerHectarUnit}
                      suggestions={unitsPerHectare}
                      testId="dose-unit-selector"
                      defaultValues={
                        doseUnit?.value &&
                        typeof doseUnit?.value === "object" &&
                        Object.keys(doseUnit.value).length > 0
                          ? doseUnit.value
                          : unitsPerHectare[0]
                      }
                    />
                  </CfFormControl>
                </Grid>
                {recommendedDoseUnit && (
                  <div
                    className={classes.warningMessage}
                    data-test="pest-dose-warning"
                  >
                    {recommendedDoseUnit === doseUnit?.value?.name &&
                      isOverMaxValue &&
                      !(errors?.plantProtections?.[index] as AnyTodo)
                        ?.dosePerHectare && (
                        <FormattedMessage id="Por.pest.maxDosePerHa.warning" />
                      )}

                    {recommendedDoseUnit !== doseUnit?.value?.name &&
                      !(errors?.plantProtections?.[index] as AnyTodo)
                        ?.dosePerHectare && (
                        <FormattedMessage
                          id="Eph.recommendedDoseUnit.warning"
                          values={{
                            doseUnit: recommendedDoseUnit ?? "",
                          }}
                        />
                      )}
                  </div>
                )}
              </Grid>
              <Grid alignItems="center" container item spacing={1} xs={6}>
                <Grid item xs={7}>
                  <CfFormControl>
                    <Field
                      component={CfFormikTextField}
                      disabled={!isEditing}
                      label={<FormattedMessage id="common.totalDose" />}
                      name={`plantProtections[${index}].dosage`}
                      onChange={setDosage}
                      validateOnBlur
                      error={
                        errors?.plantProtections
                          ? // https://github.com/jaredpalmer/formik/issues/2347
                            !!(errors?.plantProtections[index] as AnyTodo)
                              ?.dosage
                          : undefined
                      }
                      helperText={
                        !!(errors?.plantProtections?.[index] as AnyTodo)
                          ?.dosage && (
                          <FormattedMessage id="validation.number" />
                        )
                      }
                    />
                  </CfFormControl>
                </Grid>
                <Grid item xs={5}>
                  {doseUnit?.value?.id}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={10}>
              <CfFormControl>
                <Field
                  component={CfFormikTextField}
                  disabled={!isEditing}
                  label={<FormattedMessage id="common.note" />}
                  name={`plantProtections[${index}].note`}
                  error={
                    errors?.plantProtections
                      ? !!(errors?.plantProtections[index] as AnyTodo)?.note
                      : undefined
                  }
                />
              </CfFormControl>
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
    </Fragment>
  );
};

const unitsPerHectare = UnitService.getUnits()
  .filter((item: { id: string; name: string }) => item.id !== "vj")
  .map((unit) => ({ id: unit.id, name: `${unit.name}/ha` }));

const useStyles = makeStyles((theme: Theme) => ({
  name: {
    fontSize: 16,
    marginRight: 10,
    color: "#6AA8EC",
    [theme.breakpoints.down("md")]: {
      marginBottom: 10,
    },
  },
  warningNotRegisteredForCrop: {
    fontSize: "12px",
  },
  headerRow: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  warningMessage: {
    fontSize: 12,
    color: "#B28500",
    paddingLeft: 8,
    paddingBottom: 8,
  },
  customFormControl: {
    margin: "5px 0px 5px 0px",
  },
}));
