import React, { useContext } from "react";

import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { getAgiStatus } from "../../../shared/api/sentinel/agiStatus/agiStatus.selectors";

import {
  createAgIntegrationApi,
  deleteAgIntegrationApi,
  getAgIntegrationSettingsApi,
} from "../../../shared/api/sentinel/agiStatus/agiStatus.api";
import { SnackbarContext } from "../../../shared/containers/SnackbarProvider/SnackbarProvider";
import { useToggle } from "../../../shared/hooks/useToggle";
import { AsyncFn } from "../../../types";

import AgActivationDialog from "./fleetConnectorModals/AgActivationDialog";
import AgDeactivationDialog from "./fleetConnectorModals/AgDeactivationDialog";
import FleetConnectorTableRow from "./FleetConnectorTableRow";

import { getCreateAgIntegrationStatusApiParams } from "../../../shared/api/sentinel/agiStatus/agiStatus.types";

type Props = {
  shouldBeHidden: boolean;
};

const FleetConnectorRow = ({ shouldBeHidden }: Props) => {
  const showSnackbar = useContext(SnackbarContext);
  const dispatch = useDispatch();
  const agiStatus = useSelector(getAgiStatus);

  const {
    on: deactivationDialogOpen,
    setOff: closeDeactivationDialog,
    setOn: openDeactivationDialog,
  } = useToggle();
  const {
    on: activationDialogOpen,
    setOff: closeActivationDialog,
    setOn: openActivationDialog,
  } = useToggle();

  const handleAgDeactivationDialogAccept = () => {
    dispatch(deleteAgIntegrationApi());
    closeDeactivationDialog();
  };

  const handleAgActivationDialogAccept = (name: string, email: string) => {
    const param: getCreateAgIntegrationStatusApiParams = {
      email,
      name,
    };
    dispatch(
      (
        createAgIntegrationApi as unknown as AsyncFn<getCreateAgIntegrationStatusApiParams>
      )(param),
    ).then((res) => {
      if (res.error) {
        showSnackbar({
          message: (
            <FormattedMessage
              id="Agi.activationDialog.error"
              values={{ email: param.email }}
            />
          ),
          isError: true,
        });
        return;
      }
      showSnackbar({
        message: <FormattedMessage id="Agi.activationDialog.success" />,
        isSuccess: true,
      });
    });

    closeActivationDialog();
  };

  const handleAgIntegrationSetting = () => {
    dispatch((getAgIntegrationSettingsApi as unknown as AsyncFn)()).then(
      (res) => window.open(res.payload.url, "_blank"),
    );
  };

  return (
    <>
      {!shouldBeHidden ? (
        <>
          <FleetConnectorTableRow
            data={agiStatus}
            buttonOnClick={
              agiStatus.enabled
                ? handleAgIntegrationSetting
                : openActivationDialog
            }
            switchOnClick={
              agiStatus.enabled ? openDeactivationDialog : openActivationDialog
            }
          />
          <AgActivationDialog
            onAccept={handleAgActivationDialogAccept}
            onClose={closeActivationDialog}
            opened={activationDialogOpen}
          />
          <AgDeactivationDialog
            onAccept={handleAgDeactivationDialogAccept}
            onClose={closeDeactivationDialog}
            opened={deactivationDialogOpen}
          />
        </>
      ) : null}
    </>
  );
};

export default FleetConnectorRow;
