import { removeEL } from "../eventListener/eventListener.actions";
import {
  setDoubleClickZoomIA,
  removeDoubleClickZoomIA,
} from "../interaction/interaction.actions";
import {
  clearParcelDetail,
  setDefaultHoverEL,
  setDefaultSelectEL,
} from "../map/map.actions";
import { measureStart, measureEnd } from "../measurement/measurement.actions";
import { refreshDefaultStyles } from "../style/style.actions";

import * as types from "./editor.constants";

export const editorSetTool = (tool) => ({
  type: types.EDITOR_SET_TOOL,
  tool,
});

export const editorSetHoveredId = (hoveredId) => ({
  type: types.EDITOR_SET_HOVERED_ID,
  hoveredId,
});

export const editorSetMapDragging = (isMapDragging) => ({
  type: types.EDITOR_SET_MAP_DRAGGING,
  isMapDragging,
});

export const editorSetSelected = (selected, rewrite = true) => ({
  type: types.EDITOR_SET_SELECTED,
  selected,
  rewrite,
});

export const editorToolStart = (toolName) => (dispatch) => {
  dispatch(clearParcelDetail());
  dispatch(removeDoubleClickZoomIA());
  dispatch(removeEL("defaultHoverELKey"));
  dispatch(removeEL("defaultSelectELKey"));
  dispatch(editorSetTool(toolName));

  dispatch(measureStart());
};

export const editorToolEnd = () => (dispatch) => {
  dispatch(measureEnd());

  dispatch(editorSetSelected([]));
  dispatch(editorSetHoveredId(null));
  dispatch(refreshDefaultStyles());

  dispatch(setDoubleClickZoomIA());
  dispatch(setDefaultSelectEL());
  dispatch(setDefaultHoverEL());
  dispatch(editorSetTool(null));
};

/** ********* shared ********* */

export const getPayload = (reponse) => (reponse ? reponse.payload : null);

export const handleResponse = (res) => {
  const payload = getPayload(res);
  if (res?.error) {
    return Promise.reject(payload);
  }

  return Promise.resolve(payload);
};
