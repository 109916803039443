import { queryOptions } from "@tanstack/react-query";

import { GetAll2Params, getAll2 } from "../../../../generated/api/telematics";
import { staleTime } from "../../../../shared/query-client";

const machinesQuery = ({ dateFrom, dateTo, farmIds }: GetAll2Params) =>
  queryOptions({
    queryKey: ["telematics", "machines", dateFrom, dateTo, farmIds],
    queryFn: () =>
      getAll2({
        dateFrom,
        dateTo,
        "sort-col": "NAME",
        "sort-dir": "ASC",
        farmIds,
      }),
    staleTime: staleTime.Infinity,
  });

export { machinesQuery };
