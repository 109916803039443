import React from "react";

import BioMonitoring from "../../BioMonitoring";
import { IndexTypeDataProps } from "../types";
import { useBioMonitoring } from "../useBioMonitoring";

const IndexTypeSection = ({
  index,
  parcelId,
  setCropHarvestedError,
  setCropIntervals,
}: IndexTypeDataProps) => {
  const hookData = useBioMonitoring(parcelId);

  React.useEffect(() => {
    if (hookData) {
      if (hookData.cropHarvestedError) {
        setCropHarvestedError(hookData.cropHarvestedError);
      }
      if (hookData.cropIntervals) {
        setCropIntervals(hookData.cropIntervals);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hookData?.isFetching, setCropHarvestedError, setCropIntervals]);

  return (
    <BioMonitoring
      hookData={hookData}
      index={index}
      lastUpdate={undefined}
      parcelId={parcelId}
    />
  );
};

export { IndexTypeSection };
