import React from "react";

import { FormattedMessage } from "react-intl";

const MapHoverHint = () => (
  <div>
    <div
      className="hover-hint parcel-area-info"
      id="parcel-area"
      style={{ display: "none" }}
    />
    <div
      className="hover-hint measurement-info"
      id="measurement-result"
      style={{ display: "none" }}
    />
    <div
      className="hover-hint parcel-disabled-info"
      id="parcel-has-crop-split"
      style={{ display: "none" }}
    >
      <p>
        <FormattedMessage id="map-hint.parcel-has-crop-split" />
      </p>
    </div>
    <div
      className="hover-hint parcel-disabled-info"
      id="parcel-not-adjacent"
      style={{ display: "none" }}
    >
      <p>
        <FormattedMessage id="map-hint.not-adjacent" />
      </p>
    </div>
    <div
      className="hover-hint parcel-disabled-info"
      id="single-parcel-lpis"
      style={{ display: "none" }}
    >
      <p>
        <FormattedMessage id="map-hint.single-parcel-lpis" />
      </p>
    </div>
    <div
      className="hover-hint parcel-disabled-info"
      id="parcel-different-lpis"
      style={{ display: "none" }}
    >
      <p>
        <FormattedMessage id="map-hint.parcel-different-lpis" />
      </p>
    </div>
  </div>
);

export { MapHoverHint };
