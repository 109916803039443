import React from "react";

import { Breakpoint } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Theme } from "@mui/material/styles/createTheme";
import { makeStyles } from "@mui/styles";

type Props = {
  open: boolean;
  onClose: () => void;
  maxWidth?: Breakpoint;
  fullWidth?: boolean;
  openButton: JSX.Element;
};

const Root = ({
  children,
  fullWidth = false,
  maxWidth = "sm",
  onClose,
  open,
  openButton,
}: React.PropsWithChildren<Props>) => (
  <>
    {openButton}
    <Dialog
      aria-labelledby="simple-dialog-title"
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={onClose}
      open={open}
    >
      {children}
    </Dialog>
  </>
);

const Title = ({ children }: React.PropsWithChildren<unknown>) => {
  const classes = useStyles();

  return (
    <DialogTitle
      classes={{ root: classes.title }}
      data-test="dialog-title"
      id="simple-dialog-title"
    >
      {children}
    </DialogTitle>
  );
};

const Content = ({ children }: React.PropsWithChildren<unknown>) => {
  const classes = useStyles();

  return (
    <DialogContent
      classes={{ root: classes.dialogContent }}
      data-test="dialog-content"
    >
      <>{children}</>
    </DialogContent>
  );
};

const Actions = ({ children }: React.PropsWithChildren<unknown>) => (
  <DialogActions data-test="dialog-footer">{children}</DialogActions>
);

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: 20,
    fontWeight: 500,
    padding: "34px 50px 20px",
    [theme.breakpoints.down("sm")]: {
      padding: "34px 16px 20px",
    },
  },
  dialogContent: {
    padding: "0px 50px 24px 50px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 16px 24px 16px",
    },
  },
}));

export const SimpleDialog = {
  Root,
  Title,
  Content,
  Actions,
};
