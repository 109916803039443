import angular from "angular";

import CataloguesController from "./catalogues.controller";

type StateProvider = {
  state: (arg0: string, arg1: Record<string, unknown>) => StateProvider;
};

const cataloguesComponent = {
  bindings: {
    farmId: "<",
    farm: "<",
  },
  controller: CataloguesController,
};

export default angular
  .module("app.catalogues", [])
  .config(config)
  .component("cataloguesComponent", cataloguesComponent).name;

config.$inject = ["$stateProvider"];

function config($stateProvider: StateProvider) {
  $stateProvider
    .state("farm.active.catalogues", {
      url: "/catalogues",
      views: {
        farm: {
          component: "cataloguesComponent",
        },
      },
      abstract: true,
    })
    .state("farm.active.catalogues.seedsReact", {
      url: "/seedsReact",
      views: {
        farm: {
          component: "cataloguesComponent",
        },
      },
    })
    .state("farm.active.catalogues.fertilizersReact", {
      url: "/fertilizersReact",
      views: {
        farm: {
          component: "cataloguesComponent",
        },
      },
    })
    .state("farm.active.catalogues.fertilizersReact.detail", {
      url: "/:fertilizerId",
      views: {
        farm: {
          component: "cataloguesComponent",
        },
      },
    })
    .state("farm.active.catalogues.fertilizersReact.new", {
      url: "/new",
      views: {
        farm: {
          component: "cataloguesComponent",
        },
      },
    })
    .state("farm.active.catalogues.plantProtection", {
      url: "/plantProtection",
      views: {
        farm: {
          component: "cataloguesComponent",
        },
      },
    })
    .state("farm.active.catalogues.plantProtection.detail", {
      url: "/:plantProtectionId",
      views: {
        farm: {
          component: "cataloguesComponent",
        },
      },
    })
    .state("farm.active.catalogues.plantProtection.new", {
      url: "/new",
      views: {
        farm: {
          component: "cataloguesComponent",
        },
      },
    })

    .state("farm.active.catalogues.drivers", {
      url: "/drivers",
      views: {
        farm: {
          component: "cataloguesComponent",
        },
      },
    })
    .state("farm.active.catalogues.machines", {
      url: "/machines",
      views: {
        farm: {
          component: "cataloguesComponent",
        },
      },
    })
    .state("farm.active.catalogues.equipment", {
      url: "/equipment",
      views: {
        farm: {
          component: "cataloguesComponent",
        },
      },
    });
}
