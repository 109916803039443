import React from "react";

import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { Form, Formik } from "formik";
import { FormattedMessage } from "react-intl";

import CfDialog from "../../../../../shared/components/common/CfDialog/CfDialog";
import CfFormControl from "../../../../../shared/components/form/CfFormControl/CfFormControl";

import { PlantProtectionEffectivenessPerParcel } from "./PlantProtectionEffectivenessPerParcel";

import { InitialParcelToAdd } from "../../../ActionOthers/actionOther.types";
import {
  EphPlantProtectionsType,
  PlantProtectionEffectivenessFormValues,
  PlantProtectionEffectivenessType,
} from "../../actionEph.types";

type Props = {
  opened: boolean;
  handleClose: () => void;
  parcels: InitialParcelToAdd[];
  plantProtection: EphPlantProtectionsType;
  isEditing: boolean;
  getEffectiveness: (
    parcels: InitialParcelToAdd[],
  ) => PlantProtectionEffectivenessType[];
  handleAccept: (values: PlantProtectionEffectivenessFormValues) => void;
};

export const PlantProtectionEffectivenessDialog = ({
  getEffectiveness,
  handleAccept,
  handleClose,
  isEditing,
  opened,
  parcels,
  plantProtection,
}: Props) => {
  const classes = useStyles();

  const initialValues: PlantProtectionEffectivenessFormValues = (() => {
    if (!plantProtection.effectiveness?.length) {
      return { effectiveness: getEffectiveness(parcels) };
    } else {
      return { effectiveness: plantProtection.effectiveness };
    }
  })();

  const dialogTitle = (
    <FormattedMessage
      id="Por.EffectivenessDialog.heading"
      values={{
        por: plantProtection.name ?? "",
      }}
    />
  );

  return (
    <Formik<PlantProtectionEffectivenessFormValues>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleAccept}
    >
      {({ resetForm, setFieldValue, submitForm, values }) => (
        <CfDialog
          acceptText={isEditing ? <FormattedMessage id="common.save" /> : ""}
          cancelText={<FormattedMessage id="common.cancel" />}
          onAccept={submitForm}
          onClose={handleClose}
          opened={opened}
          title={dialogTitle}
          onCancel={() => {
            resetForm();
            handleClose();
          }}
        >
          <Form>
            <CfFormControl>
              {values.effectiveness.map((effectiveness, index) => {
                const handleChangeEffectiveness = (
                  event: React.ChangeEvent<HTMLInputElement>,
                ) => {
                  setFieldValue(
                    `effectiveness[${index}].value`,
                    event.target.defaultValue,
                  );
                };
                return (
                  <Grid
                    className={classes.field}
                    container
                    key={index}
                    spacing={0}
                  >
                    <Grid className={classes.gridParcelName} item xs={4}>
                      <div data-test="parcel-name">
                        {effectiveness.parcelName}
                      </div>
                    </Grid>
                    <Grid item xs={8}>
                      <PlantProtectionEffectivenessPerParcel
                        defaultValues={effectiveness.value}
                        isEditing={isEditing}
                        onChange={handleChangeEffectiveness}
                      />
                    </Grid>
                  </Grid>
                );
              })}
            </CfFormControl>
          </Form>
        </CfDialog>
      )}
    </Formik>
  );
};

const useStyles = makeStyles(() => ({
  field: {
    paddingTop: 10,
  },
  gridParcelName: {
    display: "flex",
    alignItems: "center",
  },
}));
