import * as editorTypes from "../actions/editor/editor.constants";
import * as measurementTypes from "../actions/measurement/measurement.constants";

export default () =>
  (
    state = {
      tool: null,
      hoveredId: null,
      isMapDragging: false,
      selected: [],
      measurement: {
        items: [],
      },
    },
    action = {},
  ) => {
    switch (action.type) {
      case editorTypes.EDITOR_SET_TOOL:
        return {
          ...state,
          tool: action.tool,
        };
      case editorTypes.EDITOR_SET_HOVERED_ID:
        return {
          ...state,
          hoveredId: action.hoveredId,
        };
      case editorTypes.EDITOR_SET_MAP_DRAGGING:
        return {
          ...state,
          isMapDragging: action.isMapDragging,
        };
      case editorTypes.EDITOR_SET_SELECTED:
        return {
          ...state,
          selected: updateArray(
            state.selected,
            action.selected,
            action.rewrite,
          ),
        };
      case measurementTypes.MEASUREMENT_ADD_NEW:
        return {
          ...state,
          measurement: {
            ...state.measurement,
            items: [action.measurement, ...state.measurement.items],
          },
        };
      case measurementTypes.MEASUREMENT_UPDATE:
        return {
          ...state,
          measurement: {
            ...state.measurement,
            items: state.measurement.items.map((item) => {
              if (item.id === action.measurement.id) {
                return action.measurement;
              }
              return item;
            }),
          },
        };
      case measurementTypes.MEASUREMENT_REMOVE:
        return {
          ...state,
          measurement: {
            ...state.measurement,
            items: state.measurement.items.filter(
              (item) => item.id !== action.measurementId,
            ),
          },
        };
      case measurementTypes.MEASUREMENT_CLEAR_ALL:
        return {
          ...state,
          measurement: {
            ...state.measurement,
            items: [],
          },
        };
      default:
        return state;
    }
  };

const updateArray = (oldArray, value, rewrite) => {
  const newArray = Array.isArray(value) ? value : [value];
  return rewrite ? newArray : [...oldArray, ...newArray];
};
