import React from "react";

import LinearProgress from "@mui/material/LinearProgress";
import MenuItem from "@mui/material/MenuItem";
import { Field } from "formik";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import {
  getCropUsage,
  getIsFetchingCropUsage,
} from "../../../../../shared/api/agroevidence/catalogues/eagri/eagri.selectors";

import CfFormControl from "../../../../../shared/components/form/CfFormControl/CfFormControl";
import CfFormikTextField from "../../../../../shared/components/form/CfFormikTextField/CfFormikTextField";
import * as validators from "../../../../../shared/misc/validators";

import { CropUseTypeTo } from "../../../../../shared/api/agroevidence/agroevidence.types";

interface Props {
  isEditing: boolean;
}

export const CropUsageSelector = ({ isEditing }: Props) => {
  const cropUsage = useSelector(getCropUsage);
  const isFetchingCropUsage = useSelector(getIsFetchingCropUsage);

  if (isFetchingCropUsage) {
    return <LinearProgress color="secondary" />;
  }
  const isDisable = cropUsage.length === 0 || !isEditing;

  return cropUsage.length > 0 ? (
    <CfFormControl>
      <Field
        component={CfFormikTextField}
        disabled={isDisable}
        label={<FormattedMessage id="action.cropUsage" />}
        name="cropUseType"
        select
        validate={isDisable ? undefined : validators.required}
        validateOnBlur
      >
        {cropUsage.map((item: CropUseTypeTo) => (
          <MenuItem key={item.externalId} value={item.externalId}>
            {item.description}
          </MenuItem>
        ))}
      </Field>
    </CfFormControl>
  ) : null;
};
