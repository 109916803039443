import React, { FC, Suspense, useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { ErrorBoundary } from "react-error-boundary";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getTelematicsListAdvancedFilter } from "../../selectors/telematicsList.selectors";

import { setAdvancedFilter } from "../../../shared/actions/filter.actions";

import { MachineTo } from "../../../generated/api/telematics";
import CfLoader from "../../../shared/components/common/CfLoader/CfLoader";
import CfFilter from "../../../shared/containers/CfFilter/CfFilter";
import { Thunk } from "../../../types";

import CropsSelector from "./CropsSelector/CropsSelector";
import DriverSelector from "./DriverSelector/DriverSelector";
import EquipmentSelector from "./EquipmentSelector/EquipmentSelector";
import MachineSelector from "./MachineSelector/MachineSelector";
import OperationSelector from "./OperationSelector/OperationSelector";
import ParcelAffiliationSelector from "./ParcelAffiliationSelector/ParcelAffiliationSelector";
import ParcelSelector from "./ParcelSelector/ParcelSelector";
import ParcelSubjectSelector from "./ParcelSubjectSelector/ParcelSubjectSelector";
import ProductionOperationSelector from "./ProductionOperationSelector/ProductionOperationSelector";
import StateSelector from "./StateSelector/StateSelector";

import { TelematicsState } from "../../../reducers/telematics.reducer.types";
import { TelematicsAdvancedFilter as TelematicsAdvancedFilterType } from "../../telematics.types";

const useStyles = makeStyles({
  container: {
    padding: 20,
    minWidth: 300,
    maxWidth: 600,
  },
  select: {
    marginBottom: 10,
  },
});

interface TelematicsAdvancedFilterProps {
  advancedFilter: TelematicsAdvancedFilterType;
  initMachineFilter?: MachineTo;
  langId: string;
  namespace: string;
  ngResetFilters: () => void;
  setAdvancedFilter: (
    values: TelematicsAdvancedFilterType,
    namespace: string,
  ) => void;
  setInitMachineFilter: (initMachineFilter?: MachineTo) => void;
}

export const TelematicsAdvancedFilter: FC<TelematicsAdvancedFilterProps> = ({
  advancedFilter,
  initMachineFilter,
  langId,
  namespace,
  ngResetFilters,
  setAdvancedFilter,
  setInitMachineFilter,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (initMachineFilter) {
      setAdvancedFilter({ machine: [initMachineFilter] }, namespace);
    }
  }, [initMachineFilter, namespace, setAdvancedFilter]);

  const [advancedFilterState, setAdvancedFilterState] =
    useState<TelematicsAdvancedFilterType>(advancedFilter);

  const handleChange =
    (key: keyof TelematicsAdvancedFilterType) =>
    (value: TelematicsAdvancedFilterType[typeof key]) => {
      setAdvancedFilterState((c) => ({
        ...c,
        [key]: value,
      }));
    };

  const resetInitFilter = () => {
    setInitMachineFilter(undefined);
    ngResetFilters();
  };

  return (
    <CfFilter
      filterState={advancedFilterState}
      langId={langId}
      namespace={namespace}
      onAccept={resetInitFilter}
      onReset={resetInitFilter}
      setAdvancedFilterState={setAdvancedFilterState}
    >
      <div className={classes.container}>
        <ErrorBoundary fallback={<></>}>
          <Suspense fallback={<CfLoader />}>
            <Grid container>
              <Grid className={classes.select} item xs={12}>
                <StateSelector
                  defaultValues={advancedFilter.state}
                  label={<FormattedMessage id="TelematicsList.filter.state" />}
                  onChange={handleChange("state")}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid className={classes.select} item xs={12}>
                <OperationSelector
                  defaultValues={advancedFilter.operation}
                  label={
                    <FormattedMessage id="TelematicsList.filter.operation" />
                  }
                  onChange={(values) =>
                    handleChange("operation")(values.map((v) => v.code))
                  }
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid className={classes.select} item xs={12}>
                <CropsSelector
                  defaultValues={advancedFilter.crop}
                  label={<FormattedMessage id="TelematicsList.filter.crop" />}
                  onChange={handleChange("crop")}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid className={classes.select} item xs={12}>
                <ProductionOperationSelector
                  defaultValues={advancedFilter.productionOperation}
                  onChange={handleChange("productionOperation")}
                  label={
                    <FormattedMessage id="TelematicsList.filter.productionOperation" />
                  }
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid className={classes.select} item xs={12}>
                <ParcelSelector
                  defaultValues={advancedFilter.parcel}
                  label={<FormattedMessage id="TelematicsList.filter.parcel" />}
                  onChange={handleChange("parcel")}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid className={classes.select} item xs={12}>
                <ParcelAffiliationSelector
                  defaultValues={advancedFilter.parcelAffiliation}
                  onChange={handleChange("parcelAffiliation")}
                  label={
                    <FormattedMessage id="TelematicsList.filter.parcelAffiliation" />
                  }
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid className={classes.select} item xs={12}>
                <ParcelSubjectSelector
                  defaultValues={advancedFilter.parcelSubject}
                  onChange={handleChange("parcelSubject")}
                  label={
                    <FormattedMessage id="TelematicsList.filter.parcelUser" />
                  }
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid className={classes.select} item xs={12}>
                <DriverSelector
                  defaultValues={advancedFilter.driver}
                  label={<FormattedMessage id="TelematicsList.filter.driver" />}
                  onChange={handleChange("driver")}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid className={classes.select} item xs={12}>
                <MachineSelector
                  defaultValues={advancedFilter.machine}
                  onChange={handleChange("machine")}
                  label={
                    <FormattedMessage id="TelematicsList.filter.machine" />
                  }
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid className={classes.select} item xs={12}>
                <EquipmentSelector
                  defaultValues={advancedFilter.equipment}
                  onChange={handleChange("equipment")}
                  label={
                    <FormattedMessage id="TelematicsList.filter.equipment" />
                  }
                />
              </Grid>
            </Grid>
          </Suspense>
        </ErrorBoundary>
      </div>
    </CfFilter>
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  advancedFilter: getTelematicsListAdvancedFilter(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      setAdvancedFilter,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TelematicsAdvancedFilter);
