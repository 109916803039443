import React from "react";

import { Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import CfFormattedNumber from "../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";

import { FertilizerMineralIntakeResponse } from "../../../../../shared/api/agroevidence/agroevidence.types";

interface Props {
  fertilizerDose?: FertilizerMineralIntakeResponse;
}

export const FertilizerNutrientsDose = ({ fertilizerDose }: Props) => {
  const classes = useStyles();

  return (
    <Grid
      className={classes.nutrients}
      container
      item
      sm={4}
      spacing={1}
      xs={12}
    >
      <Grid data-test="fertilizer-nutrients-N" item xs={4}>
        <div className={classes.nutrientsSpan}>N</div>
        {fertilizerDose?.nitrogenIntake !== undefined ? (
          <CfFormattedNumber value={fertilizerDose.nitrogenIntake} />
        ) : (
          "-"
        )}
      </Grid>
      <Grid data-test="fertilizer-nutrients-P" item xs={4}>
        <div className={classes.nutrientsSpan}>
          P<sub>2</sub>O<sub>5</sub>
        </div>
        {fertilizerDose?.p2o5Intake !== undefined ? (
          <CfFormattedNumber value={fertilizerDose.p2o5Intake} />
        ) : (
          "-"
        )}
      </Grid>
      <Grid data-test="fertilizer-nutrients-K" item xs={4}>
        <div className={classes.nutrientsSpan}>
          K<sub>2</sub>O
        </div>
        {fertilizerDose?.k2oIntake !== undefined ? (
          <CfFormattedNumber value={fertilizerDose.k2oIntake} />
        ) : (
          "-"
        )}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  nutrients: {
    textAlign: "center",
    width: "100%",
    marging: "0 10px",
    fontSize: 14,
  },
  nutrientsSpan: {
    color: theme.palette.grey[500],
  },
}));
