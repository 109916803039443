import { RSAA } from "redux-api-middleware";

import * as types from "./varioDoc.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../api.constants";

import { RsaaMethods, RsaaTypes } from "../../api.types";
import {
  IntegrationType,
  VariodocCreateTo,
} from "../../satellite/satellite.types";

export const getVarioDocIntegrationStatusApi = () => ({
  [RSAA]: {
    endpoint: `integrations?type=${IntegrationType.VARIODOC}&`,
    method: methods.GET as RsaaMethods,
    module: modules.SENTINEL,
    types: [
      types.GET_AGINTEGRATION_VARIODOC_REQUEST,
      types.GET_AGINTEGRATION_VARIODOC_SUCCESS,
      types.GET_AGINTEGRATION_VARIODOC_ERROR,
    ] as RsaaTypes,
  },
});

export const deleteVarioDocIntegrationApi = () => ({
  [RSAA]: {
    endpoint: `integrations?type=${IntegrationType.VARIODOC}&`,
    method: methods.DELETE as RsaaMethods,
    module: modules.SENTINEL,
    types: [
      types.DELETE_AGINTEGRATION_VARIODOC_REQUEST,
      types.DELETE_AGINTEGRATION_VARIODOC_SUCCESS,
      types.DELETE_AGINTEGRATION_VARIODOC_ERROR,
    ] as RsaaTypes,
  },
});

export const loginVarioDocApi = (param: VariodocCreateTo) => ({
  [RSAA]: {
    endpoint: "variodoc?",
    method: methods.POST as RsaaMethods,
    body: JSON.stringify(param),
    module: modules.SENTINEL,
    types: [
      types.LOGIN_VARIODOC_REQUEST,
      types.LOGIN_VARIODOC_SUCCESS,
      types.LOGIN_VARIODOC_ERROR,
    ] as RsaaTypes,
  },
});

export const validateVarioDocApi = () => ({
  [RSAA]: {
    endpoint: "variodoc/validate?",
    method: methods.GET as RsaaMethods,
    module: modules.SENTINEL,
    types: [
      types.VALIDATE_VARIODOC_REQUEST,
      types.VALIDATE_VARIODOC_SUCCESS,
      types.VALIDATE_VARIODOC_ERROR,
    ] as RsaaTypes,
  },
});
