import React from "react";

import Popover from "@mui/material/Popover";
import { makeStyles } from "@mui/styles";
import isEmpty from "lodash/isEmpty";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { getIsFetching } from "../../../../../shared/api/agroevidence/farms/farms.selectors";
import { getFarmsSuggestions } from "../../selectors/manager.selectors";

import {
  getFarmsApi,
  resetFarmsApi,
} from "../../../../../shared/api/agroevidence/farms/farms.api";
import CfSwitcherButton from "../../../../../shared/components/common/CfSwitcherButton/CfSwitcherButton";
import usePopover from "../../../../../shared/hooks/usePopover";
import AdminFarmSelector from "../../../../shared/components/AdminFarmSelector/AdminFarmSelector";

import { AdminFarm } from "../../../sensors/admin.sensors.types";

const FarmSwitcher = ({
  allFarmOption,
  farm,
  onFarmSelect,
}: {
  farm?: AdminFarm;
  allFarmOption?: boolean;
  onFarmSelect: (farm: AdminFarm) => void;
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const popover = usePopover();
  const classes = useStyles();
  const suggestions = useSelector(getFarmsSuggestions);
  const isFetchingFarms = useSelector(getIsFetching);
  const label = intl.formatMessage({ id: "FarmSwitcher.allFarms" });
  const noFarmLabel = intl.formatMessage({ id: "common.no-farm" });
  const farmOptions = [
    { id: null, code: "all", name: label?.toUpperCase() },
    { id: null, code: "none", name: noFarmLabel?.toUpperCase() },
  ];
  const extendedSuggestions = suggestions.length
    ? // @ts-expect-error no idea how to type this right now
      farmOptions.concat(suggestions)
    : suggestions;

  const onSuggestionSelect = (farm: AdminFarm) => {
    onFarmSelect(farm);
    popover.handlePopoverClose();
  };

  const onSuggestionClear = () => {
    dispatch(resetFarmsApi());
  };

  const handlePopoverClose = () => {
    popover.handlePopoverClose();
    dispatch(resetFarmsApi());
  };

  const renderSwitcherLabel = () => {
    if (farm?.name === "Default") {
      return <FormattedMessage id="common.no-farm" />;
    }
    if (allFarmOption) {
      return !isEmpty(farm) && farm?.name !== "Default" ? (
        farm.name
      ) : (
        <FormattedMessage id="FarmSwitcher.allFarms" />
      );
    }
    return !isEmpty(farm) ? (
      farm.name
    ) : (
      <FormattedMessage id="FarmSwitcher.chooseFarm" />
    );
  };

  return (
    <div>
      <CfSwitcherButton
        data-test="farm-switcher"
        onClick={popover.handlePopoverOpen}
      >
        <span className={classes.switcherButtonLabel}>
          {renderSwitcherLabel()}
        </span>
      </CfSwitcherButton>
      <Popover
        anchorEl={popover.anchorEl}
        classes={{ paper: classes.popoverPaper }}
        onClose={handlePopoverClose}
        open={popover.isOpen}
        anchorOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
      >
        <AdminFarmSelector
          isFetching={isFetchingFarms}
          onSuggestionClear={onSuggestionClear}
          onSuggestionSelect={onSuggestionSelect}
          suggestions={allFarmOption ? extendedSuggestions : suggestions}
          getFarmsByName={(search: string) =>
            dispatch(getFarmsApi({ search, isAscending: true }))
          }
        />
      </Popover>
    </div>
  );
};

const useStyles = makeStyles({
  popoverPaper: {
    width: 350,
    height: 350,
    overflow: "hidden",
  },
  switcherButtonLabel: {
    fontSize: 14,
  },
});

export { FarmSwitcher };
