import React from "react";

import { makeStyles } from "@mui/styles";
import classNames from "classnames";

type Props = {
  bottom?: number | string;
  children: React.ReactNode;
  classes?: {
    custom?: string;
  };
  left?: number | string;
  right?: number | string;
  top?: number | string;
  zIndex?: number;
};

const SectionWrapper = ({
  bottom = "unset",
  children,
  classes: propClasses = {},
  left = "unset",
  right = "unset",
  top = "unset",
  zIndex = 9,
}: Props) => {
  const classes = useStyles();

  return (
    <div
      className={classNames(classes.section, propClasses.custom)}
      style={{ top, right, bottom, left, zIndex }}
    >
      {children}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  section: {
    display: "flex",
    position: "absolute",
  },
}));

export default SectionWrapper;
