import queryString from "query-string";
import { RSAA } from "redux-api-middleware";

import * as types from "./asAppliedTaskdata.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../api.constants";

import { getStatesForApiCall } from "../../api.helpers";

import { ExportPayload, RsaaMethods, RsaaTypes } from "../../api.types";
import { TaskDataPatchTo } from "../../satellite/satellite.types";

interface GetTaskdataApiParams {
  page?: number;
  "per-page"?: number;
  showArchived: boolean;
  "sort-dir"?: string;
}

export const getTaskdataApi = (params: GetTaskdataApiParams) => ({
  [RSAA]: {
    endpoint: `taskdata?${queryString.stringify(params)}&`,
    method: methods.GET as RsaaMethods,
    module: modules.SENTINEL,
    types: getStatesForApiCall("PRECISION")("GET_PRECISION_TASK_LIST_DATA"),
  },
});

export const patchTaskdataApi = (params: TaskDataPatchTo) => ({
  [RSAA]: {
    endpoint: "taskdata?",
    method: methods.PATCH as RsaaMethods,
    module: modules.SENTINEL,
    body: JSON.stringify(params),
    types: [
      types.LOAD_AS_APPLIED_TASKDATA_REQUEST,
      types.LOAD_AS_APPLIED_TASKDATA_SUCCESS,
      types.LOAD_AS_APPLIED_TASKDATA_ERROR,
    ] as RsaaTypes,
  },
});

export const deleteTaskdataApi = (task: number[]) => ({
  [RSAA]: {
    endpoint: "taskdata?",
    method: methods.DELETE,
    module: modules.SENTINEL,
    body: JSON.stringify({ task }),
    types: [
      types.DELETE_AS_APPLIED_TASKDATA_REQUEST,
      types.DELETE_AS_APPLIED_TASKDATA_SUCCESS,
      types.DELETE_AS_APPLIED_TASKDATA_ERROR,
    ],
  },
});

export type ImportFilesApiType = {
  files: File[];
};

export const importFilesApi = (params: ImportFilesApiType) => {
  const formData = new FormData();

  params.files.forEach((file) => {
    formData.append("file", file);
  });

  return {
    [RSAA]: {
      endpoint: "taskdata?",
      method: methods.POST as RsaaMethods,
      module: modules.SENTINEL,
      noContentType: true,
      body: formData,
      types: [
        types.AS_APPLIED_IMPORT_FILES_REQUEST,
        types.AS_APPLIED_IMPORT_FILES_SUCCESS,
        types.AS_APPLIED_IMPORT_FILES_ERROR,
      ] as RsaaTypes,
    },
  };
};

export type ValidateFileApiType = {
  file: File;
};

export const validateFileApi = (params: ValidateFileApiType) => {
  const formData = new FormData();

  formData.append("file", params.file);

  return {
    [RSAA]: {
      endpoint: "taskdata/validate?",
      method: methods.POST as RsaaMethods,
      module: modules.SENTINEL,
      noContentType: true,
      body: formData,
      types: [
        types.AS_APPLIED_VALIDATE_FILES_REQUEST,
        types.AS_APPLIED_VALIDATE_FILES_SUCCESS,
        types.AS_APPLIED_VALIDATE_FILES_ERROR,
      ] as RsaaTypes,
    },
  };
};

export const asAppliedDownloadFileApi = (id: string) => ({
  [RSAA]: {
    endpoint: `taskdata/${id}/download?`,
    method: methods.GET,
    module: modules.SENTINEL,
    types: [
      types.AS_APPLIED_DOWNLOAD_FILE_REQUEST,
      {
        type: types.AS_APPLIED_DOWNLOAD_FILE_SUCCESS,
        payload: (action: unknown, state: unknown, res: Response) => ({
          body: res.blob(),
          disposition: res.headers.get("Content-Disposition"),
          type: res.headers.get("Content-Type"),
        }),
      },
      types.AS_APPLIED_DOWNLOAD_FILE_ERROR,
    ],
  },
});

export const asAppliedBulkDownloadFilesApi = (tasks: string[]) => ({
  [RSAA]: {
    endpoint: `taskdata/download?`,
    method: methods.POST,
    module: modules.SENTINEL,
    body: JSON.stringify({ tasks }),
    types: [
      types.AS_APPLIED_BULK_DOWNLOAD_FILE_REQUEST,
      {
        type: types.AS_APPLIED_BULK_DOWNLOAD_FILE_SUCCESS,
        payload: (action: unknown, state: unknown, res: Response) =>
          ({
            body: res.blob(),
            disposition: res.headers.get("Content-Disposition"),
            type: res.headers.get("Content-Type"),
          }) as ExportPayload,
      },
      types.AS_APPLIED_BULK_DOWNLOAD_FILE_ERROR,
    ] as RsaaTypes,
  },
});
