import React from "react";

import { Theme } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";

import { PositionState } from "../../../../shared/api/telematics/telematics.types";

type Props = {
  state: PositionState;
};

const MachineStatus = ({ state }: Props) => {
  const classes = useStyles();
  return (
    <Tooltip
      title={
        <FormattedMessage
          id={`MainMapTelematics.machinesDashboard.state.${state}`}
        />
      }
    >
      <div className={classNames(classes.statusCircle, classes[state])} />
    </Tooltip>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  statusCircle: {
    width: 10,
    minWidth: 10,
    maxWidth: 10,
    height: 10,
    minHeight: 10,
    maxHeight: 10,
    borderRadius: "100%",
  },
  ON: {
    border: `1px solid #A39200`,
    backgroundColor: "#FFE500",
  },
  OFF: {
    border: `1px solid #550000`,
    backgroundColor: "#D00000",
  },
  MOVING: {
    border: `1px solid #008535`,
    backgroundColor: "#00D053",
  },
  MISSING: {
    border: `1px solid ${theme.palette.common.black}`,
    backgroundColor: theme.palette.common.black,
  },
}));

export { MachineStatus };
