import React, { useCallback, useEffect } from "react";

import { Box, TableBody, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getIsPorReportHistoryIsLoading,
  getPorHistoryReport,
  getTotalCount,
} from "../../../../shared/api/agroevidence/reports/reports.selectors";

import { getPorUsageReport } from "../../actions/reports.actions";

import CfTableFooter from "../../../../common/components/CfTableFooter/CfTableFooter";
import { useCfTableFooter } from "../../../../common/components/CfTableFooter/useCfTableFooter";
import CfTableHead from "../../../../common/components/CfTableHead/CfTableHead";
import { useCfTableHead } from "../../../../common/components/CfTableHead/useCfTableHead";
import CfTableBodyEmpty from "../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableBodyLoader from "../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader";
import CfTableWrapper from "../../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import { getColDesc } from "../../../../shared/misc/helper";
import { Thunk } from "../../../../types";
import { NAMESPACE as namespace } from "../../reducer/porReportHistoryTableReducer";
import {
  getPorHistoryReportAdminPage,
  getPorHistoryReportRowsPerPage,
} from "../../selectors/porReportsHistorySelector";

import { PorUsageEvidenceRow } from "./PorUsageEvidenceRow";

import { ReportsState } from "../../../../reducers/reports.reducer.types";
import { ReportHistoryTo } from "../../../../shared/api/agroevidence/agroevidence.types";
import { PorTableState } from "../../../../shared/api/agroevidence/reports/reports.types";

interface Props {
  getPorUsageReport: (params: PorTableState) => void;
  isPorReportHistoryLoading: boolean;
  page: number;
  porHistoryReport: ReportHistoryTo[];
  porTotalCount: number;
  rowsPerPage: number;
}

const PorUsageEvidence = ({
  getPorUsageReport,
  isPorReportHistoryLoading,
  page,
  porHistoryReport,
  porTotalCount,
  rowsPerPage,
}: Props) => {
  const columns = {
    success: getColDesc(
      false,
      <FormattedMessage id="Reports.table.label.state" />,
    ),
    usagePeriod: getColDesc(
      false,
      <FormattedMessage id="Reports.table.label.usagePeriod" />,
    ),
    sent: getColDesc(
      false,
      <FormattedMessage id="Reports.table.label.sentDate" />,
    ),
    error: getColDesc(false, ""),
  };

  const { onPageChange, onRowsPerPageChange } = useCfTableFooter(namespace);
  const { onSelect, onSort } = useCfTableHead(namespace);

  const getHistoryReports = useCallback(() => {
    const params = { page, rowsPerPage };
    getPorUsageReport(params);
  }, [getPorUsageReport, page, rowsPerPage]);

  useEffect(() => {
    getHistoryReports();
  }, [getHistoryReports, page, rowsPerPage]);

  return (
    <Box>
      <Typography variant="subtitle2">
        <FormattedMessage id="Reports.history.table.header.por" />
      </Typography>
      <CfTableWrapper>
        <CfTableHead columns={columns} onSelect={onSelect} onSort={onSort} />
        {porHistoryReport.length > 0 && (
          <TableBody>
            {porHistoryReport.map((row) => (
              <PorUsageEvidenceRow key={row.id} row={row} />
            ))}
          </TableBody>
        )}
        {isPorReportHistoryLoading && <CfTableBodyLoader columns={columns} />}
        {porHistoryReport.length === 0 && <CfTableBodyEmpty colLength={1} />}
        <CfTableFooter
          count={porTotalCount}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
        />
      </CfTableWrapper>
    </Box>
  );
};

const mapStateToProps = (state: ReportsState) => ({
  porTotalCount: getTotalCount(state),
  rowsPerPage: getPorHistoryReportRowsPerPage(state),
  page: getPorHistoryReportAdminPage(state),
  porHistoryReport: getPorHistoryReport(state),
  isPorReportHistoryLoading: getIsPorReportHistoryIsLoading(state),
});

const mapDispatchToProps = (dispatch: Thunk<ReportsState>) =>
  bindActionCreators(
    {
      getPorUsageReport,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(PorUsageEvidence);
