import React from "react";

import { alpha, Theme } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import { makeStyles } from "@mui/styles";

type Props = {
  toolsClass: string;
  withoutGradient?: boolean;
};

const CfTableRowTools = ({
  children,
  toolsClass,
  withoutGradient = false,
}: React.PropsWithChildren<Props>) => {
  const classes = useStyles();
  return (
    <TableCell
      className={`${classes.editButtonCell} ${toolsClass}`}
      onClick={(evt) => evt.stopPropagation()}
    >
      {!withoutGradient && <div className={classes.gradient} />}
      {children}
    </TableCell>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  editButtonCell: {
    position: "absolute",
    right: 0,
    top: 1,
    height: "calc(100% - 3px)",
    alignItems: "center",
    display: "flex",
    backgroundColor: "#FF000000",
    paddingLeft: 5,
    paddingRight: 5,
    borderBottom: "none",
  },
  gradient: {
    width: 20,
    background: `linear-gradient(-90deg, 
      ${alpha(theme.palette.common.white, 1)}, 
      ${alpha(theme.palette.common.white, 0)})`,
    position: "absolute",
    left: -20,
    height: "100%",
  },
}));

export default CfTableRowTools;
