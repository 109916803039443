import React, { useCallback, useEffect } from "react";

import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Tooltip } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import { difference, uniq } from "lodash";
import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  isFetchingDriverAggregations,
  getDriverAggregations,
  getDriverAggregationsCount,
} from "../../../shared/api/telematics/aggregations/aggregations.selectors";
import {
  getTelematicsAggregationsOrder,
  getTelematicsAggregationsOrderBy,
  getTelematicsAggregationsPage,
  getTelematicsAggregationsRowsPerPage,
  getTelematicsAggregationsTextFilter,
  getTelematicsAggregationsUnapprovedFilter,
  getTelematicsAggregationsBulkEditMode,
  getTelematicsAggregationsSelectedRides,
  getTelematicsAggregationsSelected,
  getTelematicsAggregationsSelectedOnPage,
  getTelematicsAggregationsSelectedRidesOnPage,
} from "../../selectors/telematicsAggregations.selectors";
import { selectDateRange } from "../../selectors/telematicsTabs.selectors";

import { setAdvancedFilter } from "../../../shared/actions/filter.actions";
import {
  setTableSelected,
  setTableSubrowSelected,
} from "../../../shared/actions/table.actions";
import {
  fetchAggregationsSaga,
  setFocusedRow,
} from "../../actions/telematicsAggregations.actions";
import { setDateRange } from "../../actions/telematicsTabs.actions";

import { NAMESPACE } from "../../reducer/telematicsAggregations.reducer";
import { NAMESPACE as telListNamespace } from "../../reducer/telematicsList.reducer";

import CfTableFooter from "../../../common/components/CfTableFooter/CfTableFooter";
import { useCfTableFooter } from "../../../common/components/CfTableFooter/useCfTableFooter";
import CfTableHead from "../../../common/components/CfTableHead/CfTableHead";
import { useCfTableHead } from "../../../common/components/CfTableHead/useCfTableHead";
import CfTableBodyEmpty from "../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableBodyLoader from "../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader";
import CfTableWrapper from "../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import { getColDesc } from "../../../shared/misc/helper";
import { Thunk } from "../../../types";
import { TelematicsNgProps } from "../../containers/Telematics/Telematics";
import DoubleLinedHeader from "../DoubleLinedHeader/DoubleLinedHeader";

import { DriverRow } from "./DriverRow";
import { getIsDisabled } from "./RideRow";

import { TelematicsState } from "../../../reducers/telematics.reducer.types";
import { LogbookAggregatedTo } from "../../../shared/api/telematics/telematics.types";

type ReduxProps = ConnectedProps<typeof connector>;
type OwnProps = {
  setBulkEditMode: () => void;
};
type Props = ReduxProps & OwnProps & TelematicsNgProps;

const TelematicsAggregationsTable = ({
  bulkEditMode,
  count,
  dateFilter,
  driverAggregations,
  getAggregations,
  isFetching,
  ngRedirectToMainMapWithFilters,
  order,
  orderBy,
  page,
  rowsPerPage,
  selected,
  selectedOnPage,
  selectedRides,
  selectedRidesOnPage,
  setBulkEditMode,
  setFocusedRow,
  setTableSelected,
  setTableSubrowSelected,
  setTelListAdvancedFilter,
  setTelListDate,
  textFilter,
  unapprovedRidesOnly,
}: Props) => {
  const { onPageChange, onRowsPerPageChange } = useCfTableFooter(NAMESPACE);
  const { onSelect, onSort } = useCfTableHead(NAMESPACE);

  useEffect(() => {
    getAggregations();
    return () => {
      setFocusedRow(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    order,
    orderBy,
    page,
    rowsPerPage,
    dateFilter,
    textFilter,
    unapprovedRidesOnly,
  ]);

  const logbookFilterHandler = useCallback(
    (
      dateFrom: string,
      dateTo: string,
      driver: Record<"code" | "name", string>,
    ) => {
      setTelListDate(dateFrom, dateTo);
      setTelListAdvancedFilter(
        { driver: [{ code: driver.code, name: driver.name, validFrom: "" }] },
        telListNamespace,
      );
    },
    [setTelListAdvancedFilter, setTelListDate],
  );

  const columns = generateColumns(
    bulkEditMode,
    setBulkEditMode,
    isFetching,
    driverAggregations,
  );

  const selectableRows =
    driverAggregations?.filter((i) =>
      i.drives.some((d) => !getIsDisabled(d)),
    ) ?? [];

  return (
    <CfTableWrapper>
      <CfTableHead
        bulkMode={bulkEditMode}
        columns={columns}
        disabledCheckbox={selectableRows.length === 0 || isFetching}
        items={driverAggregations}
        onSelect={onSelect}
        onSort={onSort}
        order={order}
        orderBy={orderBy}
        selected={selected}
        selectedOnPage={selectedOnPage}
        checked={
          selectedOnPage.length > 0 &&
          selectedOnPage.length === selectableRows.length
        }
        customSelectHandler={(checked?: boolean) => {
          if (checked) {
            if (selectableRows.length > 0) {
              setTableSelected(
                uniq([
                  ...selected,
                  ...selectableRows.map((r) => r.drives[0].id),
                ]),
                NAMESPACE,
              );
              let subrowsToSelect: string[] = [];
              selectableRows.forEach((r) => {
                const validRides = r.drives.filter((d) => !getIsDisabled(d));
                const validIds = validRides.map((r) => r.id);
                subrowsToSelect = subrowsToSelect.concat(validIds);
              });
              setTableSubrowSelected(
                uniq([...selectedRides, ...subrowsToSelect]),
                NAMESPACE,
              );
            }
          } else {
            setTableSelected(difference(selected, selectedOnPage), NAMESPACE);
            setTableSubrowSelected(
              difference(selectedRides, selectedRidesOnPage),
              NAMESPACE,
            );
          }
        }}
        indeterminate={
          selectedOnPage.length > 0 &&
          selectedOnPage.length < selectableRows.length
        }
      />
      {isFetching && <CfTableBodyLoader columns={columns} />}
      {!isFetching && driverAggregations && (
        <TableBody>
          {driverAggregations.map((aggregation, i) => (
            <DriverRow
              columnsLength={Object.keys(columns).length + 1}
              data={aggregation}
              dateFilter={dateFilter}
              key={i}
              logbookFilterHandler={logbookFilterHandler}
              ngRedirectToMainMapWithFilters={ngRedirectToMainMapWithFilters}
              rowId={i}
            />
          ))}
        </TableBody>
      )}
      {!isFetching && !driverAggregations?.length && (
        <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
      )}
      <CfTableFooter
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </CfTableWrapper>
  );
};

const generateColumns = (
  bulkEditMode: boolean,
  bulkEditOnClick: () => void,
  isFetching: boolean,
  driverAggregations?: LogbookAggregatedTo[],
) => ({
  duration: getColDesc(
    true,
    <DoubleLinedHeader
      leftOffset
      primaryId="TelematicsAggregations.list.date"
      secondaryId="TelematicsAggregations.list.duration"
    />,
  ),
  "driver.name": getColDesc(
    true,
    <DoubleLinedHeader
      primaryId="TelematicsAggregations.list.driverName"
      secondaryId="TelematicsAggregations.list.driverId"
    />,
  ),
  cultivated: getColDesc(
    false,
    <FormattedMessage id="TelematicsAggregations.list.cultivated" />,
    { width: 100 },
  ),
  distance: getColDesc(
    false,
    <FormattedMessage id="TelematicsAggregations.list.distance" />,
    { width: 100 },
  ),
  time: getColDesc(
    false,
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <FormattedMessage id="TelematicsAggregations.list.time" />
      <Tooltip
        title={
          <FormattedMessage id="TelematicsAggregations.list.bulk.editTooltip" />
        }
      >
        <span>
          <IconButton
            aria-label="Bulk editation"
            onClick={bulkEditOnClick}
            size="large"
            style={{ padding: 4 }}
            disabled={
              bulkEditMode || driverAggregations?.length === 0 || isFetching
            }
          >
            <EditIcon />
          </IconButton>
        </span>
      </Tooltip>
    </div>,
  ),
});

const mapStateToProps = (state: TelematicsState) => ({
  order: getTelematicsAggregationsOrder(state),
  orderBy: getTelematicsAggregationsOrderBy(state),
  isFetching: isFetchingDriverAggregations(state),
  driverAggregations: getDriverAggregations(state),
  dateFilter: selectDateRange(state),
  count: getDriverAggregationsCount(state),
  page: getTelematicsAggregationsPage(state),
  rowsPerPage: getTelematicsAggregationsRowsPerPage(state),
  textFilter: getTelematicsAggregationsTextFilter(state),
  unapprovedRidesOnly: getTelematicsAggregationsUnapprovedFilter(state),
  bulkEditMode: getTelematicsAggregationsBulkEditMode(state),
  selected: getTelematicsAggregationsSelected(state),
  selectedRides: getTelematicsAggregationsSelectedRides(state),
  selectedOnPage: getTelematicsAggregationsSelectedOnPage(state),
  selectedRidesOnPage: getTelematicsAggregationsSelectedRidesOnPage(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      getAggregations: fetchAggregationsSaga,
      setTelListAdvancedFilter: setAdvancedFilter,
      setTelListDate: setDateRange,
      setTableSelected,
      setTableSubrowSelected,
      setFocusedRow,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TelematicsAggregationsTable);
