import React from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

type Props = {
  testId?: string;
};

function MapParcelDetailHeader({
  children,
  testId = "map-bottom-panel-header",
}: React.PropsWithChildren<Props>) {
  const classes = useStyles();

  return (
    <div className={classes.header} data-test={testId}>
      {children}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    fontSize: 14,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      padding: "18px 36px",
      borderBottom: "1px solid #EFEFEF",
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      padding: "0 0 0 20px",
      minWidth: "max-content",
      height: 120,
      borderRight: "1px solid #EFEFEF",
    },
  },
}));

export { MapParcelDetailHeader };
