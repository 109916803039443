import React, { PropsWithChildren } from "react";

import { Tooltip } from "@mui/material";

type Props = {
  onClick: () => void;
  className: string;
  tooltipTitle: React.ReactNode;
};

const ControlButton = ({
  children,
  className,
  onClick,
  tooltipTitle,
}: PropsWithChildren<Props>) => (
  <Tooltip placement="bottom" title={tooltipTitle}>
    <button className={className} onClick={onClick} type="button">
      {children}
    </button>
  </Tooltip>
);

export { ControlButton };
