import React from "react";

import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";

import SentinelAdminParcelsDialog from "../../SentinelAdminParcelsDialog/SentinelAdminParcelsDialog";
import {
  ParcelWithServices,
  SentinelAdminType,
  SentinelAdminTypeNoToggle,
} from "../useSentinelAdmin";

import { LeftSide } from "./LeftSide";
import { RightSide } from "./RightSide";

import { ParcelsConfigUpdateRequest } from "../../../../../../shared/api/satellite/satellite.types";
import { AdminFarm } from "../../../../sensors/admin.sensors.types";

type Props = {
  farm?: AdminFarm;
  handleBulkActionClick: (mode: SentinelAdminTypeNoToggle) => void;
  selected: string[];
  activeParcel?: ParcelWithServices;
  dialogTitle: React.ReactNode | string;
  handleBulkDialogAccept: (
    config: Omit<ParcelsConfigUpdateRequest, "parcelId">,
  ) => void;
  handleBulkDialogCancel: () => void;
  mode?: SentinelAdminType;
};

const SentinelAdminHeader = ({
  activeParcel,
  dialogTitle,
  farm,
  handleBulkActionClick,
  handleBulkDialogAccept,
  handleBulkDialogCancel,
  mode,
  selected,
}: Props) => {
  const classes = useStyles();

  const toggleInitData = (parcel: Partial<ParcelWithServices>) => {
    if (!parcel) return undefined;
    const sanitizedParcel = {} as {
      [key: string]: unknown;
    };

    Object.keys(parcel).forEach((key) => {
      const typedKey = key as keyof typeof parcel;
      const value = parcel[typedKey];
      // replace "false" values to "undefined":
      sanitizedParcel[typedKey] = value === false ? undefined : value;
    });

    return sanitizedParcel as Partial<ParcelWithServices>;
  };

  return (
    <>
      <Grid
        item
        md={4}
        sm={6}
        sx={{ display: "flex", alignItems: "center" }}
        xs={12}
      >
        <LeftSide
          handleBulkActionClick={handleBulkActionClick}
          selected={selected}
        />
      </Grid>
      <Grid item md={4} sm={1} xs={3} />
      <Grid className={classes.rightSide} item md={4} sm={5} xs={9}>
        <RightSide farm={farm} />
      </Grid>

      {activeParcel ? (
        <SentinelAdminParcelsDialog
          mode={mode === "toggle" ? "single" : "multi"}
          onClose={handleBulkDialogCancel}
          onSubmit={handleBulkDialogAccept}
          title={dialogTitle}
          initData={
            mode === "toggle" ? toggleInitData(activeParcel) : undefined
          }
        />
      ) : null}
    </>
  );
};

const useStyles = makeStyles(() => ({
  rightSide: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 10,
  },
}));

export { SentinelAdminHeader };
