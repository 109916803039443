import get from "lodash/get";

import ErrorService from "../../../services/Error.service";

import { ActionsState } from "../../../../reducers/actions.reducer.types";
import { ParcelsState } from "../../../../reducers/parcels.reducer.types";
import { PrecisionState } from "../../../../reducers/precision.reducer.types";
import { ReportsState } from "../../../../reducers/reports.reducer.types";
import { TelematicsState } from "../../../../reducers/telematics.reducer.types";

const DEFAULT_PATH = "api.agroevidence.parcels";

const resolveStatePath = <
  State extends ParcelsState | TelematicsState | ReportsState | ActionsState,
>(
  state: State,
  path: string | undefined,
) => get(state, path || DEFAULT_PATH);

export const getParcelsReportsState =
  (path?: string) =>
  <State extends ReportsState>(state: State) =>
    resolveStatePath(state, path)?.items || [];
export const getParcels =
  (path?: string) =>
  <State extends ParcelsState | TelematicsState | ActionsState | ReportsState>(
    state: State,
  ) =>
    resolveStatePath(state, path)?.items || [];
export const getIsFetchingParcels =
  (path?: string) =>
  (state: ParcelsState | TelematicsState | ActionsState | ReportsState) =>
    resolveStatePath(state, path)?.isFetchingItems;
export const getParcelsError = (state: ParcelsState) =>
  ErrorService.getResErrorDto(state.api.agroevidence.parcels.itemsError);
export const getTotalCount = (state: ParcelsState | TelematicsState) =>
  state.api.agroevidence.parcels.totalCount;

export const getParcelsSuggestion = (state: ParcelsState | TelematicsState) =>
  state.api.agroevidence.parcels.itemsSuggestion;
export const getIsFetchingParcelsSuggestion = (
  state: ParcelsState | TelematicsState,
) => state.api.agroevidence.parcels.isFetchingItemsSuggestion;

export const getParcelsStatistics = (state: ParcelsState) =>
  state.api.agroevidence.parcels.statistics;
export const getIsFetchingParcelsStatistics = (state: ParcelsState) =>
  state.api.agroevidence.parcels.isFetchingStatistics;

export const getParcelsLandUse = (state: ParcelsState) =>
  state.api.agroevidence.parcels.landUse;
export const getIsFetchingParcelsLandUse = (state: ParcelsState) =>
  state.api.agroevidence.parcels.isFetchingLandUse;

export const getCenters = (state: ParcelsState) =>
  state.api.agroevidence.parcels.centers;
export const getIsFetchingCenters = (state: ParcelsState) =>
  state.api.agroevidence.parcels.isFetchingCenters;

export const getParcel = (
  state: ParcelsState | TelematicsState | PrecisionState | ActionsState,
) => state.api.agroevidence.parcels.item;
export const getIsFetchingParcel = (
  state: ParcelsState | TelematicsState | PrecisionState | ActionsState,
) => state.api.agroevidence.parcels.isFetchingItem;
export const getParcelError = (
  state: ParcelsState | TelematicsState | PrecisionState | ActionsState,
) => ErrorService.getResErrorDto(state.api.agroevidence.parcels.itemError);

export const getParcelSeedApplication = (state: ParcelsState) =>
  state.api.agroevidence.parcels.itemSeedApplication;
export const getIsFetchingParcelSeedApplication = (state: ParcelsState) =>
  state.api.agroevidence.parcels.isFetchingItemSeedApplication;

export const getParcelPredecessors = (state: ParcelsState) =>
  state.api.agroevidence.parcels.itemPredecessors;
export const getIsFetchingParcelPredecessors = (state: ParcelsState) =>
  state.api.agroevidence.parcels.isFetchingItemPredecessors;

export const getParcelEagriRestrictions = (state: ParcelsState) =>
  state.api.agroevidence.parcels.itemEagriRestrictions;
export const getIsFetchinParcelEagriRestrictions = (state: ParcelsState) =>
  state.api.agroevidence.parcels.isFetchingItemEagriRestrictions;

export const getParcelSubtractionsGeometry = (state: ActionsState) =>
  state.api.agroevidence.parcels.itemSubtractionsGeometry;
export const getIsFetchinParcelSubtractionsGeometry = (state: ActionsState) =>
  state.api.agroevidence.parcels.isFetchingItemSubtractionsGeometry;

export const getParcelSubtractionsArea = (state: ActionsState) =>
  state.api.agroevidence.parcels.itemSubtractionsArea;
export const getIsFetchinParcelSubtractionsArea = (state: ActionsState) =>
  state.api.agroevidence.parcels.isFetchingItemSubtractionsArea;

export const getIsExportingData = (state: ParcelsState) =>
  state.api.agroevidence.parcels.isExportingData;

export const getParcelListFocusedRow = (state: ParcelsState) =>
  state.api.agroevidence.parcels.focusedRow;
