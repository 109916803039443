import { queryOptions } from "@tanstack/react-query";

import {
  aggregatedRecords,
  AggregatedRecordsParams,
  logbook,
  LogbookParams,
} from "../../../generated/api/telematics";

const logbookQuery = ({
  aggregated,
  ...params
}: (LogbookParams | AggregatedRecordsParams) & { aggregated: boolean }) =>
  queryOptions({
    queryKey: ["telematics", "logbook", aggregated, ...Object.values(params)],
    queryFn: async () => {
      const data = aggregated
        ? await aggregatedRecords(params)
        : await logbook(params);
      return {
        data: data.data,
        count: data.headers ? data.headers["x-total-count"] : 0,
      };
    },
  });

export { logbookQuery };
