import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useReportStyles = makeStyles((theme: Theme) => ({
  accordion: {
    margin: 0,
    "&$expanded": {
      margin: 0,
    },
  },
  root: {
    backgroundColor: theme.palette.grey[100],
    minHeight: 48,
    fontSize: 16,
    "&$expanded": {
      minHeight: 48,
    },
  },
  expandIcon: {
    padding: 6,
  },
  expanded: {},
  content: {
    margin: "8px 0px",
    justifyContent: "left",
    "&$expanded": {
      margin: "8px 0px",
    },
  },
  details: {
    display: "block",
    padding: "8px 8px 8px",
  },
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  label: {
    display: "flex",
    alignItems: "center",
  },
}));
