import React from "react";

import FilterListIcon from "@mui/icons-material/FilterList";
import { IconButton, Theme, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { getMainMapTelematicsDashboardMachinesIsOpen } from "../../../../shared/api/telematics/mainMapTelematics/dashboard/machinesDashboard.selectors";
import {
  getMainMapTelematicsDateFrom,
  getMainMapTelematicsDateTo,
} from "../../selectors/mainMapTelematics.selectors";

import { dropLastVisitedTab } from "../../../../telematics/containers/TelematicsTabs/useTelematicsLogger";
import { MachineStatus } from "../../components/MachinesDashboard/MachineStatus";

import { ShowOnMap } from "./showOnMap/ShowOnMap";

import {
  PositionDetailTo,
  PositionMachineTo,
} from "../../../../shared/api/telematics/telematics.types";

type Props = {
  testId?: string;
  machinePosition?: PositionDetailTo;
  ngRedirectToTelematics: (
    dateFrom?: string,
    dateTo?: string,
    machine?: PositionMachineTo,
  ) => void;
};

function DetailHeader({
  machinePosition,
  ngRedirectToTelematics,
  testId = "map-bottom-panel-header",
}: Props) {
  const machineDashboardIsOpen = useSelector(
    getMainMapTelematicsDashboardMachinesIsOpen,
  );
  const dateFrom = useSelector(getMainMapTelematicsDateFrom);
  const dateTo = useSelector(getMainMapTelematicsDateTo);

  const classes = useStyles();

  const handleRecordsFilterClick = () => {
    dropLastVisitedTab();
    ngRedirectToTelematics(dateFrom, dateTo, machinePosition?.machine);
  };

  const historyIsDisabled = !(
    machineDashboardIsOpen &&
    moment(machinePosition?.movementTime).isBefore(moment().startOf("day"))
  );

  if (!machinePosition) return null;

  return (
    <div className={classes.wrapper} data-test={testId}>
      <div className={classes.header}>
        <div className={classes.titles}>
          <div className={classes.statusWrapper}>
            <MachineStatus state={machinePosition.state} />
          </div>
          <div>
            <div className={classes.machineName}>
              {machinePosition.machine?.name ??
                machinePosition.machine?.gpsUnit}
            </div>
            <div className={classes.machineLicencePlate}>
              {machinePosition.machine?.licencePlate ??
                machinePosition.machine?.gpsUnit}
            </div>
          </div>
        </div>
        <div className={classes.iconButtons}>
          <ShowOnMap
            disabled={!historyIsDisabled}
            machinePosition={machinePosition}
          />
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={
              <FormattedMessage
                id={`MainMapTelematicsDetail.showDrives${machineDashboardIsOpen ? "Dashboard" : ""}`}
              />
            }
          >
            <span>
              <IconButton
                disabled={machineDashboardIsOpen}
                onClick={handleRecordsFilterClick}
              >
                <FilterListIcon style={{ minWidth: 24 }} width={24} />
              </IconButton>
            </span>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    fontSize: 14,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      padding: "18px 36px",
      borderBottom: "1px solid #EFEFEF",
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      padding: "0 0 0 20px",
      minWidth: "max-content",
      height: 120,
      borderRight: "1px solid #EFEFEF",
    },
  },
  header: {
    display: "flex",
    fontWeight: 500,
    gap: "1rem",
    [theme.breakpoints.down("md")]: {
      flexDirection: "row",
      gap: "0.5rem",
    },
    [theme.breakpoints.up("md")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  titles: {
    display: "flex",
    gap: 10,
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
  },
  statusWrapper: {
    marginTop: 5,
  },
  iconButtons: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  machineName: {
    fontWeight: 500,
    width: "100%",
  },
  machineLicencePlate: {
    color: theme.palette.grey[500],
    fontWeight: 400,
  },
  tooltip: {
    [theme.breakpoints.up("md")]: {
      margin: "0 !important",
    },
  },
}));

export default DetailHeader;
