import { IndiceType } from "../../selectors/indices";
import { zonesColors } from "../../services/monitoringZonesColors";

import { IndexType } from "../../../../shared/api/satellite/satellite.types";

type ChartDesignDataType = {
  color: string;
  ticksByIndex: number[];
  domainByIndex: number[];
};

const useChartDesignData = (index: IndiceType) => {
  // used for stroke and tooltip
  const MAIN_COLOR_INDEX = 5;
  const SAVI_COLOR_INDEX = 10;

  const getDomainByIndex = (indexType: IndiceType) => {
    switch (indexType) {
      case IndexType.CAB:
        return [2.0, 80.0];
      case IndexType.CW:
        return [0.0005, 0.07];
      case IndexType.LAI:
        return [0.1, 10];
      case IndexType.SAVI:
        return [0, 1.5];
      default:
        return [1.0, 100.0];
    }
  };

  const getTicksByIndex = (indexType: IndiceType) => {
    switch (indexType) {
      case IndexType.CAB:
        return [2.0, 21.5, 41.0, 60.5, 80.0];
      case IndexType.CW:
        return [0.0005, 0.0174, 0.0353, 0.0527, 0.07];
      case IndexType.LAI:
        return [0.1, 2.55, 5.0, 7.5, 10.0];
      case IndexType.SAVI:
        return [0, 0.5, 1.0, 1.5];
      default:
        return [1.0, 100.0];
    }
  };

  const getCABColor = () => zonesColors.CAB[MAIN_COLOR_INDEX].color;
  const getCWColor = () => zonesColors.CW[MAIN_COLOR_INDEX].color;
  const getLAIColor = () => zonesColors.LAI[MAIN_COLOR_INDEX].color;
  const getSAVIColor = () => zonesColors.SAVI[SAVI_COLOR_INDEX].color;

  const getColor = (index: IndiceType) => {
    switch (index) {
      case IndexType.CAB:
        return getCABColor();
      case IndexType.CW:
        return getCWColor();
      case IndexType.LAI:
        return getLAIColor();
      case IndexType.SAVI:
        return getSAVIColor();
      default:
        return "";
    }
  };

  const chartDesignData: ChartDesignDataType = {
    color: getColor(index),
    ticksByIndex: getTicksByIndex(index),
    domainByIndex: getDomainByIndex(index),
  };

  return {
    chartDesignData,
  };
};

export { useChartDesignData, ChartDesignDataType };
