import React from "react";

import { Grid } from "@mui/material";
import { FormattedMessage } from "react-intl";

import CfStatusPanel from "../../../../shared/components/common/CfStatusPanel/CfStatusPanel";
import useWidth from "../../../../shared/hooks/useWidth";
import { AnyTodo } from "../../../../types";

type Props = {
  ngRedirectToSowing: (parcelId: string[]) => void;
  parcelId: string;
  cropHarvestedErrorId: string;
  cropHarvestedErrorIcon: (props: AnyTodo) => React.JSX.Element;
};

const CropHarvestedError = ({
  cropHarvestedErrorIcon,
  cropHarvestedErrorId,
  ngRedirectToSowing,
  parcelId,
}: Props) => {
  const width = useWidth();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <CfStatusPanel
          dense={true}
          fullWidth={true}
          horizontal={width !== "xs"}
          icon={cropHarvestedErrorIcon}
          linkText={<FormattedMessage id="BioMonitoring.harvestCrop" />}
          onLinkClick={() => ngRedirectToSowing([parcelId])}
          content={
            <FormattedMessage
              id={`BioMonitoring.status.${cropHarvestedErrorId}`}
              values={{
                b: (chunks: string) => <b>{chunks}</b>,
              }}
            />
          }
        />
      </Grid>
    </Grid>
  );
};

export { CropHarvestedError };
