import React, { useEffect } from "react";

import { Theme } from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";

import {
  getManagementZoneParcelSelector,
  getManagementZoneParcelError,
  getManagementZoneParcelIsFetching,
} from "../../../shared/api/sentinel/managementZones/managementZones.selectors";

import {
  getManagementZoneParcel,
  resetManagementZoneParcel,
} from "../../../shared/api/sentinel/managementZones/managementZones.api";
import CfErrorPage from "../../../shared/components/common/CfErrorPage/CfErrorPage";
import CfLoader from "../../../shared/components/common/CfLoader/CfLoader";
import AreaZonesChart from "../components/ZoneManagement/AreaZonesChart/AreaZonesChart";
import GeneralizedZoneManagementMap from "../components/ZoneManagement/GeneralizedZoneManagement/GeneralizedZoneManagementMap";
import QualityZonesChart from "../components/ZoneManagement/QualityZonesChart/QualityZonesChart";
import RelativeCoverInvolvementMap from "../components/ZoneManagement/RelativeCoverInvolvement/RelativeCoverInvolvementMap";

import { BarChartStripe } from "./biomonitoring/HorizontalBarChart/HorizontalBarChart";
import { ChartPoint } from "./biomonitoring/HorizontalPointsChart/HorizontalPointsChart";

import { PrecisionState } from "../../../reducers/precision.reducer.types";

type OwnProps = {
  langId: string;
  parcelId: string;
  farmId: string;
};

const ZoneManagement = ({ farmId, langId, parcelId }: OwnProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const managementZoneParcel = useSelector((state: PrecisionState) =>
    getManagementZoneParcelSelector(state),
  );
  const managementZoneParcelIsFetching = useSelector((state: PrecisionState) =>
    getManagementZoneParcelIsFetching(state),
  );
  const managementZoneParcelError = useSelector((state: PrecisionState) =>
    getManagementZoneParcelError(state),
  );

  useEffect(() => {
    dispatch(getManagementZoneParcel(farmId, parcelId));
    return () => {
      dispatch(resetManagementZoneParcel());
    };
  }, [dispatch, farmId, parcelId, langId]);

  const data = managementZoneParcel?.length
    ? managementZoneParcel[0]
    : undefined;

  const getAreaZonesChartData = () => {
    const sortedData =
      data?.savi?.sort((a, b) => Number(b.index) - Number(a.index)) ?? [];
    const mapped =
      sortedData.map(
        (i) =>
          ({
            color: `#${i.color}` ?? "#000000",
            label: Number(i.areaHa.toFixed(1)),
          }) as BarChartStripe,
      ) ?? [];

    const invalidLabels = mapped?.filter(
      (item) => isNaN(item.label) || item.label === undefined,
    );
    const isInvalidInput = !!invalidLabels?.length;
    return isInvalidInput ? [] : mapped;
  };

  const getQualityZonesChartData = () => {
    const sortedData =
      data?.savi?.sort((a, b) => Number(b.index) - Number(a.index)) ?? [];
    const mapped =
      sortedData.map(
        (i) =>
          ({
            color: `#${i.color}` ?? "#000000",
            label: Number(i.quality?.toFixed(1)),
          }) as ChartPoint,
      ) ?? [];

    const invalidLabels = mapped?.filter(
      (item) => isNaN(item.label) || item.label === undefined,
    );
    const isInvalidInput = invalidLabels && invalidLabels.length > 0;
    return isInvalidInput ? [] : mapped;
  };

  return (
    <CfErrorPage error={managementZoneParcelError}>
      {managementZoneParcelIsFetching ? (
        <CfLoader />
      ) : (
        <Grid className={classes.wrapper} container spacing={1.5}>
          <Grid item sm={7} xs={12}>
            <Paper className={classes.paper}>
              <GeneralizedZoneManagementMap item={data} />
            </Paper>
          </Grid>
          <Grid item sm={5} xs={12}>
            <Grid className={classes.rightColumn} container>
              <Grid item>
                <Paper className={classes.paper}>
                  <QualityZonesChart
                    averageQuality={data?.averageQuality}
                    data={getQualityZonesChartData()}
                  />
                </Paper>
              </Grid>
              <Grid item>
                <Paper className={classes.paper}>
                  <AreaZonesChart
                    data={getAreaZonesChartData()}
                    totalAreaHa={data?.totalAreaHa}
                  />
                </Paper>
              </Grid>
              <Grid item style={{ flex: 1 }}>
                <Paper className={classes.paper} style={{ height: "100%" }}>
                  <RelativeCoverInvolvementMap item={data} />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </CfErrorPage>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    fontSize: 18,
    margin: 0,
  },
  wrapper: {
    marginBottom: 10,
  },
  paper: {
    paddingTop: 12,
    paddingBottom: 12,
  },
  headerBar: {
    marginBottom: 5,
  },
  cfSwitcherWrapper: {
    justifyContent: "flex-start",
  },
  rightColumn: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  [theme.breakpoints.down("xs")]: {
    heading: {
      textAlign: "center",
      marginBottom: 10,
    },
  },
}));

export default ZoneManagement;
