import {
  IndexType,
  VariableApplicationZoneType,
} from "../../../../../shared/api/satellite/satellite.types";
import {
  ExtendedIndexResponse,
  SatelliteDataType,
} from "../../../../../shared/api/sentinel/managementZones/managementZones.types";

const useVaMapSourceDialog = (satelliteData: SatelliteDataType) => {
  const customSort = (
    dataToSort: [string, ExtendedIndexResponse[] | null][],
    desiredOrder: string[],
  ) => {
    // Creating a map that will map elements to their indices in "desiredOrder"..
    const orderMap = new Map();
    desiredOrder.forEach((element, index) => {
      orderMap.set(element, index);
    });

    // sorting itself
    dataToSort.sort((a, b) => {
      const indexA = orderMap.get(a[0]);
      const indexB = orderMap.get(b[0]);

      if (indexA === undefined && indexB === undefined) {
        return 0;
      } else if (indexA === undefined) {
        return 1;
      } else if (indexB === undefined) {
        return -1;
      } else {
        return indexA - indexB;
      }
    });

    // result
    return dataToSort;
  };

  const mapSatelliteData = (satelliteData: SatelliteDataType) => {
    const res = { ...satelliteData };
    delete res[IndexType.HIST]; // we are not offering growth potential to customers anymore
    const extractedSAVI = IndexType.SAVI in res ? res.SAVI : null;
    if (extractedSAVI) {
      delete res[IndexType.SAVI];
      Object.values(VariableApplicationZoneType).forEach((t) => {
        res[t] = extractedSAVI.filter((i) => i.type === (t as string));
      });
    }
    return res;
  };

  const desiredOrder = [
    IndexType.LAI,
    IndexType.CAB,
    VariableApplicationZoneType.PLANET_BIOMONITORING_SAVI,
    IndexType.SAMPLES,
    VariableApplicationZoneType.SENTINEL_HUB_MANAGEMENT_ZONES_SAVI,
  ];

  const ensureAllKeysPresent = (
    data: [string, ExtendedIndexResponse[] | null][],
    desiredOrder: string[],
  ) => {
    const dataMap = new Map(data);

    desiredOrder.forEach((key) => {
      if (!dataMap.has(key)) {
        dataMap.set(key, null);
      }
    });

    return Array.from(dataMap.entries());
  };

  const mappedData = mapSatelliteData(satelliteData);

  const entriesData = satelliteData
    ? customSort(
        ensureAllKeysPresent(Object.entries(mappedData), desiredOrder),
        desiredOrder,
      )
    : customSort(ensureAllKeysPresent([], desiredOrder), desiredOrder);

  return {
    entriesData,
  };
};

export { useVaMapSourceDialog };
