import React from "react";

import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { Scrollbars } from "react-custom-scrollbars-2";
import { FormattedMessage } from "react-intl";

import CfTextFieldWithConfirm from "../../../../../shared/components/common/CfTextFieldWithConfirm/CfTextFieldWithConfirm";
import { StatsBody } from "../../components/ParcelZoneTable/StatsBody";
import { StatsFooter } from "../../components/ParcelZoneTable/StatsFooter";
import { StatsHeader } from "../../components/ParcelZoneTable/StatsHeader";

import { useParcelZoneStatisticsStyles } from "./zoneStatistics.styles";

import {
  ZoneStatistics,
  StatisticsItemType,
} from "../../../../../shared/api/agroevidence/zones/zones.types";

type Props = {
  zonesStats?: ZoneStatistics;
  onDeleteZone: (zone: StatisticsItemType) => void;
  onFilterZoneInParcels: (zones: StatisticsItemType) => void;
  onEditZone: (zone: StatisticsItemType) => void;
  createNewZone: boolean;
  setCreateNewZone: (val: boolean) => void;
  onCreateZoneConfirm: (zoneName: string) => void;
};

const ZoneStats = ({
  createNewZone,
  onCreateZoneConfirm,
  onDeleteZone,
  onEditZone,
  onFilterZoneInParcels,
  setCreateNewZone,
  zonesStats,
}: Props) => {
  const classes = useParcelZoneStatisticsStyles();

  return (
    <>
      <Table stickyHeader>
        <StatsHeader />
      </Table>
      <Scrollbars autoHeight={true} autoHeightMax={400}>
        <Table data-test="zone-list">
          <TableBody>
            {zonesStats?.items
              .sort((a, b) => b.totalArea - a.totalArea)
              .map((item) => (
                <StatsBody
                  item={item}
                  key={item.id}
                  onDeleteZone={onDeleteZone}
                  onEditZone={onEditZone}
                  onFilterZoneInParcels={onFilterZoneInParcels}
                />
              ))}
          </TableBody>
        </Table>
      </Scrollbars>
      <Table>
        <TableBody>
          {zonesStats?.totalArea !== undefined && (
            <StatsFooter stats={zonesStats} />
          )}
        </TableBody>
      </Table>
      <div className={classes.createZone}>
        {!createNewZone && (
          <span className={classes.button}>
            <Button
              className={classes.label}
              data-test="add-zone"
              disabled={createNewZone}
              endIcon={<AddIcon />}
              onClick={() => setCreateNewZone(true)}
            >
              <FormattedMessage id="ParcelZoneStatistics.create-zone" />
            </Button>
          </span>
        )}
        {createNewZone && (
          <div className={classes.createZoneForm}>
            <CfTextFieldWithConfirm
              label={<FormattedMessage id="ParcelZoneStatistics.new-zone" />}
              labelTestId="new-zone"
              onAccept={onCreateZoneConfirm}
              onCancel={() => setCreateNewZone(false)}
            />
          </div>
        )}
      </div>
    </>
  );
};

export { ZoneStats };
