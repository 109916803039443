export enum STATUSES {
  COMPLETE = "Complete",
  ERROR = "Error",
  PENDING = "Pending",
  RUNNING = "Running",
}

export enum EXPORT_TYPES {
  UPLOADER = "uploader",
  WRITER = "writer",
}

export interface WriterJob {
  exportDate: string;
  jobId: number;
  name: string;
  status: STATUSES;
}

export interface UploaderJob extends WriterJob {
  parentId: number;
}

export interface MachineFrom {
  id: number;
  name: string;
}

export interface MachineTo {
  machines: MachineFrom[];
}

export interface TableProcessedActionColumn {
  val: JSX.Element | string;
  rowspan?: number;
}

export interface TableProcessedAction {
  id: string;
  route: string;
  single: {
    "dateStart,id": TableProcessedActionColumn;
    "actionType.localizedNames.value": TableProcessedActionColumn;
    source: TableProcessedActionColumn;
    landUse: TableProcessedActionColumn;
    localName: TableProcessedActionColumn;
    material: TableProcessedActionColumn;
    dose: TableProcessedActionColumn;
    dosePerHectare: TableProcessedActionColumn;
  };
  multi: {
    material: TableProcessedActionColumn;
    dose: TableProcessedActionColumn;
    dosePerHectare: TableProcessedActionColumn;
  }[];
  isDraft?: boolean;
}
