import { useState } from "react";

const useInteractiveTooltip = () => {
  const [clickedTooltipIndex, setClickedTooltipIndex] = useState<number | null>(
    null,
  );
  const [hoveredTooltipIndex, setHoveredTooltipIndex] = useState<number | null>(
    null,
  );

  const handleTooltipClick = (index: number) => {
    if (clickedTooltipIndex === index) {
      setClickedTooltipIndex(null);
    } else {
      setClickedTooltipIndex(index);
    }
  };

  const handleTooltipHoverOn = (index: number) => {
    setHoveredTooltipIndex(index);
  };

  const handleTooltipHoverOff = () => {
    setHoveredTooltipIndex(null);
  };

  return {
    clickedTooltipIndex,
    hoveredTooltipIndex,
    handleTooltipClick,
    handleTooltipHoverOn,
    handleTooltipHoverOff,
  };
};

export { useInteractiveTooltip };
