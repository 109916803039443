import React, { useEffect, useState, useMemo } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import { makeStyles } from "@mui/styles";
import debounce from "lodash/debounce";

import { useTypedIntl } from "../../../shared/hooks/useTypedIntl";

type Props = {
  translId?: string;
  initialValue?: string;
  name?: string;
  disableUnderline?: boolean;
  autoFocus?: boolean;
  style?: React.CSSProperties;
  onChange: (inputValue: string) => void;
};

const CfTextFilter = ({
  autoFocus = false,
  disableUnderline = false,
  initialValue = "",
  name = "text-filter",
  onChange,
  style: propStyle = undefined,
  translId,
}: Props) => {
  const { formatMessage } = useTypedIntl();
  const styles = useStyles();
  const [value, setValue] = useState(initialValue);

  const debouncedFnc = useMemo(
    () =>
      debounce((inputValue) => {
        onChange(inputValue);
      }, 500),
    [onChange],
  );

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(
    () => () => {
      debouncedFnc.cancel();
    },
    [debouncedFnc],
  );

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    debouncedFnc(e.target.value);
  };

  const onIconResetClick = () => {
    setValue("");
    onChange("");
  };

  const icon = value.length ? <ClearIcon /> : <SearchIcon />;
  const translationId = translId
    ? formatMessage({
        id: translId,
      })
    : undefined;

  return (
    <div className={styles.wrapper}>
      <Input
        autoFocus={autoFocus}
        disableUnderline={disableUnderline}
        name={name}
        onChange={onInputChange}
        placeholder={translationId}
        value={value}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="Text filter"
              color="default"
              onClick={onIconResetClick}
              size="large"
            >
              {icon}
            </IconButton>
          </InputAdornment>
        }
        style={
          propStyle || {
            width: "100%",
          }
        }
      />
    </div>
  );
};

const useStyles = makeStyles({
  wrapper: {
    position: "relative",
    fontWeight: 400,
  },
});

export default CfTextFilter;
