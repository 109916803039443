import React from "react";

import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";

import { ParcelPredecessorTo } from "../../../../../../shared/api/agroevidence/agroevidence.types";

interface Props {
  goToHistoricalParcel: (selectedParcel: ParcelPredecessorTo) => void;
  historicalParcels: ParcelPredecessorTo[];
}

export const HistoricalParcelsPanel = ({
  goToHistoricalParcel,
  historicalParcels,
}: Props) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      {historicalParcels?.length ? (
        <div>
          {historicalParcels
            .map((parcel) => (
              <Link
                className={classes.parcelLink}
                key={parcel.id}
                onClick={() => goToHistoricalParcel(parcel)}
                to="#"
              >
                {parcel.blockNumber} {parcel.localName}
              </Link>
            ))
            .reduce((prev, curr) => (
              <>
                {prev}
                <span className={classes.separator}>,</span>
                {curr}
              </>
            ))}
        </div>
      ) : (
        "-"
      )}
    </Paper>
  );
};

const useStyles = makeStyles({
  parcelLink: {
    fontWeight: 500,
  },
  paper: {
    padding: 15,
    fontSize: 13,
  },
  separator: {
    marginRight: 8,
  },
});
